import Vue from 'vue/dist/vue.esm'


Vue.component('cart-popup',{
  template:`<div>
              <div class="container-fluid padding_right_10">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="font_open_sans_semibold_12_black margin_top_10 margin_left_min18">{{$t('cart.cart')}}&nbsp<span>({{cart_item_count}})</span></label>
                  </div>
                  <div class="col-sm-offset-7">
                    <div class="pull-right padding_right_2">
                      <label class="font_open_sans_regular_12_dark_gray margin_top_10 normal">{{$t('cart.Total')}}&nbsp<span>{{cart_item_total}}</span></label>
                    </div>
                  </div>
                </div>
                <hr v-bind:class="[{width_360: subscription_period}, 'margin_top_3 margin_bottom_0']">
                <div v-if="cart_empty" class="font_open_sans_regular_12_dark_gray margin_top_20 padding_bottom_20">{{$t('cart.cart_empty')}}</div>
                <div v-else>
                  <cart-item v-for="item in cart_items_list" v-bind:cart="true" :item = "item" :subscription_period = "subscription_period"></cart-item>
                  <hr v-bind:class="[{width_360: subscription_period}]">
                  <button type="submit" id="checkout" v-on:click="checkout"
                  class="font_open_sans_semibold_10_blue btn btn-default btn-round-sm pull-right margin_top_5 margin_bottom_11">{{$t('cart.checkout')}}</button>
                </div>
              </div>
            </div> `,
  props: {
    cart_item_count: Number,
    cart_item_total: String,
    cart_items_list: Array,
    cart_empty: Boolean,
    subscription_period: Boolean
  },
  mounted: function() {
    if (this.subscription_period) {
      jQuery("#cart_popup").css('width',386);
      jQuery("#cart_popup").css('margin-left',-342);
    }
  },
  methods: {
    checkout() {
      this.$root.$children[0].cartPopup = false;
      this.$root.$children[0].checkoutPopup = true;
    },
    close_cart() {
      this.$root.$children[0].cartPopup = false;
    }
  }
});

Vue.component('cart-item',{
  template:`<div class="margin_top_11" v-bind:id="'cart_item_'+item.id">
              <div class="container-fluid cart_container">
                <div class="row">
                  <div v-bind:class="[{width_16_percent: subscription_period && cart}, {width_15_percent: subscription_period && !cart}, 'col-sm-2 padding_left_3 padding_right_0']">
                    <img class="cart_item_img" v-bind:src="item.img_url">
                    <span v-if="item.total_items" class="bundle_items">{{item.total_items}}&nbsp{{$t('cart.items')}}</span>
                  </div>
                  <div v-bind:class="[subscription_period && cart ? 'col-sm-6 padding_left_0 padding_right_0' : subscription_period && !cart ? 'col-sm-7 padding_left_0 padding_right_8' : 'col-sm-7 width_57_percent padding_left_10 padding_right_8', 'margin_top_min_3']">
                    <div id="item_title_id" class="cart_item_title font_open_sans_regular_12_dark_gray">{{item.item_title}}</div>
                    <div v-if="item.item_type" class="font_open_sans_regular_12_dark_gray margin_top_5">
                      <label class="cart_item_type margin_bottom_0">{{item.item_type}}</label>
                    </div>
                    <div v-if="item.item_company_name" class="font_open_sans_regular_12_dark_gray margin_top_3">
                      {{item.item_company_name}}
                    </div>
                    <a href="#" @click.prevent="deleteItem(item)">Delete</a>
                    <span v-if="item.item_enrolled" class="font_open_sans_regular_11_red">{{$t('cart.item_enrolled')}}</span>
                    <span v-if="item.item_deleted" class="font_open_sans_regular_11_red">{{$t('cart.item_deleted')}}</span>
                  </div>
                  <div v-bind:class="[subscription_period ? cart ? 'col-sm-3 padding_right_0 margin_left_10' :'col-sm-3 padding_right_0' : !cart ? 'col-sm-3 padding_right_10' : 'width_19_percent col-sm-2 padding_right_0', 'vertical_align_text_top text_align_right font_open_sans_regular_12_dark_gray padding_left_0 padding_right_0']">
                    <span>{{item.item_price}}</span>
                    <span v-if="item.per_period">&nbsp{{item.per_period}}</span>
                  </div>
                </div>
              </div>
            </div>`,
  props: {
    cart: Boolean,
    item: Object,
    subscription_period: Boolean,
    cart_empty: Boolean,
    cart_item_count: Number,
    cart_item_total: String,
    cart_items_list: Array
  },
  mounted: function() {
    setTimeout(function() {
      initTrunk8Faded(".cart_item_title", false, 2);
    }, 100);
  },
  methods: {
    deleteItem (item) {
      var current_id = item.id;
      var price = parseFloat(item.item_price.slice(1).replace(/,/g, ""))
      var total_price = parseFloat(this.$root.$children[0].cart_item_total.slice(1).replace(/,/g, ""));
      var current_total_price = total_price-price;
      this.$http.delete('/cart.json',{body: {id : current_id}}).then(response => {
        this.$parent.cart_item_count = response.body.cart_items_count;
        this.$parent.cart_item_total = String(current_total_price);
        if (this.$parent.cart_item_count == 0){
          this.$parent.cart_empty = true;
          jQuery("#badge_cart").addClass("hide");
        }
        else
        document.getElementById("badge_cart").innerHTML = this.$parent.cart_item_count;
        jQuery("#cart_item_"+current_id).addClass("hide");
      });
    }
    }
});

Vue.component('cart-order-summary',{
  template:`<div class="margin_top_11">
              <div class="container-fluid">
                <div class="row font_open_sans_regular_12_dark_gray normal">
                  <div class="col-sm-8 cart_info">
                    {{$t('cart.Total')}}
                  </div>
                  <div class="col-sm-4 cart_info">
                    {{order_summary_details.cart_item_total}}
                  </div>
                  <div class="col-sm-8 cart_info padding_top_13">
                    {{$t('cart.shipping')}}&nbsp&&nbsp{{$t('cart.handling')}}
                  </div>
                  <div class="col-sm-4 cart_info padding_top_13">
                    {{order_summary_details.shipping_and_handling_cost}}
                  </div>
                  <div class="col-sm-8 cart_info padding_top_13">
                    {{$t('cart.total_before_tax')}}
                  </div>
                  <div class="col-sm-4 cart_info padding_top_13">
                    {{order_summary_details.cart_item_total}}
                  </div>
                  <div class="col-sm-8 cart_info padding_top_13">
                    {{$t('cart.estimated_tax')}}
                  </div>
                  <div class="col-sm-4 cart_info padding_top_13">
                    {{order_summary_details.estimated_tax}}
                  </div>
                  <div class="col-sm-8 cart_info padding_top_13">
                    {{$t('cart.less_discounts')}}
                  </div>
                  <div class="col-sm-4 cart_info padding_top_13">
                    {{order_summary_details.less_discounts}}
                  </div>
                  <div class="col-sm-12">
                    <hr v-if="checkout_summary" class="order_summary">
                    <hr v-else class="order_summary_hr">
                  </div>
                  <div class="col-sm-8 cart_info padding_top_10">
                    {{$t('cart.Total')}}
                  </div>
                  <div class="col-sm-4 cart_info padding_top_10">
                    {{order_summary_details.order_total}}
                  </div>
                </div>
              </div>
            </div>`,
  props: {
    order_summary_details: Object,
    checkout_summary: Boolean
  },
});

Vue.component('checkout-popup',{
  template:`<div>
              <checkout_summary v-bind:cart_items_list="cart_items_list" :order_summary_details="order_summary_details" :coupon_code_details="coupon_code_details" :redemptions_url="redemptions_url" :orders_url="orders_url" :invoice_payment_enabled="invoice_payment_enabled" :subscription_period = "subscription_period"></checkout_summary>
              <checkout-creditcard-info class="hide" v-bind:order_summary_details="order_summary_details"></checkout-creditcard-info>
            </div>`,
  props: {
    cart_items_list: Array,
    order_summary_details: Object,
    redemptions_url: String,
    orders_url: String,
    invoice_payment_enabled: Boolean,
    coupon_code_details: Object,
    subscription_period: Boolean
  },
  methods: {
    close_checkout() {
      this.$root.$children[0].checkoutPopup = false;
    }
  }
});

Vue.component('checkout_summary',{
  template:`<div id="checkout_summary">
              <div class="font_open_sans_semibold_12_black margin_left_15 margin_top_13">
                {{$t('cart.checkout_order_summary')}}
              </div>
              <hr v-bind:class="[{width_600: subscription_period}]">
              <div id="checkout_summary_cart_items" v-bind:class="[subscription_period ? 'col-sm-8 padding_right_23' : 'col-sm-7', 'padding_top_2']">
                <cart-item v-if="total_more_than_one" v-for="item in cart_items_list" v-bind:cart="false" :item="item" :subscription_period = "subscription_period"></cart-item>
                <div v-else>{{error_message}}&nbsp<a id="return_to_cart" v-on:click="show_cart">{{$t('cart.return_to_cart')}}</a></div>
              </div>
              <div v-bind:class="[subscription_period ? 'col-sm-4' : 'col-sm-5', 'padding_left_0 padding_right_6']">
                <cart-order-summary v-bind:order_summary_details="order_summary_details" :checkout_summary="false"></cart-order-summary>
              </div>
              <div class="col-sm-12 padding_left_0">
                <hr v-bind:class="[{width_600: subscription_period}, 'margin_top_20']">
              </div>
              <div v-bind:class="[{padding_bottom_8: !invoice_payment_enabled},'col-sm-12 text_align_right margin_top_17']" v-if="total_more_than_one">
                <label class="font_open_sans_regular_12_dark_gray cursor_pointer padding_right_10" v-on:click="continue_shopping">{{$t('cart.continue_shopping')}}</label>
                <button id="next_one" type="submit" v-on:click.once="show_card_info(orders_url)" class="font_open_sans_semibold_10_blue normal btn btn-default btn-round-sm margin_left_10">{{$t('cart.next')}}</button>
              </div>
            </div>`,
  props: {
    cart_items_list: Array,
    order_summary_details: Object,
    redemptions_url: String,
    orders_url: String,
    invoice_payment_enabled: Boolean,
    coupon_code_details: Object,
    subscription_period: Boolean
  },
  data: function() {
    return {
      coupons_details: this.coupon_code_details.coupons_details,
      show_coupon_code_link: false,
      total_more_than_one: true,
      price_changed: false,
      error_message: ''
    }
  },
  mounted: function() {
    var diff = jQuery("#apply_button").width() - jQuery("#apply_discount_code").width()
    if (diff > 0) {
      jQuery("#apply_discount_code").css('margin-left',diff/2);
      jQuery(".note").css('max-width',jQuery("#apply_button").width());
    }
    else {
      jQuery("#apply_discount_code").css('margin-left',0);
      jQuery(".note").css('max-width',jQuery("#apply_discount_code").width());
    }
    jQuery(".discount_code_box").addClass("width_max_content");
    if (this.subscription_period) {
      jQuery("#checkout_popup").css('width',641);
      jQuery("#checkout_popup").css('margin-left',-591);
    }
  },
  methods: {
    show_card_info: function(url) {
      this.$http.post('/orders' + '.json?').then( response => {
        if (response.body.valid == true) {
          if (response.body.zero_amount == true) {
            location.reload();
          }
          else if (response.body.message) {
            this.total_more_than_one = false;
            this.error_message = response.body.message;
          }
          else {
            jQuery("#checkout_summary").addClass("hide");
            jQuery("#checkout_popup").addClass("wepay_checkout");
            jQuery("#checkout-creditcard-info").removeClass("hide");
            if (response.body.wepay_redirect_uri) {
              jQuery(".fullscreen-container").removeClass("hide");
              WePay.iframe_checkout('cart_items', response.body.wepay_redirect_uri);
            }
          }
        }
        else {
          if (typeof response.body.price_changed !== 'undefined') {
            var self = this;
            this.price_changed = true;
            setTimeout(function(){
              self.price_changed = false;
            },2000);
            this.$root.$children[0].cart_items_list = response.body.cart_items.cart_items;
            this.$root.$children[0].order_summary_details = response.body.order_summary_details;
          }
          else {
            this.$root.$children[0].cart_items_list = response.body.cart_items.cart_items;
          }
        }
      });
    },
    apply_coupon: function() {
      var code = jQuery("#coupon_code").val();
      var self = this;
      this.$http.post(this.redemptions_url + '.json?coupon[code]='+code).then(response => {
        if (response.body != false) {
          jQuery("#coupon_code_error").addClass("hide");
          self.coupons_details = response.body.coupon_code_details.coupons_details;
          self.$root.$children[0].order_summary_details = response.body.order_summary_details;
          jQuery('#coupon_code').val('');
        }
        else{
          jQuery("#coupon_code_error").removeClass("hide");
        }
      });
    },
    remove_coupon: function(url,id) {
      var self = this;
      this.$http.delete(url + '.json?id=' + id).then(response => {
        self.coupons_details = response.body.coupon_code_details.coupons_details;
        self.$root.$children[0].order_summary_details = response.body.order_summary_details;
      });
    },
    bill_me_later: function(url) {
      this.$http.post(url + '.json?invoice_payment=true').then(response => {
        if (response.body.message) {
          this.total_more_than_one = false;
        }
        else {
          location.reload();
        }
      });
    },
    continue_shopping: function() {
      this.$root.$children[0].checkoutPopup = false;
      jQuery("html, body").animate({ scrollTop: jQuery("#Others").offset().top }, 2000);
    },
    show_cart: function() {
      this.total_more_than_one = true;
    }
  }
});

Vue.component('checkout-creditcard-info',{
  template:`<div id="checkout-creditcard-info" class="hide">
              <div class="font_open_sans_semibold_12_black margin_left_15 margin_top_13">
                {{$t('cart.checkout_pay_with_credit_card')}}
              </div>
              <hr class="padding_bottom_10 credit_card_info_hr">
              <div class="col-sm-9 " id="cart_items">
              </div>
              <div class="col-sm-3 padding_left_0 padding_right_2">
                <cart-order-summary v-bind:order_summary_details="order_summary_details" :checkout_summary="false"></cart-order-summary>
              </div>
              <div class="col-sm-9 margin_top_10 padding_right_8 padding_bottom_20 margin_left_4">
                <img src="/images/order/learnexa-cc-padlock.png"></img>
                <label class="font_open_sans_regular_12_dark_gray vertical_align_sub padding_left_5">{{$t('cart.your_order_is_safe')}}</label>
                <img src="/images/order/learnexa-cc-cc.png" class="right_float"></img>
              </div>
            </div>`,
  props: {
    order_summary_details: Object
  }
});

Vue.component('BillingDetails', {
  template: `<div class="billing_details" v-if="show_billing_address">
              <h3> {{ $t('cart_new.billing_detail_heading')}} </h3>
              <div class="billing_address_details">
                <div class="form_fields_col_1">
                  <div class="form_group">
                    <label class="required"> {{ $t('cart_new.first_name')}} </label>
                    <input type="select" class="grey_background" v-model="billing_address.first_name"/>
                  </div>
                  <div class="form_group">
                    <label class="required"> {{ $t('cart_new.second_name')}} </label>
                    <input type="select" class="grey_background" v-model="billing_address.last_name"/>
                  </div>
                  <div class="form_group">
                    <label class="required"> {{ $t('cart_new.address1')}} </label>
                    <input type="select" class="grey_background" v-model="billing_address.address1"/>
                  </div>
                  <div class="form_group">
                    <label class="required"> {{ $t('cart_new.address2')}} </label>
                    <input type="select" class="grey_background" v-model="billing_address.address2"/>
                  </div>
                  <div class="form_group">
                    <label class="required"> {{ $t('cart_new.city')}} </label>
                    <input type="select" class="grey_background" v-model="billing_address.city"/>
                  </div>
                  <div class="form_group">
                    <label class="required"> {{ $t('cart_new.country')}} </label>
                    <input type="select" class="grey_background" v-model="billing_address.country"/>
                  </div>
                  <div class="form_group">
                    <label class="required"> {{ $t('cart_new.state')}} </label>
                    <input type="select" class="grey_background" v-model="billing_address.state"/>
                  </div>
                  <div class="form_group">
                    <label class="required"> {{ $t('cart_new.zip_code')}} </label>
                    <input type="select" class="grey_background" v-model="billing_address.postal_code"/>
                  </div>
                  <div class="form_group">
                    <label class="required"> {{ $t('cart_new.phone')}} </label>
                    <input type="select" class="grey_background" v-model="billing_address.phone"/>
                  </div>
                </div>
                <a class="save_changes" @click.prevent="saveAddress">{{ $t('cart_new.save_changes')}}</a>
              </div>
            </div>`,
  props: {
    show_billing_address: Boolean,
    billing_address: Object
  },
  methods: {
    saveAddress() {
      this.$parent.saveAddress()
    }
  }
});

Vue.component('CartListing', {
  template: `<div>
              <h3 class="lxa_main_title_light lxa_text_color_02"> {{ $t('cart_new.cart_listing_heading')}} </h3>
              <div class="cart_listing lxa_border_radius lxa_bg_05 lxa_text_color_03 lxa_font_size_04" v-if="cart_items.length != 0">
                <div class="row mx-0 p-3 heading lxa_bg_04 lxa_text_color_08 lxa_border_radius_tltr lxa_semibold_font">
                  <div class="col-4 pl-0"> {{ $t('cart_new.course_title')}} </div>
                  <div class="col-2 pl-0"> Type </div>
                  <div class="col-2 pl-0"> {{ $t('cart_new.date')}} </div>
                  <div class="col-2 pl-0"> {{ $t('cart_new.price')}} </div>
                  <div class="col-2 px-0">  </div>
                </div>
                <div class="row data py-3 lxa_commom_border_b2" v-for="(purchase,index) in cart_items" :key="'cart-item-'+index">
                  <div class="col-4 pl-0"> {{ purchase.item_title }} </div>
                  <div class="col-2 pl-0"> <span v-if="purchase.item_type == 'course'">ELearning</span><span v-else-if="purchase.item_type == 'certification'">Certification</span><span v-else>Event</span> </div>
                  <div class="col-2 pl-0"> {{purchase.start_date}} </div>
                  <div class="col-2 pl-0"> {{ purchase.item_price }} </div>
                  <div class="col-2 px-0 cart_item_actions"> 
                    <a data-toggle="modal" data-target="#delete_cart_item"> <i data-toggle="tooltip" data-placement="top" title="Delete" class="fad fa-trash-alt lxa_text_color_01"></i> </a>
                    <a @click.prevent="movetoWishlist(purchase,index)"> <span v-show="!purchase.wishlisted"> <i class="far fa-heart move_to_wishlist lxa_text_color_06" data-toggle="tooltip" data-placement="top" title="Move to Wishlist"></i></span><span v-show="purchase.wishlisted"> <i class="fad fa-heart move_to_wishlist lxa_text_color_06" data-toggle="tooltip" data-placement="top" title="Wishlisted"></i></span></a>
                  </div>
                  <cart-item-actions :purchase="purchase"> </cart-item-actions>
                </div>
                <div class="timer text-right mt-4 pb-3 px-3"> 
                  {{ $t('cart_new.cart_time') }} <span class="lxa_text_color_01 lxa_semibold_font">{{ time }}</span> Minutes
                </div>
              </div>
              <div class="empty_cart" v-else=""> {{ $t('cart_new.empty_cart')}} </div>
            </div>`,
  computed: {
    time: function(){
      var min = sec = 0
      if (this.$parent.cart_time > 0) {
        var min = parseInt(this.$parent.cart_time / 60)
        var sec = parseInt(this.$parent.cart_time % 60)
      }
      return String("0" + min).slice(-2) +':'+ String("0" + sec).slice(-2)
    }
  },
  mounted: function(){
    if (this.$parent.cart_time >= 0) {
      const stopCountdown = setInterval(() => {
        this.$parent.cart_time -= 1;
        if (this.$parent.cart_time <= 0) {
          clearInterval(stopCountdown);
          this.clearSession()
        }
      }, 1000);
    }
  },
  props: ['cart_items', 'cart_time'],
  methods: {
    closeModal() {
      $('.light_box_close').click();
    },
    deleteItem(purchase) {
      this.$parent.deleteItem(purchase);
      this.closeModal();
      jQuery('#badge_cart').html(parseInt(jQuery('#badge_cart').html())-1)    
      if (parseInt(jQuery('#badge_cart').html()) == 0) {
        jQuery('#badge_cart').hide()
      }
    },
    clearSession() {
      // this.$http.post('/home/clear_cart_session.json').then(response => {
      //   this.$parent.cart_items = []
      // })
    },
    movetoWishlist(purchase,index) {
      if (purchase.wishlisted) {
        this.$http.delete(purchase.delete_wishlist_url).then(response => {
          purchase.wishlisted = false;
          this.$set(this.cart_items, index,purchase);
          this.$root.popUpMessage('Removed from Wishlist');
        })
      } else {
        this.deleteItem(purchase)
        this.$http.post(purchase.wishlist_url).then(response => {
          this.$root.popUpMessage('Moved to Wishlist');          
        });
      }
    }
  }
});

Vue.component('cart-item-actions', {
  template: `<div class="modal" id="delete_cart_item" role="dialog">
                <div class="modal-xl">
                  <div class="modal-content user_account_setting cart_item_delete">
                    <span class="cart_item_delete_heading text-center lxa_text_color_01 lxa_font_size_08"> {{$t('cart_new.delete_confirm_message')}} </span>
                    <p class="light_box_close" data-dismiss="modal"> <i data-toggle="tooltip" data-placement="top" title="Close" class="fad fa-times lxa_text_color_08 lxa_font_size_06 cursor_pointer"></i></p>
                    <div class="cart_delete_action">
                      <button class="btn lxa_bg_04 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" @click="closeModal()"> Cancel</button>
                      <button class="btn lxa_bg_01 ml-2 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" @click="deleteItem()" > Delete </button>
                      <button v-if="!purchase.wishlisted" class="btn lxa_bg_02 ml-2 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" @click="movetoWishlist()"> Move to Wishlist</button>
                    </div>
                  </div>
                </div>
              </div>`,
  props: ['purchase'],
  methods: {
    closeModal() {
      this.$parent.closeModal()
    },
    deleteItem() {
      this.$parent.deleteItem(this.purchase)
    },
    movetoWishlist() {
      this.$parent.movetoWishlist(this.purchase)
    }
  }
})

Vue.component('CartSummary', {
  template: `<div>
              <h3 class="lxa_main_title_light lxa_text_color_02"> {{ $t('cart_new.summary')}} </h3>
                <div class="cart_summary lxa_border_radius lxa_bg_05 lxa_text_color_03 lxa_font_size_04">
                  <div class="summary_listing p-3">
                    <p> 
                      {{ $t('cart_new.summary_item')}} 
                      <span class="pull-right lxa_semibold_font"> {{ order_summary['cart_item_total'] }} </span>
                    </p>
                    <p> 
                      {{ $t('cart_new.summary_sub_total')}} 
                      <span class="pull-right lxa_semibold_font"> {{ order_summary['cart_item_total'] }} </span>
                    </p>
                    <p> 
                      {{ $t('cart_new.summary_discount')}} 
                      <span class="pull-right lxa_semibold_font"> {{ order_summary['less_discounts'] }} </span>
                    </p>
                    <div class="mt-3 font_barlow_semi_condensed_10_red" v-show="errors.has('coupon_code')">Coupon Code can't be blank</div>
                    <p class="mt-1 mb-4 d-flex align-items-center justify-content-start">
                      <input type="text" v-validate="'required'" data-vv-validate-on="none" name="coupon_code" placeholder="Apply Discount/Coupon Code" class="discount_box primary_bgcolor lxa_btn_txt_01 lxa_border_radius lxa_font_size_03 mr-3" v-model="coupon_code">
                      <a class="discount_apply btn lxa_bg_01 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase ml-auto" @click.prevent="applyDiscount()">Apply</a>
                    </p>
                    <div class="coupon_codes">
                      <div v-for="(code,index) in coupon_details.coupons_details" :key="'code_'+index">
                        {{code.code}}&nbsp{{code.discount_percent}}&nbspDiscount&nbsp|&nbsp<span class="lxa_text_color_06 cursor_pointer" v-on:click="remove_coupon(code.remove_url)">Remove</span>
                      </div>
                    </div>
                    <p class="discount_area d-none"> 
                      <span class="lxa_text_color_01">{{ $t('cart_new.summary_tax_label')}}</span>
                      <span class="pull-right lxa_semibold_font"> {{ order_summary['estimated_tax'] }} </span>
                    </p>
                    <textarea maxlength="100" placeholder="Order Comments. Use this area for special instruction or questions regarding the order" class="comments_area p-3 primary_bgcolor lxa_border_radius lxa_font_size_04 txtarea-resize"/>
                       <div id="terms_and_condition" class="terms_and_condition custom-controls custom-checkbox">
                          <input id="tc" v-model="checked_tc" class="delivery custom-control-input lxa-bs-checkboxbtn" type="checkbox" value="tc">
                          <label class="custom-control-label" for="tc">
                            <i class="fad fa-stop lxa_icon_size lxa_text_color_01"></i>
                            <i class="fad fa-check-square lxa_icon_size lxa_text_color_01"></i>
                            <span>{{ $t('cart_new.summary_agree')}}</span>
                          </label>
                          <span class="lxa_text_color_01 cursor_pointer" @click="openTermsOfService()"> {{ $t('cart_new.summary_tc')}}</span>
                        </div>
                  </div>
                  <div>
                    <label :class="[{'disabled btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 lxa_semibold_font': !checked_tc}, {'btn lxa_bg_01 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 lxa_semibold_font': checked_tc}, 'click_to_pay mb-0']" @click.prevent="processToPay"> {{ $t('cart_new.cart_summary_click_to_pay')}} {{ order_summary['order_total'] }} </label>
                  </div>
                </div>
              </div>`,
  data() {
    return {
      coupon_code: '',
      checked_tc: false
    }
  },
  props: {
    order_summary: Object,
    coupon_details: Object
  },
  methods: {
    processToPay(){
      this.$parent.processToPay()
    },
    openTermsOfService(){
      window.open("/terms-of-service","_blank")
    },
    applyDiscount() {
      var self = this
      var data = { coupon: {code: this.coupon_code }}
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.post('/redemptions.json', data).then(response => { 
            response = response.body
            if (response) {
              self.$parent.order_summary = response['purchases']['order_summary_details']
              self.$parent.coupon_details = response['purchases']['coupon_code_details']
              this.$root.popUpMessage('Coupon Applied');
              if (response['purchases']['coupon_code_details']["coupons_details"].length > 0) {
                this.coupon_code = response['purchases']['coupon_code_details']["coupons_details"][0]['code']
              }
            } else {
              this.$root.popUpMessage(this.coupon_code + ' is not a valid code for the item(s) in your cart');
              this.coupon_code = ''
            } 
          });
        }
      });
    },
    remove_coupon: function(url) {
      var self = this;
      this.$http.delete(url+'.json?').then(response => {
        response = response.body
        self.$parent.order_summary = response['purchases']['order_summary_details']
        self.$parent.coupon_details = response['purchases']['coupon_code_details']
        if (response['purchases']['coupon_code_details']["coupons_details"].length > 0) {
          this.coupon_code = response['purchases']['coupon_code_details']["coupons_details"][0]['code']
        }
      });
    },
  }
});

Vue.component('PaymentDetails', {
  template: `<div v-if="show_payment_details" style="margin-top: 50px;">
              <h3 class="lxa_main_title_light lxa_text_color_02"> {{ $t('cart_new.payment_detail_heading')}} </h3>
              <div class="payment_details">
                <div class="instruction">
                  <p class="heading"> {{ $t('cart_new.payment_inst_heading')}} </p>
                  <p class="note"> {{ $t('cart_new.payment_inst_note')}}</p>
                </div>
                <div id="payment_options">
                  <div class="row">
                    <div class="col-md-4 zero_padding">
                      <div class='learnexa-custom-checkbox' v-for="option in payment_options">
                        <input class='delivery' type="checkbox" value="option" :id="option" /> 
                        <label :for="option">{{ option }}</label>
                      </div>
                    </div>
                    <div class="col-md-4 zero_padding" >
                      <div class="col-md-12 form_fields_col_1 zero_padding" >
                        <div class="form_group">
                          <label class="required"> {{ $t('cart_new.payment_card_number')}} </label>
                          <input type="text" class="grey_background" placeholder="XXXX-XXXX-XXXX-XXXX"/>
                        </div>
                      </div>
                      <div class="col-md-8 form_fields_col_1 zero_padding">
                        <div class="form_group">
                          <label class="required"> {{ $t('cart_new.payment_expiry')}} </label>
                          <input type="select" class="grey_background"/>
                        </div>
                      </div>
                      <div class="col-md-4 form_fields_col_1 zero_padding padding_left_5" >
                        <div class="form_group">
                          <label class="required"> </label>
                          <input type="select" class="grey_background"/>
                        </div>
                      </div>
                      <div class="col-md-12 form_fields_col_1 zero_padding">
                        <div class="form_group">
                          <label class="required"> {{ $t('cart_new.payment_cvv')}} </label>
                          <input type="select" class="grey_background" placeholder="XXX"/>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 zero_padding" >
                      <div class="col-md-12" style="top: 82%">
                        <div>
                          <a style="color: red; font-size: 16px;">What is CVV ?</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>`,
  data: function() {
    return {
      payment_options: ['Credit Card', 'Check', 'Contract or Invoice', 'Purchase Order', 'Cost Center']
    }
  },
  props: {
    show_payment_details: Boolean
  }
})


Vue.component('payment-status', {
  template: `<div>
              <div class="payment_confirmation">
                <span> <i class="fad fa-thumbs-up"></i> </span>
                <div class="mb-5 mt-4">
                  <p> {{ $t('cart_new.order_ack_1') }} </p>
                  <p> {{ $t('cart_new.order_ack_2') }} </p>
                </div>
              </div>
              <div class="payment_completion_action">
                <!-- <button class="btn btn-success" v-if="purchase_items == 1" @click="continueShopping"> {{ $t('cart_new.launch') }}</button>
                <button class="btn btn-info" v-if="purchase_items == 1" @click="continueShopping"> {{ $t('cart_new.view_detail_page') }}</button> -->
                <button class="btn btn-secondary" @click="continueShopping('my_learning_link')"> {{ $t('cart_new.what_am_doing') }}</button>
                <button class="btn btn-danger" @click="getOrderDetails" data-toggle="modal" data-target="#receipt_modal">{{ $t('cart_new.view_invoice') }}</button>
                <button class="btn btn-primary" @click="continueShopping('catalog_link')">{{ $t('cart_new.continue_shopping') }}</button>
              </div>

              <div class="modal" id="receipt_modal" role="dialog">
                <div class="modal-dialog modal-xl" style="width: 100%;">
                  <div class="modal-content">
                    <ItemDetails :item="currentItem" v-if="currentItem.length != 0"/>
                  </div>
                </div>
              </div>

            </div>`,
  props: {
    site_admin: Boolean,
    logged_in: Boolean
  },
  data() {
    return {
      purchase_items: 0,
      order: {},
      currentItem:[]
    }
  },
  mounted: function() {
    this.$http.get('/home/order_info').then(function(response) {
      this.order = response.body.order
      this.purchase_items = response.body.purchase_items   
    });
  },
  methods: {
    continueShopping(id) {
      // window.location.href = '/';
      jQuery('#'+id).click();
    },
    getOrderDetails() {
      this.$http.get('/get_order_details/'+this.order.id).then(function(response) {
        this.currentItem = response.body
      })
    }
  }
})