<template>
  <div>
    <p class="light_box_close margin_0"  @click="$emit('close')"> <i class="fad fa-times lxa_text_color_08 lxa_font_size_06 cursor_pointer"></i></p>
    <div class="vue-dialog-content">
      <p>{{ text }}</p>
    </div>
    <div class="vue-dialog-buttons-message" style="display: none;">
      <button id="popup_close_button" class="vue-dialog-button" @click="$emit('close')">Close</button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PopUpMessage',
    props: ["text"],
    mounted() {
      setTimeout("$('#popup_close_button').click();", 1500)
    }
  }
</script>