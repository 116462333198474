items_per_page = function (from_search) {
  var el = jQuery(document);
  var doc_width = from_search ? (el.width()*(90/100)) : el.width();
  var per_page;
  if(doc_width >= 1200) {
    per_page = from_search ? 16 : 8;
  } else if(doc_width >= 992 && doc_width <= 1199) {
    per_page = from_search ? 9 : 4;
  } else if(doc_width >= 768 && doc_width <= 991) {
    per_page = from_search ? 6 : 4;
  } else if(doc_width <= 767) {
    per_page = from_search ? 3 : 2;
  }
  return per_page;
}