<template>
  <div class="row m-0 mt-2 p-0 content_container">
    <div class="row w-100 m-0 p-0" v-if="content_loaded">
      <span id="collect_testimonial" data-toggle="modal" data-target="#collect_testimonial_modal" data-backdrop="static" data-keyboard="false"></span>
      <collect-testimonial :logged_in="logged_in"> </collect-testimonial>
      <div class="col-12 d-flex align-items-start justify-content-start p-0 m-0" id="content-player">
        <all-playlist :active_enrolled_content="active_item.enrollment.id.toString()" :active_content="active_content"> </all-playlist>
        <tile-details-share-popup v-if="shareModalOpen" v-bind:share_popup_id="share_popup_id" :item_share_link="share_product.item_share_link" :title="share_product.title" :shareable_id="share_product.id" share_type_open="invite" :from_content_player="true" :logged_in="logged_in"/>
        <div v-if="show_content_type" class="content_launcher col-3 px-0 py-3 lxa_bg_05 lxa_border_radius_tlbl overflow-hidden">
          <span class="content_title pl-3 lxa_text_color_06 lxa_font_size_08 lxa_light_font"> Content </span>
          <span @click="toggleContentDisplay()" class="close_content_type_list"> 
            <i class="fad fa-chevron-double-left lxa_text_color_06 lxa_font_size_08"></i></span>
          <lxp-content ref="contentSlide" :all_contents="all_contents" :active_content_id="player_details.content_obj.content_id" :percentage="percentage"></lxp-content>
        </div>
        <span @click="toggleContentDisplay()" v-else class="open_content_type_list p-2"> <i class="fad fa-chevron-double-right lxa_text_color_08 lxa_font_size_08"></i></span>
        <div :class="setClass">
          <div :class="ContentTitle">
            <div class="col-md-9 p-0">
              <div class="title lxa_text_color_08 lxa_font_size_08 lxa_light_font mb-2"> {{ title }} </div>
              <div class="description lxa_text_color_08 lxa_font_size_05 lxa_light_font" v-html="body"> </div>
            </div>
            <div class="col-md-3 p-0">
              <div class="d-flex align-items-center justify-content-end">
              <span class="content_action rating" v-if="active_item.rates_count != 0"> <i class="fad fa-star"></i> {{ active_item.rates_count }} </span>
              <span class="cursor_pointer content_action bookmark ml-3" @click="bookmarkItem(active_item)" :title="active_item.bookmarked ? 'Bookmarked' : 'Bookmark'"> <span v-show="!active_item.bookmarked"><i class="fad fa-bookmark fa-swap-opacity lxa_text_color_08 lxa_font_size_05"></i> </span><span v-show="active_item.bookmarked"> <i class="fad fa-bookmark lxa_text_color_08 lxa_font_size_05"></i></span> {{ active_item.bookmarks_count }} </span>
              <span v-if="!modal" class="cursor_pointer content_action playlist ml-2" data-toggle="modal" data-target="#contentPlaylist" title="Add to Playlist"> <i class="fad fa-list-ol lxa_text_color_08 lxa_font_size_05"></i> </span>
              <span class="cursor_pointer content_action share pt-0 pl-0 ml-3" data-toggle="modal" data-target="#share_detail" v-on:click="openSharePopup()" title="Share"><i class="fad fa-share-alt font_barlow_semi_condensed_light_16 lxa_text_color_08 lxa_font_size_05"></i></span>
            </div>
            </div>
          </div>
          <div v-if="!loader">
            <div class="width_100_percent lxa_contentplayer_area  d-flex align-items-end justify-content-start flex-column" v-if="['document', 'video', 'audio', 'tincan_package', 'slideshow', 'weblink'].includes(content_type)">
              <pdf-viewer v-if="showPDF" ref="pdfJS" class="_pt-5 pb-3"></pdf-viewer>
              <div v-else id="content-wrapper" class="width_100_percent _pt-5"></div>
              <span class="player_close my-3 mr-3 btn lxa_bg_04 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" @click="close()"> Done </span>
            </div>
            <div v-else-if="['quiz', 'survey'].includes(content_type)" class="width_100_percent _pt-5 _pb-3 lxa_contentplayer_area  d-flex align-items-center justify-content-start flex-column">
              <quiz-content :active_item="active_item" :quiz_obj="contents[0]" :modal_id="modal_id" v-if="contents.length != 0"></quiz-content>
              <span class="ml-auto  player_close my-3 mr-3 btn lxa_bg_04 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" @click="close()"> Done </span>
            </div>
            <div v-else-if="['webtext'].includes(content_type)" class="width_100_percent _pt-5 _pb-3 lxa_contentplayer_area  d-flex align-items-end justify-content-start flex-column">
              <webtext-component :web_text_obj="contents[0]" :modal_id="modal_id" v-if="contents.length != 0" />
              <span class="player_close my-3 mr-3 btn lxa_bg_04 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" @click="close()"> Done </span>
            </div>
          </div>  
          <div v-show="loader" class="loader_gif">
            <i class="fad fa-spinner fa-spin lxa_font_size_11 lxa_text_color_01"></i>
          </div>
        </div>    
      </div>
    </div>   
    <div v-show="!content_loaded" class="content_loader">
      <i class="fad fa-spinner fa-spin lxa_font_size_11 lxa_text_color_01"></i>
    </div>   
  </div>  
</template>


<script>
  
  import $ from 'jquery';
  import { loadPdfDocument } from './jslib/pdf_viewer'
  import VjsProgressUpdater from 'packs/vjs_progress_updater'
  require('packs/Youtube.js');
  require('packs/Vimeo.js');
  import videojs from 'video.js';
  import 'videojs-markers';
  import 'videojs-markers/dist/videojs.markers.min.css';

  import { addVideoContent, videoCommonParams, toggleContentStrip, toggleFullScreen, initContentPlayerData, close, open, cleanUpContentPlayArea, setVideoInterval, setPosition, bcVideoPlayer, buildVideoParams, addAudioContent, fetchContent } from './logic/lxa'

  import Modal from './Modal'
  import PdfViewer from './PdfViewer'
  import LxpContent from './LxpContent'
  import QuizContent from './QuizContent'
  import WebtextComponent from './WebtextComponent'
  import Comment from './Comment'
  import Note from './Note'
  import {bus} from '../eventBus.js'

  export default {
    name: 'ContentPlayer',
    components: {
      'modal': Modal,
      'pdf-viewer': PdfViewer,
      'lxp-content': LxpContent,
      'quiz-content': QuizContent,
      'webtext-component': WebtextComponent,
      'comment': Comment,
      'note': Note
    },
    props: ['content_type', 'active_item', 'active_content', 'share_product', 'logged_in','modal'],
    data() {
      return initContentPlayerData();
    },
    mounted() {
      var _this = this;
      // jQuery('#content-launch-modal').on('shown.bs.modal', function() {
      // });
      // jQuery('#content-launch-modal').on('hidden.bs.modal', function() {
        // _this.saveProgress(true);
      // });
      setTimeout(function() {
        _this.open(_this);
      } , 500);        
      // setTimeout(function() {
    //    _this.toggleContentDisplay()
    //   }, 5000);
      this.$on('set-contentplayer-data', function(player_details) {
        _this.contents = [player_details.content_obj];
        _this.$parent.content_type = player_details.content_obj.content_type
        _this.title = player_details.product_title
        _this.body = player_details.product_desc
        _this.product_owner = player_details.product_owner
        _this.getCourseDetails()
        _this.playContent(_this.contents[0]);
        _this.content_loaded = true
        _this.loader = false
      });
      this.$on('all-course-content', function(player_details) {
        _this.all_contents = player_details
      });
      this.$root.$on('changeContent', function(content) {
         _this.loader = true
         _this.changeContent(content)
      });
      this.$root.$on('deleteComment', function(comment_id) {
        _this.deleteComment(comment_id)
      });
      this.$root.$on('likeComment', function(comment_id) {
        _this.likeComment(comment_id)
      });
      this.$root.$on('unlikeComment', function(comment_id) {
        _this.unlikeComment(comment_id)
      });
      this.$root.$on('replyComment', function(data) {
        _this.replyComment(data['comment_id'], data['comment'])
      });
      this.$root.$on('goToVideo', function(note_time) {
        _this.goToVideo(note_time)
      })
    },
    updated: function() {
      this.$nextTick(() => {
        try {
          $('#lxpmulti-lession-content-title .lxpfilterscroll').remove();
        } catch (e) {
          console.log(e, e.stack);
        }
      })
    },
    methods: {
      bookmarkItem(item) {
        if (item.bookmarked) {
          this.$modal.show('dialog', {
            text: 'Are you sure you want to remove this content from bookmark?',
            buttons: [{
                title: 'Cancel',
                handler: () => {
                  this.$modal.hide('dialog')
                }
              },
              {
                title: 'Yes',
                handler: () => {
                  this.$http.delete(item.bookmark_cancel_url).then(function(response) {
                    this.$parent.active_item.bookmarks_count = this.active_item.bookmarks_count - 1
                    this.$root.popUpMessage('Bookmark removed');
                    this.$parent.active_item.bookmarked = !this.$parent.active_item.bookmarked
                  })
                  this.$modal.hide('dialog')
                }
              }
            ]
          })
        } else {
          this.$http.post(item.bookmarks_url).then(function(response) {
            this.$parent.active_item.bookmark_cancel_url = response.body.bookmark_cancel_url
            this.$parent.active_item.bookmarks_count = this.active_item.bookmarks_count + 1
            this.$root.popUpMessage('Bookmarked');
              this.$parent.active_item.bookmarked = !this.$parent.active_item.bookmarked
          })
        }
      },
      openSharePopup: function() {
        // this.shareModalOpen = true
        if(this.modal) {
          var self = this.$parent.$parent.$parent
        } else {
          var self = this.$parent.$parent  
        }
        self.shareModalOpen = true
      },
      toggleContentDisplay() {
        this.show_content_type = !this.show_content_type
      },
      goToVideo(note_time) {
        var myPlayer = videojs(this.videoOptions.videojsId);
        myPlayer.currentTime(note_time);
        myPlayer.play();
      },
      fillStar(count) {
        for (var i = 1; i <= count; i++) {
          document.querySelectorAll("#star_"+i)[0].classList.add('star_hover')
        }
      },
      removeFillStar(count) {
         document.querySelectorAll("#star_"+count)[0].classList.remove('star_hover')
      },
      selectedRating(rating) {
        this.selected_rating = rating
        for (var i = 1; i <= 5; i++) {
          document.querySelectorAll("#star_"+i)[0].classList.remove('filled')
        }
        for (var i = 1; i <= rating; i++) {
          document.querySelectorAll("#star_"+i)[0].classList.add('filled')
        }
      },
      addNewComment() {
        var _this = this
        var data = {'commentable_type': this.active_item.item_type, 'commentable_id': this.active_item.id, 'comment': this.new_comment, 'rating': this.selected_rating}
        this.$http.post('/comments', data).then(function(response) {
          _this.getCourseDetails()
        });
        this.new_comment = ''
      },
      replyComment(comment_id, comment) {
        var _this = this
        var data = {'commentable_type': 'Comment', 'commentable_id': comment_id, 'comment': comment}
        this.$http.post('/comments', data).then(function(response) {
          _this.getCourseDetails()
        });
      },
      handleImageUpload(event) {
        const files = event.target.files
        const formData = new FormData()
        formData.append('myFile', files[0])
        formData.append('associate_type', 'Course')
        formData.append('associate_id', this.active_item.id)
        var _this = this
        this.$http.post('/my_learnings/drop_boxes', formData).then(response => {
          _this.getCourseDetails()
        })
      },
      likeComment(comment_id) {
        var _this = this
        var data = {'likeable_id': comment_id, 'likeable_type': 'Comment'}
        this.$http.post('/likes', data).then(function(response) {
          _this.getCourseDetails()
        });
      },
      deleteComment(comment_id) {
        var _this = this
        this.$http.delete('/comments/'+comment_id).then(function(response) {
          _this.getCourseDetails()
        });
      },
      unlikeComment(liked_id) {
        var _this = this
        this.$http.delete('/likes/'+liked_id).then(function(response) {
          _this.getCourseDetails()
        });
      },
      getCourseDetails() {
        var _this = this
        _this.$http.get('/get_course_details/'+this.active_item.item_type+'/'+this.active_item.id).then(function(response) {
          _this.participants = response.body.participants
          _this.old_files = response.body.files
          _this.old_comments = response.body.comments
          _this.old_notes = response.body.notes
        });
      },
      close() {
        // close(this);
        var content = this.contents[0]
        if (['video', 'audio'].includes(content.content_type)) {
          var myPlayer = null
          if(document.getElementById(this.audioOptions.videojsId) != null) {
            myPlayer = videojs(this.audioOptions.videojsId);
            myPlayer.pause();
          }
          if(document.getElementById(this.videoOptions.videojsId) != null) {
            myPlayer = videojs(this.videoOptions.videojsId);
            myPlayer.pause();
          }
          new VjsProgressUpdater({ 
            player: myPlayer,
            progress_percent: content.progress_percent,
            progress_update_url: content.progress_update_url
          })
        }
        this.$parent.$parent.$parent.launch_item = false
        jQuery("#playlist_content_player_modal").modal('hide');
        this.$http.post('/update_user_feedback').then(function (response) {
          this.$children[0].collect_testimonials();
          $('#collect_testimonial').click();
          bus.$emit('enrollment',true);
          // this.content_loaded = false
        });
        // var current_content_index = this.all_contents.indexOf(this.active_content)
        // var next_content = this.all_contents[current_content_index+1]
        // if (next_content != undefined) {
        //     var refresh_content_url = this.active_content.refresh_content_progress_url;
        //     if (refresh_content_url != '') {
        //                 this.$http.get(refresh_content_url).then(function (response) {
        //       this.all_contents[current_content_index].progress_percent = response.body.progress_percent
        //     });
        //     }
        //     this.loading = true
        //   this.changeContent(next_content)
        //   this.$parent.$parent.active_content = next_content
        // } else {
        //   var next_cont  = this.all_contents.find(ele => ele.progress_percent < 100);
        //   if (typeof next_cont !== 'undefined' && next_cont !== this.active_content) {
        //       this.loading = true
        //       var refresh_content_url = this.active_content.refresh_content_progress_url;
        //       if (refresh_content_url != '') {
        //                   this.$http.get(refresh_content_url).then(function (response) {
        //         this.all_contents[current_content_index].progress_percent = response.body.progress_percent
        //       });
        //       }
        //                 jQuery("#content_details_"+next_cont.content_id).click();
        //   } else {
        //       jQuery("#playlist_content_player_modal").modal('hide');
        //                 this.$http.post('/update_user_feedback').then(function (response) {
        //       this.$children[0].collect_testimonials();
        //       $('#collect_testimonial').click();
        //       bus.$emit('enrollment',true);
        //       // this.content_loaded = false
        //     });
        //     this.$parent.$parent.launch_item = false
        //     //$('#my_learning_link').click();
        //   }
        // }
      },
      open() {
        open(this);
        this.$parent.$parent.active_content = this.all_contents[0]
      },
      playContent: function(content) {
        var _this = this;
        var data = cleanUpContentPlayArea(this);
        // setCurrentContent(content, index);
        [this.videos, this.audios, this.contentLessons, this.showPDF, this.showSurvey, this.showAssessment] = [data.videos, data.audios, data.contentLessons, data.showPDF, data.showSurvey, data.showAssessment]
        _this.$nextTick(function() {
          try {
            switch (content.content_type) {
              case "document":
                if (content.secure_url.includes('.pdf')) {
                  _this.playPDFContent(content);
                } else {
                  _this.playKCContent(content, true);
                }
                break;
              case "video": case "weblink": case 'audio':
                if (content.audio) {
                  _this.playAudioContent(content);
                } else {
                  _this.playVideoContent(content);
                }
                break;
              default:
                // This should deal with all other KC types txt, doc(x),
                // ppt(x), pps(x) xls(x), png, jpg, jpeg, gif
                // files.
                _this.playKCContent(content, true);
                break;
            }
          } catch (e) {
            console.log(e, e.stack);
          }
        });
      },
      playVideoContent: function(content) {
        try {
          this.interval = setVideoInterval(this, "video")
          var position = this.notes_time > 0 ? this.notes_time : setPosition(content)
          position = position == null ? content.start_from : position
          var [video, isBCVideo, playId, match] = buildVideoParams(content.src, false, position, content)
          video = videoCommonParams(video, this.videoOptions)
          this.$nextTick(function () {
            this.videos = [video];
            var added = addVideoContent(video);
            if(added) {
              try {
                console.log(isBCVideo)
                // if(!isBCVideo) {
                  videojs(this.videoOptions.videojsId, video).ready(function () {
                    $(".content-view-area").addClass('video');
                    $("#content-wrapper").addClass('d-flex');
                    $("#content-wrapper .video-js").addClass('m-auto');
                    this.src(video);
                    this.on('loadedmetadata', function() {
                      if(!this.hasClass('progress-seeked')) {
                        this.currentTime(video.position);
                        this.addClass('progress-seeked');
                        // this.play();
                      }
                    });
                    var myPlayer = this;
                    myPlayer.on("pause", function () {
                       $('.vjs-big-play-button').css('display', 'block')
                    });
                    myPlayer.on("play", function () {
                       $('.vjs-big-play-button').css('display', 'none')
                    });
                    new VjsProgressUpdater({ 
                      player: this,
                      progress_percent: content.progress_percent,
                      progress_update_url: content.progress_update_url
                    });
                    if(content.notes.length > 0)
                      var markers_var = content.notes.map((element)=>{ 
                          return {time: element.time,text: element.notes}
                        });
                      this.markers({
                         markerTip:{
                            display: true,
                            text: function(marker) {
                               return marker.text;
                            }
                         },
                         markers: markers_var
                      });

                  });
                // } else {
                //  bcVideoPlayer(match, playId, video)
                // }
              } catch (e) {
                console.log('Exception nextTick in play video content!', e, e.stack);
              }
            }
          });
        } catch (e) {
          console.log('Exception in play video content!', e, e.stack);
        }
      },
      playPDFContent: function(content) {
        this.showPDF = true
        this.$nextTick(function () {
          try {
            loadPdfDocument(content.secure_url)
          } catch (e) {
            console.log('Exception playPDFContent $nextTick!', e, e.stack);
          }
        });
      },
      playKCContent: function(content, uploaded) {
        try {
          clearInterval(this.interval);
          this.interval = setVideoInterval(this, "WebContents")
          // Create iframe and render url content
          this.$nextTick(function () {
            try {
              var contentURL = ''
              if (content.activity_path != null) { 
                contentURL = content.activity_path
              } else {
                contentURL = content.secure_url 
              }
              if (content.content_type == 'slideshow' && !(String(content.document.uploaded_data_content_type).includes('image'))) {
                if (String(content.document.uploaded_data_content_type).includes('opendocument')) {
                  contentURL = "/ViewerJS/#.." + contentURL
                } else {
                  contentURL = content.app_url + contentURL
                  contentURL = "https://docs.google.com/gview?url="+contentURL+"&embedded=true"
                }
              }
              var iframe = $('<iframe />', {
                "class": "url-content-frame modal_content_height",
                src: contentURL,
                id: "url-content",
                frameborder: 0,
                style: "height: 500; min-width: 100% !important; "
              });
              iframe.appendTo($('#content-wrapper'));
              iframe.onload = function() {
                iframe[0].contentDocument.body.querySelectorAll('img')[0].style.width = '100%'
              }
            } catch (e) {
              console.log('Exception playKCContent $nextTick!', e, e.stack);
            }

          });

        } catch (e) {
          // TODO: handle exception
          console.log(e, e.stack);
        }
      },
      playAudioContent: function(content) {
        try {
          this.interval = setVideoInterval(this, "Audio")
          var position = setPosition(content)
          position = position == null ? content.start_from : position
          var audio = {
            id: this.audioOptions.videojsId,
            type: "audio/mp3",
            class: "video-js vjs-default-skin embed-responsive-item_ vjs-16-9",
            controls: true,
            poster: "/favicon.ico",
            position: position,
            // src: content.url
          };
          this.audios = [audio];
          addAudioContent(audio);
          // playAudioContent(this, audio, content.url);
          videojs(this.audioOptions.videojsId).ready(function() {
            audio['src'] = content.src;
            this.src(audio);
            this.on('loadedmetadata', function() {
              if (!this.hasClass('progress-seeked')) {
                this.currentTime(audio.position);
                this.addClass('progress-seeked');
                this.play();
              }
            });
            var myPlayer = this;
            myPlayer.on("pause", function () {
               $('.vjs-big-play-button').css('display', 'block')
            });
            myPlayer.on("play", function () {
               $('.vjs-big-play-button').css('display', 'none')
            });
            new VjsProgressUpdater({ 
              player: this,
              progress_percent: content.progress_percent,
              progress_update_url: content.progress_update_url
            });
          });
        } catch (e) {
          console.log('Exception in play audio content!', e, e.stack);
        }
      },
      toggleContentStrip: function() {
        toggleContentStrip()
      },
      toggleFullScreen: function() {
        toggleFullScreen(this)
      },
      saveProgress(closeModal) {
        if((this.videos.length > 0 || this.audios.length > 0)){
          if(document.getElementById(this.audioOptions.videojsId) != null) {
            videojs(this.audioOptions.videojsId).dispose();
          }
          if(document.getElementById(this.videoOptions.videojsId) != null) {
            videojs(this.videoOptions.videojsId).dispose();
          }
        }
        this.contents = []
        this.showPDF = false;
        this.$parent.content_type = null
        this.$children[0].opened = false;
        this.all_contents = []
        this.fullscreen = false
      },
      changeContent(content) {
        if((this.videos.length > 0 || this.audios.length > 0)){
          if(document.getElementById(this.audioOptions.videojsId) != null) {
            videojs(this.audioOptions.videojsId).dispose();
          }
          if(document.getElementById(this.videoOptions.videojsId) != null) {
            videojs(this.videoOptions.videojsId).dispose();
          }
        }
        this.showPDF = false;
        fetchContent(this, content.content_id)
      }
    },
    computed: {
      setClass() {
        return this.show_content_type ? 'col-9 float_right p-0 content_body lxa_border_radius_trbr lxa_bg_03' : 'col-12 content_body p-0 lxa_border_radius lxa_bg_03'
      },
      ContentTitle() {
        var common_class = 'row m-0 content_details'
        return this.show_content_type ? ("p-3 " + common_class) : ("pl-5 py-3 pr-3 " + common_class)
      },
      percentage() {
        return this.active_item.enrollment.progress_percent
      }
    },
    beforeDestroy() {
      if (['video', 'audio', 'weblink'].includes(this.content_type)) {
        var content = this.contents[0]
        if (content.audio) {
          var myPlayer = videojs('audio-content');
        } else {
          var myPlayer = videojs('video-content');
        }
        myPlayer.dispose();
      }
    },
    watch: {
      shareModalOpen() {
        if (!this.shareModalOpen) {
          $('.newTagsList').find('li.newTagItem').remove();
        }
      }
    }
  }
</script>