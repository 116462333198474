<template>
	<div class="answer_container">
    <ul class="very_large">
      <li class="multiline_li">
        <div class="textarea">
          <textarea v-if="!have_response" v-bind:id="'responses_textarea_'+question_id+'_'" v-bind:name="'responses[textarea]['+question_id+'][]'" placeholder="Please enter your answer here" v-bind:value=response_answer_text></textarea>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
	export default {
		name: 'MultilineType',
		props: {
			item: Array,
			question_id : Number,
			response: Array,
			have_response: Boolean,
			navigate_response: Boolean
		},
		data: function() {
			return{
				response_answer_text: this.navigate_response || this.have_response ? this.response[0].answer_text : ''
			}
		}
	}
</script>