import Vue from 'vue/dist/vue.esm'
const EventBus = new Vue();
import {bus} from './eventBus.js'

Vue.component('catalog-layout', {
  template: `<div :class='[{"col-12 m-0 p-0":(logged_in)},"col-12 scroll_margin_top_100"]' id="catalog_layout">
              <div class="row lxa_mt_60 mx-0">
                <div class="col-sm-6 col-md-6 col-lg-7 mt-2 padding_0"><catalog-header :selected="selected" :menu_filters="menu_filters"/></div>
                <div class="col-sm-6 col-md-6 col-lg-5 pr-0 d-flex lxa-set-height">
                  <filter_and_sort from_section="catalog_items" :selected="selected" :autocomplete_url="autocomplete_url"/>
                  <span v-if="masonary_icon" data-toggle="tooltip" data-placement="top" title="List View" :key="'list_view_image'" class="pt-2 cursor_pointer ml-3" v-on:click="masonry()">
                    <i class="fad fa-list-ul view_image lxa_view_icons" aria-hidden="true"></i>
                  </span>
                  <span v-else :key="'masonary_view_image'" data-toggle="tooltip" data-placement="top" title="Grid View" class="pt-2 cursor_pointer ml-3" v-on:click="list()">
                    <i class="fad fa-th-large view_image lxa_view_icons" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
              <div v-if="loading" class="loader_gif">
                <i class="fad fa-spinner fa-spin lxa_font_size_11 text-danger"></i>
              </div>
              <applied-filter v-bind:AppliedFilters="AppliedFilters" :fromPage="from_page"></applied-filter>
              <div v-show="!loading">  
                <categories-tab v-if="selected=='Category'" :categorylist="items"></categories-tab>
                <available-items-list-1 v-else="" v-for="item in items" :item="item" :selected="selected" :logged_in="logged_in" :masonary_icon="masonary_icon"/>
              </div>
              <tile-details-share-popup v-if="catalogShareModalOpen" v-bind:share_popup_id="share_popup_id" :item_share_link="product.item_share_link" :title="product.title" :shareable_id="product.id" :logged_in="logged_in" share_type_open="invite" from_catalog="true" />
            </div>`,
  data: function() {
    return {
    	items: [],
      selected: 'All',
      menu_filters: [],
      autocomplete_url: '/contents/autocomplete_search',
      masonary_icon: true,
      list_view: false,
      AppliedFilters: [],
      masonry_view: true,
      product: {},
      share_popup_id: '',
      catalogShareModalOpen: false,
      from_page: 'Catalog',
      loading: true
    }
  },
  props: {
    logged_in: Boolean
  },
  mounted: function() {
    this.FetchItems();
    var self = this
    EventBus.$on("share_product_details",this.open);
    EventBus.$on("updateCatalog", function() {
      self.$http.get('/contents.json?from_single_page=true&new_single_page=true&per_page=12&item_type='+this.selected).then(function(response) {
        self.items = response.body['catalog_items'];
      });
    });
    bus.$on("CloseShare", function () {
      self.catalogShareModalOpen = false;
    })
  },
  methods: {
    list: function() {
      this.list_view=false;
      this.masonry_view=true;
      this.masonary_icon=true;
      this.FetchItems();
    },
    masonry: function() {
      this.list_view=true;
      this.masonry_view=false;
      this.masonary_icon=false;
      this.FetchItems();
    },
    FetchItems: function() {
      this.loading = true;
      this.$children[1].requestData();
    },
    open: function(product, share_popup_id, share_modal_open) {
      this.product = product;
      this.share_popup_id = share_popup_id;
      this.catalogShareModalOpen = share_modal_open;
    },
    remove_filter: function(ref,display_id,display_name,filter_type,arr_index) {
      if (filter_type=='search_item') {
        this.$children[1].$children[0][filter_type] = ''
      } else {
        var index = this.$children[1].$children[1].$refs[ref][filter_type].indexOf(display_id)
        if (index !== -1) {
          this.$children[1].$children[1].$refs[ref][filter_type].splice(index, 1);
        }
      }
      this.AppliedFilters.splice(arr_index,1);
      this.$children[1].requestData();
    }
  }
});

Vue.component('catalog-header', {
	template: `<div class="row margin_0 padding_0">
              <div class="col-sm-3 col-md-2 col-lg-2 padding_0 component-header lxa_section_title_light lxa_text_color_01 lxa_font_size_08"> {{this.$root.header_details[3].text}} </div>
              <div class="col-sm-9 col-md-10 col-lg-10 catalog-filters lxa_section_title_light pr-0">
                <div v-for="(link , idx) in menu_filters" class="d-flex align-items-center justify-content-start">
                  <span class="lxa_font_size_05 lxa_text_color_02 lxa_mr20 pb-2 cursor_pointer" :id="link.name" @click="onclick" :class="[{'lxa_text_color_04 lxa_border_b2':selected==link.name},{'mr-0':idx === menu_filters.length - 1}]">
                  <i v-if="link.name=='Recommendation'" class="fad fa-comment-alt lxa_active_icon lxa_font_size_05 mr-1 pe-none"></i>
                  <i v-if="link.name=='Category'" class="fad fa-folders lxa_active_icon lxa_font_size_05 available-item-category-list-1 mr-1 pe-none"></i>
                  <i v-if="link.name=='Wishlist'" class="fad fa-heart lxa_active_icon lxa_font_size_05 fa-swap-opacity mr-1 pe-none"></i>
                  {{link.display_name}}
                 </span>     
                </div>
              </div>
            </div>`,
  props: {
    selected: String,
    menu_filters: Array
  },
  data: function() {
  	return {
    }
  },
  methods: {
    onclick: function(event) {
      this.$parent.items = [];
      this.$parent.selected = event.target.id
      this.$parent.loading = true;
      var params = this.$parent.$children[1].requestParams()
      this.$http.get('/contents.json?'+params).then(function(response) {
        this.$parent.loading = false;
        this.$parent.items = response.body['catalog_items'];
        this.$parent.menu_filters = response.body['catalog_tabs'];
      });
    }
  }
});

Vue.component('categories-tab',{
  props: {
    categorylist: Array,
  },
  template: `<div class="category-tab-data-wrapper col-12 my-3 px-0">
             <div class="row category-wrapper category-wise-data mx-0">
             <div class="col-12 col-sm-6 col-md-3 pl-0"  v-for="(item, index) in grouped_categories" :key="'cat-'+index">
              <ul class="category-item ml-0 pl-0 mb-4">
                <li class="category-item-list" v-for="(category,index) in item" :key="'category-'+index">
                  <div v-if="category.type == 'category'" :data-filter-label="category.category_name" data-filter-name="category" :data-filter-id="'catalog_category_'+category.category_name" v-on:click="fetchCategoryWiseData(category.category_id)" v-bind:class="category.classname">{{category.category_name}}</div>
                  <div v-else v-bind:class="category.classname">{{category.category_name}}</div>
                </li>
              </ul>
             </div>
             </div>
            </div>`,
  data:function(){
    return{
      categorywisedata: [],
      catlist: this.categorylist,
      formatCatList: []
    }
  },
  watch: {
    categorylist(val) {
      this.catlist = val;
    }
  },
  computed : {
    grouped_categories : function(){
      var self = this;
      var categoryList = this.catlist;
      var groupedCollection = {};
      var i;
      var tempStr = '0-' + (categoryList.length - 1);
      for(i=0;i<categoryList.length;i++){
        var firstLetter = categoryList[i][1].charAt(0);
        if(!isNaN(firstLetter)) {
          if(groupedCollection[tempStr] == undefined){
            groupedCollection[tempStr] = [];
          }
          groupedCollection[tempStr].push(categoryList[i]);
        } else {
          firstLetter = firstLetter.toUpperCase();
          if(groupedCollection[firstLetter] == undefined){
            groupedCollection[firstLetter] = [];
          }
          groupedCollection[firstLetter].push(categoryList[i]);
        }
      }
       this.catlist = groupedCollection;
      this.formatCatList = [];
      Object.keys(groupedCollection).forEach(function(key, index){
        var groupLength = groupedCollection[key].length;
        var obj = {"category_name": key, "classname": 'mb-2 font-weight-bold font_barlow_semi_condensed_light_18 lxa_text_color_01', "type":'label'};
        self.formatCatList.push(obj);
        var self2 = self;
        groupedCollection[key].forEach(function(item, index) {
          var marginClass = '';
          if(index == groupLength-1) {
            marginClass = 'category-margin-bottom';
          }
          var listObj = {"category_name": item[1],"category_id": item[0], "classname": 'mb-1 cursor_pointer font_barlow_semi_condensed_medium_14 '+marginClass, "type":'category'};
          self2.formatCatList.push(listObj);
        });
      });
        return  this.formatCatList.reduce(function(groups, category){
        var firstLetter = category.category_name.charAt(0);
        if(!isNaN(firstLetter)) {
           if(groups[tempStr] == undefined){
            groups[tempStr] = [];
          }
          groups[tempStr].push(category);
        } else {
          firstLetter = firstLetter.toUpperCase();
          if (!groups[firstLetter]){
            groups[firstLetter] = [];
           }
        var entry = groups[firstLetter];
        entry.push(category);
        }
        return groups;
      }, {});
    }
  },
  methods: {
    fetchCategoryWiseData: function(cat_name) {
      var self = this;
      var params = this.$parent.$children[1].requestParams(1,'All',cat_name)
      this.$http.get('/contents.json?'+params).then(function(response) {
        self.$parent.items = response.body['catalog_items'];
        self.$parent.menu_filters = response.body['catalog_tabs'];
        self.$parent.selected = 'All';
      });
    },
  }
});

Vue.component('available-items-list-1', {
  template: `<div class="catalog-body mt-3"> 
              <p class="category_title" v-if="selected=='Category'"> {{category}} </p>
              <div v-if="masonary_icon">  
                <available-item-category-list-1 v-for="(list,index) in list_arr" :key="index" :list="list" :logged_in="logged_in"/>
              </div>
              <div v-else>
                <available-item-list-view v-for="list in list_arr" :list="list" :logged_in="logged_in"/>
              </div>
              <div v-if="loading" class="text-center mb-2">
                <i class="fad fa-spinner fa-spin lxa_font_size_11 text-danger"></i>
              </div>
              <div v-if="this.current_page < this.total_pages" class="text-center">
                <a class="btn lxa_bg_01 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04" role="button" @click="seeMore"> LOAD MORE </a>
              </div>
            </div>`,
  props: {
    item: Object,
    selected: String,
    logged_in: Boolean,
    masonary_icon: Boolean
  },
  data: function() {
    var obj = Object.values(this.item)[0];
    return {
      list_arr: [this.item],
      current_page: obj.params.current_page,
      total_pages: obj.params.total_pages,
      category: Object.keys(this.item)[0],
      loading: false
    }
  },
  watch: {
    item() {
      var obj = Object.values(this.item)[0];
      this.list_arr = [this.item];
      this.current_page = obj.params.current_page;
      this.total_pages = obj.params.total_pages;

    },

  },
  methods: {
    seeMore: function() {
      this.loading = true;
      var params = this.$parent.$children[1].requestParams(this.current_page + 1);
      this.$http.get('/contents.json?'+params).then(function(response) {
        var res = response.body['catalog_items'][0];
        if(response.body['catalog_items'].length > 0){
          this.loading = false;
          this.list_arr.push(res);
          this.current_page = Object.values(res)[0].params.current_page;
          this.total_pages = Object.values(res)[0].params.total_pages;
        }
      });
    }
  }
});

Vue.component('available-item-category-list-1', {
  template: `<div class="row m-0">              
              <item-tile :item="item" :index="index" :key="item.item_type+'_'+item.id" v-for="(item,index) in items" :logged_in="logged_in" :from_catalog="true"/>              
             </div>`,
  props: {
    list: Object,
    logged_in: Boolean
  },
  computed: {
    items: function() {
      return Object.values(this.list)[0].items;
    }
  }
});

Vue.component('available-item-list-view', {
  template: `<div class="row" style="margin: 0px;">              
              <list-item-tile v-for="(item,index) in items" :item="item" :index="index" :key="item.item_type+'_'+item.id" :logged_in="logged_in" :from_catalog=true ref="catalog_item"/>              
            </div>`,
  props: {
    list: Object,
    logged_in: Boolean
  },
  computed: {
    items: function() {
      return Object.values(this.list)[0].items;
    }
  }
});

Vue.component('item-bookmark', {
  template: `<span class="bookmark-section pl-3">
              <span v-show="bookmarked" class="cursor_pointer" data-toggle="tooltip" data-placement="top" title="Wishlisted" v-on:click="removeBookmark">    
                <i class="fad fa-heart font_barlow_semi_condensed_light_16" ></i>
              </span>
              <span v-show="!bookmarked" class="cursor_pointer" data-toggle="tooltip" data-placement="top" title="Wishlist" @click="onBookmarkClick">  
                <i data-toggle="tooltip" data-placement="top" title="Wishlist" @mouseover="onMouseOver" @mouseleave="onMouseLeave" class="fal fa-heart font_barlow_semi_condensed_light_16"></i>
              </span>
            </span>`,
  props: {
    bookmarked: Boolean,
    bookmarks_url: String,
    logged_in: Boolean,
    bookmark_cancel_url: String,
    can_be_bookmarked: Boolean,
    list_view: Boolean
  },
  methods: {
    onBookmarkClick: function(event) {
      if (this.logged_in) {
        if (!this.list_view) {
          var self = this.$parent.$parent;
        } else {
          var self = this.$parent;
        }
        this.$http.post(this.bookmarks_url).then(response => {
          self.bookmark_cancel_url = response.body.bookmark_cancel_url
          self.product.bookmark_cancel_url = response.body.bookmark_cancel_url
          self.bookmarked = true
          self.product.bookmarked = true
          this.$root.popUpMessage("Wishlisted Successfully");
        });
      }
    },
    onMouseOver: function(event) {
      if(!jQuery(event.target).hasClass('disabled')) {
        jQuery(event.target).attr('src', '/images/single_page/bookmarked.png');
      }
    },
    onMouseLeave: function(event) {
      if(!jQuery(event.target).hasClass('disabled')) {
        jQuery(event.target).attr('src', '/images/single_page/bookmark.png');
      }
    },
    removeBookmark: function() {
      if (this.logged_in == true) {
        if (!this.list_view) {
          var self = this.$parent.$parent;
        } else {
          var self = this.$parent;
        }
        this.$http.delete(this.bookmark_cancel_url).then(response => {
          self.bookmark_cancel_url = ''
          self.bookmarked = false
          self.product.bookmark_cancel_url = ''
          self.product.bookmarked = false
          this.$root.popUpMessage("Removed Wishlist");
        });
      }
    }
  }
});

Vue.component('catalog-detail', {
  template: `<div class="text-overlay p-2 d-flex flex-column align-items-start justify-content-start" v-on:mouseover="Truncate" v-on:mouseleave="onMouseLeave">
              <div class="d-flex">            
                <i v-if="product.item_type == 'Bundle'" data-toggle="tooltip" data-placement="top" title="Bundle" class="fad fa-file-archive lxa_icon_size lxa_gridview_icons pr-1"></i>
                <i v-else-if="(product.item_type == 'LiveEvent') || (product.item_type == 'InPersonEvent')" data-toggle="tooltip" data-placement="top" title="Event" class="fad fa-calendar-star lxa_icon_size lxa_gridview_icons pr-1"></i>
                <i v-else class="fad fa-laptop lxa_icon_size lxa_gridview_icons pr-1" data-toggle="tooltip" data-placement="top" title="E-learning"></i>
                <span class="lxa_font_size_03 lxa_medium_font item_grid_title">{{product.title}}</span>
              </div>
              <div v-show="description" :key='key' class="description">{{product.description}}</div>
              <div class="footer pt-1 w-100 font_barlow_semi_condensed_light_14"> 
                <span v-if="product.enrollments_count > 0"> {{$tc('item.enrollments',product.enrollments_count, {count: product.enrollments_count})}}  </span>
                <span v-if="product.no_of_comments > 0" class="pl-2"> {{$tc('item.comments', product.no_of_comments, {count: product.no_of_comments})}}</span>
                <span class="float-right" v-show="!description&&(product.average_rating > 0)">
                  <span data-toggle="tooltip" data-placement="top" title="Rating">
                    <i class="fad fa-star font_barlow_semi_condensed_light_16"></i>
                  </span>
                  <span class="pl-1"> 
                    {{ product.average_rating }} 
                  </span>
                </span>  
              </div>

              <div class="w-100 lxa-catquick-link-wrapper flex-column align-items-start justify-content-start mt-auto">
              
              <div class="quick-link w-100 mt-2 d-flex align-itemts-center justify-content-start">
                <span class="cursor_pointer" v-on:click.once="add_to_my_learning" data-toggle="tooltip" data-placement="top" title="Enroll"><i class="fad fa-money-check-edit text-white font_barlow_semi_condensed_light_16"></i></span>
                <item-bookmark v-if="logged_in" :bookmarked="product.bookmarked" :bookmarks_url="product.bookmarks_url" :logged_in="logged_in" :bookmark_cancel_url="product.bookmark_cancel_url" :can_be_bookmarked="product.can_be_bookmarked" :list_view="false"/>
                <span v-if="logged_in" class="Share" data-toggle="tooltip" data-placement="top" title="Share">  
                  <span class="cursor_pointer share-section pl-3" data-toggle="modal" data-target="#share_detail" v-on:click="openSharePopup()"><i data-toggle="tooltip" data-placement="top" title="Share" class="fad fa-share-alt font_barlow_semi_condensed_light_16"></i></span>
                </span>  
                <span data-toggle="tooltip" data-placement="bottom" title="More" class="item-more-option pl-3 cursor_pointer" v-on:click="show_details"><i data-toggle="tooltip" data-placement="top" title="More" class="fad fa-ellipsis-h font_barlow_semi_condensed_light_16"></i></span>
                <span v-show="description&&(product.average_rating > 0)" class="ml-auto float-right">
                  <span class="ml-auto pl-1 float-right"> 
                    {{ product.average_rating }} 
                  </span>
                  <i class="fad fa-star font_barlow_semi_condensed_light_16"></i>
                </span>
              </div>
              </div>
            </div>`,
  props: {
    logged_in: Boolean,
    product: Object,
    share_popup_id: String
  },
  data:function(){
    return {
      description: false,
      key: this.product.item_type + '_'+this.product.id
    }
  },
  mounted: function() {
    ellipsis('.description', 2);
    ellipsis('.item_grid_title', 1);
  }, 
  methods: {
    add_to_my_learning: function() {
      if (this.logged_in) {
        this.$parent.add_to_library()
      } else {
        window.scrollTo(0, 0);
        $('#login_modal').modal('show'); 
      }
    },
    openSharePopup: function() {
      EventBus.$emit("share_product_details",this.product, this.share_popup_id, true);
    },
    show_details: function() {
      this.$parent.show_details();
    },
    Truncate: function() {
      ellipsis('.description', 2);
      this.description = true
    },
    onMouseLeave: function() {
      this.description = false;
    }
  }
})

Vue.component('filter_and_sort', {
  template: `<div class="filters_and_sorting_row w-100">
              <div class="row filter_sort_heading mx-0 position-relative">
                <div class="col-12 filters px-0 d-flex justify-content-end">
                  <search-section :ref="from_section+'_search'" :selected="selected" :from_filter_id="from_section" :autocomplete_url="autocomplete_url" /> 
                  <div class="catalog_filters mb-0 d-flex align-items-start justify-content-start" :class="(from_section =='all_items')? '':''">
                    <div class="lxa_filter_type" :class='[{"active_tab": show_filter},{"background_color_gray": (!show_filter && from_section != "catalog_items")},"filter_area mr-3"]' v-click-outside="filterClickOutside" @click="showFilters"> <i class="fad fa-sliders-h lxa_filter_icon lxa_font_size_04"></i><span class="ml-2 text-uppercase lxa_font_size_03">Filters</span> </div>
                    
                    <div class="lxa_filter_type" :class='[{"active_tab": show_sort},{"background_color_gray": (from_section != "catalog_items")},"sort_area"]' v-click-outside="sortClickOutside" @click="showSortingOptions"> <span class="mr-1 lxa_font_size_03 text-uppercase">Sort</span>
                    <span :key="'lxa-sort-up'" class="pe-none" v-if="show_sort">
                    <i class="fad fa-chevron-up lxa_sortby_icon lxa_font_size_04"></i>
                    </span>
                    <span :key="'lxa-sort-down'" class="pe-none" v-else>
                    <i  class="fad fa-chevron-down lxa_sortby_icon lxa_font_size_04"></i>
</span>
                    </div>
                  </div>
                </div>
                <filter-section :show_filter="show_filter" :categories="categories" :from_section="from_section" :content_types="content_types" :product_types="product_types" :language_types="language_types"/>
                <sort-section :from_section="from_section" :show_sort="show_sort"/>
              </div>
            </div>`,
  data: function() {
    return {
      content_types: [],
      available_items_url: '/contents',
      enrolled_items_url: '/my_learnings/upcoming.json',
      show_filter: false,
      show_sort: false,
      sort_params: 'order_by=created_at&order=desc',
      product_types: [],
      categories: [],
      language_types: []
    }
  },
  props: {
    from_section: String,
    autocomplete_url: String,
    selected: String
  },
  methods: {
    filterClickOutside: function(e) {
      if(jQuery(e.target).closest('#filterId').length < 1) {
        this.show_filter = false;
      }
    },
    sortClickOutside: function(e) {
      if(jQuery(e.target).closest('#sortId').length < 1) {
        this.show_sort = false;
      }
    },
    showFilters() {
      this.show_filter = !this.show_filter
      if (this.show_sort) {
        this.show_sort = !this.show_sort
      }
    },
    SearchText: function() {
      $("#search_icon_id").trigger("click");
    },
    showSortingOptions() {
      this.show_sort = !this.show_sort
      if (this.show_filter) {
        this.show_filter = !this.show_filter  
      }
    },
    clearFilter(){
      $('.sorting_list li').removeClass('active')
      if (this.from_section == 'catalog_items') {
        this.$children[1].$refs.priceRange.rangeValue[0] = 0;
        this.$children[1].$refs.priceRange.rangeValue[1] = 5000;
        this.$children[1].$refs.ratingFilter.selectedRating = [];
        this.$children[1].$refs.priceRange.selectedPricingType = [];
        this.$children[1].$refs.deliveryType.selectedDeliveryType = [];
        this.$children[1].$refs.durationFilter.selectedDurationType = [];
      } 
      this.$children[0].search_item = ''
      this.$children[1].$refs.productType.selectedProductType = []
      this.$children[1].$refs.categoryFilter.selectedCategory = [];
      this.$children[1].$refs.dateFilter.startDate = this.$children[1].$refs.dateFilter.endDate = ''
      this.$children[1].$refs.languageType.selectedLanguageType = []
    },
    getSortOrder(type) {
      switch(type) {
      case "title_asc":
        this.sort_params = 'order_by=title&order=asc';
        break;
      case "title_desc":
        this.sort_params = 'order_by=title&order=desc';
        break;
      case "pricing_H2L":
        this.sort_params = 'order_by=price&order=desc';
        break;
      case "pricing_L2H":
        this.sort_params = 'order_by=price&order=asc';
        break;
      case "popular_items":
        this.sort_params = 'order_by=enrollments_count&order=desc';
        break;
      case "created_at_asc":
        this.sort_params = 'order_by=created_at&order=asc';
        break;
      case "created_at_desc":
        this.sort_params = 'order_by=created_at&order=desc';
        break;
      case "most_completed":
        this.sort_params = 'order_by=completed_enrollments_count&order=desc';
        break;
      }
      this.requestData();
    },
    requestData() {
      this.$parent.loading = true;
      var params = this.requestParams();
      var filter_url
      if (this.from_section == "catalog_items") {
        filter_url = this.available_items_url
        this.$http.get(filter_url+'?'+params).then(function(response) {
          // this.$parent.in_progress_items = response.body.in_progress_items;
          this.$parent.loading = false;
          this.$parent.current_page_count = response.body.current_page;
          this.$parent.total_pages_count = response.body.total_pages;
          this.$parent.next_icon=false;
          this.$parent.prev_icon=false;
          if(this.$parent.total_pages_count > 1) {
            this.$parent.next_icon=true;
            this.$parent.show_more=true;
          }
          this.$parent.sort_and_filter_params = params
          this.$parent.enrolled_params = true;
          this.$parent.items = response.body['catalog_items'];
          this.$parent.menu_filters = response.body['catalog_tabs'];
        });
      }
      else if (this.from_section == "enrolled_items") {
        if (this.selected == 'inprogress') {
          filter_url = this.enrolled_items_url
       } else if (this.selected == 'completed') {
          filter_url = '/my_learnings/completed_items.json'
        } else {
          filter_url = '/my_learnings/upcoming/all_items.json'
        }
        this.$http.get('/my_learnings/upcoming/mylearning_items_count.json?'+params).then(function(response) {
          this.$parent.end_index = my_learning_per_page()/2-1;
          this.$parent.in_progress_items_count = response.body.in_progress_items_count;
          this.$parent.all_items_count = response.body.all_items_count;
          this.$parent.completed_items_count = response.body.completed_items_count;
          if (typeof this.$parent.active_items_count === 'undefined') {
            this.$parent.active_items_count = this.$parent.in_progress_items_count;
          } else {
            if(this.$parent.selected_tab == 'inprogress') {
              this.$parent.active_items_count = this.$parent.in_progress_items_count;
            } else if(this.$parent.selected_tab=='completed') {
              this.$parent.active_items_count = this.$parent.completed_items_count;
            } else {
              this.$parent.active_items_count = this.$parent.all_items_count;
            }
          }
          this.$parent.CarouselIcon()
          this.$parent.loading =false;
        });
        this.$http.get(filter_url+'?'+params).then(function(response) {
          this.$parent.in_progress_items = response.body.in_progress_items;
          this.$parent.item_count = response.body.in_progress_items.length;
          this.$parent.current_page = response.body.current_page;
          this.$parent.total_pages = response.body.total_pages;
          this.$parent.sort_and_filter_params = params
          this.$parent.enrolled_params = true;
        });
      } else {
        this.$parent.$parent.GetAllItems(params);
      }
      if (this.show_filter){
        this.show_filter = !this.show_filter
      }
      if (this.show_sort){
        this.show_sort = !this.show_sort
      }
    },
    requestParams(page = 1,item_type,cat_name) {
      this.$parent.AppliedFilters = []
      var average_rating = []
      var content_type = []
      var price_type = []
      var duration_types = []
      if (this.from_section == 'catalog_items') {
        var price_min = this.$children[1].$refs.priceRange.rangeValue[0];
        var price_max = this.$children[1].$refs.priceRange.rangeValue[1];
        var price_type = this.$children[1].$refs.priceRange.selectedPricingType;
        // this.$parent.AppliedFilters
        if (price_type.length > 0) {
          for (var i = price_type.length - 1; i >= 0; i--) {
            if(price_type[i]) {
              this.$parent.AppliedFilters.push({ref_id: 'priceRange',filter_display_id: 'free',filter_display_name: 'free', filter_type: 'selectedPricingType'})
            } 
          }
        }
        var content_type = this.$children[1].$refs.deliveryType.selectedDeliveryType;
        if (content_type.length > 0) {
          for (var i = content_type.length - 1; i >= 0; i--) {
            this.$parent.AppliedFilters.push({ref_id: 'deliveryType',filter_display_id: content_type[i],filter_display_name: content_type[i], filter_type: 'selectedDeliveryType'})
          }
        }
        var average_rating = this.$children[1].$refs.ratingFilter.selectedRating;
        if (average_rating.length > 0) {
          for (var i = average_rating.length - 1; i >= 0; i--) {
            this.$parent.AppliedFilters.push({ref_id: 'ratingFilter',filter_display_id: average_rating[i],filter_display_name: average_rating[i], filter_type: 'selectedRating'})
          }
        }
        var selected_duration_types = this.$children[1].$refs.durationFilter.selectedDurationType;
        if (selected_duration_types.length > 0) {
          for (var i = selected_duration_types.length - 1; i >= 0; i--) {
            switch(selected_duration_types[i]) {
              case 0:
                duration_types.push([0,15]);
                break;
              case 1:
                duration_types.push([15,30]);
                break;
              case 2:
                duration_types.push([30,45]);
                break;
              case 3:
                duration_types.push([45,60]);
                break;
              case 4:
                duration_types.push([60]);
                break;
            }
          }
        }
      }
      var selected_product_types = this.$children[1].$refs.productType.selectedProductType;
      if (selected_product_types.length > 0) {
        for (var i = selected_product_types.length - 1; i >= 0; i--) {
          this.$parent.AppliedFilters.push({ref_id: 'productType',filter_display_id: selected_product_types[i],filter_display_name: selected_product_types[i], filter_type: 'selectedProductType'})
        }
      }
      var selected_category = this.$children[1].$refs.categoryFilter.selectedCategory;
      var selected_names = this.$children[1].$refs.categoryFilter.selectedNames;
      if (selected_category.length > 0) {
        for (var i = selected_category.length - 1; i >= 0; i--) {
          var filter_display_name = ''
          for (var j = this.categories.length - 1; j >= 0; j--) {
            if (this.categories[j][selected_category[i]]) {
              filter_display_name = this.categories[j][selected_category[i]].split(" (")[0]
            }
          }
          this.$parent.AppliedFilters.push({ref_id: 'categoryFilter',filter_display_id: selected_category[i],filter_display_name: filter_display_name, filter_type: 'selectedCategory'})
        }
      }
      var language_type = this.$children[1].$refs.languageType.selectedLanguageType
      if ((typeof cat_name !== 'undefined')) {
        if (!selected_category.includes(cat_name.toString())) {
          selected_category.push(cat_name.toString())
          var filter_display_name = ''
          for (var j = this.categories.length - 1; j >= 0; j--) {
            if (this.categories[j][cat_name]) {
              filter_display_name = this.categories[j][cat_name].split(" (")[0]
              selected_names.push(filter_display_name)
            }
          }
          this.$parent.AppliedFilters.push({ref_id: 'categoryFilter',filter_display_id: cat_name,filter_display_name: filter_display_name, filter_type: 'selectedCategory'})
        } 
      }

      var start_date = this.$children[1].$refs.dateFilter.startDate;
      var end_date = this.$children[1].$refs.dateFilter.endDate;
      var filters_delivery = content_type.length == 0 ? '': JSON.stringify(content_type);
      var filters_rating = average_rating.length == 0 ? '': JSON.stringify(average_rating);
      var price_type = price_type.length == 0 ? '': JSON.stringify(price_type);
      var product_types = selected_product_types.length == 0 ? '': JSON.stringify(selected_product_types);
      var category = selected_category.length == 0 ? '': JSON.stringify(selected_category);
      var duration_type = duration_types.length == 0 ? '': JSON.stringify(duration_types);
      var bookmarked = (this.$parent.selected == 'Wishlist') ? true : '';
      // var recommended = (this.$parent.selected == 'Recommendation') ? true : '';
      var recommended = ''
      var filters_language = language_type.length == 0 ? '': JSON.stringify(language_type);
      if (this.from_section == "catalog_items") {
        var itemTypes = (typeof item_type === 'undefined') ? this.$parent.selected : item_type;
        var itemPerPage = 12;
        var itemPage = page;
      } else if (this.from_section == "enrolled_items") {
        if (this.$parent.carousel_view) {
          var itemTypes = ""
          var itemPerPage = my_learning_per_page();
          var itemPage = page;
        }
        else if (this.$parent.list_view) {
          var itemTypes = ""
          var itemPerPage = 3
          var itemPage = page;
        }
      } else {
        var itemTypes = ""
        var itemPerPage = 4
        var itemPage = page;
      }

      var params = 'from_single_page=true&new_single_page=true&content_type=' + filters_delivery + '&language_type=' + filters_language + '&duration_type=' + duration_type + '&average_rating=' + filters_rating + '&price_min=' + price_min + '&price_max=' + price_max + '&price_type=' + price_type + '&product_types=' + product_types + '&category='+ category +'&start_date='+start_date +'&end_date='+end_date;
      params += '&page='+itemPage+'&per_page='+itemPerPage+'&item_type='+itemTypes
      params += '&' + this.sort_params +'&bookmarked='+bookmarked +'&recommended='+recommended + '&q='+this.$children[0].search_item
      return params
    },
    FilterItems(){
      var params = '';
      if (this.from_section == "catalog_items") {
        this.catalog_item_type = true;
        params = '&catalog_item_type='+this.catalog_item_type
      }
      else if (this.from_section == "enrolled_items") {
        this.catalog_item_type = false;
        params = '&catalog_item_type='+this.catalog_item_type+'&type='+this.selected;
      }
      this.$http.get('/home/filter_content_for_new_single_page.json?'+params).then(function(response) {
        this.content_types = response.body.content_types;
        this.product_types = response.body.product_types;
        this.categories = response.body.categories;
        this.language_types = response.body.language_types;
      });
    }
  },
  mounted() {
    this.FilterItems();
  }
});

var searchMixins = {
  props: {
    from_filter_id: String,
    autocomplete_url: String,
    selected: String
  },
  data: function(){
    return {
      search_item : '',
      item_type: ''
    }
  },
  mounted: function() {
    var self = this;
    jQuery("#"+ this.from_filter_id + '_search_bar').typeahead({
      ajax: {
        url: self.autocomplete_url+".json",
        triggerLength: 3,
        preDispatch: function (query) {
          return self.getAutoCompleteUrlParam(query);
        }
      },
      onSelect: function(e) {
        var text = e.text;
        self.search_item = text;
        self.onSubmit();
      }
    });
  }
}

Vue.component('search-section', {
  template:`<div class="mr-3 d-flex position-relative">
              <input class="lxa_border_radius_tlbl lxa_padding_l lxa_search_txt_color lxa_font_size_03" type="text" v-bind:id="search_bar_id" :class="[{background_color_gray: from_filter_id != 'catalog_items'},'search-bar search-bar-open lxa_search_txt_color']" autocomplete="off" :placeholder="$t('search.search_str')" v-model="search_item"/>
              <div v-if="search_item.length < 1" class="input-group-append">
                <button v-bind:id="search_icon_id" v-on:click="searchIn" type="button" tabindex="2" :class="[{background_color_gray: from_filter_id != 'catalog_items'},'btn btn-secondary search_box_submit px-2 lxa_border_radius_trbr lxa_padding_lr']"><i class="fad fa-search lxa_search_icon"></i></button>
              </div>
              <div v-else class="input-group-append">
                <button v-bind:id="search_icon_id" v-on:click="searchIn" type="button" tabindex="2" :class="[{background_color_gray: from_filter_id != 'catalog_items'},'btn btn-secondary search_box_submit px-2 lxa_border_radius_trbr lxa_padding_lr']"><i class="fad fa-times lxa_clear_icon"></i></button>
              </div>
            </div>`,
  mixins: [searchMixins],
  data: function() {
    return {
      search_icon_id: this.from_filter_id + '_search_icon',
      search_bar_id: this.from_filter_id + '_search_bar',
      item_type: this.selected,
      show_search: false
    }
  },
  methods: {
    searchOut: function(e) {
      if(jQuery(e.target).closest("#" + this.from_filter_id + '_search_bar').length < 1) {
        jQuery("#"+ this.from_filter_id + '_search_bar').removeClass("search-bar-open");
        jQuery("#"+ this.from_filter_id + '_search_icon').removeClass("search-icon-open");
      }
    },
    getAutoCompleteUrlParam: function(query) {
      var obj = {type: this.item_type, query: query};
      if(this.from_filter_id == "available_items") {
        switch(this.item_type) {
          case this.$t('available_item_filter.featured_items'):
            obj.only_featured = true;
            break;
          case this.$t('available_item_filter.all'):
            obj.without_category = true;
            break;
          default:
            obj.category_id = parseInt(jQuery('#'+this.item_type).attr('data-categoryid'));
            break;
        }
      } else {
        if (this.selected == 'inprogress') {
          obj.in_progress = true;
        } else if(this.selected == 'completed') {
           obj.all_complete = true;
        }
      }
      return obj
    },
    onSubmit: function() {
      if (this.search_item.length > 0) {  
        for(var i = 0; i < this.$parent.$parent.AppliedFilters.length; i++) {
          if (this.$parent.$parent.AppliedFilters[i].filter_type == 'search_item') {
            this.$parent.$parent.AppliedFilters.splice(0,i+1);
            break;
          }
        }
        // if(this.from_filter_id == "enrolled_items") {
        //   this.onEnrolledItemSearch();
        // } else {
        //   this.onAvailableItemSeacrh();
        // }
        this.$parent.requestData()
        this.$parent.$parent.AppliedFilters.push({ref_id: this.from_filter_id+'_search',filter_display_id: this.search_bar_id,filter_display_name: this.search_item, filter_type: 'search_item'})
      }   
    },
    onAvailableItemSeacrh: function() {
      var self = this;
      this.checkItemFilter();
      self.$parent.$parent.items = [];
      showHideLoader('#available_items', true);
      var params = 'new_single_page=true&q='+this.search_item+'&per_page='+items_per_page()+'&item_type='+this.item_type;
      params = params + this.$parent.requestParams();
      if(this.item_type != this.$t('available_item_filter.all')) {
        params += '&others=true';
      }
      if(jQuery('#'+this.item_type).attr('data-categoryid')) {
        params += '&category_id[]='+[jQuery('#'+this.item_type).attr('data-categoryid')];
      }
      jQuery.ajax({
        type: 'GET',
        url: this.autocomplete_url+'.json?'+params,
        success: function(response) {
          self.$parent.$parent.items = response['catalog_items'];
          self.$parent.$parent.menu_filters = response['catalog_tabs'];
          showHideLoader('#available_items', false);
        }
      });
    },
    onEnrolledItemSearch: function(page =1) {
      this.checkItemFilter();
      var self = this;
      if (this.$parent.$parent.carousel_view) {
        var itemTypes = ""
        var itemPerPage = my_learning_per_page();
        var itemPage = page;
      }
      else if (this.$parent.$parent.list_view) {
        var itemTypes = ""
        var itemPerPage = 3
        var itemPage = page;
      }
      var params = '&page='+itemPage+'&per_page='+itemPerPage   
      self.$parent.$parent[this.item_type+'_items'] = [];
      self.$parent.$parent[this.item_type] = false;
      showHideLoader('#enrolled_items', true);
      this.$http.get(this.autocomplete_url+".json?q="+this.search_item + "&type=" +itemTypes + params).then(function(response) {
        this.$parent.$parent.in_progress_items = response.body.in_progress_items;
        this.$parent.$parent.next_icon=false;
        this.$parent.$parent.prev_icon=false;
        if(this.$parent.$parent.in_progress_items.length > 5) {
          this.$parent.$parent.next_icon=true;
          this.$parent.$parent.show_more=true;
        }        
        self.$parent.$parent[self.item_type+'_items'] = response;
        self.$parent.$parent[self.item_type] = true;
        showHideLoader('#enrolled_items', false);
      });       
    },
    checkItemFilter: function() {
      var length = this.$parent.$parent.$children.length;
      var flag = 1;
      for(var i = 0; i< length && flag; i++){
        if (this.$parent.$parent.$children[i][this.from_filter_id+'_active_filter']) {
          this.item_type = this.$parent.$parent.$children[i][this.from_filter_id+'_active_filter'];
          flag = 0;
        }
      }
    },
    searchIn: function(event) {
      this.onSubmit()
      this.show_search = !this.show_search;
    }
  }
});

Vue.component('delivery-type', {
  template: `<div id="delivery-type-container">
              <div class="filter_heading"> {{$t('filter.delivery_type')}} </div>
              <div class="delivery_filter_body">
                <div v-for='item in content_types' class="filter_type_list_body">
                  <div class='learnexa-custom-checkbox'>
                    <input class='delivery' type='checkbox' :id="filter_unique_id + '_' + Object.keys(item)[0]" :value='Object.keys(item)[0]' v-model='selectedDeliveryType'>
                    <label :for="filter_unique_id + '_' + Object.keys(item)[0]">{{Object.values(item)[0]}}</label>
                  </div>
                </div>
              </div>
            </div>`,
  data: function() {
    return {
      selectedDeliveryType: []
    }
  },
  props: {
    content_types: Array,
    filter_unique_id: String
  }
});

Vue.component('price-range', {
  template: `<div id='pricing-range-container'>
              <div class="filter_heading"> {{$t('filter.price')}} </div>
              <div class="pricing_labels">
                <div v-for="item in priceTypes" class="learnexa-custom-checkbox filter_type_list_body">
                  <input type="checkbox" :value="Object.values(item)[0]" :id="filter_unique_id + '_' + Object.keys(item)[0]" v-model='selectedPricingType'>
                  <label :for="filter_unique_id + '_' + Object.keys(item)[0]">{{ Object.keys(item)[0] }}</label>
                </div>
              </div>
              <div class="price_slider">
                <b-form-slider range :max="5000" :min="0" v-model="rangeValue" :step="5" :tooltip="'hide'" :handle="'custom'"></b-form-slider>
              </div>
              <div class="d-flex align-items-center justify-content-start filter_price_bar">
                <div class='slider_min_price padding_bottom_5'>{{rangeValue[0]}}</div>
                <div class='slider_max_price padding_bottom_5 ml-auto'>{{rangeValue[1]}}</div>
              </div>
            </div>`,
  data: function() {
    return {
      rangeValue: [0, 5000],
      selectedPricingType: [],
      priceTypes: [
        {'free' : true},
        {'paid' : false }
      ]
    }
  },
  props: {
    filter_unique_id: String
  }
});

Vue.component('rating', {
  template: `<div id='rating-container'>
              <div class="filter_heading"> {{$t('filter.rating')}} </div>
              <div class="rating-filter-body">
                <div v-for='(i, index) in [5,4,3,2,1]' class="filter_type_list_body">
                  <div class='learnexa-custom-checkbox'>
                    <input type='checkbox' :id="filter_unique_id + '-star-rating-'+i" :value='i' v-model='selectedRating'>
                    <label :for="filter_unique_id + '-star-rating-'+i" style="margin-bottom: 0px !important;">
                      <div class='ratings_box margin_top_min_1'>
                        <input :value='i' class='kv-uni-star rating-loading' data-size='xs' disabled='disabled' title='' type='text'>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>`,
  data: function() {
    return {
      selectedRating: []
    }
  },
  props: {
    filter_unique_id: String
  }
});

Vue.component('sort-section', {
  template: `<div :class='[{"hide": !show_sort},{"enrolled_sort": (from_section != "catalog_items")},"sort_option"]' v-bind:id="from_section+'_sortId'">
              <ul class="sorting_list mb-0">
                <li v-for="item in sorting_option" :id="Object.keys(item)[0]" @click="selected_sort">
                  {{ Object.values(item)[0] }}
                </li>
              </ul>
            </div>`,
  data: function() {
    if (this.from_section == 'catalog_items') {
      var sorting_option = [
        {'created_at_desc' : 'Newly Listed'},
        {'created_at_asc' : 'Oldest First'},
        {'popular_items' : 'Most Enrolled'},
        {'most_completed' : 'Most Completed'},
        {'pricing_L2H' : 'Price Low to High'},
        {'pricing_H2L' : 'Price High to Low'},
        {'title_asc' : 'Title A-z'},
        {'title_desc' : 'Title Z-a'},
      ]
        } else {
      var sorting_option = [
        {'created_at_desc' : 'Newly Listed'},
        {'created_at_asc' : 'Oldest First'},
        {'title_asc' : 'Title A-z'},
        {'title_desc' : 'Title Z-a'},
      ]
    }
    return {
      sorting_option: sorting_option,
      active: 'created_at_desc'
    }
  },
  props: {
    show_sort: Boolean,
    from_section: String
  },
  mounted: function() {
    $('#'+this.from_section+'_sortId li#created_at_desc').addClass('active')
  },
  methods: {
    selected_sort(e) {
      $('#'+this.from_section+'_sortId li').removeClass('active')
      $('#'+this.from_section+'_sortId li#'+e.target.id).addClass('active')
      this.$parent.getSortOrder(e.target.id)
    }
  }
});

//sort-section with drop-down 

Vue.component('sort-drop-down', {
  template: `<div id="sort_drop_down" class="cursor_pointer">
                <div class="sort_drop_down_container sort_area background_color_gray lxa_border_radius d-flex align-items-center justify-content-center" :class="show_sort ? 'active_sortby':''" v-click-outside="sortClickOutside" @click="showSortingOptions">
                 <span class="mr-1 lxa_font_size_03 text-uppercase">Sort</span>
                      <span :key="'lxa-sort-up'" class="pe-none" v-if="show_sort">
                        <i class="fad fa-chevron-up lxa_sortby_icon lxa_font_size_04"></i>
                      </span>
                      <span :key="'lxa-sort-down'" class="pe-none" v-else>
                        <i  class="fad fa-chevron-down lxa_sortby_icon lxa_font_size_04"></i>
                      </span>
                  </div>
                <div :class='[{"hide": !show_sort},"sort_option sort_drop_down_li"]' :id="from_section+'_sortId'">
                  <ul class="sorting_list mb-0">
                    <li v-for="item in sorting_option" :id="item.value" :key="item.id">
                      <div @click="selected_sort(item.value)"> {{ item.title}}</div>
                    </li>
                  </ul>
                </div>
            </div>`,
  props: {
    show_sort: Boolean,
    from_section: String,
    sorting_option: Array
  },
  mounted: function() {
    $('#'+this.from_section+'_sortId li:first').addClass('active')
  },
  methods: {
    selected_sort(value) {
      this.$emit('selectCallBack',value)
      $('#'+this.from_section+'_sortId li').removeClass('active')
      $('#'+this.from_section+'_sortId li#'+value).addClass('active')
    },
    showSortingOptions() {
      this.$parent.show_sort = !this.$parent.show_sort
      },
    sortClickOutside: function(e) {
        if(jQuery(e.target).closest('#sortId').length < 1) {
          this.$parent.show_sort = false;
        }
      }
    }
});

//sort-section with drop-down end

Vue.component('product-types', {
  template: `<div id="product-type-container">
              <div class="filter_heading"> Type </div>
              <div class="product-type-body">
                <div v-for='item in product_types' class="filter_type_list_body">
                  <div class='learnexa-custom-checkbox'>
                    <input class='delivery' type='checkbox' :id="filter_unique_id + '_' + Object.keys(item)[0]" :value='Object.keys(item)[0]' v-model='selectedProductType'>
                    <label :for="filter_unique_id + '_' + Object.keys(item)[0]">{{Object.values(item)[0]}}</label>
                  </div>
                </div>
              </div>
            </div>`,
  data: function() {
    return {
      selectedProductType: []
    }
  },
  props: {
    product_types: Array,
    filter_unique_id: String
  }
})

Vue.component('category-filter', {
  template: `<div id="category-filter-container">
              <div class="filter_heading"> Category </div>
              <div class="search_filter">
                <input type="text" v-model="search_text" placeholder="Search">
                <span @click="search_category(search_text)" class="float-right mr-2"> <i class="fad fa-search lxa_font_size_04 lxa_search_icon" aria-hidden="true"></i> </span>
              </div>
              <div class="category_filter_body">
                <div v-for='item in filteredCategories' class="filter_type_list_body">
                  <div class='learnexa-custom-checkbox'>
                    <input class='delivery' type='checkbox' :id="filter_unique_id + '_' + Object.keys(item)[0]" :value='Object.keys(item)[0]' v-model='selectedCategory' v-on:click='addCategory(Object.values(item)[0])'>
                    <label :for="filter_unique_id + '_' + Object.keys(item)[0]">{{Object.values(item)[0]}}</label>
                  </div>
                </div>
              </div>
            </div>`,
  data: function() {
    return {
      selectedCategory: [],
      search_text: '',
      selectedNames: []
    }
  },
  props: {
    categories: Array,
    filter_unique_id: String
  },
  computed: {
    filteredCategories(){
      var categories = this.categories.filter((ctg) => { 
        return Object.values(ctg)[0].toLowerCase().includes(this.search_text.toLowerCase())
      });
      // return this.SortValues(categories);
      return categories
    }
  },
  methods: {
    addCategory: function(val) {
      this.selectedNames.push(val.split(" (")[0]);
    },
    SortValues: function(array) {
      array.sort(( a, b) => {
        if (Object.values(a)[0].split(" (")[0].toLowerCase() < Object.values(b)[0].split(" (")[0])
          return -1;
        if (Object.values(a)[0].split(" (")[0].toLowerCase() > Object.values(b)[0].split(" (")[0])
          return 1;
        return 0;
      });
      return array;
    }
  }
});

Vue.component('date-filter', {
  template: `<div id="date-filter-container">
              <div class="filter_heading"> Date </div>
              <div class="row">
                <label> From </label>
                <input type="date" placeholder="DD/MM/YYYY" v-model="startDate">
              </div>
              <div class="row">
                <label> To </label>
                <input type="date" placeholder="DD/MM/YYYY" v-model="endDate">
              </div>
            </div>`,
  data: function() {
    return {
      startDate: '',
      endDate: ''
    }
  }
});

Vue.component('language-type', {
  template: `<div id="language-type-container">
              <div class="filter_heading"> {{$t('filter.language')}} </div>
              <div class="language-filter-body">
                <div v-for='item in language_types' class="filter_type_list_body">
                  <div class='learnexa-custom-checkbox'>
                    <input class='language' type='checkbox' :id="filter_unique_id + '_' + Object.keys(item)[0]" :value='Object.keys(item)[0]' v-model='selectedLanguageType'>
                    <label :for="filter_unique_id + '_' + Object.keys(item)[0]">{{Object.values(item)[0]}}</label>
                  </div>
                </div>
              </div>
            </div>`,
  data: function() {
    return {
      // language_types: this.$t('create_course.languages'),
      selectedLanguageType: []
    }
  },
  props: {
    filter_unique_id: String,
    language_types: Array
  }
});

Vue.component('duration-filter', {
  template: `<div id="duration-filter-container">
              <div class="filter_heading"> {{$t('filter.duration')}} </div>
              <div class="duartion-filter-body">
                <div v-for='item,index in duration_types' class="filter_type_list_body">
                  <div class='learnexa-custom-checkbox'>
                    <input class='duration' type='checkbox' :id="filter_unique_id + '_' + item" :value='index' v-model='selectedDurationType'>
                    <label :for="filter_unique_id + '_' + item">{{item}}</label>
                  </div>
                </div>
              </div>
            </div>`,
  data: function() {
    return {
      duration_types: this.$t('filter.duration_types'),
      selectedDurationType: []
    }
  },
  props: {
    filter_unique_id: String
  }
});

Vue.component('filter-section', {
  template: `<div :class='[{"hide": !show_filter},{"catalog_filter": (from_section == "catalog_items")},{"enrolled_filter": (from_section == "enrolled_items")},{"mylrnallfilter": (from_section =="all_items")},"filters_option"]' id="filterId">
                <div class="filter_types d-flex flex-wrap align-items-start justify-content-start">
                  <category-filter class="filter_type_list" :categories="categories" :filter_unique_id="from_section" ref="categoryFilter"/>
                  <delivery-type class="filter_type_list" v-if="from_section == 'catalog_items'" :content_types="content_types" :filter_unique_id="from_section" ref="deliveryType"/>
                  <rating class="filter_type_list" v-if="from_section == 'catalog_items'" :filter_unique_id="from_section" ref="ratingFilter"/>
                  <product-types class="filter_type_list" :product_types="product_types" :filter_unique_id="from_section" ref="productType"/>
                  <price-range class="filter_type_list" v-if="from_section == 'catalog_items'" ref="priceRange" :filter_unique_id="from_section"/>
                  <date-filter class="filter_type_list" ref="dateFilter"/>
                  <language-type class="filter_type_list" v-bind:language_types="language_types" :filter_unique_id="from_section" ref="languageType"/>
                  <duration-filter class="filter_type_list" v-if="from_section == 'catalog_items'" :filter_unique_id="from_section" ref="durationFilter"/>
                </div>
                <div class="filter_footer d-flex align-items-center justify-content-end">
                  <div @click="clearFilter" class="lxa_font_size_04 btn text-uppercase lxa_btn_txt_02">Clear</div>
                  <div class="_apply btn lxa_bg_01 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" @click="requestData">Apply</div>
                </div>
              </div>`,
  data: function() {
    return {}
  },
  props: {
    show_filter: Boolean,
    categories: Array,
    content_types: Array,
    from_section: String,
    product_types: Array,
    language_types: Array
  },
  methods: {
    requestData() {
      var start_date = this.$refs.dateFilter.startDate;
      var end_date = this.$refs.dateFilter.endDate;
      if (Date.parse(end_date) >= Date.parse(start_date)) {
        this.$parent.requestData();
      } else {
        this.$root.popUpMessage("End date cannot be before start date");
      }
    },
    clearFilter() {
      this.$parent.clearFilter()
    }
  }
});

Vue.component('add-to-cart', {
  template: `<div class="add_to_cart">
              <div :class="[{hide: !added_to_library}, 'added_to_my_learning']">
                <div :id="'tick_circle_'+item_id" class="cartCircle">
                  <img src="/images/single_page/check_white.svg" class="tick_icon">
                </div>
              </div>
              <div v-if="!added_to_library" class="add_to_my_learning">
                <div v-if="can_add_to_cart" :id="'cart_button_'+item_id" class="animate_button">
                  <div v-if="paid_subscription">
                    <div v-if="subscribe_only&&!valid_subscription" class="margin_top_50_percent">
                      <span :id="'price_bar_'+id+item_id" @click.once="add_to_library" class="product_price lxa_font_size_04 padding_top_8 subscribe_only_text">{{$t('add_to_cart.subscribers_only')}}</span>
                      <span :id="'add_icon_'+id+item_id" @click.once="add_to_library" class="cartCircle margin_top_min_50">
                        <b v-if="!added_to_cart" class="add_icon cursor_pointer" :id="'add_img_'+id+item_id" @click.once="add_to_library">+</b>
                        <img v-else src="/images/single_page/shopping-cart-added.svg" class="cart_icon" />
                      </span>
                    </div>
                    <div v-else :class="{ cursor_pointer: !added_to_cart}">
                      <span :id="'add_icon_'+id+item_id" @click.once="add_to_library" class="cartCircle">
                        <b class="add_icon">+</b>
                      </span>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="!free" :class="[{cursor_pointer: !added_to_cart, cart_button: !from_details}]">
                      <span :id="'price_bar_'+id+item_id" @click.once="add_to_library" class="product_price">{{price}}</span>
                      <span :id="'add_icon_'+id+item_id" @click.once="add_to_library" class="cartCircle">
                        <b v-if="free || !added_to_cart" class="add_icon">+</b>
                        <img v-else src="/images/single_page/shopping-cart-added.svg" class="cart_icon" />
                      </span>
                    </div>
                    <div v-else :class="{ cursor_pointer: !added_to_cart}">
                      <span :id="'add_icon_'+id+item_id" @click.once="add_to_library" class="cartCircle">
                        <b class="add_icon">+</b>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>`,
  data: function() {
    var added_to_library = this.product.added_to_library;
    return {
      free: this.product['free?'],
      price: (added_to_library ? 0 : this.number_to_currency(this.product.price)),
      added_to_library: added_to_library,
      added_to_cart: (added_to_library ? false : this.product.added_to_cart),
      paid_subscription: (added_to_library ? false : this.product.paid_subscription),
      valid_subscription: (added_to_library ? false : this.product.valid_subscription),
      subscribe_only: (added_to_library ? false : this.product.subscribe_only),
      can_add_to_cart: (added_to_library ? false : this.product.can_add_to_cart),
      add_to_library_path: (added_to_library ? '' : this.product.add_to_library_path),
      add_to_cart_path: (added_to_library ? '' : this.product.add_to_cart_path),
      id: ''
    }
  },
  props: {
    product: Object,
    logged_in: Boolean,
    item_id: String,
    from_details: Boolean
  },
  methods: {
    number_to_currency: function(int) {
      return (int).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    },
    add_to_library: function() {
      if (this.logged_in) {
        if (!this.added_to_cart) {
          if (this.free) {
            this.add_to_my_learning();
            this.added_to_library = true
          }
          else {
            this.$http.post(this.add_to_cart_path).then(response => {
              this.added_to_cart = true
            });
          }
        }
      }
    },
    add_to_my_learning: function() {
      this.$http.post(this.add_to_library_path+'.json').then(response => {
      });
    }
  }
});

Vue.component('applied-filter',{
  template: `<div>  
              <ul class="filter-list mb-0 p-0 d-flex flex-wrap lxp-fontsize12 lxp-fontweightregular">
                <li class="applied-filter align-self-center lxp-text-primary lxp-fontfamily-primary lxp-applied-filter-label px-2 py-1 mr-2 d-flex" v-for="(filter,index) in AppliedFilters" :key="fromPage+'applied-filter-'+index">
                  <star-rating-comp v-if="filter.ref_id == 'ratingFilter'" :filter_display_id="filter.filter_display_id"></star-rating-comp>
                  <label v-else class="mb-0 mr-1" v-html="filter.filter_display_name"></label>
                  <label :class="'mb-0 icon-color-primary cursor_pointer filter-remove '+filter.ref_id" v-on:click="remove_filter(filter.ref_id,filter.filter_display_id,filter.filter_display_name,filter.filter_type,index)">
                    <i data-toggle="tooltip" data-placement="top" title="Remove" class="fad fa-times"></i>
                  </label>
                </li>
                <li v-if="AppliedFilters.length > 0" class="applied-filter align-self-center lxp-text-primary lxp-title-03 px-2 py-1"><label class="mb-0 cursor_pointer text-capitalize" v-on:click="clearFilter(isIndex)">clear</label></li>
              </ul>
            </div>`,
  props: {
    AppliedFilters: Array,
    fromPage: String,
    isIndex: String,
  },
  methods: {
    remove_filter: function(ref,display_id,display_name,filter_type,arr_index) {
      this.$parent.remove_filter(ref,display_id,display_name,filter_type,arr_index);
    },
    clearFilter: function(isIndex) {
      var i = this.isIndex ? 0 : 1;
      this.$parent.$children[i].clearFilter();
      this.$parent.$children[i].requestData();
    }
  },
});
Vue.component('star-rating-comp',{
  template: `<div>
              <input v-model="filter_display_id" class='kv-uni-star rating-loading' data-size='xs' disabled='disabled' title='' type='text'>
            </div>`,
  props: {
      filter_display_id:Number
  },
  mounted: function() {
    jQuery('.kv-uni-star').rating({
      theme: 'krajee-uni',
      filledStar: '&#x2605;',
      emptyStar: '&#x2606;',
      showClear: false,
      showCaption: false,
      step: 1
    });
  },
});