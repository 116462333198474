import $ from 'jquery';
import videojs from 'video.js';
// import axios from 'axios'; 

var scoobj;
var scorm_api_init;
var winobj;
var ajaxInProgress = false;

export function initContentPlayerData() {
	return {
		title: '',
		body: '',
		contents: [],
		contentLessons: [],
		surveys: [],
		videos: [],
		audios: [],
		enrollmentId: 0,
		classId: 0,
		courseId: 0,
		sourceName: '',
		currentModelId: '',
		allowPostAssessment: true,
		alertactionPending : '',
		alertactionPendingType : '',
		alertsurveyId : '',
		surveyMapId: '',
		bookmarkedContentId : '',
		Clsdeltype : '',
		// videojsId: 'video-content',
		contentsDefault: [],
		currentContent: null,
		currentContentIndex: null,
		currentLesson: null,
		audioOptions: {
			playPoster: "contentplayer/images/play.svg",
			pausePoster: "contentplayer/images/pause.svg",
			videojsId: 'audio-content',
		},
		videoOptions: {
			videojsId: 'video-content',
		},
		showPDF: false,
		showSurvey: false,
		showAssessment: false,
		showVideoJS: false,
		contentEndPointProps: null,
		// Header icons
		playerInit: true,
		closeModal: false,
		objectType: 'class',
		onClose: null,
		bookMarked: false,
		enforceSequence: false,
		isLastContentPlayed: true,
		externalUserId: null,
		modal_id: 'preview_lightbox',
		product: {},
		contents_list: [],
		content_obj: {},
		preview: true,
		show_content_no: 0,
		content_loaded: false,
		refresh_progress_url: '',
		result_response: {},
		result_have_response: false,
		current_content_type: '',
		title_id: 'header_title_' + new Date().getTime(),
		fullscreen: false,
		fullscreen_alert: false,
		data: {},
		show_title: false,
		show_playlist: true,
		player_details: {
			product: {}, 
			contents_list: [], 
			content_obj: {},
			refresh_progress_url: '', 
			show_content: 0, 
			result_response: {}, 
			result_have_response: false, 
			select_thumbnail_url : '', 
			content_loaded: false, 
			container_name: ''
		},
		all_contents: [],
		participants: [],
		old_comments: [],
		new_comment: '',
		show_comments: false,
		selected_rating: null,
		old_files: [],
		show_files: false,
		product_owner: '',
		show_product_owner: false,
		show_content_type: true,
		new_note: '',
		show_notes: false,
		old_notes: [],
		shareModalOpen: false,
		share_popup_id: "share_popup_" + new Date().getTime(),
		loader: true,
		notes_time: 0
	};
}

function styleCurrentContent(content, index) {
	try {
		// this.removeCurrentContentStyles();
		var type = content.type;
		if(type == 'assessment' && content.pre_test == "Pre-test") {
			type = 'pre-assessment';
		}
		$('.content-playlist').find('.content-icon i').removeClass('fa-icon-color-active');
		if(type == 'assessment') {
			$('#launch-assessment-'+index).find('.content-icon i').addClass('fa-icon-color-active');
		} else if(type == 'pre-assessment') {
			$('#launch-pre-assessment-'+index).find('.content-icon i').addClass('fa-icon-color-active');
		} else if(type == 'survey') {
			$('#launch-survey-'+index).find('.content-icon i').addClass('fa-icon-color-active');
		} else {
			$('#launch-content-'+index).find('.content-icon i').addClass('fa-icon-color-active');
		}
	} catch (e) {
		// TODO: handle exception
	}
}

function getCurrentContent(data) {
	try {
		return data.currentContent;
	} catch (e) {
		// TODO: handle exception
		console.log(e, e.stack);
	}
}

export function fetchFileType(file) {
	return file.substr((file.lastIndexOf('.') + 1));
}

export function addVideoContent(video) {
  try {
    var videoTag = $('<video />', video);
    videoTag.appendTo($('#content-wrapper'));
    return videoTag;
  } catch (e) {
    console.log(e, e.stack);
  }
}

export function addAudioContent(audio) {
	try {
		var audioTag = $('<audio />', audio);
		audioTag.appendTo($('#content-wrapper'));
		return audioTag;
	} catch(e) {
		console.log(e, e.stack);
	}
}

export function videoCommonParams(video, videoOptions) {
	video["id"] = videoOptions.videojsId;
	video["class"] = "video-js vjs-default-skin vjs-big-play-centered";
	video["aspectRatio"] = "16:9";
	video["fluid"] = true;
	video["controls"] = true;
	// video["controlBar"] = {}
  // video["controlBar"]["children"] = ["playToggle", "progressControl", "currentTimeDisplay", "timeDivider", "durationDisplay", "volumeMenuButton", "fullscreenToggle"]
	return video
}

function updateVideoContentProgress(from, data) {
	try {
		updateContentProgress(undefined,undefined,from, data);
	} catch (e) {
		console.log(e, e.stack);
	}
}

export function toggleContentStrip() {
  try {
    $('#content-launch-modal').toggleClass('content-strip-collapsed');
    var _duration = 50;
    if($('#content-launch-modal').hasClass('full-screen')) {
      _duration = 500;
    }
    $("#contentStrip").slideToggle({
      duration: _duration,
      complete: function(){
        $('#content-launch-modal .modal-header .toggle-icon').toggleClass("fa-chevron-double-up fa-chevron-double-down");
        if($('#content-launch-modal .modal-header .toggle-icon').hasClass("fa-chevron-double-up")) {
          $('#content-launch-modal .content-header-tooltip').attr('data-original-title', '');
        } else {
          $('#content-launch-modal .content-header-tooltip').attr('data-original-title', '');
        }
        if($("#contentStrip").is(':visible')) {
          setTimeout(function() {
            $('#content-launch-modal .flexslider').data('flexslider').resize();
            $(window).trigger('resize');
          }, 500);
        }
      }
    });
  } catch(e) {
    console.log(e, e.stack);
  }
}

export function fetchContent(data, active_content_id = null) {
	var req_url
	switch (data.active_item.item_type) {
		case "Course":
			req_url = data.active_item.course_path;
			break;
		case "LiveEvent":
			req_url = data.active_item.live_event_path;
			break;
		case "InPersonEvent":
			req_url = data.active_item.in_person_event_path;
			break;    
		case "Certification":
			req_url = data.active_item.course_path;
			break;
	}
	data.$http.get(req_url).then(function(response) {
		if ( typeof response.body.detail_item == 'undefined' ) {
			var detail_item = response.body.contents
		} else {
			var detail_item = response.body.detail_item.contents
		}
		data.player_details.contents_list = detail_item;
		if (active_content_id == null) {
			data.$emit('all-course-content', data.player_details.contents_list)
		}
		if (detail_item != undefined) {
		for (var i = 0; i < detail_item.length; i++) {
			if (active_content_id != null) { response.body.last_accessed_content_id = active_content_id }
			if(detail_item[i]["content_id"] == response.body.last_accessed_content_id) {
				data.player_details.show_content = i;
				var content_type = detail_item[i]['content_type'];
				var content_id = detail_item[i]['content_id'];
				data.player_details.product_title = response.body.product_title
				data.player_details.product_desc = response.body.product_desc
				data.player_details.product_owner = response.body.product_owner

				data.player_details.select_thumbnail_url = detail_item[i]['select_thumbnail_url']
				data.$http.get(data.player_details.select_thumbnail_url).then(function(response) {
					data.player_details.refresh_progress_url = response.body.refresh_progress_url;
					var current_content_url = '';
					if(response.body.url) { current_content_url = response.body.url; }
					var content_title = data.player_details.contents_list[0].content_title
					var from_page = ''
					switch(content_type) {
						case "quiz": case "survey":
						    if(response.body.ps_complete) {
						    	data.$http.get(response.body.response_show_url+'&is_last_content='+response.body.is_last_content).then(function(response) { 
									data.player_details.content_obj = response.body;
									data.player_details.content_obj.title = content_title;
									data.player_details.content_obj.content_id = content_id;
									data.player_details.content_obj.content_type = content_type;
									data.player_details.content_loaded = true;
									data.$emit('set-contentplayer-data', data.player_details)
								});
			                }
						    else {
                  data.$http.post(response.body.response_sets_url+'&from_page='+from_page+'&product_id='+response.body.product_id+'&is_last_content='+response.body.is_last_content).then(function(response) { 
									data.player_details.content_obj = response.body;
									data.player_details.content_obj.title = content_title;
									data.player_details.content_obj.content_id = content_id;
									data.player_details.content_obj.content_type = content_type;
									data.player_details.content_loaded = true;
									data.$emit('set-contentplayer-data', data.player_details)
								});
						    }
							break;
						case "tincan_package": 
							data.$http.get(current_content_url).then(function(response) {
								data.player_details.content_obj = response.body;
								data.player_details.content_obj.title = content_title;
								data.player_details.content_obj.content_id = content_id;
								data.player_details.content_obj.content_type = content_type;
								data.player_details.content_loaded = true;
								data.$emit('set-contentplayer-data', data.player_details)
							});
							break; 
						default:
							data.player_details.content_obj = response.body;
							data.player_details.content_obj.title = content_title;
							data.player_details.content_obj.content_id = content_id;
							data.player_details.content_obj.content_type = content_type;
							if(content_type == "webtext") {data.player_details.content_loaded = true;}
							data.$emit('set-contentplayer-data', data.player_details)
							break;
					}
				});
			}
		}
		}
	});
}

export function toggleFullScreen(data) {
	try {
		var toggleFSClasses = function() {
			try {
				$('#content-launch-modal').toggleClass('full-screen');
				$('#content-launch-modal .modal-header #full-screen-icon').toggleClass("fa-compress-arrows-alt fa-expand-arrows-alt");
				if($('#content-launch-modal .modal-header #full-screen-icon').hasClass("fa-compress-arrows-alt")) {
					$('#content-launch-modal .content-fs-tooltip').attr('data-original-title', '');
				} else {
					$('#content-launch-modal .content-fs-tooltip').attr('data-original-title', '');
				}
				setTimeout(function() {
					initFlexSlider();
				}, 500);
			} catch (e) {
				console.log(e, e.stack);
			}
		}
		var elem = document.getElementById('content-launch-modal');
		if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
			if (elem.requestFullScreen) {
				elem.requestFullScreen();
			} else if (elem.mozRequestFullScreen) {
				elem.mozRequestFullScreen();
			} else if (elem.webkitRequestFullScreen) {
				elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
			} else if (elem.msRequestFullscreen) {
				elem.msRequestFullscreen();
			}
			// hideContentStrip(data);
			toggleFSClasses();
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			}
			toggleFSClasses();
		}
	} catch(e) {
		console.log(e, e.stack);
	}
}

function initFlexSlider() {
	try {
		if(typeof $('#content-launch-modal .flexslider').data('flexslider') != "undefined") {
			$('#content-launch-modal .flex-viewport, #content-launch-modal .flex-direction-nav').addClass("old");
			$('#content-launch-modal .flexslider').removeData("flexslider");
		}
		var options = {
			selector: ".slides > div.content-slide",
			maxItems: getFlexMax(),
			minItems: getFlexMax(),
			start: function() {
				$('[data-toggle="tooltip"]').tooltip({ container: 'body',trigger:'hover'});
				$('.flex-viewport').height("");
				$('.flex-viewport').addClass("h-100 w-100");
				$('.flex-prev').html('<i class="fad fa-chevron-left"></i>');
				$('.flex-next').html('<i class="fad fa-chevron-right"></i>');
			},
			init: function() {
				setTimeout(function() {
					try {
					$('#content-launch-modal .flexslider').data('flexslider').resize();
					$('#content-launch-modal .flex-viewport.old, #content-launch-modal .flex-direction-nav.old').remove();
					} catch (e) {
						console.log(e, e.stack);
					}
				}, 500);
			},
			after: function(){ 
				$('[data-toggle="tooltip"]').tooltip({ container: 'body',trigger:'hover'});
			},
			move: 1,
			animationSpeed: 600,
			// Added newly
			animation: "slide",
			slideshow: false,
			direction: "horizontal",
			animationLoop: false,
			smoothHeight: true,
			controlNav: false,
			directionNav: true,
			reverse: false,
			keyboard: false,
			touch: false,
			multipleKeyboard: false,
			prevText: "",
			nextText: "",
			customDirectionNav: "",
			mousewheel: false,
		};
		if($('#content-launch-modal .flexslider div.content-slide').length) {
			renderFlexSlider('#content-launch-modal .flexslider', options);
		}
	} catch(e) {
		console.log(e, e.stack);
	}
}

function getFlexMax() {
	try {
		var flexContentSize = 292;
		var calculated = Math.floor($('#content-launch-modal').width()/flexContentSize);
		calculated = (calculated == 0 ? 1 : calculated);
		var totalSlides = $('#content-launch-modal .flexslider .content-slide').length;
		return Math.min(calculated, totalSlides);
	} catch (e) {
		console.log(e)
	}
}

function renderFlexSlider(selector, options) {
	var defaultOptions = {
		animation: "slide",
		itemWidth: 210,
		itemMargin: 0,
		controlNav: false,
		animationLoop: false,
		slideshow: false
	};
	var assigned = Object.assign(defaultOptions, options);
	var slider = $(selector).flexslider(assigned);
	return slider;
}

function updateContentProgress(callbackParam, callback, from, data) {
	try {
		var _this = data;
		var source =(typeof event == "undefined")? "undefined" : event.target || event.srcElement;
		var content = getCurrentContent();
		if(typeof callback == "function" && (content == null || ((_this.currentLesson == null || typeof _this.currentLesson == "undefined") && content.type != "external_assessment"))) {
		callback();
		return;
		}
		var lessonId = null;
		var contentVersionId = 0;
		var contentId = null;

		if(content.type != "external_assessment") {
		lessonId = _this.currentLesson.lesson_id;
		contentVersionId = content.version_id;
		contentId = content.content_id;
		}
		
		var vars = {
			_apiName: "UpdateScoreAPI",
			endPoint: _this.contentEndPointProps.content_completion_endpoint,
			// learner_id: lxpApp.getUserId(),
			// stid: lxpApp.getUserId(),
			enrollid: _this.enrollmentId,
			regid: _this.enrollmentId,
			// prestatus: '',
			courseid: _this.courseId,
			classid: _this.classId,
			lessonid: lessonId,
			contenttype: content.type,
			versionid: contentVersionId,
			// userid: lxpApp.getUserId(),
			playfrom: "contentplayer",
			// calltype: '',
			// surveyid: '',
			display_cols: "ID,ValidTo,Code,Title,LaunchType,ContentType,MaxAttempt,AttemptLeft,ClsScore,SuspendData,progress,overallProgress",
			returntype: "json",
			// apiurl:
			// "https://e1src.exphosted.com/apis/ext/ExpertusOneAPI.php",
			// filename: '',
			// apiname: 'UpdateScoreAPI',
			access_token: _this.contentEndPointProps["access_token"],
			// // progress: 100,
			contentid: contentId,
			contentTitle: content.title,
		};
		var launchUpdateUrl = "/lxpapi";
		if(typeof scoobj != 'undefined' && scoobj != null) {
			var apiResult = (callbackParam !== undefined && scoobj._type.toLowerCase() == 'scorm') ? callbackParam : scoobj.Finish();
		}
		
		// console.log("updateScore result = ", apiResult, scoobj);
		var launchflag = 0;

		var sessionTime = '0';

		var status = '';
		var score = '';
		var loc = '';
		var compstatus = '';
		var launch_data = '';
		var suspend_data = '';
		var exit = '';
		var grade = '';
		var xstatus = '';

		if(typeof(apiResult) != undefined && typeof(apiResult) != 'undefined') {
			status = apiResult.status;
			score = apiResult.score;
			sessionTime = apiResult.sessionTime;
			loc = apiResult.location;
			compstatus = apiResult.completionStatus;
			launch_data = apiResult.launch_data;
			suspend_data = apiResult.suspend_data;
			exit = apiResult.exit;
			launchflag = (apiResult !== undefined) ? apiResult.launchflag : 0;
		}
		var is_scorm_api_changed = true;

		switch(content.type) {
		case "Video/Audio":
			var videoJsId = (content.sub_type == "video" ? _this.videoOptions.videojsId : _this.audioOptions.videojsId);
			if(typeof from == "undefined" && from != "Video" && from != "Audio") {
				videojs(videoJsId).pause();
			}
			var progress = Math.round(((videojs(videoJsId).currentTime() / videojs(videoJsId).duration()) * 100));
			var contentstatus = (progress == 100 ? "completed" : "failed")
			status = (progress < 100 ? "lrn_crs_cmp_inp" : "lrn_crs_cmp_cmp");
			suspendData = {
				"current_position": videojs(videoJsId).currentTime(),
				"progress": progress,
				"video_duration": videojs(videoJsId).duration()
			}
			vars["progress"] = progress;
			vars["contentstatus"] = contentstatus;
			vars["status"] = status;
			vars["suspend_data"] = escape(escape(JSON.stringify(suspendData)));
			break;
		case "Knowledge Content":
			if(content.sub_type == "pdf"){
				// console.log("pdfViewerpdfViewer", pdfViewer)
				// console.log("current page
				// ::",pdfViewer.currentPageNumber);
				// console.log("total
				// pagessss::",pdfViewer.pagesCount);//pagesCount,currentPageNumber
				var prev_current_page = 1;
				var suspendData = false;
				if(content.content_progress != null && content.content_progress != "null"){
					prev_current_page = JSON.parse(content.content_progress[0].suspend_data).current_page;
					suspendData = true;
				}
				console.log(prev_current_page);
				var totalper, KcsuspendData;
				// if(prev_current_page >= pdfViewer.currentPageNumber && suspendData == true) {
				// 	var KcsuspendData = content.content_progress[0].suspend_data;
				// 	var totalper = JSON.parse(content.content_progress[0].suspend_data).progress;
				// }else{
				// 	var current_page = pdfViewer.currentPageNumber;
				// 	var total_page = pdfViewer.pagesCount;
				// 	var totalper = (100/total_page)*current_page;
				// 	var KcsuspendData = '{"total_page":"'+total_page+'","current_page":"'+current_page+'","progress":'+totalper+'}'
				// }
				if(totalper == 100){
					vars["progress"] = totalper;
					vars["contentstatus"] = "completed";
					vars["suspend_data"] = escape(KcsuspendData);
					vars["status"] = "lrn_crs_cmp_cmp";
				}else{
					vars["progress"] = totalper;
					vars["contentstatus"] = "Failed";
					vars["suspend_data"] = escape(KcsuspendData);
					vars["status"] = "lrn_crs_cmp_inp";
				}
			}else{
				// console.log("pdfFindControllerpdfFindController"
				// , PDFViewer)
				vars["progress"] = 100;
				vars["contentstatus"] = "completed";
				vars["suspend_data"] = 100;
				vars["status"] = "lrn_crs_cmp_cmp";
			}
			
			if(score == undefined || score == '') {
				score = '0';
				grade = '';
			}

			vars['score'] = score;
			
			break;

		case "SCORM 1.2":
		case "SCORM 2004":
			// if(prevcontentstatus != 'Completed') {
			var scormprogress = 0;
			if(scoobj._version == '2004') {
				var completion_status = compstatus.toLowerCase();
				var success_status = status.toLowerCase();
				if(completion_status == 'completed') {
					xstatus='lrn_crs_cmp_cmp';
					scormprogress = 100;
				}
				else if(completion_status == 'unknown' && (success_status == 'unknown' || success_status == 'passed')){
					xstatus='lrn_crs_cmp_cmp';
					scormprogress = 100;
				}
				else {
					xstatus='lrn_crs_cmp_inp';
				}
			} else {
				if($.inArray(status.toLowerCase(), ["passed", "failed", "completed", "unknown"]) != -1) {
					xstatus='lrn_crs_cmp_cmp';
					scormprogress = 100;
				}else{
					xstatus='lrn_crs_cmp_inp';
				}
			}

			// } else {
			// xstatus = 'lrn_crs_cmp_cmp';
			// }
			if(scoobj._version == '2004') {
				// comparing initialized values with the current
				// scorm result
				if(compstatus == 'not attempted' && scorm_api_init.session_time == sessionTime && scorm_api_init.lesson_location == loc && scorm_api_init.launch_data == launch_data && scorm_api_init.suspend_data == suspend_data && scorm_api_init.success_status == status) {
					is_scorm_api_changed = false;
				}
			} else {
				// comparing initialized values with the current
				// scorm result
				if(status  == 'not attempted' && scorm_api_init.session_time == sessionTime && scorm_api_init.lesson_location == loc && scorm_api_init.launch_data == launch_data && scorm_api_init.suspend_data == suspend_data) {
					is_scorm_api_changed = false;
				}
			}
			if(score == undefined || score == '') {
				score = '0';
				grade = '';
			}
			console.log(grade)
			vars['status'] = xstatus;
			vars['contentstatus'] = status;		// for SCORM 1.2
			// =>
			// lesson_status
			// and for 2004
			// =>
			// success_status
			vars['compstatus'] = compstatus;	// for SCORM 1.2
			// => '' and for
			// 2004 =>
			// completion_status
			vars["progress"] = scormprogress;
			vars['sestime'] = sessionTime;
			vars['score'] = score;
			if(loc != null && typeof loc != "undefined") {
				vars['location'] = loc;
			}
			vars['launch_data'] = launch_data;
			vars['suspend_data'] = encodeURIComponent(suspend_data);
			vars['exit'] = exit;
			vars['contenttype'] = scoobj._type;
			vars['contentversion'] = scoobj._version;
			// vars['aicc_sid'] = aicc_sid;
			vars['is_scorm_api_changed'] = is_scorm_api_changed;
			// vars['launchflag'] = launchflag;
			break;

		case "AICC":
		case "AICC Course Structure":
			vars['LXPLRScreateStatements'] = 1;	
			var sid = _this.getAiccId(content,_this.currentLesson.lesson_id);
			var passUrl="session_id="+sid+"&command=UPDATELMSDATA";
			console.log(passUrl);
			launchUpdateUrl = "/aicc-content-relay/";
			var aiccVars = {
					"session_id": callbackParam,
					"command": "UPDATELMSDATA",
					'launchflag': launchflag
			};
			aiccVars = "session_id=" + callbackParam + "&command=" + "UPDATELMSDATA" + '&launchflag=' + launchflag;
			break;

		case "Tin Can":
			vars['LXPLRScreateStatements'] = 1;						
			sid = _this.getAiccId(content,_this.currentLesson.lesson_id);
			passUrl="Authorization="+sid+"&command=UPDATELMSDATA";
			console.log(passUrl);
			launchUpdateUrl = "/content-relay/";
			aiccVars = {
					"Authorization": callbackParam,
					"command": "UPDATELMSDATA",
					'launchflag': launchflag
			};
			aiccVars = "Authorization=" + callbackParam + "&command=" + "UPDATELMSDATA" + '&launchflag=' + launchflag;
			break;

			case "external_assessment":
				vars['launchUrl'] = content.external_assessment_url;
				vars['questionpro_id'] = content.external_assessment_id;
				vars['status'] = "lrn_crs_cmp_cmp";
				vars['_apiName'] = "QPUpdateScoreAPI";
				try {
					delete vars['lessonid'];
					delete vars['contentid'];
				} catch (e) {
					// TODO: handle exception
					console.log(e, e.stack);
				}
			break;
		}
		
		if(typeof callback == "undefined") {
			var showLoader = (from != "scormInit" && from != "Video" && from != "Audio" && from != "WebContents");
			callback = _this.createCallback(_this.closeModal, showLoader);
		}
		if($.inArray(content.type, ["Tin Can",  "AICC", "AICC Course Structure"]) !== -1) {

			setAjaxInProgress(true);
			try {
				axios.post(launchUpdateUrl, aiccVars)
				.then(res => {
					setAjaxInProgress(false);
					// console.log('Response from E1 Rest = ',
					// res.data);
				//	if(typeof res.data != "undefined" && from !== 'scormInit' && (typeof source == "undefined" || source == "undefined" || source['className'] == "fad fa-times pointer mt-0" || source['className'] == "cls-save-button")) {
					if(typeof res.data != "undefined"){
						if(source['className'] == "fad fa-times pointer mt-0" || source['className'] == "cls-save-button"){
							vars['launchflag']=0;
						}else if(from == 'scormInit' || from == 'WebContents'){
							vars['launchflag']=1;
						}
						vars['contentstatus'] = (typeof res.data.content_status != "undefined")?res.data.content_status:"incomplete";
						vars['status'] = (typeof res.data.status != "undefined")?res.data.status:"lrn_crs_cmp_inp";
						vars['score'] = (typeof  res.data.score != "undefined")?res.data.score :"";
						vars['progress'] = (typeof  res.data.progress != "undefined")? res.data.progress: null;
						vars['contenttype'] = scoobj._type;
						// _this.makeAPICall(vars, callback, from);
					}

				})
				.catch(error => {
					setAjaxInProgress(false);
					console.log(error);
				});
			} catch(e) {
				setAjaxInProgress(false);
				console.log(e, e.stack);
			}
		} else {
			// if(from !== 'scormInit' && typeof callbackParam == "undefined" && (typeof source == "undefined" || source == "undefined" || source['className'] == "fad fa-times pointer mt-0" || source['className'] == "cls-save-button")){
				if(source['className'] == "fad fa-times pointer mt-0" || source['className'] == "cls-save-button"){
					vars['launchflag']=0;	      
				}else if(from == 'scormInit' || from == 'WebContents'){
					vars['launchflag']=1;
				}
				// _this.makeAPICall(vars, callback, from);
			// }
		}

	} catch (e) {
		setAjaxInProgress(false);
		// TODO: handle exception
		console.log(e, e.stack);
		if(typeof callback == "function") {
			callback();
		}
	}
}

function hideContentStrip(data) {
	try {
		$('#content-launch-modal').addClass('content-strip-collapsed');
		$("#contentStrip").slideUp({
			duration: 50,
			complete: function () {
				$('#content-launch-modal .modal-header .toggle-icon')
				.removeClass("fa-chevron-double-up")
				.addClass("fa-chevron-double-down");
				$('#content-launch-modal .content-header-tooltip').attr('data-original-title', '');
			},
		});
		if (data.showAssessment == true) {
			var docheight = $(window).height() - 100;
			var cpbodysech = $('#content-launch-modal').height();
			var cpplaysech = $('#play-section').height();
			var cpheadfootplh = cpbodysech - cpplaysech; //get content player header footer and playlist section height
			var cpviewah = (docheight - cpheadfootplh);
			var getcpviewah = (data.$refs.AssessmentViewer.viewOption == 'Vertical') ? cpviewah : cpviewah - 50;
			$('#AssessmentContainer').css({
				'max-height': getcpviewah + 100 + 'px',
				'overflow': 'hidden'
			});
			$('#AssessmentContainer').slimScroll({
				barClass: 'lxpfilterscroll',
				size: '16px',
				minHeight: 'auto',
				height: '100%',
				position: 'right',
				start: 'top',
				alwaysVisible: false,
				distance: '0',
				borderRadius: '0',
				opacity: 1,
				allowPageScroll: true,
				touchScrollStep: 200,
				enableKeyNavigation: true
			});
		}
	} catch (e) {
		// TODO: handle exception
		console.log(e, e.stack);
	}
}

// function toggleLoader(show) {
// 	try {
// 		// this.$refs.cploader.is_enable_loader = show;
// 		if(show == true) {
// 			$('#play-section').addClass('invisible');
// 		} else {
// 			$('#play-section').removeClass('invisible');
// 		}
// 	} catch (e) {
// 		// TODO: handle exception
// 		console.log(e, e.stack);
// 	}
// }

function calculateProgress(content) {
	try {
		var progress = 0;
		switch (content.objectType) {
		case "assessment":
			if( content.assessment_progress != null && !isNaN(content.assessment_progress[0].progress) ){
					progress = content.assessment_progress[0].progress/100;
					if (  progress < 0 ) progress = 0;
			}
			break;
			
		case "survey":
			if(content.survey_progress != null && !isNaN(content.survey_progress[0].progress) ){
				if ( content.survey_progress[0].survey_status === "Completed" ){
					progress = 1;
					break;
				}
				progress = content.survey_progress[0].progress/100;
				if (  progress < 0 ) progress = 0;
			}
			break;

		case "external_assessment":
			if(content.status == "Completed") {
				progress = 1;
			}
			break;
		
		default:
			var webBased = ["SCORM 2004", "SCORM 1.2", "Tin Can", "AICC", "AICC Course Structure"];
			if(content.content_progress != null && typeof content.type != "undefined" && $.inArray(content.type, webBased) != -1) {
				var completedLessons = 0;
				$.each(content.content_progress, function(index, value) {
					try {
						var webProgress = value;
						var completion_status = webProgress.completion_status;
						var success_status = webProgress.content_status;
						if(typeof webProgress.progress != "undefined" && parseFloat(webProgress.progress) >= 100) {
							completedLessons += 1;
						} else if(content.sub_type == 'SCORM 2004') {
							if(completion_status == 'completed') {
								completedLessons += 1;
							} else if(completion_status == 'unknown' && (success_status == 'unknown' || success_status == 'passed')){
								completedLessons += 1;
							}
						} else if($.inArray(success_status, ["passed", "failed", "completed", "unknown"]) != -1) {
							completedLessons += 1;
						}
					} catch (e) {
						// TODO: handle exception
						console.log(e, e.stack);
					}
				});
				// console.log("completedLessons", completedLessons);
				// console.log("content.launch.length", content.launch.length);
				if(completedLessons == content.launch.length) {
					progress = 1;
				} else {
					progress = (completedLessons / content.launch.length);
				}
			} else if(content.content_progress != null && !isNaN(content.content_progress[0].progress)) {
				progress = content.content_progress[0].progress/100;
			}
		break;
		}
		return progress;
	} catch (e) {
		// TODO: handle exception
		console.log(e, e.stack);
	}
}

export function close(data) {
	// data.$emit('update:show', false);
	if(data.$children[0].opened != "undefined") {
		data.$children[0].opened = false;
	}
	clearInterval(data.interval);
	// data.show = false;
	// data.destroy();
}

export function open(data) {
	// _data.fetchExternalUserId();
	function _fetchContent() {
		try {
			if(data.playerInit == true) {
				return fetchContent(data);
			} else{
				setTimeout(_fetchContent, 2000);
			}
		} catch(e) {
			console.log(e, e.stack);
		}
	}
	return _fetchContent();
}

export function cleanUpContentPlayArea(data) {
	try {
		data.videos = [];
		data.audios = [];
		data.contentLessons = [];
		data.showPDF = false;
		data.showSurvey = false;
		data.showAssessment = false;
		
		$("#pdf-content-wrapper #viewer, #content-wrapper").empty();
		$(".content-view-area").removeClass('video');
		$("#content-wrapper").removeClass('d-flex');
		$("#content-wrapper .video-js").removeClass('m-auto');
		data.$nextTick(function() {
			try {
				// console.log("$nextTick in cleanUpContentPlayArea!",
				//							document.getElementById(this.videoOptions.videojsId),
				//							videojs(this.videoOptions.videojsId));
			} catch (e) {
				// TODO: handle exception
				console.log(e, e.stack);
			}
		});
		return data;
	} catch (e) {
		// TODO: handle exception
		console.log(e, e.stack);
	}
}

export function saveProgress(closeModal, data) {
	try {
		var currentContent = getCurrentContent();
		// var currentContentIndex = data.currentContentIndex;
		// this.checkAndSubmitSurveyAssessment(currentContent);
    // var callback = createCallback(closeModal, false, data);
    console.log(closeModal, data)
		var webBased = ["SCORM 2004", "SCORM 1.2", "Tin Can", "AICC", "AICC Course Structure"];
		if(typeof winobj != "undefined" && winobj.closed == false && $.inArray(currentContent.type, webBased) != -1) {
			winobj.closed = true;
			// clearTimeout(timeOutVar);
			var iframeID = "url-content";
			var frame = document.getElementById(iframeID);
			try {
				if(frame !== undefined && frame != null) {
					frame.parentNode.removeChild(frame);
				}
			} catch(e) {
				console.log(e, e.stack);
			}
		} else {
			updateContentProgress(undefined, null);
		}
	} catch(e) {
		console.log(e, e.stack);
	}
}

// function createCallback(closeModal, showLoader, data) {
// 	try {
// 		var callback = null;
// 		var currentContent = getCurrentContent();
// 		var currentContentIndex = data.currentContentIndex;
// 		var cp = data;
// 		if(closeModal == true) {
// 			callback = function() {
// 				if($("#external-cplayer").length) {
// 					if(externalContentLaunch.$refs.callfrom == "externalContentLaunch") {
// 						cp.close();
// 						window.close();
// 					}
// 				} else {
// 					if(typeof cp.onClose == "function") {
// 						cp.onClose();
// 					}
// 					// mylearnapp.$refs.mylearning.clickeventmylan(mylearnapp.selected);
// 					cp.toggleLoader(false);
// 					cp.close();
// 				}
// 			}
// 			// this.closeModal = true;
// 		} else if(data.contents.length >= 1) {
// 			callback = function() {
// 				// cp.refreshCurrentContent(currentContent, currentContentIndex, showLoader);
// 			}
// 			// this.closeModal = false;
// 		}
// 		return callback;
// 	} catch (e) {
// 		// TODO: handle exception
// 		console.log(e, e.stack);
// 	}
// }

function setAjaxInProgress(value) {
	ajaxInProgress = value;
	console.log(ajaxInProgress)
}

export function setVideoInterval(data, from) {
	var contentRefreshVal = (1000 * 60 * 1000);
	return setInterval(() => updateVideoContentProgress(from, data), contentRefreshVal);
}

export function setPosition(content) {
	if(content.content_progress != null && content.content_progress != "null") {
		if(content.content_progress[0].suspend_data != null && content.content_progress[0].suspend_data != "null") {
			var suspendData = JSON.parse(unescape(content.content_progress[0].suspend_data));
			position = Math.round(suspendData.current_position);
			var _vDuration = Math.round(suspendData.video_duration);
			var position = (position >= _vDuration ? 0 : position)
			return position
		}
	}
	return null
}

export function bcVideoPlayer(match, playId, video) {
	var bcURL = "https://players.brightcove.net/" + match[2] + "/" + playId.substr(1, playId.indexOf('_') - 1) + "_" + "default" + "/index.min.js";
	var vOp = {async: false, target: 'head', type: "text/javascript"};
	var vCB = function(err, script) {
		if (err) {
			// console.log('Error in videojs bc load', err);
		} else {
			console.log('videojs bc load success', script);
			initiateBCVideoPlayer(video);
		}
	};
	// load(bcURL, vOp, vCB);
	console.log(vOp, vCB, bcURL);
}

function initiateBCVideoPlayer() {
	console.log('BC')
}

export function buildVideoParams(url, isBCVideo, position, content) {
	var video = {}
	var match = []
	var playId = ''
	if(url.match(/^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/) != null) {
		video = {
			cid: Math.random(),
			techOrder: ["youtube"],
			sources: [{
				type: "video/youtube",
				src: content.src,
			}],
			position: position,
			rel: 0,
		};
	} else if(url.indexOf("vimeo.com") !== -1) {
		video = {
			cid: Math.random(),
			techOrder: ["vimeo"],
			sources: [{
				type: "video/vimeo",
				src: content.src,
			}],
			position: position,
		};
	} else {
		video = {
			src: url,
			position: position,
		};
	}
	return [video, isBCVideo, playId, match]
}

export function setCurrentContent(content, index) {
	try {
		var type = content.type;
		if(type == 'assessment' && content.pre_test == "Pre-test") {
			type = 'pre-assessment';
		}
		styleCurrentContent(content, index, type);
	} catch (e) {
		console.log(e, e.stack);
	}
}