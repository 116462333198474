import Vue from 'vue/dist/vue.esm'
import 'packs/date.js'
import moment from 'moment'

Vue.component('mylearning_events_list',{
  template:`<div id="event_list">
              <hr class="events_list_content" v-if="index!=0"/>
              <div class="container-fluid" id="container">
                <div class="col-sm-10 padding_left_10 padding_right_0">
                  <div v-bind:id="'mylearning_event_' + event_id" class="mylearning_event_list events_calendar_image">
                    <div class="events_date_font"> {{ event_date_in_calendar }}</div>
                    <div class="events_month_font"> {{ event_month_in_calendar }}</div>
                    <div class="font_events_title"> {{ event_name }} </div>
                    <div class="font_events_details">
                      {{$t('events.start')}}   {{ event_startdatetime }} @ {{ event_starttime }}<br/>
                      {{$t('events.end')}}   {{ event_enddatetime }} @ {{ event_endtime }}<br/>
                      {{ event_createdtime }}
                    </div>
                  </div>
                </div>
              </div>
            </div>`,
  props: {
    item: Object,
    zone: String,
    index: Number,
    length: Number
  },
  data: function() {
    var events = this.item;
    return {
      event_id: events.id,
      event_name: events.name,
      event_date_in_calendar: moment(new Date(events.display_startdatetime)).format('D'),
      event_month_in_calendar: moment(new Date(events.display_startdatetime)).format('MMM'),
      event_startdatetime: moment(new Date(events.display_startdatetime)).format('MMMM D, YYYY'),
      event_enddatetime: moment(new Date(events.display_enddatetime)).format('MMMM D, YYYY'),
      event_starttime: moment(new Date(events.starttime)).utc().format('h:mm a'),
      event_endtime: moment(new Date(events.endtime)).utc().format('h:mm a'),
      event_createdtime: moment(new Date(moment(String(events.event_created_at)).utc().format('MMMM D, YYYY h:mm:ss a'))).fromNow()
    }
  },
  mounted: function() {
    
  },
  methods: {
    
  }
});