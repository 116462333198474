<template>
	<div id="content_wrapper" class="w-100 content" style="height:100%;">
    <div id="content_player_viewer" class="modal_content_height">
      <div class='preview'>
        <div style="height:100%;position:relative;" class="player_preview course_video_player" id='player_preview'>
          <div v-bind:id="'web_text_preview_'+content_id" style="max-width:80%; margin: auto; background: #FFFFFF;" class="modal_content_height"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
	export default {
		name: 'WebtextComponent',
		props: {
			web_text_obj: Object,
			modal_id: String
		},
		data: function() {
			return {
				webtext_content : this.web_text_obj.content_text,
				content_id : this.web_text_obj.content_id
			}
		},
		mounted: function() {
			var x = "web_text_preview_" + this.content_id;
			if(this.web_text_obj.is_last_content) {
				showHideLastContentNotice(false);
			}
			jQuery("#"+x).html(this.webtext_content);
			jQuery("#"+x).niceScroll({cursorcolor:"#4184F3",cursorwidth:"3px"});
			init_tiny_mce('mceWebTextContentCallbackContentPlayer','490', '640');
			tinyMCE.execCommand("mceRemoveControl", false, x);
			tinyMCE.execCommand("mceAddControl", false, x);
		}
	}
</script>