import Vue from 'vue/dist/vue.esm'
const EventBus = new Vue();

Vue.component('groups-layout', {
  template: `<div> 
              <edit-group-tile :edit_group_popup="edit_group_popup" :show_edit_group="show_edit_group"></edit-group-tile>
              <group-detail :group_detail="group_detail" :logged_in="logged_in"></group-detail>
              <div v-if="!group_detail && !loading">  
                <div class="p-3">
                 <div class="d-flex align-items-center justify-content-start">
                  <div class="group_title lxa_text_color_09 lxa_font_size_08 lxa_light_font">{{group_header}}</div>

                  <div v-if="can_create_group" class="ml-auto">                       
                    <button type="button" v-on:click="show_create_group = !show_create_group" class="btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase cursor_pointer"><i class="fad fa-plus mr-1 lxa_font_size_04"></i>CREATE GROUP</button>
                    <div v-if="show_create_group" class="create_group_popup custom_popover">
                      <create-group-section v-bind:show_create_group="true"></create-group-section>
                    </div>
                  </div>
                  </div>

                  <div class="row mx-0">  
                    <div class="col-10 px-0 group_tabs lxa_menu_container margin_bottom_30 margin_top_30">
                      <span v-bind:class="[{'active_tab menu_active': active_tab=='all'},'cursor_pointer menu']" v-on:click="GroupFilter('all')">All ({{group_counts.all_group_count}})</span>
                      <span v-bind:class="[{'active_tab menu_active': active_tab=='member'},'cursor_pointer menu']" v-on:click="GroupFilter('member','member=1')">Member ({{group_counts.members_count}})</span>
                      <span v-bind:class="[{'active_tab menu_active': active_tab=='not_member'},'cursor_pointer menu']" v-on:click="GroupFilter('not_member','not_member=1')">Non Member ({{group_counts.non_member_count}})</span>
                      <span v-bind:class="[{'active_tab menu_active': active_tab=='pending_approval'},'cursor_pointer menu']" v-on:click="GroupFilter('pending_approval','pending_approval=1')">Awaiting Approval ({{group_counts.awaiting_approval_count}})</span>
                    </div>
                   <!-- <div class="col-2 text-right p-0 d-flex flex-row-reverse">
                      <span class="cursor_pointer sort_chevron"><i class="fad fa-chevron-down" aria-hidden="true" style="margin-top: 11px;color:#1E90FF;width:16px;height:16px"></i></span>
                      <select v-on:change="sortGroups()" id="sort_group" class="group_sort font_barlow_semi_condensed_light_14 cursor_pointer">
                        <option value="" disabled selected hidden>Sort</option>
                        <option value="recent">Recently created</option>
                        <option value="asc">A-Z title</option>
                        <option value="desc">Z-A title</option>
                      </select>
                    </div> -->
                  </div>  
                  <div class="row d-flex mx-0" v-if="groups_arr.length != 0">
                    <group-tile v-for="(group,index) in groups_arr" :key="group.group.id" :group="group" :index="index"></group-tile>
                  </div>
                  <div v-else class="lxa_font_size_03 lxa_light_font"> None </div>
                </div>
                <div v-if="current_page < total_pages" class="modal-footer m-3">
                  <button type="button" class="btn lxa_bg_01 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 mx-auto" v-on:click="LoadMore">LOAD MORE</button>
                </div>
              </div> 
              <div v-show="loading" class="text-center">
                <i class="fad fa-spinner fa-spin lxa_font_size_11 lxa_text_color_01 mx-auto"></i>
              </div>
            </div>`,
  props: {
    logged_in: Boolean
  },            
  data: function() {
    return {
      groups_arr: [],
      active_tab: 'all',
      show_create_group: false,
      current_page: 1,
      total_pages: 1,
      group_detail: false,
      edit_group_popup: false,
      show_edit_group: false,
      can_create_group: false,
      group: {},
      group_counts: {},
      site_admin: false,
      loading: true,
      group_header: 'My Groups'
    }
  },
  watch: {
    site_admin() {
      this.group_header = this.site_admin ? 'Groups' : 'My Groups'
    } 
  },
  mounted: function() {
    this.GroupList(false,1);
    jQuery("#groups_modal .modal-body").niceScroll({cursorcolor:"#4184F3",cursorwidth:"3px",autohidemode: "scroll", horizrailenabled: false, nativeparentscrolling: false});
  },
  methods: {
    GroupFilter: function(active_tab,params='') {
      this.active_tab = active_tab;
      this.GroupList(false,1,params);
    },
    LoadMore: function() {
      var params = '';
      if (this.active_tab != 'all') {
        params = '&'+active_tab+'=1'
      }
      this.GroupList(true,this.current_page+1,params);
    },
    sortGroups: function() {
      var sort_by = jQuery("#sort_group").val();     
      var sorting = '&order='+sort_by;
      this.GroupList(false,1,sorting);
    },
    GroupList: function(load_more,current_page,params='') {
      if (this.total_pages >= current_page) {  
        var group_params = '&page='+ current_page+'&per_page='+groups_per_page();
        if (params != '') {
          group_params = group_params+'&'+params;
        }
        this.$http.get('/groups.json?'+group_params).then(function(response) {    
          if (load_more) {
            this.groups_arr = this.groups_arr.concat(response.body.groups_arr);
          } else {
            this.groups_arr = response.body.groups_arr;
          }
          jQuery("groups_modal .modal-body").getNiceScroll().resize();
          this.can_create_group = response.body.can_create_group;
          this.total_pages = response.body.total_pages;
          this.current_page = response.body.current_page;
          this.group_counts = response.body.group_counts;
          this.loading = false;
          this.site_admin = response.body.site_admin
        }); 
      }
    }
  }
});

Vue.component('groups-modal', {
  template: `<div class="modal" id="groups_modal" role="dialog">
              <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                  <p class="light_box_close" data-dismiss="modal"> <i data-toggle="tooltip" data-placement="top" title="Close" class="fad fa-times lxa_text_color_08 lxa_font_size_06 cursor_pointer"></i></p>
                  <edit-group-tile :edit_group_popup="edit_group_popup" :show_edit_group="show_edit_group"></edit-group-tile>
                  <group-detail :group_detail="group_detail" :logged_in="logged_in"></group-detail>
                  <div v-if="!group_detail && !loading">  
                    <div class="modal-body padding_30">
                    <div class="d-flex align-items-center justify-content-start">
                     <div class="group_title lxa_text_color_09 lxa_font_size_08 lxa_light_font">Groups</div>

                      <div v-if="can_create_group" class="ml-auto">                       
                        <button type="button" v-on:click="show_create_group = !show_create_group" class="btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius text-uppercase font_barlow_semi_condensed_light_14"><i class="fad fa-plus mr-3 lxa_font_size_04"></i>CREATE GROUP</button>
                        <div v-if="show_create_group" class="create_group_popup custom_popover">
                          <create-group-section v-bind:show_create_group="true"></create-group-section>
                        </div>
                      </div>

                      </div>
                      
                      <div class="col-sm-12 group_tabs">
                        <span v-bind:class="[{'active_tab': active_tab=='all'},'cursor_pointer font_barlow_semi_condensed_light_18 margin_right_10']" v-on:click="GroupFilter('all')">All({{group_counts.all_group_count}})</span>
                        <span v-bind:class="[{'active_tab': active_tab=='member'},'cursor_pointer font_barlow_semi_condensed_light_18 margin_right_10']" v-on:click="GroupFilter('member','member=1')">Member({{group_counts.members_count}})</span>
                        <span v-bind:class="[{'active_tab': active_tab=='not_member'},'cursor_pointer font_barlow_semi_condensed_light_18 margin_right_10']" v-on:click="GroupFilter('not_member','not_member=1')">Not a Member({{group_counts.non_member_count}})</span>
                        <span v-bind:class="[{'active_tab': active_tab=='pending_approval'},'cursor_pointer font_barlow_semi_condensed_light_18 marmargin_right_10gin_right_5']" v-on:click="GroupFilter('pending_approval','pending_approval=1')">Awaiting Approval({{group_counts.awaiting_approval_count}})</span>
                      </div>
                      <div v-if="groups_arr.length != 0">
                        <group-tile v-for="(group,index) in groups_arr" :key="group.group.id" :group="group" :index="index"></group-tile>
                      </div>
                      <div v-else class="font_barlow_semi_condensed_light_14"> None </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-danger m-auto" v-on:click="LoadMore">load more</button>
                    </div>
                  </div> 
                  <div v-show="loading" class="text-center">
                    <i class="fad fa-spinner fa-spin lxa_font_size_11 text-danger mx-auto"></i>
                  </div> 
                </div>
              </div>
            </div>`,
  props: {
    logged_in: Boolean
  },            
  data: function() {
    return {
      groups_arr: [],
      active_tab: 'all',
      show_create_group: false,
      current_page: 1,
      total_pages: 1,
      group_detail: false,
      edit_group_popup: false,
      show_edit_group: false,
      can_create_group: false,
      group: {},
      group_counts: {},
      loading: true
    }
  },
  mounted: function() {
    this.GroupList(false,1);
    jQuery("#groups_modal .modal-body").niceScroll({cursorcolor:"#4184F3",cursorwidth:"3px",autohidemode: "scroll", horizrailenabled: false, nativeparentscrolling: false});
  },
  methods: {
    GroupFilter: function(active_tab,params='') {
      this.active_tab = active_tab;
      this.GroupList(false,1,params);
    },
    LoadMore: function() {
      var params = '';
      if (this.active_tab != 'all') {
        params = '&'+active_tab+'=1'
      }
      this.GroupList(true,this.current_page+1,params);
    },
    GroupList: function(load_more,current_page,params='') {
      if (this.total_pages >= current_page) {  
        var group_params = '&page='+ current_page+'&per_page='+groups_per_page();
        if (params != '') {
          group_params = group_params+'&'+params;
        }
        this.$http.get('/groups.json?'+group_params).then(function(response) {    
          if (load_more) {
            this.groups_arr = this.groups_arr.concat(response.body.groups_arr);
          } else {
            this.groups_arr = response.body.groups_arr;
          }
          jQuery("groups_modal .modal-body").getNiceScroll().resize();
          this.can_create_group = response.body.can_create_group;
          this.total_pages = response.body.total_pages;
          this.current_page = response.body.current_page;
          this.group_counts = response.body.group_counts;
          this.loading = false;
        }); 
      }
    }
  }
});

Vue.component('group-tile', {
  template: `<div v-bind:id="'group_thumbnail_'+group_id" v-bind:class="['group_thumbnail col-sm-6 col-md-3 col-lg-2 mb-3 pl-0']" >
              <div class="thumbnail border-0 lxa_border_radius overflow-hidden">
                <div class="thumbnail_container">
                  <img v-bind:src="group.logo" class="thumbnail_img" alt="thumbnail" v-on:click="GroupDetail"/>
               </div>
                <div class="group_label pl-1 lxa_bg_01 lxa_text_color_08 lxa_font_size_03 lxa_light_font lxa_border_radius_tlbr">
                  <span v-if="group_member_button == 'Sign Up'">Non Member</span>
                  <span v-else-if="group_member_button == 'Awaiting Approval'">Awaiting Approval</span>
                  <span v-else-if="group_member_button == 'Group Owner'">Owner</span>
                  <span v-else>Member</span>
                </div>
                <div v-if="!detailed_description" class="caption lxa_bg_05 lxa_font_size_03" v-on:mouseover="detailed_description = true">
                  <div class="lxa_text_color_02 lxa_medium_font group_name"> {{group_name}}</div>
                  <div class="lxa_text_color_01 mt-3 lxa_light_font">
                  <span>{{$tc('item.member', member_count, {count: member_count})}} </span>
                    <span>&nbsp&nbsp{{group_topic}}&nbspDiscussion members</span>
                  </div>
                </div>
                <div v-else class="detailed_caption lxa_bg_01 lxa_text_color_08 lxa_font_size_03 lxa_light_font" v-on:mouseleave="detailed_description = false">
                  <div class="lxa_medium_font group_name pb-2"> {{group_name}}</div>
                  <div> Created On: {{founded}}</div>
                  <div> Owner: {{owner_name}} </div>
                  <div class="row margin_top_40 p-0 ml-0 mr-0">
                    <div class="col-sm-7 padding_0">
                      <span data-toggle="tooltip" data-placement="top" title="Sign up" class="cursor_pointer" v-on:click="SignUp()">
                        <i v-if="group_member_button == 'Sign Up'" class="fad fa-plus" style="width: 16px;height: 16px;color:#FFFFFF"></i>
                      </span>
                    </div>
                    <div class="col-sm-5 padding_0 text-right">
                      <span data-toggle="tooltip" data-placement="top" title="Edit" v-if="can_be_updated" class="cursor_pointer" v-on:click="EditGroup()">
                        <i class="fad fa-pencil lxa_icon_size lxa_text_color_08 mr-2"></i>
                      </span>
                      <span data-toggle="tooltip" data-placement="top" title="Delete" v-if="can_be_deleted" class="cursor_pointer" v-on:click="DeleteGroup()">
                        <i v-if="can_be_deleted" class="fad fa-trash-alt lxa_icon_size lxa_text_color_08 mr-2"></i>
                      </span>
                      <span v-if="group_member_button != 'Group Owner'" class="cursor_pointer" v-on:click="CancelGroup()">
                        <i data-toggle="tooltip" data-placement="top" title='Cancel Membership' v-on:click="CancelGroup()" v-if="group_member_button == 'Private Group Member' || group_member_button == 'Member'" class="fad fa-times-circle lxa_icon_size lxa_text_color_08 mr-2"></i>
                        <i data-toggle="tooltip" data-placement="top" title='Cancel Request' v-on:click="CancelGroup()" v-else-if="group_member_button == 'Awaiting Approval'" class="fad fa-times-circle lxa_icon_size lxa_text_color_08 mr-2"></i>
                      </span>
                      <span data-toggle="tooltip" data-placement="top" title="More" class="cursor_pointer" v-on:click="GroupDetail">
                        <i class="fad fa-ellipsis-h lxa_icon_size lxa_text_color_08"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div> 
            </div>`,
  props: {
    group: Object,
    index: Number
  },
  data: function() {
    return {
      detailed_description: false, 
      group_id: this.group.group.id,
      group_name: this.group.group.name,
      owner_text: this.group.owner_text,
      can_be_deleted: this.group.can_be_deleted,
      owner_name: this.group.owner_names,
      member_count: this.group.member_count,
      group_topic: this.group.topic,
      founded: this.group.founded,
      group_description: this.group.group.description,
      group_member_button: this.group.group_member_button,
      member_url: this.group.member_url,
      cancel_url: this.group.cancel_url,
      logo: this.group.logo,
      group_url: this.group.group_url,
      show_more_option: false,
      can_be_updated: this.group.can_be_updated,
      can_be_participated_in_by: this.group.can_by_participated_in_by,
      cancel_text: ''
    }
  },
  computed: {
    margin_right: function(){
      return (this.index+1)%(groups_per_page()/2);
    }
  }, 
  watch: {
    group_member_button: function() {
      if (this.group_member_button == 'Private Group Member' || this.group_member_button == 'Member') {
        this.cancel_text = 'Cancel Membership'
      } else if(this.group_member_button == 'Awaiting Approval') {
        this.cancel_text = 'Cancel Request'
      }
    }
  },
  mounted: function() {
    setTimeout(function() {
      ellipsis('.group_name', 1);
    },20);
  },
  methods: {
    SignUp: function() {
      this.$http.post(this.member_url).then(function(response) {
        if(response.body) {
          this.group_member_button = response.body.group_member_button;
          this.member_count = response.body.member_count;
          this.$parent.group_counts = response.body.group_counts;
          if (this.group_member_button == 'Awaiting Approval') {
            this.$root.popUpMessage('Request Sent Successfully')
          } else {
            this.$root.popUpMessage('Added to Group')
          }
          this.RemoveGroup();
        }
        else {
          this.group_member_button = 'Sign Up'
        }
      });        
    }, 
    DeleteGroup: function() {
      this.$http.delete('groups/'+this.group_id).then(function(response) {
        this.$parent.group_counts = response.body.group_counts;
        this.$root.popUpMessage('Deleted Successfully')
        this.RemoveGroup();  
      });  
    },
    CancelGroup: function() {
      this.$http.post(this.cancel_url).then(function(response) {
        if(response.body) {
          this.group_member_button = 'Sign Up'
          this.$parent.group_counts = response.body.group_counts;
          this.$root.popUpMessage('Cancelled Successfully')
          // this.show_more_option = false;
          this.RemoveGroup();
        }
      });  
    },
    RemoveGroup: function() {
      var index = this.$parent.groups_arr.indexOf(this.group);
      this.$parent.groups_arr.splice(index, 1);
    },  
    GroupDetail: function() {
      if (this.can_be_participated_in_by) {
        this.$http.get(this.group_url).then(function(response) {
          this.$parent.group_detail = true;
          EventBus.$emit("group_detail",response.body);
        });
      }
    },
    EditGroup: function() {
      this.$http.get('groups/'+this.group_id+'/edit.json').then(function(response) {
        this.$parent.show_edit_group = true;
        this.$parent.edit_group_popup = true;
        EventBus.$emit("EditGroupData",response.body);        
      });  
    },     
  }
});

Vue.component('group-detail',{
    template: `<div v-if="group_detail" class="group_detail_container px-3">
              <div>
                <div class="col-sm-12 px-0">
                  <button type="button" v-on:click="Back()" class="btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase cursor_pointer"> <i class="fad fa-angle-left"></i> <span class="ml-1">BACK</span> </button>
                  <!--<span class="cursor_pointer" v-on:click="show_announcements = !show_announcements"><i class="fad fa-bell fa-2x" style="color:#FFA500;float:right"></i></span>
                  <span v-if="total_announcements_count!=0" id="badge_announcement" class="badge badge_icon">{{total_announcements_count}}</span>  
                  <div v-if="new_announcement_link">
                    <button type="button" v-on:click="create_announcement" class="btn btn-success">CREATE ANNOUNCEMENT</button>
                  </div>
                  <div v-if="show_new_form">
                    <group-announcement-form :create_announcement_url="new_announcement_url" :create="true"> </group-announcement-form>
                  </div>
                  <div v-if="show_announcements" class="announcement_popup custom_popover">
                    <announcement-header-popup :group_announcements="group_announcements" :group_new_announcement_url="new_announcement_url" :group_new_announcement_link="new_announcement_link" :from_group="true"/>
                  </div> -->                 
                </div>
                <div class="col-12 px-0 py-3">
                  <span class="lxa_text_color_04 lxa_font_size_05 lxa_light_font">{{group_name}}</span>
                  <span v-if="can_be_updated" class="px-3" v-on:click="EditGroupDetail()">
                    <span class="cursor_pointer" data-toggle="tooltip" title="Edit"><i class="fad fa-pencil lxa_icon_size lxa_text_color_05"></i></span>
                  </span>
                  <span class="cursor_pointer" v-on:click="DeleteGroupDetail()">
                    <span class="cursor_pointer" data-toggle="tooltip" title="Delete"><i v-if="can_be_deleted" class="fad lxa_icon_size fa-trash-alt lxa_text_color_01"></i></span>
                  </span>                  
                </div>
                <div v-if="show_edit_group" class="create_group_popup custom_popover">
                  <create-group-section v-bind:show_create_group="true" :show_edit_group="show_edit_group"></create-group-section>
                </div>
                <div class="row mx-0">
                  <div class="col-lg-1 col-xl-1 col-md-3 col-sm-5 px-0">
                    <img v-bind:src="logo" class="thumbnail_img lxa_border_radius" alt="thumbnail"></img>
                  </div>
                  <div class="col-lg-11 col-xl-11 col-md-9 col-sm-7 pr-0 lxa_text_color_03 lxa_font_size_04 lxa_light_font">
                    <div class="d-flex flex-column align-items-start justify-content-start">
                    <div v-show="truncate" class="group_description">{{group_description}}</div>
                    <div v-show="!truncate">
                      <span> {{group_description}} </span>
                      <span v-on:click="ToggleContent"><i class="fad fa-chevron-up read_more_arrow"></i></span>
                    </div>
                    <div id="group_desc_truncate" v-on:click="ToggleContent" class="hide"></div>
                    <div v-bind:class="[{'position-relative': truncate},'group_options']">
                      <span>{{$tc('item.member', member_count, {count: member_count})}} </span>
                      <span class="group_seperator"></span>
                      <span>{{group_topic}}&nbspDiscussion Topics</span>
                      <span class="group_seperator"></span>
                      <span>Created on:&nbsp{{founded}}</span>
                      <span class="group_seperator"></span>
                      <span>Owner:&nbsp{{owner_name}}</span>
                    </div>
                    </div>
                  </div>
                </div>
                <div v-if="can_be_updated" class="lxa_text_color_03 lxa_font_size_05 lxa_light_font mt-5">                 
                    <div class="schedule_width">
                      <schedule-meeting v-if="!is_feature_disabled_live_event" :group_id="group_id" :new_group_live_event_url="new_group_live_event_url"></schedule-meeting>
                    </div>                  
                </div> 
                <div v-if="can_be_managed_by" class="mt-5">
                  <div class="manage_member_row"> 
                    <manage-members :new_invite_url="new_invite_url" :invitable_type="invitable_type" :invitable_id="group_id" :manage_groups_url="manage_groups_url" :members_count="members_count"></manage-members>
                  </div>
                </div>
                <div class="mt-5">
                  <div class="catalog_group_row"> 
                    <group-catalog-tile :group_products="group_products" :group_id="group_id" :products_current_page="products_current_page" :products_total_pages="products_total_pages" :group_product_url="group_product_url" :logged_in="logged_in"></group-catalog-tile>
                  </div>
                </div>
                <div class="mt-5">
                  <div class="group_discussion_row"> 
                    <group-discussion :new_discussion_url="new_discussion_url" :discussion_url="discussion_url" :group_id="group_id" :topics_followed_count="topics_followed_count" :logged_in="logged_in"></group-discussion>
                  </div>
                </div>                                                     
              </div>
            </div>`,
  props: {
    group_detail: Boolean,
    logged_in: Boolean
  },
  data: function() {
    return {
      group_name: '',
      owner_text: '',
      can_be_deleted: false,
      owner_name: '',
      member_count: 0,
      group_topic: '',
      founded: '',
      group_description: '',
      logo: '',
      can_by_participated_in_by: false,
      is_feature_disabled_chat: false,
      is_feature_disabled_live_event: false,
      new_group_message_url: '',
      send_group_message_url: '',
      new_group_live_event_url: '',
      can_be_managed_by: false,
      new_invite_url: '',
      invitable_type: '',
      manage_groups_url: {},
      members_count: {},
      products_current_page: 0,
      products_total_pages: 0,       
      group_products: [],
      group_product_url: '',
      new_discussion_url: '',
      discussion_url: '',
      topics_followed_count: 0,
      group_id: '',
      show_edit_group: false,
      show_announcements: false,
      total_announcements_count: 0,
      new_announcement_url: '',
      new_announcement_link: '',
      show_new_form: false,
      can_be_updated: false,
      truncate: true
    }
  },
  mounted: function() {
    EventBus.$on("group_detail",this.open);
    setTimeout(function() {
      ellipsis('.group_description', 5, {
        responsive: true,
        replaceStr: '<span onClick="ShowMore(\''+'group_desc_truncate'+'\')" class="cursor_pointer">...</span>'
      });
    },20);
  },
  methods: {
    Back: function() {
     this.$parent.group_detail = false;
     this.show_edit_group = false;
     this.show_announcements = false;
    },
    open: function(data) {
      this.$parent.group_detail = true;
      this.group_name = data.group.group.name;
      this.group_description = data.group.group.description;
      this.logo = data.group.logo;
      this.founded = data.group.founded;
      this.member_count = data.group.member_count;
      this.group_topic = data.group.topic;
      this.owner_name = data.group.owner_names;
      this.can_be_deleted = data.group.can_be_deleted;
      this.can_be_updated = data.group.can_be_updated;
      this.can_by_participated_in_by = data.group.can_by_participated_in_by;
      this.is_feature_disabled_chat = data.group.is_feature_disabled_chat;
      this.is_feature_disabled_live_event = data.group.is_feature_disabled_live_event;
      this.new_group_message_url = data.group.new_group_message_url;
      this.send_group_message_url = data.group.send_group_message_url;
      this.new_group_live_event_url = data.group.new_group_live_event_url;
      this.can_be_managed_by = data.group.can_be_managed_by;
      this.new_invite_url = data.group.new_invite_url;
      this.invitable_type = data.group.invitable_type;
      this.manage_groups_url = data.group.manage_groups_url;
      this.members_count = data.group.members_count;
      this.group_products = data.group.products;
      this.products_current_page = data.group.current_page;
      this.products_total_pages = data.group.total_pages;
      this.group_product_url = data.group.group_product_url;
      this.new_discussion_url = data.group.new_discussion_url;
      this.discussion_url = data.group.discussion_url;
      this.topics_followed_count = data.group.topics_followed_count;
      this.group_id = data.group.group.id;
      this.group_private = data.group.group.is_private;
      this.group_auto_member = data.group.group.auto_approve_members;
      this.group_announcements = data.group.announcements.announcements;
      this.new_announcement_url = data.group.announcements.new_announcement_url;
      this.new_announcement_link = data.group.announcements.new_announcement_link;
      this.total_announcements_count = data.group.total_announcements_count; 
      setTimeout(function() {
        ellipsis('.group_description', 5, {
          responsive: true,
          replaceStr: '<span onClick="ShowMore(\''+'group_desc_truncate'+'\')" class="cursor_pointer">...</span>'
        });
      },50);   
    },
    DeleteGroupDetail: function() {
      this.$http.delete('groups/'+this.group_id).then(function(response) {
        this.$parent.$parent.show_groups = false;
        jQuery('#groups_modal').modal('toggle');
      });  
    },
    EditGroupDetail: function() {
      this.show_edit_group = true;  
    },  
    create_announcement: function() {
      this.show_new_form = !this.show_new_form;
    },
    ToggleContent: function() {
      this.truncate = !this.truncate;
    }    
  }
});

Vue.component('edit-group-tile',{
    template: `<div v-if="edit_group_popup">
              <div>
                <div v-if="show_edit_group" class="create_group_popup custom_popover">
                  <create-group-section v-bind:show_create_group="true" :show_edit_group="show_edit_group" :edit_group_popup="edit_group_popup"></create-group-section>
                </div>
              </div>
            </div>`,
  props: {
    edit_group_popup: Boolean,
    show_edit_group: Boolean
  },
  data: function() {
    return {
      group_name: '',
      member_count: 0,
      group_description: '',
      logo: '',
      group_id: '',
      group_private: '',
      group_auto_member: ''
    }
  },
  mounted: function() {
    EventBus.$on("EditGroupData",this.open);
  },
  methods: {
    open: function(data) {
      this.group_name = data.group.name;
      this.group_description = data.group.description;
      this.logo = data.logo;
      this.member_count = data.group.member_count;
      this.group_private = data.group.is_private;
      this.group_auto_member = data.group.auto_approve_members;
      this.group_id = data.group.id;
    },     
  }
});

Vue.component('send-group-chat',{
    template: `<div class="col-sm">
                  <div class="padding_bottom_12 blue_color_group"> Send Group Chat </div>
                  <div class="send_group_container">
                    <div class="padding_top_13">
                      <div class="no_chat_history font_barlow_semi_condensed_light_14">No chat history available</div>
                      <form v-bind:id="'group_chat_id_'+group_id" v-on:submit.prevent="onSubmit" role="form">
                        <textarea class="margin_top_2 group_chat_textarea form-control font_barlow_semi_condensed_light_14" name="group_chat[body]" placeholder="Type here" v-validate="'required'" data-vv-validate-on="none" v-model="group_chat"></textarea>
                        <div class="submit_cont margin_top_10">
                          <button type="submit" class="group_submit blue_background_group pull-right btn">{{$t('common.save')}}</button>
                          <button class="group_cancel pull-right btn">{{$t('common.cancel')}}</button>
                        </div>
                      </form>
                    </div>  
                  </div>                    
                </div>`,
  props: {
    group_id: Number
  },
  data: function() {
    return{
      group_chat: ''
    }
  }
});

Vue.component('send-group-message',{
    template: `<div class="col-sm">
                  <div class="padding_bottom_12 orange_color_group"> Send Group Message </div>
                  <div class="send_group_container">
                    <div class="padding_top_13">
                      <form v-bind:id="'group_message_id_'+group_id" v-on:submit.prevent="onSubmit" role="form">
                        <textarea class="margin_top_2 group_message_textarea form-control font_barlow_semi_condensed_light_14" name="group_message[body]" placeholder="Type here" v-validate="'required'" data-vv-validate-on="none" v-model="group_message"></textarea>
                        <div class="submit_cont margin_top_10">
                          <button type="submit" class="group_submit orange_background_group pull-right btn">{{$t('common.save')}}</button>
                          <div class="font_barlow_semi_condensed_14 group_success_msg success_msg hide" v-bind:id="group_id+'_send_group_message_success_msg'">{{$t('message.after_send_message')}}</div>
                        </div>
                      </form>
                      <button class="group_cancel pull-right btn" v-on:click="cancelMessage()">{{$t('common.cancel')}}</button>
                    </div>  
                  </div>  
                </div>`,
  props: {
    group_id: Number,
    new_group_message_url: String,
    send_group_message_url: String
  },
  data: function() {
    return{
      group_message: ''
    }
  },
  mounted: function() {
    this.$http.get(this.new_group_message_url).then(function(response) {       
    });  
  },
  methods: {
    onSubmit: function() {
      this.$validator.validateAll().then(result => {
        if (result) {
          var params = '&message='+this.group_message;
          this.$http.post(this.send_group_message_url+'.json?'+params).then(function(response) {
            if(response.body == true) {
              this.group_message = '';
              jQuery("#"+this.group_id+"_send_group_message_success_msg").removeClass('hide').addClass('show');             
            }
          });
        }
      });
      setTimeout(function() {
        jQuery('.group_success_msg').removeClass('show').addClass('hide');
      },3500);                  
    },
    cancelMessage: function() {
      this.group_message = '';
      jQuery("#"+this.group_id+"_send_group_message_success_msg").removeClass('show').addClass('hide');      
    }    
  }
});

Vue.component('schedule-meeting',{
    template: `<div class="col-12 px-0">
                  <div class="lxa_text_color_01 lxa_light_font"> Schedule Meeting </div>
                  <div>
                    <div class="pt-4">
                      <div class="new_group_live_event">
                        <form v-bind:id="'schedule_meeting_id_'+group_id" class="d-flex align-items-center justify-content-start" v-on:submit.prevent="onSubmit" role="form">
                          <div class="w-50 mr-3">
                            <div class="lxa_text_color_03 lxa_font_size_03 lxa_light_font pb-2"> Title: </div>
                            <div class=""><input type="text" name="meeting_title" class="lxa_bg_05 form-control input-sm p-2 height_40px lxa_border_radius border-0" v-validate="'required'" data-vv-validate-on="none" v-model="meeting_title" id="meeting_title"></div>
                          </div>  
                          <div class="w-25 mr-3">
                            <div class="lxa_text_color_03 lxa_font_size_03 lxa_light_font pb-2"> Start Time: </div>
                            <div class="d-flex"> 
                              <input type="text" id="meeting_start_date" name="meeting_start_date" class="lxa_bg_05 input-sm p-2 height_40px lxa_border_radius border-0 mr-3" v-validate="'required'" data-vv-validate-on="none" v-model="meeting_start_date" readonly>
                              <input type="text" id="meeting_start_time" name="meeting_start_time" class="lxa_bg_05 input-sm p-2 height_40px lxa_border_radius border-0" v-validate="'required'" data-vv-validate-on="none" v-model="meeting_start_time" readonly>
                            </div>
                          </div>
                          <div class="mr-3">
                            <div class="lxa_text_color_03 lxa_font_size_03 lxa_light_font pb-2"> Duration: </div>
                            <div class=""> 
                              <select class="lxa_bg_05 input-sm p-2 height_40px lxa_border_radius border-0 dropdown_duration" id="meeting_duration">
                                <option v-for="duration in durations" v-bind:value="duration">{{duration[0]}}</option>
                              </select>
                            </div>
                          </div>
                          <div class="submit_cont mt-3">
                          <div class="d-flex align-items-center justify-content-start">
                            <button v-on:click="ClearData" class="group_cancel btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase">{{$t('common.clear').toUpperCase()}}</button>
                            <button type="submit" class="group_submit red_background_group btn btn lxa_bg_01 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase ml-3">{{$t('common.send').toUpperCase()}}</button>
                          </div>
                          </div>
                        </form>
                      </div>  
                    </div>  
                  </div>                   
                </div>`,
  props: {
    group_id: Number,
    new_group_live_event_url: String
  },
  data: function() {
    return{
      meeting_title: '',
      meeting_start_date: Date.now().toString("MMM d, yyyy"),
      meeting_start_time: Date.now().add({minutes: (30 - (Date.now().getMinutes() % 30))}).toString("h:mm tt").toLowerCase(),
      send_group_live_event_url: '',
      live_event: {},
      durations: [],
      default_title: '',
      selected_groups: []
    }
  },
  mounted: function() {
    DateTimePicker('meeting_start_date','M d, Y',true,false);
    DateTimePicker('meeting_start_time','g:i a',false,true); 
    this.MeetingData();    
  },
  methods: {
    onSubmit: function() {
      this.$validator.validateAll().then(result => {
        if (result) {         
          var meeting_duration = jQuery("#meeting_duration").val();
          var new_group_attributes_hash = {group_id: this.group_id.toString()};
          var dt = new Date(jQuery("#meeting_start_date").val() + ' ' + jQuery("#meeting_start_time").val());
          meeting_duration = /,(.+)/.exec(meeting_duration)[1];
          this.live_event.scheduled_start_display_date = jQuery("#meeting_start_date").val();
          this.live_event.scheduled_start_display_time = jQuery("#meeting_start_time").val();
          this.live_event.title = jQuery("#meeting_title").val();
          this.live_event.new_group_attributes = [new_group_attributes_hash];
          this.live_event.scheduled_start_time = dt.toISOString();            
          dt.setSeconds( dt.getSeconds() + meeting_duration );
          var date = dt.toISOString();
          this.live_event.scheduled_end_time = date;
          this.live_event.scheduled_end_display_date = new Date(date).toString("MMM d, yyyy");
          this.live_event.scheduled_end_display_time = new Date(date).toString("h:mm tt");      
          this.$http.post(this.send_group_live_event_url+'&group_id='+this.group_id+'&live_event_groups[]='+this.selected_groups+'&meeting_duration='+meeting_duration+'&default_title='+this.default_title,{live_event: this.live_event}).then(function(response) {
            if(response.status == 200){
              this.$root.popUpMessage("Schedule Meeting has created successfully");
              this.$parent.group_products = response.body.group.products;
              this.$parent.products_current_page = response.body.group.current_page;
              this.$parent.products_total_pages = response.body.group.total_pages;
              this.meeting_title = response.body.group.title;
              this.default_title = response.body.group.title;         
            }
            else{
              this.$root.popUpMessage("Could not created Schedule Meeting. Please check the values");
            }
          });           
        }
      });          
    }, 
    ClearData: function() {
      this.MeetingData();
    },
    MeetingData: function() {
      this.$http.get(this.new_group_live_event_url).then(function(response) {
        this.meeting_title = response.body.title;
        this.live_event = response.body.live_event;
        this.durations = response.body.durations;
        this.selected_groups = response.body.selected_groups;
        this.send_group_live_event_url = response.body.send_group_live_event_url;
        this.default_title = response.body.title;
      }); 
    }
  }
});

Vue.component('manage-members',{
    template: `<div class="col-sm px-0">
                  <div class="row mx-0 member_header">
                    <div class="col px-0 lxa_text_color_05 lxa_font_size_05 lxa_light_font"> Manage members</div>                
                  </div>
                  <div class="manage_member_container">
                    <div class="d-flex align-items-center justify-content-start mt-3 mb-4">
                      <div class="col px-0 lxa_menu_container mr-auto">
                        <span v-bind:class="[{'active_tab menu_active': active_tab=='active'},'cursor_pointer menu']" v-on:click="ManageMemberFilter('active',group_active_memberships)">Active&nbsp({{this.members_count.active_membership_count}})</span>
                        <span v-bind:class="[{'active_tab menu_active': active_tab=='awaiting_approval'},'cursor_pointer menu']" v-on:click="ManageMemberFilter('awaiting_approval',group_awaiting_approval)">Awaiting Approval&nbsp({{this.members_count.awaiting_approval_count}})</span>
                        <span v-bind:class="[{'active_tab menu_active': active_tab=='invited'},'cursor_pointer menu']" v-on:click="ManageMemberFilter('invited',group_invited_users)">Invited&nbsp({{this.members_count.invited_count}})</span>
                        <span v-bind:class="[{'active_tab menu_active': active_tab=='declined'},'cursor_pointer menu']" v-on:click="ManageMemberFilter('declined',group_declined_users)">Declined&nbsp({{this.members_count.declined_count}})</span>
                        <span v-bind:class="[{'active_tab menu_active': active_tab=='canceled'},'cursor_pointer menu']" v-on:click="ManageMemberFilter('canceled',group_canceled_memberships)">Canceled&nbsp({{this.members_count.canceled_count}})</span>                    
                      </div> 

                      <div class="mr-3 d-flex position-relative">               
                      <input id="lxa_group_member_search" type="text" class="member_search lxa_border_radius_tlbl lxa_padding_l lxa_search_txt_color lxa_font_size_03 lxa_bg_05 search-bar-open lxa_search_txt_color border-0" :placeholder="$t('search.search_str')" v-model="keyword" v-on:keyup="searchMember()">
                      <div class="input-group-append">
                         <button type="button" tabindex="2" class="search_box_submit px-2 lxa_border_radius_trbr lxa_padding_lr"><i class="fad fa-search lxa_icon_size lxa_search_icon"></i></button>
                      </div>
                      </div>

                      <div class="mr-3 d-flex align-items-center justify-content-start position-relative lxa_border_radius lxa_sort_height">  
                   
                    <sort-drop-down :from_section="'group_items'" :show_sort="show_sort" :sorting_option="sorting_option" @selectCallBack="sortMember" />
                    </div>  
                      <button type="button" v-on:click="newInvite()" class="btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase cursor_pointer"><i class="fad fa-plus" aria-hidden="true"></i> <span>invite member</span> </button> 
                      <div v-if="show_invite" class="new_invite_popup custom_popover">
                        <new-invite-section :invitable_type="invitable_type" :invitable_id="invitable_id"></new-invite-section>
                      </div>  
                    </div>
                    <div v-if="memberships_count > 0" class="row mx-0 manage_member_contents">
                      <div class="card_horizontal col-sm-6 col-md-4 col-xl-3 col-lg-4 pb-3 px-0" v-for="(membership,index) in membership_arr">
                        <div class="padding_0">
                          <img v-bind:src="membership.logo" class="thumbnail_img lxa_border_radius" alt="thumbnail"></img>
                        </div>
                        <div class="card-body py-0 pr-0 lxa_font_size_04 lxa_light_font">
                          <div v-if="!membership.invitation" class="member_details d-flex flex-column h-100">
                            <div class="member_name lxa_text_color_09 mb-2">{{membership.display_name}}</div>
                            <div v-if="membership.joined_on">Joined on {{membership.joined_on}}</div>
                            <div v-else-if="membership.declined_on">Declined on {{membership.declined_on}}</div>
                            <div v-else-if="membership.deleted_on">Deleted on {{membership.deleted_on}}</div>
                            <div v-if="membership.joined_on" class="topics_count d-flex mt-2">
                              <span v-if="membership.topics_count>0"> {{membership.topics_count}} &nbspTopics</span>
                              <span v-if="membership.replies_count > 0"> {{membership.replies_count}} &nbspReplies</span>
                            </div>
                            <div class="mt-auto" v-if="membership.user_membership_hash.membership">
                              <div v-if="membership.user_membership_hash.accepted">
                                <div v-if="!(multiple_owners) && membership.user_membership_hash.is_owner" class="d-flex align-items-center justify-content-start">
                                  <span data-toggle="tooltip" data-placement="top" :title="membership.user_membership_hash.remove_tooltip_message" class="mr-3"><i class="fad fa-user-crown lxa_text_color_09"></i></span>
                                  <span data-toggle="tooltip" data-placement="top" :title="membership.user_membership_hash.cancel_tooltip_message"><i class="fad fa-trash lxa_text_color_09"></i></span>
                                </div>
                                <div v-else>
                                  <div v-if="membership.user_membership_hash.is_owner">
                                    <span v-on:click="approachBy(membership.user_membership_hash.remove_owner_url,false,index)" data-toggle="tooltip" data-placement="top" title="Remove Owner"><i class="fa fa-user-crown" style="color: #FFA500;width: 14px;height: 14px;cursor: pointer;position: absolute;margin-top: 15px"></i></span>
                                    <span v-on:click="cancelMembership(membership.user_membership_hash.cancel_membership_url,index)" data-toggle="tooltip" data-placement="top" :title="membership.user_membership_hash.is_owner_cancel_tooltip_message"><i class="fa fa-trash" style="color: #FFA500;width: 14px;height: 14px;cursor: pointer;position: absolute;margin-top: 15px;margin-left: 26px"></i></span>
                                  </div>
                                  <div v-else>
                                    <span v-on:click="approachBy(membership.user_membership_hash.add_owner_url,false,index)" data-toggle="tooltip" data-placement="top" title="Add as Owner"><i class="fa fa-user-crown" style="color: #FFA500;width: 14px;height: 14px;cursor: pointer;position: absolute;margin-top: 15px"></i></span>
                                    <span v-on:click="cancelMembership(membership.user_membership_hash.cancel_membership_url,index)" data-toggle="tooltip" data-placement="top" title="Cancel membership"><i class="fa fa-trash" style="color: #FFA500;width: 14px;height: 14px;cursor: pointer;position: absolute;margin-top: 15px;margin-left: 26px"></i></span>
                                  </div>                                  
                                </div>
                              </div>
                              <div v-else-if="membership.user_membership_hash.pending">
                                <span v-on:click="approachBy(membership.user_membership_hash.approve_membership_url,true,index)" data-toggle="tooltip" data-placement="top" title="Approve Member"><i class="fa fa-user-crown" style="color: #FFA500;width: 14px;height: 14px;cursor: pointer;position: absolute;margin-top: 15px"></i></span>
                                <span v-on:click="approachBy(membership.user_membership_hash.reject_membership_url,true,index)" data-toggle="tooltip" data-placement="top" title="Reject Member"><i class="fa fa-trash" style="color: #FFA500;width: 14px;height: 14px;cursor: pointer;position: absolute;margin-top: 15px;margin-left: 26px"></i></span>
                              </div>
                            </div>
                            <div class="mt-3" v-else>
                                <span v-on:click="approachBy(membership.user_membership_hash.restore_membership_url,true,index)" data-toggle="tooltip" data-placement="top" title="Restore Membership"><i class="fa fa-user-crown" style="color: #FFA500;width: 14px;height: 14px;cursor: pointer;position: absolute;margin-top: 15px"></i></span>
                            </div>
                          </div>  
                          <div v-else class="member_details padding_0 font_barlow_semi_condensed_light_14">
                            <div class="member_name">{{membership.display_name}}</div>
                            <div v-if="membership.invited_on != ''">Invited on {{membership.invited_on}}</div>
                            <div v-else-if="membership.declined_on != ''">Declined on {{membership.declined_on}}</div>
                            <div class="d-flex mt-4">
                              <span v-on:click="ResendInvitation(membership.resend_inivitation_url)" data-toggle="tooltip" data-placement="top" title="Resend Invitation"><i class="fa fa-user-crown" style="color: #FFA500;width: 14px;height: 14px;cursor: pointer;position: absolute;margin-top: 15px"></i></span>
                              <span v-if="membership.pending" v-on:click="approachBy(membership.cancel_invitation_url,true,index)" data-toggle="tooltip" data-placement="top" title="Cancel invitation"><i class="fa fa-trash" style="color: #FFA500;width: 14px;height: 14px;cursor: pointer;position: absolute;margin-top: 15px;margin-left: 26px"></i></span>
                              <span v-else v-on:click="approachBy(membership.cancel_invitation_url,true,index)" data-toggle="tooltip" data-placement="top" title="Dismiss"><i class="fa fa-trash" style="color: #FFA500;width: 14px;height: 14px;cursor: pointer;position: absolute;margin-top: 15px;margin-left: 26px"></i></span>
                            </div>
                          </div>
                        </div>
                      </div>                                        
                    </div>
                    <div v-else class="font_barlow_semi_condensed_light_14"> None </div>  
                  </div>    
                </div>`,
  props: {
    manage_groups_url: Object,
    members_count: Object,
    new_invite_url: String,
    invitable_type: String,
    invitable_id: Number
  },
  data: function() {
    return{
      active_tab: 'all',
      active_url: '',
      group_active_memberships: this.manage_groups_url.group_active_memberships,
      group_awaiting_approval: this.manage_groups_url.group_awaiting_approval,
      group_invited_users: this.manage_groups_url.group_invited_users,
      group_declined_users: this.manage_groups_url.group_declined_users,
      group_canceled_memberships: this.manage_groups_url.group_canceled_memberships,
      all_membership_count: this.members_count.all_membership_count,
      active_membership_count: this.members_count.active_membership_count,
      awaiting_approval_count: this.members_count.awaiting_approval_count,
      invited_count: this.members_count.invited_count,
      declined_count: this.members_count.declined_count,
      canceled_count: this.members_count.canceled_count,
      memberships_count: 0,
      membership_arr: [],
      multiple_owners: false,
      url: '',
      keyword: '',
      show_invite: false,
      sorting_option: [
        {id:1,title: 'A to Z', value: 'asc'},
        {id:2,title: 'Z to A', value: 'desc'},
      ],
      show_sort: false,
    }
  },
  mounted: function() {
    this.ManageMemberFilter('active',this.group_active_memberships);
  },
  methods: {
    ManageMemberFilter: function(active_tab,active_url,params='') {
      this.active_tab = active_tab;
      this.active_url = active_url;    
      this.url = active_url+'.json?'+params;
      if (active_tab == 'all') {
        this.url = this.url+'&filter=all'
      }
      else{
        this.url = this.url+'&filter='+active_tab
      }
      this.$http.get(this.url).then(function(response) {
        this.memberships_count = response.body.memberships_count;
        this.$parent.members_count = response.body.members_count;
        this.multiple_owners = response.body.multiple_owners;
        this.membership_arr = response.body.membership_arr;
      });
    },
    sortMember: function(value) {
      var sorting = '&order='+value;
      this.ManageMemberFilter(this.active_tab,this.active_url,sorting);
    },
    newInvite: function() {
      var params = '&invitable_type='+this.invitable_type+'&invitable_id='+this.invitable_id;
      this.$http.get(this.new_invite_url+'.json?'+params).then(function(response) {
        this.show_invite = true;
      });
    },
    searchMember: function() {
      var key = this.keyword.toLowerCase();
      jQuery(".member_details .member_name").each( function() {
        var member_str = jQuery(this).text().toLowerCase();
        if (member_str.indexOf(key)!=-1) {
          jQuery(this).parent().parent().parent().show();
        }
        else {
          jQuery(this).parent().parent().parent().hide();
        }
      });      
    },
    cancelMembership: function(url,index) {
      this.$http.delete(url).then(function(response) {
        this.membership_arr.splice(index,1);
        this.$parent.members_count = response.body.members_count;
        this.$root.popUpMessage(response.body.message);
      });      
    },
    approachBy: function(url,remove,index) {
      this.$http.put(url).then(function(response) {
        if (response.body.success) {
          this.$parent.members_count = response.body.members_count;
          if (remove) {
            this.membership_arr.splice(index,1);
          } else {
            this.membership_arr = response.body.membership_arr;
          }
          this.$root.popUpMessage(response.body.message);
        } 
      });
    },
    ResendInvitation: function(url) {
      this.$http.get(url).then(function(response) {
      });
    }                 
  }
});

Vue.component('new-invite-section', {
  template: `<div>
              <div class="mb-3 lxa_font_size_03 lxa_regular_font"> Send invitations to :</div>  
              <form id="new_invite" class="form-horizontal " v-on:submit.prevent="onSubmit" role="form">
                <div class="container-fluid padding_0">
                  <div class="mb-2 lxa_font_size_03 lxa_light_font">To</div>
                  <div class="form-group m-0">
                    <div class="col-sm-12 control px-0">
                      <input id="invite_to" type="text" class="form-control input-sm tags message_input primary_bgcolor w-100 p-2 lxa_border_radius border-0 height_40px hide" v-validate="'required'" data-vv-validate-on="none" name="user_ids" :placeholder="$t('message.to')" autocomplete="off">
                       <newTagInput v-bind:recipient_err="errors.has('user_ids')" />
                      <div class="error_container">
                        <span id="error_on_user_list" class ="font_open_sans_regular_11_red inbox_err inline_block hide"></span>
                      </div>
                    </div>
                  </div>
                  <div class="mb-2 lxa_font_size_03 lxa_light_font">Message</div>
                  <div id="invite_message" class="form-group">
                    <div class="col-sm-12 px-0">
                      <textarea class="text_message primary_bgcolor w-100 p-2 lxa_border_radius border-0 txtarea-resize lxa_font_size_04" v-validate="'required'" data-vv-validate-on="none" name="invite[message]" placeholder="Message"></textarea>
                    </div>
                  </div>
                  <div>
                    <div class="submit_cont d-flex align-items-center justify-content-end">                  
                      <button class="group_cancel btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" v-on:click="cancelInvite()">{{$t('common.cancel').toUpperCase()}}</button>
                      <button type="submit" class="group_submit btn lxa_bg_02 ml-2 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase ml-3">{{$t('common.send').toUpperCase()}}</button>
                    </div>
                  </div>
                  <div class="success_msg hide margin_top_10">{{$t('share_popup.send_invite').toUpperCase()}}</div>
                </div>
              </form>               
            </div>`,
  props: {
    invitable_type: String,
    invitable_id: Number
  },
  mounted: function() {
    self = this;
    jQuery('#newTagsInput').typeahead({
      displayField: "email",
      ajax: {
        url: '/home/auto_complete_for_username.json',
        method: 'get',
        triggerLength: 1
      },
      valueField: "name",
      onSelect: function(e) {updateElementForMessages(e);}
    });
    jQuery("input#newTagsInput").focus();
    jQuery(".newTagsList").addClass('invite_to');

    jQuery('.newTagsList').on('DOMNodeInserted', '.dropdown-menu', function (){
     self.resize_dropdownlist();
    });
    window.addEventListener('resize', this.resize_dropdownlist);
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.resize_dropdownlist);
  },    
  methods: {
    resize_dropdownlist: function() {
      var flag = false;
      jQuery(".dropdown-menu").css("margin-left", "-6px");
      setTimeout(function() {
        jQuery(".dropdown-menu").removeClass('max_content_dropdown');
        jQuery(".dropdown-menu li").each(function(index) {
          if (this.offsetWidth < this.scrollWidth) {
            flag =  true;
          }
        });
        if(flag){
          jQuery(".dropdown-menu").addClass('max_content_dropdown');
        }
      }, 50);
    },
    cancelInvite: function() {
      this.$parent.show_invite = false;
    },
    onSubmit: function() {
      self=this;
      jQuery("#error_on_user_list").removeClass('show').addClass('hide');
      this.$validator.validateAll().then(result => {
        if (result) {
          var params = '&invitable_type='+this.invitable_type+'&invitable_id='+this.invitable_id;
          this.$http.post('/invites.json?'+params+'&'+jQuery('#new_invite').serialize()).then(response => {
            if (response.body.success == true) {
              this.$parent.show_invite = false;
            } else {
              jQuery("#error_on_user_list").text(response.body.errors);
              jQuery("#error_on_user_list").removeClass('hide').addClass('show');;
            }
          });
        }
      });
    },
  }
});


Vue.component('group-catalog-tile',{
    template: `<div class="col-sm px-0">
                <div class="row mx-0">
                  <div class="col px-0 lxa_text_color_09 lxa_font_size_05 lxa_light_font header_title">Group Catalog</div>
                </div>
                <div class="catalog_group_container d-flex my-4">
                  <div v-if="group_products.length != 0" class="row col-12 p-0 m-0">
                    <item-tile :item="item" :index="index" :key="item.item_type+'_'+item.id" v-for="(item,index) in group_products" :logged_in="logged_in" :from_catalog="true" :from_group="true" :from_similar_course="true"/>
                    <a class="carousel_arrow carousel-control-prev" v-if="prev_icon" v-on:click="prev_page_group_products()" role="button" data-slide="prev">
                      <div class="prev_icon_box">
                        <span class="carousel-control-prev-icon" aria-hidden="true">
                         <i class="fad fa-chevron-left"></i>
                        </span>
                        <span class="sr-only">Previous</span>
                      </div>
                    </a>
                    <a class="carousel_arrow carousel-control-next" v-if="next_icon || (products_total_pages > 1)" v-on:click="next_page_group_products()" role="button" data-slide="next">
                      <div class="next_icon_box">
                        <span class="carousel-control-next-icon" aria-hidden="true">
                         <i class="fad fa-chevron-right"></i>
                        </span>
                        <span class="sr-only">Next</span>
                      </div>
                    </a>                    
                  </div>
                  <div v-else class="font_barlow_semi_condensed_light_14"> None </div>  
                </div>    
              </div>`,
  props: {
    group_products: Array,
    group_id: Number,
    products_current_page: Number,
    products_total_pages: Number,
    group_product_url: String,
    logged_in: Boolean
  },
  data: function() {
    return {
      prev_icon: false,
      next_icon: false
    }
  }, 
  mounted: function() {
    if(this.products_total_pages > 1) {
      this.next_icon=true;
    }    
  },
  methods: {
    next_page_group_products: function() {
      var self = this;
      var new_page = this.products_current_page + 1;
      var params_per_page = '&from_group_products=true'+'&page='+new_page+'&per_page='+4;
      this.prev_icon=true;
      if(new_page == this.products_total_pages) {
        this.next_icon=false;
      }
      this.$http.get(this.group_product_url+'.json?'+params_per_page).then(function(response) {
        this.$parent.group_products = response.body.group.products;      
        this.$parent.products_current_page = response.body.group.current_page;
        this.$parent.products_total_pages = response.body.group.total_pages;           
      });
    },  
    prev_page_group_products: function() {
      var self = this;
      var new_page = this.products_current_page - 1;
      var params_per_page = '&from_group_products=true'+'&page='+new_page+'&per_page='+4;
      if(new_page == 1) {
        this.prev_icon=false;
      }
      if(new_page != this.products_total_pages) {
        this.next_icon=true;
      }
      this.$http.get(this.group_product_url+'.json?'+params_per_page).then(function(response) {
        this.$parent.group_products = response.body.group.products;
        this.$parent.products_current_page = response.body.group.current_page;
        this.$parent.products_total_pages = response.body.group.total_pages;
      });
    }   
  }
});

Vue.component('group-discussion',{
    template: `<div class="col-sm px-0">
                  <div class="discussion_header">
                    <div class="col px-0 lxa_text_color_01 lxa_font_size_05 lxa_light_font header_title">Group Discussion</div>                 
                  </div>
                  <div class="group_discussion_container">
                    <div class="pb-3">
                      <div class="d-flex align-items-center justify-content-start mt-3 mb-4">
                        <div class="col px-0 lxa_menu_container mr-auto">
                          <span v-bind:class="[{'active_tab menu_active': active_tab=='all_topics'},'cursor_pointer menu']" v-on:click="GroupDiscussionFilter('all_topics', 'tab=true')">All Topics&nbsp({{topics_count}})</span>
                          <span v-bind:class="[{'active_tab menu_active': active_tab=='followed_topics'},'cursor_pointer menu']" v-on:click="GroupDiscussionFilter('followed_topics', 'tab=true&followed_topics=true')">Followed Topics&nbsp({{followed_topics_count}})</span>                 
                        </div> 
                        <div class="mr-3 d-flex position-relative"> 
                          <input type="text" id="lxa_group_discuss_search" class="discussion_search lxa_border_radius_tlbl lxa_padding_l lxa_search_txt_color lxa_font_size_03 lxa_bg_05 search-bar-open lxa_search_txt_color border-0" :placeholder="$t('search.search_str')" v-model="keyword" v-on:keyup="searchDiscussion()">
                          <div class="input-group-append">
                             <button type="button" tabindex="2" class="search_box_submit px-2 lxa_border_radius_trbr lxa_padding_lr"><i class="fad fa-search lxa_icon_size lxa_search_icon"></i></button>
                          </div>
                        </div>  
                        <div class="mr-3 d-flex align-items-center justify-content-start position-relative lxa_border_radius lxa_sort_height">  
                      
                         <sort-drop-down :from_section="'group_discussion_items'" :show_sort="show_sort" :sorting_option="sorting_option" @selectCallBack="sortDiscussion" />
                        </div>  
                        <button type="button" v-on:click="newDiscussion()" class="btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase cursor_pointer text-uppercase"><i class="fad fa-plus" aria-hidden="true"></i> <span>new discussion</span></button>
                        <div v-if="show_discussion" class="new_discussion_popup custom_popover">
                          <new-discussion-section :create_discussion_url="discussion_url"></new-discussion-section>
                        </div>      
                      </div>                 
                    </div>
                    <div v-bind:id="'topics_group_'+group_id" class="col-12 p-0 group_discussion_contents">
                      <div v-if="topics.length != 0" class="col-12 padding_0">
                        <group-discussion-list v-for="(discussion_topic,index) in discussion_topic_arr" :discussion_topic="discussion_topic" :key="discussion_topic.topic.id" :comment_url="discussion_topic.comment_url" :logged_in="logged_in"></group-discussion-list>
                      </div>
                      <div v-else class="font_barlow_semi_condensed_light_14"> None </div>                                             
                    </div>
                  </div>  
                </div>`,
  props: {
    new_discussion_url: String,
    discussion_url: String,
    group_id: Number,
    topics_followed_count: Number,
    logged_in: Boolean
  },
  data: function() {
    return{
      active_tab: 'all_topics',
      topics: [],
      discussion_topic_arr: [],
      url: '',
      keyword: '',
      topics_params: '',
      show_discussion: false,
      topics_count: 0,
      followed_topics_count: 0,
      current_page: 1,
      total_pages: 1,
       sorting_option: [
        {id:1,title: 'Newest Topic', value:"newest_topic"},
        {id:2,title: 'Recently Replied', value:"recently_replied"},
        {id:3,title: 'Most Replied', value:"most_replied"},
        {id:4,title:'Most Liked', value:"most_liked"},
        {id:5,title:'Most Followed', value:"most_followed"}
      ],
        show_sort: false,
    }
  },
  mounted: function() {
    this.GroupDiscussionFilter('all_topics','tab=true');
    this.$nextTick(() => {
      jQuery(".group_discussion_contents").niceScroll({cursorcolor:"#4184F3",cursorwidth:"3px",autohidemode: 'scroll',nativeparentscrolling: false});
    })
  },
  updated: function(){
    this.$nextTick(() => {
      jQuery(".group_discussion_contents").getNiceScroll().resize();
    })
  }, 
  methods: {
    GroupDiscussionFilter: function(active_tab,params='') {
      this.active_tab = active_tab;
      this.topics_params = params;  
      this.url = this.discussion_url+'.json?&'+this.topics_params;
      this.$http.get(this.url).then(function(response) {
        this.discussion_topic_arr = response.body.discussion_topic_arr;
        this.topics = response.body.topics;
        this.topics_count = response.body.topics_all_count;
        this.followed_topics_count = response.body.topics_followed_count;
        this.total_pages = response.total_pages;
        this.current_page = this.current_page +1;
      });
    },
    sortDiscussion: function(value) {
      var sorting = '&order='+value;
      this.topics_params = this.topics_params+sorting;
      this.GroupDiscussionFilter(this.active_tab,this.topics_params);
    },
    newDiscussion: function() {
      this.$http.get(this.new_discussion_url).then(function(response) {
        this.show_discussion = true;
      });
    },
    searchDiscussion: function() {
      var key = this.keyword.toLowerCase();
      jQuery(".discussion_topic_title").each( function() {
        var group_topic_str = jQuery(this).text().toLowerCase();
        if (group_topic_str.indexOf(key)!=-1) {
          // jQuery(this).parent().parent().parent().show();
          jQuery(this).closest(".group_discussion_list").show()
        }
        else {
          // jQuery(this).parent().parent().parent().hide();
          jQuery(this).closest(".group_discussion_list").hide()
        }
      });      
    }, 
  }
});

Vue.component('group-discussion-list',{
    template: `<div class="group_discussion_list">
                <div v-bind:id="discussion_topic.topic.title+'_'+discussion_topic.topic.id" class="d-flex align-items-start justify-content-start">
                  <div class="padding_0 discussion_topic_thumbnail mr-2">
                    <img v-bind:src="discussion_topic.logo" class="thumbnail_img lxa_border_radius" alt="thumbnail"></img>
                  </div>
                  <div class="group-discss-row-wrapper w-100 lxa_font_size_03 lxa_light_font">
                  <div class="row w-100 mx-0">
                    <div class="col-10 px-0">                    
                    <div class="discussion_topic_title lxa_font_size_04 lxa_medium_font">{{discussion_topic.topic.title}}</div>               
                    </div>
                    <div v-if="logged_in && user_can_interact" class="col-2 px-0 text-right">
                      <div v-if="followed_detail == 'Cannot Follow Yourself' || followed_detail == 'Follow'" class="lxa_font_size_03 lxa_light_font lxa_text_color_06" disabled="true"><i class="fad fa-comment-check"></i> Follow</div>
                      <div v-else v-on:click="follow_unfollow_topic(follow_unfollow_url)" class="cursor_pointer lxa_font_size_03 lxa_light_font lxa_text_color_06"><i class="fad fa-comment-check"></i> <span v-if="!followed">Follow</span><span v-else>Unfollow</span></div>
                    </div>
                    <div v-else class="col-2 px-0"></div>  
                  </div>
                  <div class="row mx-0">                  
                    <div class="col-12 px-0 topic_created_on">Created By&nbsp{{discussion_topic.created_by}} &nbspOn {{discussion_topic.created_on}}</div>
                  </div>
                  <div class="row mx-0">
                    <div v-bind:id="'comment_truncate'+comment_id" v-on:click="ToggleContent" class="hide"></div>
                    <div class="col-8 px-0 discussion_topic_description" v-show="truncate" v-bind:id="'all_comment_text_'+comment_id" class="announcement_text_display lxa_font_size_03 lxa_text_color_02 lxa_light_font">{{discussion_topic.topic.description}}</div>
                    <div v-show="!truncate" class="announcement_text_display lxa_font_size_03 lxa_text_color_02 lxa_light_font">{{discussion_topic.topic.description}}<span v-on:click="ToggleContent"><i class="fad fa-chevron-up read_more_arrow"></i></span></div>
                    <div id="reply_container" class="col-4 pr-0 text-right">
                      <span v-if="logged_in && (discussion_topic.user_can_interact)" v-on:click="reply_to_discussion=!reply_to_discussion" data-toggle="tooltip" data-placement="top" title="Reply"><i class="lxa_text_color_06 fad fa-reply" style="width: 16px;height: 16px;cursor: pointer"></i></span> 
                      <span v-if="topic_replies_count > 0" class="like_count lxa_bg_01">{{topic_replies_count}}</span>
                      <span :class="[{'count_padding': (topic_replies_count > 0)}, 'topic_separator']"></span>
                      <span v-if="logged_in && (discussion_topic.user_can_interact)" v-on:click="like_unlike_topic(like_unlike_url)" data-toggle="tooltip" data-placement="top" title="Like"><i class="fad fa-thumbs-up lxa_text_color_05" style="width: 16px;height: 16px;cursor: pointer"></i></span>
                      <span v-if="likes_count > 0" class="like_count lxa_bg_01">{{likes_count}}</span>
                      <span :class="[{'count_padding': (likes_count > 0)}, 'topic_separator']"></span>
                      <span v-if="discussion_topic.can_be_deleted_by" v-on:click="delete_topic(discussion_topic.delete_url)" data-toggle="tooltip" data-placement="top" title="Delete"><i class="lxa_text_color_01 fad fa-trash" style="width: 16px;height: 16px;cursor: pointer"></i></span> 
                    </div>
                    <div class="w-100 mt-3" v-if="comments_arr.length != 0&&!hide_comment">
                      <div v-for="(comment,index) in comments_arr">
                        <div v-if="!comment.deleted_comment">
                          <group-discussion-comment :comment="comment" :discussion_topic="discussion_topic" :reply_url="comment_url" :logged_in="logged_in"/> 
                          <hr class="topic_border mt-2"/>
                        </div>
                      </div>
                    </div>                      
                    <form v-if="reply_to_discussion" v-bind:id="form_id" v-on:submit.prevent="onSubmit" class="w-100 d-flex mt-3" role="form">
                      <textarea class="discussion_textarea lxa_font_size_03 txtarea-resize lxa_border_radius_tlbl" name="comments_group[body]" :placeholder="$t('new_comment.write_a_comment')" v-validate="'required'" data-vv-validate-on="none"></textarea>
                      <div>
                        <button type="submit" class="discussion_submit btn lxa_bg_04 lxa_btn_txt_01 lxa_font_size_04 text-uppercase lxa_border_radius_trbr">{{$t('item.add_comment')}}</button>
                      </div>
                    </form> 
                    <div v-else-if="comments_arr.length > 0" class="lxa_text_color_06 lxa_font_size_03">
                      <span v-if="!hide_comment" v-on:click="hide_comment = true" class="cursor_pointer">{{$tc('item.hide_comment',comments_arr.length , {count: comments_arr.length})}}</span>
                      <span v-else v-on:click="hide_comment = false" class="cursor_pointer">{{$tc('item.show_comment',comments_arr.length , {count: comments_arr.length})}}</span>
                    </div>                                            
                  </div>  
                  </div>                                                        
                </div>
                <hr class="topic_border mt-2"/>
              </div>`,
  props: {
    discussion_topic: Object,
    comment_url: String,
    logged_in: Boolean
  },
  data: function() {
    return {
      likes_count: this.discussion_topic.like_unlike_detail.likes_count,
      liked: this.discussion_topic.like_unlike_detail.liked,
      like_unlike_url: this.discussion_topic.like_unlike_detail.like_unlike_url,
      followed: this.discussion_topic.follow_unfollow_detail.followed,
      follow_unfollow_url: this.discussion_topic.follow_unfollow_detail.follow_unfollow_url,
      followed_detail: this.discussion_topic.follow_unfollow_detail,
      user_can_interact: this.discussion_topic.user_can_interact,
      topic_replies_count: this.discussion_topic.topic_replies_count,
      latest_reply_created_on: this.discussion_topic.latest_reply_created_on,
      form_id: 'post_group_topic_comment_' + new Date().getTime(),
      comments_arr: [],
      reply_to_discussion: false,
      hide_comment: false,
      truncate: true,
      comment_id: this.discussion_topic.topic.id,
    }
  },
  mounted: function() {
    this.$http.get(this.comment_url).then(function(response) {
      this.comments_arr = response.body.group_comments_arr;
    });
    ellipsis('#all_comment_text_'+this.comment_id, 3, {
      responsive: true,
      replaceStr: '<span onClick="ShowMore(\''+'comment_truncate'+this.comment_id+'\')" data-toggle="tooltip" title="Click to see more." class="cursor_pointer">...</span>'
    });
  },
  methods: {
    ToggleContent: function() {
      this.truncate = !this.truncate;
    }, 
    like_unlike_topic: function(url) {
      var self = this;
      if(self.logged_in) {
        if(self.liked) {
          self.$http.delete(url).then(response => {
            self.likes_count = response.body.likes_count;
            self.liked = response.body.liked;
            self.like_unlike_url = response.body.like_url;
          });
        } else {
          self.$http.post(url).then(response => {
            if(response.body) {
              self.likes_count = response.body.likes_count;
              self.liked = response.body.liked;
              self.like_unlike_url = response.body.unlike_url;
            }
          });
        }
      }
    },    
    delete_topic: function(url) {
      this.$http.delete(url).then(function(response) {
        if((response.body) == true) {
          var index = this.$parent.discussion_topic_arr.indexOf(this.discussion_topic)
          this.$parent.discussion_topic_arr.splice(index, 1)
        }
      });      
    },
    onSubmit: function() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.post(this.comment_url+'.json?&'+jQuery('#'+this.form_id).serialize()).then(response => {
            if (response.body) {
              this.comments_arr.push(response.body);
              jQuery("#"+this.form_id).find('input[type=text], textarea').val('');
              this.topic_replies_count = this.topic_replies_count + 1;
              jQuery(".group_discussion_contents").niceScroll({cursorcolor:"#4184F3",cursorwidth:"3px",autohidemode: "scroll", horizrailenabled: false, nativeparentscrolling: false});
            }
          });
        }
      });      
    },
    follow_unfollow_topic: function(url) {
      var self = this;
      if(self.logged_in) {
        if(self.followed) {
          self.$http.delete(url).then(response => {
            self.followed = response.body.followed;
            self.follow_unfollow_url = response.body.follow_url;
            self.$parent.followed_topics_count = response.body.topics_followed_count;
          });
        } else {
          self.$http.post(url).then(response => {
            if(response.body) {
              self.followed = response.body.followed;
              self.follow_unfollow_url = response.body.unfollow_url;
              self.$parent.followed_topics_count = response.body.topics_followed_count;
            }
          });
        }
      }
    }             
  }
});

Vue.component('group-discussion-comment', {
  template: `<div>
              <div class="d-flex">  
                <div class="padding_0 discussion_topic_thumbnail">
                  <img v-bind:src="comment.creator_logo" class="thumbnail_img" alt="thumbnail"></img>
                </div>
                <div class="padding_0 font_barlow_semi_condensed_light_14 w-100 row p-0 m-0">
                  <div class="topic_created_on col-8 p-0">Created By&nbsp{{comment.comment.author_name}}&nbspCreated On&nbsp {{comment.created_at}}</div>
                  <div id="reply_container" class="margin_bottom_0 col-4 text-right">
                    <span v-if="logged_in && (discussion_topic.user_can_interact)" v-on:click="reply_comment(comment.reply_url)" data-toggle="tooltip" data-placement="top" title="Reply"><i class="lxa_text_color_06 fad fa-reply" style="width: 16px;height: 16px;cursor: pointer"></i></span> 
                    <span class="topic_separator"></span>
                    <span v-if="comment.can_be_deleted" v-on:click="delete_comment(comment.delete_url)" data-toggle="tooltip" data-placement="top" title="Delete"><i class="lxa_text_color_01 fad fa-trash" style="width: 16px;height: 16px;cursor: pointer"></i></span> 
                  </div>
                  <div class="topic_description">{{comment.comment.body}}</div>
                </div>
              </div>  
              <form v-if="reply_to_comment" v-bind:id="form_id" class="margin_left_40 d-flex mt-3" v-on:submit.prevent="onSubmit" role="form">
                <textarea class="discussion_textarea lxa_font_size_03 txtarea-resize lxa_border_radius_tlbl" name="comments_group[body]" :placeholder="$t('new_comment.write_a_comment')" v-validate="'required'" data-vv-validate-on="none"></textarea>
                <div>
                  <button type="submit" class="discussion_submit btn lxa_bg_04 lxa_btn_txt_01 lxa_font_size_04 text-uppercase lxa_border_radius_trbr">{{$t('common.submit')}}</button>
                </div>
              </form>
              <div v-else-if="replies.length > 0" class="lxa_text_color_06 lxa_font_size_03">
                <span v-if="!hide_reply" v-on:click="hide_reply = true" class="cursor_pointer">{{$tc('item.hide_reply',replies.length , {count: replies.length})}}</span>
                <span v-else v-on:click="hide_reply = false" class="cursor_pointer">{{$tc('item.show_reply',replies.length , {count: replies.length})}}</span>
              </div> 
              <div v-if="!hide_reply" v-for="(comment,index) in replies">
                <div v-if="!comment.deleted_comment">
                  <hr class="topic_border mt-2"/>
                  <div class="ml-3">              
                    <group-discussion-comment :comment="comment" :discussion_topic="discussion_topic" :reply_url="reply_url" :reply="true" :logged_in="logged_in"/>
                  </div> 
                </div>
              </div>                                            
            </div>`,
  props: {
    logged_in: Boolean,
    comment: Object,
    reply_url: String,
    discussion_topic: Object,
    reply: Boolean  
  },            
  data: function() {
    return {
      reply_to_comment: false,
      reply_id: this.comment.comment.id,
      form_id: 'post_reply_group_comment_' + new Date().getTime(),
      replies: this.comment.comments,
      hide_reply: false
    }
  }, 
  mounted: function() {
    ellipsis('.topic_description',3)
  },
  methods: {
    delete_comment: function(url) {
      var self = this;
      this.$http.delete(url).then(function(response) {
        if(response.body) {
          if(this.reply) {
            var cons = self.$parent.replies;
          } else {
            var cons = self.$parent.comments_arr;
          }
          var index = cons.indexOf(this.comment)
          cons.splice(index, 1)
        }
      });
      this.$parent.topic_replies_count = this.$parent.topic_replies_count - 1;      
    },
    reply_comment: function(url) {
      this.reply_to_comment = !this.reply_to_comment;
      // if (this.reply_to_comment) {
      //   this.$http.get(url).then(function(response) {
      //   });
      // }       
    },
    onSubmit: function() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.post(this.reply_url+'.json?&'+jQuery('#'+this.form_id).serialize()+'&reply_for_id='+this.reply_id).then(response => {
            if (response.body) {
              this.replies.push(response.body);
              jQuery("#"+this.form_id).find('input[type=text], textarea').val('');
              this.$parent.topic_replies_count = this.$parent.topic_replies_count + 1;
              this.reply_to_comment = false;
            }
          });
        }
      });      
    }           
  }
});

Vue.component('new-discussion-section', {
  template: `<div> 
              <form id="new_discussion_topic" class="form-horizontal " v-on:submit.prevent="onSubmit" role="form">
                <div class="container-fluid padding_0">
                  <div class="mb-2 lxa_font_size_03 lxa_light_font">Title</div>
                  <div class="form-group">
                    <div class="col-sm-12 control px-0">
                      <input id="group_topic_title" type="text" class="primary_bgcolor w-100 p-2 lxa_border_radius border-0 lxa_font_size_04" v-validate="'required'" data-vv-validate-on="none" name="topics_group[title]" :placeholder="$t('sort.title')">
                    </div>
                  </div>
                  <div class="mb-2 mt-3 lxa_font_size_03 lxa_light_font">Message</div>
                  <div class="form-group">
                    <div class="col-sm-12 px-0">
                      <textarea id="group_topic_description" class="primary_bgcolor w-100 p-2 lxa_border_radius border-0 txtarea-resize lxa_font_size_04" v-validate="'required'" data-vv-validate-on="none" name="topics_group[description]" placeholder="Message"></textarea>
                    </div>
                  </div>
                  <div class="col-sm-12 px-0 d-flex align-items-center justify-content-end">
                    <button v-on:click="cancelDiscussion()" class="btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase">{{$t('common.cancel').toUpperCase()}}</button> 
                    <button type="submit" class="btn lxa_bg_04 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase ml-3">{{$t('common.save').toUpperCase()}}</button>
                  </div>
                </div>
              </form>               
            </div>`,
  props: {
    create_discussion_url: String    
  }, 
  methods: {
    cancelDiscussion: function() {
      this.$parent.show_discussion = false;
    },
    onSubmit: function() {
      self=this;
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.post(this.create_discussion_url+'.json?&'+jQuery('#new_discussion_topic').serialize()).then(response => {
            if (response.body.success == true) {
              this.$parent.show_discussion = false;
              this.$parent.discussion_topic_arr = response.body.discussion_topic_arr;
              this.$parent.topics = response.body.topics;
              this.$parent.topics_count = response.body.topics_followed_count;             
            }
          });
        }
      });
    },
  }
});