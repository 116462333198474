import Vue from 'vue/dist/vue.esm'
const EventBus = new Vue();

Vue.component('announcement-popup',{   
  template:`<div id="notification_home" class="notification_home padding_bottom_10">
              <div class="container-fluid padding_left_3" id="container">  
                <div class="row" id="row">
                  <div class="col-sm-6 padding_top_3">
                  </div>
                  <div class="announcement_box">
                    <div class="col-sm-1 margin_left_5 margin_top_min_3" v-if="new_announcement_link">
                      <a class="new_announcement_class font_size_22"></a>  
                    </div>
                  </div>
                </div>
              </div>
              <div v-infinite-scroll="loadMoreAnnouncements" infinite-scroll-disabled="busy" infinite-scroll-distance="10" infinite-scroll-immediate-check="false" id="announcement_display" style="margin-top:-11px" >
                <div v-if="announcements.length>0">
                 <announcement v-for="(item,index) in announcements" v-bind:item="item" :index="index" :new_announcement_url="new_announcement_url" :page_no="page" :length="announcements.length"></announcement>
                </div>
                <div v-else class="no_announcement lxa_font_size_02 lxa_medium_font">{{$t('announcement.no_announcements')}}</div>
                <div class="loading hidden">
                  <hr class="loading_hr">
                  <div> class="color_light_gray" </div>
                  <item-loader></item-loader>
                </div>
              </div>
            </div>`,           
  data: function(){
    return{
      announcements: Object,
      new_announcement_url: '',
      new_announcement_link: false,
      total_pages: 1,
      page: 1,
      current_view: 'announcement_display',
      busy: false
    }
  },
  mounted: function(){
    this.$parent.loading = true;
    this.$http.get('/my_learnings/announcements.json').then(response => {
      this.$parent.loading = false;
      jQuery('#notifications').removeClass("hide");
      this.announcements = response.body.announcements;
   });
  
  },
  methods: {
    loadMoreAnnouncements: function() {
      var self = this;
      self.busy = true;
      if(self.page < self.total_pages){
        self.page = self.page + 1;
        jQuery("#"+self.current_view).find('.loading').removeClass('hidden');
        jQuery("#"+self.current_view).find('.circular_loader').removeClass('hidden').addClass('announcement_loader');        
        this.$http.get(this.$root.announcement_url+'.json'+'?page='+self.page).then(response => {
          if(response.body.announcements.length !=0) {
            jQuery("#"+self.current_view).find('.loading').addClass('hidden');
            jQuery("#"+self.current_view).find('.circular_loader').addClass('hidden');            
            self.announcements = self.announcements.concat(response.body.announcements);
            self.busy = false;
            // jQuery("#announcement_display").getNiceScroll().resize()
          }
        });
      } 
    }
  }
 
});

Vue.component('announcement',{
  template:`<div class="container-fluid m-0 mt-2 p-0" v-bind:id="'announcement_'+announcement_id">
              <div class="row m-0 p-0" id="notification_row">
                <div class="col-8 m-0 p-0">
                  <div v-bind:id="'announcement_time_'+announcement_id" class="font_size_12 notification_content notification_time_display"><img v-if="priority == 1" v-bind:id="'priority_'+announcement_id" src="/images/icons/exclaimation.png" alt="more" class="more vertical_align_text_top"><span v-if="creator" class="lxa_button_title_light_1 notification_content notification_sender word_wrap">{{creator ? creator : 'Learnexa Support'}}</span></div> 
                </div>
                <div class="col-4 m-0 p-0 text-right">
                  <div class="lxa_title_light_01">{{time}} </div>
                </div> 
              </div> 
              <div class="row m-0 p-0 mt-2">
                <div class="col-12 m-0 p-0">
                  <div v-bind:id="'announcement_text_'+announcement_id" class="announcement_text_display lxa_title_light_01">{{body}}</div>
                </div>
              </div> 
              <div class="row m-0 p-0 mt-3">
                <hr class="w-100 m-0 p-0"/>
              </div>
            </div>`,
  props: {
    index: Number,
    item: Object,
    new_announcement_link: Boolean,
    length: Number
  },

  data: function(){
    return{
      creator: this.item.creator,
      body: this.item.body,
      time: this.item.announcement_time,
      priority: this.item.priority,
      delete_url: this.item.delete_announcement_url,
      edit_announcement_url: this.item.edit_announcement_url,
      announcement_id: this.item.announcement_id,
      active: false
    }
  },
  methods: {
    //editOptionOpen: function() {
      //this.active = true;
      //if(this.index == this.length-1){
        
        
      //}
    //},
    //editOptionClose: function() {
      //this.active = false;
      //if(this.index == this.length-1){
        
      //}
    //},
  },
});

Vue.component('all-announcement-component',{
  template: `<div class="modal" id="all_announcements_modal" role="dialog" data-backdrop="static" data-keyboard="false">
              <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content modal_outer_ui">
                  <p class="light_box_close" v-on:click="$parent.show_all_announcements=false" data-dismiss="modal"> <i class="fad fa-times lxa_text_color_08 lxa_font_size_06 cursor_pointer"></i></p>
                  <div class="modal-body p-0">
                    <announcement-header-popup :from_all_announcements="true"/>
                  </div>
                </div>
              </div>
            </div>`,
            mounted: function(){
               this.LoadScroll();
            },
            updated: function() {
            this.$nextTick(() => {     
                this.LoadScroll();
            });
          },
  methods: {
    LoadScroll: function() {
      jQuery("#all_announcements_modal #announcements_display").slimScroll({
        barClass:'lxascroll',
        size : '3px',
        width: '100%',
        height : '100%',
        position : 'right',
        start : 'top',
        alwaysVisible : false,
        distance: '0',
        borderRadius: '0',
        // railVisible: true,
        railBorderRadius:0,
        opacity:1,
        allowPageScroll: true,
        touchScrollStep:200,
        enableKeyNavigation: true
      });
    },
            }
});

Vue.component('announcement-header-popup',{   
  template:`<div :class="[{'': from_all_announcements}, 'px-0 col-sm-12']" id="announcementId">
              <div v-show="!loading">
                <div v-if="from_all_announcements" class="col-sm-12 px-0 mb-4">
                  <div class="d-flex align-items-center justify-content-start">
                  <span class="announcements_title"> Announcements </span>
                  <span class="ml-auto">
                    <span v-if="announcements.length>0" class="all_announcement_header btn lxa_bg_01 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" v-on:click="deleteAllAnnouncements()">
                    <i class="fad fa-trash-alt"></i> Remove All  </span> 
                    <span v-if="is_admin" class="all_announcement_header btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase cursor_pointer ml-3" data-toggle="modal" data-target="#new_announcement" v-on:click="createAnnouncement()"><i class="fad fa-plus" style="width: 16px;height: 16px;color:#FFFFFF"></i> Create Announcement  </span>
                  </span>
                  </div>
                </div>     
                <div v-else :class="[{'group_announcement_padding': from_group},'d-flex align-items-center justify-content-start mb-4']">
                  <span class="announcements_title"> Announcements </span>
                  <span class="ml-auto">
                  <span v-if="announcements.length>0" class="announcement_show_all lxa_text_color_06 lxa_font_size_03 cursor_pointer" v-on:click="$parent.show_all_announcements = true" data-toggle="modal" data-target="#all_announcements_modal"> Show All </span>
                  <i v-if="is_admin" class="fad fa-pencil lxa_text_color_05 lxa_font_size_03 ml-3"></i>
                  <span v-if="is_admin" class="announcement_edit lxa_text_color_06 lxa_font_size_03 cursor_pointer ml-1" v-on:click="moreDetail()"> Edit </span>
                </span>
                </div>
                
                <div v-if="!from_all_announcements && show_more_detail" class="col-sm-12 padding_0 cursor_pointer custom_popover show_more_detail_popup">
                  <div class="col-sm-12 create_announcement_popup p-2 lxa_bg_02 lxa_btn_txt_01 lxa_border_radius_tltr lxa_font_size_04 text-uppercase cursor_pointer" data-toggle="modal" data-target="#new_announcement" v-on:click="createAnnouncement()"><i class="fad fa-plus"></i><span class="ml-1">Create Announcement</span>  </div>
                  <div v-if="announcements.length>0" class="col-sm-12 clear_all_announcement_popup p-2 lxa_bg_01 lxa_btn_txt_01 lxa_border_radius_blbr lxa_font_size_04 text-uppercase cursor_pointer" v-on:click="deleteAllAnnouncements()"><i class="fad fa-trash"></i> <span class="ml-1">Clear all Announcements</span>  </div> 
                </div>
                <div :class="[{'': from_all_announcements}, 'notification_home']" id="notification_home">
                  
                  <div v-if="from_all_announcements" v-infinite-scroll="loadMoreAnnouncements" infinite-scroll-disabled="busy" infinite-scroll-distance="10" infinite-scroll-immediate-check="false" id="announcements_display">
                    <div v-if="announcements.length>0">
                      <announcement-list v-for="(item,index) in announcements" :key="item.announcement_id" :ref="'announcement-'+item.announcement_id" v-bind:item="item" :index="index" :new_announcement_url="new_announcement_url" :page_no="page" :length="announcements.length" :is_admin="is_admin" :from_all_announcements="from_all_announcements"></announcement-list>
                    </div>
                    <div v-else class="lxa_font_size_02 lxa_medium_font pb-2 text-center">{{$t('announcement.no_announcements')}}</div>
                    <div class="loading hidden">           
                      <item-loader></item-loader>
                    </div>
                  </div>
                  <div v-else id="announcements_display" v-infinite-scroll="loadMoreAnnouncements" infinite-scroll-disabled="busy" infinite-scroll-distance="10" infinite-scroll-immediate-check="false">
                    <div class="mr-1" v-if="announcements.length>0">
                      <announcement-list v-for="(item,index) in announcements" :key="item.announcement_id" v-bind:item="item" :index="index" :new_announcement_url="new_announcement_url" :page_no="page" :length="announcements.length" :is_admin="is_admin" :from_all_announcements="from_all_announcements"></announcement-list>
                    </div>
                    <div v-else class="lxa_font_size_02 lxa_medium_font pb-2 text-center">{{$t('announcement.no_announcements')}}</div>
                  </div>                
                </div>
              </div>
              <div v-show="loading" class="text-center">
                <i key="loader" class="fad fa-spinner fa-spin lxa_font_size_10 lxa_text_color_01"></i>
              </div>  
            </div>
            `,           
  data: function(){
    return{
      announcements: Object,
      new_announcement_url: '',
      new_announcement_link: false,
      total_pages: 1,
      page: 1,
      current_view: 'announcements_display',
      busy: false,
      show_more_detail: false,
      delete_all_announcements_url: '',
      is_admin: false   ,
      loading: true 
    }
  },
  props: {
    show_announcement: Boolean,
    from_group: Boolean,
    group_announcements: Array,
    group_new_announcement_url: String,
    group_new_announcement_link: Boolean,
    from_all_announcements: Boolean
  },  
  mounted: function(){
    $(".announcement_popup").css({top: $('#header_announcement').position().top+65, left: $('#header_announcement').position().left-185});
    if (this.from_group == false) {
      var params = this.from_all_announcements ? '&all=true' : '&page=1'+'&per_page=5'+'&popup=true'
      this.$http.get(this.$root.announcement_url+'.json?'+params).then(response => {
        jQuery('#notifications').removeClass("hide");
        this.announcements = this.from_all_announcements ? response.body.announcements : response.body.announcements.slice(0,5);
        this.new_announcement_url = response.body.new_announcement_url;
        this.is_admin = response.body.is_admin;
        this.delete_all_announcements_url = response.body.delete_all_announcements_url;
        this.new_announcement_link = response.body.new_announcement_link;
        this.total_pages = response.body.total_pages;
        this.$root.total_announcement_count = response.body.total_announcements_count;
        setTimeout(function(){
            jQuery("#announcements_display").slimScroll({
              barClass:'lxascroll',
              size : '3px',
              height: '100%',
              width: '100%',
              position : 'right',
              start : 'top',
              alwaysVisible : false,
              distance: '0',
              borderRadius: '0',
              // railVisible: true,
              railBorderRadius:0,
              opacity:1,
              allowPageScroll: true,
              touchScrollStep:200,
              enableKeyNavigation: true
            });
          // jQuery("#announcements_display").niceScroll({cursorcolor:"#4184F3",cursorwidth:"3px",autohidemode: "scroll"}); 
        }, 20);      
        this.loading = false;
      });
    }
    else {
      this.loading = false;
      this.announcements = this.group_announcements;
      this.new_announcement_url = this.group_new_announcement_url;
      this.new_announcement_link = this.group_new_announcement_link;             
    }  
    var self = this;
    EventBus.$on("UpdateAnnouncement", function (announcement,index) {
      self.$set(self.announcements, index,announcement);
      self.$refs['announcement-'+announcement.announcement_id][0].$vnode.key = Math.floor(Date.now() / 1000);
      // self.$refs['announcement-'+announcement.announcement_id][0].$forceUpdate();
      // self.$refs['announcement-'+announcement.announcement_id][0].$vnode.key = announcement.announcement_id;
    })
  
  },
  methods: {
    loadMoreAnnouncements: function() {
      var self = this;
      self.busy = true;
      if(self.page < self.total_pages){
        self.page = self.page + 1;
        jQuery("#"+self.current_view).find('.loading').removeClass('hidden');
        jQuery("#"+self.current_view).find('.circular_loader').removeClass('hidden').addClass('announcement_loader');        
        this.$http.get(this.$root.announcement_url+'.json'+'?page='+self.page+'&per_page=5&popup=true').then(response => {
          if(response.body.announcements.length !=0) {
            jQuery("#"+self.current_view).find('.loading').addClass('hidden');
            jQuery("#"+self.current_view).find('.circular_loader').addClass('hidden');            
            self.announcements = self.announcements.concat(response.body.announcements);
            self.busy = false;
          }
        });
      }       
    },
    moreDetail: function() {     
      this.show_more_detail = !this.show_more_detail;
    }, 
    createAnnouncement: function() {
      if (this.from_all_announcements) {
        parent = this.$parent.$parent;
      } else {
        parent = this.$parent;
      }
      parent.edit_product = false;
      parent.create = true;
      this.show_more_detail = false;
      parent.show_new_form = true;
      this.$http.get(this.new_announcement_url).then(response => {
        parent.available_items = response.body.available_products;
        parent.selected_items = response.body.selected_products;
        parent.create_announcement_url = response.body.create_announcement_url;
        parent.audience_id = response.body.aid;
        parent.audience_type = response.body.atype;
      });
      // jQuery("#all_announcements_modal").modal('hide')
 
    },
    deleteAllAnnouncements: function() {
      this.$modal.show('dialog', {
        text: 'Are you sure you want to delete all the Announcements ?',
        buttons: [{
            title: 'Cancel',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Yes',
            handler: () => {
              this.$modal.hide('dialog')
              this.$http.delete(this.delete_all_announcements_url).then(response => {
                this.announcements = response.body.announcements;
                this.$root.total_announcement_count = response.body.total_announcements_count;
                this.$root.popUpMessage('Deleted Successfully');
              });
            }
          }
        ]
      });
    }        
  }
});


Vue.component('announcement-list',{
  template:`<div class="col-sm-12 px-0 container-fluid notification_container_padding" :class="(index==0) ? 'pb-3':'announcements_list_content py-3'" v-bind:id="announcement_id_name + announcement_id">
              <div :class="[{'each_announcement_first_index': (index==0)}, 'row mx-0 each_announcement']">
                <div class="col-12 px-0">
                <div class="d-flex align-items-start justify-content-start">
                <div :class="[{'first_index': (index==0)}, 'announcement_thumbnail']">
                  <img :src="item.logo_url" class="thumbnail_img img-fluid" alt="thumbnail"></img>
                </div>  
                <div :class="[{'': !from_all_announcements}, 'rownotification card-body py-0 pr-0 w-50']" id="notification_row">
                  
                
                   <!-- <div class="col-sm-1 padding_top_2 padding_left_13">
                    </div>-->
                   
                      <!--<div :class="[{'padding_top_15': (index==0)}, 'announcements_list_content']" />-->
                        <div id="notification_details" :class="[{'': !from_all_announcements},'row mx-0']">
                          <label v-if="from_all_announcements" v-bind:id="'announcement_time_'+announcement_id" class="mb-0 col-sm-12 padding_0 notification_content notification_time_display">
                            <div class="row mx-0 padding_bottom_5">
                              <div class="col-sm-11 px-0"><span class="col-sm-12 px-0 title lxa_font_size_03 lxa_text_color_02 lxa_light_font">{{title}} </span></div>
                              <div v-bind:id="'announcement_desc_show'+announcement_id" class="hide" v-on:click="ToggleContent"></div>
                              <div class="col-sm-1 px-0 edit_box">
                                <span class="float_right">
                                  <span class="cursor_pointer" v-on:click="delete_announcement()"><i data-toggle="tooltip" data-placement="bottom" title="Delete" class="fad fa-trash-alt lxa_text_color_01 lxa_font_size_03 cursor_pointer"></i></span>
                                  <span v-if="is_admin" class="cursor_pointer" data-toggle="modal" data-target="#new_announcement" v-on:click="editAnnouncement()"><i data-toggle="tooltip" data-placement="bottom" title="Edit" class="fad fa-pencil lxa_text_color_05 lxa_font_size_03 cursor_pointer ml-3"></i></span>
                                </span>
                              </div>
                            </div>
                            <div class="row margin_0 padding_bottom_5">
                              <img v-if="priority == 1" v-bind:id="'priority_'+announcement_id" src="/images/icons/exclaimation.png" alt="more" class="more vertical_align_text_top"><span v-if="creator" class="lxa_font_size_03 lxa_text_color_02 lxa_light_font vertical_align_text_top notification_content notification_sender word_wrap">{{creator ? creator : 'Learnexa Support'}}</span>
                            </div>  
                            <div class="row mx-0">
                              <div class="col-sm-11 px-0" id="announcement_description">
                                <div v-show="truncate" v-bind:id="'all_announcement_text_'+announcement_id" class="announcement_text_display lxa_font_size_03 lxa_text_color_02 lxa_light_font">{{body}}</div>
                                <div v-show="!truncate" class="announcement_text_display lxa_font_size_03 lxa_text_color_02 lxa_light_font">{{body}}<span v-on:click="ToggleContent"><i class="fad fa-chevron-up read_more_arrow"></i></span></div>
                              </div>
                              <div class="col-sm-1 px-0 announcement_time text-right">
                                <span class="lxa_font_size_02 lxa_text_color_02 lxa_light_font">&nbsp {{time}} </span>
                              </div>
                            </div>  
                          </label>                        
                          <label v-else v-bind:id="'announcement_time_'+announcement_id" class="mb-0 col-sm-12 padding_0 notification_content notification_time_display">
                            <div class="row mx-0">
                              <span class="col-sm-11 px-0 title lxa_font_size_03 lxa_text_color_02 lxa_light_font">{{title}}</span>
                              <div class="col-sm-1 px-0 announcement_more_arrow text-right">
                                <span v-show="!show_announcement_option" v-on:click="announcementOption()" class="hover" id="announcement_option"><i class="fad fa-chevron-down lxa_text_color_01 lxa_font_size_04 cursor_pointer"></i></span>
                                <span v-show="show_announcement_option" v-on:click="announcementOption()" class="hover" id="announcement_option"><i class="fad fa-chevron-up lxa_text_color_01 lxa_font_size_04 cursor_pointer"></i></span>
                                
                              </div>
                            </div>  
                            <span class="col-sm-11 px-0">                          
                              <img v-if="priority == 1" v-bind:id="'priority_'+announcement_id" src="/images/icons/exclaimation.png" alt="more" class="more vertical_align_text_top">
                              <span v-if="creator" class="lxa_font_size_03 lxa_text_color_02 lxa_light_font notification_content notification_sender word_wrap">{{creator ? creator : 'Learnexa Support'}}</span>
                            </span>
                            <div :class="[{'vertical_align_text_top': !show_announcement_option}, 'row mx-0']">
                              <div :class="[{'col-sm-12': show_announcement_option,'col-sm-9': !show_announcement_option}, 'px-0']">
                                <div v-bind:class="[{'announcement_description' : show_announcement_option, 'testimonial_description' : (is_admin && item.testimonial_id != null)}, 'announcement_text_'+announcement_id]" id="announcement_description">
                                  <div v-show="!show_announcement_option" v-bind:id="'announcement_text_'+announcement_id" class="announcement_text_display lxa_font_size_02 lxa_text_color_02 lxa_light_font">{{body}}</div>
                                  <p v-show="show_announcement_option" class="announcement_text_display lxa_font_size_02 lxa_text_color_02 lxa_light_font" v-html="body"></p>
                                  <span v-if="is_admin && show_announcement_option && item.testimonial_id != null">  
                                    <a class='btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase cursor_pointer' style="text-decoration: none;" data-toggle="modal" :data-target="'#edit_collected_testimonial_modal_'+item.testimonial_id" data-backdrop="static" data-keyboard="false"> VIEW </a>
                                  </span>
                                </div>
                              </div>  
                              <div v-if="!show_announcement_option" class="col-sm-3 px-0 announcement_time text-right">
                                <span class="lxa_font_size_02 lxa_text_color_02 lxa_light_font">&nbsp {{time}} </span>
                              </div>
                            </div>                            
                          </label>
                          <div v-if="show_announcement_option" class="col-12 px-0 each_announcement_option d-flex align-items-center justify-content-start">
                            <span class="time_ago mr-auto">&nbsp {{time}} </span>
                            <span class="ml-auto cursor_pointer">
                            <span class="allign" v-on:click="delete_announcement()"><i class="fad fa-trash-alt lxa_text_color_01 lxa_font_size_03 cursor_pointer" aria-hidden="true"></i></span>
                            <span v-if="is_admin" class="" data-toggle="modal" data-target="#new_announcement" v-on:click="editAnnouncement()"><i class="fad fa-pencil lxa_text_color_05 lxa_font_size_03 cursor_pointer ml-3" aria-hidden="true"></i></span>
                            </span>
                          </div>                       
                         
                        </div>                
                      </div>
             
                  </div>
                </div>
                </div>
              </div>   
            </div>`,
  props: {
    index: Number,
    item: Object,
    new_announcement_link: Boolean,
    length: Number,
    new_announcement_url: String,
    is_admin: Boolean,
    from_all_announcements: Boolean
  },

  data: function(){
    return{
      creator: this.item.creator,
      title: this.item.title,
      body: this.item.body,
      time: this.item.announcement_time,
      priority: this.item.priority,
      delete_url: this.item.delete_announcement_url,
      edit_announcement_url: this.item.edit_announcement_url,
      announcement_id_name: this.from_all_announcements ? 'detail_announcement_' : 'announcement_',
      announcement_id: this.item.announcement_id,
      active: false,
      show_announcement_option: false,
      truncate: true
    }
  },
  mounted: function() {
    ellipsis('.title', 1);
    ellipsis('#announcement_text_'+this.announcement_id, 1);
    ellipsis('#all_announcement_text_'+this.announcement_id, 1, {
      responsive: true,
      replaceStr: '<span onClick="ShowMore(\''+'announcement_desc_show'+this.announcement_id+'\')" data-toggle="tooltip" title="Click to see more" class="cursor_pointer">...</span>'
    });
  },
  methods: {
    announcementOption: function() {     
      this.show_announcement_option = !this.show_announcement_option;
      setTimeout(function() {
        jQuery("#announcements_display").getNiceScroll().resize();
      },30);
    },
    delete_announcement: function(){
      this.$modal.show('dialog', {
        text: 'Are you sure you want to delete the Announcement ?',
        buttons: [{
            title: 'Cancel',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Yes',
            handler: () => {
              this.$http.delete(this.delete_url).then(response => {
                jQuery('#announcement_'+this.announcement_id).hide();
                jQuery('#detail_announcement_'+this.announcement_id).hide();
                jQuery('#badge_announcement').html(parseInt(jQuery('#badge_announcement').html())-1)  
                this.$root.popUpMessage('Deleted Successfully');  
              });
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    },
    editAnnouncement: function() {  
      if (this.from_all_announcements) {
        parent = this.$parent.$parent.$parent;
      } else {
        parent = this.$parent.$parent;
      }  
      parent.edit_product = true;
      // jQuery("#all_announcements_modal").modal('hide') 
      this.$http.get(this.edit_announcement_url+'/edit.json'+'?&id='+this.announcement_id).then(response => { 
        parent.characters_allowed = 300 - response.body.text.length;
        parent.title = response.body.title;
        parent.announcement_text = response.body.text;
        parent.announcement_start_date = response.body.start_date;
        parent.announcement_end_date = response.body.end_date;
        parent.announcement_start_time = response.body.start_time;
        parent.announcement_end_time = response.body.end_time;
        parent.id = this.announcement_id;
        parent.create = false;
        parent.available_items = response.body.available_products;
        parent.selected_items = response.body.selected_products;
        parent.priority = response.body.priority;
        parent.audience_id = response.body.aid;
        parent.audience_type = response.body.atype;
        parent.update_announcement_url = this.edit_announcement_url;
        parent.announcement_index = this.index;
        if (response.body.priority == 0)
        {
          jQuery('#announcement_priority').val('0');
        }
        else {
          jQuery('#announcement_priority').val('1');
        }
        parent.show_new_form = true;
        // jQuery('body').addClass('modal-open');
        var edit_modal = $('<div />').appendTo('body');
        edit_modal.attr('class', 'modal-backdrop show');        
        // jQuery("#notifications").css("width","330px");
        // jQuery("#notifications").css("margin-left","-300px");
        jQuery("#new_announcement_form").removeClass("hide");
        jQuery(".announcement_items_select").niceScroll({cursorcolor:"#4184F3",cursorwidth:"3px",cursorfixedheight: "50",autohidemode: "scroll"});
      });
      
    },
    ToggleContent: function() {
      this.truncate = !this.truncate;
    }      
  }
});

Vue.component('new-announcement-form',{
  template:`<div id="new_announcement_form" class="create_new_announcement_popup">
              <div v-show="!loading" class="row mx-0">
                <div id="new_announcement_header" class="col-12 px-0 lxa_text_color_05 lxa_font_size_08 lxa_light_font mb-4">{{$t('announcement.write_an_announcement')}}</div>
               <div class="col-8 px-0">
               <div class="row ml-0">
                <label class="col-sm-12 px-0 lxa_font_size_04 lxa_text_color_02 lxa_light_font">Title</label>
                <input maxlength="300" name = "announcement_title" class="col-sm-12 announcement_title primary_bgcolor lxa_border_radius lxa_font_size_04 p-2" v-model="title"></input>
                </div>
                <div class="row ml-0 mt-3">
              
                    <div class="col-8 pl-0 m-0">
                      <div class="col-12 p-0 m-0 row">
                        <label class="col-4 padding_0">
                        <span class="lxa_font_size_04 lxa_text_color_02 lxa_light_font">{{$t('announcement.description')}}</span>
                        <span class="lxa_text_color_01"> *</span>
                        </label>
                        <label class="col-8 padding_0 text-right characters_remaining lxa_font_size_04 lxa_text_color_01 lxa_light_font">{{$t('common.characters_remaining')}}<span class="padding_left_3">{{characters_allowed}}</span></label>
                      </div>
                      <textarea maxlength="300" name = "announcement text" class="notification_textarea txtarea-resize"  v-on:keyup="show_remaining_characters" v-model="announcement_text"></textarea>
                      <div class="error_container">  
                        <span class ="font_open_sans_regular_11_red error_span hide inline_block padding_bottom_5" id="text_error"></span>
                      </div>
                    </div>
                    <div class="col-4 pl-2 p-0 m-0 priority_box">
                      <label class="announcement_label lxa_font_size_04 lxa_text_color_02 lxa_light_font">{{$t('announcement.priority')}}</label>
                      
                      <select class="priority_dropdown primary_bgcolor lxa_border_radius lxa_font_size_04 p-2" id="announcement_priority">
                        <option value="0">{{$t('announcement.normal')}}</option>
                        <option value="1">{{$t('announcement.urgent')}}</option>
                      </select>
                      <label class="announcement_label lxa_font_size_04 lxa_text_color_02 lxa_light_font mt-4">{{$t('announcement.display_announcement')}}</label>
                      <span class="notification_span lxa_font_size_04 lxa_text_color_01 lxa_light_font">{{ $t('announcement.never_end') }}</span>
                     
                      <div class="col-12 d-flex display_dates mt-3 primary_bgcolor lxa_border_radius lxa_font_size_04 lxa_light_font p-2">
                        <div class="col-6 p-0 from_date">
                         
                          <label class="announcement_label mb-0">{{$t('common.from')}}<span class="lxa_text_color_01" >*</span></label>
                          <div class="position-relative">
                          <input type="text" id="announcement_start_date" name="from date_time" v-model="announcement_start_date" class="lxa_font_size_04 input_padding notification_date_input form-control input-sm" ><div class="calendar_display"><i class="fad fa-calendar lxa_text_color_05 lxa_font_size_04"></i></div></input>
                          </div>
                          <div class="position-relative">
                          <input type="text" id="announcement_start_time" name="from date_time" v-model="announcement_start_time" class="lxa_font_size_04 notification_time_input form-control input-sm input_padding" ><div class="calendar_display"><i class="fad fa-calendar lxa_text_color_05 lxa_font_size_04"></i></div></input>
                          <div class="error_container">  
                            <span class ="font_open_sans_regular_11_red error_span hide inline_block padding_bottom_5" id="start_date_error"></span>
                          </div> 
                          </div>
                        </div>
                        <div class="col-6 p-0 to_date"> 
                          
                          <label class="announcement_label mb-0">{{$t('common.to')}}</label>
                          <div class="position-relative ml-2">
                          <input type="text" id="announcement_end_date" v-model="announcement_end_date" class="lxa_font_size_04 notification_date_input form-control input-sm input_padding"><div class="calendar_display"><i class="fad fa-calendar lxa_text_color_05 lxa_font_size_04"></i></div></input>
                          </div>
                          <div class="position-relative ml-2">
                          <input type="text" id="announcement_end_time" v-model="announcement_end_time" class="lxa_font_size_04 notification_time_input form-control input-sm input_padding"><div class="calendar_display"><i class="fad fa-calendar lxa_text_color_05 lxa_font_size_04"></i></div></input>
                          <div class="error_container">  
                            <span class ="font_open_sans_regular_11_red error_span hide inline_block padding_bottom_5" id="end_date_error"></span>
                          </div> 
                          </div>
                        </div>
                     
                      </div>
                 
                    </div>
                  </div>
            
                </div>
                <div class="col-sm-4 pl-2 pr-0 available_item_box">
                  <label class="announcement_label lxa_font_size_04 lxa_text_color_02 lxa_light_font">{{ $t('announcement.who_should_see_this_announcement') }}</label>
                  <div id="notification_select_participant" class="primary_bgcolor lxa_border_radius lxa_font_size_04 p-2">
                    <div class="search_course_box position-relative">
                      <input type="text" id="search_box" class="notification_search_input form-control input-sm lxa_bg_05 lxa_border_radius lxa_font_size_04 p-2" :placeholder="$t('announcement.search_courses')" v-on:keyup="search_courses"/>
                      <div class="cursor_pointer search_product position-absolute"><i class="fad fa-search lxa_text_color_05 lxa_font_size_04"></i></div>
                      <img src="/images/clear.png" id="reset_search" class="products_list" v-if="clear_search" v-on:click="clear_search_data"></img>
                    </div>  
                    <div class="announcement_items_select">

                        <div id="announcement_every_one" class="announcement_every_one_wrapper custom-controls custom-checkbox mb-2">
                          <input id="every_one" v-on:click="select_all()" name="announcement_every_one" class="announcement_every_one custom-control-input lxa-bs-checkboxbtn" type="checkbox" value="every one">
                          <label class="string_trunk8 custom-control-label" for="every_one">
                            <i class="fad fa-stop lxa_icon_size lxa_text_color_06"></i>
                            <i class="fad fa-check-square lxa_icon_size lxa_text_color_06"></i>
                            <span class="announcement_label lxa_text_color_03 lxa_font_size_04 lxa_light_font ml-1">{{$t('announcement.every_one')}}</span>
                          </label>
                        </div>
                   
                      <label v-if="available_items_sorted_Array.length == 0" class="announcement_label padding_left_14 margin_top_5">{{$t('common.none')}}</label>
                      <ul v-if="available_items_sorted_Array.length != 0" id="available_product_list">
                        <li v-bind:id="'products_list' + item.id" v-on:click="select_product($event,item,index)" v-for="(item,index) in available_items_sorted_Array" v-bind:key="item.id" :class="[{'margin_top_0': (index==0), 'mt-2': (index==1)},'form-check pl-0 product_checkbox checkbox products_list']">
                          <div v-bind:id="'products_list' + item.id" class="available_items_select_wrapper custom-controls custom-checkbox">
                          <input v-bind:id="'checkbox1_' + item.id" :name="'checkbox1_' + item.id" class="products_list custom-control-input lxa-bs-checkboxbtn" type="checkbox" value="every one">
                          <label class="custom-control-label available_items_select_label products_list" v-bind:id="'label_' + item.id" :for="'checkbox1_' + item.id">
                            <i class="fad fa-stop lxa_icon_size lxa_text_color_06"></i>
                            <i class="fad fa-check-square lxa_icon_size lxa_text_color_06"></i>
                            <span class="lxa_text_color_03 lxa_font_size_04 lxa_light_font ml-1 lxa_selected_chbox">{{item.title}}</span>
                          </label>
                        </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>  
                <div class="col-sm-12 px-0 mt-3 save_announcement">
                  <div class="col-sm-12 padding_0">
                    <div class="row margin_0">                      
                      <div class="col-6 offset-6 text-right px-0">
                        <button type="button" class="btn btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" v-on:click="close_new_announcement">{{$t('common.cancel').toUpperCase()}}</button>                        
                        <button type="submit" v-if="create" v-on:click="create_new_announcement" class="btn save btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase ml-3">{{$t('common.save').toUpperCase()}}</button>  
                        <button type="submit" v-else v-on:click="edit_announcement" class="btn btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase ml-3">{{$t('common.save').toUpperCase()}}</button> 
                      </div>
                    </div>  
                  </div>
                </div>
              </div>
              <div v-show="loading">
                <i key="loader" class="fad fa-spinner fa-spin lxa_font_size_10 text-danger"></i>
              </div>  
            </div>`,
  props: {
    available_items: Array,
    selected_items: Array,
    create_announcement_url: String,
    update_announcement_url: String,
    edit_product: Boolean,
    create: Boolean,
    announcement_index: Number
  },
  data: function(){
    return{
      characters_allowed: 300,
      announcement_text: '',
      announcement_start_date: Date.now().toString("MMM d, yyyy"),
      announcement_start_time: Date.now().add({minutes: (30 - (Date.now().getMinutes() % 30))}).toString("H:mm tt").toLowerCase(),
      announcement_end_date: '',
      announcement_end_time: '',
      id: 0,
      selected_item_checkbox: true,
      available_item_checkbox: false,
      clear_search: false,
      start_date_text: this.$t('announcement.start_date'),
      end_date_text: this.$t('announcement.end_date'),
      selectAll: false,
      title: '',
      loading: true
    }
  },
  computed: {
    available_items_sorted_Array: function() {
      return array_sort_by_name(this.$parent.available_items,"title");        
    },
    selected_items_sorted_Array: function() {        
      return array_sort_by_name(this.$parent.selected_items,"title");       
    }
  },
  mounted: function() {
    // jQuery('.create_new_announcement_popup').hide();
    // setTimeout(function() {
    //   jQuery('.create_new_announcement_popup').show();
    // },3500);        
    this.characters_allowed = 300 - this.announcement_text.length;
    var announcement_comp = this;
    registerDateTimePair({startDate: 'announcement_start_date',
          startTime: 'announcement_start_time',
          endDate: 'announcement_end_date',
          endTime: 'announcement_end_time'});
    jQuery('#announcement_start_time').bind('change', function() { announcement_comp.announcement_start_time = jQuery(this).val();});
    jQuery('#announcement_end_time').bind('change', function() { announcement_comp.announcement_end_time = jQuery(this).val();});      
    jQuery('#announcement_start_date').change(function() { announcement_comp.announcement_start_date = jQuery(this).val();});
    jQuery('#announcement_end_date').change(function() { announcement_comp.announcement_end_date = jQuery(this).val();});
    DateTimePicker('announcement_start_date','M d, Y',true,false);
    DateTimePicker('announcement_end_date','M d, Y',true,false);
    DateTimePicker('announcement_start_time','H:i a',false,true);
    DateTimePicker('announcement_end_time','H:i a',false,true);   
    // this.loading = false
    tinymce.init({
      selector: 'textarea.notification_textarea',
      height: 248,
      width: 580,
      max_chars: 300,
      menubar: false,
      insertdatetime_formats: ["%H:%M:%S", "%Y-%m-%d", "%I:%M:%S %p", "%D"],
      charmap_append: [
        [0x2600, 'sun'],
        [0x2601, 'cloud']
      ],
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount'
      ],
      toolbar1: 'undo redo | fontselect | fontsizeselect |' +
      'bold italic underline backcolor charmap ' ,
      toolbar2:'alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ' +
      'image link media table removeformat | help ',
      content_css: '//www.tiny.cloud/css/codepen.min.css',
      init_instance_callback: this.loading = false
    });
    if (this.edit_product != false) {  
      this.$parent.selected_items.forEach(function(item){
        jQuery('#' + 'checkbox1_' + item.id).prop("checked", true);
      }); 
      if (this.$parent.priority == 0)
      {
        jQuery('#announcement_priority').val('0');
      }
      else {
        jQuery('#announcement_priority').val('1');
      }
      if (this.$parent.available_items.length == this.$parent.selected_items.length) {
        jQuery('#every_one').prop("checked", true);
        this.selectAll = true;
      }
      this.announcement_start_date = this.$parent.announcement_start_date;
      this.announcement_start_time = this.$parent.announcement_start_time;
      this.announcement_end_date = this.$parent.announcement_end_date;
      this.announcement_end_time = this.$parent.announcement_end_time;
      this.announcement_text = this.$parent.announcement_text;
      this.title = this.$parent.title;
      tinyMCE.activeEditor.setContent(this.$parent.announcement_text, {format : 'text'});  
      this.loading = false 
    }      
  },

  methods:{
    replaceAnnouncement: function(event) {
      var exis_event = jQuery("#announcements_display").find(".notification_container_padding").first();
      var new_event = jQuery("#announcements_display .notification_container_padding").last();
      (new_event).insertBefore(exis_event);
    },
    close_new_announcement: function(){
      this.clearAnnouncementCreateForm();
      this.$parent.show_new_form = false;
      jQuery("#new_announcement").modal('toggle')
      jQuery('.modal-backdrop:eq(1)').remove();
      jQuery('body').removeClass('modal-open');
      jQuery('#new_announcement_form').addClass('hide');
      // jQuery("#notification_home").removeClass("hide");
      // jQuery("#notifications").css("width","300px");
      // jQuery("#notifications").css("margin-left","-272px");
    },
    show_remaining_characters: function(){
      this.characters_allowed = 300 - this.announcement_text.length;
    },
    search_courses: function(){
      this.clear_search = true;
      var keyword = jQuery('#search_box').val();
      var equal = search_and_highlight('available_product_list', keyword);
      if (keyword.length == 0 || equal) {
        this.clear_search = false;
      }
    },
    clearAnnouncementCreateForm: function(event) {
      jQuery("#new_announcement_form").find('.error_span').addClass('hide');
      this.characters_allowed = 300;
      this.title = '';
      this.announcement_text = '';
      this.announcement_start_date = Date.now().toString("MMM d, yyyy");
      this.announcement_start_time = Date.now().toString("hh:mm tt").toLowerCase();
      this.announcement_end_date = '';
      this.announcement_end_time ='';
      this.selected_item_checkbox = true;
      this.available_item_checkbox = false;
      this.id = 0;
      this.$parent.create = true;
      jQuery('#announcement_priority').val('0');
    },
    select_product: function(event,item,index){
      if(jQuery('#' + 'checkbox1_' + item.id).is(":checked")) {
        this.$parent.selected_items.push(item);
      }
      else {
        this.unselect_product();     
      }
    },
    unselect_product: function(event,item,index){
      this.$parent.selected_items.pop(item);
    },
    clear_search_data: function(){
      jQuery("#search_box").val("");
      this.search_courses();
      this.clear_search = false;
    },
    select_none: function(){
      if (this.$parent.selected_items.length != 0 && this.$parent.available_items.length != 0) {
        this.$parent.selected_items = [];
        this.$parent.available_items.forEach(function(item){
          jQuery('#' + 'checkbox1_' + item.id).prop("checked", false);
        });  
      }
    },
    select_all: function(){
      this.selectAll = !this.selectAll;
      if (this.selectAll == true) {
        if (this.$parent.available_items.length > 0) {
          this.$parent.selected_items.push.apply(this.$parent.selected_items, this.$parent.available_items);
          this.$parent.selected_items.forEach(function(item){
            jQuery('#' + 'checkbox1_' + item.id).prop("checked", true);
          });
        }
      }
      else {
        this.select_none();             
      }  
    },
    create_new_announcement: function() {
      var max = 300
      var numChars = tinymce.activeEditor.plugins.wordcount.body.getCharacterCount();
      if (numChars > max) {
        this.$root.popUpMessage("Maximum " + max + " characters allowed.");
      } else {
      var message = tinymce.activeEditor.getContent({format: 'text'});
      this.announcement_start_date = jQuery('#announcement_start_date').val();
      this.announcement_start_time = jQuery('#announcement_start_time').val();
      this.announcement_end_date = jQuery('#announcement_end_date').val();
      this.announcement_end_time = jQuery('#announcement_end_time').val(); 
      jQuery("#new_announcement_form").find('.error_span').addClass('hide');
      var self = this;
      var products = [];
      var priority = 0;
      var announcement_hash = {}; 
      var end_date = this.announcement_end_date.length > 0 ? new Date(this.announcement_end_date + ' ' + this.announcement_end_time) : ''
      if (jQuery("#announcement_priority").val() == "1") {
        priority = 1;
      };
      var params = '&product_item_search=Search Courses'+'&announcement[end_date]='+end_date+'&announcement[display_start_time]='+this.announcement_start_time+'&announcement[start_date]='+ new Date(this.announcement_start_date + ' ' + this.announcement_start_time)+'&announcement[display_start_date]='+this.announcement_start_date+'&announcement[title]='+this.title+'&announcement[text]='+message+'&announcement[audience_type]='+this.$parent.audience_type+'&announcement[audience_id]='+this.$parent.audience_id+'&announcement[display_end_time]='+this.announcement_end_time+'&announcement[priority]='+priority+'&announcement[display_end_date]='+this.announcement_end_date;
      if (this.$parent.selected_items.length != 0) {
        for (var i = 0; i < this.$parent.selected_items.length; i++) {
          products[i] = this.$parent.selected_items[i].id;
        }
        var product_id = JSON.stringify(products);
        var params = params +'&products='+product_id
      }
      this.$http.post(this.create_announcement_url+'?'+params).then(response => {
        if(response.body.errors){
          this.display_error_message(response.body.errors);              
        }
        else{
          if(response.body != false){
            self.$parent.$refs.announcementHeader.announcements.push(response.body[0]);
            setTimeout(function(){ self.replaceAnnouncement(); }); 
          }         
          self.clearAnnouncementCreateForm(); 
          self.$root.popUpMessage("Announcement created");
          this.$parent.show_new_form = false;
          if (this.$parent.show_all_announcements) {
            jQuery('.modal-backdrop:eq(1)').remove();
          }
          jQuery("#new_announcement").modal('hide');      
          // jQuery('#all_announcements_modal').modal('hide');
          // this.$parent.show_all_announcements = false;
          // this.$parent.show_announcement = false;
        }
      });
      }
    },
    edit_announcement: function(){
      var max = 300
      var numChars = tinymce.activeEditor.plugins.wordcount.body.getCharacterCount();
      if (numChars > max) {
        this.$root.popUpMessage("Maximum " + max + " characters allowed.");
      } else {
        var message = tinymce.activeEditor.getContent({format: 'text'});
        this.announcement_start_date = jQuery('#announcement_start_date').val();
        this.announcement_start_time = jQuery('#announcement_start_time').val();
        this.announcement_end_date = jQuery('#announcement_end_date').val();
        this.announcement_end_time = jQuery('#announcement_end_time').val();       
        jQuery("#new_announcement_form").find('.error_span').addClass('hide');      
        var self = this;
        var products = [];
        var priority = 0;
        var announcement_hash = {};
        if (jQuery("#announcement_priority").val() == "1") {
          priority = 1;
        };
        var params = '&product_item_search=Search Courses'+'&announcement[end_date]='+'&announcement[display_start_time]='+this.announcement_start_time+'&announcement[start_date]='+ new Date(this.announcement_start_date + ' ' + this.announcement_start_time)+'&announcement[display_start_date]='+this.announcement_start_date+'&announcement[title]='+this.title+'&announcement[text]='+message+'&announcement[audience_type]='+this.$parent.audience_type+'&announcement[audience_id]='+this.$parent.audience_id+'&announcement[display_end_time]='+this.announcement_end_time+'&announcement[priority]='+priority+'&announcement[display_end_date]='+this.announcement_end_date;
        if (this.$parent.selected_items.length != 0) {
          for (var i = 0; i < this.$parent.selected_items.length; i++) {
            products.splice(i, 0, this.$parent.selected_items[i].id);
          }
          var product_id = JSON.stringify(products);
          var params = params + '&id='+this.id+'&products='+product_id
        }
        this.$http.put(this.update_announcement_url+'.json?'+params).then(response => {
          if(response.body.errors){
            this.display_error_message(response.body.errors);              
          }else{
            if(!response.body.show){
              jQuery('#announcement_'+self.id).addClass("hide");
            }
            if (jQuery("#announcement_priority").val() == "1") {
              if (!jQuery('#priority_'+self.id).length) {
                jQuery('#announcement_time_'+self.id).prepend("<img src='/images/icons/exclaimation.png' alt='more' class='more vertical_align_text_top'/>"); 
              }              
            };
            if (jQuery("#announcement_priority").val() == "0") {
              jQuery('#announcement_time_'+self.id).find('.more').remove();
            };
            self.$root.popUpMessage("Announcement updated Successfully");
            // jQuery('#announcement_text_'+self.id).text(self.announcement_text);
            EventBus.$emit("UpdateAnnouncement",response.body.announcement,this.announcement_index)
            // jQuery('#new_announcement_form').addClass('hide');
            // jQuery("#notification_home").removeClass("hide");
            // jQuery("#notifications").css("width","300px");
            // jQuery("#notifications").css("margin-left","-272px");
            self.clearAnnouncementCreateForm();
          }
        });
        this.$parent.show_new_form = false;
        // jQuery("#new_announcement").modal('hide');
        jQuery('.modal-backdrop:eq(1)').remove();
        // jQuery('body').removeClass('modal-open');      
        // jQuery('#all_announcements_modal').modal('hide');
        // this.$parent.show_all_announcements = false;
        // this.$parent.show_announcement = false;
      }  
    },
    display_error_message: function(errors_obj){
      for (var key of Object.keys(errors_obj)) {
        if (key == 'end_date') {
          jQuery("#end_date_error").html(this.end_date_text +' '+ errors_obj[key]);
          jQuery("#end_date_error").removeClass("hide");
        }else if(key == 'start_date') {
          jQuery("#start_date_error").html(this.start_date_text +' '+ errors_obj[key]);
          jQuery("#start_date_error").removeClass("hide");
        } else {
          jQuery("#text_error").html(key + ' '+ errors_obj[key]);
          jQuery("#text_error").removeClass("hide");
        }
      }
    }    
  }          
});

Vue.component('group-announcement-form',{
  template:`<div id="new_announcement_form" class="create_new_announcement_popup custom_popover">
              <div v-show="!loading">
                <label id="new_announcement_header" class="padding_top_9 font_open_sans_semibold_12_black padding_left_8">{{$t('announcement.write_an_announcement')}}</label>
                <div class="col-sm-9 padding_top_10 padding_0">  
                  <div class="row margin_0">
                    <label class="col-sm-4 padding_0">{{$t('announcement.description')}}<span class="color_red"> *</span></label>
                    <label class="col-sm-8 padding_0 text_align_left characters_remaining padding_right_10 normal">{{$t('common.characters_remaining')}}<span class="padding_left_3">{{characters_allowed}}</span></label>
                  </div>
                  <textarea maxlength="300" name = "announcement text" class="notification_textarea"  v-on:keyup="show_remaining_characters" v-model="announcement_text"></textarea>
                  <div class="col-sm-8 padding_0 priority_box">
                    <label class="announcement_label padding_top_10 padding_left_20">{{$t('announcement.priority')}}</label>
                    
                    <select class="priority_dropdown primary_bgcolor lxa_border_radius lxa_font_size_04 p-2" id="announcement_priority">
                      <option value="0">{{$t('announcement.normal')}}</option>
                      <option value="1">{{$t('announcement.urgent')}}</option>
                    </select>
                    <br/>
                    <br/> 
                    <label class="announcement_label margin_top_7">{{$t('announcement.display_announcement')}}</label>
                    <span class="notification_span">{{ $t('announcement.never_end') }}</span>
                    <br/>
                    <div class="display_dates">
                    <br/>
                      <div class="from_date">
                        <label class="announcement_label padding_left_25" style="width: 19%">{{$t('common.from')}}<span class="color_red" >*</span></label>
                        <input type="text" id="announcement_start_date" name="from date_time" v-model="announcement_start_date" class="input_padding notification_date_input form-control input-sm" ><div class="calendar_display"><i class="fad fa-calendar" style="width: 16px;height: 16px;color:#008000"></i></div></input>
                        <input type="text" id="announcement_start_time" name="from date_time" v-model="announcement_start_time" class="notification_time_input form-control input-sm input_padding" ><div class="calendar_display"><i class="fad fa-calendar" style="width: 16px;height: 16px;color:#008000"></i></div></input>
                      </div>
                      <div class="to_date">  
                        <label class="announcement_label padding_left_48 padding_top_10" style="width: 19%">{{$t('common.to')}}</label>
                        <input type="text" id="announcement_end_date" v-model="announcement_end_date" class="notification_date_input form-control input-sm input_padding"><div class="calendar_display"><i class="fad fa-calendar" style="width: 16px;height: 16px;color:#008000"></i></div></input>
                        <input type="text" id="announcement_end_time" v-model="announcement_end_time" class="notification_time_input form-control input-sm input_padding"><div class="calendar_display"><i class="fad fa-calendar" style="width: 16px;height: 16px;color:#008000"></i></div></input>
                        <div class="error_container">  
                          <span class ="font_open_sans_regular_11_red error_span hide inline_block padding_bottom_5" id="end_date_error"></span>
                        </div> 
                      </div>
                    <br/>  
                    </div>
                  </div>
                </div>   
                <div class="col-sm-12 padding_0 padding_top_10 save_announcement">
                  <div class="col-sm-12 padding_0">
                    <div class="row margin_0">                      
                      <div class="col-6 offset-6 text-right">
                        <button type="button" class="btn btn-secondary mr-2" v-on:click="close_new_announcement">{{$t('common.cancel').toUpperCase()}}</button>                        
                        <button type="submit" v-if="create" v-on:click="create_new_announcement" class="btn btn-success save margin_bottom_3">{{$t('common.save').toUpperCase()}}</button>  
                        <button type="submit" v-else v-on:click="edit_announcement" class="btn btn-default margin_bottom_3">{{$t('common.save').toUpperCase()}}</button> 
                      </div>
                    </div>  
                  </div>
                </div>
              </div>
              <div v-show="loading">
                <i key="loader" class="fad fa-spinner fa-spin lxa_font_size_10 text-danger"></i>
              </div>  
            </div>`,
  props: {
    create_announcement_url: String,
    create: Boolean
  },
  data: function(){
    return{
      characters_allowed: 300,
      announcement_text: '',
      announcement_start_date: Date.now().toString("MMM d, yyyy"),
      announcement_start_time: Date.now().add({minutes: (30 - (Date.now().getMinutes() % 30))}).toString("h:mm tt").toLowerCase(),
      announcement_end_date: '',
      announcement_end_time: '',
      id: 0,
      selected_item_checkbox: true,
      available_item_checkbox: false,
      clear_search: false,
      start_date_text: this.$t('announcement.start_date'),
      end_date_text: this.$t('announcement.end_date'),
      selectAll: false,
      title: '',
      loading: true
    }
  },
  mounted: function() {     
    this.characters_allowed = 300 - this.announcement_text.length;
    var announcement_comp = this;
    registerDateTimePair({startDate: 'announcement_start_date',
          startTime: 'announcement_start_time',
          endDate: 'announcement_end_date',
          endTime: 'announcement_end_time'});
    jQuery('#announcement_start_time').bind('change', function() { announcement_comp.announcement_start_time = jQuery(this).val();});
    jQuery('#announcement_end_time').bind('change', function() { announcement_comp.announcement_end_time = jQuery(this).val();});      
    jQuery('#announcement_start_date').change(function() { announcement_comp.announcement_start_date = jQuery(this).val();});
    jQuery('#announcement_end_date').change(function() { announcement_comp.announcement_end_date = jQuery(this).val();});
    DateTimePicker('announcement_start_date','M d, Y',true,false);
    DateTimePicker('announcement_end_date','M d, Y',true,false);
    DateTimePicker('announcement_start_time','H:i a',false,true);
    DateTimePicker('announcement_end_time','H:i a',false,true);   
    // this.loading = false
    tinymce.init({
      selector: 'textarea.notification_textarea',
      height: 250,
      width: 580,
      menubar: false,
      insertdatetime_formats: ["%H:%M:%S", "%Y-%m-%d", "%I:%M:%S %p", "%D"],
      charmap_append: [
        [0x2600, 'sun'],
        [0x2601, 'cloud']
      ],
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount'
      ],
      toolbar1: 'undo redo | fontselect | fontsizeselect |' +
      'bold italic underline backcolor charmap ' ,
      toolbar2:'alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ' +
      'image link media table removeformat | help ',
      content_css: '//www.tiny.cloud/css/codepen.min.css',
      init_instance_callback: this.loading = false
    });    
  },

  methods:{
    replaceAnnouncement: function(event) {
      var exis_event = jQuery("#announcements_display").find(".notification_container_padding").first();
      var new_event = jQuery("#announcements_display .notification_container_padding").last();
      (new_event).insertBefore(exis_event);
    },
    close_new_announcement: function(){
      this.clearAnnouncementCreateForm();
      this.$parent.show_new_form = false;
      jQuery("#new_announcement").modal('toggle')
      jQuery('.modal-backdrop').remove();
      jQuery('body').removeClass('modal-open');
      jQuery('#new_announcement_form').addClass('hide');
      jQuery("#notification_home").removeClass("hide");
      jQuery("#notifications").css("width","300px");
      jQuery("#notifications").css("margin-left","-272px");
    },
    show_remaining_characters: function(){
      this.characters_allowed = 300 - this.announcement_text.length;
    },
    clearAnnouncementCreateForm: function(event) {
      jQuery("#new_announcement_form").find('.error_span').addClass('hide');
      this.characters_allowed = 300;
      this.title = '';
      this.announcement_text = '';
      this.announcement_start_date = Date.now().toString("MMM d, yyyy");
      this.announcement_start_time = Date.now().toString("hh:mm tt").toLowerCase();
      this.announcement_end_date = '';
      this.announcement_end_time ='';
      this.selected_item_checkbox = true;
      this.available_item_checkbox = false;
      this.id = 0;
      this.$parent.create = true;
      jQuery('#announcement_priority').val('0');
    },
    clear_search_data: function(){
      jQuery("#search_box").val("");
      this.search_courses();
      this.clear_search = false;
    },
    select_none: function(){
      if (this.$parent.selected_items.length != 0 && this.$parent.available_items.length != 0) {
        this.$parent.selected_items = [];
        this.$parent.available_items.forEach(function(item){
          jQuery('#' + 'checkbox1_' + item.id).prop("checked", false);
        });  
      }
    },
    create_new_announcement: function() {
      var max = 300
      var numChars = tinymce.activeEditor.plugins.wordcount.body.getCharacterCount();
      if (numChars > max) {
        this.$root.popUpMessage("Maximum " + max + " characters allowed.");
      } else {
        var message = tinymce.activeEditor.getContent({format: 'text'});
        this.announcement_start_date = jQuery('#announcement_start_date').val();
        this.announcement_start_time = jQuery('#announcement_start_time').val();
        this.announcement_end_date = jQuery('#announcement_end_date').val();
        this.announcement_end_time = jQuery('#announcement_end_time').val(); 
        jQuery("#new_announcement_form").find('.error_span').addClass('hide');
        var self = this;
        var products = [];
        var priority = 0;
        var announcement_hash = {}; 
        var end_date = this.announcement_end_date.length > 0 ? new Date(this.announcement_end_date + ' ' + this.announcement_end_time) : ''
        if (jQuery("#announcement_priority").val() == "1") {
          priority = 1;
        };
        var params = '&product_item_search=Search Courses'+'&announcement[end_date]='+end_date+'&announcement[display_start_time]='+this.announcement_start_time+'&announcement[start_date]='+ new Date(this.announcement_start_date + ' ' + this.announcement_start_time)+'&announcement[display_start_date]='+this.announcement_start_date+'&announcement[title]='+this.title+'&announcement[text]='+message+'&announcement[audience_type]='+this.$parent.audience_type+'&announcement[audience_id]='+this.$parent.audience_id+'&announcement[display_end_time]='+this.announcement_end_time+'&announcement[priority]='+priority+'&announcement[display_end_date]='+this.announcement_end_date;
        this.$http.post(this.create_announcement_url+'?'+params).then(response => {
          if(response.body.errors){
            this.display_error_message(response.body.errors);              
          }
          else{
            self.$parent.$refs.announcementHeader.announcements.push(response.body[0]);
            setTimeout(function(){ self.replaceAnnouncement(); });         
            self.clearAnnouncementCreateForm();
            this.$parent.show_new_form = false;
            jQuery("#new_announcement").modal('hide');      
            jQuery('#all_announcements_modal').modal('hide');
            this.$parent.show_all_announcements = false;
            this.$parent.show_announcement = false;
          }
        });
      }
    },
    edit_announcement: function(){
      var max = 300
      var numChars = tinymce.activeEditor.plugins.wordcount.body.getCharacterCount();
      var message = tinymce.activeEditor.getContent({format: 'text'});
      this.announcement_start_date = jQuery('#announcement_start_date').val();
      this.announcement_start_time = jQuery('#announcement_start_time').val();
      this.announcement_end_date = jQuery('#announcement_end_date').val();
      this.announcement_end_time = jQuery('#announcement_end_time').val();       
      jQuery("#new_announcement_form").find('.error_span').addClass('hide');      
      var self = this;
      var products = [];
      var priority = 0;
      var announcement_hash = {};
      if (jQuery("#announcement_priority").val() == "1") {
        priority = 1;
      };
      var params = '&product_item_search=Search Courses'+'&announcement[end_date]='+'&announcement[display_start_time]='+this.announcement_start_time+'&announcement[start_date]='+ Date.now()+'&announcement[display_start_date]='+this.announcement_start_date+'&announcement[title]='+this.title+'&announcement[text]='+message+'&announcement[audience_type]='+this.$parent.audience_type+'&announcement[audience_id]='+this.$parent.audience_id+'&announcement[display_end_time]='+this.announcement_end_time+'&announcement[priority]='+priority+'&announcement[display_end_date]='+this.announcement_end_date;
      this.$http.put(this.update_announcement_url+'.json?'+params).then(response => {
        if(response.body.errors){
          this.display_error_message(response.body.errors);              
        }else{
          if(response.body == false){
            jQuery('#announcement_'+self.id).addClass("hide");
          }
          if (jQuery("#announcement_priority").val() == "1") {
            if (!jQuery('#priority_'+self.id).length) {
              jQuery('#announcement_time_'+self.id).prepend("<img src='/images/icons/exclaimation.png' alt='more' class='more vertical_align_text_top'/>"); 
            }              
          };
          if (jQuery("#announcement_priority").val() == "0") {
            jQuery('#announcement_time_'+self.id).find('.more').remove();
          };
          jQuery('#announcement_text_'+self.id).text(self.announcement_text);
          jQuery('#new_announcement_form').addClass('hide');
          jQuery("#notification_home").removeClass("hide");
          jQuery("#notifications").css("width","300px");
          jQuery("#notifications").css("margin-left","-272px");
          self.clearAnnouncementCreateForm();
        }
      });
      this.$parent.show_new_form = false;
      jQuery("#new_announcement").modal('hide');
      jQuery('.modal-backdrop').remove();
      jQuery('body').removeClass('modal-open');      
      jQuery('#all_announcements_modal').modal('hide');
      this.$parent.show_all_announcements = false;
      this.$parent.show_announcement = false;
    },
    display_error_message: function(errors_array){
      if (errors_array.length == 2) {
        error_msg = errors_array[0][1];
        jQuery("#end_date_error").html(this.end_date_text +' '+ error_msg);
        jQuery("#end_date_error").removeClass("hide");
        error_msg = errors_array[1][1];
        jQuery("#start_date_error").html(this.start_date_text +' '+ error_msg);
        jQuery("#start_date_error").removeClass("hide");
      }
      else{
        error_msg = errors_array[0][1];
        if (errors_array[0][0] == 'end_date') {
          jQuery("#end_date_error").html(this.end_date_text +' '+ error_msg);
          jQuery("#end_date_error").removeClass("hide");
        }else {
          jQuery("#start_date_error").html(this.start_date_text +' '+ error_msg);
          jQuery("#start_date_error").removeClass("hide");
        }
      }  
    }    
  }          
});
