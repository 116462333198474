<template>
	<div class="answer_container">
		<ul class="very_large">
			<li class="fill_blank_li">
				<div class="input_text">
					<input v-if="!have_response" placeholder="Please enter your answer here" v-bind:id="'responses_fill_blanks_'+question_id+'_'" v-bind:name="'responses[fill_blanks]['+question_id+'][]'" style="height: 30px !important;" type="text" v-bind:value=response_answer_text>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		name: 'FillBlanksType',
		props: {
			item: Array,
			response: Array,
			question_id: Number,
			have_response: Boolean,
			navigate_response: Boolean
		},
		data: function() {
			return{
				response_answer_text: this.navigate_response || this.have_response ? this.response[0].answer_text : ''
			}
		}
	}
</script>