import Vue from 'vue/dist/vue.esm'
import { addVideoContent, videoCommonParams, toggleContentStrip, toggleFullScreen, initContentPlayerData, close, open, cleanUpContentPlayArea, setVideoInterval, setPosition, bcVideoPlayer, buildVideoParams, addAudioContent, fetchContent } from './content_player/logic/lxa'
require('packs/Youtube.js');
require('packs/Vimeo.js');
import videojs from 'video.js';
import { loadPdfDocument } from './content_player/jslib/pdf_viewer'
import WebtextComponent from './content_player/WebtextComponent'
import PdfViewer from './content_player/PdfViewer'
import QuizContent from './content_player/QuizContent'

Vue.component('CreateCourseContent', {
  template: `<div>
              <div class="create_course_header">
                <span class="create_course_header_title"> {{$t('create_course.heading')}} </span>
                <span class="create_course_header_button active" @click="save_course"><a> {{$t('create_course.save')}} </a></span>
                <span class="create_course_header_button" @click="clear_course"><a> {{$t('create_course.cancel')}} </a></span>
              </div>
              <div class="create_course_body">
                <div class="row">
                  <div class="col-md-9 padding_0">
                    <div class="row">
                      <div class="add_course_content col-md-7">
                        <p> {{$t('create_course.add_content')}} <span> {{$t('create_course.add_content_desc')}} </span></p>
                        <div class="add_course_list">
                          <div v-for="list in course_content_list" @click="get_content(list.doc_type)" :class="[{'active': list.is_active}, 'cursor_pointer course_list']"> 
                            <i :class="['fa', list.font_class]" aria-hidden="true" v-if="list.font_class"></i>
                            <a> {{ list.name }} </a>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-5 padding_0">
                        <NewCourseFormFields :logged_in="logged_in" :top_section="true" :course_data="course_data"/>
                      </div>
                    </div>
                    <div class="row">
                      <div class="sample_course_content col-md-12 col-sm-12">
                        <div class="sample_list">
                          <div class="search_box">
                            <input type="text" placeholder="Search"/>
                            <span> <i class="fa fa-search fa-2x" aria-hidden="true"></i> </span>
                          </div>
                          <div class="listing_records">
                            <div class="record" v-for="content in contents" @click="get_document(content.id,content.content_type)">
                              <div class="icon"><i class="fa fa-sticky-note fa-2x" aria-hidden="true"></i> </div>
                              <div>
                                <span class="icon" style="float: right;" v-if="selected_contents.includes(content.id)"><i class="fa fa-check-circle" aria-hidden="true"></i> </span>
                                <p class="title"> {{ content.title }} </p>
                                <p class="description"> Created By {{ content.created_at }} </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-if="show_preview" class="sample_list_preview">
                          <div class="" v-if="['document', 'video', 'audio', 'tincan_package', 'slideshow', 'weblink'].includes(content_type)" style="width: 100%;">
                            <pdf-viewer v-if="showPDF" ref="pdfJS"></pdf-viewer>
                            <div v-else id="preview_container" class="col-sm-12"></div>
                          </div> 
                          <div v-if="['quiz', 'survey'].includes(content_type)" style="width: 100%;">
                            <quiz-content :quiz_obj="content_obj" :preview="true"></quiz-content>
                          </div>
                          <div v-if="['webtext'].includes(content_type)">
                            <webtext-component :web_text_obj="content_obj"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 padding_0">
                    <NewCourseFormFields :logged_in="logged_in" :top_section="false" :course_data="course_data"/>
                  </div>
                </div>
              </div>
            </div>`,
  props: {
    logged_in: Boolean,
    course_data: Object
  },
  components: {
    'webtext-component': WebtextComponent,
    'pdf-viewer': PdfViewer,
    'quiz-content': QuizContent,
  },
  data: function() {
    return {
       course_content_list: [
        {name: 'Presentation', is_active: true, doc_type: "document", font_class: 'fa-file'},
        {name: 'Document', is_active: false, doc_type: "document", font_class: 'fa-file'},
        {name: 'Video & Audio', is_active: false, doc_type: "video", font_class: 'fa-video-camera'},
        {name: 'Web page', is_active: false, doc_type: "webtext", font_class: 'fa-file'},
        {name: 'Quiz', is_active: false, doc_type: "quiz", font_class: 'fa-puzzle-piece'},
        {name: 'Survey', is_active: false, doc_type: "survey", font_class: 'fa-puzzle-piece'},
        {name: 'Scorm Package', is_active: false, doc_type: "scorm_package", font_class: 'fa-puzzle-piece'},
        {name: 'Tin Can', is_active: false, doc_type: "tincan_package", font_class: 'fa-puzzle-piece'}
      ],
      contents: [],
      active_doc_type: "",
      active_source: "",
      show_preview: false,
      selected_contents: [],
      course: {},
      create_url: this.course_data.create_url,
      content_preview_url: '',
      audioOptions: {
        playPoster: "contentplayer/images/play.svg",
        pausePoster: "contentplayer/images/pause.svg",
        videojsId: 'preview_container',
      },
      videoOptions: {
        videojsId: 'preview_container',
      },
      content_type: "",
      content_obj: {},
      showPDF: false,
      price_schedule_params: [
        {
          effective_now: true,
          free: true,
          for_member: false,
          price: 0.00,
          current_schedule: true,
          schedule_order: 0          
        }
      ],
      member_price_schedule_params: [
        {
          effective_now: true,
          free: true,
          for_member: true,
          price: 0.00,
          current_schedule: true,
          schedule_order: 0          
        }
      ],
      non_merchant_price_schedule: {
        effective_now: true,
        free: true,
        current_schedule: true
      }      
    }
  },
  mounted: function() {
    // if (this.logged_in) {
    this.$http.get('/content_picker?doc_type=document&new_single_page=true').then(function(response) {
      this.contents = response.body.contents;
      this.content_preview_url = response.body.preview_url;
      this.get_document(this.contents[0].id,this.contents[0].content_type);
    });
    // }
  },
  methods: {
    save_course() {
      if (!(this.course_data.pricing_hash.merchant_account)) {
        this.price_schedule_params = []
        this.price_schedule_params.push(this.non_merchant_price_schedule)
      }
      if ((this.$children[1].price_check) == "false") {
        this.price_schedule_params[0].free = false
        this.price_schedule_params[0].price = this.$children[1].price_schedule_value
      }
      if ((this.$children[1].member_price_check) == "false") {
        this.member_price_schedule_params[0].free = false
        this.member_price_schedule_params[0].price = this.$children[1].member_price_schedule_value
      }
      var contents = {documents: this.selected_contents}
      var course_data = {course: this.$children[1].course,documents: this.selected_contents, from_page: 'catalog', new_single_page: true}
      var course_duartion = (course_data.course.duration).split(':');
      if (!(this.course_data.pricing_hash.paid_subscription)) { (this.course_data.pricing_hash.member_pricing) ? ([course_data['price_schedule'], course_data['member_price_schedule']] = [this.price_schedule_params, this.member_price_schedule_params]) : (course_data['price_schedule'] = this.price_schedule_params) }
      course_data.course.title = this.$children[0].course.title;
      course_data.course.raw_data = this.$children[0].course.raw_data;
      course_data.course.duration = (course_duartion[0]) * 3600 + (course_duartion[1]) * 60;
      if (course_data.course.title && course_data.course.raw_data && course_data.documents && course_data.course.language) {
        this.$http.post(this.create_url,course_data).then(function(response) {
          this.$parent.showCreateCourse = !this.$parent.showCreateCourse;
          alert('Course Created Successfully');
        });
      }
    },
    clear_course() {
      this.$children[0].selected_contents = [];
    },
    get_content(doc_type) {
      this.$http.get('/content_picker?doc_type='+doc_type+'&new_single_page=true').then(function(response) {
        this.contents = response.body.contents;
        this.content_preview_url = response.body.preview_url;
        this.course_content_list.find((list) => list.is_active == true ).is_active = false
        this.course_content_list.find((list) => list.doc_type == doc_type ).is_active = true
        this.active_doc_type = doc_type
        this.selected_contents = [];
        this.get_document(this.contents[0].id,this.contents[0].content_type)
      });
    },
    get_document(content_id,content_type) {
      this.showPDF = false;
      const index = this.selected_contents.indexOf(content_id);
      if (index > -1) {
        this.selected_contents.splice(index, 1);
      }else{
        this.selected_contents.push(content_id)
      }
      this.show_preview = false;
      $("#pdf-content-wrapper").remove();
      $(".content-view-area").removeClass('video');
      this.$http.get(this.content_preview_url+'?&content_id='+content_id).then(function(response) {
        var from_page = 'preview';
        this.content_type = content_type;
        this.show_preview = true;
        switch (content_type) {
          case "slideshow": case "document":
            this.content_obj = response.body;
            this.showPDF = true;
            this.$nextTick(function () {
              try {
                loadPdfDocument(this.content_obj.secure_url)
              } catch (e) {
                console.log('Exception playPDFContent $nextTick!', e, e.stack);
              }
            });
            break;
          case "webtext":
            this.content_obj = response.body;
            break;
          case "slideshow": case "document":
            this.content_obj = response.body;
            break;
          case "quiz": case "survey":
            this.content_obj = response.body;
            break;
          case "video":
            this.content_obj = response.body;
            this.playVideoContent(this.content_obj)
            break;
          case "tincan_package":
            var current_content_url = response.body.url;
            this.$http.get(current_content_url).then(function(response) {
              this.content_obj = response.body;
              var content = response.body;
              try {
                // Create iframe and render url content
                this.$nextTick(function () {
                  try {
                    var contentURL = ''
                    if (content.activity_path != null) { 
                      contentURL = content.activity_path
                    } else {
                      contentURL = content.secure_url 
                    }
                    if (content.content_type == 'slideshow' && !(String(content.document.uploaded_data_content_type).includes('image'))) {
                      if (String(content.document.uploaded_data_content_type).includes('opendocument')) {
                        contentURL = "/ViewerJS/#.." + contentURL
                      } else {
                        contentURL = content.app_url + contentURL
                        contentURL = "https://docs.google.com/gview?url="+contentURL+"&embedded=true"
                      }
                    }
                    var iframe = $('<iframe />', {
                      "class": "url-content-frame modal_content_height",
                      src: contentURL,
                      id: "url-content",
                      frameborder: 0,
                      style: "width: 0; min-width: 100% !important; "
                    });
                    iframe.appendTo($('#preview_container'));
                    iframe.onload = function() {
                      iframe[0].contentDocument.body.querySelectorAll('img')[0].style.width = '100%'
                    }
                  } catch (e) {
                    console.log('Exception playKCContent $nextTick!', e, e.stack);
                  }

                });

              } catch (e) {
                // TODO: handle exception
                console.log(e, e.stack);
              }
            });
        }
      });
    },
    playVideoContent: function(content) {
      try {
        // var oldPlayer = document.getElementById('preview_container');
        // videojs(oldPlayer).dispose();
        // $( ".sample_list_preview" ).append( "<div id='preview_container' class='col-sm-12'></div>" );
        this.interval = setVideoInterval(this, "video")
        var position = 0
        position = position == null ? content.start_from : position
        var [video, isBCVideo, playId, match] = buildVideoParams(content.src, false, position, content)
        video = videoCommonParams(video, this.videoOptions)
        this.$nextTick(function () {
          this.videos = [video];
          var added = addVideoContent(video);
          var videoTag = $('<video />', video);
          videoTag.prependTo($('.sample_list_preview'));
          if(added) {
            try {
              // if(!isBCVideo) {
                videojs(this.videoOptions.videojsId, video).ready(function () {
                  $(".content-view-area").addClass('video');
                  $("#preview_container").addClass('d-flex');
                  $("#preview_container .video-js").addClass('m-auto');
                  // this.src(video);
                  this.on('loadedmetadata', function() {
                    if(!this.hasClass('progress-seeked')) {
                      this.currentTime(video.position);
                      this.addClass('progress-seeked');
                      this.play();
                    }
                  });
                  this.on('play', function() {
                    if(!this.hasClass('progress-seeked')) {
                      this.currentTime(video.position);
                      this.addClass('progress-seeked');
                      this.play();
                    }
                  });
                  this.on('error', function() {
                    // console.log("video error");
                  });
                  new VjsProgressUpdater({ 
                    player: this,
                    // progress_percent: content.progress_percent,
                    // progress_update_url: content.progress_update_url
                  });
                });
              // } else {
              //  bcVideoPlayer(match, playId, video)
              // }
            } catch (e) {
              console.log('Exception nextTick in play video content!', e, e.stack);
            }
          }
        });
      } catch (e) {
        console.log('Exception in play video content!', e, e.stack);
      }
    },
  }
});


Vue.component('NewCourseFormFields', {
  template: `<div class="add_course_form_fields">
              <div class="form_fields_col_1 col-md-12 col-sm-12" v-if="top_section">
                <div class="form_group">
                  <label class="required"> {{$t('create_course.title')}} </label> 
                  <input type="text" class="grey_background" v-model="course.title"/>
                </div>
                <div class="form_group">
                  <label class="required"> {{$t('create_course.description')}} </label>
                  <textarea class="grey_background textarea" v-model="course.raw_data" wrap="hard"/>
                </div>
              </div>
              <div class="form_fields_col_2 col-md-12 col-sm-12" v-if="!top_section">
                <div class="form_group">
                  <div class="row padding_0 margin_0">
                    <div class="col-sm-6 padding_0"> {{$t('create_course.category')}} </div>
                    <div class="col-sm-6 padding_0 text_align_right cursor_pointer products_category" v-on:click="show_categories = !show_categories" v-click-outside="outside">{{$t('inperson_event_edit.choose')}}</div>
                  </div>
                  <div v-if="show_categories" id="category_popup" class="course_selection_popup custom_popover">
                    <categories-popup v-bind:selected_categories="selected_categories" :available_categories="available_categories"></categories-popup>
                  </div>
                  <textarea id="category_textarea" name="category_textarea" rows="1" cols="76">{{this.selected_categories_list}}</textarea>
                </div>

                <div v-if="pricing_details.merchant_account">
                  <div v-if="pricing_details.paid_subscription">
                    <div class="form_group">
                      <div class="row padding_0 margin_0">
                        <div class="col-sm-6 padding_0"> Price : </div>
                      </div>
                      <div class="col-sm-12 padding_0">
                        <div class="col-sm-6 padding_0">
                          <input type="radio" v-on:click="course.subscribe_only = false" id="course_subscribe_only_false" name="course[subscribe_only]" value="false" checked>
                          <label for="course_subscribe_only_false">Free</label><br>
                        </div>
                        <div class="col-sm-6 padding_0">
                          <input type="radio" v-on:click="course.subscribe_only = true" id="course_subscribe_only_true" name="course[subscribe_only]" value="true">
                          <label for="course_subscribe_only_true">Subscribe Only</label><br>
                        </div>
                      </div>                    
                    </div>                    
                  </div>
                  <div v-else>
                    <div class="form_group" id="current_price_schedule">
                      <div class="row padding_0 margin_0">
                        <div class="col-sm-6 padding_0"> {{this.pricing_details.price_text}} </div>
                        <div class="col-sm-6 padding_0 text_align_right cursor_pointer products_category" v-if="this.pricing_details.scheduled_pricing" v-on:click="(show_non_member_prices = !show_non_member_prices), (show_member_prices = false)" v-click-outside="outside">{{$t('inperson_event_edit.schedule')}}</div>
                      </div>
                      <div v-if="show_non_member_prices" class="padding_8 custom_popover">
                        <div id="price_schedule_scrollbar_content"> 
                          <price-schedules-popup :for_member="false" :popup_id="'price_schedule_popup'" :show_member_prices="show_member_prices" :show_non_member_prices="show_non_member_prices"/>
                        </div>
                      </div>
                      <div class="col-sm-12 padding_0">
                        <div class="col-sm-6 padding_0">
                          <input type="radio" id="price_schedule__free_true" name="price_schedule[][free]" v-model="price_check" value="true" checked>
                          <label for="price_schedule_free">Free</label><br>
                        </div>
                        <div class="col-sm-1 padding_0">
                          <input type="radio" id="price_schedule__free_false" name="price_schedule[][free]" v-model="price_check" value="false">
                          <label for="price_schedule_price">$</label><br>
                        </div>
                        <input id="price_schedule__price" v-model="price_schedule_value" class="col-sm-2 padding_0" name="price_schedule[][price]" rows="1" cols="20"></input>                
                      </div>  
                    </div>
                    <br> 
                    <div v-if="this.pricing_details.member_pricing" class="form_group" id="current_member_price_schedule">
                      <div class="padding_0 margin_0">
                        <div class="col-sm-6 padding_0"> Member Price </div>
                        <div class="col-sm-6 padding_0 text_align_right cursor_pointer products_category" v-if="this.pricing_details.scheduled_pricing" v-on:click="(show_member_prices = !show_member_prices), (show_non_member_prices = false)" v-click-outside="outside">{{$t('inperson_event_edit.schedule')}}</div>
                      </div>
                      <div v-if="show_member_prices" class="padding_8 custom_popover">
                        <div id="member_price_schedule_scrollbar_content">
                          <price-schedules-popup :for_member="true" :popup_id="'member_price_schedule_popup'" :show_member_prices="show_member_prices" :show_non_member_prices="show_non_member_prices"/>
                        </div>
                      </div>
                      <div class="col-sm-12 padding_0">
                        <div class="col-sm-6 padding_0">
                          <input type="radio" id="member_price_schedule__free_true" name="member_price_schedule[][free]" v-model="member_price_check" value="true" checked>
                          <label for="member_price_schedule_free">Free</label><br>
                        </div>
                        <div class="col-sm-1 padding_0">
                          <input type="radio" id="member_price_schedule__free_false" name="member_price_schedule[][free]" v-model="member_price_check" value="false">
                          <label for="member_price_schedule_price">$</label><br>
                        </div>
                        <input id="member_price_schedule__price" v-model="member_price_schedule_value" class="col-sm-2 padding_0" name="member_price_schedule[][price]" rows="1" cols="20"></input>                
                      </div>  
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="form_group">
                    <div class="row padding_0 margin_0">
                      <div class="col-sm-6 padding_0"> Price : </div>
                    </div>
                    <div class="col-sm-12 padding_0">
                      <div class="col-sm-6 padding_0 margin_left_30">
                        <div id="non_merchant_free">Free</div>
                      </div>
                      <div class="col-sm-3 padding_0 cursor_pointer" v-on:mouseover="tooltip_popup = true" v-on:mouseleave="tooltip_popup = false" id="merchant_account_set_up">
                        <div>{{pricing_details.sell_product_text}}</div>
                      </div>
                      <div v-if="tooltip_popup" class="merchant_account_set_up_message custom_popover">
                        <div>{{pricing_details.tooltip_message}}</div>
                      </div>  
                    </div>                    
                  </div>                  
                </div>
                <div class="form_group">
                  <label class="required"> Language </label>
                  <select name="language_selection" v-model="course.language">
                    <option disabled value="">Select</option>
                    <option v-for="language in languages" v-bind:value="language">{{language}}</option>
                  </select>
                </div><br>
                <div class="form_group">
                  <label> Duration: </label>
                  <input type="text" v-model="course.duration" placeholder="Enter in HHH:MM Format"/>
                </div>                                  

                <div class="form_group">
                  <label> {{ $t('create_course.tags')}} </label>
                  <input type="text" class="grey_background" />
                </div>
                
                <div class="form_group">
                  <div class="row padding_0 margin_0">
                    <div class="col-sm-6 padding_0"> {{$t('create_course.prerequisites')}} </div>
                    <div class="col-sm-6 padding_0 text_align_right cursor_pointer products_prerequisite" v-on:click="show_prerequisites = !show_prerequisites" v-click-outside="outside">{{$t('inperson_event_edit.choose')}}</div>
                  </div>
                  <div v-if="show_prerequisites" id="prerequisite_popup" class="course_prerequisite_popup custom_popover">
                    <prerequisites-popup v-bind:selected_prerequisites="selected_prerequisites" :available_prerequisites="available_prerequisites"></prerequisites-popup>
                  </div>
                  <textarea id="prerequisite_textarea" name="prerequisite_textarea" rows="1" cols="76">{{this.selected_prerequisites_list}}</textarea>
                </div>
                <label> {{ $t('create_course.who_can_see_this_course')}} </label>
                <div class="nested_form_group">
                  <div class="form_group">
                    <select name="user_visibility" @change="UserVisibility($event)">
                      <option value=1>Everyone</option>
                      <option value=2>Only Certain Groups</option>
                      <option value=0>Noone</option>
                    </select>
                    <input type="text" class="white_background" />
                  </div>
                  <div class="col-md-6 col-sm-6 same_row form_group">
                    <div> {{ $t('create_course.catalog_start_date')}} </div>
                    <input id="CatalogStartDate" v-model="course.catalog_start_date" class="event_start_end_date">
                    <input id="CatlogStartTime"v-model="course.catalog_start_time" class="event_start_end_time">
                  </div>
                  <div class="col-md-6 col-sm-6 same_row form_group">
                    <div> {{ $t('create_course.catalog_end_date')}} </div>
                    <input id="CatalogEndDate" v-model="course.catalog_end_date" class="event_start_end_date">
                    <input id="CatalogEndTime" v-model="course.catalog_end_time" class="event_start_end_time">
                  </div>
                  <div class="form_group">
                    <label> {{ $t('create_course.access_expires')}} </label>
                    <select name="access_expires">
                      <option v-for="(item,index) in expire_values" :key="'access_expires_'+item[1]" :value='item[1]'>{{item[0]}}</option>
                    </select>
                  </div>
                </div>
                <div class="row form_group padding_0 margin_0">
                  <div class="col-sm-6 padding_0"> {{ $t('create_course.assign_course_to')}} </div>
                  <div class="col-sm-6 padding_0 text_align_right cursor_pointer products_user" v-on:click="show_users = !show_users" v-click-outside="outside">{{$t('inperson_event_edit.choose')}}</div>
                </div>
                <div v-if="show_users" id="users_popup" class="course_prerequisite_popup custom_popover">
                  <users-popup v-bind:selected_users="selected_users" :available_users="available_users"></users-popup>
                </div>
                <textarea id="user_textarea" name="user_textarea" rows="1" cols="76">{{this.selected_users_list}}</textarea>
                <div class="form_group">
                  <label> {{ $t('create_course.view_content')}} </label>
                  <div>
                    <div>  
                      <input type="radio" id="any_order" name="view_content" value="30" checked>
                      <label for="any_order">In any order</label><br>
                    </div>
                    <div>
                      <input type="radio" id="defined_order" name="view_content" value="60">
                      <label for="defined_order">defined order</label><br>
                    </div>
                  </div>
                </div>
                <div class="form_group">
                  <label> {{ $t('create_course.credits')}} </label>
                  <input type="text" class="grey_background" v-model="course.credits"/>
                </div>
                <div class="form_group">
                  <label> {{ $t('create_course.product_code')}} </label>
                  <input type="text" class="grey_background" v-model="course.code"/>
                </div>

                <label> {{ $t('create_course.schedule')}} </label>
                <div class="nested_form_group">
                  <div class="col-md-6 col-sm-6 form_group same_row">
                    <label> {{ $t('create_course.scheduled_start_date')}} </label>
                    <input id="ScheduleStartDate" class="white_background" v-model="course.schedule_start_date"/>
                  </div>
                  <div class="col-md-6 col-sm-6 same_row form_group">
                    <label> {{ $t('create_course.scheduled_end_date')}} </label>
                    <input id="ScheduleEndDate" class="white_background" v-model="course.schedule_end_date"/>
                  </div>

                </div>
              </div>
            </div>`,
  props: {
    top_section: Boolean,
    course_data: Object
  },
  data: function() {
    return {
      course: {
        title: '',
        raw_data: '',   
        catalog_start_date: Date.now().toString("dd-MM-yyyy"),
        catalog_start_time: Date.now().toString("HH:mm"),
        catalog_end_date: '',
        catalog_end_time: '',
        new_course_prerequisite_attributes: [],
        new_category_attributes: [],
        new_enrollment_attributes: [],
        schedule_start_date: Date.now().toString("dd-MM-yyyy"),
        schedule_end_date: '',
        code: '',
        credits: '',
        display_in_catalog: 1,  
        shuffle_questions: false,
        subscribe_only: false,
        system_generated: false,
        tag_list: [],
        view_in_defined_order: false,
        new_single_page: true,
        passing_percentage: 0,
        language: '',
        duration: ''
      },
      show_categories: false,
      show_prerequisites: false,
      show_users: false,
      show_non_member_prices: false,
      show_member_prices: false,
      tooltip_popup: false,
      price_check: true,
      member_price_check: true,      
      price_schedule_value: this.course_data.pricing_hash.price,
      member_price_schedule_value: this.course_data.pricing_hash.price_member,
      pricing_details: this.course_data.pricing_hash,
      selected_categories: this.course_data.selected_categories,
      available_categories: this.course_data.categories,
      selected_prerequisites: this.course_data.selected_prerequisites,
      available_prerequisites: this.course_data.available_prerequisites,
      selected_users: this.course_data.selected_users,
      available_users: this.course_data.available_users,
      selected_categories_list: '',
      selected_prerequisites_list: '',
      selected_users_list: '',
      catalog_start_date: Date.now().toString("dd-MM-yyyy"),
      catalog_start_time: Date.now().toString("HH:mm"),
      catalog_end_date: '',
      catalog_end_time: '',
      expire_values: this.course_data.expire_values,
      users_list_path: this.course_data.users_list_path,
      schedule_start_date: Date.now().toString("dd-MM-yyyy"),
      schedule_end_date: '',
      languages: this.$t('create_course.languages')
    }
  },
  mounted: function() {
    DateTimePicker('CatalogStartDate','d-m-Y',true,false);
    DateTimePicker('CatalogEndDate','d-m-Y',true,false);
    DateTimePicker('ScheduleStartDate','d-m-Y',true,false);
    DateTimePicker('ScheduleEndDate','d-m-Y',true,false);
    DateTimePicker('CatlogStartTime','H:i',false,true);
    DateTimePicker('CatalogEndTime','H:i',false,true);
    this.course.catalog_start_date = jQuery('#CatalogStartDate').val();
    this.course.catalog_start_time = jQuery('#CatlogStartTime').val();
    this.course.catalog_end_date = jQuery('#CatalogEndDate').val();
    this.course.catalog_end_time = jQuery('#CatalogEndTime').val();
  },
  methods: {
    outside: function(e) {
      if((jQuery(e.target).closest('#category_popup').length < 1) && (jQuery(e.target).closest('.products_category').length < 1) ) {
        this.show_categories = false;
      }
      if((jQuery(e.target).closest('#prerequisite_popup').length < 1) && (jQuery(e.target).closest('.products_prerequisite').length < 1) ) {
        this.show_prerequisites = false;
      }
      if((jQuery(e.target).closest('#users_popup').length < 1) && (jQuery(e.target).closest('.products_user').length < 1) ) {
        this.show_users = false;
      }
    },
    UserVisibility: function(event) {
      
    },
    CourseCreationData: function() {
      var course = [];
      course['title'] = this.title;
      course['raw_data'] = this.raw_data
    }
  }
});

Vue.component('price-schedules-popup',{
  template: `<div :id="popup_id">
              <div class="col-sm-12 padding_0">
                <div class="col-sm-8 padding_0">Price Schedule</div>
                <div class="col-sm-4 padding_0 cursor_pointer" v-on:click="new_price()"> + Create a Price </div>
              </div>
              <hr class="margin_bottom_0"/>
              <price-schedules-content :effective_now="true" :content_id="content_id" :for_member="for_member"/>
              <price-schedules-content v-if="show_new_price" v-for="(create_new_price,index) in create_new_prices" :index="index" :key="create_new_price" :create_new_price="create_new_price" :content_id="content_id" :show_new_price="show_new_price" :effective_now="false" :for_member="for_member"/>
              <div class="col-sm-12 padding_0" id="row_name">
                <div class="col-sm-12 padding_0"> 
                  <div class="right_float">
                    <a id="cancel_name" v-on:click="cancel_selection" class="cancel no_underline padding_right_10">{{$t('common.cancel')}}</a> 
                    <button v-if="for_member" v-on:click="save_selection_member_pricing()" class="font_open_sans_semibold_10_blue btn btn-default btn-round-sm">{{$t('common.save').toUpperCase()}}</button>
                    <button v-else v-on:click="save_selection_pricing()" class="font_open_sans_semibold_10_blue btn btn-default btn-round-sm">{{$t('common.save').toUpperCase()}}</button> 
                  </div>               
                </div>
              </div> 
            </div>`,
  props: {
    for_member: Boolean,
    popup_id: String,
    show_member_prices: Boolean,
    show_non_member_prices: Boolean
  },
  data: function() {
    return {
      show_new_price: false,
      counter: 0,
      create_new_prices: [],
      content_id: ''
    }
  },
  mounted: function() {
    this.content_id = this.for_member ? 'member_price_schedule' : 'price_schedule'
  },
  methods: {
    new_price: function() {
      this.show_new_price = true;
      this.create_new_prices.push(this.counter);
      this.counter++
    },
    cancel_selection: function() {
      this.$parent.show_non_member_prices = false;
      this.$parent.show_member_prices = false;
    },
    save_selection: function() {
      var free_product = this.$children[0].price_schedule.free
      if (!(free_product)) {
        var price = this.$children[0].price_schedule.price
        jQuery("#current_" + this.content_id).find(":input#" + this.content_id + "__free_false").click();
        this.for_member? (this.$parent.member_price_schedule_value = price) : (this.$parent.price_schedule_value = price)
      } else {
        jQuery("#current_" + this.content_id).find(":input#" + this.content_id + "__free_true").click();
      }      
      this.cancel_selection();      
    },
    save_selection_member_pricing: function() {
      this.$parent.$parent.member_price_schedule_params = []
      this.$children.forEach(item => {
        this.$parent.$parent.member_price_schedule_params.push(item.price_schedule)
      }); 
      this.save_selection();
    },
    save_selection_pricing: function() {
      this.$parent.$parent.price_schedule_params = []
      this.$children.forEach(item => {
        this.$parent.$parent.price_schedule_params.push(item.price_schedule)
      });   
      this.save_selection();
    }   
  }
});

Vue.component('price-schedules-content',{
  template: `<div :id="content_id + '_for_' + index">
              <hr v-if="show_new_price" class="margin_bottom_0"/>
              <div id="rates">
                <div class="col-sm-12 padding_0">
                  <div class="col-sm-4 padding_0">
                    <input v-if="show_new_price" type="radio" :id="content_id + '__free_' + index + '_true'" :name="content_id + '[][free_' + index +']'" v-on:click="price_schedule.free = true" value="true" checked>
                    <input v-else type="radio" :id="content_id + '__free_true'" :name="content_id + '[][free]'" v-on:click="price_schedule.free = true" value="true" checked>
                    <label for="free_price">Free</label><br>
                  </div>
                  <div class="col-sm-8 padding_0">
                    <div id="effective_price" value="effective_price"> </div>
                    <label for="effective_price">{{this.effective_now_text}}</label><br>
                  </div>                
                </div>
                <div class="col-sm-1 padding_0 paid">            
                  <input v-if="show_new_price" type="radio" :id="content_id + '__free_' + index + '_false'" :name="content_id + '[][free_' + index +']'" v-on:click="price_schedule.free = false" value="false">
                  <input v-else type="radio" :id="content_id + '__free_false'" :name="content_id + '[][free]'" v-on:click="price_schedule.free = false" value="false">
                  <label for="price">$</label><br>
                </div>
                <input v-model="price_schedule.price" :id="content_id + '__price'" class="col-sm-2 padding_0" :name="content_id + '[][price]'" rows="1" cols="20" value="price_schedule.price"></input>                 
                <div v-if="show_new_price">
                  <div class="col-sm-1 padding_0"></div>
                  <input :id="'price_start_date'+index" v-model="price_schedule.schedule_display_date" class="col-sm-3 padding_0" :name="content_id + '[][schedule_display_date]'" value="price_schedule.schedule_display_date">
                  <div class="col-sm-1 padding_0"></div>
                  <input :id="'price_start_time'+index" v-model="price_schedule.schedule_display_time" class="col-sm-2 padding_0" :name="content_id + '[][schedule_display_time]'" value="price_schedule.schedule_display_time">
                  <div class="col-sm-1 cursor_pointer" v-on:click="delete_price()"> Delete </div>
                </div>                  
              </div>
            </div>`,
  props: {
    effective_now: Boolean,
    show_new_price: Boolean,
    content_id: String,
    create_new_price: Number,
    index: Number,
    for_member: Boolean
  },
  data: function() {
    return {
      effective_now_text: this.effective_now ? "Effective Now" : "Starting",
      price_schedule: {
        effective_now: this.effective_now,
        free: true,
        for_member: this.for_member,
        price: 0.00,
        current_schedule: this.effective_now,
        schedule_order: 0,
        schedule_display_date: Date.now().toString("MMM d, yyyy"),
        schedule_display_time: Date.now().toString("h:mm tt")
      }
    }
  },
  mounted: function() {
    DateTimePicker('price_start_date'+ this.index,'M d, Y',true,false);
    DateTimePicker('price_start_time'+ this.index,'g:i a',false,true);
    this.price_schedule.schedule_display_date = jQuery('#price_start_date'+ this.index).val();
    this.price_schedule.schedule_display_time = jQuery('#price_start_time'+ this.index).val();
  },
  methods: {
    delete_price: function() {     
      this.$http.delete('/admin/price_schedule').then(function(response) {
        if(response.body) {
          var index = this.$parent.create_new_prices.indexOf(this.create_new_price)
          this.$parent.create_new_prices.splice(index, 1)       
        }
      });
    }
  }
});

Vue.component('categories-popup',{
  template: `<div>
              <div class="course_selection_container">
                <input type="text" id="category_search_box" class="notification_search_input form-control input-sm" :placeholder="$t('announcement.search_courses')" v-on:keyup="search_courses"/>
                <img src="/images/clear.png" id="reset_category_search" class="products_category" v-if="clear_search" v-on:click="clear_search_data"></img>
                <div> Selected Items</div>
                <div v-if="selected_items_sorted_array.length != 0">
                  <div v-bind:id="'selected_category_list' + item.id" v-on:click="unselect_product($event,item,index)" v-for="(item,index) in selected_items_sorted_array" v-bind:key="item.id" class="products_category">
                    <input class="products_category" checked='true' type="checkbox" v-bind:id="'checkbox_' + item.id" :name="item.name" :value="item.name">
                    <label class="products_category" v-bind:id="'label' + item.id" :for="'checkbox_' + item.id"> {{item.name}}</label>
                  </div>
                </div>  
                <div> Available Items</div>
                <div v-if="available_items_sorted_array.length != 0" id="available_category_list">
                  <div v-bind:id="'category_list' + item.id" v-on:click="select_product($event,item,index)" v-for="(item,index) in available_items_sorted_array" v-bind:key="item.id" class="items products_category">
                    <input class="products_category" type="checkbox" v-bind:id="'checkbox_' + item.id" :name="item.name" :value="item.name">
                    <label class="products_category" v-bind:id="'label' + item.id" :for="'checkbox_' + item.id"> {{item.name}}</label>
                  </div>
                </div> 
              </div> 
              <div class="row margin_left_10" id="row_name">
                <div class="col-sm-12 padding_right_0 padding_top_4 margin_left_2"> 
                  <div class="right_float">
                    <a id="cancel_name" v-on:click="cancel_selection" class="cancel no_underline padding_right_10">{{$t('common.cancel')}}</a> 
                    <button v-on:click="save_selection" class="font_open_sans_semibold_10_blue btn btn-default btn-round-sm">{{$t('common.save').toUpperCase()}}</button> 
                  </div>               
                </div>
              </div> 
            </div>`,
  props: {
    selected_categories: Array,
    available_categories: Array
  },
  data: function() {
    return {
      clear_search: false,
      available_categories_arr: [...this.available_categories],
      selected_categories_arr: [...this.selected_categories],
    }
  },
  computed: {
    available_items_sorted_array: function() {
      return array_sort_by_name(this.available_categories_arr,"name");        
    },
    selected_items_sorted_array: function() { 
      return array_sort_by_name(this.selected_categories_arr,"name");       
    }
  },
  mounted: function() {
    jQuery(".course_selection_container").niceScroll({cursorcolor:"#4184F3",cursorwidth:"3px",autohidemode: "scroll"});
  },
  methods: {
    select_product: function(event,item,index){
      this.available_categories_arr.splice(index, 1);
      this.selected_categories_arr.splice(index, 0, item);
    },
    unselect_product: function(event,item,index){
      this.selected_categories_arr.splice(index, 1);
      this.available_categories_arr.splice(index, 0, item);
    },
    search_courses: function(){
      this.clear_search = true;
      var keyword = jQuery('#category_search_box').val();
      var equal = search_items('available_category_list', keyword);
      if (keyword.length == 0 || equal) {
        this.clear_search = false;
      }
    },
    clear_search_data: function(){
      jQuery("#category_search_box").val("");
      this.search_courses();
      this.clear_search = false;
    },
    cancel_selection: function() {
      this.$parent.show_categories = false;
    },
    save_selection: function() {
      var selected_arr = [];
      for (var i = 0; i < this.selected_categories_arr.length; i++) {
        this.$parent.course.new_category_attributes.push({category_id: this.selected_categories_arr[i].id})
        selected_arr.push(this.selected_categories_arr[i].name)
      }
      this.$parent.available_categories = this.available_categories_arr;
      this.$parent.selected_categories = this.selected_categories_arr;
      this.$parent.show_categories = false;
      this.$parent.selected_categories_list = selected_arr.join();
      
    }
  }
});

Vue.component('prerequisites-popup',{
  template: `<div>
              <div class="course_selection_container">
                <input type="text" id="prerequisites_search_box" class="notification_search_input form-control input-sm" :placeholder="$t('announcement.search_courses')" v-on:keyup="search_courses"/>
                <img src="/images/clear.png" id="reset_category_search" class="products_prerequisite" v-if="clear_search" v-on:click="clear_search_data"></img>
                <div> Selected Items</div>
                <div v-if="selected_items_sorted_array.length != 0">
                  <div v-bind:id="'selected_prerequisites_list' + item.id" v-on:click="unselect_product($event,item,index)" v-for="(item,index) in selected_items_sorted_array" v-bind:key="item.type+item.id" class="products_prerequisite">
                    <input class="products_prerequisite" checked='true' type="checkbox" v-bind:id="'checkbox_' + item.id" :name="item.title" :value="item.title">
                    <label class="products_prerequisite" v-bind:id="'label' + item.id" :for="'checkbox_' + item.id"> {{item.title}}</label>
                  </div>
                </div>  
                <div> Available Items</div>
                <div v-if="available_items_sorted_array.length != 0" id="available_prerequisites_list">
                  <div v-bind:id="'prerequisites_list' + item.id" v-on:click="select_product($event,item,index)" v-for="(item,index) in available_items_sorted_array" v-bind:key="item.type+item.id" class="items products_prerequisite">
                    <input class="products_prerequisite" type="checkbox" v-bind:id="'checkbox_' + item.id" :name="item.title" :value="item.title">
                    <label class="products_prerequisite" v-bind:id="'label' + item.id" :for="'checkbox_' + item.id"> {{item.title}}</label>
                  </div>
                </div> 
              </div> 
              <div class="row margin_left_10" id="row_name">
                <div class="col-sm-12 padding_right_0 padding_top_4 margin_left_2"> 
                  <div class="right_float">
                    <a id="cancel_name" v-on:click="cancel_selection" class="cancel no_underline padding_right_10">{{$t('common.cancel')}}</a> 
                    <button v-on:click="save_selection" class="font_open_sans_semibold_10_blue btn btn-default btn-round-sm">{{$t('common.save').toUpperCase()}}</button> 
                  </div>               
                </div>
              </div> 
            </div>`,
  props: {
    selected_prerequisites: Array,
    available_prerequisites: Array
  },
  data: function() {
    return {
      clear_search: false,
      available_prerequisites_arr: [...this.available_prerequisites],
      selected_prerequisites_arr: [...this.selected_prerequisites],
    }
  },
  computed: {
    available_items_sorted_array: function() {
      return array_sort_by_name(this.available_prerequisites_arr,"title");        
    },
    selected_items_sorted_array: function() { 
      return array_sort_by_name(this.selected_prerequisites_arr,"title");       
    }
  },
  mounted: function() {
    jQuery(".course_selection_container").niceScroll({cursorcolor:"#4184F3",cursorwidth:"3px",autohidemode: "scroll"});
  },
  methods: {
    select_product: function(event,item,index){
      this.available_prerequisites_arr.splice(index, 1);
      this.selected_prerequisites_arr.splice(index, 0, item);
    },
    unselect_product: function(event,item,index){
      this.selected_prerequisites_arr.splice(index, 1);
      this.available_prerequisites_arr.splice(index, 0, item);
    },
    search_courses: function(){
      this.clear_search = true;
      var keyword = jQuery('#prerequisites_search_box').val();
      var equal = search_items('available_prerequisites_list', keyword);
      if (keyword.length == 0 || equal) {
        this.clear_search = false;
      }
    },
    clear_search_data: function(){
      jQuery("#prerequisites_search_box").val("");
      this.search_courses();
      this.clear_search = false;
    },
    cancel_selection: function() {
      this.$parent.show_prerequisites = false;
    },
    save_selection: function() {
      var selected_arr = [];
      for (var i = 0; i < this.selected_prerequisites_arr.length; i++) {
        this.$parent.course.new_course_prerequisite_attributes.push({prerequisite_id: this.selected_prerequisites_arr[i].id,prerequisite_type: this.selected_prerequisites_arr[i].title})
        selected_arr.push(this.selected_prerequisites_arr[i].title)
      }
      this.$parent.available_prerequisites = this.available_prerequisites_arr;
      this.$parent.selected_prerequisites = this.selected_prerequisites_arr;
      this.$parent.show_prerequisites = false;
      this.$parent.selected_prerequisites_list = selected_arr.join();
      
    }
  }
});

Vue.component('users-popup',{
  template: `<div>
              <div class="course_selection_container">
                <input type="text" id="users_search_box" class="notification_search_input form-control input-sm" :placeholder="$t('announcement.search_courses')" v-on:keyup="search_courses"/>
                <img src="/images/clear.png" id="reset_category_search" class="products_user" v-if="clear_search" v-on:click="clear_search_data"></img>
                <div> Selected Items</div>
                <div v-if="selected_items_sorted_array.length != 0">
                  <div v-bind:id="'selected_users_list' + item.id" v-on:click="unselect_product($event,item,index)" v-for="(item,index) in selected_items_sorted_array" v-bind:key="'user_'+item.id" class="products_user">
                    <input class="products_user" checked='true' type="checkbox" v-bind:id="'checkbox_' + item.id" :name="item.name" :value="item.name">
                    <label class="products_user" v-bind:id="'label' + item.id" :for="'checkbox_' + item.id"> {{item.name}}</label>
                  </div>
                </div>  
                <div> Available Items</div>
                <div v-if="available_items_sorted_array.length != 0" id="available_user_list">
                  <div v-bind:id="'prerequisites_list' + item.id" v-on:click="select_product($event,item,index)" v-for="(item,index) in available_items_sorted_array" v-bind:key="'user_'+item.id" class="items products_user">
                    <input class="products_user" type="checkbox" v-bind:id="'checkbox_' + item.id" :name="item.name" :value="item.name">
                    <label class="products_user" v-bind:id="'label' + item.id" :for="'checkbox_' + item.id"> {{item.name}}</label>
                  </div>
                </div> 
              </div> 
              <div class="row margin_left_10" id="row_name">
                <div class="col-sm-12 padding_right_0 padding_top_4 margin_left_2"> 
                  <div class="right_float">
                    <a id="cancel_name" v-on:click="cancel_selection" class="cancel no_underline padding_right_10">{{$t('common.cancel')}}</a> 
                    <button v-on:click="save_selection" class="font_open_sans_semibold_10_blue btn btn-default btn-round-sm">{{$t('common.save').toUpperCase()}}</button> 
                  </div>               
                </div>
              </div> 
            </div>`,
  props: {
    selected_users: Array,
    available_users: Array
  },
  data: function() {
    return {
      clear_search: false,
      available_users_arr: [...this.available_users],
      selected_users_arr: [...this.selected_users],
    }
  },
  computed: {
    available_items_sorted_array: function() {
      return array_sort_by_name(this.available_users_arr,"name");        
    },
    selected_items_sorted_array: function() { 
      return array_sort_by_name(this.selected_users_arr,"name");       
    }
  },
  mounted: function() {
    jQuery(".course_selection_container").niceScroll({cursorcolor:"#4184F3",cursorwidth:"3px",autohidemode: "scroll"});
  },
  methods: {
    select_product: function(event,item,index){
      this.available_users_arr.splice(index, 1);
      this.selected_users_arr.splice(index, 0, item);
    },
    unselect_product: function(event,item,index){
      this.selected_users_arr.splice(index, 1);
      this.available_users_arr.splice(index, 0, item);
    },
    search_courses: function(){
      this.clear_search = true;
      var keyword = jQuery('#users_search_box').val();
      var equal = search_items('available_user_list', keyword);
      if (keyword.length == 0 || equal) {
        this.clear_search = false;
      }
    },
    clear_search_data: function(){
      jQuery("#users_search_box").val("");
      this.search_courses();
      this.clear_search = false;
    },
    cancel_selection: function() {
      this.$parent.show_users = false;
    },
    save_selection: function() {
      var selected_arr = [];
      for (var i = 0; i < this.selected_users_arr.length; i++) {
        this.$parent.course.new_enrollment_attributes.push(this.selected_users_arr[i].id)
        selected_arr.push(this.selected_users_arr[i].name)
      }
      this.$parent.available_users = this.available_users_arr;
      this.$parent.selected_users = this.selected_users_arr;
      this.$parent.show_users = false;
      this.$parent.selected_users_list = selected_arr.join();
      
    }
  }
});