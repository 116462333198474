<template>
	<div id="pdf-content-wrapper" class="pdf-content-wrapper w-100 h-100 overflow-auto">
		<div id="viewerContainer">
			<div class="text-center mb-3">
				<button id="prev" class="btn lxa_bg_01 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase">Previous</button>
				<button id="next" class="btn lxa_bg_01 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase ml-3">Next</button>
				&nbsp; &nbsp;
				<span>Page: <span id="page_num"></span> / <span id="page_count"></span></span>
			</div>
			<canvas id="viewer" class="pdfViewer" style="margin-left: 20%;"></canvas>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'PdfViewer',
		data() {
			return {}
		}
	}
</script>