<template>
	<div class="modal-mask" v-if="show" transition="modal" style="background: #f0f0f0; position: absolute; width: 100%; top: -300px;">
		<div class="modal-container modal-content border-0 rounded-0 position-relative" id="content-launch-modal" style="z-index: 501; width: 95%; margin: 30px 20px; border-radius: 10px !important; max-height: 800px;">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'modal',
		data() {
			return {
				opened: false
			}
		},
		props: ["show", "onClose", "onOpen"],
		updated: function () {
			if(this.show && !this.opened) {
				this.opened = true;
				this.open();
			}
			this.$nextTick(function () {
				// $('[data-toggle="tooltip"]').tooltip({ container: '#content-launch-modal',trigger:'hover'});
			});
		},
		methods: {
			close: function () {
				this.opened = false;
				this.onClose();
			},
			open: function() {
				this.onOpen();
			}
		}
	}
</script>