import Vue from 'vue/dist/vue.esm'
const EventBus = new Vue();

Vue.component('login-modal', {
  template: `<div class="modal" id="login_modal" role="dialog">
              <div class="modal-dialog modal-xl">
                <div class="modal-content">                  
                  <div class="modal-body" id="login_modal_body" style="background-image: url('Bg4.svg');">
                   <img src="/images/new_ui/Awning.svg" class="img-fluid" id="awning_banner"/>                                                                 
                      <div class="row" id="forms">                        
                        <div class="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 col-md-12 col-sm-12">
                          <div class="container p-4">                                                     
                              <div class="tab">
                                <a v-on:click="login=true" role="button" :class="[{'border_bottom_success pb-2 mr-2 text-dark text-decoration-none':login}, 'mr-2 text-dark  text-decoration-none']" data-bs-toggle="tab" data-bs-target="#login_form"><span><i class="fad fa-sign-in-alt lxa_text_color_05 font_barlow_semi_condensed_light_16 lxa_icon_size"></i></span><span class="font_barlow_semi_condensed_light_18"> Sign In </span></a>                                
                                <a v-on:click="login=false" role="button" :class="[{'border_bottom_primary pb-2 ml-2 text-dark  text-decoration-none': !(login)}, 'ml-2 text-dark  text-decoration-none']" data-bs-toggle="tab" data-bs-target="#signup"><span><i class="fad fa-user-plus lxa_text_color_06 font_barlow_semi_condensed_light_16" lxa_icon_size></i></span><span class="font_barlow_semi_condensed_light_18"> Sign up </span></a>                                                                
                              </div>
                              <div v-if="login" class="tab-content">
                                <login-form v-bind:sso_enabled="sso_enabled" :encryted_company_id="encryted_company_id"></login-form>
                                <forgot_password_form></forgot_password_form>
                                <password_reset_confirm_form></password_reset_confirm_form>
                                <reset_password_form></reset_password_form>
                                <confirm_message_after_reset_form></confirm_message_after_reset_form>
                              </div>
                              <div v-else class="tab-content">
                                <signup_form></signup_form>
                                <confirmation_message_after_signup></confirmation_message_after_signup>
                              </div>                             
                          </div>                
                        </div>                        
                      </div>
                   </div>  
                  </div>
                </div>
              </div>
            </div>`,
  props: {
    sso_enabled: Boolean,
    encryted_company_id: String
  },
  data: function() {
    return {
      login: true
    }
  },
  mounted: function() {
    jQuery('#login_modal').on('shown.bs.modal', function() {
      jQuery('input').val('');
      jQuery(".invalid-feedback").hide();
      jQuery("input[name=remember_signin]").prop("checked", "");
      resize_login_modal();
    });
    jQuery('#login_modal').on('hidden.bs.modal', function() {
      jQuery("#error_msg").html('');
      EventBus.$emit('ResetForm');
    });
  }  
});

Vue.component('login-form', {
  template: `<div id="login_form" class="font_barlow_semi_condensed_light_14">
              <div class="row">
                <div class="col-12">
                  <form class="form-horizontal" v-on:submit.prevent="onSubmit" role="form">
                    <div class="form-row pb-xl-2 pt-2">
                      <div class="col-6 text-left">
                        <label class="col-form-label font_barlow_semi_condensed_light_14">{{$t('login.email_address')}}<span>&nbsp;</span><span class="lxa_text_color_01">*</span></label>
                      </div>
                      <div class="col-6 text-right">
                          <div class="col-form-label">
                            <span class="invalid-feedback">                          
                              <span class ="font_barlow_semi_condensed_10_red" v-show="errors.has('email')">{{ $t('login.enter_valid_email_address') }}</span>                        
                            </span>
                          </div>
                      </div>
                      <input type="email" v-validate="'required|email'" data-vv-validate-on="none" name="email" class="form-control  border-0 line_height_17 border_radius_9 error_field lxa_txt_input lxa_font_size_03 lxa_text_color_02" v-model="email">
                    </div>
                    <div class="form-row pb-xl-2">
                      <div class="col-6 text-left">
                        <label class="col-form-label font_barlow_semi_condensed_light_14">{{$t('login.password')}}<span>&nbsp;</span><span class="lxa_text_color_01">*</span></label>
                      </div>
                      <div class="col-6 text-right">        
                          <div class="col-form-label">
                            <span class="invailid-feedback">                
                              <span class ="font_barlow_semi_condensed_10_red" v-show="errors.has('password')">{{ $t('login.enter_password') }}</span>                        
                            </span>
                          </div>
                      </div>                      
                      <input v-bind:type="[showPassword ? 'text' : 'password']"  v-validate="'required'" data-vv-validate-on="none" name="password" class="form-control  border-0 line_height_17 border_radius_9 error_field lxa_txt_input lxa_font_size_03 lxa_text_color_02" v-model="password">
                      <span @click="showPassword=!showPassword" :key="showPassword">
                      <i class="fad lxa_text_color_06 lxa_eye_icon" :class="[showPassword ? 'fa-eye-slash' : 'fa-eye']"></i>
                      </span>                      
                    </div>
                    <span id="error_msg" class="red_color"> </span>
                    <div class="form-row pt-xl-1 pb-xl-2">
                      <div class="col-6">
                        <div class="col-form-label text-left">
                          <div class="form-check form-check-inline">                
                          <div id="remember_signin_wrapper" class="remember_signin_wrapper custom-controls custom-checkbox">
                          <input v-model="remember_me" id="remember_signin" name="remember_signin" class="remember_signin custom-control-input lxa-bs-checkboxbtn" type="checkbox" value="remember" v-bind:true-value="1" v-bind:false-value="0">
                          <label class="string_trunk8 custom-control-label" for="remember_signin">
                            <i class="fad fa-stop lxa_icon_size lxa_text_color_01"></i>
                            <i class="fad fa-check-square lxa_icon_size lxa_text_color_01"></i>
                            <span class="signed_in_label padding_left_2 font_barlow_semi_condensed_light_14">{{$t('login.keep_me_signed_in')}}</span>
                          </label>
                        </div>

                          </div>
                        </div>
                      </div>
                      <div class="col-6 text-right">
                        <label class="col-form-label cursor_pointer"><span class="pr-2"><i data-toggle="tooltip" data-placement="top" title="Forgot" class="fad fa-key lxa_text_color_01 lxa_font_size_04"></i></span><span id="lnkforget" class="hyperlink font_barlow_semi_condensed_light_14" v-on:click="forgot()">{{$t('login.forgot')}}</span></label>
                      </div>
                    </div>                    
                    <div class="form-row pt-xl-3 pb-xl-3">
                      <div class="col-6 text-left"><a :href="google_auth_url" data-method="post" v-if="sso_enabled" class="btn lxa_bg_01 btn-sm lxa_button_title_light_1_a lxa_btn_txt_01 lxa_border_radius lxa_font_size_04" >SIGN IN WITH GOOGLE</a></div>
                      <div class="col-6 text-right">
                        <button class="btn lxa_bg_03 btn-sm mr-2 lxa_button_title_light_1_a lxa_btn_txt_01 lxa_border_radius lxa_font_size_04" data-dismiss="modal" type="button">{{$t('create_course.cancel').toUpperCase()}}</button>                                                
                        <button type="submit" class="btn lxa_bg_02 btn-sm ml-2 lxa_button_title_light_1_a lxa_btn_txt_01 lxa_border_radius lxa_font_size_04">{{$t('common.login').toUpperCase()}}</button>
                      </div>
                    </div>
                  </form>              
                </div>                
              </div>
            </div>`,

  props: {
    sso_enabled: Boolean,
    encryted_company_id: String
  },

  data: function() {
    return {
      email: '',
      password: '',
      showPassword: false,
      remember_me: 0,
    }
  },
  computed: {
    google_auth_url: function() {
      return '/users/auth/google_oauth2?state='+this.encryted_company_id
    }
  },
  methods: {
    forgot() { 
      jQuery("#login_form").find('.error_field').html('');
      this.errors.clear();
      jQuery("#login_form").addClass("hide");
      // jQuery("#login_form").find('form input').val('');
      jQuery("#forgot_password").removeClass("hide");
      // jQuery("#forgot_password").addClass("popover-content");
      jQuery("#confirm_message").addClass("hide");
      jQuery("#confirm_message").removeClass("popover-content");
    },
    onSubmit() {
      jQuery("#error_msg").html('')
      this.$validator.validateAll().then(result => {
        if (result) {
          var csrfToken = encodeURIComponent(jQuery('meta[name="csrf-token"]').attr('content'));
          jQuery.ajax({
            type: 'POST',
            url: "/users/sign_in",
            data : jQuery('#form').serialize()+ "user[email]="+this.email+"&user[password]="+this.password+"&user[remember_me]="+this.remember_me + "&from_single_page=1&authenticity_token="+csrfToken,
            success: function(response) {
              jQuery("#error_msg").html(jQuery.parseJSON(response));
            },
            error: function(response){
              jQuery("#error_msg").html(response['responseText']).show();
            } 
          });
        }
      });
      jQuery(".invalid-feedback").show();
    }
  },
  mounted: function() {
    jQuery("#login_form").css('margin-left', ((-jQuery('#login_form').width()) + (jQuery('#login_link').width()/2) + 18) + 'px');
    var current = this; 
    var login_checkbox = jQuery(".remember_me input[type=checkbox]");
    customCheckbox(login_checkbox);
    jQuery("#error_msg").html('')
    EventBus.$on('ResetForm', (data) => {
      jQuery.extend(this.$data, this.$options.data.apply(this));
    });
  }
});

Vue.component('forgot_password_form', {
  template:`<div id="forgot_password" class="hide row text-center">
              <div class="col-12 pt-3">
                 <form class="form-horizontal" v-on:submit.prevent="onSubmit" role="form">
                  <div class="form-row p-2">
                    <div class="col-4 text-left pl-0">
                      <label class="col-form-label font_barlow_semi_condensed_light_14"> {{$t('login.email_address')}} <span class="lxa_text_color_01">*</span></label>
                    </div>
                    <div class="col-8 text-right">                      
                      <div class="col-form-label">
                        <span class="invalid-feedback">
                          <span class="font_barlow_semi_condensed_10_red error_field" id="forgot_password_errors"></span>
                        </span>
                      </div>                      
                    </div>
                    <input type="email" class="form-control line_height_17 border-0 border_radius_9 lxa_txt_input lxa_font_size_03 lxa_text_color_02" id="emails" :placeholder="$t('login.email_address')" v-model="email">                    
                  </div>                  
                  <div class="form-row">                    
                    <div class="col-12 _offset-3 d-flex align-items-center justify-content-end mt-2">
                      <button class="btn lxa_bg_03 btn-sm mr-2 lxa_button_title_light_1_a text-left lxa_btn_txt_01 lxa_border_radius lxa_font_size_04" v-on:click="never_mind">{{$t('create_course.cancel').toUpperCase()}}</button>                       
                      <button type="submit" class="lxa_button_title_light_1_a ml-2 btn lxa_bg_04 btn-sm text-right lxa_btn_txt_01 lxa_border_radius lxa_font_size_04">{{$t('common.send').toUpperCase()}}</button>
                    </div>                    
                  </div>                    
                 </form>
              </div>
            </div>`,
  data() {
    return {
      email: ''
    }
  },
  methods: {
    never_mind() {
      jQuery("#forgot_password").addClass("hide");
      jQuery("#login_form").removeClass("hide");
      jQuery('input').val('');
    },
    onSubmit() {
      var self = this;
      jQuery.ajax({
        type: 'POST',
        url: "/password_resets",
        data : jQuery('#form').serialize() + "email="+this.email+"&from_single_page=1",
        success: function(response) {
          if (response) {
            jQuery("#forgot_password_errors").html(response);
          } else {
            jQuery("#forgot_password").addClass("hide");
            // this.$parent.loginPopup = false;
            // jQuery("#confirm_message").removeClass("hide");
            // jQuery("#confirm_message").addClass("popover-content");
            self.$root.popUpMessage("Reset password information has been send to your email");
            jQuery("#login_form").removeClass('hide');
          }
        }
      });
      jQuery(".invalid-feedback").show();
    }
  },
  mounted: function() {
    EventBus.$on('ResetForm', (data) => {
      jQuery.extend(this.$data, this.$options.data.apply(this));
    });
  }
});

Vue.component('password_reset_confirm_form', {
  template:`<div id="confirm_message" class="hide text-center">
              <form class="form-horizontal">
                <div class="form-row margin_bottom_4 mt-4">
                  <div class="col-12">
                    <p class="font_open_sans_regular_12_gray" id="message">{{$t('login.reset_message')}}</p>
                  </div>
                </div>
                <hr class="margin_top_0">
                <div class="form-row margin_bottom_11">        
                  <div class="col-12">
                    <a id="reset_ok" class="btn lxa_bg_04 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" role="button" v-on:click="reset_ok()">{{$t('common.okay')}}</a>
                  </div>
                </div>
              </form>
            </div>`,
  methods: {
    reset_ok() {
      jQuery("#login_form").removeClass('hide');
      this.$parent.loginPopup = false;
      jQuery("#confirm_message").addClass('hide');
      jQuery("#confirm_message").removeclass('popover-content');
    }
  }
});

Vue.component('reset_password_form', {
  template:`<div id="reset_password" class="hide">
              <form class="form-horizontal" v-on:submit.prevent="onSubmit" role="form">
                <div class="form-group margin_bottom_4 margin_top_min_3">
                  <div class="col-sm-12">
                    <p class="font_open_sans_semibold_12_black">{{$t('login.reset_password')}}</p>
                  </div>
                </div>
                <div class="form-group margin_bottom_8">
                  <div class="col-sm-12">
                    <input type="password" class="form-control input-sm " id="new_password" :placeholder="$t('login.New Password')" v-model="password" name="password">
                    <span class ="font_barlow_semi_condensed_10_red error_field" id="reset_password_error"></span>
                  </div>
                </div>
                <div class="form-group margin_bottom_25">
                  <div class="col-sm-12">
                    <input type="password" class="form-control input-sm " id="new_password_confirmation" :placeholder="$t('login.Confirm Password')" v-model="password_confirmation" name="password_confirmation">
                    <div class="error_container">  
                      <span class ="font_barlow_semi_condensed_10_red error_field" id="reset_password_confirmation_error"></span>
                    </div>
                  </div>
                </div>
                <hr>
                <div class="form-group">        
                  <div class="col-sm-offset-8 col-sm-4">
                    <button type="submit" class="font_open_sans_semibold_10_blue btn btn-default btn-round-sm">{{$t('common.reset').toUpperCase()}}</button>
                  </div>
                </div>
              </form>
            </div>`,
  data() {
    return {
      password: '',
      password_confirmation: '',
      message: this.$t('login.after_clicking_reset') 
    }
  },
  methods: {
    onSubmit() {
      var currentURL = document.URL;
      var id = currentURL.split('#').pop().split('=').pop();
      var self = this;
      jQuery.ajax({
        type: 'PUT',
        url: "/password-reset/"+id+'.json',
        data : jQuery('#form').serialize() + "user[password]="+this.password+"&user[password_confirmation]="+this.password_confirmation+"&from_single_page=1",
        success: function(response) {
          if (response.length) {
            if( response[0][0] == (jQuery('#new_password').attr('name')) ) {
              jQuery("#reset_password_error").html(response[0][0] +' '+ response[0][1]);
            } else {
              jQuery("#reset_password_error").html('');
            }
            if( response[response.length - 1][0] == (jQuery('#new_password_confirmation').attr('name')) ) {
              jQuery("#reset_password_confirmation_error").html(response[response.length - 1][0] +' '+ response[response.length - 1][1]);
            } else {
              jQuery("#reset_password_confirmation_error").html('');
            }      
          } else {
            jQuery("#reset_password").addClass("hide");
            jQuery("#confirm_message_after_reset").removeClass("hide");
            jQuery("#confirm_message_after_reset").addClass("popover-content");
            jQuery("#reset_confirm_message").html(self.message);
            setTimeout(function(){ window.location = '/' }, 1500);
          }
        }
      });
    }
  }
});

Vue.component('confirm_message_after_reset_form', {
  template:`<div id="confirm_message_after_reset" class="hide">
              <form class="form-horizontal">
                <div class="form-group margin_bottom_4">
                  <div class="col-sm-12">
                    <p class="font_open_sans_regular_12_gray" id="reset_confirm_message"></p>
                  </div>
                </div>
                <hr class="margin_top_0">
                <div class="form-group">        
                  <div class="col-sm-offset-9 col-sm-3">
                    <a id="confirm_ok" class="font_open_sans_regular_12_white btn btn-primary btn-sm" role="button" v-on:click="confirm_ok()">{{$t('common.okay')}}</a>
                  </div>
                </div>
              </form>
            </div>`,
  methods: {
    confirm_ok() {
      jQuery("#confirm_message_after_reset").addClass("hide");
      jQuery("#confirm_message_after_reset").removeClass("popover-content");
      jQuery("#login").removeClass("hide");
      jQuery("#login").addClass("popover-content");
    }
  }
});

Vue.component('signup_form', {
  template:`<div id="signup" class="row text-center">
              <div class="col-12">
                <form class="form-horizontal" v-on:submit.prevent="onSubmit" role="form">
                  <div class="form-row">
                    <div class="col-6">
                      <div class="form-row px-2">
                        <div class="col-6 text-left">
                          <label class="col-form-label" for="firstname"><span class="font_barlow_semi_condensed_light_14">{{$t('login.first_name')}}</span></label>
                        </div>
                        <div class="col-6 text-right">
                          <div class="col-form-label">
                            <span class="invalid-feedback">
                              <span class="font_barlow_semi_condensed_10_red" v-show="errors.has('firstname')">{{ $t('login.enter_first_name') }}</span>
                            </span>
                          </div> 
                        </div>
                        <input type="firstname" v-validate="'required'" id="firstname" name="firstname" class="form-control  line_height_17 border-0 border_radius_9 lxa_txt_input lxa_font_size_03 lxa_text_color_02" v-model="firstname" :placeholder="$t('login.first_name')">
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-row px-2">
                        <div class="col-6 text-left"><label class="col-form-label"><span class="font_barlow_semi_condensed_light_14">{{$t('login.last_name')}}</span></label></div>
                        <div class="col-6 text-right">
                          <div class="col-form-label">
                            <span class="invalid-feedback">
                              <span class="font_barlow_semi_condensed_10_red" v-show="errors.has('lastname')">{{ $t('login.enter_last_name') }}</span>
                            </span>
                          </div>
                        </div>
                        <input type="text" v-validate="'required'" data-vv-validate-on="none" name="lastname" class="form-control  line_height_17 border-0 border_radius_9 lxa_txt_input lxa_font_size_03 lxa_text_color_02" v-model="lastname" :placeholder="$t('login.last_name')">
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col-12">
                      <div class="form-row px-2">
                        <div class="col-6 text-left"><label class="col-form-label"><span class="font_barlow_semi_condensed_light_14">{{$t('login.email_address')}}</span></label></div>
                        <div class="col-6 text-right">
                          <div class="col-form-label">
                            <span class="invalid-feedback">
                              <span class="font_barlow_semi_condensed_10_red" v-show="errors.has('email')">{{ $t('login.enter_email') }}</span>
                              <span class="font_barlow_semi_condensed_10_red error_field" id="email_error"></span>
                            </span>
                          </div>
                        </div>
                        <input type="email" v-validate="'required|email'" data-vv-validate-on="none" name="email" id="email" class="form-control  line_height_17 border-0 border_radius_9 lxa_txt_input lxa_font_size_03 lxa_text_color_02" v-model="email" :placeholder="$t('login.email_address')">
                      </div>                      
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col-6">
                      <div class="form-row px-2">
                        <div class="col-6 text-left"><label class="col-form-label"><span class="font_barlow_semi_condensed_light_14">{{$t('login.password')}}</span></label></div>
                        <div class="col-6 text-right">
                          <div class="col-form-label">
                            <span class="invalid-feedback">
                              <span class="font_barlow_semi_condensed_10_red" v-show="errors.firstByRule('password','required')">{{ $t('login.password_cant_blank') }}</span>
                              <span class="font_barlow_semi_condensed_10_red" v-show="errors.firstByRule('password','min')">{{ $t('login.password_too_short') }}</span>
                              <span class="font_barlow_semi_condensed_10_red error_field" id="password_error"></span>
                            </span>
                          </div>
                        </div>                        
                        <input v-on:blur="ShowConfirm" v-bind:type="[showPassword ? 'text' : 'password']" v-validate="'required|min:6'" data-vv-validate-on="none" name="password" class="form-control line_height_17 border-0 border_radius_9 lxa_txt_input lxa_font_size_03 lxa_text_color_02" v-model="password" ref="password" :placeholder="$t('login.password')">                                                  
                        <span @click="showPassword=!showPassword" :key="showPassword"><i class="fad lxa_text_color_06 lxa_eye_icon" :class="[showPassword ? 'fa-eye-slash' : 'fa-eye']"></i></span>                                              
                      </div>
                    </div>
                    <div v-if="show_confirm" class="col-6">
                      <div class="form-row px-2">
                        <div class="col-8 text-left"><label class="col-form-label"><span class="font_barlow_semi_condensed_light_14">{{$t('login.confirm_password')}}</span></label></div>
                        <div class="col-4 text-right">
                          <div class="col-form-label">
                            <span class="invalid-feedback">  
                              <span class="font_barlow_semi_condensed_10_red" v-show="errors.firstByRule('password_confirmation','required')">{{ $t('login.enter_password_confirmation') }}</span>
                              <span class="font_barlow_semi_condensed_10_red" v-show="errors.firstByRule('password_confirmation','confirmed')">{{ $t('login.password_confirmation_compares_with_password') }}</span>
                            </span>
                          </div>
                        </div>
                        <input type="password" v-validate="'required|confirmed:password'" data-vv-validate-on="none" name="password_confirmation" class="form-control line_height_17 border-0 border_radius_9 lxa_txt_input lxa_font_size_03 lxa_text_color_02" data-vv-as="password" v-model="password_confirmation" :placeholder="$t('login.confirm_password')"> 
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col-12">
                      <div class="form-row px-2">
                        <div class="col-12 text-left">
                          <div class="form-check form-check-inline terms_and_privacy font-open_sans_regular_10_strom_gray">                        
                            <div class="col-form-label">
                              <span class="invalid-feedback">  
                                <span class ="font_barlow_semi_condensed_10_red error_field" id="terms_and_privacy_error"></span>
                              </span>
                            </div>
                            <div class="col-form-label">                                                       
                            
                      <div id="terms_of_service_wrapper" class="terms_of_service_wrapper custom-controls custom-checkbox">
                          <input v-model="terms_of_service" id="terms_of_service" name="terms_of_service" class="terms_of_service custom-control-input lxa-bs-checkboxbtn" type="checkbox" value="terms of service" v-bind:true-value="1" v-bind:false-value="0">
                          <label class="string_trunk8 custom-control-label" for="terms_of_service">
                            <i class="fad fa-stop lxa_icon_size lxa_text_color_01"></i>
                            <i class="fad fa-check-square lxa_icon_size lxa_text_color_01"></i>
                            <span class="terms_of_service_link">{{$t('login.by_clicking_signup')}}</span><span>&nbsp;</span><a href="/terms-of-service" target="_blank">{{$t('login.terms_of_service')}}</a>&nbsp;{{$t('login.and')}}&nbsp;<a href="/privacy" target="_blank">{{$t('login.privacy_policy')}}</a>
                          </label>
                        </div>

                            </div>
                          </div>                    
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">          
                    <div class="col-6 offset-6 text-right">
                      <button class="btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 mr-2 font_open_sans_semibold_10_white btn-sm" data-dismiss="modal" type="button">{{$t('create_course.cancel').toUpperCase()}}</button>                      
                      <button type="submit" class="font_open_sans_semibold_10_white ml-2 btn lxa_bg_04 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 btn-sm right_float">{{$t('common.sign_up').toUpperCase()}}</button>
                    </div>
                  </div>
                </form>
              </div>            
            </div>`,
  data() {
    return {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      password_confirmation: '',
      showPassword: false,
      terms_of_service: 1,
      show_confirm: false
    }
  },
  methods: {
    onSubmit() { 
      var self = this 
      this.$validator.validateAll().then(result => {
        if (result) {
          if (document.getElementById("terms_of_service").checked) {
            var csrfToken = encodeURIComponent(jQuery('meta[name="csrf-token"]').attr('content')); 
            jQuery.ajax({
              type: 'POST',
              url: "/users"+'.json',
              data : jQuery('#form').serialize() + "user[firstname]="+this.firstname+"&user[lastname]="+this.lastname+"&user[email]="+this.email+"&user[password]="+this.password+"&user[password_confirmation]="+this.password_confirmation+"&authenticity_token="+csrfToken,
              success: function(response) {
                if(Object.keys(response).length != 0) {
                  if (Object.keys(response).includes("email")) {
                    jQuery("#email_error").html('email '+response['email']);
                  } if (Object.keys(response).includes("password_confirmation")) {
                    jQuery("#password_error").html('password confirmation '+response['password_confirmation']);
                  }
                }
                else {
                  jQuery("#signup").addClass("hide");
                  jQuery("#signup").removeClass("popover-content");
                  // jQuery("#signup_confirm_message").removeClass("hide");
                  // jQuery("#signup_confirm_message").addClass("popover-content");
                  jQuery('.tab a')[0].click()
                  self.$root.popUpMessage("Thanks for signing up! You should receive an e-mail confirmation shortly.");
                  jQuery("#login_form").removeClass('hide');
                }    
              }
            });
          }  
          else {
            jQuery("#terms_and_privacy_error").html("Please agree to terms of service")
          }
        }
       });   
      jQuery(".invalid-feedback").show();
    },
    ShowConfirm: function() {
      var self = this 
      this.$validator.validateAll().then(result => {
        if (result) {
          self.show_confirm = true
        }
        jQuery(".invalid-feedback").show();
      });   
    }
  },
  mounted() {
    jQuery("#signup_forms").css('margin-left', ((-jQuery('#signup_forms').width()) + (jQuery('#signup_link').width()/2) + 24 ) + 'px');
    var signup_checkbox = jQuery(".terms_and_privacy input[type=checkbox]");
    EventBus.$on('ResetForm', (data) => {
      jQuery.extend(this.$data, this.$options.data.apply(this));
    });
    //customCheckbox(signup_checkbox);
  }
});

Vue.component('confirmation_message_after_signup', {
  template:`<div id="signup_confirm_message" class="hide mt-3">
              <form class="form-horizontal">
                <div class="form-group margin_bottom_4">
                  <div class="col-sm-12">
                    <p class="font_open_sans_regular_12_gray">{{$t('login.signup_message')}}</p>
                  </div>
                </div>
                <hr class="margin_top_0">
                <div class="form-group margin_bottom_11">        
                  <div class="col-sm-offset-9 col-sm-3">
                    <a id="okay" class="font_open_sans_regular_12_blue" v-on:click="okay()">{{$t('common.okay')}}</a>
                  </div>
                </div>
              </form>
            </div>`,
  methods: {
    okay() {
      window.location = '/';
    }
  }
});