<template>
	<div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr">
		<span> {{ note.name }} </span>
		<span> {{ note.notes }} </span>
		<span> {{ noteTime(note) }} </span>
		<span> <a @click="goToVideo(note.time)"> Play </a></span>
	</div>
</template>

<script>
	export default {
		name: 'Note',
		props: ["note"],
		data() {
			return {
			}
		},
		methods: {
			goToVideo(note_time) {
				this.$root.$emit('goToVideo', note_time)
			},
			noteTime(note) {
				var seconds = note.time
				const format = val => `0${Math.floor(val)}`.slice(-2)
				const hours = seconds / 3600
				const minutes = (seconds % 3600) / 60

				return [hours, minutes, seconds % 60].map(format).join(':')
			}
		}
	}
</script>