<template>
	<div>
		<span> {{ comment.name }} </span><br/>
		<span> {{ comment.comment }} </span><br/>
		<span> 
			<span>{{ comment.time }}</span> |
			<span>
				<a @click="add_reply_comment = true">Reply</a>
			</span> |
			<span v-if="comment.liked">
				<span>{{ comment.likes_count}} </span>
				<a @click="unlikeComment(comment.liked_id)">Unlike</a>
			</span>
			<span v-if="!comment.liked"><a @click="likeComment(comment.id)" >Like</a></span> |
			<span><a @click="deleteComment(comment.id)">Delete</a></span>
		</span>
		<span v-if="comment.replies">
			<div v-for="reply in comment.replies" style="margin-left: 10px;border: 1px solid black;padding: 5px;">
				<comment :comment="reply"> </comment>
			</div>
		</span>
		<div v-if="add_reply_comment">
			<a @click="replyComment(comment.id)" style="color: #0000FF; font-size: 14px;"> Add Comment </a>
			<p class="new_comment"> <input type="text" v-model="reply_comment" /></p>
			<a @click="add_reply_comment = false">Cancel</a>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Comment',
		props: ["comment"],
		data() {
			return {
				add_reply_comment: false,
				reply_comment: ''
			}
		},
		methods: {
			replyComment(comment_id) {
				var data = {comment: this.reply_comment, comment_id: comment_id}
				this.$root.$emit('replyComment', data)
				this.reply_comment = ''
				this.add_reply_comment = false
			},
			unlikeComment(comment_id) {
				this.$root.$emit('unlikeComment', comment_id)
			},
			likeComment(comment_id) {
				this.$root.$emit('likeComment', comment_id)
			},
			deleteComment(comment_id) {
				this.$root.$emit('deleteComment', comment_id)
			}
		}
	}
</script>