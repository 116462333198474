<template>
	<div class="answer_container" style="display:flex;">
		<ul class="very_large">
			<li class="d-flex">
				<div v-if="!have_response" class="radio_button left_float margin_right_10">
					<div class="input_checkbox" style="margin-top: -4px;">
						<input class="float_left" :checked="item.id == response_answer_id" v-bind:name="'responses[radiobutton]['+question_id+']'" type="checkbox" v-bind:value=item.id>
					</div>
				</div>
				<div v-bind:class="[have_response && item.id == response_answer_id ? 'left_float margin_right_10 strong' : 'left_float margin_right_10']">{{ numbering[index] }}</div>
				<div v-bind:class="[have_response && item.id == response_answer_id ? 'left_float margin_right_10 choice_answer_text strong' : 'left_float margin_right_10 choice_answer_text']">{{ item.text }}</div>
		  </li>
		</ul>
	</div>
</template>
<script>
	export default {
		name: '',
		props: {
			item: Object,
			numbering: Array,
			index: Number,
			question_id : Number,
			response: Array,
			have_response: Boolean,
			navigate_response: Boolean
		},
		data: function() {
			return{
				response_answer_id: this.navigate_response || this.have_response ? this.response[0].answer_id : ''
			}
		}
	}
</script>