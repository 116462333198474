import Vue from 'vue/dist/vue.esm'
const EventBus = new Vue();
import ContentPlayer from '../components/content_player/ContentPlayer.vue'
import Select2 from 'v-select2-component';
import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';
import $ from 'jquery';
require('packs/Youtube.js');
require('packs/Vimeo.js');
import videojs from 'video.js';
import { Container, Draggable } from "vue-smooth-dnd";
import ColourPicker from 'vue-colour-picker';
import {bus} from './eventBus.js'
// import VueCal from 'vue-cal'
// import 'vue-cal/dist/vuecal.css'
import Multiselect from 'vue-multiselect'
import VueHtml2pdf from 'vue-html2pdf'
import VLazyImage from "v-lazy-image/v2"

Vue.component('content-player', {
  template: `<content-player-new :content_type="content_type" :modal="modal" :active_item="active_item" :active_content="active_content" :share_product="product" :logged_in="logged_in" v-if="active_item"> </content-player-new>`,
  components: {
    'content-player-new': ContentPlayer
  },
  props: ["active_content", "product", "logged_in","modal"],
  data() {
    return {
      active_item: {},
      content_type: ''
    }
  },
  mounted() {
    // var _this = this
    // this.$root.$on('load-content-player', function(item) {
    this.active_item = this.product
    if (this.product) {
      this.content_type = this.product.content_type
    }
    // });
  }
})

Vue.component('MyLearning-layout', {

  template:`<div id="my_learning_layout" class="mt-5 pt-1 container-fluid padding_0 scroll_margin_top_100">
              <div class="row padding_0 margin_0">
                <div class="col-12 p-0">
                  <div class="row m-0">
                    <div class="col-11 p-0">
                      <p class="font_barlow_semi_condensed_light_24 text-left">{{this.$root.header_details[1].text}}</p>
                    </div>
                    <div class="col-1 p-0 d-flex justify_flex_end">
                      <span data-toggle="tooltip" data-placement="top" title="List View" id="list_icon" v-if="carousel_icon" :key="'list_view_image'" class="cursor_pointer" v-on:click="carousel()"> 
                        <i class="fad fa-list-ul view_image  text-danger" aria-hidden="true" style="width: 20px;height: 20px;"></i> 
                      </span> 
                      <span data-toggle="tooltip" data-placement="top" title="Grid View" id="carousel_icon" v-else :key="'masonary_view_image'" class="cursor_pointer" v-on:click="list()"> 
                        <i class="fad fa-th-large view_image  text-danger" aria-hidden="true" style="width: 20px;height: 20px;"></i>  
                      </span>
                      <!-- <div id="calendar_id" class="p-0 pl-2" v-on:click="calendar_icon()"><i class="fas fa-calendar-alt" style="width: 20px;height: 20px;color: #FFA500;"></i></div> -->                    
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <question-lightbox></question-lightbox>
                <div v-if="loading" class="loader_gif">
                  <i class="fad fa-spinner fa-spin lxa_font_size_11 text-danger"></i>
                </div>
                <div id="carousel_layout" class="col-sm-12 px-0">
                  <div class="row padding_top_10 mr-0">  
                    <div class="col-5 d-flex pl-0 d-flex align-items-center justify-content-start">
                      <div :class="[{'link_active': (selected_tab=='inprogress')}, 'lxa_section_title_light pl-0 pb-2 mr-4 cursor_pointer']" v-on:click="InProgress()">
                        To-complete <span class="pl-1">({{in_progress_items_count}})</span>
                      </div>
                      <div :class="[{'link_active': (selected_tab=='completed')}, 'lxa_section_title_light pl-0 pb-2 mr-4 cursor_pointer']" v-on:click="Completed()">
                        Completed <span class="pl-1">({{completed_items_count}})</span>
                      </div>
                      <div :class="[{'link_active': (selected_tab=='all')}, 'lxa_section_title_light pl-0 pb-2 cursor_pointer']" v-on:click="All()">
                        All <span class="pl-1">({{all_items_count}})</span>
                      </div>
                    </div>
                    <div class="col-7 pl-0 lxa-set-height"> 
                      <filter_and_sort from_section="enrolled_items" :autocomplete_url="autocomplete_url" :selected="selected_tab"></filter_and_sort>
                    </div>
                  </div>
                  <div class="row">
                    <applied-filter v-bind:AppliedFilters="AppliedFilters" :fromPage="from_page"></applied-filter>
                  </div>
                  <div v-show="!loading">
                    <div v-if="calendar_view" class="row col-sm-12 p-0">
                      <div v-if="(selectedEvents.length>0) && showEventCarousel" class="event_carousel col-sm-6 p-0">
                        <div v-if="carousel_view" class="carousel slide pt-3 pb-2" data-ride="carousel" id="carousel_controls">
                          <div class="carousel-inner">
                            <div class="carousel-item active">
                              <item-tile v-if="Cancelled_items" v-for="item in selectedEvents" :item="item" :key="item.item_type+'_'+item.id" :logged_in="logged_in" ref="enrolled_item"/>
                            </div>
                          </div>
                          <a class="carousel_arrow carousel_arrow carousel-control-prev ml-0" v-if="prev_icon" v-on:click="prev_page()" role="button" data-slide="prev">
                            <div class="prev_icon_box">
                              <span class="carousel-control-prev-icon" aria-hidden="true">
                              <i class="fad fa-chevron-left"></i>
                              </span>
                              <span class="sr-only">Previous</span>
                            </div>
                          </a>
                          <a class="carousel_arrow carousel_arrow carousel-control-next" v-if="next_icon" v-on:click="next_page()" role="button" data-slide="next">
                            <div class="next_icon_box">
                              <span class="carousel-control-next-icon" aria-hidden="true">
                              <i class="fad fa-chevron-right"></i>
                              </span>
                              <span class="sr-only">Next</span>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div v-else class="event_carousel col-sm-6 p-0 none_item">You have not enrolled in any classes this month.</div>  
                    </div>
                      
                    <div v-else>
                      <div v-if="item_count > 0">
                        <div v-if="carousel_view" class="carousel slide pt-2 pb-4" data-ride="carousel" id="carousel_controls">
                          <div class="carousel-inner">
                            <div class="carousel-item active">
                              <div class="row m-0 p-0 flex-nowrap">                            
                                <item-tile v-if="Cancelled_items" v-for="(item,index) in in_progress_items.slice(start_index,end_index+1)" :class="{'lxa-firstrecord-hide':(prev_icon == true && index === 0)}" :item="item" :key="item.item_type+'_'+item.id" :logged_in="logged_in" ref="enrolled_item"/>
                              </div>
                            </div>
                          </div>
                          <a class="carousel_arrow carousel-control-prev" v-if="prev_icon" v-on:click="prev_page()" role="button" data-slide="prev">
                            <div class="prev_icon_box">
                              <span class="carousel-control-prev-icon" aria-hidden="true">
                              <i class="fad fa-chevron-left"></i>
                              </span>
                              <span class="sr-only">Previous</span>
                            </div>
                          </a>
                          <a class="carousel_arrow carousel-control-next" v-if="next_icon" v-on:click="next_page()" role="button" data-slide="next">
                            <div class="next_icon_box">
                              <span class="carousel-control-next-icon" aria-hidden="true">
                             <i class="fad fa-chevron-right"></i>
                              </span>
                              <span class="sr-only">Next</span>
                            </div>
                          </a>
                        </div>
                        <div v-if="list_view">
                          <div class="col-sm-12 px-0" id="listed_view">
                            <div class="row list_view_type">
                              <list-item-tile v-if="Cancelled_items" v-for="item in in_progress_items" :item="item" :key="item.item_type+'_'+item.id" :logged_in="logged_in" ref="enrolled_item"/>
                            </div>
                            <div class="row justify-content-end mt-2 mb-2">
                              <div v-if="show_more">
                                <div v-on:click="show_more_list()" class="showall btn lxa_bg_01 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04"> LOAD MORE </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>  
                      <div v-else class="none_item lxa_section_light">Go to <span class="catalog_link" v-on:click="GoToCatalog()">Catalog</span> to enroll for new Courses (or) View your Learning History</div>
                    </div>  
                  </div>    
                </div>
              </div>
              <tile-details-share-popup v-if="share_modal_open" v-bind:share_popup_id="share_popup_id" :item_share_link="product.item_share_link" :title="product.title" :shareable_id="product.id" :logged_in="logged_in" share_type_open="invite" from_catalog="true" />
              <all-items-lightbox v-bind:upcoming_items="upcoming_items" :completed_items="completed_items" :incompleted_items="incompleted_items" :autocomplete_url="autocomplete_url" :selected_tab="selected_tab"></all-items-lightbox>
              <all-playlist :display_playlist="display_playlist" :active_enrolled_content="active_enrolled_content"> </all-playlist>
            </div>`,

  props: {
    logged_in: Boolean,
    display_playlist: Boolean,
    active_enrolled_content: String
  },
  data: function() {
    return {
      enrolled_params: false,
      in_progress_items: [],
      start_index: 0,
      end_index: 0,
      upcoming_items: {},
      completed_items: {},
      incompleted_items: {},
      Cancelled_items: true,
      current_page: 0,
      total_pages: 0,
      next_icon: false,
      prev_icon: false,
      selectedValue: '',
      list_view: false,
      carousel_view: true,
      carousel_icon: true,
      show_more: false,
      autocomplete_url: '/my_learnings/upcoming/autocomplete_search',
      show: false,
      content_type: null,
      calendar_view: false,
      events: [],
      selectedEvents: [],
      showEventCarousel: false,
      selectedDate: '',
      active_item: null,
      selected_tab: 'inprogress',
      all_items_path: '/my_learnings/upcoming/all_items',
      AppliedFilters: [],
      in_progress_items_count: 0,
      all_items_count: 0,
      completed_items_count: 0,
      item_count: 1,
      from_page: 'Mylearing',
      all_items: false,
      sort_and_filter_params: '',
      loading: true,
      share_modal_open: false,
      product: {},
      active_items_count: this.in_progress_items_count
    }
  },
  mounted: function() {
    var params_per_page = '&per_page='+my_learning_per_page(this.list_view);
    // this.GetItems(params_per_page, this.calendar_view);
    this.$children[1].requestData();
    var self = this;
    // this.active_items_count = this.in_progress_items_count;
    EventBus.$on("share_product_details",this.open);
    bus.$on('enrollment', (data) => { 
      var params_per_page = '&per_page='+my_learning_per_page(this.list_view);
      // this.GetItems(params_per_page, this.calendar_view,true);
      self.start_index = 0
      self.$children[1].requestData();
    });
    bus.$on("CloseShare", function () {
      self.share_modal_open = false;
    })
  },
  watch: {
    start_index: function() {
      this.CarouselIcon();
    }
  },
  methods: {
    next_page: function() {
      this.start_index = this.start_index + my_learning_per_page(this.list_view)/2-1;
      this.end_index = this.end_index + my_learning_per_page(this.list_view)/2-1;
      if (this.current_page < this.total_pages) {  
        if (this.enrolled_params) {
          var page = this.current_page + 1;
          var params_per_page = this.sort_and_filter_params+'&page='+page+'&per_page='+my_learning_per_page(this.list_view);
          this.filter_items(params_per_page)
        }
        else {
          var self = this;
          var new_page = this.current_page + 1;
          var params_per_page = 'page='+new_page+'&per_page='+my_learning_per_page(this.list_view);
          this.filter_items(params_per_page)
        }
      }
    },
    prev_page: function() {
      this.start_index = this.start_index - my_learning_per_page(this.list_view)/2+1;
      this.end_index = this.end_index - my_learning_per_page(this.list_view)/2+1;
      // if (this.start_index > 0) {
      //   if (this.enrolled_params) {
      //     var page = this.current_page - 1;
      //     var per_page = my_learning_per_page();
      //     var params_per_page = this.sort_and_filter_params+'&page='+page+'&per_page='+per_page;
      //     this.filter_items(params_per_page)
      //   }
      //   else {
      //     var self = this;
      //     var new_page = this.current_page - 1;
      //     var params_per_page = 'page='+new_page+'&per_page='+my_learning_per_page();
      //     this.filter_items(params_per_page)
      //   }
      // }
    },
    carousel: function() {
      this.list_view=true;
      this.carousel_view=false;
      this.carousel_icon=false;
      var params_per_page = this.sort_and_filter_params+'&per_page='+my_learning_per_page(this.list_view);
      this.filter_items(params_per_page,true)
    },
    list: function() {
      this.list_view=false;
      this.carousel_view=true;
      this.carousel_icon=true;
      var params_per_page = this.sort_and_filter_params+'&per_page='+my_learning_per_page(this.list_view);
      this.filter_items(params_per_page,true)
    },
    Completed: function() {
      if (this.selected_tab != 'completed') {
        this.selected_tab = 'completed';
        this.active_items_count = this.completed_items_count;
        this.GetCompletedItems(this.RequestParams(), this.calendar_view,true)
        var self = this;
        setTimeout(function(){ 
          self.$children[1].FilterItems();
        }, 20);
      }  
    },
    All: function() {
      if (this.selected_tab != 'all') {
        this.GetAllItems(this.RequestParams(), this.calendar_view,false);
        this.selected_tab = 'all';
        this.active_items_count = this.all_items_count;
        var self = this;
        this.all_items = true;
        setTimeout(function(){ 
          self.$children[1].FilterItems();
        }, 20);
      }  
    },
    InProgress: function() {
      if (this.selected_tab != 'inprogress') {
        this.GetItems(this.RequestParams(), this.calendar_view,true);
        this.selected_tab = 'inprogress';
        this.active_items_count = this.in_progress_items_count;
        var self = this;
        setTimeout(function(){ 
          self.$children[1].FilterItems();
        }, 20);
      }
    },
    show_more_list: function() {
      if (this.enrolled_params) {
        var page = this.current_page + 1;
        var per_page = 4;
        if(page == this.total_pages_count) {
          this.show_more=false;
        }
        var params_per_page = this.sort_and_filter_params+'&page='+page+'&per_page='+per_page
      }
      else {
        var new_page = this.current_page + 1;
        var params_per_page = 'page='+new_page+'&per_page=4';
      }
      this.filter_items(params_per_page)
      setTimeout(function(){ jQuery(".list_view_type").niceScroll({cursorcolor:"#4184F3",cursorwidth:"3px",autohidemode: "scroll"}); }, 20);
    },
    filter_items: function(params_per_page,tab_switch = false) {
      switch(this.selected_tab) {
        case 'inprogress':
          this.GetItems(params_per_page,this.calendar_view,tab_switch);
          break;
        case 'completed':
          this.GetCompletedItems(params_per_page, this.calendar_view,tab_switch);
          break;
        case 'all':
          this.GetAllItems(params_per_page, this.calendar_view,tab_switch);
          break;
      }
    },
    calendar_icon: function() {
      this.selectedDate = ''
      this.calendar_view = !this.calendar_view
      var params_per_page = '&per_page='+my_learning_per_page(this.list_view)+'&calendar_view='+this.calendar_view+'&selected_date='+this.selectedDate;
      this.filter_items(params_per_page); 
    },
    cellEvents: function(cell, e) {
      this.selectedDate = moment(e).format('YYYY-MM-DD');
      var params_per_page = '&per_page='+my_learning_per_page(this.list_view)+'&calendar_view='+this.calendar_view+'&selected_date='+this.selectedDate;
      this.filter_items(params_per_page);
    },
    GetItems: function(params_per_page, calendar,tab_switch) {
      var url = '/my_learnings/upcoming.json?'+params_per_page
      this.FetchData(url,calendar,tab_switch);
    },
    GetCompletedItems: function(params_per_page, calendar,tab_switch) {
      var url = '/my_learnings/completed_items.json?'+params_per_page
      this.FetchData(url,calendar,tab_switch);
    },
    GetAllItems: function(params_per_page, calendar,tab_switch) {
      this.loading = true;
      this.$http.get(this.all_items_path+'.json?'+params_per_page).then(response => {
        this.upcoming_items = response.body.in_progress_items;
        this.showEventCarousel = calendar ? true : false;
        this.completed_items = response.body.completed_items;
        this.incompleted_items = response.body.incompleted_items;
        $('#all_items_lightbox').modal('show');
        this.loading = false;
        if (this.list_view) {
          setTimeout(function(){ jQuery(".list_view_type").niceScroll({cursorcolor:"#4184F3",cursorwidth:"3px",autohidemode: "scroll"}); }, 20);
        } 
      });
      // var url = this.all_items_path+'.json?'
      // this.FetchData(url,calendar,true);
    },
    remove_filter: function(ref,display_id,display_name,filter_type,arr_index) {
      if (filter_type=='search_item') {
        this.$children[1].$children[0][filter_type] = ''
      } else {
        var index = this.$children[1].$children[1].$refs[ref][filter_type].indexOf(display_id)
        if (index !== -1) {
          this.$children[1].$children[1].$refs[ref][filter_type].splice(index, 1);
        }
      }
      this.AppliedFilters.splice(arr_index,1);
      this.$children[1].requestData();
    },
    GoToCatalog: function() {
      document.getElementById("catalog_layout").scrollIntoView();
    },
    open: function(product, share_popup_id, share_modal_open) {
      this.product = product;
      this.share_popup_id = share_popup_id;
      this.share_modal_open = share_modal_open;
    },
    CarouselIcon: function() {
      if (this.start_index <= 0) {
        this.prev_icon = false;
      } else {
        this.prev_icon = true;
      }
      if (this.end_index >= this.active_items_count-1 || this.end_index == 0) {
        this.next_icon = false;   
      } else {
        this.next_icon = true;
      }
    },
    FetchData: function(url,calendar,tab_switch) {
      // this.loading = true;
      this.$http.get(url).then(response => {
        if ((this.list_view && response.body.current_page > 1) || !tab_switch) {
          this.in_progress_items = this.in_progress_items.concat(response.body.in_progress_items);
          this.item_count = response.body.in_progress_items.length;
        } else {
          this.start_index = 0;
          this.end_index = 3;
          this.in_progress_items = response.body.in_progress_items;
          this.item_count = response.body.in_progress_items.length;
          this.CarouselIcon();
        }
        this.events = response.body.events_count;
        this.selectedEvents = response.body.in_progress_events;        
        this.current_page = response.body.current_page;
        this.total_pages = response.body.total_pages;
        this.showEventCarousel = calendar ? true : false
        this.loading = false;
        if (this.list_view) {
          if (this.current_page < this.total_pages) {
            this.show_more = true
          } else {
            this.show_more = false
          }
          setTimeout(function(){ jQuery(".list_view_type").niceScroll({cursorcolor:"#4184F3",cursorwidth:"3px",autohidemode: "scroll"}); }, 20);
        } 
      });
    },
    RequestParams: function (){
      if (this.enrolled_params) {
        var params_per_page = this.calendar_view ? '&per_page='+my_learning_per_page(this.list_view)+'&calendar_view='+this.calendar_view+'&selected_date='+this.selectedDate : this.sort_and_filter_params+'&per_page='+my_learning_per_page(this.list_view);
      } else {
        var params_per_page = this.calendar_view ? '&per_page='+my_learning_per_page(this.list_view)+'&calendar_view='+this.calendar_view+'&selected_date='+this.selectedDate : '&per_page='+my_learning_per_page(this.list_view);
      }
      return params_per_page
    }
  }
});

Vue.directive('click-outside', {
  bind: function(el, binding, vNode) {
    // Provided expression must evaluate to a function.
    if (typeof binding.value !== 'function') {
      const compName = vNode.context.name
      var warn = "[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be"
      if (compName) { warn += "Found in component '${compName}'" }
      
      console.warn(warn)
    }
    // Define Handler and cache it on the element
    const bubble = binding.modifiers.bubble
    const handler = (e) => {
      if (bubble || (!el.contains(e.target) && el !== e.target)) {
        binding.value(e)
      }
    }
    el.__vueClickOutside__ = handler

    // add Event Listeners
    document.addEventListener('click', handler)
  },  
  unbind: function(el, binding) {
    // Remove Event Listeners
    document.removeEventListener('click', el.__vueClickOutside__)
    el.__vueClickOutside__ = null
  }
})

Vue.component('Announcement-layout', {
  template:`<div class="row mx-0"><div class="col-sm-12 px-0 mt-5 pt-1">
            <div class="row mx-0">
            <div class="col-12 px-0">
              <div class="d-flex align-items-center justify-content-start mb-3">
              <span class="announcements_title"> Announcements </span>
              <span v-on:click="ShowAll" data-toggle="modal" data-target="#all_announcements_modal" class="cursor_pointer announcements_showall ml-auto"> Show All </span>
              </div>
              </div>
              </div>
              <div class="row mx-0 lxa_aside_announcement_wrapper">
              
              <div v-if="announcements.length>0" v-infinite-scroll="loadMoreAnnouncements" infinite-scroll-disabled="busy" infinite-scroll-distance="10" infinite-scroll-immediate-check="false" id="announcement_display" class="col-12 lxa_announcement_panel">
                <div>
                  <announcement v-for="(item,index) in announcements" v-bind:item="item" :index="index" :new_announcement_link="''" :page_no="page" :length="announcements.length"></announcement>
                </div>
              </div> 
          
              </div>
            </div></div>`,
  data: function() {
    return {
      audience_id: 1,
      audience_type: "",
      create_announcement_url:"",
      selected_items: "",
      current_view: 'announcement_display',
      total_pages: 1,
      page: 1,
      available_items: "",
      announcements: [],
      show_all_announcements: false,
      show_new_form: false,
      update_announcement_url: '',
      create: true,
      edit_product: false,
      show_announcement: false,
     }
   },
  mounted: function(){
    this.$http.get(this.$root.announcement_url+'.json?page=1').then(response => {
      this.announcements = response.body.announcements;
      this.total_pages = response.body.total_pages;
      setTimeout(function(){ 
        jQuery("#announcement_display").slimScroll({
          barClass:'lxascroll',
          size : '3px',
          height: '414px',
          width: '100%',
          // height : '100%',
          position : 'right',
          start : 'top',
          alwaysVisible : false,
          distance: '0',
          borderRadius: '0',
          // railVisible: true,
          railBorderRadius:0,
          opacity:1,
          allowPageScroll: true,
          touchScrollStep:200,
          enableKeyNavigation: true
              });
        // jQuery("#announcement_display").niceScroll({fixed:true,cursorcolor:"#4184F3",cursorwidth:"3px",autohidemode: "scroll", nativeparentscrolling: false});
      }, 10);      
    });
  },
  methods: {
    loadMoreAnnouncements: function() {
      var self = this;
      self.busy = true;
      if(self.page < self.total_pages){
        self.page = self.page + 1;
        jQuery("#"+self.current_view).find('.loading').removeClass('hidden');
        jQuery("#"+self.current_view).find('.circular_loader').removeClass('hidden').addClass('announcement_loader');        
        this.$http.get(this.$root.announcement_url+'.json'+'?page='+self.page).then(response => {
          if(response.body.announcements.length !=0) {
            jQuery("#"+self.current_view).find('.loading').addClass('hidden');
            jQuery("#"+self.current_view).find('.circular_loader').addClass('hidden');            
            self.announcements = self.announcements.concat(response.body.announcements);
            self.busy = false;
            // jQuery("#announcement_display").getNiceScroll().resize()
          }
        });
      }    
    },
    ShowAll: function() {
      this.$parent.$parent.show_all_announcements = true;
    }
   }
 });

Vue.component('Event-layout', {
  template:`<div v-if="!from_profile" class="col-sm-12 mt-5 pt-1 pl-0 pr-0">
              <div class="font_barlow_semi_condensed_light_24">
                <span> Events </span>
                <span id="CreateEventIcon" class="cursor_pointer float_right" v-on:click="CreateEvent"> <img src="/images/add.png"> </span>
              </div>
              <div id="UpcomingPastEvents" class="items_content_list_box mt-3">
                <div class="events_switching">
                  <span id="upcoming_link" class="cursor_pointer" v-on:click="UpcomingEvent"> Upcoming </span>
                  <span> &nbsp &nbsp | &nbsp &nbsp </span>
                  <span id="past_link" class="cursor_pointer" v-on:click="PastEvent"> Past </span>
                </div>
                <transition>
                  <div id="upcoming_event_container" class="events_scroll_bar" v-show="upcoming" track-by='id'>
                    <div v-if="upcoming_events.length>0">
                      <mylearning_events_list v-for="(upcoming_event, index) in upcoming_events" v-bind:data="upcoming_event" v-bind:key="upcoming_event.text" :item="upcoming_event" :index="index" :length="upcoming_events.length"></mylearning_events_list>
                      <br/>
                    </div>
                    <div v-else class="events_none">{{$t('common.none')}}</div>
                  </div>
                </transition>
                <transition>
                  <div id="past_event_container" class="events_scroll_bar" v-show="past" track-by='id'>
                    <div v-if="past_events.length>0">
                      <mylearning_events_list v-for="(past_event, index) in past_events" v-bind:data="past_event" v-bind:key="past_event.text" :item="past_event" :index="index" :length="past_events.length"></mylearning_events_list>
                      <br/>
                    </div>
                    <div v-else class="events_none">{{$t('common.none')}}</div>
                  </div>
                </transition><br/>
              </div>
              <div id="CreateEvents" class="create_event_container">
                <div class="create_event_inner_container">
                  <div class="create_event_data">
                    <form>
                      <span>Name</span><span class="events_required"> * </span><span>:</span><br/>
                      <input type="text" id="EventName" v-model="event_name" class="event_name_text_box">
                      <div class="margin_top_11">
                        <span>Description:</span><br/>
                        <tinymce v-model="event_description"></tinymce>
                      </div>
                      <div class="margin_top_11">
                        <span>Start time</span><span class="events_required"> * </span><span>:</span><br/>
                        <input id="EventStartDate" v-model="event_start_date" class="event_start_end_date">
                        <input id="EventStartTime"v-model="event_start_time" class="event_start_end_time">
                      </div>
                      <div class="margin_top_11">
                        <span>End time</span><span class="events_required"> * </span><span>:</span><br/>
                        <input id="EventEndDate" class="event_start_end_date">
                        <input id="EventEndTime" class="event_start_end_time">
                      </div>
                      <div class="margin_top_11">
                        <span>Location:</span><br/>
                        <input type="text" id="EventLocation" v-model="event_location" class="event_location_text_box">
                      </div>
                    </form>
                  </div>
                </div>
                <div style="margin-top: 7px; margin-left: 286px">
                  <span class="create_event_cancel" v-on:click="CancelNewEvent">Cancel</span>
                  <button id="SaveEvent" class="create_event_save" v-on:click="SubmitNewEvent">Save</button>
                </div>
              </div><br/>
            </div>
            <div v-else> 
              <div class="col-sm-12 padding_0">
                <div class="font_barlow_semi_condensed_light_24">
                  <h4> Events </h4>
                  <span id="EditEventIcon" class="cursor_pointer float_right font_barlow_semi_condensed_14 text_color_orange" v-on:click="EditEvent()"> Edit </span>
                </div>
                <div id="ProfileUpcomingPastEvents" class="items_content_list_box">
                  <div class="events_switching">
                    <span id="upcoming_link" class="cursor_pointer" v-on:click="UpcomingEvent"> Upcoming </span>
                    <span> &nbsp &nbsp | &nbsp &nbsp </span>
                    <span id="past_link" class="cursor_pointer" v-on:click="PastEvent"> Past </span>
                  </div>
                  <transition>
                    <div id="profile_upcoming_event_container" class="events_scroll_bar" v-show="upcoming" track-by='id'>
                      <div v-if="upcoming_events.length>0">
                        <mylearning_events_list v-for="(upcoming_event, index) in upcoming_events" v-bind:data="upcoming_event" v-bind:key="upcoming_event.text" :item="upcoming_event" :index="index" :length="upcoming_events.length"></mylearning_events_list>
                        <br/>
                      </div>
                      <div v-else class="events_none">{{$t('common.none')}}</div>
                    </div>
                  </transition>
                  <transition>
                    <div id="profile_past_event_containers" class="events_scroll_bar" v-show="past" track-by='id'>
                      <div v-if="past_events.length>0">
                        <mylearning_events_list v-for="(past_event, index) in past_events" v-bind:data="past_event" v-bind:key="past_event.text" :item="past_event" :index="index" :length="past_events.length"></mylearning_events_list>
                        <br/>
                      </div>
                      <div v-else class="events_none">{{$t('common.none')}}</div>
                    </div>
                  </transition><br/>
                </div>              
              </div>
              <event-edit-section v-if="show_edit_event" :selected_events="selected_events" :created_events="created_events" :save_event_selections_url="save_event_selections_url"/>
            </div>`,
  data: function() {
    return {
      upcoming_events: [],
      past_events: [],
      upcoming: false,
      past: false,
      event_name: '',
      event_description: '',
      event_start_date: Date.now().toString("dd-MM-yyyy"),
      event_start_time: Date.now().toString("HH:mm"),
      event_end_date: '',
      event_end_time: '',
      event_location: '',
      active: false,
      busy: false,
      show_edit_event: false,
      selected_events: [],
      created_events: []
    }
  },
  props: {
    from_profile: Boolean,
    user_event_url: String,
    select_users_event_url: String,
    save_event_selections_url: String
  }, 
  mounted: function() {
    if (!this.from_profile) {
      jQuery("#CreateEvents").hide();
      DateTimePicker('EventStartDate','d-m-Y',true,false);
      DateTimePicker('EventEndDate','d-m-Y',true,false);
      DateTimePicker('EventStartTime','H:i',false,true);
      DateTimePicker('EventEndTime','H:i',false,true);
      this.event_start_date = jQuery('#EventStartDate').val();
      this.event_start_time = jQuery('#EventStartTime').val();
      this.event_end_date = jQuery('#EventEndDate').val();
      this.event_end_time = jQuery('#EventEndTime').val();
      var event_comp = this;
      this.$parent.loading = true;
      this.$http.get('/my_learnings/events.json?all=true').then(response => {
        this.EventsResponse(false, response.body.events_list, false, false, true, response.body.total_pages);
      });
    }
    else {
      jQuery("#CreateEvents").hide();
      this.$http.get(this.user_event_url+'&all=true').then(response => {
        this.EventsResponse(false, response.body.events_list, false, false, true, response.body.total_pages);
      });
    }  
  },
  methods: {
    UpcomingEvent: function() {
      jQuery("#CreateEvents").hide();
      var event_comp = this;
      this.$parent.loading = true;
      var url = !this.from_profile ? '/my_learnings/events.json?all=true' : this.user_event_url+'&all=true';
      this.$http.get(url).then(response => {
        this.EventsResponse(false, response.body.events_list, false, false, true, response.body.total_pages);
      }); 
    },
    PastEvent: function() {
      jQuery("#CreateEvents").hide();
      var event_comp = this;
      this.$parent.loading = true;
      var url = !this.from_profile ? '/my_learnings/events.json?past=true' : this.user_event_url+'&past=true';
      this.$http.get(url).then(response => {
        this.EventsResponse(false, false, response.body.events_list, true, false, response.body.total_pages);
      });
    },
    CreateEvent: function() {
      jQuery("#UpcomingPastEvents").hide();
      jQuery("#CreateEventIcon").hide();
      this.event_name = '';
      this.event_description = '';
      this.event_end_date = '';
      this.event_end_time = '';
      this.event_location = '';
      $('#EventName').css('border-color', '#FFFFFF');
      $('#EventStartDate').css('border-color', '#FFFFFF');
      $('#EventStartTime').css('border-color', '#FFFFFF');
      $('#EventEndDate').css('border-color', '#FFFFFF');
      $('#EventEndTime').css('border-color', '#FFFFFF');
      jQuery("#CreateEvents").show();
    },
    CancelNewEvent: function() {
      jQuery("#CreateEvents").hide();
      jQuery("#CreateEventIcon").show();
      jQuery("#UpcomingPastEvents").show();
    },
    SubmitNewEvent: function() {
      this.event_start_date = jQuery('#EventStartDate').val();
      this.event_start_time = jQuery('#EventStartTime').val();
      this.event_end_date = jQuery('#EventEndDate').val();
      this.event_end_time = jQuery('#EventEndTime').val();
      var event = this;
      if ($('#EventName').val() == '') {
        $('#EventName').css('border-color', 'rgb(239, 3, 38)');
      }
      if ($('#EventStartDate').val() == '') {
        $('#EventStartDate').css('border-color', 'rgb(239, 3, 38)');
      }
      if ($('#EventStartTime').val() == '') {
        $('#EventStartTime').css('border-color', 'rgb(239, 3, 38)');
      }
      if ($('#EventEndDate').val() == '') {
        $('#EventEndDate').css('border-color', 'rgb(239, 3, 38)');
      }
      if ($('#EventEndTime').val() == '') {
        $('#EventEndTime').css('border-color', 'rgb(239, 3, 38)');
      }
      if (($('#EventName').val() != '') && ($('#EventStartDate').val() != '') && ($('#EventStartTime').val() != '') && ($('#EventEndDate').val() != '') && ($('#EventEndTime').val() != '')) {
        var event_comp = this;
        this.$parent.loading = true;
        this.$http.post('/my_learnings/events.json?'+'&event='+ this.event_name+'&event_description='+ this.event_description+'&event_start_date='+ this.event_start_date+'&event_start_time='+ this.event_start_time+'&event_end_date='+ this.event_end_date+'&event_end_time='+ this.event_end_time+'&event_location='+ this.event_location).then(response => {
          this.EventsResponse(false, response.body.events_list, false, true, response.body.total_pages);
          this.UpcomingEvent();
          jQuery("#UpcomingPastEvents").show();
          jQuery("#upcoming_link").show();
          jQuery("#CreateEventIcon").show();
          jQuery("#CreateEvents").hide();
        });
      }
    },
    EditEvent: function() {
      this.$http.get(this.select_users_event_url).then(function(response) {
        this.selected_events = response.body.selected_events;
        this.created_events = response.body.created_events;
        this.show_edit_event = true;          
      });      
    },
    EventsResponse: function(parent_loading, upcoming_events, past_events, past, upcoming, total_pages) {
      this.$parent.loading = parent_loading;
      this.upcoming_events = upcoming_events;
      this.past_events = past_events;
      this.past = past;
      this.upcoming = upcoming;
      this.total_pages = total_pages;
      setTimeout(function(){ jQuery("#upcoming_event_container").niceScroll({cursorcolor:"#4184F3",cursorwidth:"3px",autohidemode: "scroll"}); }, 20);
      setTimeout(function(){ jQuery("#past_event_container").niceScroll({cursorcolor:"#4184F3",cursorwidth:"3px",autohidemode: "scroll"}); }, 20);
    }
  }
});

Vue.component('tinymce', {
  props: ['value'],
  template: `<div><textarea rows="10" id="EventDescription" v-bind:value="value" class="event_description_text_box"></textarea></div>`,
  methods: {
    updateValue: function (value) {
      this.$emit('input', value.trim());
    }
  },
    mounted: function(){
      var component = this;
      tinymce.init({
        target: this.$el.children[0],
        setup: function (editor) {
          editor.on('Change', function (e) {
            component.updateValue(editor.getContent());
          })
        }
      });
    }
});

Vue.component('banner-layout', {
  template:`<div>
              <div class="banner_container">
                <span v-if="logged_in && site_admin" class="banner_change_edit" data-toggle="modal" data-target="#banner_uploads_modal"> 
                  <i class="fad fa-pencil mt-2 ml-2" data-toggle="tooltip" data-placement="top" title="Edit"></i>
                </span>              
                <div id="carouselImageVideo" class="carousel slide height_400px" data-ride="carousel">
                  <ol class="carousel-indicators" v-show="banner_uploads.length > 1">
                    <li v-for="(banner, index) in banner_uploads" data-target="#carouselImageVideo" :data-slide-to="index" :class="{'active': index == 0}" />
                  </ol>
                  <div class="carousel-inner h-100">
                    <div  v-for="(banner, index) in banner_uploads" :class='[{"active": index == 0},"carousel-item", "h-100"]' :id="'banner_id_'+banner.id" :key="index" data-interval="5000">
                      <span v-if="banner.type == 'image'" @click="redirectToUrl(banner)">  
                        <v-lazy-image v-bind:src="banner.image_url" :class="[{'banner_image': banner.redirection_url != ''},'w-100 h-100']" alt="banner"></v-lazy-image>
                      </span>
                      <video v-else-if="banner.video_url.mp4" class="w-100 h-100 video-js" :autoplay="banner.autoplay" muted :id="'bannerVideoPlayer_'+banner.id" :controls="!banner.autoplay">
                        <source :src="banner.video_url.mp4" >
                      </video>
                      <iframe :src="banner.video_url.url" v-else="" class="w-100 h-100 no_border" :id="'bannerVideoPlayer_'+banner.id" />
                      <div class="banner_content" v-if="banner.title" v-bind:style="{ backgroundColor: banner.title_bar_color }"> 
                        <span v-html="banner.custom_title"></span>
                      </div>
                      <div :class="[{'green-color': !video_muted},'muteVideo']" @click="muteVideo(banner)" v-if="banner.type == 'video'">
                        <span data-toggle="tooltip" data-placement="top" title="Unmute" v-show="video_muted" :id="'unmute_video_'+banner.id"> <i class="fad fa-volume-mute fa-lg"></i></span>
                        <span data-toggle="tooltip" data-placement="top" title="Mute" v-show="!video_muted" :id="'mute_video_'+banner.id"> <i class="fas fa-volume-up fa-lg"></i></span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>`,
  components: {
    VLazyImage
  },
  props: {
    logged_in: Boolean,
    site_admin: Boolean
  },
  data: function() {
    return {
      banner_uploads: [],
      video_muted: true
    }
  },
  methods: {
    muteVideo(banner) {
      var videoID = "bannerVideoPlayer_" + banner.id
      if (banner.video_url.url) {
        // this.loadIframeVideo(videoID, banner)
      } else {
        var video=document.getElementById(videoID);
        video.muted = !video.muted
      }
      this.video_muted = !this.video_muted
    },
    loadIframeVideo(iFrameID, banner) {
      if (banner.video_url.url.includes('youtube')) {
        setTimeout(function() {
          onYouTubePlayerAPIReady(iFrameID, banner.id)
        },1000);
      } else {
        setTimeout(function() {
          onVimeoPlayerAPIReady(iFrameID, banner.id)
        },1000);
      }
    },
    redirectToUrl(banner) {
      if (banner.redirection_url != '') {
        window.open(banner.redirection_url, "_blank");
      }
    },
    loadVideo(banner) {
      setTimeout(function() {
        var video=document.getElementById("bannerVideoPlayer_" + banner.id);
        $('#banner_id_'+banner.id).attr('data-interval', video.duration*1000)
      }, 2000);
    },
    loadBanners() {
      this.$http.get('/get_active_banners?new_single_page=true').then(function(response) {
        this.banner_uploads = response.body['banner_uploads']
        for (let i = 0; i < this.banner_uploads.length; i++) {
          var banner = this.banner_uploads[i]
          var videoID = "bannerVideoPlayer_" + banner.id
          if ( banner.type == 'video' ) {
            if (banner.video_url.url) {
              this.loadIframeVideo(videoID, banner)
            } else if (banner.video_url.mp4) {
              this.loadVideo(banner)
            }
          }
        }
      });
    }
  },
  mounted: function() {
    loadIframeAPI()
    $("#carouselImageVideo").carousel();
    this.loadBanners()
    var _this = this
    this.$root.$on('updateBannerUploads', function() {
      _this.loadBanners()
    })
  }
});

Vue.component('header-layout', {
  template:`<div class="container-fluid ml-0 pl-0">
            <div v-if="header_img" class="row mb-3 pl-3">
              <div class="col-sm-12">
                <img src="/images/new_ui/Awning.svg" style="width: 100%">
              </div>
            </div>
            <div id="navbar" v-if="logged_in" class="pl-3 row header_section margin_bottom_10 background_color_gray">
              <div class="col-2 h-100 d-flex align-items-center justify-content-start pr-0">
                <div class="w-100 cursor_pointer" v-on:click="redirectHome">  
                  <v-lazy-image v-bind:src="logo_url" class="img-fluid lxalogo company_logo" alt="logo"></v-lazy-image>
                </div>
              </div>
              <div class="col-6 d-flex align-items-center justify-content-start pl-3">
                <div v-if="site_admin" key="fa-archive">
                <span v-on:click="store_design_view()">
                  <i class="fa fa-archive lxa_icon_size lxa_text_color_01 cursor_pointer mr-2"></i>
                  <span class="lxa_section_title_light cursor_pointer mr-3">{{ getLabel(0) }}</span>
                </span>
                </div>
                <div>
                <span v-on:click="my_learning_view()">
                  <i key="fa-books" class="fad fa-books lxa_icon_size lxa_text_color_06 cursor_pointer mr-2"></i>
                  <span id="my_learning_link" class="lxa_section_title_light cursor_pointer mr-3">{{ getLabel(1) }}</span>
                </span>
                </div>
                <div>
                <span @click="scrollIntoView('quick_access_container')">
                  <i key="fa-rabbit" class="fad fa-rabbit-fast lxa_icon_size lxa_text_color_01 cursor_pointer mr-2"></i>
                  <span class="lxa_section_title_light cursor_pointer mr-3"> {{ getLabel(2) }}</span>
                </span>
                </div>
                <div>
                 <span @click="scrollIntoView('catalog_layout')">
                  <i key="fa-suitcase" class="fad fa-suitcase lxa_icon_size lxa_text_color_06 cursor_pointer mr-2"></i>
                  <span id="catalog_link" class="lxa_section_title_light cursor_pointer mr-3"> {{ getLabel(3) }}</span>
                 </span>
                </div>
                <div>
                <span @click="scrollIntoView('user_profile_view')">  
                  <i key="fa-user" class="fad fa-user lxa_icon_size lxa_text_color_05 cursor_pointer mr-2"></i>
                  <span class="lxa_section_title_light cursor_pointer mr-3">{{ getLabel(4) }}</span>
                </span>
                </div>
                <!--<div>
                <span v-on:click="showAchievement()">
                  <i key="fa-trophy" class="fa fa-trophy lxa_icon_size achievements_menu cursor_pointer mr-2"></i>
                  <span :class='[{"active_tab": show_achievement},"lxa_section_title_light mr-3 cursor_pointer"]' v-click-outside="achievementOutside">Achievements</span>                           
               </span>
                </div>-->
              </div>
              <achievement-section :show_achievement="show_achievement" :selected_achievements="selected_achievements"/>              
              <div class="col-4 d-flex align-items-center justify-content-end position-relative">
              <div v-if="show_announcement" class="">
                <div class="announcement_popup lxa_bg_05 lxa_border_radius p-3">
                  <announcement-header-popup ref="announcementHeader"></announcement-header-popup>
                </div>  
              </div> 
              <div class="d-flex align-items-center justify-content-start pr-3">                
                <span id="header_announcement" v-on:click="showAnnouncement()" class="hover mr-3" v-click-outside="announcementOutside"><i data-toggle="tooltip" data-placement="top" title="Announcement"  class="fad fa-bell announcementicon lxa_font_size_09"></i> 
                <span v-if="updated_notification_count!=0" id="badge_announcement" class="badge badge_icon">{{updated_notification_count}}</span></span>
                <span data-toggle="tooltip" data-placement="top" title="Cart" @click.prevent="showCart" class="hover mr-3"><i class="fad fa-shopping-cart lxa_text_color_01 lxa_font_size_09"></i>
                <span v-show="update_cart_count!=0 ? true : false" id="badge_cart" class="badge badge_icon">{{update_cart_count}}</span></span>
                <!-- <span class="hover"> <i class="fad fa-inbox " aria-hidden="true" style="color: #FF0000"></i> </span> -->
                <!-- <span data-toggle="modal" data-target="#user_account_modal" class="user_icon_link hover mr-3" v-click-outside="outside"><i data-toggle="tooltip" data-placement="top" title="Settings" class="fad fa-user-cog lxa_text_color_06 lxa_font_size_09"></i></span> -->
              </div>
              <div class="d-flex justify-content-end">
              <div class="_col-1 font_barlow_semi_condensed_medium_18 pr-3 d-flex flex-column align-items-end justify-content-center">
                <span class="profusrname">{{ item.firstname }} {{ item.lastname }}</span>
                <span data-toggle="tooltip" data-placement="top" title="SignOut" class="cursor_pointer mt-3" @click="SignOut()"> <i class="fad fa-power-off lxa_icon_size lxa_text_color_01"></i> </span>
              </div>
              <div class="_col-1 ml-auto">
                <span  data-toggle="modal" data-target="#user_profile_modal" data-backdrop="static" data-keyboard="false"> 
                  <img v-bind:src="profile_image_url" class="lxa_border_radius cursor_pointer lxaprofile_pic" v-if="profile_image_url" />
                  <div id="default_profile" class="margin_top_10" v-else>
                    <i class="fad fa-user-alt w-100 default_profile_pic lxa_text_color_06" viewBox="100 0 512 512"></i>
                  </div>
                </span>
              </div>
              </div> 
              </div>
                                                   
              <div v-if="textChangeProfile" id="ProfileImageText" style="margin-left: 18px; margin-top: -30px; cursor: pointer" @mouseover="openProfileModel" @mouseleave="closeProfileModel">
                <div class="profile_text_align">Change Profile</div>
                <div class="upload_file_type">
                  <button class="profile_upload_button" v-on:click="uploadProfileImage()"></button>
                  <input type='file' ref="inputFile" accept="image/*" id="getProfileImage" @change="uploadedFile" style="display: none"/>
                </div>
              </div>
            </div>
            <div v-else id="navbar" class="pl-3 row header_section background_color_gray">
              <div class="col-2 h-100 d-flex align-items-center justify-content-start pr-0">
                <div class="w-100">  
                  <v-lazy-image v-bind:src="logo_url" class="img-fluid lxalogo company_logo" alt="logo"></v-lazy-image>
                </div>
              </div>
              <div class="col-7 d-flex align-items-center justify-content-start pl-3">
                <i class="fa fa-archive lxa_icon_size lxa_text_color_01 mr-2"></i>
                <span class="lxa_section_title_light cursor_pointer" v-on:click="scrollIntoView('catalog_layout')">Catalog</span>
              </div>
              <div class="col-3 lxa_section_title_light text_align_right d-flex align-items-center justify-content-end">
                <a v-if="super_site && !logged_in" href="/companies/new" class="cursor_pointer lxa_txtlink mr-2" id="user_subscribe">Subscribe
                  <i class="fad fa-sign-in-alt lxa_text_color_05 lxa_icon_size"></i>
                  &nbsp;&nbsp;
                </a>
                <span data-toggle="modal" data-target="#login_modal" class="cursor_pointer mr-2" id="user_sign_in">Sign in</span>
                <i class="fad fa-sign-in-alt lxa_text_color_05 lxa_icon_size"></i>
              </div>
            </div>
            <div v-if="show_all_announcements">
              <all-announcement-component></all-announcement-component>
            </div>
            <div v-if="show_new_form">
              <div class="modal show" id="new_announcement" role="dialog" aria-labelledby="new_announcement_label" aria-hidden="true" tabindex="-1" data-backdrop="static" data-keyboard="false">
                <div class="modal-dialog modal-xl" role="document">
                  <div class="modal-content modal_outer_ui">
                    <p class="light_box_close lxa_text_color_08 lxa_font_size_06 cursor_pointer" v-on:click="closeNewAnnouncementModel()"> <i class="fad fa-times lxa_text_color_08 lxa_font_size_06 cursor_pointer"></i></p>
                    <div class="modal-body p-0">              
                      <new-announcement-form :update_announcement_url="update_announcement_url" :create="create" :edit_product="edit_product" :available_items="available_items" :selected_items="selected_items" :show_new_form="show_new_form" :create_announcement_url="create_announcement_url" :audience_id="audience_id" :audience_type="audience_type" :announcement_index="announcement_index"> </new-announcement-form>
                    </div>
                  </div>
                </div>
              </div> 
            </div>  
            <span id="fetch_header_data" @click="getHeaderContent()" class="display_none"> Fetch Data</span>
          </div>`,
  data: function() {
    return {
      profile_image_url: '',
      inputPicture: null,
      textChangeProfile: false,
      selected_achievements: [],
      show_achievement: false,
      show_announcement: false,
      show_groups: false,
      show_create_group: false,
      params: '',
      empty_list: '',
      show_header_option: false,
      show_all_announcements: false,
      show_new_form: false,
      update_announcement_url: '',
      create: true,
      edit_product: false,
      available_items: [],
      selected_items: [],
      create_announcement_url: '',
      audience_id: '',
      audience_type: '',
      logo_url: '',
      label_content: [],
      item: {},
      header_img: false,
      announcement_index: 0
    }
  },
  components: {
    VLazyImage
  },
  props: {
    total_announcement_count: Number,
    logged_in: Boolean,
    site_admin: Boolean,
    super_site: Boolean,
    home_url: String
  },
  computed: {
    updated_notification_count: function() {
      return this.$root.total_announcement_count
    },
    update_cart_count: function () {
      return this.$root.total_cart_count
    }
  },  
  mounted: function(){
    this.$http.post('/upload_profile').then(response => {
      this.profile_image_url = response.data.image_url;
    });
    if (this.logged_in) {
      this.$http.get('/account_settings.json').then(response => {
        this.item = response.body;
        this.name = this.item.firstname;
        this.email = this.item.email;
        this.last_name = this.item.lastname;
        this.email_url = this.item.change_email_url;
        this.name_url = this.item.change_name_url;
        this.password_url = this.item.change_password_url;
      });
    }
    this.getHeaderContent(  )
  },
  methods: {
    getHeaderContent() {
      this.$http.get('/get_header_details').then(response => {
        this.label_content = response.body.header_details;
        this.$root.HeaderDetails(response.body.header_details)
        this.logo_url = response.body.logo_url
        this.header_img = response.body.header_img
      });
    },
    SignOut: function (event) {
      var csrfToken = encodeURIComponent(jQuery('meta[name="csrf-token"]').attr('content'));
      jQuery.ajax({
        type: 'DELETE',
        url: "/users/sign_out?"+"&single_page=1&authenticity_token="+csrfToken,
        success: function(response) {}
      });
    },
    getLabel(index) {
      var text = ''
      if (this.label_content.length != 0) {
        text = this.label_content[index].text
        if (text == "") {
          text = this.label_content[index].default_text
        }
      }
      return text
    },
    scrollIntoView(id) {
      if (jQuery('#'+id).length > 0) {
        const element = document.getElementById(id);
        if(!$("#navbar").hasClass("sticky_header")){
          var navbar = document.getElementById("navbar");
          navbar.classList.add("sticky_header");
        } 
        element.scrollIntoView();
      } else {
        this.$root.payment_status = false;
        this.$root.my_learning = true;
        this.$root.show_course_details = false;
        this.$root.show_blog_items = false;
        this.$root.show_group = false;
        this.$root.show_cart = false;
        this.$root.content_update(id);
      }
    },
    profileOutside: function(e) {
      if(jQuery(e.target).closest('#profileId').length < 1) {
        // this.show_profile_info = false;
      }
    },
    achievementOutside: function(e) {
      if(jQuery(e.target).closest('#achievementId').length < 1) {
        this.show_achievement = false;
      }
    },
    announcementOutside: function(e) {
      if(jQuery(e.target).closest('#announcementId').length < 1 && jQuery(e.target).closest('#new_announcement').length < 1) {
        if(!hasClass(e.target,'cancel') && !hasClass(e.target,'save') && !hasClass(e.target,'fa-secondary') && !hasClass(e.target,'fa-primary') && !hasClass(e.target,'fa-times') && !hasClass(e.target,'modal-body') && !hasClass(e.target,'create_announcement_popup') && !hasClass(e.target,'modal')){
          this.show_announcement = false;
        }  
      }
    },
    openProfileModel: function() {
      // this.textChangeProfile = true;
      // $("#ProfileImageText").show();
    },
    closeProfileModel: function() {
      $("#ProfileImageText").hide();
    },
    closeNewAnnouncementModel: function() {
      $("#new_announcement").modal('hide');
      this.show_new_form = false;
      if (this.show_all_announcements) {
        jQuery('.modal-backdrop:eq(1)').remove();
      } else {
        jQuery('.modal-backdrop').remove();
      }
      jQuery('body').removeClass('modal-open');      
    },
    uploadProfileImage: function() {
      document.getElementById('getProfileImage').click();
      $("#ProfileImageText").hide();
    },
    uploadedFile: function() {
      this.inputPicture = this.$refs.inputFile.files[0];
      let formData = new FormData();
      formData.append('picture', this.inputPicture);
      this.$http.post('/upload_profile', formData).then(response => {
        this.profile_image_url = response.data.image_url;
      });
    },
    showCart() {
      this.$root.payment_status = false;
      if (!this.$root.show_cart) {
        this.$root.show_cart = true;
        this.$root.show_blog_items = false;
        this.$root.show_group = false;
        this.$root.my_learning = false;
        this.$root.content_update();
        window.scrollTo(0,0);
      }
    },
    my_learning_view: function() {
      if (this.$root.my_learning ) {
        if (this.$root.show_cart) {
          this.$root.show_cart = false
          // EventBus.$emit("toogleCart")
        }
        this.scrollIntoView('my_learning_layout')
      }
      this.$root.payment_status = false;
      this.$root.my_learning = true;
      this.$root.show_course_details = false;
      this.$root.show_blog_items = false;
      this.$root.show_group = false;
      this.$root.content_update('my_learning_layout');
    },
    store_design_view: function() {
      if (!this.$root.my_learning && this.$root.show_cart) {
        this.$root.show_cart = false
        // EventBus.$emit("toogleCart")
      }
      this.$root.payment_status = false;
      this.$root.my_learning = false;
      this.$root.show_blog_items = false;
      this.$root.show_group = false;
      this.$root.content_update();
      window.scrollTo(0,0);
    },
    showAchievement: function() {
      this.$http.get(this.$root.achievement_url).then(function(response) {
        this.selected_achievements = response.body.selected_achievements_arr;
        this.show_achievement = !this.show_achievement;
      });          
    },
    showAnnouncement: function() {
      this.show_announcement = !this.show_announcement;                
    },
    statusCheck: function() {
      this.params = ''
      if(jQuery('#' + 'checkbox1').is(":checked")) {
        this.params = this.params + '&member=1'
      } 
      if(jQuery('#' + 'checkbox2').is(":checked")) {
        this.params = this.params + '&not_member=1'        
      } 
      if(jQuery('#' + 'checkbox3').is(":checked")) {
        this.params = this.params + '&pending_approval=1'      
      }    
      this.groupList();                               
    },
    showHeaderOptions: function() {
      this.show_header_option = !this.show_header_option
    },
    outside: function(e) {
      if (!hasClass(e.target, 'user_icon_menu')) {
        this.show_header_option = false;
      }
    },
    ScrollToElement:function(){
      if (this.$root.show_course_details) {
        this.$root.show_course_details = false;
      } else {
        const el = document.getElementById('catalog_layout');
        el.scrollIntoView({behaviour: "smooth"});
      }
    },
    redirectHome: function() {
      window.location.href = this.home_url;
    },
  }
});

Vue.component('group-list',{
  template:`<div id="group_listing" class="panel_content_padding"
              <div v-bind:id="'group_detail_' + group_id" class="detail_group">
                <img v-bind:alt="group_name" class="group_thumbnail_medium" v-bind:src= "logo" v-bind:title="group_name">
                <ul class="horizontal" id="group_detail">
                  <li class="group_name">{{group_name}}
                  </li>
                  <li v-if="can_be_deleted" class="column"> | </li>
                  <a v-if="can_be_deleted" class="delete_link" v-on:click="delete_group()"> Delete</a>
                  </br>
                  <li>{{owner_text + ':'}} {{owner_names}}</li>
                  <li class="column">|</li>
                  <li>{{member_count + ' '}} Member</li>
                  <li class="column">|</li>
                  <li> discussion topics :  {{group_topic}}</li>
                  <li class="column">|</li>
                  <li> Founded :  {{founded}}</li>
                  <div class="group_member_button" v-if="group_member_button == 'Sign Up'" v-on:click="SignUp()" style="background: #ff7924; color: #FFFFFF"> {{group_member_button}}
                  </div>
                  <div v-else class="group_member_button"> {{group_member_button}}
                  </div>
                  <div class="groups_request_cancel" v-if="group_member_button == 'Private Group Member' || group_member_button == 'Member'" v-on:click="cancel_group()"><a class="hover"> Cancel Membership</a>
                  </div>
                  <div class="groups_request_cancel" v-else-if="group_member_button == 'Awaiting Approval'" v-on:click="cancel_group()"><a class="hover"> Cancel Request</a>
                  </div>                                      
                  </br>
                </ul>
                <div class="group_description"> {{group_description}}
                </div> 
                <hr v-if="last_entry != true" class="dotted margin_bottom">
              </div>
            </div>`,
  props: {
    index: Number,
    group: Object
  },

  data: function(){
    return{
      last_entry: (this.group.group_count == this.index + 1),
      group_id: this.group.group.id,
      group_name: this.group.group.name,
      owner_text: this.group.owner_text,
      can_be_deleted: this.group.can_be_deleted,
      owner_names: this.group.owner_names,
      member_count: this.group.member_count,
      group_topic: this.group.topic,
      founded: this.group.founded,
      group_description: this.group.group.description,
      group_member_button: this.group.group_member_button,
      member_url: this.group.member_url,
      cancel_url: this.group.cancel_url,
      logo: this.group.logo
    }
  },
  methods: {
    SignUp: function() {
      this.$http.post(this.member_url).then(function(response) {
        if(response.body) {
          this.group_member_button = response.body.group_member_button;
          this.member_count = response.body.member_count;
        }
        else {
          this.group_member_button = 'Sign Up'
        }
      });        
    }, 
    cancel_group: function() {    
      this.$http.post(this.cancel_url).then(function(response) {
        if(response.body) {
          this.group_member_button = 'Sign Up'
          this.member_count = response.body.member_count;
        }
      });
    }
  }
});

Vue.component('create-group-section', {
  template: `<div>              
              <form id="file_upload_form">
                <div v-if="show_edit_group" class="mb-3 lxa_font_size_03 lxa_regular_font"> Edit a Group</div>
                <div v-else class="mb-3 lxa_font_size_03 lxa_regular_font"> Create a Group</div>
                <div class="create_group_details">
                  <div class="create_group_name mb-2 lxa_font_size_03 lxa_light_font">Name<span class="text-danger">*</span></div>
                  <div class="form_field mb-3">
                    <div class="input_text">
                      <input id="group_name" class="primary_bgcolor w-100 p-2 lxa_border_radius border-0 height_40px" v-validate="'required'" data-vv-validate-on="none" v-model="group_name" name="group[name]" size="30" type="text">
                      <div class="error_container">
                        <span class ="font_open_sans_regular_11_red" v-show="errors.has('group[name]')">Group Name can't be blank</span>
                      </div>                            
                    </div>
                  </div>
                  <div class="mb-2 lxa_font_size_03 lxa_light_font">Description<span class="text-danger">*</span></div>                       
                  <div class="form_field mb-3">
                    <div class="textarea">
                      <textarea cols="40" id="group_description" class="primary_bgcolor w-100 p-2 lxa_border_radius border-0 txtarea-resize" v-validate="'required'" data-vv-validate-on="none" v-model="group_description" name="group[description]" rows="4"></textarea>
                      <div class="error_container">
                        <span class ="font_open_sans_regular_11_red" v-show="errors.has('group[description]')">Group Description can't be blank</span>
                      </div>                              
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div class="input_checkbox">
                       
                      <div id="group_is_private_wrapper" class="mb-2 group_is_privates_wrapper custom-controls custom-checkbox">
                          <input id="group_is_private" name="group[is_private]" v-on:click="select_private()" class="group_is_private custom-control-input lxa-bs-checkboxbtn" type="checkbox" value="group is private">
                          <label class="string_trunk8 custom-control-label" for="group_is_private">
                            <i class="fad fa-stop lxa_icon_size lxa_text_color_07"></i>
                            <i class="fad fa-check-square lxa_icon_size lxa_text_color_06"></i>
                            <span class="lxa_font_size_03 lxa_light_font">Private Group</span>
                          </label>
                        </div>

                      </div>
                    </div>
                   
                  </div>
                  <div class="columns line_height_13">
                    <div class="mb-3 lxa_font_size_01 lxa_light_font">Private Groups are only seen by Group members. Membership is by invite only.</div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div class="input_checkbox">
                        
                      <div id="group_auto_approve_members_wrapper" class="mb-2 group_auto_approve_members_wrapper custom-controls custom-checkbox">
                          <input id="group_auto_approve_members" name="group[auto_approve_members]" v-on:click="select_auto_member()" class="group_auto_approve_members custom-control-input lxa-bs-checkboxbtn" type="checkbox" value="auto approved">
                          <label class="string_trunk8 custom-control-label" for="group_auto_approve_members">
                            <i class="fad fa-stop lxa_icon_size lxa_text_color_07"></i>
                            <i class="fad fa-check-square lxa_icon_size lxa_text_color_06"></i>
                            <span class="lxa_font_size_03 lxa_light_font">Automatically accept new members?</span>
                          </label>
                        </div>

                      </div>
                    </div>
                  
                    <input id="from" name="from" type="hidden">
                  </div>    
                  <div class="columns" id="group_logo_field">
                      <div v-if="logo_image_url && !change_logo" class="d-flex"><img v-bind:src="logo_image_url" class="upload_link primary_bgcolor lxa_text_color_01 lxa_font_size_03 lxa_regular_font text-uppercase d-flex align-items-center justify-content-start"></img><span v-on:click="ChangeLogo" class="mt-5 pt-2 pl-3 cursor_pointer"><i class="fad fa-trash-alt lxa_text_color_01 lxa_font_size_04"></i></span></div>
                      <div v-else class="upload_link primary_bgcolor lxa_text_color_01 lxa_font_size_03 lxa_regular_font text-uppercase p-3 d-flex align-items-center justify-content-start" v-on:click="uploadLogoImage()">
                      Upload logo
                      </div>
                      <input type='file' ref="inputFile" accept="image/*" id="getLogoImage" @change="uploadedImageFile" style="display: none"/>
                  </div>
                  <div id="image_upload_help_text" class="my-3 lxa_font_size_01 lxa_light_font">Upload square image for better looks.
                  </div>
                </div>    
              </form>  
              <div class="col-sm-12 d-flex flex-row-reverse padding_0 margin_top_15">
                <div class="text_align_right" id="save_group_button">
                  <button v-if="show_edit_group" type="submit" class="btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase ml-3" v-on:click="UpdateGroup()">Done</button>
                  <button v-else type="submit" class="btn lxa_bg_02 ml-2 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase ml-3" v-on:click="saveGroup()">CREATE</button>  
                </div>
                <div class="text_align_right cancel_group">
                  <button type="button" class="btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" v-on:click="cancelCreateGroup()">CANCEL</button>
                </div>
              </div>                        
            </div>`,
  data: function() {
    return {
      group_name: '',
      group_description: '',
      group_private: false,
      group_auto_member: false,
      logo_image_url: '',
      groups_arr: [],
      from: 'my_learning',
      group_id: '',
      change_logo: false
    }
  },
  props: {
    show_create_group: Boolean,
    show_edit_group: Boolean,
    edit_group_popup: Boolean
  },
  mounted: function() {
    if (this.show_edit_group != false) {  
      if (this.$parent.group_private == true) {
        jQuery('#group_is_private').prop("checked", true);
      }else {
        jQuery('#group_is_private').prop("checked", false);
      }
      if (this.$parent.group_auto_member == true) {
        jQuery('#group_auto_approve_members').prop("checked", true);
      }else {
        jQuery('#group_auto_approve_members').prop("checked", false);
      }      
      this.group_name = this.$parent.group_name;
      this.group_description = this.$parent.group_description;
      this.logo_image_url = this.$parent.logo;
      this.group_private = this.$parent.group_private;
      this.group_auto_member = this.$parent.group_auto_member;
      this.group_id = this.$parent.group_id;
    }
  },  
  methods: {
    uploadLogoImage: function() {    
      document.getElementById('getLogoImage').click();
    }, 
    uploadedImageFile: function() {
      this.inputLogo = this.$refs.inputFile.files[0];
      this.formData = new FormData();
      this.formData.append('logo', this.inputLogo);
      this.logo_image_url = URL.createObjectURL(this.inputLogo)
      this.change_logo = false;
    },       
    cancelCreateGroup: function() {  
      if (!this.show_edit_group) {  
        this.$parent.show_create_group = false;
        this.group_name = '';
        this.group_description = '';
        jQuery('#group_is_private').prop("checked", false);  
        this.group_private = false;   
        jQuery('#group_auto_approve_members').prop("checked", false);
        this.group_auto_member = false;  
        this.logo_image_url = '';  
      }else {
        if (this.edit_group_popup) {
          this.$parent.$parent.show_edit_group = false;
        } else {
          this.$parent.show_edit_group = false;
        }  
        this.$parent.$parent.edit_group_popup = false;
      }     
    },
    saveGroup: function() {
      this.$validator.validateAll().then(result => {
        if (result) {      
          if (this.logo_image_url == '') {
            this.$root.popUpMessage("Please Upload a Logo");
          } else {
            this.group_name = jQuery('#group_name').val();
            this.group_description = jQuery('#group_description').val();
            var self = this;
            var params = "&group[name]="+this.group_name+"&group[description]="+ this.group_description+"&group[is_private]="+this.group_private+"&group[auto_approve_members]="+this.group_auto_member+"&from="+this.from;
            this.$http.post('/groups'+'?'+params, this.formData).then(function(response) {
              if(response.data.status == 'failed'){
                this.$root.popUpMessage(response.data.message);
              }
              else{
                this.$root.popUpMessage("Created Group");
                this.$parent.groups_arr.unshift(response.body[0]);
                this.$parent.group_counts = response.body[0].group_counts;
                setTimeout(function(){ self.replaceGroup(); });
              }
            });
            this.$parent.show_create_group = false;
            this.cancelCreateGroup();
          } 
        }
      });         
    },
    UpdateGroup: function() {
      this.$validator.validateAll().then(result => {
        if (result) {      
          this.group_name = jQuery('#group_name').val();
          this.group_description = jQuery('#group_description').val();
          var self = this;
          var params = "&group[name]="+this.group_name+"&group[description]="+ this.group_description+"&group[is_private]="+this.group_private+"&group[auto_approve_members]="+this.group_auto_member+"&from="+this.from;
          this.$http.put('/groups/'+this.group_id+'?'+params, this.formData).then(function(response) {
            this.$parent.group_name = this.group_name;
            this.$parent.group_description = this.group_description;
            this.$parent.logo = response.body[0].logo;
            this.$parent.group_private = this.group_private;
            this.$parent.group_auto_member = this.group_auto_member;   
            setTimeout(function(){ self.replaceGroup(); });
          });
          this.cancelCreateGroup();
        }
      });         
    },    
    replaceGroup: function() {
      var exis_group = jQuery("#group_list").find(".detail_group").first();
      var new_group = jQuery("#group_list .detail_group").last();
      (new_group).insertBefore(exis_group);
    },
    select_private: function() {
      if(jQuery('#group_is_private').is(":checked")) {
        this.group_private = true;
      } 
      else {
        this.group_private = false;   
      }         
    },
    select_auto_member: function() {
      if(jQuery('#group_auto_approve_members').is(":checked")) {
        this.group_auto_member = true;
      }
      else {
        this.group_auto_member = false;    
      }          
    },
    ChangeLogo: function(){
      this.change_logo = true;
    }
  }
});

Vue.component('profile-form', {
  template:`<div id="profile" class="col-sm-12 col-md-2 profile_model_box padding_top_10px margin_left_minus_10px">
              <div id="container">
                <div class="row" id="row">
                  <div id="number_circle" class="col-sm-1">
                    <div id="circleName" class="numberCircle margin_left_minus_17px"><span>{{name[0]}}</span></div>
                  </div>
                  <div id="details" class="col-sm-10">
                    <div id="account_name" class="profile_name_text profile_truncate word_wrap margin_left_minus_10px width_145px"><span>{{name}}&nbsp{{lastname}}</span></div>
                    <br/>
                    <div id="account_email" class="profile_mail_password_text profile_truncate word_wrap margin_left_minus_10px width_145px"><span>{{email}}</span></div>
                    <br/>
                    <div class="profile_mail_password_text profile_truncate word_wrap margin_left_minus_10px width_145px"><span >{{$t('login.password')}}:&nbsp**********</span></div>
                  </div>
                  <div class="col-sm-1 profile_more_link" v-on:mouseover="display_change_detail" v-on:mouseleave="active = false">
                    <img id="profile_more" src="/images/single_page/more_icon_small.png" alt="more" class="more cursor_pointer float-right">
                    <div id="change_detail" v-show="active" class="popover bottom in" style="display: block !important">
                      <div id="change_details_arrow" class="arrow_img"><img src="/images/single_page/arrow.png" class="padding_right_0"></div>
                      <change-details class="margin_left_0px margin_top_5px"></change-details>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="margin_top_5px margin_bottom_4px">
              <div class="row">
                <div class="col-sm-12">
                  <p id="row_name" v-on:click="signout" class="signout cursor_pointer">{{$t('common.sign_out')}}</p>
                </div>
              </div>
            </div>`,
  props: {
    name: String,
    lastname:String,
    email: String
  },
  data: function(){
    return{
      active: false
    }
  },
  methods: {
    signout: function (event) {
      var csrfToken = encodeURIComponent(jQuery('meta[name="csrf-token"]').attr('content'));
      jQuery.ajax({
        type: 'DELETE',
        url: "/users/sign_out?"+"&single_page=1&authenticity_token="+csrfToken,
        success: function(response) {}
      });
    }, 
    display_change_detail: function() {
      this.active = true;
      jQuery("#change_details_arrow").css('margin-left', (jQuery('#change_detail').width() - 23 + 'px'));
      jQuery("#change_detail").css('margin-left', ((0 - jQuery('#change_detail').width()) + 21 + 'px'));
    }
  } 
});

Vue.component('change-details', {
  template:`<div id="change" style="text-decoration:none;" class="popover-content line_height_10px">
              <div><p id="changename" class="col-sm-12 padding_left_0 padding_top_0 changedetails cursor_pointer" v-on:click="changename">{{$t('profile.change_name')}}</p></div>
              <div><p id="changeemail" class="changedetails col-sm-12 padding_left_0 padding_top_0 cursor_pointer" v-on:click="changeemail">{{$t('profile.change_email')}}</p></div>
              <div><p id="changepassword" class="changedetails cursor_pointer" v-on:click="changepassword">{{$t('profile.change_password')}}</p></div>
            </div>`,
  methods: {
    changename: function(event) {
      jQuery("#profile").addClass("hide");
      jQuery("#change_names").removeClass("hide");
      jQuery("#change_names").addClass("popover-content");
    },
    changeemail: function(event) {
      jQuery("#profile").addClass("hide");
      jQuery("#change-emails").removeClass("hide");
      jQuery("#change-emails").addClass("popover-content");
    },
    changepassword: function(event) {
      jQuery("#profile").addClass("hide");
      jQuery("#change-passwords").removeClass("hide");
      jQuery("#change-passwords").addClass("popover-content");
    }
  }
});

Vue.component('change-name', {
  template:`<div id="change_names" class="hide profile_model_box padding_top_8 padding_bottom_6">
              <form class="form-horizontal" v-on:submit.prevent="onSubmit" role="form">
                <label class="change_font">{{$t('profile.change_name')}}</label>
                <div class="container-fluid margin_top_1" id = "changename_container">
                  <div class="row" id="row_name" style="">
                    <div class="col-sm-6 left_minus_30px" id="row_name">
                      <input type="text" v-validate="'required'" data-vv-validate-on="none" name="firstname" class="nameinputs padding_left_3" v-model="firstname"/>
                    </div>
                    <div id="row_name" class="col-sm-6 left_minus_15px">
                      <input type="text" v-validate="'required'" data-vv-validate-on="none" class="nameinputs padding_left_3" name="lastname" v-model="lastname">
                    </div>
                  </div>
                  <div class="col-sm-6 error_container">
                    <span class ="font_open_sans_regular_11_red" id="firstname_error" v-show="errors.has('firstname')">{{ $t('login.enter_first_name') }}</span>
                  </div>
                  <div class="col-sm-6 error_container">
                    <span class ="font_open_sans_regular_11_red" id="lastname_error" v-show="errors.has('lastname')">{{ $t('login.enter_last_name') }}</span>
                  </div>
                  <hr class="margin_top_11"/>
                  <div id="row_name" class="row margin_top_minus_10px margin_bottom_minus_10px">
                    <div class="col-sm-9">
                      <p id="cancel_name" v-on:click="cancel_name" class="profile_cancel_button cursor_pointer float-right">{{$t('common.cancel')}}</p>
                    </div>
                    <div class="col-sm-1">
                      <button type="submit" class="font_open_sans_semibold_10_blue btn btn-default btn-round-sm float-left">{{$t('common.save').toUpperCase()}}</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>`,
  props: {
    name_url: String,
    name: String,
    last_name: String
  },
  data: function() {
    return{
      firstname: this.name,
      lastname: this.last_name
    }
  },
  methods: {
    cancel_name: function(event) {
      this.firstname = this.name;
      this.lastname = this.last_name;
      jQuery("#change_names").addClass("hide");
      jQuery("#profile").removeClass("hide");
      jQuery("#profile").addClass("popover-content");
      this.errors.clear();
    },
    onSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          var self = this;
          jQuery.ajax({
            type: 'PUT',
              url: this.name_url,
              data: jQuery('#form').serialize() + "user[firstname]="+this.firstname +"&user[lastname]="+this.lastname,
              success: function(response) {
                var fullname = self.firstname + ' ' + self.lastname;
                jQuery("#account_name").find('span').remove();
                jQuery("#account_name").append('<span class="line_height_7 truncate word_wrap" style="color: #000000"> '+fullname+' </span>');
                jQuery("#circleName").find('span').remove();
                jQuery("#circleName").append('<span>'+fullname[0]+'</span>');
                self.$parent.name = self.firstname;
                self.$parent.last_name = self.lastname;
                jQuery("#change_names").addClass("hide");
                jQuery("#name_success").removeClass("hide");
                jQuery("#name_success").addClass("popover-content");
              }
          });
        }
      });
    }
  }
});

Vue.component('name-update-success', {
  template:`<div id="name_success" class="hide profile_model_box">
              <form class="form-horizontal">
                <div class="form-group margin_bottom_4">
                  <div class="col-sm-12">
                    <p class="font_open_sans_regular_12_gray">{{$t('profile.after_changing_name')}}</p>
                  </div>
                </div>
                <hr class="margin_bottom_10 margin_top_minus_10px"/>
                <div class="col-sm-12">
                  <a id="okay" class="no_underline font_open_sans_regular_12_blue float-right" v-on:click="close()">{{$t('common.okay')}}</a>
                </div>
              </form>
            </div>`,
  methods: {
    close: function(event) {
      jQuery("#name_success").addClass("hide");
      jQuery("#profile").removeClass("hide");
      jQuery("#profile").addClass("popover-content");
      setTimeout(function() {
        initTrunk8Faded('.truncate',false,1);
      },100);
    }
  }
});

Vue.component('change-email', {
  template:`<div id="change-emails" class="hide profile_model_box padding_top_8 padding_bottom_6">
              <form class="form-horizontal" v-on:submit.prevent="onSubmit" role="form">
                <label class="change_font">{{$t('profile.change_email')}}</label>  
                <input type="email" v-validate="'required|email'" data-vv-validate-on="none" name="email" class="form-control input-sm" v-model="new_email">
                <div class="error_container">
                    <span class ="font_open_sans_regular_11_red" id="email_error" v-show="errors.has('email')">{{ this.$t('login.enter_email') }}</span>
                  </div>
                <hr class="margin_top_11"/>
                <div id="row_name" class="row margin_top_minus_10px margin_bottom_minus_10px">
                  <div class="col-sm-9">
                    <p id="cancel_email" v-on:click="cancel_email" class="profile_cancel_button cursor_pointer float-right">{{$t('common.cancel')}}</p>
                  </div>
                  <div class="col-sm-1">
                    <button type="submit" class="font_open_sans_semibold_10_blue btn btn-default btn-round-sm float-left">{{$t('common.save').toUpperCase()}}</button>
                  </div>
                </div>
              </form>
            </div>`,
  props: {
     email: String,
     email_url: String
  },
  data: function () {
    return {
      new_email: this.email
    }
  },
  methods: {
    cancel_email: function(event) {
      this.new_email = this.email;
      jQuery("#change-emails").addClass("hide");
      jQuery("#profile").removeClass("hide");
      jQuery("#profile").addClass("popover-content");
    },
    onSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          var self = this;
          jQuery.ajax({
            type: 'PUT',
            url: this.email_url,
            data : jQuery('#form').serialize() + "user[email]="+this.new_email,
            success: function(response) {
              self.$parent.email = self.new_email;
              jQuery("#account_email").find('span').remove();
              jQuery("#account_email").append('<span class="line_height_7 font_open_sans_regular_12_light_gray normal truncate word_wrap">'+self.new_email+'</span>');
              jQuery("#change-emails").addClass("hide");
              jQuery("#email-success").removeClass("hide");
              jQuery("#email-success").addClass("popover-content");
            }
          });
        }
      });
    }
  }
});

Vue.component('email-success', {
  template:`<div id="email-success" class="hide profile_model_box">
              <form class="form-horizontal">
                <div class="form-group margin_bottom_4">
                  <div class="col-sm-12">
                    <p class="font_open_sans_regular_12_gray">{{$t('profile.after_changing_email')}}</p>
                  </div>
                </div>
                <hr class="margin_bottom_10 margin_top_minus_10px"/>
                <div class="col-sm-12">
                  <a id="okay" class="no_underline font_open_sans_regular_12_blue float-right" v-on:click="close()">{{$t('common.okay')}}</a>
                </div>
              </form>
            </div>`,
  methods: {
    close: function(event) {
      jQuery("#email-success").addClass("hide");
      jQuery("#profile").removeClass("hide");
      jQuery("#profile").addClass("popover-content");
      setTimeout(function() {
        initTrunk8Faded('.truncate',false,1);
      },100);
    }
  }
});

Vue.component('change-password', {
  template:`<div id="change-passwords" class="hide profile_model_box padding_top_8 padding_bottom_6">
              <form class="form-horizontal padding_left_3" v-on:submit.prevent="onSubmit" role="form">
                <label class="change_font" style="padding-left: 1px;padding-bottom: 0px">{{$t('profile.change_password')}}</label>
                <div>
                  <input type="password" v-validate="'required'" data-vv-validate-on="none" id="current_password" name="Current password" :placeholder="$t('profile.current_password')" class="passwords margin_top_1 form-control input-sm" v-model="Current_password" style="padding-left: 6px !important">
                  <div class="error_container">
                    <span class ="font_open_sans_regular_11_red"  id="current_password_error"></span>
                    <span class ="font_open_sans_regular_11_red" v-show="errors.has('Current password')">{{ $t('profile.enter_current_password') }}</span>
                  </div>
                </div>
                <div>
                  <input type="password" v-validate="'required'" data-vv-validate-on="none" id="password" name="password" :placeholder="$t('profile.new_password')" class="passwords form-control input-sm" v-model="New_password" style="padding-left: 6px !important">
                  <div class="error_container">
                    <span class ="font_open_sans_regular_11_red" v-show="errors.has('password')">{{ $t('login.password_cant_blank') }}</span>
                    <span class ="font_open_sans_regular_11_red" id="password_error"></span>
                  </div>  
                </div>
                <div>
                  <input type="password" v-validate="'required'" data-vv-validate-on="none" id="password_confirmation" name="Confirm password" :placeholder="$t('profile.confirm_password')" class="passwords form-control input-sm" v-model="Confirm_password" style="padding-left: 6px !important">
                  <div class="error_container">
                    <span class ="font_open_sans_regular_11_red" v-show="errors.has('Confirm password')">{{ $t('login.enter_password_confirmation') }}</span>
                    <span class ="font_open_sans_regular_11_red" id="confirm_password_error"></span>
                  </div>
                </div>
                <hr class="margin_top_11"/>
                <div id="row_name" class="row margin_top_minus_10px margin_bottom_minus_10px">
                  <div class="col-sm-9">
                    <p id="cancel_password" v-on:click="cancel_password" class="profile_cancel_button cursor_pointer float-right">{{$t('common.cancel')}}</p>
                  </div>
                  <div class="col-sm-1">
                    <button type="submit" class="font_open_sans_semibold_10_blue btn btn-default btn-round-sm float-left">{{$t('common.save').toUpperCase()}}</button>
                  </div>
                </div>
              </form>   
            </div>`,
  props: {
    password_url: String
  },
  data() {
    return {
      item: Object,
      error: '',
      Current_password: '',
      New_password: '',
      Confirm_password: '',
      current_password: this.$t('profile.current_password'),
      password: this.$t('login.password'),
      confirm_password: this.$t('profile.password_confirmation')  
    }
  },          
  methods: {
    cancel_password: function(event) {
      this.Current_password = '';
      this.New_password = '';
      this.Confirm_password = '';
      this.errors.clear(); 
      jQuery("#change-passwords").addClass("hide");
      jQuery("#profile").removeClass("hide");
      jQuery("#profile").addClass("popover-content");
      jQuery("#current_password_error").html("");
      jQuery("#password_error").html("");
    },

    onSubmit() {
      jQuery("#password_error").html("");
      jQuery("#confirm_password_error").html("");
      jQuery("#current_password_error").html(""); 
      this.$validator.validateAll().then(result => {
        if (result) {
          var self = this;
          if (this.Current_password == this.New_password ) {
            jQuery("#current_password_error").html("Current password and New password are same");
          }
          else {
            jQuery.ajax({
              type: 'PUT',
              url: this.password_url,
              data : jQuery('#form').serialize() + "user[current_password]="+this.Current_password +"&user[password]="+this.New_password+"&user[password_confirmation]="+this.Confirm_password,
              success: function(data) {
                if (Object.keys(data).includes("current_password")) {
                  jQuery("#current_password_error").html(self.current_password + " " + data['current_password']);
                }
                else if (Object.keys(data).includes("password_confirmation")) {
                  jQuery("#confirm_password_error").html(self.confirm_password + " " + data['password_confirmation'][0]);
                }
                else
                {
                  jQuery("#change-passwords").addClass("hide");
                  jQuery("#password_success").removeClass("hide");
                  jQuery("#password_success").addClass("popover-content");
                }
              }
            });
          }
        }
      });
    }
  }
});

Vue.component('password-change-success', {
  template:`<div id="password_success" class="hide profile_model_box">
              <form class="form-horizontal">
                <div class="form-group margin_bottom_4">
                  <div class="col-sm-12">
                    <p class="font_open_sans_regular_12_gray">{{$t('profile.after_changing_password')}}</p>
                  </div>
                </div>
                <hr class="margin_bottom_10 margin_top_minus_10px"/>
                <div class="col-sm-12">
                  <a id="okay" class="no_underline font_open_sans_regular_12_blue float-right" v-on:click="close()">{{$t('common.okay')}}</a>
                </div>
              </form>
            </div>`,
  methods: {
    close: function(event) {
      jQuery("#password_success").addClass("hide");
      jQuery("#profile").removeClass("hide");
      jQuery("#profile").addClass("popover-content");
    }
  }  
});

Vue.component('achievement-section', {
  template: `<div :class='[{"hide": !show_achievement, "achievement_popup": !from_profile}]' id="achievementId">
                <div class="filter_types">
                  <div v-if="from_profile" class="row mx-0">
                    <div class="col-10 px-0">
                      <span class="lxa_font_size_04 lxa_medium_font d-flex align-items-center justify-content-start mb-3"> Achievements </span>
                    </div>
                    <div class="col-2 px-0" v-if="!from_profile_view">
                      <span class="edit_achievement_button cursor_pointer d-flex align-items-center justify-content-end" @click="editAchievement" v-click-outside="achievementOutside" data-toggle="tooltip" data-placement="top" title="Edit"> <i class="fad fa-pencil lxa_text_color_01"></i> </span>
                    </div>
                  </div>
                  <div v-else>                
                    <span class="achievement_header_text"> Achievements </span> 
                    <div class="col-auto cursor_pointer" v-on:click="editAchievement()"> Edit </div>
                  </div>   
                  <div v-if="selected_achievements.length==0" class="row mx-0">
                    <div class="col-12 d-flex align-items-center justify-content-center"> </div>
                  </div>
                  <div v-else class="row mx-0">
                    <div v-if="from_profile" class="col-12 user_achievement_list px-0 d-flex align-items-center justify-content-start">
                      <span v-for="select in selected_achievements" class="p-4 mb-3 primary_bgcolor user_achievement_col text-center lxa_border_radius"> 
                        <img class="achievement_thumbnail selected_achievement_cursor" :src="select.thumbnail_url" :title="select.title">
                      </span>
                    </div>

                    <table v-else="" class="col-sm-6 px-0 select_achievements" v-for="select in selected_achievements"> 
                      <tbody> 
                        <tr>
                          <td>
                            <img class="achievement_thumbnail selected_achievement_cursor" :src="select.thumbnail_url" :title="select.title">
                          </td>
                          <span class="achievement_seperator"></span>
                        </tr>  
                      </tbody>
                    </table>

                  </div>                                     
                </div>
                <div :class='[{"hide": !show_edit_achievement, "custom_popover padding_8": from_profile, "edit_achievement_popup": !from_profile}]'>
                  <form id="select_achievements_form" >
                    <div v-if="all_achievements.length==0">
                      <div> No badge to add! </div>
                    </div>
                    <div v-else class="col-sm-12 padding_0">
                      <div v-if="from_profile">
                        <h6 class="w-100 lxa_font_size_04 lxa_medium_font d-flex align-items-center justify-content-start mb-3"> All Achievements </h6>
                        <span v-for="achievement in all_achievements" class="mr-3"> 
                          <img :class="getAchievementSelectClass(achievement)" :id="'achievement_'+achievement.id" :data-achievement_type="achievement.achievement_type" :data-id="achievement.id" :src="achievement.thumbnail_url" :title="achievement.title" v-on:click="img_select(achievement.id,achievement.achievement_type)">
                        </span>
                      </div>
                      <table v-else="" class="col-sm-6 padding_0 select_achievements" v-for="achievement in all_achievements"> 
                        <tbody> 
                          <tr>
                            <td>
                              <img class="achievement_thumbnail" :id="'achievement_'+achievement.id" :data-achievement_type="achievement.achievement_type" :data-id="achievement.id" :src="achievement.thumbnail_url" :title="achievement.title" v-on:click="img_select(achievement.id,achievement.achievement_type)">
                            </td>
                            <span class="achievement_seperator"></span>
                          </tr>  
                        </tbody>
                      </table>
                      <div :class='[{"float_right padding_top_12": from_profile, "col-sm-12 padding_0": !from_profile}]' class="d-flex align-items-center justify-content-end"> 
                        <a class="btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase mr-3 lxa_fontfamily" @click.prevent ="closeEdit()">Cancel</a>   
                        <button class= "btn lxa_bg_01 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase lxa_fontfamily" @click.prevent ="save_selection()">Done</button>
                      </div>
                    </div>
                  </form>  
                </div>  
              </div>`,
  data: function() {
    return {
      // selected_achievements:[],
      all_achievements: [], 
      show_edit_achievement: false,
      selected_certificates: [],
      selected_certifications: []  
    }
  },
  props: {
    show_achievement: Boolean,
    from_profile: Boolean,
    selected_achievements: Array,
    from_profile_view: {default: false, type: Boolean}
  },
  watch: {
    show_edit_achievement() {
      if (this.from_profile) {
        this.$parent.$parent.data_altered = this.show_edit_achievement
      }
    }
  },
  methods: {
    setSelectedAchievements() {
      var _this = this
      _this.selected_achievements.forEach(function (achievement) {
        if (achievement.achievement_type == 'certificates') {
          _this.selected_certificates.push(achievement.id)
        }
        else {
          _this.selected_certifications.push(achievement.id)
        }
      });
    },
    achievementOutside: function(e) {
      if(jQuery(e.target).closest('#achievementId').length < 1) {
        this.show_edit_achievement = false;
      }
    },
    getAchievementSelectClass(achievement) {
      return achievement.is_selected ? 'selected achievement_thumbnail' : 'achievement_thumbnail'
    },
    editAchievement: function() {
      this.all_achievements = []
      this.$http.get(this.$root.achievement_url).then(function(response) {
        this.all_achievements = response.body.all_achievements_arr;
        this.save_selection_url = response.data.save_selection_url;
        this.show_edit_achievement = true; 
      });        
      this.setSelectedAchievements();
    },
    save_selection: function() {
      this.$http.get(this.save_selection_url + '.json?&certificates='+ JSON.stringify(this.selected_certificates) + '&certifications=' + JSON.stringify(this.selected_certifications)).then(function(response) {
        this.show_edit_achievement = false;
        this.updatedAchievement();
        this.$root.popUpMessage("Successfully Updated !!")
        EventBus.$emit("updateProfileData")
      });
      this.selected_certificates.clear();
      this.selected_certifications.clear();
      jQuery(".achievement_thumbnail").removeClass('selected');    
    },
    img_select: function(achievement_id,achievement_type) {
      jQuery("#achievement_"+achievement_id).hasClass('selected') ? jQuery("#achievement_"+achievement_id).removeClass('selected') : jQuery("#achievement_"+achievement_id).addClass('selected')
      if (jQuery("#achievement_"+achievement_id).hasClass('selected')) {
        if (achievement_type == 'certificates') {
          this.selected_certificates.push(achievement_id)
        }
        else {
          this.selected_certifications.push(achievement_id)
        }
      }
      else {
        var index = (achievement_type == 'certificates') ? (this.selected_certificates.indexOf(achievement_id)) : (this.selected_certifications.indexOf(achievement_id))
        if (achievement_type == 'certificates') {
          this.selected_certificates.splice(index,1)
        }
        else {         
          this.selected_certifications.splice(index,1)
        }          
      }
    },
    closeEdit: function() {
      this.show_edit_achievement = false; 
    },
    updatedAchievement: function() {
      this.$http.get(this.$root.achievement_url).then(function(response) {
        this.$parent.selected_achievements = response.body.selected_achievements_arr;
        this.show_achievement = true;
        if (this.from_profile) {
          this.$parent.$parent.achievements = response.body.selected_achievements_arr;
        }
      });          
    }     
  },
});

Vue.component('Quick-access', {
  template:`<div class="col-sm-12 mt-5 pt-1 px-0 scroll_margin_top_100" id="quick_access_container">
            <div class="font_barlow_semi_condensed_light_24">
              {{this.$root.header_details[2].text}}
            </div>
            <div class="container-fluid margin_top_15 padding_left_0 padding_right_0">
              <div class="row mx-0 quick_access_row d-flex align-items-center justify-content-start">
                <div class="col-sm px-0 quick_access_column mr-3 cursor_pointer" v-on:click="showBlog()" key="lxa_blog" @mouseover="quick_access_hover = true" @mouseleave="quick_access_hover = false" :class="quick_access_hover ? 'lxa_blog_box':''">
                  <div class="row">                               
                  <div class="col-12 quick_access_image">
                    <i class="fad fa-blog quick_access_icons" :class="quick_access_hover ? '':'lxa_text_color_06'"></i>
                  </div>
                  <div class="col-12 text_align_center mt-3 line_height_20 font_barlow_semi_condensed_medium_18">
                    {{$t('quick_access.blog')}}
                  </div>
                  </div>
                </div>
                <div class="col-sm px-0 quick_access_column mr-3 cursor_pointer" data-toggle="modal" data-target="#user_filebox_modal" data-backdrop="static" data-keyboard="false" key="lxa_filing" @mouseover="quick_access_hover = true" @mouseleave="quick_access_hover = false"  :class="quick_access_hover ? 'lxa_filing_box':''">
                  <div class="row">                  
                  <div class="col-12 quick_access_image">
                    <i class="fad fa-cabinet-filing quick_access_icons" :class="quick_access_hover ? '':'lxa_text_color_01'"></i>
                  </div>
                  <div class="col-12 text_align_center mt-3 line_height_20 font_barlow_semi_condensed_medium_18">
                    {{$t('quick_access.filebox')}}
                  </div>
                  </div>
                </div>
                <div class="col-sm px-0 quick_access_column mr-3 cursor_pointer" v-on:click="ShowGroups()" key="lxa_groups" @mouseover="quick_access_hover = true" @mouseleave="quick_access_hover = false" :class="quick_access_hover ? 'lxa_groups_box':''">
                  <div class="row">
                  <div class="col-12 quick_access_image">
                    <i class="fad fa-users-medical quick_access_icons" :class="quick_access_hover ? '':'lxa_text_color_04'"></i>
                  </div>
                  <div class="col-12 text_align_center mt-3 line_height_20 font_barlow_semi_condensed_medium_18">
                    {{$t('quick_access.groups')}}
                  </div>
                  </div>
                </div>
                <div class="col-sm px-0 quick_access_column mr-3 cursor_pointer" data-toggle="modal" data-target="#user_playlist_modal" @click="showUserPlaylist = true" data-backdrop="static" data-keyboard="false" key="lxa_playlist" @mouseover="quick_access_hover = true" @mouseleave="quick_access_hover = false" :class="quick_access_hover ? 'lxa_playlist_box':''">
                  <div class="row">
                  <div class="col-12 quick_access_image">
                    <i class="fad fa-list-ol quick_access_icons" :class="quick_access_hover ? '':'lxa_text_color_06'"></i>
                  </div>
                  <div class="col-12 text_align_center mt-3 line_height_20 font_barlow_semi_condensed_medium_18">
                    {{$t('quick_access.playlist')}}
                  </div>
                  </div>
                </div>
                <div class="col-sm px-0 quick_access_column cursor_pointer" data-toggle="modal" data-target="#user_testimonial_modal" data-backdrop="static" data-keyboard="false" key="lxa_testimonials" @mouseover="quick_access_hover = true" @mouseleave="quick_access_hover = false" :class="quick_access_hover ? 'lxa_testimonials_box':''">
                  <div class="row">
                  <div class="col-12 quick_access_image">
                    <i class="fad fa-comment-alt-dots mx-auto d-block lxa_icon_size_large" :class="quick_access_hover ? '':'lxa_text_color_04'"></i>
                  </div>
                  <div class="col-12 text_align_center mt-3 line_height_20 font_barlow_semi_condensed_medium_18">
                    {{$t('quick_access.testimonials')}}
                  </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="show_groups">
              <groups-modal :logged_in="logged_in"></groups-modal>
            </div>
            <user-playlist v-if="showUserPlaylist"> </user-playlist>
            <user-testimonial :logged_in="logged_in" :site_admin="site_admin"> </user-testimonial>
            <collect-user-testimonial :user_feedback="user_feedback"> </collect-user-testimonial>
            <playlist-content-player> </playlist-content-player>
            <tile-details-share-popup v-if="shareModalOpen" v-click-outside="outside" v-bind:share_popup_id="share_popup_id" :item_share_link="product.item_share_link" :title="product.title" :shareable_id="product.id" :logged_in="logged_in" share_type_open="invite" :from_content_player="false"/>
            <filebox-lists> </filebox-lists>
          </div>`,
  props: {
    logged_in: Boolean,
    site_admin: Boolean
  },
  data: function() {
    return {
      show_groups: false,
      showUserPlaylist: false,
      user_feedback: false,
      share_popup_id: "share_popup_" + new Date().getTime(),
      shareModalOpen: false,
      product: {},
      quick_access_hover: false,
    }
  },
  mounted() {
    var _this = this
    this.$root.$on('collectUserFeedback', function() {
      _this.user_feedback = true
    })
    //this.quick_access_mouseover;
  },
  methods: {
    quick_access_mouseover: function(){
      var _this = this
      _this.quick_access_hover = true
      //alert("quick_access_mouseover");
    },
    quick_access_mouseleave: function(){
      var _this = this
      _this.quick_access_hover = false
      //alert("quick_access_mouseleave");
    },
    showBlog(){
      // this.$root.show_blog_items = true;
      if (!this.$root.show_blog_items) {
        this.$root.show_cart = false;
        this.$root.show_blog_items = true;
        this.$root.show_group = false;
        this.$root.my_learning = true;
        this.$root.header_update();
      } 
      // EventBus.$emit('canSeeBlog', this.$root.show_blog_items);
    },
    ShowGroups: function() {
      // this.show_groups = true
      if (!this.$root.show_group) {
        this.$root.show_cart = false;
        this.$root.show_blog_items = false;
        this.$root.show_group = true;
        this.$root.my_learning = true;
        this.$root.header_update();
        window.scrollTo(0,0);
      } 
    },
    outside: function(e) {
      // if(e.target.id != 'item_details_share' && !hasClass(e.target, 'rm') && !hasClass(e.target,'stop_close')) {
      //   this.sharePopupOpen = false;
      // }
    },
  }
});

Vue.component('admin-quick-access', {
  template:`<div class="col-sm-12 px-0 mt-5 scroll_margin_top_100" id="admin_quick_access_container">
            <div class="lxa_main_title_light">
              {{$t('quick_access.quick_access')}}
            </div>
            <div class="container-fluid margin_top_15 padding_left_0 padding_right_0">
              <div class="admin_quick_access_row">
                <div id="lxa_create_course_box" :class="[{'active': showCreateCourse},{'lxa_create_course_box':admin_quick_access_hover},'admin_quick_access_column']" key="lxa_create_course" @mouseover="admin_quick_access_hover = true" @mouseleave="admin_quick_access_hover = false">
                  <div class="row" @click="toggle_create_course">
                    <div class="col-12 quick_access_container" :key="admin_quick_access_hover">
                      <i class="fad fa-books mx-auto d-block lxa_icon_size_large" :class="admin_quick_access_hover ? '':'lxa_text_color_07'"></i>
                    </div>
                    <div class="col-12 text_align_center line_height_20 font_barlow_semi_condensed_medium_18">{{$t('quick_access.create_course')}}</div>
                  </div>
                </div>
                <div id="lxa_create_banner_box" class="admin_quick_access_column" key="lxa_create_banner" @mouseover="admin_quick_access_hover = true" @mouseleave="admin_quick_access_hover = false" :class="admin_quick_access_hover ? 'lxa_create_banner_box':''">
                <div class="row" data-toggle="modal" data-target="#banner_uploads_modal">
                  <div class="col-12 quick_access_container" :key="admin_quick_access_hover">
                    <i class="fad fa-image mx-auto d-block lxa_icon_size_large" :class="admin_quick_access_hover ? '':'lxa_text_color_09'"></i>
                  </div>
                  <div class="col-12 text_align_center line_height_20 font_barlow_semi_condensed_medium_18">{{$t('quick_access.create_banner')}}</div>
                </div>
                </div>
                <div class="admin_quick_access_column" v-on:click="redirectUser()" key="lxa_create_user" @mouseover="admin_quick_access_hover = true" @mouseleave="admin_quick_access_hover = false" :class="admin_quick_access_hover ? 'lxa_create_user_box':''">
                <div class="row">  
                  <div class="col-12 quick_access_container" :key="admin_quick_access_hover">
                    <i class="fad fa-user mx-auto d-block lxa_icon_size_large" :class="admin_quick_access_hover ? '':'lxa_text_color_01'"></i>
                  </div>
                  <div class="col-12 text_align_center line_height_20 font_barlow_semi_condensed_medium_18">{{$t('quick_access.create_user')}}</div>
                </div>
                </div>
                <div class="admin_quick_access_column" v-on:click="redirectReport()" key="lxa_create_report" @mouseover="admin_quick_access_hover = true" @mouseleave="admin_quick_access_hover = false" :class="admin_quick_access_hover ? 'lxa_create_report_box':''">
                  <div class="row">
                  <div class="col-12 quick_access_container" :key="admin_quick_access_hover">
                    <i class="fad fa-analytics mx-auto d-block lxa_icon_size_large" :class="admin_quick_access_hover ? '':'lxa_text_color_06'"></i>
                  </div>
                  <div class="col-12 text_align_center line_height_20 font_barlow_semi_condensed_medium_18">{{$t('quick_access.create_report')}}</div>
                </div>
                </div>
                <!--<div class="admin_quick_access_column" @click="toggle_create_bundle" key="lxa_create_bundle" @mouseover="admin_quick_access_hover = true" @mouseleave="admin_quick_access_hover = false" :class="admin_quick_access_hover ? 'lxa_create_bundle_box':''">
                  <div class="row">
                  <div class="col-12 quick_access_container">
                    <i class="fad fa-percentage lxa_icon_size_large"></i>
                  </div>
                  <div class="col-12 text_align_center line_height_20 font_barlow_semi_condensed_medium_18">{{$t('quick_access.create_bundle')}}</div>
                </div>
                </div> -->
                <div class="admin_quick_access_column" data-toggle="modal" data-target="#user_testimonial_modal" key="lxa_testimonials" @mouseover="admin_quick_access_hover = true" @mouseleave="admin_quick_access_hover = false" :class="admin_quick_access_hover ? 'lxa_testimonials_box':''">
                 <div class="row">
                  <div class="col-12 quick_access_container" :key="admin_quick_access_hover">
                    <i class="fad fa-comment-alt-dots mx-auto d-block lxa_icon_size_large" :class="admin_quick_access_hover ? '':'lxa_text_color_04'"></i>
                  </div>
                  <div class="col-12 text_align_center line_height_20 font_barlow_semi_condensed_medium_18">{{$t('quick_access.create_testimonial')}}</div>
                </div>
                </div>
                <div class="admin_quick_access_column" v-on:click="redirectOther()" key="lxa_others" @mouseover="admin_quick_access_hover = true" @mouseleave="admin_quick_access_hover = false" :class="admin_quick_access_hover ? 'lxa_others_box':''">
                  <div class="row">
                  <div class="col-12 quick_access_container" :key="admin_quick_access_hover">
                    <i class="fad fa-users-medical mx-auto d-block lxa_icon_size_large" :class="admin_quick_access_hover ? '':'lxa_text_color_05'"></i>
                  </div>
                  <div class="col-12 text_align_center line_height_20 font_barlow_semi_condensed_medium_18">{{$t('quick_access.others')}}</div>
                </div>
                </div>
              </div>
            </div>
            <div v-if="showCreateCourse" class="create_course_wrapper">
              <CreateCourseContent :logged_in="logged_in" :course_data="course_data"/>
            </div>
            <div v-if="showCreateBundle" class="create_course_wrapper">
              <CreateBundleContent :logged_in="logged_in"/>
            </div>
            <user-testimonial :logged_in="logged_in" :site_admin="site_admin"> </user-testimonial>
          </div>`,

  data: function() {
    return {
      showCreateCourse: false,
      showCreateBundle: false,
      course_data: {},
      admin_quick_access_hover: false,
    }
  },
  props: {
    logged_in: Boolean,
    site_admin: Boolean
  },
  methods: {
    toggle_create_course() {
      // if (this.showCreateCourse) {
      //   this.showCreateCourse = !this.showCreateCourse;
      // }
      // else {
      //   this.$http.get('/users/courses/new').then(function(response) {
      //     this.course_data = response.body;
      //     this.showCreateCourse = !this.showCreateCourse;
      //   });
      // }
      window.location='/admin/courses?pre_page=store_design' 
    },
    toggle_create_bundle() {
      this.showCreateBundle = !this.showCreateBundle
    },
    redirectReport: function() {
      window.location.href = this.$root.report_url;
    },
    redirectOther: function() {
      window.location.href = this.$root.others_url;
    },
    redirectUser: function() {
      window.location.href = this.$root.users_url;
    },
  }
});

Vue.component('store-design',{
  template: `<div class="col-sm-12 px-0">
              <div class="lxa_main_title_light testing">
                {{this.$root.header_details[0].text}}
              </div>
              <div class="container-fluid margin_top_15 padding_left_0 padding_right_0">
                <div class="store_design_row">
                  <div class="store_design_column display_none">
                    <div class="container-fluid" v-on:click = "openTemplates">
                      <div class="row quick_access_container" style="text-align: center">
                        <i class="fad fa-palette" style="width: 50px;height: 50px;color:#0000FF"></i>
                      </div>
                      <div class="text_align_center line_height_20 font_barlow_semi_condensed_medium_18">{{$t('store_design.layout')}}</div>
                    </div>
                  </div>
                  <div class="store_design_column display_none">
                    <div class="row quick_access_container text_align_center">
                      <i class="fad fa-th" style="width: 50px;height: 50px;color:#008000"></i>
                    </div>
                    <div class="text_align_center line_height_20 font_barlow_semi_condensed_medium_18">{{$t('store_design.widgets')}}</div>
                  </div>

                  <div class="store_design_column " data-toggle="modal" data-target="#manage_header_modal" key="manage_header" @mouseover="store_design_hover = true" @mouseleave="store_design_hover = false" :class="store_design_hover ? 'lxa_manage_header':''">
                    <div class="row quick_access_container text_align_center m-0 mb-3 mt-5" >
                      <i class="fad fa-arrow-to-top mx-auto d-block lxa_text_color_04 lxa_icon_size_large"></i>
                    </div>
                    <div class="text_align_center line_height_20 font_barlow_semi_condensed_medium_18">{{$t('store_design.manage_header')}}</div>
                  </div>
                  <div class="store_design_column" data-toggle="modal" data-target="#manage_footer_modal" key="manage_footer" @mouseover="store_design_hover = true" @mouseleave="store_design_hover = false" :class="store_design_hover ? 'lxa_manage_footer':''">
                    <div class="row quick_access_container text_align_center m-0 mb-3 mt-5" >
                      <i class="fad fa-arrow-to-bottom mx-auto d-block lxa_text_color_06 lxa_icon_size_large"></i>
                    </div>
                    <div class="text_align_center line_height_20 font_barlow_semi_condensed_medium_18">{{$t('store_design.manage_footer')}}</div>
                  </div>
                  <div class="store_design_column display_none">
                    <div class="row quick_access_container text_align_center" >
                      <i class="fad fa-folders lxa_text_color_06 lxa_icon_size_large"></i>
                    </div>
                    <div class="text_align_center line_height_20 font_barlow_semi_condensed_medium_18">Manage assets</div>
                  </div>
                </div>
              </div>
              <div v-if="showTemplates">
                <template-store :components="components"  :layout="layout" :show_cart="show_cart"></template-store>
              </div>
              <manage-header> </manage-header>
              <manage-footer> </manage-footer>
            </div>`,
  props:{
    layout: String,
    components: Array,
    show_cart: Boolean,
  },
  data: function(){
    return {
      showTemplates: false,
      store_design_hover: false
    }
  },
  methods:{
    openTemplates: function(){
      this.showTemplates = true;
    }
  }
});

Vue.component('footer-layout',{
  template:`<div :class='[{"row footerBox":(logged_in)},"pr-3"]'>
              <div class="row m-0 col-12" v-if="active_content.length == 0">
                <div class ="col-sm-4">
                  <img class ="footerLogo" src="/images/new_ui/Learnexa_Logo_Reversed.svg">
                </div>
                <div class ="col-sm-4">
                  <p class ="font_barlow_semi_condensed_medium_18 white">Security & Privacy</p>
                  <a class ="font_barlow_semi_condensed_light_16 white" @click="openPrivacyPolicy()">Privacy Policy</a></br>
                  <a class ="font_barlow_semi_condensed_light_16 white" @click="openTermsOfService()">Terms of Service</a></br>
                </div>
                <div class="col-sm-4">
                  <p class ="font_barlow_semi_condensed_medium_18 white">
                  Get Your Learning Cloud</br></p>
                  <p class ="font_barlow_semi_condensed_light_16 white">
                  Only Learnexa Learning Cloud gives you everything </br>
                  you need to create, share and track online courses,</br>
                  quizzes and certification in one easy to use platform.</br>
                  </p>
                </div>
              </div>
              <div v-else :class="getColumnClass">
                <div class="column_data" v-for="column in active_content">
                  <div class="row m-0 mb-2" v-if="column.text.length != 0">
                    <span class="col-12 p-0 mb-1 font_barlow_semi_condensed_medium_18 white"> {{ column.text }} </span>
                  </div>

                  <div class="row m-0">
                    <span v-html="column.content" class="font_barlow_semi_condensed_light_16 white"> </span>
                  </div>
                </div>
              </div>
              <div class="row m-0 col-12 mt-2 font_barlow_semi_condensed_light_16 white">
                <div class="col-6 p-0">
                  <span>{{ $t('footer.copyright', {year: year}) }}</span>
                </div>
                <div class="col-6 p-0 text_align_right">
                  <span> {{ $t('footer.version', {version: version}) }} </span>
                </div>
              </div>
              <span id="fetch_footer_data" @click="fetchDetails()" class="display_none"> Fetch Data</span>
            </div>`,
  data() {
    return {
      active_content: [],
      no_of_columns: 1,
      year: '',
      version: ''
    }
  },
  props: {
    logged_in: Boolean
  },
  mounted() {
    this.fetchDetails()
  },
  computed: {
    getColumnClass() {
      return "footer_columns w-100 col-12 grid_"+this.no_of_columns
    }
  },
  methods: {
    openPrivacyPolicy(){
      window.location ="/privacy"
    },
    openTermsOfService(){
      window.location ="/terms-of-service"
    },
    fetchDetails() {
      this.$http.get('/get_footer_details').then(response => {
        this.active_content = response.body.footer_details
        this.no_of_columns = response.body.no_of_columns
        this.year = response.body.year
        this.version = response.body.version
      });
    },
  }
});

Vue.component('new-cart-list', {
  template: `<div class="row cart_details margin_0 col-12 m-0" v-if="show_cart">
              <div class="col-sm-12 col-md-12 col-lg-9 pl-0">
                <CartListing v-bind:cart_items="cart_items" :cart_time="cart_time"/>
                <BillingDetails v-bind:billing_address="billing_address" :show_billing_address="show_billing_address"/>
                <PaymentDetails v-bind:show_payment_details="show_payment_details"/>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-3 padding_0">
                <CartSummary v-bind:order_summary="order_summary" :coupon_details="coupon_details"/>
              </div>
            </div>`,
  props: {
    show_cart: Boolean,
    site_admin: Boolean
  },
  data: function(){
    return {
      cart_items: [],
      order_summary: {},
      billing_address: {},
      show_billing_address: false,
      show_payment_details: false,
      cart_time: 0,
      coupon_details: {},
    }
  },
  mounted: function() {
    var self = this;
    // self.$root.show_cart = !self.$root.show_cart
    self.$http.get('/home/cart_info').then(function(response) {
      self.cart_items = JSON.parse(response.body['purchases'])['cart_items']
      self.order_summary = JSON.parse(response.body['purchases'])['order_summary_details']
      self.billing_address = response.body['billing_address']
      self.cart_time = response.body['cart_time']
      self.coupon_details = JSON.parse(response.body['purchases'])['coupon_code_details']
    });
  },
  beforeDestroy() {
    EventBus.$off('toogleCart');
  },
  methods: {
    deleteItem(item) {
      this.$http.delete('/cart.json',{body: {id : item.id}}).then(response => {
        this.cart_items = response.body['cart_items']
        this.order_summary = response.body['order_summary_details']
        EventBus.$emit("updateCatalog")
      });
    },
    processToPay(){
      this.$http.post('/orders.json').then(response => {
        window.location.href = response.body.wepay_redirect_uri
      });
    },
  }
});

Vue.component('inbox-layout', {
  template: `<div class="modal" id="inbox_modal" role="dialog">
                <div class="modal-dialog modal-xl">
                  <div class="modal-content">
                    <div class="inbox_modal">
                      <div style="float: right;">
                        <p @click="new_message = true" class="new_message">  <i class="fad fa-plus"></i> <span> {{ $t('inbox.new_message')}} </span></p>
                      </div>
                      <div class="margin_top_10"> <p style="color: #5EC96B; font-size: 24px;"> {{ $t('inbox.all_messages')}} </p></div>
                      <p class="light_box_close" data-dismiss="modal"><i class="fad fa-times lxa_text_color_08 lxa_font_size_06 cursor_pointer"></i></p>

                      <div class="row" style="margin: 0px; margin-top: 30px; overflow: scroll; height: 55px;">
                        <div class="message_tabs col-md-8 paddingNil">
                          <span @click="active_tab = 'inbox'" :class='[{"active_inbox_tab": active_tab == "inbox"}]'> {{ $t('inbox.inbox_tab')}} </span>
                          <span @click="active_tab = 'sent'" :class='[{"active_inbox_tab": active_tab == "sent"}]'> {{ $t('inbox.sent_tab')}} </span>
                        </div>
                        <div class="inbox_actions col-md-4 paddingNil">
                          <a @click="inboxActions('mark_as_unread')" style="margin-right: 10px;"> 
                            <i class="fad fa-envelope" style="color: #FFA500"></i> {{ $t('inbox.mark_unread')}}
                          </a>
                          <a @click="inboxActions('mark_as_read')" style="margin-right: 10px;"> 
                            <i class="fad fa-envelope-open" style="color: #008000"></i> {{ $t('inbox.mark_read')}}  
                          </a>
                          <a @click="inboxActions('delete_message')" style="margin-right: 10px;"> 
                            <i class="fad fa-trash-alt" style="color: #FF0000"></i> {{ $t('inbox.delete_selected')}} 
                          </a>
                          <a @click="inbox_action = !inbox_action" style="position: absolute; float: right; right: 25px; color: #FF0000">
                            <i class="fad fa-chevron-down"></i>
                          </a>
                          <a @click="selectAllCheckbox()" style="position: absolute; float: right; right: 15px; ">
                            <input type="checkbox" id="selectAll" />
                          </a>
                        </div>
                      </div>

                      <div v-if="inbox_action" class="inbox_action">
                        <a @click="selectCheckbox('all')"> All </a>
                        <a @click="selectCheckbox('read')"> Read </a>
                        <a @click="selectCheckbox('unread')"> Unread </a>
                      </div>

                      <div class="inbox_container paddingNil" style="overflow: scroll; height: 65%;">
                        <div v-for="sent_message in messages" :key="sent_message.id" :class='[{"unread": !sent_message.is_read, "read": sent_message.is_read},"row"]' :id=" 'inbox_' + sent_message.id">
                          <div class="col-md-9 paddingNil">
                            <p style="font-weight: bold;"> {{ sent_message.user }} </p>
                            <p style="color: #808080"> {{ sent_message.body }} </p>
                          </div>
                          <div class="col-md-2 paddingNil">
                            <span class="message_time" style="font-size: 16px;"> {{ sent_message.created_at }} </span>
                          </div>
                          <div class="col-md-1 paddingNil">
                            <p style="float: right;right: 15px;position: absolute;"> 
                              <span data-toggle="tooltip" data-placement="top" title="Delete" @click="deleteMessage(sent_message)"> <i class="fad fa-trash-alt" style="color: #FF0000"></i> </span>
                              <span> <input type="checkbox" :id="sent_message.id" name="conversation" :value="sent_message.id" @click="hightlightRow(sent_message)"> </span>
                            </p>
                          </div>
                          <hr />
                        </div>
                      </div>

                      <div class="load_more" v-if="messages.length >= 5"> <span @click="loadmoreRecords"> {{ $t('inbox.load_more')}} </span> </div>

                      <div class="new_message_form" v-if="new_message">
                        <div class="row" style="margin-top: 10px;">
                          <div class="col-md-3">
                            <label> To :</label>
                          </div>
                          <div class="col-md-9">
                            <select2 v-model="selected_users" :options="user_lists" :settings="{ multiple: true , width: '100%'}"/>
                          </div>
                        </div>
                        <div class="row" style="margin-top: 10px;">
                          <div class="col-md-3">
                            <label> Subject :</label>
                          </div>
                          <div class="col-md-9">
                            <input type="text" v-model="new_message_subject" style="width: 100%;"/>
                          </div>
                        </div>
                        <div class="row" style="margin-top: 10px;">
                          <div class="col-md-3">
                            <label> Body : </label>
                          </div>
                          <div class="col-md-9">
                            <textarea v-model="new_message_body" style="width: 100%;"/>
                          </div>
                        </div>
                        <div class="row" style="margin-top: 10px;">
                          <div class="col-md-6">
                            <a @click="createNewMessage" style="margin-left: 10px;color: #FFFFFF;background: #FF0000;padding: 10px;border-radius: 5px;"> Send </a>
                            <a @click="closeMessage" style="margin-left: 10px;color: #FFFFFF;background: #FF0000;padding: 10px;border-radius: 5px;"> Close </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 
            </div>`,
  data: function() {
    return {
      messages: [],
      active_tab: 'inbox',
      new_message_subject: '',
      new_message_body: '',
      user_lists: [],
      selected_users: [],
      new_message: false,
      current_page: 1,
      inbox_action: false
    }
  },
  mounted() {
    var self = this;
    this.getUserMessages();
  },
  beforeDestroy() {
    EventBus.$off('toogleInbox');
  },
  components: {
    'select2': Select2
  },
  watch: {
    active_tab() {
      this.current_page = 1
      this.messages = []
      this.getUserMessages(this.active_tab)
    }
  },
  methods: {
    createNewMessage() {
      var data = {}
      data['recipients'] = this.selected_users
      data['message'] = {}
      data['message']['subject'] = this.new_message_subject
      data['message']['body'] = this.new_message_body
      var _this = this;
      if (this.selected_users.length == 0 ) {
        this.$root.popUpMessage("Select Recipient email ids")
        return false
      }
      if ( this.new_message_subject != '' && this.new_message_body != '') {
        this.$http.post('/messages.json', data).then(function(response) {
          this.$root.popUpMessage("Message Sent !!")
          _this.getUserMessages(this.active_tab)
          _this.clearMessageContent()
        })
      } else {
        this.$root.popUpMessage("Enter Message Content")
      }
      
    },
    closeMessage() {
      this.$modal.show('dialog', {
        text: 'Do you really want to close?',
        buttons: [{
            title: 'Cancel',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Yes',
            handler: () => {
              this.clearMessageContent()
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    },
    clearMessageContent() {
      this.selected_users = []
      this.new_message_subject = ''
      this.new_message_body = ''
      this.new_message = false
    },
    getUserMessages(active_tab = 'inbox') {
      this.$http.get('/conversations.json?box='+active_tab+'&page='+this.current_page).then(function(response) {
        if (this.current_page == 1) {
          this.messages = response.body.messages
        } else {
          this.messages = this.messages.concat(response.body.messages)
        }
        this.user_lists = response.body.user_lists
      });
    },
    mySelectEvent({id, text}){
    },
    inboxActions(action) {
      var checked_ids = []
      var markedCheckbox = document.querySelectorAll('input[type="checkbox"]:checked');
      for (var checkbox of markedCheckbox) {
        checked_ids.push(checkbox.value)
      }
      var data = {}
      data["checked_ids"] = checked_ids;
      data["inbox_action"] = action;
      if (checked_ids.length != 0) {
        if(action !="delete_message" || (action=="delete_message" && confirm("Do you really want to delete?"))){
          var _this = this
          this.$http.post('/conversation_actions.json', data).then(function(response) {
            _this.getUserMessages(this.active_tab)
            this.$root.popUpMessage( response.body.message )
          });
        }
      } else {
        this.$root.popUpMessage("Select a conversation to proceed Further")
      }
    },
    selectCheckbox(type) {
      var checkboxes = document.querySelectorAll('.inbox_container input[type="checkbox"]');
      for (var checkbox of checkboxes) {
        checkbox.checked = false
      }
      if (type == 'unread') {
        checkboxes = document.querySelectorAll('.inbox_container .unread input[type="checkbox"]');
      } else if (type == 'read') {
        checkboxes = document.querySelectorAll('.inbox_container .read input[type="checkbox"]');
      }
      for (var checkbox of checkboxes) {
        checkbox.checked = true
        document.querySelectorAll('#selectAll')[0].checked = (type == 'all') ? true : false
      }
    },
    selectAllCheckbox() {
      var checkboxes = document.querySelectorAll('.inbox_container input[type="checkbox"]');
      for (var checkbox of checkboxes) {
        checkbox.checked = document.querySelectorAll('#selectAll')[0].checked
      }
    },
    deleteMessage(message) {
      if(confirm("Do you really want to delete?")){
        var _this = this
        this.$http.delete('/delete_message/'+message.id).then(function(response) {
          this.$root.popUpMessage("Deleted Successfully !!")
          _this.getUserMessages(this.active_tab)
        })
      }
    },
    hightlightRow(message) {
      document.querySelectorAll('#inbox_'+message.id)[0].classList.toggle('selected')
    },
    loadmoreRecords() {
      this.current_page = this.current_page + 1
      this.getUserMessages(this.active_tab);
    }
  }
})

Vue.component('filebox-lists', {
  template: `<div class="modal" id="user_filebox_modal" role="dialog">
              <div class="modal-xl">
                <div class="modal-content user_filebox height_800 primary_bgcolor">
                  <div class="d-flex align-items-center justify-content-end">
                    <div class="account_setting_heading lxa_text_color_06 lxa_font_size_08 lxa_light_font"> Filebox </div>
                    <div class="ml-auto d-flex align-items-center justify-content-end"> 
                     <div class="add_files_container">
                      <label class="add_file mb-0 mr-3 btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase cursor_pointer" for="file-upload-filebox" >  <i class="fad fa-plus"></i> Add Files </label>
                       <input id="file-upload-filebox" type="file" name="file" ref="files" @change="handleImageUpload" style="display: none;"/>
                     </div>                  
                    <div class="position-relative">
                    <sort-drop-down  :from_section="'filebox_items'" :show_sort="show_sort" :sorting_option="sorting_option" @selectCallBack="selected_sort" />
                   </div>
                   </div>
                  </div>
                  <p class="light_box_close" data-dismiss="modal"> <i data-toggle="tooltip" data-placement="top" title="Close" class="fad fa-times lxa_text_color_08 lxa_font_size_06 cursor_pointer"></i></p>
                  <div class="margin_top_15 lxa_font_size_04 filebox_listing text_align_center">
                  <div v-if="dropbox_items.length">
                    <div class="row mx-0" v-for="(item,index) in dropbox_items" :key="'filebox_'+item.id+index">
                      <div class="col-2 pr-2 p-0"> 
                        <img :src="item.download" class="lxa_filebox_thumbnail"/>
                      </div>
                      <div class="col-10 p-0 lxa_common_border_b1">
                        <div class="row mx-0 lxa_font_size_04 lxa_text_color_03 lxa_light_font">
                          <span class="col-12 px-0"> {{ item.filename }} &nbsp ({{ item.filesize }} Kb) </span>
                        </div>
                        <div class="row mx-0">
                          <span class="col-12 px-0 py-2 lxa_text_color_07 lxa_font_size_04 lxa_medium_font"> {{ item.title}} </span>
                        </div>
                        <div class="row mx-0">
                          <span class="col-sm-12 col-md-8 col-lg-8 px-0 pb-2 lxa_font_size_04 lxa_text_color_03 lxa_light_font"> Uploaded by: {{ item.by_user}} on {{ item.uploaded_at}}</span>
                          <span v-if="item.owner" class="col-sm-12 col-md-4 col-lg-4 px-0 pb-2 align_right">
                            <span data-toggle="tooltip" data-placement="bottom" title="Edit" class="cursor_pointer filebox_class" @click.prevent="editFile(item)"> <i class="fad fa-pencil-alt lxa_text_color_06 lxa_font_size_04"></i> </span>
                            <span data-toggle="tooltip" data-placement="bottom" title="Share" class="cursor_pointer pl-3 filebox_class" @click.prevent="shareFile(item)"> <i class="fad fa-share-alt lxa_text_color_06 lxa_font_size_04"></i> </span>
                            <a class="cursor_pointer pl-3" :href="item.download" download v-if="item.download != ''"> <i class="fad fa-download lxa_text_color_06 lxa_font_size_04"></i> </a>
                            <span data-toggle="tooltip" data-placement="bottom" title="Delete" class="cursor_pointer pl-3" @click.prevent="deleteFile(item)"> <i class="fad fa-trash-alt lxa_text_color_01 lxa_font_size_04"></i></span>
                          </span>
                          <span v-else class="col-sm-12 col-md-4 col-lg-4 px-0 pb-2 align_right">
                            <a class="cursor_pointer pl-3" :href="item.download" download v-if="item.download != ''"> <i class="fad fa-download lxa_text_color_06 lxa_font_size_04"></i> </a>
                          </span>
                        </div>
                      </div>
                      <div v-click-outside="outside" v-if="active_action == 'share' && active_item == item" class="p-3 lxa_border_radius lxa_bg_05 filebox_edit_share">
                        <div class="row mx-0 mt-1"> 
                          <span>Send your file to someone's filebox </span>
                        </div>
                        <div class="row mx-0 mt-2 font_size_12 lxa_text_color_03">
                          <div class="col-12 px-0 lxa_font_size_02 lxa_text_color_03"> Recipient email address(es): <span class="required"> </span></div>
                          <div class="col-12 px-0 lxa_font_size_02 lxa_text_color_03 mb-2"> (Seperate  multiple emails with space)</div>
                        </div>
                        <select2 v-model="selected_users" class="lxa_border_radius" :options="user_lists" :settings="{ multiple: true , width: '100%'}"/>
                        <div class="row mx-0 mt-2">
                          <div> Message </div>
                          <textarea rows="5" class="mt-2 p-2 primary_bgcolor lxa_border_radius h-100 w-100 border-0 txtarea-resize"> </textarea>
                        </div>
                        <div class="row mx-0 mt-3 float_right lxa_font_size_03">
                          <span class="btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase cursor_pointer mr-3" @click="cancelEdit"> Cancel </span>
                          <span class="btn lxa_bg_04 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase cursor_pointer" @click="shareCourse"> Send </span>
                        </div>
                      </div>
                      <div v-click-outside="outside" v-if="active_action == 'edit' && active_item == item" class="p-3 lxa_border_radius lxa_bg_05 filebox_edit_share">
                          <div class="row mx-0"> 
                            <span>Pick an item for this file </span>
                          </div>
                          <div class="row mx-0 mt-2 font_size_12 lxa_text_color_03">
                            <span> Event Name: <span class="required"> </span></span>
                          </div>
                          <div class="mt-3 lxa_border_radius primary_bgcolor w-100 p-3 lxa_filebox_crslist">
                            <div class="row mx-0 mb-2">
                              <span class="p-2 bg-white search_button col-sm-3 col-md-2 col-xl-1"><i class="fad fa-search lxa_text_color_03"></i></span>
                              <input type="text" v-model="search_course" placeholder="Pick from below" class="p-2 lxa_border_radius_trbr border-0 col-sm-9 col-md-10 col-xl-11">
                            </div>
                            <div class="mb-1" v-for='itemList in coursesList' :key="itemList.id">
                          <div :id="itemList.id +'wrapper'" :style="[(selectedCourse == itemList.title) ? {'color':'#3d8ac4'} : '']" class="learnexa-custom-radiobutton custom-controls custom-radio d-flex align-items-center justify-content-start">
                          <input :id="'filter_unique_id_' + itemList.id" :value='itemList.id' v-model='selectedCourse' name="selectedCourse" class="delivery custom-control-input lxa-bs-radiobtn" type="radio">
                          <label class="custom-control-label" :for=" 'filter_unique_id_' + itemList.id">
                            <i class="fad fa-circle  mr-1 lxa_icon_size lxa_text_color_03"></i>
                            <i class="fad fa-dot-circle  mr-1 lxa_icon_size lxa_text_color_06"></i>
                            <span>{{itemList.title}}</span>
                          </label>
                          </div>
                            </div>
                          </div>
                          <div class="row mx-0 mt-3">
                          <div class="col-12 px-0">
                          <div :id="item.id +'wrapper'" class="sharetype-custom-radiobutton custom-controls custom-radio d-flex align-items-center justify-content-start">
                          <input :id="'share_with_author_' + item.id" value="share_with_author" v-model='share_type' name="share_type" class="custom-control-input lxa-bs-radiobtn" type="radio">
                          <label class="custom-control-label" :for="'share_with_author_'+item.id">
                            <i class="fad fa-circle  mr-1 lxa_icon_size lxa_text_color_03"></i>
                            <i class="fad fa-dot-circle  mr-1 lxa_icon_size lxa_text_color_06"></i>
                            <span>Share with Author Only</span>
                          </label>
                          </div>
                          </div>
                          </div>
                          <div class="row mx-0 mt-1">
                          <div class="col-12 px-0">
                          <div :id="item.id +'wrapper'" class="sharetype-custom-radiobutton custom-controls custom-radio d-flex align-items-center justify-content-start">
                          <input :id="'share_with_author_and_participants_' + item.id" value="share_with_author_and_participants" v-model='share_type' name="share_type" class="custom-control-input lxa-bs-radiobtn" type="radio">
                          <label class="custom-control-label" :for="'share_with_author_and_participants_'+item.id">
                            <i class="fad fa-circle  mr-1 lxa_icon_size lxa_text_color_03"></i>
                            <i class="fad fa-dot-circle  mr-1 lxa_icon_size lxa_text_color_06"></i>
                            <span>Share with Author & Participants</span>
                          </label>
                          </div>
                          </div>
                          </div>
                          <div class="row mx-0 my-3 lxa_font_size_03">
                          <div class="col-12 px-0">
                          <div class="d-flex align-items-center justify-content-end">
                            <span class="btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase cursor_pointer mr-3" @click="cancelEdit"> Cancel </span>
                            <span class="btn lxa_bg_04 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase cursor_pointer" @click="updateCourse"> Send </span>
                          </div>
                          </div>
                          </div>
                        </div>
                    </div>
                  </div>
                   <div v-else>No Files to display</div>
                  </div>
                  <div class="row mx-0" v-if="show_load_more">
                    <div class="col-12 px-0 text-center mt-3">
                      <span class="load_more btn lxa_bg_01 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase cursor_pointer" @click="updatePage"> Load More </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>`,
  data() {
    return {
      dropbox_items: [],
      sort_value: 'recently_added',
      courses_list: [],
      selectedCourse: '',
      active_item: null,
      selected_users: [],
      active_action: '',
      search_course: null,
      show_sort: true,
      sorting_option: [
        {id:1,title: 'Recently Added', value: 'recently_added'},
        {id:2,title: 'Title', value: 'title'},
        {id:3,title: 'Owner', value: 'owner'},
        {id:4,title:'Course', value: 'course'}
      ],
      share_type: '',
      current_page: 1,
      total_pages: 1,
      show_load_more: false
    }
  },
  mounted() {
    this.fetchFiles()
  },
  components: {
    'select2': Select2
  },
  computed: {
    coursesList() {
      let courses_list = this.courses_list
      if (this.search_course != '' && this.search_course) {
        courses_list = courses_list.filter((item) => {
          return (item.title.toUpperCase().includes(this.search_course.toUpperCase()))
        })
      }
      return courses_list
    }
  },
  methods: {
    updatePage() {
      this.current_page = this.current_page + 1
      this.fetchFiles()
    },
    cancelEdit(){
      this.active_action = ''
      this.share_type = ''
      this.active_action = ''
    },
    selected_sort(value) {
      this.sort_value = value
      this.current_page = 1
      this.dropbox_items = []
      this.fetchFiles()
    },
    fetchFiles() {
      this.$http.get('/my_learnings/drop_boxes.json?order_by='+this.sort_value+'&page='+this.current_page).then(response => {
        if ( response.body.dropbox_items.length == 0) {
          this.current_page = 1
        } else {
          this.dropbox_items = this.dropbox_items.concat(response.body.dropbox_items) 
        }
        this.total_pages = response.body.total_pages 
        this.user_lists = response.body.user_lists
        this.show_load_more = (this.current_page < this.total_pages) ? true : false
      });
    },
    handleImageUpload() {
      if (this.$refs.files.files[0].size < 1048576) {
        const formData = new FormData()
        formData.append('myFile', this.$refs.files.files[0])
        this.$http.post('/my_learnings/drop_boxes', formData).then(response => {
          this.$root.popUpMessage("Uploaded Successfully !!")
          this.dropbox_items = response.body.dropbox_items
          this.current_page = 1
          this.show_load_more = (this.current_page < this.total_pages) ? true : false
        })
      } else {
       
        this.$root.popUpMessage('Larger than 1 MB')
      }
    },
    deleteFile(item) {
      this.$modal.show('dialog', {
        text: 'Are you sure you want to delete the file ?',
        buttons: [{
            title: 'Cancel',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Yes',
            handler: () => {
              this.$http.delete('/my_learnings/drop_boxes/'+item.id).then(response => {
                this.dropbox_items = response.body.dropbox_items
                this.show_load_more = response.body.show_load_more
                this.current_page = 1
                this.$modal.hide('dialog')
                this.$root.popUpMessage('Deleted File')
              });
            }
          }
        ]
      })
      
    },
    editFile(item) {
      this.share_type = ''
      this.active_item = item
      this.active_action = 'edit'
      this.$http.get('/my_learnings/drop_boxes/'+item.id+'/edit').then(response => {
        this.courses_list = response.body.courses
      })
    },
    shareFile(item) {
      this.active_item = item
      this.active_action = 'share'
    },
    updateCourse() {
      this.$http.put('/my_learnings/drop_boxes/'+this.active_item.id+'/'+this.selectedCourse).then(response => {
        this.dropbox_items = response.body.dropbox_items
        this.active_item = null
        this.active_action = ''
        this.$root.popUpMessage('Successfully Updated !!');
      })
    },
    shareCourse() {
      if (this.selected_users.length == 0) {
        this.$root.popUpMessage('Please select Recipient email');
      } else {
        var data = {id: this.active_item.id, selected_users: this.selected_users}
        this.$http.post('/my_learnings/drop_boxes/share_item', data).then(response => {
          this.dropbox_items = response.body.dropbox_items
          this.active_action = ''
          this.active_item = null
          this.selected_users = []
          this.$root.popUpMessage('Successfully shared files !!');
        })
      }
    },
    outside: function(e) {
      if((jQuery(e.target).closest('.filebox_edit_share').length < 1)&&(jQuery(e.target).closest('.filebox_class').length < 1)) {
        this.active_action = ''
        this.active_item = null
      }
    },
  }
});

Vue.component('header-more-option', {
  template: `<div class="line_height_32 font_barlow_semi_condensed_light_18 user_icon_menu">
              <!-- <div data-toggle="modal" data-target="#inbox_modal" class="user_icon_link">
                <i class="fad fa-envelope" style="color: #FFFF00"></i>
                <span class="cursor_pointer padding_left_10">Inbox</span>
              </div> -->
              <!-- <div class="user_icon_link" v-on:click="ShowBlog()">
                <i class="fad fa-blog" style="width: 16px;height: 16px;color:#00FFBF"></i>
                <span class="cursor_pointer padding_left_10">Blog</span>
            </div> -->
               <!--<div class="user_icon_link" v-on:click="ShowGroups()" data-toggle="modal" data-target="#groups_modal">
                <i class="fad fa-users" style="width: 16px;height: 16px;color:#0000FF"></i>
                <span class="padding_left_10">Groups</span>
              </div>-->
              <div v-if="site_admin" class="user_icon_link" v-on:click="redirectReport()">  
                <i class="fad fa-analytics" style="width: 16px;height: 16px;color:#FF0000"></i>
                <span class="lxa_section_title_light pl-2  cursor_pointer pr-4">Admin</span>
              </div>
              <div data-toggle="modal" data-target="#user_account_modal" class="user_icon_link">
                <i class="fad fa-cog" style="width: 16px;height: 16px;color:#008000"></i>
                <span class="cursor_pointer padding_left_10">Settings</span>
              </div>
              <!-- <div v-on:click="SignOut()"  class="user_icon_link">
                <i class="fad fa-power-off" style="width: 16px;height: 16px;color:#FF0000"></i>
                <span class="padding_left_10 cursor_pointer">Sign Out</span>
              </div> -->
            </div>`,
  data: function() {
    return {
    }
  },
  props: {
    site_admin: Boolean
  },
  methods: {
    ShowGroups: function() {
      this.$parent.show_groups = true
    },
    ShowBlog: function() {
      this.$root.show_course_details = false;
      this.$root.show_blog_items = true;
    },
    SignOut: function (event) {
      var csrfToken = encodeURIComponent(jQuery('meta[name="csrf-token"]').attr('content'));
      jQuery.ajax({
        type: 'DELETE',
        url: "/users/sign_out?"+"&single_page=1&authenticity_token="+csrfToken,
        success: function(response) {}
      });
    },  
    toggleAccountSetting() {
      this.$parent.toggleAccountSetting()
    },
    redirectReport: function() {
      window.location.href = this.$root.report_url;
    },
  }
});



Vue.component('user-profile', {
  template: `<div class="modal" id="user_profile_modal" role="dialog">
                <div class="modal-xl">
                  <div class="modal-content user_profile">
                    <div class="mb-3"> <h4 class="user_profile_heading lxa_text_color_09 lxa_section_title_light lxa_font_size_08"> {{ $t('user_profile.heading')}} </h4> </div>
                    <p class="light_box_close" data-dismiss="modal" @click.prevent="closeBox" data-toggle="tooltip" data-placement="top" title="Close"> <i class="fad fa-times lxa_text_color_08 lxa_font_size_06 cursor_pointer"></i></p>
                    <div class="lxa_menu_container d-flex align-items-center justify-content-start">
                      <span v-for="menu in menu_items" :class="[{'menu_active': menu == active_menu},'menu']" @click="toggleMenu(menu)"> {{ menu}}</span>
                    </div>

                    <user-profile-details v-if="active_menu == menu_items[0]" :personal_information="personal_information" :achievements="achievements" :user_skills="user_skills" :interest_options="interest_options" :selected_interest="selected_interest"> </user-profile-details>
                    <user-profile-preferences v-if="active_menu == menu_items[1]" :user_preferences="user_preferences" :all_time_zones="all_time_zones" :selected_zone="selected_zone" :all_date_formats="all_date_formats" :selected_date="selected_date"> </user-profile-preferences>
                    <user-profile-address v-if="false"> </user-profile-address>
                    <user-profile-orders v-if="active_menu == menu_items[2]" :items="order_details" :order_page="order_page" :show_load_more="show_load_more"> </user-profile-orders>
                    <analytics v-if="active_menu == menu_items[3]" :analytics="analytics" > </analytics>
                  </div>
                </div>
            </div>`,
  data() {
    return {
      active_menu: 'Details',
      menu_items: ['Details', 'Preferences', 'Orders', 'Analytics'],
      personal_information: {},
      achievements: [],
      following_users: [],
      recent_contents: [],
      recommendations_given: [],
      top_rated: [],
      recent_activity_list: [],
      activity_count: 0,
      activity_page: 1,
      more_recommendation: false,
      more_comment: false,
      more_upcoming_item: false,
      more_recent_content: false,
      more_top_rated: false,
      show_edit_profile: false,
      show_edit_description: false,
      show_events: false,
      user_event_url: '',
      select_users_event_url: '',
      save_event_selections_url: '',
      sort_upcoming_items_url: '',
      upcoming_items: [],
      users_comments: []  ,
      order_details: [],
      order_page: 1,
      user_preferences: [],
      user_skills: [],
      interest_options: [],
      selected_interest: [],
      all_time_zones: [],
      selected_zone: [],
      all_date_formats: [],
      selected_date: [],
      data_altered: false,
      show_load_more: false,
      analytics: {}
    }
  },
  mounted() {
    this.loadUserData()
  },
  watch: {
    more_recommendation(){
      this.loadUserData()
    },
    more_comment(){
      this.loadUserData()
    },
    more_upcoming_item(){
      this.loadUserData()
    },       
    more_recent_content(){
      this.loadUserData()
    },
    more_top_rated() {
      this.loadUserData()
    },
    order_page() {
      this.loadUserData();
    }
  },
  methods: {
    closeBox() {
      if (this.data_altered) {
        this.toggleMenu('Details')
      } else {
        $("#user_profile_modal .light_box_close").click()
        this.active_menu = 'Details'
      }
    },
    toggleMenu(menu) {
      if (this.data_altered) {
        this.$modal.show('dialog', {
          text: 'Changes have been made to the Profile Section. Do you want to save the changes?',
          buttons: [{
              title: 'Cancel',
              handler: () => {
                if ( this.active_menu == "Details") {
                  this.$children[0].$children[0].show_edit_achievement = false
                  this.$children[0].$children[1].addNewSkill = false
                  this.$children[0].$children[2].addNewInterest = false
                }
                this.loadUserData()
                this.data_altered = false
                this.$modal.hide('dialog')
              }
            },
            {
              title: 'Yes',
              handler: () => {
                if ( this.active_menu == "Details") {
                  if (this.$children[0].$children[0].show_edit_achievement == true) {
                    this.$children[0].$children[0].save_selection()
                  } else if (this.$children[0].$children[1].addNewSkill == true) {
                    this.$children[0].$children[1].saveNewSkill()
                  } else if (this.$children[0].$children[2].addNewInterest == true) {
                    this.$children[0].$children[2].saveInterest()
                  } else {
                    this.$children[0].saveChanges()  
                  }
                } else {
                  this.$children[0].saveChanges()
                }
                this.$modal.hide('dialog')
              }
            }
          ]
        })
      } else {
        this.active_menu = menu
      }
    },
    clickCallback(page) {
      this.activity_page = page
      this.loadUserData()
    },
    loadUserData() {
      var analytics_date = ''
      if (this.active_menu == 'Analytics') {
        analytics_date = this.$children[0].analytics_start_date + '//' + this.$children[0].analytics_end_date
      }
      this.$http.get('/user_profile?page='+this.activity_page+'&analytics_date='+analytics_date+'&more_recommendation='+this.more_recommendation+'&more_comment='+this.more_comment+'&more_upcoming_item='+this.more_upcoming_item+'&more_recent_content='+this.more_recent_content+'&more_top_rated='+this.more_top_rated+'&order_page='+this.order_page).then(function(response) {
        this.personal_information = response.body.personal_information
        this.achievements = response.body.achievements
        this.following_users = response.body.following_users
        this.recent_contents = response.body.recent_contents
        this.recommendations_given = response.body.recommendations_given
        this.top_rated = response.body.top_rated
        this.recent_activity_list = response.body.recent_activity_list
        this.activity_count = response.body.activity_count
        this.user_event_url = response.body.user_event_url
        this.select_users_event_url = response.body.select_users_event_url
        this.save_event_selections_url = response.body.save_event_selections_url
        this.show_events = true;
        this.upcoming_items = response.body.upcoming_items
        this.sort_upcoming_items_url = response.body.sort_upcoming_items_url
        this.users_comments = response.body.users_comments  
        this.order_details = this.order_details.concat(response.body.orders)
        this.user_preferences = response.body.user_preferences  
        this.user_skills = response.body.user_skills  
        this.interest_options = response.body.interest_options  
        this.selected_interest = response.body.selected_interest  
        this.all_time_zones = response.body.all_time_zones  
        this.all_date_formats = response.body.all_date_formats  
        this.selected_date = response.body.selected_date  
        this.selected_zone = response.body.selected_zone
        this.show_load_more = response.body.show_load_more
        this.analytics = response.body.analytics
      });
    }
  }
});

Vue.component('profile-edit-section', {
  template: `<div>              
              <form id="profile_edit_form" class="padding_10">
                <div class="edit_profile_details font_barlow_semi_condensed_light_14">
                  <div class="col-sm-12 padding_0">
                    <div class="col-sm-4 padding_0 margin_bottom_4">First Name</div>
                    <div class="form_field margin_bottom_11 col-sm-8 padding_0">
                      <div class="input_text">
                        <input id="first_name" class="width_100_percent" v-validate="'required'" data-vv-validate-on="none" v-model="first_name" name="user[firstname]" size="30" type="text">
                        <div class="error_container">
                          <span class ="font_open_sans_regular_11_red" v-show="errors.has('firstname')">First Name can't be blank</span>
                        </div>                            
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 padding_0"> 
                    <div class="col-sm-4 padding_0 margin_bottom_4">Last Name</div>                       
                    <div class="form_field margin_bottom_11 col-sm-8 padding_0">
                      <div class="input_text">
                        <input id="last_name" class="width_100_percent" v-validate="'required'" data-vv-validate-on="none" v-model="last_name" name="user[lastname]" size="30" type="text">
                        <div class="error_container">
                          <span class ="font_open_sans_regular_11_red" v-show="errors.has('lastname')">Last Name can't be blank</span>
                        </div>                              
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 padding_0"> 
                    <div class="col-sm-4 padding_0 margin_bottom_4">Title</div>                       
                    <div class="form_field margin_bottom_11 col-sm-8 padding_0">
                      <div class="input_text">
                        <input id="title" class="width_100_percent" v-model="title" name="user[designation]" size="30" type="text">                              
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 padding_0"> 
                    <div class="col-sm-4 padding_0 margin_bottom_4">Company</div>                       
                    <div class="form_field margin_bottom_11 col-sm-8 padding_0">
                      <div class="input_text">
                        <input id="company" class="width_100_percent" v-model="company" name="user[company_name]" size="30" type="text">                              
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 padding_0"> 
                    <div class="col-sm-4 padding_0 margin_bottom_4">Email</div>                       
                    <div class="form_field margin_bottom_11 col-sm-8 padding_0">
                      <div class="input_text">
                        <input id="email" class="width_100_percent" v-validate="'required'" data-vv-validate-on="none" v-model="email" name="user[email]" size="30" type="text">
                        <div class="error_container">
                          <span class ="font_open_sans_regular_11_red" v-show="errors.has('email')">Email can't be blank</span>
                        </div>                              
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 padding_0"> 
                    <div class="col-sm-4 padding_0 margin_bottom_4">Phone</div>                       
                    <div class="form_field margin_bottom_11 col-sm-8 padding_0">
                      <div class="input_text">
                        <input id="phone_no" class="width_100_percent" v-model="phone_no" name="user[phone_number]" size="30" type="text">                              
                      </div>
                    </div>
                  </div>
                  <div class="col=col-sm-12 padding_0">                                                                                                
                    <div class="col-sm-4 padding_0 quarter_margin_top float_left margin_bottom_4">
                      <div class="column font_barlow_semi_condensed_light_14">Logo:</div>
                    </div>
                    <div class="col-sm-8 padding_0 quarter_margin_top">
                      <div class="column align_left" id="logo_field">
                        <div v-if="logo_image_url">
                          <div class="logo_max_width">{{logo_image_url}}</div>
                        </div>
                        <div v-else class="font_barlow_semi_condensed_light_14"> None </div> 
                      </div>  
                    </div>
                  </div>      
                  <div class="col-sm-12 padding_0" id="group_logo_field">
                      <div v-if="logo_image_url" class="upload_link" v-on:click="uploadLogoImage()">Change logo</div>
                      <div v-else class="upload_link font_barlow_semi_condensed_light_14" v-on:click="uploadLogoImage()">Upload logo</div>
                      <input type='file' ref="inputFile" accept="image/*" id="getLogoImage" @change="uploadedImageFile" style="display: none"/>
                  </div>
                  <div id="image_upload_help_text" class="font_barlow_semi_condensed_light_10">Upload square image for better looks.
                  </div>
                </div>    
              </form>  
              <div class="col-sm-12 padding_0 margin_top_15">
                <div class="col-sm-6"></div>
                <div class="text_align_right col-sm-3 padding_0">
                  <button type="button" class="btn btn-secondary font_barlow_semi_condensed_light_14" v-on:click="cancelProfile()">Cancel</button>
                </div>
                <div class="text_align_right col-sm-3 padding_0 save_button">
                  <button type="submit" class="btn btn-success save margin_bottom_3 font_barlow_semi_condensed_light_14" v-on:click="saveProfile()">Save</button>  
                </div>
              </div>                        
            </div>`,
  data: function() {
    return {
      first_name: this.personal_information.user_name,
      last_name: this.personal_information.user_lastname,
      title: this.personal_information.designation,
      company: this.personal_information.company_name,
      email: this.personal_information.email,
      phone_no: this.personal_information.phone_number,
      logo_image_url: this.personal_information.image_url,
      update_user_url: this.personal_information.update_user_url
    }
  },
  props: {
    personal_information: Object
  },
  methods: {
    uploadLogoImage: function() {    
      document.getElementById('getLogoImage').click();
    },
    uploadedImageFile: function() {
      this.inputLogo = this.$refs.inputFile.files[0];
      this.formData = new FormData();
      this.formData.append('logo', this.inputLogo);
      this.logo_image_url = this.inputLogo.name
    },
    cancelProfile: function() {  
      this.$parent.show_edit_profile = false;
    },
    saveProfile: function() {
      this.$validator.validateAll().then(result => {
        if (result) {
          var params = '.json?&'+jQuery('#profile_edit_form').serialize()+'&update_section=profile_personal_info'
          this.$http.put(this.update_user_url+ params, this.formData).then(function(response) {
            this.$parent.personal_information = response.body;
            this.$parent.show_edit_profile = false;
          });
        }
      });         
    },              
  }
});

Vue.component('description-edit-section', {
  template: `<div>
              <div class="col-sm-12 padding_0">
                <textarea maxlength="300" name = "user[description]" class="profile_description" v-model="description"></textarea>
              </div>   
              <div class="col-sm-12 padding_0 margin_top_15">
                <div class="col-sm-6"></div>
                <div class="text_align_right col-sm-5 padding_0 cancel_button">
                  <button type="button" class="btn btn-secondary font_barlow_semi_condensed_light_14" v-on:click="cancelDescription()">Cancel</button>
                </div>
                <div class="text_align_right col-sm-1 padding_0 padding_left_23 save_button">
                  <button type="submit" class="btn btn-success save margin_bottom_3 font_barlow_semi_condensed_light_14" v-on:click="saveDescription()">Save</button>  
                </div>
              </div>                        
            </div>`,
  data: function() {
    return {
      description: this.personal_information.user_description,
      update_user_url: this.personal_information.update_user_url
    }
  },
  props: {
    personal_information: Object
  },
  mounted: function() {
    tinymce.init({
      selector: 'textarea.profile_description',
      height: 250,
      width: 580,
      menubar: false,
      insertdatetime_formats: ["%H:%M:%S", "%Y-%m-%d", "%I:%M:%S %p", "%D"],
      charmap_append: [
        [0x2600, 'sun'],
        [0x2601, 'cloud']
      ],
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount'
      ],
      toolbar1: 'undo redo | fontselect | fontsizeselect |' +
      'bold italic underline backcolor charmap ' ,
      toolbar2:'alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ' +
      'image link media table removeformat | help ',
      content_css: '//www.tiny.cloud/css/codepen.min.css'
    });
  },  
  methods: {
    cancelDescription: function() {  
      this.$parent.show_edit_description = false;
    },
    saveDescription: function() {  
      var message = tinymce.activeEditor.getContent({format: 'text'});
      var params = '.json?&'+'&user[description]='+message+'&update_section=profile_description'
      this.$http.put(this.update_user_url+ params).then(function(response) {
        this.$parent.personal_information = response.body;
        this.$parent.show_edit_description = false;
      });      
    }                 
  }
});

Vue.component('event-edit-section', {
  template: `<div>
              <div class="custom_popover edit_event" id="event_lists">
                <div class="col-sm-12 padding_10 event_search">
                  <input type="text" id="search_box" class="form-control input-sm" placeholder="Search Events" v-on:keyup="search_events"/>
                  <img src="/images/single_page/search.png" class="search_event_icon"></img>
                  <img src="/images/clear.png" id="reset_search" v-if="clear_search" v-on:click="clear_search_data"></img>
                </div>
                <div class="col-sm-12 padding_10">
                  <label class="padding_left_14 margin_top_7">Selected Events</label>
                  <br/>
                </div>
                <ul v-if="selected_events_sorted_Array.length != 0" id="available_event_list" class="padding_10 list_events">
                  <li v-bind:id="'products_list' + item.id" v-on:click="unselect_event($event,item,index)" v-for="(item,index) in selected_events_sorted_Array" v-bind:key="item.id" class="form-check-label checkbox">      
                    <input v-bind:id="'checkbox_' + item.id" type="checkbox" class="form-check-input products_list margin_left_0">
                    <span v-bind:id="'tick_id' + item.id" class="selected_products_tick_span"></span>
                    <label v-bind:id="'label' + item.id" class="available_items_select_label products_list padding_left_5">{{item.name}}</label>          
                  </li>
                </ul>
                <div class="col-sm-12 padding_10">
                  <label class="announcement_label padding_left_14 margin_top_5">Available Events</label>
                </div>
                <label v-if="available_events_sorted_Array.length == 0" class="announcement_label padding_10">{{$t('common.none')}}</label>
                <ul v-if="available_events_sorted_Array.length != 0" id="available_event_list" class="padding_10">
                  <li v-bind:id="'products_list' + item.id" v-on:click="select_event($event,item,index)" v-for="(item,index) in available_events_sorted_Array" v-bind:key="item.id" class="form-check-label product_checkbox checkbox products_list margin_top_10">
                    <input v-bind:id="'checkbox_' + item.id" type="checkbox" class="form-check-input products_list margin_left_0">
                    <label v-bind:id="'label_' + item.id" class="available_items_select_label products_list">{{item.name}}</label>
                  </li>
                </ul>
                <div class="col-sm-12 padding_0 margin_top_15">
                  <div class="col-sm-3 padding_10 cancel_button">
                    <button type="button" class="btn btn-secondary font_barlow_semi_condensed_light_14" v-on:click="cancelEventPopup()">Cancel</button>
                  </div>
                  <div class="col-sm-1 padding_10 padding_left_23 save_button">
                    <button type="submit" class="btn btn-success save margin_bottom_3 font_barlow_semi_condensed_light_14" v-on:click="save()">Save</button>  
                  </div>
                </div>                                 
              </div>                       
            </div>`,
  data: function() {
    return {
      clear_search: false
    }
  },
  props: {
    selected_events: Array,
    created_events: Array,
    save_event_selections_url: String
  },
  computed: {
    available_events_sorted_Array: function() {
      return array_sort_by_name(this.$parent.created_events,"name");        
    },
    selected_events_sorted_Array: function() { 
      return array_sort_by_name(this.$parent.selected_events,"name");       
    }
  },  
  mounted: function() {
    this.$parent.selected_events.forEach(function(item){
      jQuery('#' + 'checkbox_' + item.id).prop("checked", true);
    });
    setTimeout(function(){ jQuery("#event_lists").niceScroll({cursorcolor:"#4184F3",cursorwidth:"3px",autohidemode: "scroll"}); }, 20);
  },  
  methods: {
    search_events: function(){
      this.clear_search = true;
      var keyword = jQuery('#search_box').val();
      var equal = search_and_highlight('available_event_list', keyword);
      if (keyword.length == 0 || equal) {
        this.clear_search = false;
      }
    },
    clear_search_data: function(){
      jQuery("#search_box").val("");
      this.search_events();
      this.clear_search = false;
    },
    unselect_event: function(event,item,index){
      this.$parent.selected_events.splice(index, 1);
      this.$parent.created_events.splice(index, 0, item);
    },
    select_event: function(event,item,index){
      this.$parent.created_events.splice(index, 1);
      this.$parent.selected_events.splice(index, 0, item);
      setTimeout(function(){ jQuery('#' + 'checkbox_' + item.id).prop("checked", true); },100);
    },
    cancelEventPopup: function(){
      this.$parent.show_edit_event = false;
    },
    save: function(){
      var events = [];
      var params = '&selectable_type=Event'
      if (this.$parent.selected_events.length != 0) {
        for (var i = 0; i < this.$parent.selected_events.length; i++) {
          events[i] = this.$parent.selected_events[i].id;
        }
        var event_id = JSON.stringify(events);
        var params = params + '&selected_events='+event_id
      }      
      this.$http.post(this.save_event_selections_url+params).then(function(response) {
        if (response.body) {
          this.$parent.show_edit_event = false;
        }
      });
    }                                  
  }
});

Vue.component('upcoming-inprogress-section', {
  template: `<div>
              <h4> Upcoming & In-Progress </h4>
              <div class="events_switching">
                <span id="recent_link" class="cursor_pointer" v-on:click="ItemFilter('recent')"> Recent </span>
                <span> &nbsp &nbsp | &nbsp &nbsp </span>
                <span id="title_link" class="cursor_pointer" v-on:click="ItemFilter('title')"> Title </span>
              </div>                        
              <span v-for="item in upcoming_items" class="col-sm-12 item_list font_barlow_semi_condensed_light_14">
                <span class="col-sm-3 padding_0"><img class="img_size" :src="item.thumbnail_url" :title="item.title"></span>
                <p> {{item.title}} </p>
                <p> By  {{item.by_name}} </p>                          
              </span>                        
            </div>`,
  props: {
    upcoming_items: Array,
    sort_upcoming_items_url: String,
    more_upcoming_item: Boolean
  }, 
  methods: {
    ItemFilter: function(order) {  
      var params = '&order='+ order+'&more_upcoming_item='+this.more_upcoming_item
      this.$http.get(this.sort_upcoming_items_url+ params).then(function(response) {
        this.$parent.upcoming_items = response.body
      });      
    }                 
  }
});

Vue.component('recent-activity-list', {
  template: `<div class="recent_activity col-sm-12 padding_0">
              <div class="col-sm-2 padding_0">
                <span><img class="activity_img_size" :src="activity.thumbnail_url"></span>
              </div>  
              <div class="col-sm-10 padding_0">
                <span>{{activity.by_name}}&nbsp&nbsp{{activity.action}}</span>
                <span class="user_seperator">|</span>
                <a v-if="activity_data.visibility" v-on:click="updateVisibility(activity.visibility_url)" class="show_profile">Show to profile</a>
                <a v-else v-on:click="updateVisibility(activity.visibility_url)" class="hide_profile">Hide from profile</a>
              </div>
              <p> {{activity.created_at}} </p>                         
            </div>`,
  data: function() {
    return {
      activity_data: this.activity
    }
  },
  props: {
    activity: Object
  }, 
  methods: {
    updateVisibility(url) {
      this.$http.get(url).then(function(response) {
        this.activity_data = response.body;
      });      
    }
  }  
});

Vue.component('profile-comments-list', {
  template: `<div class="col-sm-12 padding_0 padding_top_12 font_barlow_semi_condensed_14">
              <span><img class="img_size" :src="comment.thumbnail_url"></span>
              <div class="comment_title_container"> {{comment.comment.body}}  {{comment.text}}  {{comment.title}}
                <span class="user_seperator">|</span>
                <a v-if="comment_data.visibility" v-on:click="updateVisibility(comment.visibility_url)" class="show_profile">Show to profile</a>
                <a v-else v-on:click="updateVisibility(comment.visibility_url)" class="hide_profile">Hide from profile</a>
              </div>
              <p> {{comment_createdtime}} </p>
              <hr class="comment_bottom_line"/>                         
            </div>`,
  data: function() {
    var profile_comment = this.comment;
    return {
      comment_createdtime: moment(new Date(moment(String(profile_comment.comment.created_at)).utc().format('MMMM D, YYYY h:mm:ss a'))).fromNow(),
      comment_data: this.comment
    }
  },
  props: {
    comment: Object
  },
  methods: {
    updateVisibility(url) {
      this.$http.get(url).then(function(response) {
        this.comment_data = response.body;
      });      
    }
  }  
});

Vue.component('user-account-setting', {
  template: `<div class="modal" id="user_account_modal" role="dialog">
              <div class="modal-xl">
                <div class="modal-content user_account_setting">
                  <div class="margin_top_30">
                    <span class="account_setting_heading lxa_text_color_06 lxa_font_size_08 lxa_light_font mt-5"> {{$t('account_setting.heading')}} </span>
                  </div>
                  <p class="light_box_close" data-dismiss="modal"> <i data-toggle="tooltip" data-placement="top" title="Close" class="fad fa-times lxa_text_color_08 lxa_font_size_06 cursor_pointer"></i></p>
                  <div class="account_setting_menu margin_top_30">
                    <span v-for="menu in menu_items" :class="[{'active': menu == active_menu}]" @click="active_menu=menu">
                      {{ menu}}
                    </span>
                  </div>
                  <!-- <account-details v-if="active_menu == menu_items[0]" :account_details="account_details"> </account-details> -->
                  <!-- <purchase_history v-if="active_menu == menu_items[1]" :items="order_details" :order_page="order_page" :show_load_more="show_load_more"> </purchase_history> --> 
                  <analytics v-if="active_menu == menu_items[0]" :analytics="analytics" > </analytics>
                  <account-notification v-if="active_menu == menu_items[1]" :user_notifications="user_notifications"> </account-notification>
                </div>
              </div>
            </div>`,
  data() {
    return {
      active_menu: 'Analytics',
      // menu_items: ['Account', 'Purchase History', 'Analytics', 'Dashboard', 'Notification'],
      menu_items: ['Analytics', 'Notification'],
      account_details: {},
      order_details: [],
      order_page: 1,
      analytics: {},
      user_notifications: [],
      show_load_more: false
    }
  },
  props: ["logged_in"],
  mounted() {
    this.loadData()
  },
  watch: {
    order_page() {
      this.loadData()
    }
  },
  methods: {
    loadData() {
      // if (this.logged_in) {
        var analytics_date = ''
        if (this.active_menu == 'Analytics') {
          analytics_date = this.$children[0].analytics_start_date + '//' + this.$children[0].analytics_end_date
        }
        this.$http.get('/user_account_settings?order_page='+this.order_page+'&analytics_date='+analytics_date).then(function(response) {
          this.account_details = response.body.account_details
          this.order_details = this.order_details.concat(response.body.order_details)
          this.analytics = response.body.analytics
          this.user_notifications = response.body.user_notifications
          this.show_load_more = response.body.show_load_more
        });
      // }
    }
  }
});

Vue.component('account-details', {
  template: `<div class="account_details margin_top_30">
              <div class="form_fields">
                <div class="form_group">
                  <label> {{$t('account_setting.first_name')}} </label> <br/>
                  <input type="text" v-model="account_details.firstname"> </input>
                </div>
                <div class="form_group">
                  <label> {{$t('account_setting.last_name')}} </label> <br/>
                  <input type="text" v-model="account_details.lastname"> </input>
                </div>
                <div class="form_group">
                  <label> {{$t('account_setting.email_id')}} </label> <br/>
                  <input type="text" v-model="account_details.email"> </input>
                </div>
                <div class="form_group">
                  <label> {{$t('account_setting.password')}} </label> <br/>
                  <input :type="password_type ? 'password' : 'text' " v-model="account_details.password"> </input>
                  <span class="eye_slash" @click="password_type = !password_type" data-toggle="tooltip" data-placement="top" title="View Password"><i class="fad fa-eye"></i></span>
                </div>
                <div class="form_group">
                  <label> {{$t('account_setting.sub_expr')}} </label> <br/>
                  <datepicker v-model="account_details.subscription_end_date" format="MMM dd, yyyy"> </datepicker>
                </div>
              </div>
              <div class="form_action">
                <a class="btn btn-secondary btn-sm" data-dismiss="modal"> {{$t('account_setting.cancel')}} </a>
                <a class="btn btn-danger btn-sm" @click="saveChanges"> {{$t('account_setting.save')}} </a>
              </div>
            </div>`,
  props: ["account_details"],
  data() {
    return {
      password_type: true
    }
  },
  components: {
    Datepicker
  },
  methods: {
    saveChanges() {
      this.$http.post('/update_user_details', this.account_details).then(function(response) {
        this.$root.popUpMessage('Saved !!')
      });
    }
  }
})

Vue.component('purchase_history', {
  template: `<div class="purchase_history margin_top_30">
              <div class="order_table">
                <b-table hover :items="items">
                  <template #head(view_invoice)> {{ }}</template>
                  <template #cell(view_invoice)="data">
                    <span class="eye_slash" style="top: 0;" data-toggle="modal" data-target="#receipt_modal" @click="showReceipt(data)"><i  data-toggle="tooltip" data-placement="top" title="Show Receipt" class="fad fa-eye"></i></span>
                  </template>
                </b-table>
                <div class="modal" id="receipt_modal" role="dialog">
                  <div class="modal-dialog modal-xl mt-5" style="width: 100%;">
                    <div class="modal-content">
                      <ItemDetails :item="currentItem"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="align_center" v-if="show_load_more">
                <a class="load_more" @click="loadMore"> {{$t('account_setting.load_more')}} </a>
              </div>
            </div>`,
  props: ["items", "order_page", "show_load_more"],
  data() {
    return {
      currentItem: null
    }
  },
  methods: {
    loadMore() {
      this.$parent.order_page = this.$parent.order_page + 1
    },
    showReceipt(order) {
      // this.currentItem = order.item;
      this.$http.get('/get_order_details/'+order.item.order_no).then(function(response) {
        this.currentItem = response.body
      })
    }
  }
});

Vue.component('ItemDetails', {
  template: `<div id="receipt" v-if="item">
                <p class="light_box_close" @click="hideModal()" data-dismiss-modal="modal2"> <i data-toggle="tooltip" data-placement="top" title="Close" class="fad fa-times lxa_text_color_02 lxa_font_size_06 cursor_pointer"></i></p>
                <div class="row margin_0" style="height: 75px;">
                  <div class="col-md-6 padding_0 lxa_font_size_08 h-100"> 
                    <img :src="item.logo_url" class="w-50 h-100">
                  </div>
                  <div class="col-md-4 padding_0 font_size_20 font_weight_bold mt-4"> {{$t('account_setting.receipt')}}</div>
                </div>
                <div class="row margin_0 padding_0 margin_top_30 lxa_font_size_04">
                  <div v-for="(value, name) in item.order_details" class="row col-md-6 margin_0 padding_0" v-if="value">
                    <div class="col-md-4 padding_0"> <span> {{ name }} :</span> </div>
                    <div class="col-md-6 padding_0"> <span> {{ value }} </span> </div>
                  </div>
                </div>
                <div class="margin_top_30"> 
                  <b-table hover :items="item.price_splitup"></b-table>
                </div>
            </div>`,
  props: ["item"],
  methods: {
    toggleReceipt() {
      this.$parent.currentItem = null;
    },
    hideModal() {
      jQuery("#receipt_modal").modal('hide');
    }
  },
  mounted() {
  }
})

Vue.component('analytics', {
  template: `<div class="account_analytics margin_top_30">
              <div class="date_filter row">
                <div class="from dates col-md-3">
                  <label class="lxa_text_color_03 lxa_font_size_04 lxa_light_font"> {{$t('account_setting.analytics_from')}} </label>
                  <datepicker id="start_date" class="lxa_font_size_03 lxa_text_color_02 lxa_border_radius lxa_bg_05 border-0 lxa_date_picker" v-model="analytics_start_date" format="MMM dd, yyyy"></datepicker>
                  <span class="downarrow" @click="openStartDate"><i class="fad fa-chevron-down"></i></span>
                </div>
                <div class="to dates col-md-3">
                  <label class="lxa_text_color_03 lxa_font_size_04 lxa_light_font"> {{$t('account_setting.analytics_to')}} </label>
                  <datepicker id="end_date" class="lxa_font_size_03 lxa_text_color_02 lxa_border_radius lxa_bg_05 border-0 lxa_date_picker" v-model="analytics_end_date" format="MMM dd, yyyy"></datepicker>
                  <span class="downarrow" @click="openEndDate"><i class="fad fa-chevron-down"></i></span>
                </div>
                <div class="col-md-3">
                  <div class="apply_changes btn lxa_bg_04 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" @click="applyFilter"> {{$t('account_setting.apply')}} </div>
                </div>
              </div>
              <div class="chart">
                <apexchart height="250" type="line" :options="videoOptions" :series="video_series"></apexchart>
              </div>
              <div class="chart">
                <apexchart height="250" type="line" :options="pageOptions" :series="page_series"></apexchart>
              </div>
            </div>`,
  props: ["analytics"],
  data() {
    return {
      videoOptions: {
        chart: {
          id: 'video-seconds',
          toolbar: {show: false}
        },
        xaxis: {
          type: 'datetime',
          tickAmount: 10,
        },
        colors: ['#61d4e3'],
        markers: {
          size: 4,
          colors: ["#449bf9"],
          strokeColors: "#fff",
          strokeWidth: 2,
          hover: {
            size: 7,
          }
        },
        title: {
          text: 'Time spent on Videos',
          align: 'left',
          style: {
            color: '#f9ce63' 
          }
        },
        grid: {
          borderColor: '#f9ce63',
        },
      },
      pageOptions: {
        chart: {
          id: 'page-read',
          toolbar: {show: false}
        },
        xaxis: {
          type: 'datetime',
        },
        colors: ['#e74547'],
        markers: {
          size: 4,
          colors: ["#e64235"],
          strokeColors: "#fff",
          strokeWidth: 2,
          hover: {
            size: 7,
          }
        },
        title: {
          text: 'Number of Pages Read',
          align: 'left',
          style: {
            color: '#5fcf7d' 
          }
        },
        grid: {
          borderColor: '#5fcf7d',
        }
      },
      video_series: [],
      page_series: [],
      analytics_start_date: '',
      analytics_end_date: ''
    }
  },
  components: {
    Datepicker
  },
  mounted: function() {
    this.analytics_start_date = this.analytics.start_date
    this.analytics_end_date = this.analytics.end_date
    this.alterChartData()
  },
  watch: {
    analytics() {
      this.alterChartData()
    }
  },
  methods: {
    applyFilter() {
      this.$parent.loadUserData()
    },
    alterChartData() {
      this.video_series = [{
        name: 'No. of Video Minutes',
        data: this.analytics.video_data
      }]
      this.page_series = [{
        name: 'No. of Pages Read',
        data: this.analytics.page_data
      }]
    },
    openStartDate() {
      document.querySelectorAll('#start_date')[0].click()
    },
    openEndDate() {
      document.querySelectorAll('#end_date')[0].click()
    }
  }
});

Vue.component('account-notification', {
  template: `<div class="notification_settings margin_top_30">
              <p> {{ $t('account_setting.notification_heading') }} </p>
              <div v-for="notification in user_notifications" class="row padding_0 margin_left_0 margin_top_15">
                <div class="col-md-12 padding_0 margin_left_0">
                  <span> <toggle-button :value="notification.notification" color="#5ec968" :sync="true" @change="onChangeEventHandler(notification)"/> </span>
                  <span style="margin-left: 15px;"> {{ notification.name }} </span>
                </div>
              </div>
              <div class="form_action d-flex align-items-center justify-content-end">
                <a class="btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" data-dismiss="modal"> {{ $t('account_setting.cancel') }} </a>
                <a class="btn lxa_bg_01 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase ml-3" @click="saveChanges"> {{ $t('account_setting.save') }} </a>
              </div>
            </div>
            `,
  props: ["user_notifications"],
  data() {
    return {
    }
  },
  methods: {
    onChangeEventHandler(notification) {
      for (var i = 0; i < this.$parent.user_notifications.length; i++) {
        if (this.$parent.user_notifications[i].name == notification.name) {
          this.$parent.user_notifications[i].notification = !this.$parent.user_notifications[i].notification
        }
      }
    },
    saveChanges() {
      var data = {}
      data['notification_enabled_ids'] = []
      for (var i = 0; i < this.user_notifications.length; i++) { 
        if (this.user_notifications[i].notification) {
          data['notification_enabled_ids'].push(this.user_notifications[i].id) 
        }
      }
      this.$http.post('/user_disabled_notification', data).then(function(response) {
        this.$root.popUpMessage("Successfully Updated !!")
      });
    }
  }
});

Vue.component('banner-uploads', {
  template: `<div class="modal" id="banner_uploads_modal" role="dialog">
              <div class="modal-xl">
                <div class="modal-content user_profile primary_bgcolor">
                  <div class="d-flex align-items-center justify-content-start">
                    <div class="account_setting_heading lxa_text_color_01 lxa_font_size_08 lxa_light_font"> {{$t('banner_uploads.heading')}} </div>
                    <div class="ml-auto add_banner btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase cursor_pointer" @click="addNewBanner">  <i class="fad fa-plus"></i> <span> {{ $t('banner_uploads.add_banner')}} </span></div>
                  </div>
                  
                  <p class="light_box_close margin_0" data-dismiss="modal"> <i data-toggle="tooltip" data-placement="top" title="Close" class="fad fa-times lxa_text_color_08 lxa_font_size_06 cursor_pointer"></i></p>
                  
                  <div v-if="banner_uploads.length != 0">
                    <div class="margin_top_30">
                      <Container @drop="onDrop" orientation="horizontal" behaviour="contain" class="banner_headings lxa_font_size_04 lxa_menu_container d-flex align-items-center justify-content-start pb-3">
                        <Draggable v-for="(banner, index) in banner_uploads" :class='[{"active menu_active": active_banner == banner.id},"menu"]' :key="index">
                          <span data-toggle="tooltip" data-placement="bottom" title="Drag left or right to rearrange the order of banners" @click="updateActiveBanner(banner, index)" :id="banner.custom_id">{{ banner.heading }}
                            <span v-show="active_banner == banner.id">
                              <i :class='[{"left_30": banner.heading.length > 10}, {"left_5": banner.heading.length <= 5}, {"left_15": (banner.heading.length <= 10 && banner.heading.length > 5)},"fad fa-arrows-alt-h drag-arrow banner_arrow"]'></i>
                            </span>
                          </span>
                        </Draggable>
                        <div class="smooth-dnd-draggable-wrapper">
                          <span @click="updateActiveBanner(new_banner, 'new_banner')" v-if="add_new_banner" :class='[{"active": active_banner == 0}]'>Banner {{ banner_length + 1}} </span>
                        </div>
                      </Container>
                    </div>
                    <div v-for="(banner, index) in banner_uploads">
                      <banner-details :banner="banner" :active_banner="active_banner" :marquee_length="marquee_length"> </banner-details>
                    </div>
                    <banner-details :banner="new_banner" :add_new_banner="add_new_banner" :active_banner="active_banner" :marquee_length="marquee_length"> </banner-details>
                  </div>

                </div>
              </div>
            </div>`,
  data() {
    return {
      banner_uploads: [],
      active_banner: '',
      add_new_banner: false,
      marquee_length: 0,
      new_banner: {},
      banner_length: 0,
      videojs_id: ''
    }
  },
  components: {
    Draggable,
    Container
  },
  mounted: function() {
    // EventBus.$on('toogleBanner');
    this.getBanners();
    var self = this;
    jQuery('#banner_uploads_modal').on('hidden.bs.modal', function() {
      jQuery('#'+self.videojs_id).remove();
      self.getBanners();
    });
  },
  // beforeDestroy() {
  //   EventBus.$off('toogleBanner');
  // },
  methods: {
    getBanners(active_banner_id = undefined) {
      this.$http.get('/get_banner_image?new_single_page=true').then(function(response) {
        this.banner_uploads = []
        this.banner_uploads = response.body['banner_uploads']
        if ( active_banner_id == undefined) {
          this.active_banner = this.banner_uploads[0].id
        } else {
          this.active_banner = active_banner_id
        }
        this.resetNewBanner()
        this.marquee_length = response.body['marquee_length']
        this.add_new_banner = false
        this.banner_length = this.banner_uploads.length
      });
    },
    resetNewBanner() {
      var new_order = this.banner_uploads[this.banner_uploads.length-1].order + 1
      this.new_banner = {
        title: '',
        start_date: new Date(),
        end_date: '',
        title_bar_color: '',
        preview_url: '',
        type: 'image',
        order: new_order,
        redirection_url: '',
        embed_url: '',
        autoplay: false
      }
    },
    updateActiveBanner(banner, index) {
      if (index == 'new_banner') {
        this.active_banner = 0
        this.add_new_banner = true
      } else {
        this.add_new_banner = false
        this.active_banner = banner.id
        var video = {
          src: banner.preview_url,
        }
        setTimeout(() => this.$children[index+1].playVideo(video), 1000);
        setTimeout(() => this.$children[index+1].muteVideo(), 1000);
      }
    },
    addNewBanner() {
      this.add_new_banner = true
      this.active_banner = 0
    },
    onDrop(dropResult) {
      var new_order = 0
      if (dropResult.addedIndex == 0) {
        var prev_order = 1
        var next_order = this.banner_uploads[dropResult.addedIndex].order
        new_order = parseFloat((Math.random() * (next_order - prev_order) + prev_order).toFixed(3))
      } else if (dropResult.addedIndex == (this.banner_uploads.length - 1) ) {
        new_order = this.banner_uploads[dropResult.addedIndex].order + 0.01
      } else {
        var prev_order = this.banner_uploads[dropResult.addedIndex].order
        var next_order = this.banner_uploads[dropResult.addedIndex+1].order
        new_order = parseFloat((Math.random() * (next_order - prev_order) + prev_order).toFixed(3))
      }
      this.banner_uploads[dropResult.removedIndex].order = new_order
      var banner = this.banner_uploads[dropResult.removedIndex]
      let formData = new FormData();
      formData.append('banner[id]', banner.id)
      formData.append('banner[order]', banner.order)
      this.$http.post('/update_banner_order', formData).then(function(response) {
        this.$root.popUpMessage("Updated Banner");
        this.$root.$emit('updateBannerUploads');
        this.getBanners();
      });
    }
  }
});


Vue.component('banner-details', {
  template: `<div class="new_banner_fields margin_top_30 lxa_font_size_03" v-if="(active_banner == banner.id) || add_new_banner">
                <div class="form_fields row margin_0">
                  <div class="form_group col-12 px-0 lxa_font_size_03">
                   <div class="d-flex align-items-center justify-content-start">
                    <div id="banner_type_radio_wrapper" class="mr-3 learnexa-custom-radiobutton custom-controls custom-radio d-flex align-items-center justify-content-start">
                          <input id="banner_type_image" value="image" v-model="banner.type" name="banner_type" class="delivery custom-control-input lxa-bs-radiobtn" type="radio">
                          <label class="custom-control-label" for="banner_type_image">
                            <i class="fad fa-circle  mr-1 lxa_icon_size lxa_text_color_05"></i>
                            <i class="fad fa-dot-circle  mr-1 lxa_icon_size lxa_text_color_05"></i>
                            <span>Image</span>
                          </label>
                  </div>
                  
                  <div id="banner_type_radio_wrapper" class="learnexa-custom-radiobutton custom-controls custom-radio d-flex align-items-center justify-content-start">
                          <input id="banner_type_video" value="video" v-model="banner.type" name="banner_type" class="delivery custom-control-input lxa-bs-radiobtn" type="radio">
                          <label class="custom-control-label" for="banner_type_video">
                            <i class="fad fa-circle  mr-1 lxa_icon_size lxa_text_color_01"></i>
                            <i class="fad fa-dot-circle  mr-1 lxa_icon_size lxa_text_color_01"></i>
                            <span>Video</span>
                          </label>
                  </div>
</div>
                  </div>
                </div>

                <div class="form_fields row margin_0 margin_top_30">
                  <div class="form_group col-md-6 padding_0 padding_right_15">
                    <label class="mb-2 lxa_text_color_03 lxa_font_size_04 lxa_light_font"> {{$t('banner_uploads.title')}} </label> <br/>
                    <input type="text" v-model="banner.title" class="p-2 lxa_font_size_03 lxa_text_color_02 lxa_border_radius lxa_bg_05 border-0"> </input>
                  </div>
                  <div class="form_group col-md-2 padding_0 padding_right_15">
                    <label class="mb-2 lxa_text_color_03 lxa_font_size_04 lxa_light_font"> {{$t('banner_uploads.time_period')}} </label> <br/>
                    <datepicker class="pl-2 lxa_font_size_03 lxa_text_color_02 lxa_border_radius lxa_bg_05 border-0 lxa_date_picker" placeholder="From: MM/DD/YYYY" format="MMM dd, yyyy" v-model="banner.start_date" :disabled="banner.default" :class="{ 'disabled': banner.default == true }" :disabledDates="disabledDates" @closed="setEndDate()" :highlighted="highlighted" :id="'start_date_' + banner.id" ref="start_date_calendar"> </datepicker>
                    <span class="downarrow vdp-datepicker" @click="openStartDate"><i class="fad fa-chevron-down"></i></span>
                  </div>
                  <div class="form_group col-md-2 padding_0 padding_right_15" >
                    <label class="lxa_text_color_03 lxa_font_size_04 lxa_light_font"> </label>
                    <datepicker class="mt-2 pl-2 lxa_font_size_03 lxa_text_color_02 lxa_border_radius lxa_bg_05 border-0 lxa_date_picker" placeholder="To: MM/DD/YYYY" format="MMM dd, yyyy" v-model="banner.end_date" :disabled="banner.default" :class="{ 'disabled': banner.default == true }" :disabledDates="disabledEndDates" :highlighted="highlighted" :id="'end_date_' + banner.id" ref="end_date_calendar"> </datepicker>
                    <span class="downarrow" @click="openEndDate"><i class="fad fa-chevron-down"></i></span>
                    <div class="mt-1 text-right lxa_text_color_03 lxa_font_size_02 lxa_light_font">Leave empty to never end</div>
                  </div>
                  
                  <colour-picker v-model="banner.title_bar_color" :color="banner.title_bar_color" label="Title Bar Color" picker="sketch" class="form_group col-md-2 padding_0 colorize" />
                </div>

                <div class="form_fields row margin_0 margin_top_15">
                  <div class="form_group rel_pos col-md-5 padding_0 padding_right_15">
                    <label v-if="banner.type == 'image'" class="mb-2 lxa_text_color_03 lxa_font_size_04 lxa_light_font"> Banner Image - Embed URL </label> 
                    <label v-else class="mb-2 lxa_text_color_03 lxa_font_size_04 lxa_light_font"> {{$t('banner_uploads.embed_url')}} </label>
                    <br/>
                    <div class="d-flex align-items-center justify-content-start">
                    <input type="text" v-model="banner.embed_url" class="p-2 lxa_font_size_03 lxa_text_color_02 lxa_border_radius lxa_bg_05 border-0"> </input>
                    <span class="ml-3 embed_url  btn lxa_bg_07 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" @click="embedImage" v-if="banner.type == 'image'">EMBED</span>
                    <span class="ml-3 embed_url  btn lxa_bg_07 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" @click="embedVideo" v-if="banner.type == 'video'">EMBED</span>
                    </div>
                    <span class="embed_url_note" v-if="banner.type == 'image'"> {{$t('banner_uploads.embed_img_note')}} </span>
                    <span class="embed_url_note" v-if="banner.type == 'video'"> {{$t('banner_uploads.embed_video_note')}} </span>
                  </div>
                  <div class="wrapper col-md-1 padding_0 padding_right_15">
                      <div class="line"></div>
                      <div class="wordwrapper"> <div class="word">Or</div></div>
                  </div>
                  
                  <div class="form_group col-md-6 padding_0">
                    <label class="mb-2 lxa_text_color_03 lxa_font_size_04 lxa_light_font"> Upload a File</label> <br/>
                    <label for="banner-file-upload" class="banner_file_upload  btn lxa_bg_09 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase"> Browse</label>
                    <input id="banner-file-upload" type="file" name="file" ref="files" @change="attachImageFile" style="display: none" accept="image/*" v-if="banner.type == 'image'"/>
                    <input id="banner-file-upload" type="file" name="file" ref="videofiles" @change="attachVideoFile" style="display: none" accept="video/mp4" v-if="banner.type == 'video'"/>
                    <p class="embed_url_note margin_0" v-if="banner.type == 'image'"> {{$t('banner_uploads.upload_img_note')}} </p>
                    <p class="embed_url_note margin_0" v-if="banner.type == 'video'"> {{$t('banner_uploads.upload_video_note')}} </p>
                  </div>
                </div>
                
                <div class="row form_fields margin_0 margin_top_15" v-if="banner.type == 'image'">
                  <div class="form_group rel_pos col-md-5 padding_0 padding_right_15">
                    <label class="mb-2 lxa_text_color_03 lxa_font_size_04 lxa_light_font"> {{$t('banner_uploads.redirection_url')}} </label> <br/>
                    <input type="text" v-model="banner.redirection_url" class="p-2 lxa_font_size_03 lxa_text_color_02 lxa_border_radius lxa_bg_05 border-0"> </input>
                  </div>
                </div>

                <div class="form_fields margin_0 margin_top_30" style="height: 425px;">
                  <div class="form_group banner_image_area">
                   <div class="d-flex align-items-center justify-content-start">
                    <label class="mb-2 lxa_text_color_03 lxa_font_size_04 lxa_light_font"> {{$t('banner_uploads.preview')}} </label>
                    <div class="ml-auto d-flex align-items-center justify-content-start lxa_text_color_03 lxa_font_size_04 lxa_light_font mb-2" v-if="banner.type == 'video'"> 
                      <toggle-button class="mb-0" :value="banner.autoplay" :color="{checked: '#1ac867', unchecked: '#e51434'}" :sync="true" @change="onChangeEventHandler(banner)"/> 
                      <div class="ml-2" v-if="banner.autoplay">{{$t('banner_uploads.autoplay_on')}}</div>
                      <div class="ml-2" v-else="">{{$t('banner_uploads.autoplay_off')}}</div>
                    </div>
                    </div>
                    <div class="form_group image_source_preview lxa_border_radius lxa_bg_05 border-0 overflow-hidden position-relative" v-show="banner.type == 'image'">
                      <img :src="banner.preview_url" v-if="banner.preview_url" class="border-0"></img>
                    </div>
                    <div class="position-relative overflow-hidden w-100 lxa_border_radius" v-show="banner.type == 'video'">
                    <div :id="'banner_preview_container_' + banner.id" class="col-sm-12 px-0 video_source_preview height_400px lxa_border_radius lxa_bg_05 border-0">
                    </div>
                    <div :class="[{'lxa_bg_02': !video_muted},'muteVideo p-2 lxa_bg_01 lxa_btn_txt_01 lxa_font_size_04']" @click="muteVideo()" v-if="banner.type == 'video'">
                      <span v-show="video_muted" :id="'unmute_video_'+banner.id" data-toggle="tooltip" data-placement="top" title="Unmute"> <i class="fad fa-volume-mute fa-lg"></i></span>
                      <span v-show="!video_muted" :id="'mute_video_'+banner.id" data-toggle="tooltip" data-placement="top" title="Mute"> <i class="fas fa-volume-up fa-lg"></i></span>
                    </div>
                    </div>
                  </div>
                  <div class="banner_content" v-if="banner.title && (banner.preview_url || banner.embed_url)" v-bind:style="{ backgroundColor: banner.title_bar_color }"> 
                    <span v-if="banner.title.length < marquee_length"> {{ banner.title }} </span>
                    <span v-else> <marquee>{{ banner.title }} </marquee></span>
                  </div>
                </div>
                <div class="form_action d-flex align-items-center justify-content-start">
                  <a class="delete btn lxa_bg_05 lxa_btn_txt_02 lxa_border_radius lxa_font_size_04 mr-auto" @click="deleteBanner" :class="{ 'disabled': banner.default == true }"> 
                    <span v-if="banner.default" data-toggle="tooltip" data-placement="top" title="Delete" class="lxa_font_size_04"><i class="fad fa-trash-alt lxa_text_color_03"></i> {{$t('banner_uploads.remove')}} </span>
                    <span v-if="!banner.default" data-toggle="tooltip" data-placement="top" title="Delete" class="lxa_font_size_04"><i class="fad fa-trash-alt lxa_text_color_01"></i> {{$t('banner_uploads.remove')}} </span>
                  </a>
                  <a class="revert btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" @click="reloadBanners(banner.id)"> {{$t('banner_uploads.revert')}} </a>
                  <a class="save btn lxa_bg_01 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase ml-3" @click="saveChanges"> {{$t('banner_uploads.save')}} </a>                  
                </div>
              </div>`,
  data() {
    return {
      file: '',
      image_source_preview: '',
      video_muted: true,
      disabledDates: {
        to: new Date(Date.now() - 86400000)
      },
      disabledEndDates: {
        to: new Date(Date.now() - 86400000)
      },
      highlighted: {
        dates: [ 
          new Date(Date.now()),
        ]
      }
    }
  },
  props: {
    banner: Object,
    active_banner: Number,
    add_new_banner: Boolean,
    marquee_length: Number
  },
  components: {
    Datepicker,
    'colour-picker': ColourPicker
  },
  mounted() {
    if (this.banner.id == this.active_banner && this.banner.type == 'video') {
      setTimeout(() => this.previewVideo(), 1000);
      setTimeout(() => this.muteVideo(), 1000);
      this.loadIframeVideo()
    }
  },
  methods: {
    onChangeEventHandler(banner) {
      if ( this.add_new_banner) {
        this.$parent.new_banner.autoplay = !this.$parent.new_banner.autoplay
      } else {
        for (var i = 0; i < this.$parent.banner_uploads.length; i++) {
          if (this.$parent.banner_uploads[i].id == banner.id) {
            this.$parent.banner_uploads[i].autoplay = !this.$parent.banner_uploads[i].autoplay
          }
        }
      }
    },
    openStartDate() {
      this.$refs.start_date_calendar.showCalendar();
      this.$refs.start_date_calendar.$el.querySelector('input').focus()
      // document.querySelectorAll('#start_date_'+this.banner.id)[0].click()
    },
    openEndDate() {
      this.$refs.end_date_calendar.showCalendar();
      this.$refs.end_date_calendar.$el.querySelector('input').focus()
      // document.querySelectorAll('#end_date_'+this.banner.id)[0].click()
    },
    setEndDate() {
      this.banner.end_date = ''
      this.disabledEndDates = {
        to: new Date(this.banner.start_date)
      }
    },
    reloadBanners(banner_id) {
      if ( banner_id == undefined) {
        this.$parent.resetNewBanner();
      } else {
        this.$parent.getBanners(banner_id);
      }
      this.$root.$emit('updateBannerUploads');
    },
    attachImageFile() {
      this.file = this.$refs.files.files[0];
      this.resetEmbedUrl();
      this.banner.preview_url = URL.createObjectURL(this.file);
    },
    embedImage() {
      this.banner.preview_url = this.banner.embed_url
    },
    attachVideoFile() {
      this.file = this.$refs.videofiles.files[0];
      var video = {
        src: URL.createObjectURL(this.file),
      }
      this.resetEmbedUrl();
      this.playVideo(video)
    },
    embedVideo() {
      var video = {
        src: this.banner.embed_url,
      }
      this.playVideo(video)
    },
    previewVideo() {
      var video = {
        src: this.banner.preview_url,
      }
      this.playVideo(video)
    },
    resetEmbedUrl() {
      this.banner.embed_url = ''
    },
    getVideoUrl(video_src) {
      var video_src = video_src
      if (video_src.includes('vimeo')) {
        var video_id = video_src.split('/')[video_src.split('/').length-1]
        video_src = "https://player.vimeo.com/video/"+video_id+'?autoplay=1&loop=1&autopause=0&muted=0'
      } else if (video_src.includes('youtube')) {
        var video_id = video_src.split('=')[video_src.split('=').length-1]
        video_src = "https://www.youtube.com/embed/"+video_id+'?rel=0&showinfo=0&controls=0&fs=0&iv_load_policy=3&autohide=1&enablejsapi=1&mute=0&autoplay=1&loop=1'
      }
      return video_src
    },
    playVideo(video) {
      video['src'] = this.getVideoUrl(video.src)
      video['class'] = "video-js vjs-default-skin"
      video['aspectRatio'] = "16:9"
      video['position'] = 0
      video['fluid'] = false
      video['autoplay'] = this.banner.autoplay
      video['controls'] = true
      video['muted'] = false
      video['preload'] = "auto"
      video['id'] = 'VideoPlayer_' + this.banner.id
      var videojsId = "video-preview-"+this.banner.id
      this.$parent.videojs_id = video['id'];
      var videoTag = $('<video/>', video);
      var iframeTag = $('<iframe />', video);
      // var iframeTag = $('<iframe class="lxa-video-iframe"/>', video);
      $("#banner_preview_container_"+this.banner.id).html('')
      if (video.src.includes("youtube") || video.src.includes("vimeo")) {
        iframeTag.appendTo($("#banner_preview_container_"+this.banner.id));
      } else {
        videoTag.appendTo($("#banner_preview_container_"+this.banner.id));
      }
      videojs(videojsId, video)
    },
    saveChanges() {
      let formData = new FormData();
      formData.append('photo[image]', this.file);
      formData.append('banner[id]', this.banner.id ? this.banner.id : '')
      formData.append('banner[title]', this.banner.title)
      formData.append('banner[title_bar_color]', this.banner.title_bar_color)
      formData.append('banner[start_date]', this.banner.start_date)
      formData.append('banner[end_date]', this.banner.end_date)
      formData.append('banner[embed_url]', this.banner.embed_url)
      formData.append('banner[banner_type]', this.banner.type)
      formData.append('banner[redirection_url]', this.banner.redirection_url)
      formData.append('banner[order]', this.banner.order)
      formData.append('banner[autoplay]', this.banner.autoplay)
      if (this.file || this.banner.embed_url || this.banner.preview_url) {
        this.$http.post('/home/homepage_highlights/upload_banner_image?new_single_page=true', formData).then(function(response) {
          if (response.body.created) {
            this.$root.popUpMessage("Created Banner");
          } else {
            this.$root.popUpMessage("Banner Updated successfully");
          }
          this.reloadBanners(response.body.banner_id);
        });
      }else{
        this.$root.popUpMessage("Please Upload File");
      }
    },
    deleteBanner() {
      var self = this;
      this.$modal.show('dialog', {
        text: 'Are you sure you want to delete the banner?',
        buttons: [{
            title: 'Cancel',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Yes',
            handler: () => {
              self.$http.delete('/delete_banner/'+self.banner.id).then(function(response) {
                self.reloadBanners(response.body.banner_id);
                self.$modal.hide('dialog')
                self.$root.popUpMessage("Deleted Banner");   
              });
            }
          }
        ]
      })
    },
    muteVideo() {
      var videoID = "VideoPlayer_"+this.banner.id
      if (this.banner.video_url.url) {
        this.loadIframeVideo(videoID)
      } else {
        var video=document.getElementById(videoID);
        if (this.video_muted == video.muted) {
          video.muted = !video.muted
        }
      }
      this.video_muted = !this.video_muted
    },
    loadIframeVideo(iFrameID) {
      if (this.banner.video_url.url.includes('youtube')) {
        onYouTubePlayerAPIReady(iFrameID, this.banner.id)
      } else {
        onVimeoPlayerAPIReady(iFrameID, this.banner.id)
      }
    },
  }
})

Vue.component('user-profile-details', {
  template: `<div class="personal_information margin_top_30">
              <div class="user_personal_details">
                <div class="row mx-0">
                  <div class="col-sm-12 col-md-3 col-lg-3 px-0">
                  <div class="d-flex align-items-start justify-content-start">
                    <img class="user_img" :src="personal_information.image_url" width="150px" height="150px" :title="personal_information.image_url ? '' : 'Upload Profile Image'">
                    <span class="pl-2 cursor_pointer" title="Upload Profile Image" @click="uploadProfileImage"> <i data-toggle="tooltip" data-placement="top" title="Edit" class="fad fa-pencil lxa_text_color_05"></i> </span>
                    <input type='file' ref="inputFile" accept="image/*" id="updateProfileImage" @change="uploadedFile" style="display: none"/>
                  </div>
                  </div>
                  <div class="col-sm-12 col-md-9 col-lg-9 form_fields px-0">
                    <div class="form_group">
                      <label> {{$t('account_setting.first_name')}} </label> 
                      <input type="text" v-model="personal_information.user_name" @change="toggleDataAltered(true)"> </input>
                    </div>
                    <div class="form_group">
                      <label> {{$t('account_setting.last_name')}} </label> 
                      <input type="text" v-model="personal_information.user_lastname" @change="toggleDataAltered(true)"> </input>
                    </div>
                    <div class="form_group">
                      <label> {{$t('account_setting.email_id')}} </label> 
                      <input type="text" v-model="personal_information.email"> </input>
                    </div>
                    <!-- <div class="form_group">
                      <label> {{$t('account_setting.password')}} </label> 
                      <input :type="password_type ? 'password' : 'text' " v-model="personal_information.password"> </input>
                      <span class="eye_slash cursor_pointer" @click="password_type = !password_type" data-toggle="tooltip" data-placement="top" title="Show Password" :key="password_type"><i class="fad fa-eye lxa_text_color_05" :class="[!password_type ? 'fa-eye-slash' : 'fa-eye']"></i></span>
                    </div> -->
                  </div>
                </div>
                <div class="row margin_0">
                  <div class="col-sm-12 col-md-12 col-lg-12 padding_0">
                    <div class="form_group">
                      <label> {{$t('user_profile.profile_summary')}} </label>
                      <span class="limiter float_right">{{charactersLeft}}</span>
                      <textarea class="profile_summary txtarea-resize" v-model="personal_information.user_description" :maxlength="maxlength" @change="toggleDataAltered(true)"> </textarea>
                    </div>
                  </div>
                </div>
                <div class="form_action d-flex align-items-center justify-content-end">
                  <a class="btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" @click="cancelChanges" data-dismiss="modal">{{$t('account_setting.cancel')}}</a>
                  <a class="btn lxa_bg_01 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase ml-3" @click="saveChanges">{{ $t('account_setting.save')}}</a>
                </div>
              </div>
              <div class="row margin_0 user_skills_interest margin_top_30">
                <div class="col-12 achievements pl-0">
                  <achievement-section :show_achievement="true" :from_profile="true" :selected_achievements="achievements"> </achievement-section> 
                </div>
                <div class="col-12 skills">
                  <skills-section :user_skills="user_skills"> </skills-section>
                </div>
                <div class="col-12 interest pr-0">
                  <interest-section :selected_interest="selected_interest" :interest_options="interest_options"> </interest-section>
                </div>
              </div>
            </div>`,
  props: ["personal_information", "achievements", "user_skills", "selected_interest", "interest_options"],
  data() {
    return {
      password_type: true,
      maxlength: 5000
    }
  },
  components: {
    Datepicker,
  },
  methods: {
    uploadProfileImage: function() {
      document.getElementById('updateProfileImage').click();
    },
    uploadedFile: function() {
      var inputPicture = this.$refs.inputFile.files[0];
      this.$parent.personal_information.image_url = URL.createObjectURL(inputPicture);
      // let formData = new FormData();
      // formData.append('picture', this.inputPicture);
      // this.$http.post('/upload_profile', formData).then(response => {
      //   if(response.data.message == 'success'){
      //     this.$root.popUpMessage("Successfully Updated !!");
      //     this.$parent.personal_information.image_url = response.data.image_url;
      //     this.$parent.$parent.$children[0].profile_image_url = response.data.image_url;
      //   }
      //   else{
      //     this.$root.popUpMessage("Update Failed - "+response.data.message);
      //   }
      // },
      // response => {
      //   this.$root.popUpMessage("Update Failed - "+response.data.message)
      // });
    },
    toggleDataAltered(value) {
      this.$parent.data_altered = value
    },
    cancelChanges() {
      this.$parent.loadUserData();
      this.toggleDataAltered(false);
    },
    saveChanges() {
      let formData = new FormData();
      var inputPicture = this.$refs.inputFile.files[0];
      formData.append('user[firstname]', this.personal_information.user_name);
      formData.append('user[lastname]', this.personal_information.user_lastname);
      formData.append('user[description]', this.personal_information.user_description);
      if (typeof inputPicture !== 'undefined') {
        formData.append('picture', inputPicture);
      }
      this.$http.post('/upload_profile', formData).then(response => {
        if (response.body.message) {
         this.$root.popUpMessage(response.body.message) 
        } else {
          this.$root.popUpMessage("Successfully Updated !!")
          this.toggleDataAltered(false); 
          this.$parent.personal_information.image_url = response.data.image_url;
          this.$parent.$parent.$children[0].profile_image_url = response.data.image_url;
          EventBus.$emit("updateProfileData")
        }
      });
    }
  },
  computed: {
    charactersLeft() {
      if (this.personal_information.user_description != undefined) {
        var char = this.personal_information.user_description.length,
        limit = this.maxlength;

        return (limit - char) + " / " + limit + " characters remaining";
      }
      return ''
    }
  }
});

Vue.component('user-profile-orders', {
  template: `<div class="user_purchase_history">
              <div v-if="items.length>0">
                <div class="row margin_0 pdf_actions">
                  <div class="col-md-10 p-0"> </div>
                  <div class="col-md-2 p-0 text-right"> 
                    <span v-print="'#printMe'" class="cursor_pointer print" title="Print" data-toggle="tooltip" data-placement="top"><i class="fad fa-print fa-lg lxa_text_color_07 lxa_font_size_04"></i></span>
                    <span @click="generateReport()" class="ml-3 cursor_pointer export" title="Export" data-toggle="tooltip" data-placement="top"> <i class="fad fa-share-square fa-lg lxa_text_color_04 lxa_font_size_04"></i> </span>
                  </div>
                </div>
                <VueHtml2pdf
                  :show-layout="true"
                  :float-layout="false"
                  :enable-download="true"
                  :preview-modal="true"
                  :paginate-elements-by-height="1400"
                  filename="Orders"
                  :pdf-quality="2"
                  :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="landscape"
                  pdf-content-width="100%"
                  ref="html2Pdf"
                >
                  <section slot="pdf-content">
                  <div class="lxa_tbl_container mt-3 lxa_bg_05 lxa_border_radius">
                    <div class="order_table" id="printMe">
                      <b-table hover :items="items" sticky-header="true">
                        <template #cell(view_invoice)="data">
                          <span class="eye_slash cursor_pointer text-center" style="top: 0;" data-toggle="modal" data-target="#receipt_modal" @click="showReceipt(data)" data-backdrop="static"><i class="fad fa-eye lxa_text_color_05"></i></span>
                        </template>
                      </b-table>
                      <div class="modal" id="receipt_modal" role="dialog">
                        <div class="modal-dialog modal-xl mt-5" style="width: 100%;">
                          <div class="modal-content">
                            <ItemDetails :item="currentItem"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="text_align_center mt-2" v-if="show_load_more">
                      <a class="_load_more btn lxa_bg_04 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase mb-3" @click="loadMore"> {{$t('account_setting.load_more')}} </a>
                    </div>
                    </div>
                  </section>
                </VueHtml2pdf>
              </div>
              <div v-else class="row mx-0">
                    <div class="col-12 d-flex align-items-center justify-content-center no_purchase_history" > No purchase history found. </div>
              </div>

            </div>`,
  props: ["items", "order_page", "show_load_more"],
  data() {
    return {
      currentItem: null
    }
  },
  components: {
    VueHtml2pdf
  },
  mounted: function() {
    setTimeout(function(){ 
      jQuery("#printMe").slimScroll({
        barClass:'lxascroll',
        size : '3px',
        height: '535px',
        width: '100%',
        position : 'right',
        start : 'top',
        alwaysVisible : false,
        distance: '0',
        borderRadius: '0',
        // railVisible: true,
        railBorderRadius:0,
        opacity:1,
        allowPageScroll: true,
        touchScrollStep:200,
        enableKeyNavigation: true
      });
    }, 20);
  },
  methods: {
    loadMore() {
      this.$parent.order_page = this.$parent.order_page + 1
    },
    showReceipt(order) {
      // this.currentItem = order.item;
      this.$http.get('/get_order_details/'+order.item.order_no).then(function(response) {
        this.currentItem = response.body
      })
    },
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    }
  }
});

Vue.component('user-profile-preferences', {
  template: `<div class="notification_settings margin_top_30">
              <div class="row margin_0">
                <div class="col-md-4 form_field p-0 pr-2">
                  <label> Time Zone </label>
                  <multiselect v-model="selected_zone" :options="all_time_zones" label="name" track-by="name"> </multiselect>
                </div>
                <div class="col-md-4 form_field p-0 pl-2">
                  <label> Date Format </label>
                  <multiselect v-model="selected_date" :options="all_date_formats" label="name" track-by="name"> </multiselect>
                </div>
              </div>
              <div v-for="preference in user_preferences" class="row p-0 margin_left_0 margin_top_15">
                <div class="col-md-12 p-0 margin_left_0">
                  <span> <toggle-button :value="preference.preference" :color="{checked: '#5ec968', unchecked: '#f34423'}" :sync="true" @change="onChangeEventHandler(preference)"/> </span>
                  <span class="ml-3"> {{ preference.name }} </span>
                </div>
              </div>
              <div class="form_action d-flex align-items-center justify-content-end">
                <a class="btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" role="button" @click="cancelChanges" data-dismiss="modal"> {{ $t('account_setting.cancel') }} </a>
                <a class="btn lxa_bg_01 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase ml-3" @click="saveChanges"> {{ $t('account_setting.save') }} </a>
              </div>
            </div>
            `,
  props: ["user_preferences", "all_time_zones", 'selected_zone', "all_date_formats", "selected_date"],
  data() {
    return {
    }
  },
  watch: {
    selected_zone() {
      this.toggleDataAltered(true)
    },
    selected_date() {
      this.toggleDataAltered(true)
    }
  },
  components: {
    Multiselect
  },
  methods: {
    toggleDataAltered(value) {
      this.$parent.data_altered = value
    },
    onChangeEventHandler(preference) {
      for (var i = 0; i < this.$parent.user_preferences.length; i++) {
        if (this.$parent.user_preferences[i].name == preference.name) {
          this.$parent.user_preferences[i].preference = !this.$parent.user_preferences[i].preference
          this.toggleDataAltered(true)
        }
      }
    },
    saveChanges() {
      var data = {}
      if (this.selected_zone.length != 0 ) {
        data['user_timezone'] = this.selected_zone.id
      }
      if (this.selected_date.length != 0 ) {
        data['user_dateformat'] = this.selected_date.id
      }
      data['preference_enabled_ids'] = []
      for (var i = 0; i < this.user_preferences.length; i++) { 
        if (this.user_preferences[i].preference) {
          data['preference_enabled_ids'].push(this.user_preferences[i].id) 
        }
      }
      this.$http.post('/user_disabled_preference', data).then(function(response) {
        this.$root.popUpMessage("Preference Updated successfully!!")
        this.toggleDataAltered(false);
      });
    },
    cancelChanges() {
      this.$parent.loadUserData();
      this.toggleDataAltered(false);
    }
  }
});

Vue.component('skills-section', {
  template: `<div>
              <div class="row mx-0" v-if="!addNewSkill">
                <div class="col-10 px-0 mb-3">
                  <span class="lxa_font_size_04 lxa_medium_font"> Skills </span>
                </div>
                <div class="col-2 px-0" v-if="!from_profile_view">
                 <div class="d-flex align-items-center justify-content-end">
                  <span class="cursor_pointer mr-3" @click="addNewSkill = true" data-toggle="tooltip" data-placement="top" title="Add Skill"> <i class="fad fa-plus lxa_text_color_05"></i> </span>
                  <span class="cursor_pointer lxa_font_size_04" @click="toggleSkill" data-toggle="tooltip" data-placement="top" title="Edit Skill"> <i class="fad fa-pencil lxa_text_color_01"></i> </span>
                 </div>
                </div>
              </div>
              <div class="row mx-0" v-if="addNewSkill" style="box-sizing: border-box;">
                <div class="col-10 px-0 mb-3">
                  <span class="lxa_font_size_04 lxa_medium_font"> Skills </span>
                </div>
                <div class="col-6 px-0">
                <div class="form_group">
                  <input placeholder="Skill Name" type="text" v-model="new_skill" @click="toggleDataAltered(true)" />
                </div>
                </div>
                <div class="col-6 px-0 pl-2">
                <div class="form_group">
                  <input placeholder="Skill Percent" type="text" v-model="new_skill_percent" @click="toggleDataAltered(true)"/>
                </div>
                </div>
                <div class="col-12 p-0 mt-2">
                <div class="form_group d-flex align-items-center justify-content-end">
                  <a class="btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase mr-3" @click="cancelChanges">Cancel</a>
                  <a class="btn lxa_bg_01 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" @click="saveNewSkill">Save</a>
                </div>
                </div>
              </div>
              <div class="class_overflow" v-else>
                <div class="row mx-0 mb-3 lxa_font_size_04" v-for='skill in user_skills'>
                  <div :class="[{'col-10': editSkill}, {'col-10': !editSkill}, 'px-0']"> <span class="d-flex align-items-center justify-content-start h-100"> {{ skill.name }} </span> </div>
                  <div class="col-2 p-0"> 
                  <div class="d-flex align-items-center justify-content-end">
                    <span :id="skill.id"> <skill-circle :progress="skill.percent" :skill="skill" :from_profile_view="from_profile_view"> </skill-circle></span> 
                    <span v-if="editSkill" class="cursor_pointer lxa_font_size_04 ml-3" @click="deleteSkill(skill)" data-toggle="tooltip" data-placement="top" title="Delete Skill"> <i class="fad fa-trash-alt lxa_text_color_01"></i> </span> </div>
                  </div>
                </div>
              </div>
            </div>`,
  data() {
    return {
      new_skill: '',
      new_skill_percent: 0,
      addNewSkill: false,
      editSkill: false,
      circular_progress: ''
    }
  },
  props: {
    user_skills: Array,
    from_profile_view: {default: false, type: Boolean}
  },
  watch: {
    addNewSkill() {
      this.toggleDataAltered(this.addNewSkill)
    }
  },
  updated: function() {
    this.LoadScroll();
  },
  methods: {
    LoadScroll: function() {
      jQuery(".class_overflow").slimScroll({
        barClass:'lxascroll',
        size : '3px',
        width: '100%',
        height : '100%',
        position : 'right',
        start : 'top',
        alwaysVisible : false,
        distance: '0',
        borderRadius: '0',
        // railVisible: true,
        railBorderRadius:0,
        opacity:1,
        allowPageScroll: true,
        touchScrollStep:200,
        enableKeyNavigation: true
      });
    },
    defaultSkillValues() {
      this.new_skill = ''
      this.new_skill_percent = 0
    },
    toggleDataAltered(value) {
      this.$parent.$parent.data_altered = value
    },
    cancelChanges() {
      this.addNewSkill = false
      this.defaultSkillValues()
    },
    saveNewSkill() {
      if (this.new_skill != '' && this.new_skill_percent != '') {
        var data = {}
        data['user_skill'] = {}
        data['user_skill']['name'] = this.new_skill
        data['user_skill']['percent'] = this.new_skill_percent
        this.$http.post('/user_skills', data).then(function(response) {          
          this.$root.popUpMessage(response.body.success);
          this.addNewSkill = false
          this.$parent.$parent.user_skills = response.body.user_skills
          this.defaultSkillValues()
          EventBus.$emit("updateProfileData")
        });
      } else {
        this.$root.popUpMessage("Enter SkillDetails");
      }
    },
    deleteSkill(skill) {
      this.$modal.show('dialog', {
        text: 'Are you sure you want to remove your skill ?',
        buttons: [{
            title: 'Cancel',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Yes',
            handler: () => {
              this.$http.delete('/user_skills/'+skill.id).then(function(response) {
                this.$modal.hide('dialog')
                this.editSkill = false
                this.$parent.$parent.user_skills = response.body.user_skills
                this.$root.popUpMessage(response.body.success);
                EventBus.$emit("updateProfileData")
              });
            }
          }
        ]
      })
    },
    toggleSkill() {
      this.editSkill = !this.editSkill
    },
    skillPercent() {
      // this.circular_progress = circle_animation("#"+this.progress_id,'#52e471','#c5c5c5',7)
      // this.circular_progress.animate(progress/100);
    }
  }
});

Vue.component('interest-section',{
  template: `<div>
              <div class="row margin_0 p-0" v-if="!addNewInterest">
                <div class="col-10 p-0">
                  <span class="lxa_font_size_04 lxa_medium_font"> Interest </span>
                </div>
                <div class="col-2 p-0" v-if="!from_profile_view">
                  <span class="cursor_pointer d-flex align-items-center justify-content-end" @click="addNewInterest = true" data-toggle="tooltip" data-placement="top" title="Edit"> <i class="fad fa-pencil lxa_text_color_01"></i> </span>
                </div>
              </div>
              <div class="row margin_0" v-if="addNewInterest">
                <div class="col-10 p-0">
                  <span class="lxa_font_size_04 lxa_medium_font"> Interest </span>
                </div>
                <div class="col-12 form_group p-0 margin_top_15">
                  <multiselect v-model="local_selected_interest" :options="interest_options" :multiple="true" label="name" track-by="name"> </multiselect>
                </div>
                <div class="col-12 form_group p-0 mt-2 d-flex align-items-center justify-content-end">
                  <a @click="cancelInterest" class="btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase mr-3"> Cancel </a>
                  <a @click="saveInterest" class="btn lxa_bg_01 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase"> Save </a>
                </div>
              </div>
              <div v-else="" class="row margin_0 interest_list">
                <span v-for="interest in local_selected_interest" :key="interest.id" :style="{'background-color': backgroundColors[interest.id % 4]}" class="mr-2 mt-2 lxa_font_size_04">
                  {{ interest.name }}
                </span>
              </div>
            </div>`,
  props: {
    selected_interest: Array,
    interest_options: Array,
    from_profile_view: {default: false, type: Boolean}
  },
  data() {
    return {
      addNewInterest: false,
      backgroundColors: ['#fb1c1c', '#51dbd4', '#59d959', '#cf9719'],
      local_selected_interest: this.selected_interest
    }
  },
  components: {
    Multiselect
  },
  watch: {
    addNewInterest() {
      this.toggleDataAltered(this.addNewInterest)
    },
    selected_interest() {
      this.local_selected_interest = this.selected_interest
    }
  },
  methods: {
    toggleDataAltered(value) {
      this.$parent.$parent.data_altered = value
    },
    cancelInterest() {
      this.addNewInterest = false;
      this.local_selected_interest = this.selected_interest;
      // this.$parent.$parent.loadUserData()
    },
    saveInterest() {
      var data = {}
      data['user_interest'] = {}
      data['user_interest']['selected_ids'] = []
      for (var i = 0; i < this.local_selected_interest.length; i++) { 
        data['user_interest']['selected_ids'].push(this.local_selected_interest[i].id) 
      }
      this.$http.post('/user_interests', data).then(function(response) {
        this.addNewInterest = false
        this.$root.popUpMessage("Successfully Updated !!")
        EventBus.$emit("updateProfileData")
      });
    }
  }
});

Vue.component('all-items-lightbox',{
  template: `<div class="modal" id="all_items_lightbox" tabindex="-1" role="dialog">
              <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content lxa_border_radius">
                  <p class="light_box_close" data-dismiss="modal"> <i data-toggle="tooltip" data-placement="top" title="Close" class="fad fa-times lxa_text_color_08 lxa_font_size_06 cursor_pointer"></i></p>
                  <div class="modal-header border-bottom-0 pb-0">
                    <div class="col-4 p-0 mt-2">  
                      <div class="font_barlow_semi_condensed_light_24">What I am doing</div>
                    </div>
                    <div class="col-8 p-0 lxa-set-height">  
                      <filter_and_sort from_section="all_items" :autocomplete_url="autocomplete_url" :selected="selected_tab"></filter_and_sort>
                    </div>
                  </div>
                  <div class="modal-body pt-0">
                    <div class="row mx-0 mb-2">
                     <div class="col-12 px-0">
                      <applied-filter v-bind:AppliedFilters="AppliedFilters" :fromPage="'Mylearing'" isIndex="0"></applied-filter>
                    </div>

                    </div>

                    <div class="row mx-0" id="upcomingitemscontainer">
                    <div class="col-12 px-0">
                    <div class="lxa_section_title_light lxa_mlntocomplete" data-toggle="collapse"  aria-expanded="false" href="#mlnTocomplete">
                      
                      <div class="custom-control custom-checkbox p-0">
                      <input type="checkbox" id="mlnTocomplete-accordiononoff"  class="custom-control-input lxa-bs-collapse" key="mlnTocomplete-accordiononoff"  checked /> 
                      <label for="mlnTocomplete-accordiononoff" class="custom-control-label d-flex align-items-center justify-content-start">
                      <span class="mr-2 lxp-title-04">To-complete <span class="pl-2">({{upcoming_hash.total_count}})</span></span> 
                      <i class="fad fa-angle-down lxa_font_size_08 lxa_text_color_06"></i> 
                      <i class="fad fa-angle-up lxa_font_size_08 lxa_text_color_06"></i>
                      </label>
                      </div>

                    </div>
                    
                    <div id="mlnTocomplete" class="collapse show" data-parent="#upcomingitemscontainer">



                    <div id="upcoming_container" class="all_item_container mt-2">  
                      <list-item-tile v-for="item in upcoming_hash.items" :item="item" :key="item.item_type+'_'+item.id" :logged_in="true" ref="all_item" :from_catalog="false"/>
                    </div>
                    <div v-if="upcoming_hash.current_page < upcoming_hash.total_pages" class="loadmore d-flex mt-4 mb-5">
                      <a @click="seeMore(upcoming_hash.url,upcoming_hash.current_page,'upcoming')" class="mx-auto btn lxa_bg_01 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase"> Load More </a>
                    </div>

                    </div>

                    </div>
                    </div>


                    <div class="row mx-0" id="completeditemscontainer">
                    <div class="col-12 px-0">
                    <div class="lxa_section_title_light lxa_mlncomplete" data-toggle="collapse"  aria-expanded="false" href="#mlnComplete">
                     
                     <div class="custom-control custom-checkbox p-0">
                      <input type="checkbox" id="mlnComplete-accordiononoff" class="custom-control-input lxa-bs-collapse" key="mlnComplete-accordiononoff"  checked /> 
                      <label for="mlnComplete-accordiononoff" class="custom-control-label d-flex align-items-center justify-content-start">
                      <span class="mr-2 lxp-title-04">Completed <span class="pl-2">({{completed_hash.total_count}})</span></span> 
                      <i class="fad fa-angle-down lxa_font_size_08 lxa_text_color_06"></i> 
                      <i class="fad fa-angle-up lxa_font_size_08 lxa_text_color_06"></i>
                      </label>
                      </div>

                    </div>
                    
                    <div id="mlnComplete" class="collapse show" data-parent="#completeditemscontainer">

                   

                    <div id="completed_container" class="all_item_container mt-2">  
                      <list-item-tile v-for="item in completed_hash.items" :item="item" :key="item.item_type+'_'+item.id" :logged_in="true" ref="all_item" :from_catalog="false"/>
                    </div>
                    <div v-if="completed_hash.current_page < completed_hash.total_pages" class="loadmore d-flex mt-4 mb-5">
                      <a @click="seeMore(completed_hash.url,completed_hash.current_page,'completed')" class="mx-auto btn lxa_bg_01 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase"> Load More </a>
                    </div>

                   </div>
                </div>
                </div>

                <div class="row mx-0" id="otheritemscontainer">
                    <div class="col-12 px-0">
                    <div class="lxa_section_title_light lxa_mlnothers" data-toggle="collapse"  aria-expanded="false" href="#mlnOthers">
                    
                     <div class="custom-control custom-checkbox p-0">
                      <input type="checkbox" id="mlnOthers-accordiononoff" class="custom-control-input lxa-bs-collapse" key="mlnOthers-accordiononoff"  checked /> 
                      <label for="mlnOthers-accordiononoff" class="custom-control-label d-flex align-items-center justify-content-start">
                      <span class="mr-2 lxp-title-04">Others <span class="pl-2">({{incompleted_hash.total_count}})</span></span> 
                      <i class="fad fa-angle-down lxa_font_size_08 lxa_text_color_06"></i> 
                      <i class="fad fa-angle-up lxa_font_size_08 lxa_text_color_06"></i>
                      </label>
                      </div>

                    </div>
                    <div id="mlnOthers" class="collapse show" data-parent="#otheritemscontainer">

                    

                    <div id="incomplete_container" class="all_item_container mt-2">  
                      <list-item-tile v-for="item in incompleted_hash.items" :item="item" :key="item.item_type+'_'+item.id" :logged_in="true" ref="all_item" :from_catalog="false"/>
                    </div>
                    <div v-if="incompleted_hash.current_page < incompleted_hash.total_pages" class="loadmore d-flex mt-4 mb-5">
                      <a @click="seeMore(incompleted_hash.url,incompleted_hash.current_page,'incomplete')" class="mx-auto btn lxa_bg_01 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase"> Load More </a>
                    </div>
                   </div>

                   </div>
                   </div>

                  </div>
                </div>
              </div>
            </div>`,
  props: {
    upcoming_items: Object,
    completed_items: Object,
    incompleted_items: Object,
    autocomplete_url: String,
    selected_tab: String
  },
  data: function() {
    return {
      upcoming_hash: this.upcoming_items,
      completed_hash: this.completed_items,
      incompleted_hash: this.incompleted_items,
      all_items_url: '',
      AppliedFilters: [],
    }
  },
  mounted: function() {
    // jQuery(".all_item_container").niceScroll({cursorcolor:"#4184F3",cursorwidth:"6px",autohidemode: "scroll"});
    var self = this;
    $('#all_items_lightbox').on('hidden.bs.modal', function () {
      self.upcoming_hash = self.upcoming_items;
      self.completed_hash = self.completed_items;
      self.incompleted_hash = self.incompleted_items; 
      self.$parent.InProgress();
    });

 this.$nextTick(() => {            
                 this.ExpandcollapseMylearn();
                });
  },
  watch: {
    upcoming_items() {
      this.upcoming_hash = this.upcoming_items;
this.ExpandcollapseMylearn();
    },
    completed_items() {
      this.completed_hash = this.completed_items;
this.ExpandcollapseMylearn();
    },
    incompleted_items() {
      this.incompleted_hash = this.incompleted_items;
     this.ExpandcollapseMylearn();
    }
  },
    updated () {
                this.$nextTick(() => {            
                 this.ExpandcollapseMylearn();
                });
            },
  methods: {
    ExpandcollapseMylearn: function() {
      console.log("ExpandcollapseMylearn trigger");
      $("#mlnTocomplete").collapse('show');
      $("#mlnComplete").collapse('show');
      $("#mlnOthers").collapse('show');
      $("#mlnTocomplete-accordiononoff").prop('checked', true);
      $("#mlnComplete-accordiononoff").prop('checked', true);
      $("#mlnOthers-accordiononoff").prop('checked', true);
    },
    seeMore: function(url,current_page,tab) {
      var params = this.$children[0].requestParams();
      var params_per_page = params + '&page='+(current_page+1)+'&per_page='+4
      this.$http.get(url+'?'+params_per_page).then(function(response) {
        if (tab=='upcoming') {
          this.upcoming_hash.items = this.upcoming_hash.items.concat(response.body.in_progress_items);
          this.upcoming_hash.current_page = response.body.current_page;
          this.upcoming_hash.total_pages = response.body.total_pages;
        } else if (tab=='completed') {
          this.completed_hash.items = this.completed_hash.items.concat(response.body.in_progress_items);
          this.completed_hash.current_page = response.body.current_page;
          this.completed_hash.total_pages = response.body.total_pages;
        } else {
          this.incompleted_hash.items = this.incompleted_hash.items.concat(response.body.in_progress_items);
          this.incompleted_hash.current_page = response.body.current_page;
          this.incompleted_hash.total_pages = response.body.total_pages;
        }
      });
    },
    open: function(product, share_popup_id){
      this.$parent.open(product, share_popup_id, true)
    },
    remove_filter: function(ref,display_id,display_name,filter_type,arr_index) {
      if (filter_type=='search_item') {
        this.$children[0].$children[0][filter_type] = ''
      } else {
        var index = this.$children[0].$children[1].$refs[ref][filter_type].indexOf(display_id)
        if (index !== -1) {
          this.$children[0].$children[1].$refs[ref][filter_type].splice(index, 1);
        }
      }
      this.AppliedFilters.splice(arr_index,1);
      this.$children[0].requestData();
    }
  }
});


Vue.component('user-profile-view', {
  template: `<div id="user_profile_view" class="user_profile_view lxa_mt_60" v-if="data_loaded">
              <div class="col-12 px-0"> 
                <div class="d-flex align-items-center justify-content-start">
                  <span class="user_profile_heading lxa_text_color_09 lxa_section_title_light lxa_font_size_08"> {{this.$root.header_details[4].text}} </span> 
                  <span data-toggle="modal" data-target="#user_profile_modal" data-backdrop="static" data-keyboard="false" class="ml-auto lxa_font_size_04 cursor_pointer" title="Modify"> <i title="Modify" data-toggle="tooltip" data-placement="top" class="fad fa-pencil lxa_text_color_01"></i> </span>
                </div>
              </div>
              <user-profile-details-view :personal_information="personal_information" :achievements="achievements" :user_skills="user_skills" :interest_options="interest_options" :selected_interest="selected_interest"> </user-profile-details-view>
            </div>`,
  data() {
    return {
      data_loaded: false,
      personal_information: {},
      achievements: [],
      user_skills: [],
      interest_options: [],
      selected_interest: []
    }
  },
  mounted() {
    this.loadUserData();
    this.data_loaded = true
    var _this = this;
    EventBus.$on("updateProfileData", function () {
      _this.loadUserData()
    })
  },
  methods: {
    loadUserData() {
      this.$http.get('/user_profile').then(function(response) {
        this.personal_information = response.body.personal_information
        this.achievements = response.body.achievements
        this.user_skills = response.body.user_skills  
        this.interest_options = response.body.interest_options  
        this.selected_interest = response.body.selected_interest
      });
    }
  }
})

Vue.component('user-profile-details-view', {
  template: `<div class="personal_information margin_top_30">
              <div class="user_personal_details lxa_font_size_04">
                <div class="row mx-0">
                  <div class="col-sm-12 pl-0">
                  <div class="d-flex align-items-start justify-content-start">
                    
                    <div class="lxa_profile_photo">
                    <img class="user_img img-fluid" :src="personal_information.image_url" />
                    </div>

                    <div class="lxa_profile_titdescdetails ml-3">
                    <div class="d-flex align-items-start justify-content-start">
                      <span class="lxa_font_size_06 lxa_text_color_06"> {{ personal_information.user_name }}&nbsp{{ personal_information.user_lastname }}</span>
                      <span class="lxa_text_color_01" v-if="personal_information.designation && personal_information.designation.length != 0">, {{ personal_information.designation}} </span>
                    </div>
                    <div v-show="truncate" id="user_profile_description" class="row mx-0 lxa_text_color_03 mt-2">
                      <span> {{ personal_information.user_description }} </span>
                    </div>
                    <div v-show="!truncate" class="row mx-0 lxa_text_color_03 mt-2">
                      <span> {{ personal_information.user_description }} </span>
                      <span v-on:click="ToggleContent"><i class="fad fa-chevron-up read_more_arrow"></i></span>
                    </div>
                    <div id="profile_desc_truncate" v-on:click="ToggleContent" class="hide"></div>

                   </div> 
                  </div>
                  </div>
                </div>
              </div>
              <div class="row mx-0 user_skills_interest_view margin_top_30">
                <div class="col-12 achievements">
                  <achievement-section :show_achievement="true" :from_profile="true" :selected_achievements="achievements" :from_profile_view="from_profile_view"> </achievement-section> 
                </div>
                <div class="col-12 skills">
                  <skills-section :user_skills="user_skills" :from_profile_view="from_profile_view"> </skills-section>
                </div>
                <div class="col-12 interest">
                  <interest-section :selected_interest="selected_interest" :interest_options="interest_options" :from_profile_view="from_profile_view"> </interest-section>
                </div>
              </div>
            </div>`,
  props: ["personal_information", "achievements", "user_skills", "selected_interest", "interest_options"],
  data() {
    return {
      from_profile_view: true,
      truncate: true
    }
  },
  updated: function() {
    ellipsis('#user_profile_description', 5, {
      responsive: true,
      replaceStr: '<span onClick="ShowMore(\''+'profile_desc_truncate'+'\')" class="cursor_pointer" data-toggle="tooltip" title="Click to see more.">...</span>'
    });
  },
  methods: {
    ToggleContent: function() {
      this.truncate = !this.truncate;
    }  
  }
});

Vue.component('skill-circle', {
  template: `<span :id="'skill_'+skill.id+'_'+from_profile_view">
              <span class="play_progress" :id="'skill_percent_'+skill.id+'_'+from_profile_view">
                <span> </span>
              </span>
            </span>`,
  props: {
    progress: Number,
    skill: Object,
    from_profile_view: Boolean
  },
  data() {
    return {
      circular_progress: ''
    }
  },
  mounted() {
    this.$nextTick(function () {
      jQuery('#skill_'+this.skill.id +'_'+this.from_profile_view+' .play_progress').find('span').html(this.progress+'%');
      if(this.circular_progress == '')
        this.circular_progress = circle_animation("#skill_percent_"+this.skill.id+'_'+this.from_profile_view,'#52e471','#c5c5c5',7)
      this.circular_progress.animate(this.progress/100);
    });
  }
});

Vue.component('playlist-content-player', {
  template: `<div class="modal position-fixed" id="playlist_content_player_modal" role="dialog">
                <div class="modal-xl">
                  <div class="modal-content user_account_setting" style="width: 80%; margin-left: 10%; top: 20px;">
                    <p class="light_box_close" data-dismiss="modal" @click="closeModal()" data-toggle="tooltip" data-placement="top" title="Close"> <i class="fad fa-times lxa_text_color_08 lxa_font_size_06 cursor_pointer"></i></p>
                    <content-player :active_content="active_content" :product="product" :logged_in="logged_in" :modal="true" v-if="loadPlayer"> </content-player>
                  </div>
                </div>
            </div>`,
  data() {
    return {
      logged_in: true,
      active_content: null,
      product: {},
      loadPlayer: false
    }
  },
  mounted() {
    var _this = this;
    this.$root.$on('launchContentPlayer', function(data) {
      _this.loadPlayer = false
      _this.product = {}
      _this.$parent.product = {}
      _this.$http.get('/playlist_item/'+data.playlist_id+'/'+data.content_id).then(function(response) {
        _this.product = response.data.playlist_item
        _this.$parent.product = response.data.playlist_item
        _this.loadPlayer = true
        setTimeout(function() { 
          _this.$root.$emit('load-content-player', _this.product)
        }, 500);
      })
    });
    jQuery('#playlist_content_player_modal').on('hidden.bs.modal', function() {
      _this.closeModal()  
    });
  },
  methods: {
    closeModal() {
      this.loadPlayer = false
      this.product = {}
    }
  }
});

Vue.component('manage-header', {
  template: `<div class="modal" id="manage_header_modal" role="dialog">
              <div class="modal-dialog modal-xl">
                <div class="modal-content lxa_border_radius">
                  <div> <h4 class="user_profile_heading lxa_text_color_04 lxa_font_size_08 lxa_light_font mb-4"> Manage Header </h4> </div>
                  <p class="light_box_close" data-dismiss="modal" data-toggle="tooltip" data-placement="top" title="Close"><i class="fad fa-times lxa_text_color_08 lxa_font_size_06 cursor_pointer"></i></p>

                  <div class="row mx-0 font_barlow_semi_condensed_light_16">
                    <div class="col-3 p-0">
                      <span class="create_blog_inner_text lxa_text_color_03 lxa_font_size_04 lxa_light_font">Logo</span>
                      <span class="float_right" @click="clearLogo()" data-toggle="tooltip" data-placement="top" title="Clear Logo"><i class="fad fa-trash-alt lxa_text_color_01 cursor_pointer"></i></span>
                      <div class="primary_bgcolor lxa_border_radius p-3 mt-3" v-if="logo_url.length != 0">
                      <img class="mt-2 w-100 h-50" :src="logo_url">
                      </div>
                      <label for="file-upload" class="blog_file_upload mt-2 lxa_border_radius lxa_bg_05 lxa_font_size_04 lxa_text_color_05" v-else> Upload Image</label>
                      <input id="file-upload" type="file" name="file" ref="files" @change="attachImageFile" style="display: none" accept="image/*"/>
                    </div>
                    <div class="col-9 p-0 text-right">
                      <div class="form-check form-check-inline">                      
                      <div id="remember_signin_wrapper" class="remember_signin_wrapper custom-controls custom-checkbox">
                          <input v-model="header_img" id="remember_signin" name="remember_signin" class="remember_signin custom-control-input lxa-bs-checkboxbtn" type="checkbox" value="remember">
                          <label class="string_trunk8 custom-control-label" for="remember_signin">
                            <i class="fad fa-stop lxa_icon_size lxa_text_color_07"></i>
                            <i class="fad fa-check-square lxa_icon_size lxa_text_color_06"></i>
                            <span class="signed_in_label lxa_text_color_03 lxa_font_size_04 lxa_light_font ml-1">Show Top Image</span>
                          </label>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="margin_top_15 font_barlow_semi_condensed_light_16">
                    <div class="footer_columns grid_5" v-if="content.length != 0">
                      <div class="column_data" v-for="column in header_columns">
                        <div class="row m-0">
                          <label class="col-12 p-0 mb-1 lxa_text_color_03 lxa_font_size_04 lxa_light_font"> {{ content[column-1].default_text }} </label>
                          <input class="primary_bgcolor w-100 p-2 height_40px lxa_border_radius border-0" type="text" v-model="content[column-1].text" :placeholder="content[column-1].default_text"> </input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row m-0 margin_top_30 font_barlow_semi_condensed_light_16">
                    <div class="col-12 p-0 form_action d-flex align-items-center justify-content-end">
                      <a class="btn btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase mr-3" data-dismiss="modal" v-on:click="CancelHeader"> {{ $t('account_setting.cancel') }} </a>
                      <a class="btn btn lxa_bg_01 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" @click="saveChanges"> {{ $t('account_setting.save') }} </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>`,
  data() {
    return {
      header_columns: [1,2,3,4,5],
      content: [],
      logo_url: '',
      file: '',
      header_img: true
    }
  },
  mounted() {
    this.fetchDetails()
  },
  methods: {
    fetchDetails() {
      var _this = this;
      this.$http.get(this.$root.get_header_details_url).then(response => {
        _this.content = response.body.header_details
        _this.logo_url = response.body.logo_url
        _this.header_img = response.body.header_img
      });
    },
    saveChanges() {
      let formData = new FormData();
      formData.append('logo', this.file);
      formData.append('content', JSON.stringify(this.content));
      formData.append('header_img', this.header_img);
      var _this = this;
      this.$http.post(this.$root.save_header_details_url, formData).then(response => {
        if (response.body.success) {
          $("#manage_header_modal").modal('hide');
          $("#manage_header_modal").modal('hide');
          _this.$root.popUpMessage("Saved Successfully");
          $("#fetch_header_data").click()
        }
      });
    },
    attachImageFile() {
      this.file = this.$refs.files.files[0];
      this.logo_url = URL.createObjectURL(this.file);
    },
    clearLogo() {
      this.logo_url = ''
    },
    CancelHeader: function() {
      this.fetchDetails();
    }
  }
});

Vue.component('manage-footer', {
  template: `<div class="modal" id="manage_footer_modal" role="dialog">
              <div class="modal-dialog modal-xl">
                <div class="modal-content lxa_border_radius">
                  <div> <h4 class="user_profile_heading lxa_text_color_06 lxa_font_size_08 lxa_light_font mb-4"> Manage Footer </h4> </div>
                  <p class="light_box_close" data-dismiss="modal"><i data-toggle="tooltip" data-placement="top" title="Close" class="fad fa-times lxa_text_color_08 lxa_font_size_06 cursor_pointer"></i></p>
                  <div class="lxa_text_color_03 lxa_font_size_04 lxa_light_font">
                    Select Number of Columns required
                  </div>
                  <div class="mt-1 font_barlow_semi_condensed_light_16">
                    <select @change="selected_value" class="select_footer_columns lxa_border_radius">
                      <option v-for="number in dropdown_lists" :value="number" :id="'select_footer_columns_'+number"> {{ number }} </option>
                    </select>
                  </div>
                  <div class="margin_top_15 font_barlow_semi_condensed_light_16">
                    <div :class="getColumnClass">
                      <div class="column_data" v-for="(column, index) in getTotalColumns">
                        <div class="row m-0">
                          <label class="col-12 p-0 mb-1 lxa_text_color_03 lxa_font_size_04 lxa_light_font"> Column {{ column }} </label>
                          <input class="primary_bgcolor w-100 p-2 height_40px lxa_border_radius border-0" type="text" v-model="content[column-1].text" :disabled="(index==0) ? true : false"> </input>
                        </div>

                        <div class="row m-0 margin_top_15">
                          <label class="col-12 p-0 mb-1 lxa_text_color_03 lxa_font_size_04 lxa_light_font"> Content </label>
                          <textarea class="primary_bgcolor w-100 p-2 lxa_border_radius border-0 txtarea-resize" v-model="content[column-1].content" :disabled="(index==0) ? true : false"> </textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mx-0 margin_top_30 font_barlow_semi_condensed_light_16">
                    <div class="col-12 p-0 form_action d-flex align-items-center justify-content-end">
                      <a class="btn btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase mr-3" data-dismiss="modal"> {{ $t('account_setting.cancel') }} </a>
                      <a class="btn btn lxa_bg_01 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" @click="saveChanges"> {{ $t('account_setting.save') }} </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>`,
  data() {
    return {
      no_of_columns: 1,
      dropdown_lists: [1,2,3,4,5],
      content: [
        {text: 'Powered By', content: '<img src="/images/new_ui/Learnexa_Logo_Reversed.svg" class="footerLogo">'}
      ]
    }
  },
  components: {
    'select2': Select2
  },
  watch: {
    no_of_columns(new_val, old_val) {
      new_val = parseInt(new_val)
      var _this = this
      if (new_val > old_val) {
        Array.from({length: this.no_of_columns}, (_, i) => i + 1).forEach((value, index) => {
          if (_this.content[value - 1] == undefined) {
            _this.content.push({text: '', content: ''});
          }
        });
      } else {
        Array.from({length: (old_val - new_val)}, (_, i) => i + 1).forEach((value, index) => {
          _this.content.pop()
        });
      }
    }
  },
  computed: {
    getTotalColumns() {
      return Array.from({length: this.no_of_columns}, (_, i) => i + 1)
    },
    getColumnClass() {
      return "footer_columns grid_"+this.no_of_columns
    }
  },
  mounted() {
    this.fetchDetails()
  },
  methods: {
    fetchDetails() {
      var _this = this
      this.$http.get(this.$root.get_footer_details_url).then(response => {
        if (response.body.no_of_columns != 0) {
          _this.content = response.body.footer_details
          _this.no_of_columns = response.body.no_of_columns
          $('#select_footer_columns_'+response.body.no_of_columns)[0].selected = true
        }
      });
    },
    saveChanges() {
      var data = {}
      data['content'] = this.content
      data['no_of_columns'] = this.no_of_columns
      var _this = this
      this.$http.post(this.$root.save_footer_details_url, data).then(response => {
        if (response.body.success) {
          _this.$root.popUpMessage("Saved Successfully");
          $("#manage_footer_modal .light_box_close").click()
          $("#fetch_footer_data").click()
        }
      });
    },
    selected_value() {
      this.no_of_columns = event.target.value
    }
  }
})

Vue.component('reset-password', {
  template:`<div class="modal" id="update_password" role="dialog">
              <div class="modal-dialog modal-xl">
                  <div class="modal-content">
                    <div class="modal-body" id="forgot_password_body" style="background-image: url('/Bg3.svg');">
                      <img src="/images/new_ui/Awning.svg" class="img-fluid" id="awning_banner"/>                                                                 
                      <div class="row" id="forms">                        
                        <div class="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 col-md-12 col-sm-12">
                          <div class="container p-4">                                                     
                            <form class="form-horizontal" v-on:submit.prevent="onSubmit" role="form">
                              <div class="form-group margin_bottom_4 margin_top_min_3">
                                <div class="col-sm-12">
                                  <p class="lxa_text_color_02 lxa_font_size_05 lxa_light_font">{{$t('login.reset_password')}}</p>
                                </div>
                              </div>
                              <div class="form-group margin_bottom_8">
                                <div class="col-sm-12">
                                  <div class="d-flex align-items-center justify-content-start">
                                    <label class="col-form-label font_barlow_semi_condensed_light_14">Password <span class="lxa_text_color_01">*</span>
                                    </label>
                                    <div class="col-form-label ml-auto">
                                      <span class="invalid-feedback">
                                      <span class="font_barlow_semi_condensed_10_red" v-show="errors.firstByRule('password','required')">{{ $t('login.password_cant_blank') }}</span>
                                      <span class="font_barlow_semi_condensed_10_red" v-show="errors.firstByRule('password','min')">{{ $t('login.password_too_short') }}</span>
                                      <span class="font_barlow_semi_condensed_10_red error_field" id="password_error"></span>
                                    </span>
                                    </div>
                                  </div>
                                  <div class="row m-0 p-0">
                                   <input v-bind:type="[showPassword ? 'text' : 'password']" v-validate="'required|min:6'" data-vv-validate-on="none" name="password" class="form-control line_height_17 border-0 border_radius_9 lxa_txt_input lxa_font_size_03 lxa_text_color_02" v-model="password" ref="password" :placeholder="$t('login.password')">
                                   <span @click="showPassword=!showPassword" :key="showPassword"><i class="fad lxa_text_color_06 lxa_eye_icon" :class="[showPassword ? 'fa-eye-slash' : 'fa-eye']"></i></span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group margin_bottom_25">
                                <div class="col-sm-12">
                                <div class="d-flex align-items-center justify-content-start">
                                  <div class="col-6 text-left pl-0">
                                    <label class="col-form-label font_barlow_semi_condensed_light_14">Password Confirmation<span>&nbsp;</span><span class="lxa_text_color_01">*</span></label>
                                  </div>
                                      <div class="col-form-label ml-auto">
                                        <span class="invalid-feedback">  
                                        <span class="font_barlow_semi_condensed_10_red" v-show="errors.firstByRule('password_confirmation','required')">{{ $t('login.enter_password_confirmation') }}</span>
                                        <span class="font_barlow_semi_condensed_10_red" v-show="errors.firstByRule('password_confirmation','min')">{{ $t('login.password_confirmation_too_short') }}</span>
                                        <span class="font_barlow_semi_condensed_10_red" v-show="errors.firstByRule('password_confirmation','confirmed')">{{ $t('login.password_confirmation_compares_with_password') }}</span>
                                      </span>
                                      </div>
                                  </div>
                                  <input type="password" class="form-control  border-0 line_height_17 lxa_border_radius lxa_txt_input lxa_font_size_03 lxa_text_color_02" id="new_password_confirmation" :placeholder="'confirm password'" v-model="password_confirmation" name="password_confirmation" v-validate="'required|min:6|confirmed:password'" data-vv-validate-on="none" data-vv-as="password">
                                </div>
                              </div>
                              <hr>
                              <div class="form-group">        
                                <div class="d-flex align-items-center justify-content-end pr-3">
                                  <button class="btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04" data-dismiss="modal" type="button">{{$t('create_course.cancel').toUpperCase()}}</button> 
                                  <button type="submit" class="btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 ml-3">{{$t('common.reset').toUpperCase()}}</button>
                                </div>
                              </div>
                            </form>                           
                          </div>                
                        </div>                        
                      </div>
                      </div>
                  </div>
                </div>  
            </div>`,
  mounted: function() {
    $('#update_password').modal('show');
  },      
  data() {
    return {
      password: '',
      password_confirmation: '',
      message: 'login',
      showPassword: false,
    }
  },
  methods: {
    onSubmit() {
      this.$validator.validateAll().then(result => {
        console.log(result);
        if (result) {
          jQuery.ajax({
            type: 'PUT',
            url: "/update_password.json",
            data : jQuery('#update_password_form').serialize()+ "user[password]="+this.password+"&user[password_confirmation]="+this.password_confirmation,
            success: function(response) {
              if(response){
                $('#update_password').modal('hide');
              }
            }
          });
        }
      });    
    jQuery(".invalid-feedback").show();
    }
  }
});

Vue.component('forgot-password', {
  template:`<div class="modal" id="forgot_password" role="dialog">
                <div class="modal-dialog modal-xl">
                  <div class="modal-content">
                    <div class="modal-body" id="forgot_password_body" style="background-image: url('/Bg3.svg');">
                      <img src="/images/new_ui/Awning.svg" class="img-fluid" id="awning_banner"/>                                                                 
                      <div class="row" id="forms">                        
                        <div class="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 col-md-12 col-sm-12">
                          <div class="container p-4">                                                     
                            <form id='forgot_password_form' class="form-horizontal" v-on:submit.prevent="onSubmit" role="form">
                              <div class="form-group margin_bottom_4 margin_top_min_3">
                                <div class="col-sm-12">
                                  <p class="lxa_text_color_02 lxa_font_size_05 lxa_light_font">{{$t('login.reset_password')}}</p>
                                </div>
                              </div>
                              <div class="form-group margin_bottom_8">
                                <div class="col-sm-12">
                                  <div class="d-flex align-items-center justify-content-start">
                                    <label class="col-form-label font_barlow_semi_condensed_light_14">Password <span class="lxa_text_color_01">*</span>
                                    </label>
                                    <div class="col-form-label ml-auto">
                                      <span class="invalid-feedback">
                                      <span class="font_barlow_semi_condensed_10_red" v-show="errors.firstByRule('password','required')">{{ $t('login.password_cant_blank') }}</span>
                                      <span class="font_barlow_semi_condensed_10_red" v-show="errors.firstByRule('password','min')">{{ $t('login.password_too_short') }}</span>
                                      <span class="font_barlow_semi_condensed_10_red error_field" id="password_error"></span>
                                    </span>
                                    </div>
                                  </div>
                                  <div class="row m-0 p-0">
                                    <input v-bind:type="[showPassword ? 'text' : 'password']" v-validate="'required|min:6'" data-vv-validate-on="none" name="password" class="form-control line_height_17 border-0 border_radius_9 lxa_txt_input lxa_font_size_03 lxa_text_color_02" v-model="password" ref="password" :placeholder="$t('login.password')">
                                   <span @click="showPassword=!showPassword" :key="showPassword"><i class="fad lxa_text_color_06 lxa_eye_icon" :class="[showPassword ? 'fa-eye-slash' : 'fa-eye']"></i></span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group margin_bottom_25">
                                <div class="col-sm-12">
                                <div class="d-flex align-items-center justify-content-start">
                                  <div class="col-6 text-left pl-0">
                                    <label class="col-form-label font_barlow_semi_condensed_light_14">Password Confirmation<span>&nbsp;</span><span class="lxa_text_color_01">*</span></label>
                                  </div>
                                      <div class="col-form-label ml-auto">
                                        <span class="invalid-feedback">  
                                        <span class="font_barlow_semi_condensed_10_red" v-show="errors.firstByRule('password_confirmation','required')">{{ $t('login.enter_password_confirmation') }}</span>
                                        <span class="font_barlow_semi_condensed_10_red" v-show="errors.firstByRule('password_confirmation','min')">{{ $t('login.password_confirmation_too_short') }}</span>
                                        <span class="font_barlow_semi_condensed_10_red" v-show="errors.firstByRule('password_confirmation','confirmed')">{{ $t('login.password_confirmation_compares_with_password') }}</span>
                                      </span>
                                      </div>
                                  </div>
                                  <input type="password" class="form-control  border-0 line_height_17 lxa_border_radius lxa_txt_input lxa_font_size_03 lxa_text_color_02" id="new_password_confirmation" :placeholder="'confirm password'" v-model="password_confirmation" name="password_confirmation" v-validate="'required|min:6|confirmed:password'" data-vv-validate-on="none" data-vv-as="password">
                                </div>
                              </div>
                              <hr>
                              <div class="form-group">        
                                <div class="d-flex align-items-center justify-content-end pr-3">
                                  <button class="btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04" data-dismiss="modal" type="button">{{$t('create_course.cancel').toUpperCase()}}</button> 
                                  <button type="submit" class="btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 ml-3">{{$t('common.reset').toUpperCase()}}</button>
                                </div>
                              </div>
                            </form>                           
                          </div>                
                        </div>                        
                      </div>
                      </div>
                  </div>
                </div>
              </div>`,
  mounted: function() {
    $('#forgot_password').modal('show');
  },      
  data() {
    return {
      password: '',
      password_confirmation: '',
      message: 'login',
      showPassword: false,
    }
  },
  methods: {
    onSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          const urlParams = new URLSearchParams(window.location.search);
          var token = urlParams.get('reset_password_token')
          jQuery.ajax({
            type: 'PUT',
            url: "/users/password.json",
            data : jQuery('#forgot_password_form').serialize()+"&user[password]="+this.password+"&user[password_confirmation]="+this.password_confirmation+"&user[reset_password_token]="+token,
            success: function(response) {
              if(response){
                window.location = '/'
              }
            }
          });
        }
      });
      jQuery(".invalid-feedback").show();
    }
  }
});