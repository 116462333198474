import Vue from 'vue/dist/vue.esm'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'en',
  messages: {
    en: {
      login: {
        username: 'Username',
        or: 'or',
        email_address: 'E-Mail',
        password: 'Password',
        confirm_password: 'Confirm Password',
        keep_me_signed_in: 'Keep me signed in',
        forgot: 'Forgot Password',
        never_mind: 'Never mind',
        first_name: 'First Name',
        last_name: 'Last Name',
        by_clicking_signup: 'I agree with',
        terms_of_service: 'Terms of Service',
        privacy_policy: 'Privacy Policy',
        and: 'and',
        reset_message: 'Information to reset your password has been send to your email address. ',
        reset_password: 'Reset Password',
        new_password: 'New Password',
        after_clicking_reset: 'Your Password has been reset',
        signup_message: 'Thanks for signing up! You should receive an e-mail confirmation shortly.',
        enter_valid_email_address: 'Enter a valid email address',
        enter_password: 'Enter a password',
        enter_first_name: "Firstname is required",
        enter_last_name: "Lastname is required",
        enter_email: "Email is required",
        password_cant_blank: "Required",
        password_too_short: "Too short (Minimum 6 Chars)",
        enter_password_confirmation: "Required",
        password_confirmation_too_short: "too short (Min 6 Chars)",
        password_confirmation_compares_with_password: "Not matching"
      },
      common: {
        login: 'Sign In',
        sign_up: 'Sign Up',
        send: 'Send',
        cancel: 'Cancel',
        clear: 'clear',
        okay: 'Okay',
        reset: 'Reset',
        sign_out: 'Sign Out',
        save: 'Save',
        delete_string: 'Delete',
        edit: 'Edit',
        none: 'None',
        close: 'Close',
        wat_im_working_on: "What I'm working on",
        categories: 'Categories',
        available_items: 'Available Items',
        no_product_found: "Sorry, we couldn't find any matching courses.",
        try_search_again: 'Please modify your search and try again.',
        search_results: 'SEARCH RESULTS',
        recommended_courses: 'Recommended Courses',
        done: 'Done',
        like: 'Like',
        unlike: 'Unlike',
        reply: 'Reply',
        characters_remaining: 'Max Characters',
        apply: 'Apply',
        description: 'Description',
        from: 'From',
        to: 'To',
        location: 'Location',
        available_users: 'Available Users:',
        submit: 'Submit',
        bookmarks: 'Bookmarks',
        shared_items: 'Shared Items'
      },
      profile: {
        change_name: 'Change Name',
        change_email: 'Change Email',
        change_password: 'Change Password',
        current_password: 'Current Password',
        new_password: 'New Password',
        confirm_password: 'Confirm Password',
        after_changing_name: 'Name is updated successfully.',
        after_changing_email: 'Email is updated successfully.',
        after_changing_password: 'Password is updated successfully.',
        password_confirmation: 'Password Confirmation',
        enter_current_password: "Current password can't be blank"
      },
      message: {
        mail: 'Mail',
        to: 'To',
        subject: 'Subject',
        inbox: 'Inbox',
        sent: 'Sent',
        reply: 'Reply',
        subject_error: "Subject can't be blank",
        body_error: "Body can't be blank",
        recipient_error: "Recipient can't be blank",
        message_content: 'Message',
        after_send_message: 'Message Sent!',
        send_message: 'Your message has been sent',
        on: 'on',
        wrote: 'Wrote:',
        invalid_username: 'Invalid username'
      },
      header_menu: {
        home: 'Home',
        catalog: 'Catalog',
        groups: 'Groups',
        profile: 'Profile',
        reports: 'Reports',
        blog: 'Blog',
        manage_site: 'Manage Site'
      },
      store_design: {
        store_design: 'Store Design',
        layout: 'Layout',
        widgets: 'Widgets',
        manage_header: 'Manage Header',
        manage_footer: 'Manage Footer'
      },
      quick_access: {
        quick_access: 'Admin Quick Access',
        create_course: 'Create Course',
        create_banner: 'Create Banner',
        create_user: 'Create User',
        create_report: 'Create Report',
        create_bundle: 'Create Bundle',
        create_testimonial: 'Testimonial',
        others: 'Others',
        blog: 'Blog',
        filebox: 'Filebox',
        groups: 'Groups',
        playlist: 'Playlist',
        testimonials: 'Testimonials'
      },
      events: {
        calender: 'Calender',
        name: 'Name',
        description: 'Description',
        start: 'Start:',
        end: 'End:',
        upcoming: 'Upcoming',
        past: 'Past',
        clone: 'Clone',
        delete_message: 'Are you sure you want to delete this item?',
        error: 'Error',
        enter_name: "Name can't be blank",
        enter_start_time: "Start time can't be blank",
        enter_end_time: "End time can't be blank",
        start_time: 'Start time',
        end_time: 'End time'
      },
      sort: {
        sort_by: 'SORT BY',
        title_caps: 'TITLE',
        title: 'Title',
        a_z: 'A-Z',
        z_a: 'Z-A',
        pricing_caps: 'PRICING',
        pricing: 'Pricing:',
        h_to_l: 'High to Low',
        l_to_h: 'Low to High',
        rating_caps: 'RATING',
        rating: 'Rating:',
        relevance: 'RELEVANCE',
        most_recent: 'Most Recent',
        most_popular: 'Most Popular'
      },
      search: {
        search_str: 'Search',
        search_users: 'Search users'
      },
      filter: {
        text: 'FILTER',
        reset: 'Reset',
        price: 'PRICE',
        delivery_type: 'DELIVERY TYPE',
        quiz: 'Quiz',
        video: 'Video',
        audio: 'Audio',
        webbased: 'Web-based',
        presentation: 'Presentation',
        document_text: 'Document',
        survey: 'Survey',
        scrom: 'Scorm',
        package_text: 'Package',
        tincan: 'Tin Can',
        rating: 'RATING',
        language: 'LANGUAGE',
        duration: 'DURATION',
        duration_types: ['0-15 Mins','15-30 Mins','30-45 Mins','45-60 Mins','More than 60 Mins']
      },
      announcement: {
        text: 'Announcement',
        text_plural: 'Announcements',
        dismiss: 'Dismiss',
        write_an_announcement: 'Create Announcement',
        description: 'Description',
        characters_remaining: 'characters remaining',
        display_announcement: 'Display announcement:',
        announcement_cant_blank: "Announcement can't be blank",
        start_date_error: "Start Date can't be empty",
        never_end: 'Leave empty to never end',
        priority: 'Priority',
        normal: 'Low',
        urgent: 'High',
        who_should_see_this_announcement: 'Which participants should see this announcement?',
        search_courses: 'Search courses',
        selected_items: 'Selected items:',
        select_none: 'Select None',
        every_one: 'Everyone',
        available_items: 'Available Items:',
        select_all: 'Select All',
        end_date: 'End date',
        start_date: 'Start date',
        no_announcements: 'There are no Announcements'
      },
      enrolled_item_filter: {
        in_progress: 'In Progress',
        recently_completed: 'Recently Completed',
        all_completed: 'All Completed',
        cancelled: 'Cancelled',
        due_soon: 'Due Soon'
      },
      enrolled_item_more_link: {
        progress: 'Progress:',
        questions: 'Questions',
        cancel_text: 'Are you sure? \n(All your progress will be lost. If this is a paid item then it will have to be purchased again.)'
      },
      available_item_filter: {
        all: 'All',
        featured_items: 'Featured Items'
      },
      available_item_more_link: {
        preview: 'Preview',
        comment: 'Comment',
        share: 'Share',
      },
      content_progress: {
        completed: 'Completed'
      },
      add_to_cart: {
        full: 'FULL',
        subscribers_only: 'Subscribers only'
      },
      item: {
        content: 'Content',
        about_this_course: 'About this Course',
        review_and_ratings: 'Review and Ratings',
        write_a_review: 'Write a review',
        rate_this_item: 'Rate this item',
        review: '{count} review | {count} reviews',
        member: '{count} Member | {count} Members',
        additional_information: 'Additional Information',
        type_your_review: 'Type your review',
        prerequisites: 'Prerequisites',
        see_more: 'See More',
        see_less: 'See Less',
        created_by: 'Created By',
        last_updated: 'Last Updated',
        enrollments: '{count} Enrollment | {count} Enrollments',
        comments: '{count} Comment | {count} Comments',
        hide_reply: 'hide {count} reply | hide {count} replies',
        show_reply: 'view {count} reply | view {count} replies',
        hide_comment: 'hide {count} comment | hide {count} comments',
        show_comment: 'view {count} comment | view {count} comments',
        most_helpul: 'Most Helpful',
        newest_first: 'Most Recent',
        enter_new_comment: 'Write your new comment here',
        add_your_comment: 'Add Your Comment!',
        add_comment: 'Add Comment',
        write_your_comment: 'Write your new comment here',
        your_rating: 'Your rating:',
        be_the_first_to_comment: 'Be the first to comment',
        no_comments_yet: 'No comments yet',
        add_to_wishlist: 'Add to Wishlist',
        launch: 'Launch',
        course_details: 'Course Details',
        instructor: 'Instructor',
        what_is_inside: 'What is Inside',
        duration_hours: '{count} hr | {count} hrs',
        duration_mins: '{count} min | {count} mins'
      },
      cart: {
        cart: 'Cart',
        Total: 'Total:',
        checkout: 'CHECKOUT',
        shipping: 'Shipping',
        handling: 'Handling:',
        total_before_tax: 'Total Before Tax:',
        estimated_tax: 'Estimated Tax:',
        less_discounts: 'Less Discounts:',
        thanks_for_your_order: 'Thanks For Your Order',
        these_were_added_to: 'These were added to "What im Working on".',
        checkout_order_summary: 'Checkout - Order Summary',
        apply_discount_code: 'Apply discount code',
        note: 'Note:',
        finish_checkout_message: 'Finish checkout within 10 minutes of applying a code.',
        continue_shopping: 'Continue Shopping',
        next: 'NEXT',
        bill_me_later: 'Bill me later - email me an invoice',
        your_order_is_safe: 'Your order is Safe & Secure. Guaranteed.',
        items: 'items',
        Discount: 'Discount',
        invalid_coupon_code: 'Invalid coupon code',
        cart_empty: 'You dont have any items in your cart.',
        item_enrolled: 'Item is already added to my learning. Remove it from cart to continue.',
        item_deleted: 'Item is not purchasable any more. Remove it from cart to continue.',
        total_amount_less_than_one: 'Your total Cart amount, adjusted for discounts, can not be less than $ 0.99.Please adjust your Cart.',
        return_to_cart: 'return to cart',
        checkout_pay_with_credit_card: 'Checkout - Enter credit card Information'
      },
      share_popup: {
        copy: 'Copy',
        message_default: 'I thought you might be interested in this item.',
        link_share: 'Copy this URL to share.',
        embed_share: 'Copy this code and paste it in a web page.',
        embed_show_thumbnail: 'Show thumbnail image.',
        embed_show_add_learning: 'Show Add to Learning button.',
        social_share: 'Click the social network you want to share with.',
        can_edit_users: 'Users who can edit this Course:',
        send_invite: 'Your invite has been sent',
        share_edit: 'Successfully shared',
        embed_show_description: 'Show Description.',
        embed_show_title: 'Show Title.',
        embed_show_line_separator: 'Show Separator Line.'
      },
      share_popup_filter: {
        invite: 'Invite',
        link: 'Link',
        embed: 'Embed',
        social: 'Social',
        share_for_edit: 'Share for Edit'
      },
      new_comment: {
        post: 'Post',
        write_a_comment: 'Write your comment here.',
        enter_comment: 'Comment can\'t be blank',
        comment_added: 'Comment added succesfully'
      },
      enrolled_item_question_lightbox: {
        create_question: 'Create question',
        no_questions: 'No questions have been created yet. Be the first...',
        search_questions: 'Search Question',
        write_question: 'Write a question',
        about: 'About',
        participants: 'Participants',
        best_question: '0 Best Question | 1 Best Question | {count} Best Questions',
        open_question: '0 Open Question | 1 Open Question | {count} Open Questions',
        more: 'More',
        less: 'Less'
      },
      enrolled_item_new_answer: {
        enter_your_answer: 'enter your answer',
        add_answer: 'Add answer',
        add_your_answer: 'Add your answer'
      },
      enrolled_item_question_detail: {
        back_to_questions: '< Back to questions',
        answers: '0 Answers | 1 Answer | {count} Answers',
        asked_by: 'Asked By '
      },
      inperson_event_edit: {
        edit_inperson_event: 'Edit In-Person Event',
        schedule: 'Schedule',
        days: 'Days',
        time: 'Time',
        instructors: 'Instructors:',
        choose_instructor: 'Choose Instructor',
        categories: 'Categories:',
        choose: 'Choose',
        tags: 'Tags:',
        survey: 'Survey:',
        non_member_price: 'Non-Member Price:',
        member_price: 'Member Price:',
        max_participants: 'Max Participants:',
        free: 'Free',
        unlimited: 'Unlimited',
        who_can_see_in_catalog: 'Who can see this In-person event in Catalog:',
        every_one: 'Everyone',
        only_certain_groups: 'Only certain Groups',
        choose_groups: 'Choose Groups',
        auto_enroll: 'Auto Enroll',
        no_groups_created: 'you havent created any Groups',
        credits: 'Credits:',
        product_code: 'Product Code:',
        choose_a_group: 'Choose atleast one group',
        code_already_taken: 'code has already been taken',
        select_weekday: 'Weekday not selected',
        create_content: 'Create content'
      },
      display_in_catalog_date: {
        begin: 'Begin',
        end: 'End'
      },
      choose_item_popup: {
        selected_users: 'Selected users',
        create_new: '(create new)',
        selected_categories: 'Selected categories',
        selected_tags: 'Selected tags',
        selected_groups: 'Selected Groups',
        available_categories: 'Available categories',
        available_tags: 'Available tags',
        available_groups: 'Available Groups'
      },
      price_schedule_popup: {
        price_schedule: 'Price Schedule',
        create_price: '+ Create a Price'
      },
      price_schedule_list: {
        effective_now: 'Effective now',
        starting: 'Starting'  
      },
      choose_survey: {
        select_survey_content: 'Select or create surveys or questions to add to your In-Person Event',
        mine: 'Mine',
        shared: 'Shared',
        folders: 'Folders',
        types: 'Types',
        select_first: 'Please select content first'
      },
      instruction: {
        instructions: 'Instructions:', 
        question: '0 Question | 1 Question | {count} Questions',              
        start: 'Start'
      },
      create_course: {
        title: 'Learning Video Title',
        description: 'Learning Video Description',
        category: 'Category',
        price: 'Price',
        tags: 'Tags',
        prerequisites: 'Prerequisites',
        who_can_see_this_course: 'Who can see this Course in Catalog',
        catalog_start_date: 'Begin',
        catalog_end_date: 'End',
        access_expires: 'Access Expires',
        assign_course_to: 'Assign this course to',
        view_content: 'View Content',
        credits: 'Credits',
        product_code: 'Product Code',
        schedule: 'Schedule',
        scheduled_start_date: 'From',
        scheduled_end_date: 'To',
        heading: 'Create a Course',
        save: 'Save',
        cancel: 'Cancel',
        add_content: 'Add Content',
        add_content_desc: '(Pick Content to add to your learning video)',
        languages: ['English','French','German','Italian','Japanese','Korean','Portuguese','Russian','Simplified Chinese','Spanish']
      },
      cart_new: {
        cart_listing_heading: 'Items in Cart',
        course_title: 'Title',
        date: 'Date',
        price: 'Price',
        summary: 'Summary',
        payment_detail_heading: 'Payment Details',
        billing_detail_heading: 'Billing Details',
        first_name: 'FirstName',
        second_name: 'SecondName',
        address1: 'Address1',
        address2: 'Address2',
        city: 'City',
        country: 'Country',
        state: 'State / Province',
        zip_code: 'ZIP',
        phone: 'Phone',
        save_changes: 'Save',
        summary_item: 'Items',
        summary_sub_total: 'Sub Total',
        summary_discount: 'Discount',
        summary_tax_label: 'Calculate TAX',
        summary_agree: 'I Agree to the',
        summary_tc: 'Terms & Conditions',
        cart_summary_click_to_pay: 'Click to Pay',
        payment_inst_heading: 'Select a Payment Method from the following options',
        payment_inst_note: 'Note: Your billing information must match the billing address of the credit card entered below or we will be unable to process your payment',
        payment_card_number: 'Card Number',
        payment_expiry: 'Expiry',
        payment_cvv: 'CVV',
        empty_cart: "Cart is empty. Go to 'Catalog' to enroll for new learning objects.",
        cart_time: "Time Left To Complete Your Purchase",
        delete_confirm_message: 'Are you sure you want to delete this item?',
        order_ack_1: 'Thank you for your order.',
        order_ack_2: 'Here are some actions that you may want to perform next ',
        launch: 'Launch',
        view_detail_page: 'View Detail Page',
        what_am_doing: "Go to What I'm doing",
        view_invoice: "View Invoice",
        continue_shopping: "Continue Shopping"
      },
      account_setting: {
        heading: 'Account Settings',
        first_name: 'First Name',
        last_name: 'Last Name',
        email_id: 'Email ID',
        password: 'Password',
        sub_expr: 'Subscription Expiration',
        cancel: 'Cancel',
        save: 'Save',
        load_more: 'Load More',
        receipt: 'Receipt',
        analytics_from: 'From',
        analytics_to: 'To',
        apply: 'Apply',
        notification_heading: 'Get an email notification whenever the selected events take place...'
      },
      user_profile: {
        heading: 'Profile',
        recently_created: 'Recently Created',
        recently_shared: 'Recently Shared',
        recent_activity: 'Recent Activity',
        top_rated: 'Top Rated',
        following_users: 'Following Users',
        profile_summary: 'Profile Summary'
      },
      inbox: {
        new_message: 'New Message',
        all_messages: 'Messages',
        inbox_tab: 'Inbox',
        sent_tab: 'Sent Message',
        mark_unread: 'Mark as unread',
        mark_read: 'Mark as read',
        delete_selected: 'Delete Selected',
        load_more: 'Load More'
      },
      banner_uploads: {
        heading: 'Manage Banner',
        add_banner: 'ADD NEW BANNER',
        title: 'Title',
        title_bar_color: 'Title Bar Color',
        revert: 'REVERT',
        save: 'SAVE',
        time_period: 'Time Period',
        preview: 'Preview',
        remove: 'Remove',
        embed_url: 'Embed URL',
        embed_img_note: 'This can be a URL from Photo sites like Flickr (i.e https://live.staticflickr.com/5609/15556384292_7ea5846c29_h.jpg)',
        embed_video_note: 'This can be a URL from video sites like Youtube (i.e https://youtube.com/watch?v=IOF27o5CEsE)',
        upload_file: 'Upload a File',
        upload_img_note: 'Supported Formats: jpeg, jpg, gif, png, bmp. Image can be up to 560px wide x 315px tall',
        upload_video_note: 'Supported Formats: mp4',
        redirection_url: 'Banner Link URL',
        autoplay_on: 'Autoplay On',
        autoplay_off: 'Autoplay Off'
      },
      testimonial: {
        admin_heading: 'Testimonials',
        learner_heading: 'Write Testimonial',
        collect_feedback: 'Can you please provide your overall experience feedback about our store?',
        dont_ask_again: 'Dont ask again',
        maybe_later: 'Maybe later',
        provide_feedback: 'Provide feedback',
        what_they_are_saying: 'Success Stories',
        admin_review_heading: 'Review Testimonial',
        user_heading: 'My Testimonials'
      },
      footer: {
        copyright: "© Copyright {year}, ExpertusONE. All rights reserved.",
        version: "Version: v {version}"
      }
    },

    hi: {
      login: {
        username: 'उपयोगकर्ता नाम',
        or: 'या',
        email_address: 'ईमेल',
        password: 'कुंजिका',
        confirm_password: 'पुष्टि करना',
        keep_me_signed_in: 'मुझे याद रखें',
        forgot: 'भूल गया?',
        never_mind: 'कोई बात नहीं',
        first_name: 'पहला नाम',
        last_name: 'अंतिम नाम',
        by_clicking_signup: 'मैं सहमत हूं',
        terms_of_service: 'सेवा की शर्तें',
        privacy_policy: 'गोपनीयता नीति',
        and: 'तथा',
        reset_message: 'अपना पासवर्ड रीसेट करने की जानकारी आपके ईमेल पते पर भेज दिया गया है।',
        reset_password: 'पासवर्ड रीसेट',
        new_password: 'नया पासवर्ड',
        after_clicking_reset: 'आपका पासवर्ड रिसेट कर दिया गया है',
        signup_message: 'साइन अप करने के लिए धन्यवाद! आप शीघ्र ही एक पुष्टिकरण ईमेल प्राप्त करेंगे।',
        enter_valid_email_address: 'मान्य ईमेल पता दर्ज करें',
        enter_password: 'पासवर्ड ङालें',
        enter_first_name: "पहला नाम आवश्यक है",
        enter_last_name: "उपनाम आवश्यक है",
        enter_email: "ईमेल की जरूरत है",
        password_cant_blank: "पासवर्ड की आवश्यकता है",
        password_too_short: "पासवर्ड बहुत छोटा है (कम से कम 6 अक्षर है)",
        enter_password_confirmation: "पुष्टि की आवश्यकता है",
        password_confirmation_too_short: "पुष्टिकरण बहुत छोटा है (न्यूनतम 6 वर्ण है)"
      },
      common: {
        login: 'लॉग इन करें',
        sign_up: 'साइन अप करें',
        send: 'संदेश',
        cancel: 'रद्द करना',
        okay: 'ठीक है',
        reset: 'रीसेट',
        sign_out: 'प्रस्थान करें',
        save: 'सहेजें',
        delete_string: 'हटाएं',
        edit: 'संपादित करें',
        none: 'कोई नहीं',
        close: 'बंद करे',
        wat_im_working_on: "मैं पर क्या काम कर रहा हूँ",
        categories: 'श्रेणियाँ',
        available_items: 'उपलब्ध आइटम',
        no_product_found: "क्षमा करें, हम कोई मेल खाते पाठ्यक्रम नहीं पा सके।",
        try_search_again: 'कृपया अपनी तलाश में बदलाव लाओ और फिर से प्रयास करें।',
        search_results: 'खोज परिणाम',
        recommended_courses: 'अनुशंसित पाठ्यक्रम',
        done: 'किया हुआ',
        like: 'पसंद',
        unlike: 'भिन्न',
        reply: 'जवाब दे दो',
        characters_remaining: 'शेष वर्ण',
        apply: 'लागू',
        description: 'विवरण',
        from: 'से',
        to: 'सेवा',
        location: 'स्थान',
        available_users: 'उपलब्ध उपयोगकर्ता:',
        submit: 'प्रस्तुत',
        bookmarks: 'बुकमार्क'
      },
      profile: {
        change_name: 'नाम परिवर्तित करें',
        change_email: 'ई - मेल बदलें',
        change_password: 'पासवर्ड बदलें',
        current_password: 'वर्तमान पासवर्ड',
        new_password: 'नया पासवर्ड',
        confirm_password: 'पासवर्ड की पुष्टि कीजिये',
        after_changing_name: 'नाम सफलतापूर्वक अद्यतन किया जाता है।',
        after_changing_email: 'ईमेल सफलतापूर्वक अद्यतन किया जाता है।',
        after_changing_password: 'पासवर्ड सफलतापूर्वक अद्यतन किया जाता है।',
        password_confirmation: 'पासवर्ड पुष्टि',
        enter_current_password: "वर्तमान पासवर्ड खाली नहीं हो सकता"
      },
      message: {
        mail: 'मेल',
        to: 'सेवा',
        subject: 'विषय',
        inbox: 'इनबॉक्स',
        sent: 'भेज दिया',
        reply: 'जवाब दे दो',
        subject_error: "विषय खाली नहीं हो सकता",
        body_error: "शरीर खाली नहीं हो सकता",
        recipient_error: "प्राप्तकर्ता खाली नहीं हो सकता",
        message_content: 'संदेश',
        after_send_message: 'मैसेज बेजा गया!',
        send_message: 'आपका सन्देश भेज दिया गया है',
        on: 'पर',
        wrote: 'लिखा था:',
        invalid_username: 'अमान्य उपयोगकर्ता नाम'
      },
      header_menu: {
        home: 'घर',
        catalog: 'सूची',
        groups: 'समूह',
        profile: 'प्रोफ़ाइल',
        reports: 'रिपोर्ट',
        blog: 'ब्लॉग',
        manage_site: 'साइट प्रबंधित करें'
      },
      store_design: {
        store_design: 'स्टोर डिजाइन',
        layout: 'विन्यास',
        widgets: 'विजेट',
        manage_header: 'हैडर प्रबंधित करें',
        manage_footer: 'पाद लेख प्रबंधित करें'
      },
      quick_access: {
        quick_access: 'त्वरित ऐक्सेस',
        create_course: 'कोर्स बनाएं',
        create_banner: 'बैनर बनाएं',
        create_user: 'उपयोगकर्ता बनाइये',
        create_report: 'रिपोर्ट बनाएं',
        create_bundle: 'बंडल बनाएं',
        create_testimonial: 'गुणों का वर्ण-पत्र',
        others: 'अन्य',
        blog: 'ब्लॉग',
        filebox: 'फ़ाइल बॉक्स',
        groups: 'समूहों',
        playlist: 'प्लेलिस्ट',
        testimonials: 'प्रशंसापत्र'
      },
      events: {
        calender: 'Calender',
        name: 'Name',
        description: 'Description',
        start: 'Start:',
        end: 'End:',
        upcoming: 'Upcoming',
        past: 'Past',
        clone: 'Clone',
        delete_message: 'Are you sure you want to delete this item?',
        error: 'Error',
        enter_name: "Name can't be blank",
        enter_start_time: "Start time can't be blank",
        enter_end_time: "End time can't be blank",
        start_time: 'Start time',
        end_time: 'End time'
      },
      sort: {
        sort_by: 'इसके अनुसार क्रमबद्ध करें',
        title_caps: 'शीर्षक',
        title: 'शीर्षक',
        a_z: 'ए-जेड',
        z_a: 'जेड-ए',
        pricing_caps: 'मूल्य निर्धारण',
        pricing: 'मूल्य निर्धारण:',
        h_to_l: 'उच्च सेवा कम',
        l_to_h: 'कम सेवा उच्च',
        rating_caps: 'रेटिंग',
        rating: 'रेटिंग:',
        relevance: 'प्रासंगिकता',
        most_recent: 'सबसे हाल का',
        most_popular: 'सबसे लोकप्रिय'
      },
      search: {
        search_str: 'खोज',
        search_users: 'खोज उपयोगकर्ताओं'
      },
      filter: {
        text: 'फ़िल्टर',
        reset: 'रीसेट',
        price: 'कीमत',
        delivery_type: 'वितरण के प्रकार',
        quiz: 'प्रश्नोत्तरी',
        video: 'वीडियो',
        audio: 'ऑडियो',
        webbased: 'वेब आधारित',
        presentation: 'प्रस्तुतीकरण',
        document_text: 'दस्तावेज़',
        survey: 'सर्वेक्षण',
        scrom: 'स्कॉर्म',
        package_text: 'पैकेज',
        tincan: 'टिन का डब्बा',
        rating: 'रेटिंग',
        language: 'भाषा: हिन्दी',
        duration: 'अवधि'
      },
      announcement: {
        text: 'घोषणा',
        text_plural: 'घोषणाएँ',
        dismiss: 'खारिज',
        write_an_announcement: 'एक घोषणा लिखें:',
        characters_remaining: 'शेष वर्ण',
        display_announcement: 'प्रदर्शन घोषणा:',
        announcement_cant_blank: "घोषणा खाली नहीं हो सकता",
        start_date_error: "प्रारंभ तिथि खाली नहीं हो सकता",
        never_end: 'कभी नहीं समाप्त करने के लिए खाली छोड़ दें',
        priority: 'प्राथमिकता',
        normal: 'साधारण',
        urgent: 'अति आवश्यक',
        who_should_see_this_announcement: 'कौन सा प्रतिभागियों इस घोषणा देखना चाहिए?',
        search_courses: 'कोर्स खोजें',
        selected_items: 'चयनित उत्पाद:',
        select_none: 'किसी का चयन न करें',
        every_one: 'हर कोई',
        available_items: 'उपलब्ध आइटम',
        select_all: 'सभी का चयन करे',
        end_date: 'अंतिम तिथि',
        start_date: 'आरंभ करने की तिथि',
        no_announcements: 'कोई घोषणाएँ'
      },
      enrolled_item_filter: {
        in_progress: 'चालू',
        recently_completed: 'हाल ही में पूरा हुआ',
        all_completed: 'सभी पूरे किए',
        cancelled: 'रद्द',
        due_soon: 'जल्द ही देय'
      },
      enrolled_item_more_link: {
        progress: 'प्रगति:',
        questions: 'प्रशन',
        cancel_text: 'Are you sure? \n(All your progress will be lost. If this is a paid item then it will have to be purchased again.)'
      },
      available_item_filter: {
        all: 'सब',
        featured_items: 'विशेष वस्तुएं'
      },
      available_item_more_link: {
        preview: 'पूर्वावलोकन',
        comment: 'टिप्पणी',
        share: 'शेयर',
      },
      content_progress: {
        completed: 'पूरा कर लिया है'
      },
      add_to_cart: {
        full: 'पूर्ण',
        subscribers_only: 'केवल सदस्य'
      },
      item: {
        content: 'Content',
        about_this_course: 'About this Course',
        review_and_ratings: 'Review and Ratings',
        write_a_review: 'Write a review',
        rate_this_item: 'Rate this item',
        review: '{count} review | {count} reviews',
        member: '{count} Member | {count} Members',
        additional_information: 'Additional Information',
        type_your_review: 'Type your review',
        prerequisites: 'Prerequisites',
        see_more: 'और देखें',
        see_less: 'कम देखें',
        created_by: 'के द्वारा बनाई गई',
        last_updated: 'आखरी अपडेट',
        comments: '{count} टिप्पणी | {count} टिप्पणियाँ',
        hide_reply: '{count} उत्तर छुपाएं',
        most_helpul: 'सबसे सहायक',
        newest_first: 'सबसे हाल का',
        enter_new_comment: 'Write your new comment here',
        add_your_comment: 'Add Your Comment!',
        add_comment: 'Add Comment',
        write_your_comment: 'Write your new comment here',
        your_rating: 'Your rating:',
        be_the_first_to_comment: 'Be the first to comment',
        no_comments_yet: 'No comments yet',
        add_to_wishlist: 'इच्छा सूची में जोड़ें',
        launch: 'प्रक्षेपण',
        course_details: 'पाठ्यक्रम विवरण',
        instructor: 'प्रशिक्षक',
        what_is_inside: 'अंदर क्या है'
      },
      cart: {
        cart: 'गाड़ी',
        Total: 'संपूर्ण:',
        checkout: 'चेक आउट',
        shipping: 'शिपिंग',
        handling: 'हैंडलिंग:',
        total_before_tax: 'कर के पहले का कुल:',
        estimated_tax: 'अनुमानित कर:',
        less_discounts: 'कम छूट:',
        thanks_for_your_order: 'आपके आदेश के लिए धन्यवाद',
        these_were_added_to: 'इन करने के लिए जोड़ा गया था "मैं पर क्या काम कर रहा हूँ"।',
        checkout_order_summary: 'चेकआउट - आदेश सारांश',
        apply_discount_code: 'छूट कोड लागू करें',
        note: 'Note:',
        finish_checkout_message: 'Finish checkout within 10 minutes of applying a code.',
        continue_shopping: 'Continue Shopping',
        next: 'आगे',
        bill_me_later: 'Bill me later - email me an invoice',
        your_order_is_safe: 'Your order is Safe & Secure. Guaranteed.',
        items: 'items',
        Discount: 'Discount',
        invalid_coupon_code: 'Invalid coupon code',
        cart_empty: 'तुम न अपने गाड़ी में किसी भी आइटम नहीं हैं।',
        item_enrolled: 'Item is already added to my learning. Remove it from cart to continue.',
        item_deleted: 'Item is not purchasable any more. Remove it from cart to continue.',
        total_amount_less_than_one: 'Your total Cart amount, adjusted for discounts, can not be less than $ 0.99.Please adjust your Cart.',
        return_to_cart: 'return to cart',
        checkout_pay_with_credit_card: 'Checkout - Enter credit card Information'
      },
      share_popup: {
        copy: 'Copy',
        message_default: 'I thought you might be interested in this item.',
        link_share: 'Copy this URL to share.',
        embed_share: 'Copy this code and paste it in a web page.',
        embed_show_thumbnail: 'Show thumbnail image.',
        embed_show_add_learning: 'Show Add to Learning button.',
        social_share: 'Click the social network you want to share with.',
        can_edit_users: 'Users who can edit this Course:',
        send_invite: 'Your invite has been sent',
        share_edit: 'Successfully shared'
      },
      share_popup_filter: {
        invite: 'Invite',
        link: 'Link',
        embed: 'Embed',
        social: 'Social',
        share_for_edit: 'Share for Edit'
      },
      new_comment: {
        post: 'Post',
        write_a_comment: 'Write your comment here.',
        enter_comment: 'Comment can\'t be blank',
        comment_added: 'Comment added succesfully'
      },
      enrolled_item_question_lightbox: {
        create_question: 'Create question',
        no_questions: 'No questions have been created yet. Be the first...',
        search_questions: 'Search Questions',
        write_question: 'Write a question',
        about: 'के बारे में',
        participants: 'Participants',
        best_question: '0 Best Question | 1 Best Question | {count} Best Questions',
        open_question: '0 Open Question | 1 Open Question | {count} Open Questions',
        more: 'More',
        less: 'Less'
      },
      enrolled_item_new_answer: {
        enter_your_answer: 'enter your answer',
        add_answer: 'Add answer',
        add_your_answer: 'Add your answer'
      },
      enrolled_item_question_detail: {
        back_to_questions: '< Back to questions',
        answers: '0 Answers | 1 Answer | {count} Answers',
        asked_by: 'asked by'
      },
      inperson_event_edit: {
        edit_inperson_event: 'Edit In-Person Event',
        schedule: 'Schedule',
        days: 'Days',
        time: 'Time',
        instructors: 'Instructors:',
        choose_instructor: 'Choose Instructor',
        categories: 'Categories:',
        choose: 'Choose',
        tags: 'Tags:',
        survey: 'Survey:',
        non_member_price: 'Non-Member Price:',
        member_price: 'Member Price:',
        max_participants: 'Max Participants:',
        free: 'Free',
        unlimited: 'Unlimited',
        who_can_see_in_catalog: 'Who can see this In-person event in Catalog:',
        every_one: 'Everyone',
        only_certain_groups: 'Only certain Groups',
        choose_groups: 'Choose Groups',
        auto_enroll: 'Auto Enroll',
        no_groups_created: 'you havent created any Groups',
        credits: 'Credits:',
        product_code: 'Product Code:',
        choose_a_group: 'Choose atleast one group',
        code_already_taken: 'code has already been taken',
        select_weekday: 'Weekday not selected',
        create_content: 'Create content'
      },
      display_in_catalog_date: {
        begin: 'Begin',
        end: 'End'
      },
      choose_item_popup: {
        selected_users: 'Selected users',
        create_new: '(create new)',
        selected_categories: 'Selected categories',
        selected_tags: 'Selected tags',
        selected_groups: 'Selected Groups',
        available_categories: 'Available categories',
        available_tags: 'Available tags',
        available_groups: 'Available Groups'
      },
      price_schedule_popup: {
        price_schedule: 'Price Schedule',
        create_price: '+ Create a Price'
      },
      price_schedule_list: {
        effective_now: 'Effective now',
        starting: 'Starting'  
      },
      choose_survey: {
        select_survey_content: 'Select or create surveys or questions to add to your In-Person Event',
        mine: 'Mine',
        shared: 'Shared',
        folders: 'Folders',
        types: 'Types',
        select_first: 'Please select content first'
      },
      instruction: {
        instructions: 'Instructions:', 
        question: '0 Question | 1 Question | {count} Questions',              
        start: 'Start'
      },
      create_course: {
        title: 'वीडियो शीर्षक सीखना',
        description: 'वीडियो विवरण सीखना',
        category: 'श्रेणी',
        price: 'कीमत',
        tags: 'टैग',
        prerequisites: 'आवश्यक शर्तें',
        who_can_see_this_course: 'कैटलॉग में इस कोर्स को कौन देख सकता है',
        catalog_start_date: 'शुरू',
        catalog_end_date: 'समाप्त',
        access_expires: 'पहुँच समाप्त',
        assign_course_to: 'इस कोर्स को असाइन करें',
        view_content: 'सामग्री देखें',
        credits: 'क्रेडिट',
        product_code: 'उत्पाद कोड',
        schedule: 'अनुसूची',
        scheduled_start_date: 'से',
        scheduled_end_date: 'प्रति',
        heading: 'एक कोर्स बनाएं',
        save: 'सहेजें',
        cancel: 'रद्द करें',
        add_content: 'सामग्री जोड़ें',
        add_content_desc: '(अपने सीखने के वीडियो में जोड़ने के लिए सामग्री चुनें)',
        languages: ['अंग्रेजी', 'फ्रेंच', 'जर्मन', 'इतालवी', 'जापानी', 'कोरियाई', 'पुर्तगाली', 'रूसी', 'सरलीकृत चीनी', 'स्पैनिश']
      },
      cart_new: {
        cart_listing_heading: 'कार्ट में आइटम',
        course_title: 'पाठ्यक्रम का विषय',
        date: 'तारीख',
        price: 'कीमत',
        summary: 'Summary',
        payment_detail_heading: 'भुगतान विवरण',
        billing_detail_heading: 'बिलिंग विवरण',
        first_name: 'पहला नाम',
        second_name: 'दूसरा नाम',
        address1: 'पता 1',
        address2: 'पता द्वितीय',
        city: 'शहर',
        country: 'देश',
        state: 'राज्य / प्रांत',
        zip_code: 'ज़िप',
        phone: 'फ़ोन',
        save_changes: 'परिवर्तनों को सुरक्षित करें',
        summary_item: 'आइटम',
        summary_sub_total: 'उप योग',
        summary_discount: 'छूट',
        summary_tax_label: 'कर की गणना करें',
        summary_agree: 'मैं इसके लिए सहमत हूँ',
        summary_tc: 'नियम एवं शर्तें',
        cart_summary_click_to_pay: 'भुगतान करने के लिए क्लिक करें',
        payment_inst_heading: 'निम्नलिखित विकल्पों में से एक भुगतान विधि का चयन करें',
        payment_inst_note: 'नोट: आपकी बिलिंग जानकारी नीचे दर्ज किए गए क्रेडिट कार्ड के बिलिंग पते से मेल खानी चाहिए अन्यथा हम आपके भुगतान को संसाधित करने में असमर्थ होंगे',
        payment_card_number: 'कार्ड संख्या',
        payment_expiry: 'समाप्ति',
        payment_cvv: 'सीवीवी',
        empty_cart: "कार्ट खाली है। नई शिक्षण वस्तुओं के लिए नामांकन करने के लिए 'कैटलॉग' पर जाएं।",
        cart_time: "आपकी खरीदारी पूरी करने के लिए समय बचा है",
        delete_confirm_message: 'क्या आप सुनिश्चित रूप से इस आइटम को मिटाना चाहते हैं?',
        order_ack_1: 'आपके ऑर्डर के लिए धन्यवाद।',
        order_ack_2: 'यहां कुछ कार्रवाइयां दी गई हैं जिन्हें आप आगे करना चाहेंगे',
        launch: 'प्रक्षेपण',
        view_detail_page: 'विवरण पृष्ठ देखें',
        what_am_doing: "मैं जो कर रहा हूं उस पर जाएं",
        view_invoice: "चालान देखें",
        continue_shopping: "Continue Shopping"
      },
      account_setting: {
        heading: 'अकाउंट सेटिंग',
        first_name: 'पहला नाम',
        last_name: 'अंतिम नाम',
        email_id: 'ईमेल आईडी',
        password: 'कुंजिका',
        sub_expr: 'सदस्यता समाप्ति',
        cancel: 'रद्द करें',
        save: 'परिवर्तनों को सुरक्षित करें',
        load_more: 'और लोड करें',
        receipt: 'रसीद',
        analytics_from: 'से',
        analytics_to: 'प्रति',
        apply: 'लागू करना',
        notification_heading: 'चयनित ईवेंट होने पर एक ईमेल सूचना प्राप्त करें...'
      },
      user_profile: {
        heading: 'प्रोफ़ाइल',
        recently_created: 'हाल ही में बनाया गया',
        recently_shared: 'हाल ही में साझा किया गया',
        recent_activity: 'हाल की गतिविधि',
        top_rated: 'टॉप रेटेड',
        following_users: 'निम्नलिखित उपयोगकर्ता',
        profile_summary: 'प्रोफ़ाइल सारांश'
      },
      inbox: {
        new_message: 'नया संदेश',
        all_messages: 'संदेशों',
        inbox_tab: 'इनबॉक्स',
        sent_tab: 'भेजा गया संदेश',
        mark_unread: 'अपठित के रूप में चिह्नित करें',
        mark_read: 'पढ़े हुए का चिह्न',
        delete_selected: 'चयनित को हटाओ',
        load_more: 'और लोड करें'
      },
      banner_uploads: {
        heading: 'बैनर प्रबंधित करें',
        add_banner: 'नया बैनर जोड़ें',
        title: 'शीर्षक',
        title_bar_color: 'शीर्षक बार रंग',
        revert: 'फिर लौट आना',
        save: 'सहेजें',
        time_period: 'समय सीमा',
        preview: 'पूर्वावलोकन',
        remove: 'निकालना',
        embed_url: 'यूआरएल एम्बेड करें',
        embed_img_note: 'यह फ़्लिकर (जैसे https://live.staticflickr.com/5609/15556384292_7ea5846c29_h.jpg) जैसी फ़ोटो साइटों का URL हो सकता है।',
        embed_video_note: 'यह Youtube जैसी वीडियो साइटों का URL हो सकता है (अर्थात https://youtube.com/watch?v=IOF27o5CEsE)',
        upload_file: 'एक फ़ाइल अपलोड करें',
        upload_img_note: 'समर्थित प्रारूप: जेपीईजी, जेपीजी, जीआईएफ, पीएनजी, टिफ, बीएमपी। चित्र 560px चौड़ा x 315px लंबा हो सकता है',
        upload_video_note: 'समर्थित प्रारूप: mp4',
        redirection_url: 'पुनर्निर्देशन URL',
        autoplay_on: 'ऑटोप्ले चालू',
        autoplay_off: 'ऑटोप्ले बंद'
      },
      testimonial: {
        admin_heading: 'प्रशंसापत्र',
        learner_heading: 'प्रशंसापत्र लिखें',
        collect_feedback: 'क्या आप कृपया हमारे स्टोर के बारे में अपना समग्र अनुभव फ़ीडबैक प्रदान कर सकते हैं?',
        dont_ask_again: 'फिर मत पूछो',
        maybe_later: 'शायद बाद में',
        provide_feedback: 'राय देने',
        what_they_are_saying: 'वे सब क्या कह रहे हैं',
        admin_review_heading: 'समीक्षा प्रशंसापत्र'
      }  
    },

    fr: {
      login: {
        username: "Nom d'utilisateur",
        or: 'OU',
        email_address: 'Email',
        password: 'Mot de passe',
        confirm_password: 'confirmer',
        keep_me_signed_in: 'Me garder connecté',
        forgot: 'Oublié?',
        never_mind: 'Ça ne fait rien',
        first_name: 'Prénom',
        last_name: 'Nom de famille',
        by_clicking_signup: "je suis d'accord avec",
        terms_of_service: "Conditions d'utilisation",
        privacy_policy: 'Politique de confidentialité',
        and: 'et',
        reset_message: 'Informations pour réinitialiser votre mot de passe a été envoyé à votre adresse e-mail. ',
        reset_password: 'réinitialiser le mot de passe',
        new_password: 'nouveau mot de passe',
        after_clicking_reset: 'Votre mot de passe a été réinitialisé',
        signup_message: 'Merci pour votre inscription! Vous devriez recevoir une confirmation par e-mail sous peu.',
        enter_valid_email_address: 'Entrez une adresse e-mail valide',
        enter_password: 'Entrer un mot de passe',
        enter_first_name: "Le prénom est requis",
        enter_last_name: "Le nom de famille est requis",
        enter_email: "L'e-mail est requis",
        password_cant_blank: "Mot de passe requis",
        password_too_short: "Le mot de passe est trop court (6 caractères minimum)",
        enter_password_confirmation: "Confirmer est requis",
        password_confirmation_too_short: "La confirmation est trop courte (le minimum est de 6 caractères)"
      },
      common: {
        login: "S'identifier",
        sign_up: "S'inscrire",
        send: 'Envoyer',
        cancel: 'Annuler',
        okay: "d'accord",
        reset: 'Réinitialiser',
        sign_out: 'Déconnexion',
        save: 'sauvegarder',
        delete_string: 'Effacer',
        edit: 'modifier',
        none: 'Aucun',
        close: 'Fermer',
        wat_im_working_on: 'Ce sur quoi je travaille',
        categories: 'Catégories',
        available_items: 'Articles disponibles',
        no_product_found: "Désolé, nous n'avons trouvé aucune correspondance cours.",
        try_search_again: "Veuillez modifier votre recherche et réessayer.",
        search_results: 'RÉSULTATS DE LA RECHERCHE',
        recommended_courses: 'Cours recommandés',
        done:'Terminé',
        like: 'Comme',
        unlike: 'contrairement à',
        reply: 'Répondre',
        characters_remaining: 'caractères restants',
        apply: 'Appliquer',
        description: 'La description',
        from: 'de',
        to: 'à',
        location: 'Emplacement',
        available_users: 'Utilisateurs disponibles:',
        submit: 'Soumettre',
        bookmarks: 'Bookmarks'
      },
      profile: {
        change_name: 'Changer de nom',
        change_email: "Changer l'e-mail",
        change_password: 'Changer le mot de passe',
        current_password: 'Mot de passe actuel',
        new_password: 'Nouveau mot de passe',
        confirm_password: 'Confirmez le mot de passe',
        after_changing_name: 'Le nom est mis à jour avec succès.',
        after_changing_email: "L'email est mis à jour avec succès.",
        after_changing_password: 'Le mot de passe est mis à jour avec succès.',
        password_confirmation: 'Confirmation mot de passe',
        enter_current_password: "Le mot de passe actuel ne peut pas être vide",
      },
      message: {
        mail: 'Courrier',
        to: 'À',
        subject: 'Assujettir',
        inbox: 'Boîte de réception',
        sent: 'Envoyé',
        reply: 'Répondre',
        subject_error: "Le sujet ne peut pas être vide",
        body_error: "Le corps ne peut pas être vide",
        recipient_error: "Le destinataire ne peut pas être vide",
        message_content: 'Message',
        after_send_message: 'Message envoyé!',
        send_message: 'Votre message a été envoyé',
        on: 'sur',
        wrote: 'A écrit:',
        invalid_username: "Nom d'utilisateur invalide"
      },
      header_menu: {
        home: 'Accueil',
        catalog: 'Catalogue',
        groups: 'Groupes',
        profile: 'Profil',
        reports: 'Rapports',
        blog: 'Blog',
        manage_site: 'Gérer le site'
      },
      store_design: {
        store_design: 'Conception de magasin',
        layout: 'Disposition',
        widgets: 'Widget',
        manage_header: "Gérer l'en-tête",
        manage_footer: "Gérer le pied de page"
      },
      quick_access: {
        quick_access: 'Accès rapide',
        create_course: 'Créer un cours',
        create_banner: 'Créer une bannière',
        create_user: 'Créer un utilisateur',
        create_report: 'Creer un rapport',
        create_bundle: 'Créer un lot',
        create_testimonial: 'Témoignage',
        others: 'Les autres',
        blog: 'Blog',
        filebox: 'Boîte de fichier',
        groups: 'Groupes',
        playlist: 'Playlist',
        testimonials: 'Témoignages'
      },
      events: {
        calender: 'Calendrier',
        description: 'La description',
        name: 'prénom',
        start: 'Début:',
        end: 'Fin:',
        upcoming: 'Prochain',
        past: 'Passé',
        clone: 'Cloner',
        delete_message: 'Êtes-vous sûr de bien vouloir supprimer cet élément?',
        error: 'Erreur',
        enter_name: "Le nom ne peut pas être vide",
        enter_start_time: "L'heure de début ne peut pas être vide",
        enter_end_time: "L'heure de fin ne peut pas être vide",
        start_time: 'Heure de début',
        end_time: 'Heure de fin'
      },
      sort: {
        sort_by: 'TRIER PAR',
        title_caps: 'TITRE',
        title: 'Titre',
        a_z: 'A-Z',
        z_a: 'Z-A',
        pricing_caps: 'PRIX',
        pricing: 'Tarification:',
        h_to_l: 'Haut en bas',
        l_to_h: 'De bas en haut',
        rating_caps: 'ÉVALUATION',
        rating: 'Évaluation:',
        relevance: 'PERTINENCE',
        most_recent: 'Le plus récent',
        most_popular: 'Le plus populaire'
      },
      search: {
        search_str: 'Chercher',
        search_users: 'Rechercher des utilisateurs'
      },
      filter: {
        text: 'FILTRE',
        reset: 'Réinitialiser',
        price: 'PRIX',
        delivery_type: 'TYPE DE LIVRAISON',
        quiz: 'Quiz',
        video: 'Vidéo',
        audio: "l'audio",
        webbased: 'Basé sur le Web',
        presentation: 'Présentation',
        document_text: 'Document',
        survey: 'Enquête',
        scrom: 'Scorm',
        package_text: 'Paquet',
        tincan: 'Boîte de conserve',
        rating: 'ÉVALUATION',
        language: 'LANGUE',
        duration: 'DURÉE'
      },
      announcement: {
        text: 'Annonce',
        text_plural: 'Annonces',
        dismiss: 'Rejeter',
        write_an_announcement: 'Ecrire une annonce:',
        characters_remaining: 'caractères restants',
        display_announcement: "Annonce d'affichage:",
        announcement_cant_blank: "L'annonce ne peut pas être vide",
        start_date_error: "La date de début ne peut pas être vide",
        never_end: 'Laisser vide pour ne jamais finir',
        priority: 'priorité',
        normal: 'Normal',
        urgent: 'Urgent',
        who_should_see_this_announcement: 'Quels participants devraient voir cette annonce?',
        search_courses: 'Rechercher des cours',
        selected_items: 'Éléments sélectionnés:',
        select_none: 'Ne rien sélectionner',
        every_one: 'Toutes les personnes',
        available_items: 'Éléments disponibles:',
        select_all: 'Tout sélectionner',
        end_date: 'Date de fin',
        start_date: 'Date de début',
        no_announcements: "Il n'y a pas d'annonces"
      },
      enrolled_item_filter: {
        in_progress: 'En cours',
        recently_completed: 'Complété récemment',
        all_completed: 'Tous complétés',
        cancelled: 'Annulé',
        due_soon: 'Bientôt'
      },
      enrolled_item_more_link: {
        progress: 'Le progrès:',
        questions: 'Des questions',
        cancel_text: "Êtes-vous sûr? \n (Tous vos progrès seront perdus. S'il s'agit d'un article payé, il devra être acheté à nouveau.)"
      },
      available_item_filter: {
        all: 'Tout',
        featured_items: 'Articles en vedette'
      },
      available_item_more_link: {
        preview: 'Aperçu',
        comment: 'Commentaire',
        share: 'Partager',
      },
      content_progress: {
        completed: 'Terminé'
      },
      add_to_cart: {
        full: 'PLEIN',
        subscribers_only: 'abonnés seulement'
      },
      item: {
        content: 'Contenu',
        about_this_course: 'À propos de ce cours',
        review_and_ratings: 'Avis et évaluations',
        write_a_review: 'Écrire une critique',
        rate_this_item: 'Évaluez cet article',
        review: '{count} la revue | {count} avis',
        additional_information: 'Information additionnelle',
        type_your_review: 'Tapez votre avis',
        prerequisites: 'Conditions préalables',
        see_more: 'Voir plus',
        see_less: 'Voir moins',
        created_by: 'Créé par',
        last_updated: 'Dernière mise à jour',
        comments: '{count} Commentaire | {count} commentaires',
        hide_reply: 'cacher {count} réponse | cacher {count} réponses',
        most_helpul: 'Plus utile en',
        newest_first: "Le plus récent",
        enter_new_comment: 'Écrivez votre nouveau commentaire ici',
        add_your_comment: 'Ajoutez votre commentaire!',
        add_comment: 'Ajouter un commentaire',
        write_your_comment: 'Écrivez votre nouveau commentaire ici',
        your_rating: 'Votre note',
        be_the_first_to_comment: 'Soyez le premier à commenter',
        no_comments_yet: "aucun commentaire pour l'instant",
        add_to_wishlist: 'Ajouter à la liste de souhaits',
        launch: 'Lancer',
        course_details: 'Détails du cours',
        instructor: 'instructeur',
        what_is_inside: "Qu'y a-t-il à l'intérieur"
      },
      cart: {
        cart: 'Chariot',
        Total: 'Total:',
        checkout: 'la caisse',
        shipping: 'livraison',
        handling: 'Manipulation:',
        total_before_tax: 'Total hors taxe:',
        estimated_tax: 'Taxe estimée:',
        less_discounts: 'Moins de remises:',
        thanks_for_your_order: 'Merci pour votre commande',
        these_were_added_to: 'Ceux-ci ont été ajoutés à "ce que je travaille sur".',
        checkout_order_summary: 'Commander - Récapitulatif de la commande',
        apply_discount_code: 'Appliquer le code de réduction',
        note: 'Remarque:',
        finish_checkout_message: 'Terminer la caisse dans les 10 minutes suivant lapplication dun code.',
        continue_shopping: 'Continuer vos achats',
        next: 'PROCHAIN',
        bill_me_later: 'Facturez-moi plus tard-envoyez-moi une facture par courriel',
        your_order_is_safe: 'Votre commande est sûre et sécurisée. Garanti.',
        items: 'articles',
        Discount: 'Remise',
        invalid_coupon_code: 'Code de Coupon Invalide',
        cart_empty: 'Vous navez aucun article dans votre panier.',
        item_enrolled: 'Larticle est déjà ajouté à mon apprentissage. Retirez-le du panier pour continuer.',
        item_deleted: 'Larticle ne peut plus être acheté. Retirez-le du panier pour continuer.',
        total_amount_less_than_one: 'Le montant total de votre panier, ajusté pour les réductions, ne peut pas être inférieur à 0,99 $. Veuillez ajuster votre panier.',
        return_to_cart: 'retourner au panier',
        checkout_pay_with_credit_card: 'Caisse - Entrez les informations de carte de crédit'
      },
      share_popup: {
        copy: 'Copier',
        message_default: 'Je pensais que vous pourriez être intéressé par cet article.',
        link_share: 'Copiez cette URL pour partager.',
        embed_share: 'Copiez ce code et collez-le dans une page Web.',
        embed_show_thumbnail: 'Afficher la vignette.',
        embed_show_add_learning: "Afficher le bouton Ajouter à l'apprentissage",
        social_share: "Cliquez sur le réseau social avec lequel vous souhaitez partager.",
        can_edit_users: 'Utilisateurs pouvant modifier ce cours:',
        send_invite: 'Votre invitation a été envoyée',
        share_edit: 'partagé avec succès'
      },
      share_popup_filter: {
        invite: 'Inviter',
        link: 'Lien',
        embed: 'Embed',
        social: "Social",
        share_for_edit: "Partager pour modifier"
      },
      new_comment: {
        post: 'Poster',
        write_a_comment: 'Écrivez votre commentaire ici.',
        enter_comment: 'Le commentaire ne peut pas être vide',
        comment_added: 'Commend ajouté avec succès'
      },
      enrolled_item_question_lightbox: {
        create_question: 'Créer une question',
        no_questions: 'Aucune question na encore été créée. Soyez le premier...',
        search_questions: 'Rechercher des questions',
        write_question: 'Ecrire une question',
        about: 'Sur',
        participants: 'Participants',
        best_question: '0 Meilleure question | 1 meilleure question | {count} Meilleures questions',
        open_question: '0 Question Ouverte | 1 Question Ouverte | {count} Questions ouvertes',
        more: 'Plus',
        less: 'Moins'
      },
      enrolled_item_new_answer: {
        enter_your_answer: 'entrez votre réponse',
        add_answer: 'Ajouter une réponse',
        add_your_answer: 'Ajoutez votre réponse'
      },
      enrolled_item_question_detail: {
        back_to_questions: '< Retour aux questions',
        answers: '0 réponses | 1 réponse | {count} Réponses',
        asked_by: 'demandé par'
      },
      inperson_event_edit: {
        edit_inperson_event: 'Modifier lévénement en personne',
        schedule: 'Programme',
        days: 'Journées',
        time: 'Temps',
        instructors: 'Instructeurs:',
        choose_instructor: 'Choisissez un instructeur',
        categories: 'Catégories:',
        choose: 'Choisir',
        tags: 'Mots clés:',
        survey: 'Enquête:',
        non_member_price: 'Prix ​​non-membre:',
        member_price: 'Prix ​​du membre:',
        max_participants: 'Max Participants:',
        free: 'Gratuit',
        unlimited: 'Illimité',
        who_can_see_in_catalog: 'Qui peut voir cet événement en personne dans le catalogue:',
        every_one: 'Toutes les personnes',
        only_certain_groups: 'Seulement certains groupes',
        choose_groups: 'Choisissez des groupes',
        auto_enroll: 'Inscription automatique',
        no_groups_created: 'vous navez créé aucun groupe',
        credits: 'Crédits:',
        product_code: 'Code produit:',
        choose_a_group: 'Choisissez au moins un groupe',
        code_already_taken: 'le code a déjà été pris',
        select_weekday: 'Jour de la semaine non sélectionné',
        create_content: 'créer du contenu'
      },
      display_in_catalog_date: {
        begin: 'Commencer',
        end: 'Fin'
      },
      choose_item_popup: {
        selected_users: 'Utilisateurs sélectionnés',
        create_new: '(créer un nouveau)',
        selected_categories: 'Catégories sélectionnées',
        selected_tags: 'Tags sélectionnés',
        selected_groups: 'Groupes sélectionnés',
        available_categories: 'Catégories disponibles',
        available_tags: 'Tags disponibles',
        available_groups: 'Groupes disponibles'
      },
      price_schedule_popup: {
        price_schedule: 'Bordereau de prix',
        create_price: 'créer un prix'
      },
      price_schedule_list: {
        effective_now: 'En vigueur maintenant',
        starting: 'Départ'  
      },
      choose_survey: {
        select_survey_content: 'Sélectionnez ou créez des sondages ou des questions à ajouter à votre événement en personne',
        mine: 'Mien',
        shared: 'partagé',
        folders: 'Dossiers',
        types: 'Les types',
        select_first: 'Veuillez dabord sélectionner le contenu'
      },
      instruction: {
        instructions: 'Instructions:',
        question: '0 Question | 1 Question | {count} Questions',
        start: 'Début'
      },
      create_course: {
        title: 'Titre de la vidéo dapprentissage',
        description: 'Description de la vidéo dapprentissage',
        category: 'Catégorie',
        price: 'Prix',
        tags: 'Mots clés',
        prerequisites: 'Conditions préalables',
        who_can_see_this_course: 'Qui peut voir ce cours dans le catalogue',
        catalog_start_date: 'Commencer',
        catalog_end_date: 'Finir',
        access_expires: 'Laccès expire',
        assign_course_to: 'Attribuez ce cours à',
        view_content: 'Afficher le contenu',
        credits: 'Crédits',
        product_code: 'Code produit',
        schedule: 'Calendrier',
        scheduled_start_date: 'De',
        scheduled_end_date: 'À',
        heading: 'Créer un cours',
        save: 'sauvegarder',
        cancel: 'Annuler',
        add_content: 'Ajouter du contenu',
        add_content_desc: '(Choisissez du contenu à ajouter à votre vidéo dapprentissage)',
        languages: ['Anglais','Français','Allemand','Italien','Japonais','Coréen','Portugais','Russe','Chinois simplifié','Espagnol']
      }      
    },

    ru: {
      login: {
        username: 'имя пользователя',
        or: 'ИЛИ ЖЕ',
        email_address: 'Эл. адрес',
        password: 'пароль',
        confirm_password: 'подтверждать',
        keep_me_signed_in: 'Запомнить меня',
        forgot: 'Забыл?',
        never_mind: 'Неважно',
        first_name: 'Имя',
        last_name: 'Фамилия',
        by_clicking_signup: 'я согласен с ',
        terms_of_service: 'Условия использования',
        privacy_policy: 'политика конфиденциальности',
        and: 'а также',
        reset_message: 'Информация для сброса пароля была отправлена ​​на ваш адрес электронной почты. ',
        reset_password: 'Сброс пароля',
        new_password: 'новый пароль',
        after_clicking_reset: 'Ваш пароль был сброшен',
        signup_message: 'Спасибо за регистрацию! Вскоре вы получите подтверждение по электронной почте.',
        enter_valid_email_address: 'Введите действительный адрес электронной почты',
        enter_password: 'введите пароль',
        enter_first_name: "Требуется имя",
        enter_last_name: "Требуется фамилия",
        enter_email: "Электронная почта обязательна",
        password_cant_blank: "необходим пароль",
        password_too_short: "Пароль слишком короткий (минимум 6 символов)",
        enter_password_confirmation: "Требуется подтверждение",
        password_confirmation_too_short: "Подтверждение слишком короткое (минимум 6 символов)"
      },
      common: {
        login: 'Авторизоваться',
        sign_up: 'зарегистрироваться',
        send: 'послать',
        cancel: 'Отмена',
        okay: 'Хорошо',
        reset: 'Сброс',
        sign_out: 'Выход',
        save: 'Сохранить',
        delete_string: 'Удалить',
        edit: 'редактировать',
        none: 'Никто',
        close: 'Закрыть',
        wat_im_working_on: 'Что я работаю',
        categories: 'Категории',
        available_items: 'Доступные предметы',
        no_product_found: "Извините мы не нашли подходящего курсы.",
        try_search_again: "Пожалуйста измените свой поиск и повторите попытку.",
        search_results: 'РЕЗУЛЬТАТЫ ПОИСКА',
        recommended_courses: 'Рекомендуемые курсы',
        done: 'сделано',
        like: 'подобно',
        unlike: 'В отличие от',
        reply: 'Ответить',
        characters_remaining: 'оставшиеся символы',
        apply: 'налечь',
        description: 'Описание',
        from: 'из',
        to: 'в',
        location: 'Место нахождения',
        available_users: 'Доступные пользователи:',
        submit: 'Отправить',
        bookmarks: 'Bookmarks'
      },
      profile: {
        change_name: 'Сменить имя',
        change_email: 'Сменить имейл',
        change_password: 'Изменить пароль',
        current_password: 'Текущий пароль',
        new_password: 'Новый пароль',
        confirm_password: 'Подтвердите Пароль',
        after_changing_name: 'Имя обновляется успешно.',
        after_changing_email: 'Электронная почта обновляется успешно.',
        after_changing_password: 'Пароль обновляется успешно.',
        password_confirmation: 'Подтверждение пароля',
        enter_current_password: "Текущий пароль не может быть пустым"
      },
      message: {
        mail: 'почта',
        to: 'к',
        subject: 'Предмет',
        inbox: 'входящие',
        sent: 'Отправлено',
        reply: 'Ответить',
        subject_error: "Объект не может быть пустым",
        body_error: "Тело не может быть пустым",
        recipient_error: "Получатель не может быть пустым",
        message_content: 'Сообщение',
        after_send_message: 'Сообщение отправлено!',
        send_message: 'Ваше сообщение было отправлено',
        on: 'на',
        wrote: 'Написала:',
        invalid_username: 'Неверное имя пользователя'
      },
      header_menu: {
        home: 'Главная',
        catalog: 'Каталог',
        groups: 'группы',
        profile: 'Профиль',
        reports: 'Отчеты',
        blog: 'Блог',
        manage_site: 'Управление сайтом'
      },
      store_design: {
        store_design: 'Дизайн магазина',
        layout: 'Макет',
        widgets: 'Виджеты',
        manage_header: 'Управление заголовком',
        manage_footer: 'Управление нижним колонтитулом'
      },
      quick_access: {
        quick_access: 'Быстрый доступ',
        create_course: 'Создать курс',
        create_banner: 'Создать баннер',
        create_user: 'Создать пользователя',
        create_report: 'Создать отчет',
        create_bundle: 'Создать пакет',
        create_testimonial: 'Отзыв',
        others: 'Другие',
        blog: 'Блог',
        filebox: 'Файловый ящик',
        groups: 'Группы',
        playlist: 'Плейлист',
        testimonials: 'Отзывы'
      },
      events: {
        calender: 'Календарь',
        name: 'имя',
        description: 'Описание',
        start: 'Начало:',
        end: 'Конец:',
        upcoming: 'предстоящий',
        past: 'прошлое',
        clone: 'клон',
        delete_message: 'Вы уверены, что хотите удалить этот элемент?',
        error: 'ошибка',
        enter_name: "Имя не может быть пустым",
        enter_start_time: "Время начала не может быть пустым",
        enter_end_time: "Время окончания не может быть пустым",
        start_time: 'время начала',
        end_time: 'Время окончания'
      },
      sort: {
        sort_by: 'СОРТИРОВАТЬ ПО',
        title_caps: 'ЗАГЛАВИЕ',
        title: 'заглавие',
        a_z: 'A-Z',
        z_a: 'Z-A',
        pricing_caps: 'PRICING',
        pricing: 'Ценообразование:',
        h_to_l: 'От низкого до низкого',
        l_to_h: 'Снизу вверх',
        rating_caps: 'РЕЙТИНГ',
        rating: 'Рейтинг:',
        relevance: 'АКТУАЛЬНОСТЬ',
        most_recent: 'Самые последние',
        most_popular: 'Самый популярный'
      },
      search: {
        search_str: 'Поиск',
        search_users: 'Поиск пользователей'
      },
      filter: {
        text: 'ФИЛЬТР',
        reset: 'Сброс',
        price: 'ЦЕНА',
        delivery_type: 'ТИП ПОСТАВКИ',
        quiz: 'викторина',
        video: 'видео',
        audio: 'аудио',
        webbased: 'веб-исходя из',
        presentation: 'презентация',
        document_text: 'Документ',
        survey: 'Опрос',
        scrom: 'Scorm',
        package_text: 'пакет',
        tincan: 'Консервная банка',
        rating: 'РЕЙТИНГ',
        language: 'ЯЗЫК',
        duration: 'ПРОДОЛЖИТЕЛЬНОСТЬ'
      },
      announcement: {
        text: 'Объявление',
        text_plural: 'Объявления',
        dismiss: 'отклонять',
        write_an_announcement: 'Написать объявление:',
        characters_remaining: 'оставшиеся символы',
        display_announcement: 'Показать объявление:',
        announcement_cant_blank: "Объявление не может быть пустым",
        start_date_error: "Дата начала не может быть пуста",
        never_end: 'Оставьте пустым, чтобы никогда не заканчиваться',
        priority: 'приоритет',
        normal: 'Нормальный',
        urgent: 'неотложный',
        who_should_see_this_announcement: 'Какие участники должны увидеть это объявление?',
        search_courses: 'Поиск курсов',
        selected_items: 'Выбранные элементы:',
        select_none: 'Выберите Нет',
        every_one: 'Все',
        available_items: 'Доступные предметы:',
        select_all: 'Выбрать все',
        end_date: 'Дата окончания',
        start_date: 'Дата начала',
        no_announcements: 'Нет объявлений'
      },
      enrolled_item_filter: {
        in_progress: 'В ходе выполнения',
        recently_completed: 'Недавно завершенный',
        all_completed: 'Все завершено',
        cancelled: 'отменен',
        due_soon: 'Скоро'
      },
      enrolled_item_more_link: {
        progress: 'Прогресс:',
        questions: 'Вопросов',
        cancel_text: 'Ты уверен? \n(Все ваши успехи будут утеряны. Если это платный товар, тогда его нужно будет снова купить.)'
      },
      available_item_filter: {
        all: 'Все',
        featured_items: 'Популярные товары'
      },
      available_item_more_link: {
        preview: 'предварительный просмотр',
        comment: 'Комментарий',
        share: 'Поделиться',
      },
      content_progress: {
        completed: 'Завершенный'
      },
      add_to_cart: {
        full: 'ПОЛНЫЙ',
        subscribers_only: 'только подписчики'
      },
      item: {
        content: 'содержание',
        about_this_course: 'Об этом курсе',
        review_and_ratings: 'Обзор и рейтинги',
        write_a_review: 'Написать обзор',
        rate_this_item: 'Оценить этот товар',
        review: '{count} обзор | {count} отзывы',
        additional_information: 'Дополнительная информация',
        type_your_review: 'Введите ваш отзыв',
        prerequisites: 'Предпосылки',
        see_more: 'Узнать больше',
        see_less: 'Показать меньше',
        created_by: 'Создано',
        last_updated: 'Последнее обновление',
        comments: '{count} Комментарий | {count} Комментарии',
        hide_reply: 'скрыть {count} ответ | скрыть {count} ответа',
        most_helpul: 'Сначала самое',
        newest_first: 'Самые последние',
        enter_new_comment: 'Напишите ваш новый комментарий здесь',
        add_your_comment: 'Добавьте свой комментарий!',
        add_comment: 'Добавить комментарий',
        write_your_comment: 'Напишите ваш новый комментарий здесь',
        your_rating: 'Ваш рейтинг',
        be_the_first_to_comment: 'Оставьте первый комментарий',
        no_comments_yet: 'Пока без коментариев',
        add_to_wishlist: 'Добавить в список желаний',
        launch: 'Запуск',
        course_details: 'детали курса',
        instructor: 'инструктор',
        what_is_inside: 'То, что находится внутри'
      },
      cart: {
        cart: 'телега',
        Total: 'Всего:',
        checkout: 'контроль',
        shipping: 'Перевозка',
        handling: 'Погрузочно-разгрузочные работы:',
        total_before_tax: 'Итого до налогообложения:',
        estimated_tax: 'Приблизительный налог:',
        less_discounts: 'Меньше Скидки:',
        thanks_for_your_order: 'Спасибо за ваш заказ',
        these_were_added_to: 'Они были добавлены в «Что я работаю».',
        checkout_order_summary: 'Оформить заказ',
        apply_discount_code: 'Применить код скидки',
        note: 'Заметка:',
        finish_checkout_message: 'Завершите проверку в течение 10 минут после применения кода.',
        continue_shopping: 'Продолжить покупки',
        next: 'СЛЕДУЮЩИЙ',
        bill_me_later: 'Заплатите мне позже - напишите мне счет-фактуру',
        your_order_is_safe: 'Ваш заказ безопасен и безопасен. Гарантированный.',
        items: 'Предметы',
        Discount: 'скидка',
        invalid_coupon_code: 'Неверный код купона',
        cart_empty: 'У вас нет предметов в корзине.',
        item_enrolled: 'Элемент уже добавлен к моему обучению. Удалите его из корзины, чтобы продолжить.',
        item_deleted: 'Товар больше не приобретается. Удалите его из корзины, чтобы продолжить.',
        total_amount_less_than_one: 'Ваша общая сумма Корзина, скорректированная на скидки, не может быть меньше $ 0,99. Пожалуйста, настройте свою корзину.',
        return_to_cart: 'вернуться в корзину',
        checkout_pay_with_credit_card: 'Оформить заказ - введите данные кредитной карты'
      },
      share_popup: {
        copy: 'Копировать',
        message_default: 'Я думал, вас может заинтересовать этот элемент',
        link_share: 'Скопируйте этот URL для совместного использования.',
        embed_share: 'Скопируйте этот код и вставьте его на веб-страницу',
        embed_show_thumbnail: 'Показывать миниатюру',
        embed_show_add_learning: 'Показать Добавить в обучающую кнопку.',
        social_share: 'Нажмите на социальную сеть, с которой вы хотите поделиться.',
        can_edit_users: 'Пользователи, которые могут редактировать этот курс:',
        send_invite: 'Ваше приглашение отправлено',
        share_edit: 'успешно совместно'
      },
      share_popup_filter: {
        invite: 'Пригласить',
        link: 'Ссылка',
        embed: 'Вставить',
        social: 'Социальный',
        share_for_edit: 'Поделиться для редактирования'
      },
      new_comment: {
        post: 'после',
        write_a_comment: 'Напишите свой комментарий здесь',
        enter_comment: 'Комментарий не может быть пустым',
        comment_added: 'Успешно добавлено'
      },
      enrolled_item_question_lightbox: {
        create_question: 'Создать вопрос',
        no_questions: 'Вопросов пока не создано. Будь первым...',
        search_questions: 'Вопросы по поиску',
        write_question: 'Написать вопрос',
        about: 'Около',
        participants: 'участники',
        best_question: '0 Лучший вопрос | 1 Лучший вопрос | {count} Лучшие вопросы',
        open_question: '0 Открытый вопрос | 1 Открытый вопрос | {count} Открытые вопросы',
        more: 'Больше',
        less: 'Меньше'
      },
      enrolled_item_new_answer: {
        enter_your_answer: 'введите свой ответ',
        add_answer: 'Добавить ответ',
        add_your_answer: 'Добавьте свой ответ'
      },
      enrolled_item_question_detail: {
        back_to_questions: '< Назад к вопросам',
        answers: '0 Ответы | 1 Ответ | {count} Ответы',
        asked_by: 'попросил'
      },
      inperson_event_edit: {
        edit_inperson_event: 'Редактировать In-Person Event',
        schedule: 'График',
        days: 'дней',
        time: 'Время',
        instructors: 'Инструкторы:',
        choose_instructor: 'Выберите Инструктор',
        categories: 'Категории:',
        choose: 'выберите',
        tags: 'Метки:',
        survey: 'Опрос:',
        non_member_price: 'Non-Member Цена:',
        member_price: 'Цена участника:',
        max_participants: 'Макс. Участники:',
        free: 'Свободно',
        unlimited: 'неограниченный',
        who_can_see_in_catalog: 'Кто может увидеть это личное событие в Каталоге:',
        every_one: 'Все',
        only_certain_groups: 'Только определенные группы',
        choose_groups: 'Выберите группы',
        auto_enroll: 'Автозапись',
        no_groups_created: 'вы не создали никаких групп',
        credits: 'Кредиты:',
        product_code: 'Код продукта:',
        choose_a_group: 'Выберите по крайней мере одну группу',
        code_already_taken: 'код уже принят',
        select_weekday: 'Не выбран будний день',
        create_content: 'создать контент'
      },
      display_in_catalog_date: {
        begin: 'Начать',
        end: 'Конец'
      },
      choose_item_popup: {
        selected_users: 'Выбранные пользователи',
        create_new: '(создавать новое)',
        selected_categories: 'Избранные категории',
        selected_tags: 'Избранные теги',
        selected_groups: 'Избранные группы',
        available_categories: 'Доступные категории',
        available_tags: 'Доступные теги',
        available_groups: 'Доступные группы'
      },
      price_schedule_popup: {
        price_schedule: 'Расценки',
        create_price: 'создать цену'
      },
      price_schedule_list: {
        effective_now: 'В настоящее время',
        starting: 'начало'  
      },
      choose_survey: {
        select_survey_content: 'Выбирайте или создавайте опросы или вопросы, чтобы добавить свое личное событие',
        mine: 'Мой',
        shared: 'Общий',
        folders: 'Папки',
        types: 'Типы',
        select_first: 'Сначала выберите контент'
      },
      instruction: {
        instructions: 'инструкции:',
        question: '0 Вопрос | 1 Вопрос | {count} Вопросов',
        start: 'Начало'
      },
      create_course: {
        title: 'Название обучающего видео',
        description: 'Описание обучающего видео',
        category: 'Категория',
        price: 'Цена',
        tags: 'Теги',
        prerequisites: 'Предпосылки',
        who_can_see_this_course: 'Кто может видеть этот курс в каталоге',
        catalog_start_date: 'Начинать',
        catalog_end_date: 'Конец',
        access_expires: 'Доступ истекает',
        assign_course_to: 'Назначьте этот курс',
        view_content: 'Просмотр содержания',
        credits: 'Кредиты',
        product_code: 'Код продукта',
        schedule: 'Расписание',
        scheduled_start_date: 'Из',
        scheduled_end_date: 'К',
        heading: 'Создать курс',
        save: 'Сохранить',
        cancel: 'Отмена',
        add_content: 'Добавить содержимое',
        add_content_desc: '(Выберите Содержание, чтобы добавить в обучающее видео)',
        languages: ['английский','французкий язык','Немецкий','Итальянский','Японский','корейский язык','португальский','русский','Упрощенный китайский','испанский']
      }      
    },

    ch: {
      login: {
        username: '用户名',
        or: '要么',
        email_address: '电子邮件',
        password: '密码',
        confirm_password: '确认',
        keep_me_signed_in: '保持登录状态',
        forgot: '忘记？',
        never_mind: '没关系',
        first_name: '名字',
        last_name: '姓',
        by_clicking_signup: '我同意',
        terms_of_service: '服务条款',
        privacy_policy: '隐私政策',
        and: '和',
        reset_message: '重置密码的信息已发送到您的电子邮件地址. ',
        reset_password: '重设密码',
        new_password: '新密码',
        after_clicking_reset: '您的密码已重置',
        signup_message: '感谢您注册！您应该很快收到一封电子邮件确认.',
        enter_valid_email_address: '输入一个有效的电子邮件地址',
        enter_password: '输入密码',
        enter_first_name: "名字是必需的",
        enter_last_name: "姓氏是必填项",
        enter_email: "电子邮件是必需的",
        password_cant_blank: "密码是必需的",
        password_too_short: "密码太短（最少6个字符）",
        enter_password_confirmation: "需要确认",
        password_confirmation_too_short: "确认太短（最少 6 个字符）"
      },
      common: {
        login: '登录',
        sign_up: '注册',
        send: '发送',
        cancel: '取消',
        okay: '好的',
        reset: '重启',
        sign_out: '登出',
        save: '保存',
        delete_string: '删除',
        edit: '编辑',
        none: '没有',
        close: '关',
        wat_im_working_on: '我在做什么',
        categories: '类别',
        available_items: '可用项目',
        no_product_found: '对不起，我们找不到任何匹配 培训班.',
        try_search_again: '请修改您的搜索，然后再试一次.',
        search_results: '搜索结果',
        recommended_courses: '推荐课程',
        done: '搞定',
        like: '喜欢',
        unlike: '不像',
        reply: '回复',
        characters_remaining: '剩余字符',
        apply: '应用',
        description: '描述',
        from: '从',
        to: '至',
        location: '位置',
        available_users: '可用用户：',
        submit: '提交',
        bookmarks: 'Bookmarks'
      },
      profile: {
        change_name: '更换名字',
        change_email: '更改电子邮件',
        change_password: '更改密码',
        current_password: '当前密码',
        new_password: '新密码',
        confirm_password: '确认密码',
        after_changing_name: '名称已成功更新.',
        after_changing_email: '电子邮件成功更新.',
        after_changing_password: '密码成功更新.',
        password_confirmation: '确认密码',
        enter_current_password: "当前密码不能为空"
      },
      message: {
        mail: '邮件',
        to: '至',
        subject: '学科',
        inbox: '收件箱',
        sent: '发送',
        reply: '回复',
        subject_error: "主题不能为空",
        body_error: "身体不能为空",
        recipient_error: "收件人不能为空",
        message_content: '信息',
        after_send_message: '留言已发送 !',
        send_message: '您的留言已发送',
        on: '上',
        wrote: '写:',
        invalid_username: '无效的用户名'
      },
      header_menu: {
        home: '家',
        catalog: '目录',
        groups: '组',
        profile: '轮廓',
        reports: '报告',
        blog: '博客',
        manage_site: '管理网站'
      },
      store_design: {
        store_design: '店铺设计',
        layout: '布局',
        widgets: '小部件',
        manage_header: '管理标题',
        manage_footer: '管理页脚'
      },
      quick_access: {
        quick_access: '快速访问',
        create_course: '创建课程',
        create_banner: '创建横幅',
        create_user: '创建用户',
        create_report: '创建报告',
        create_bundle: '创建捆绑包',
        create_testimonial: '感言',
        others: '其他',
        blog: '博客',
        filebox: '文件箱',
        groups: '团体',
        playlist: '播放列表',
        testimonials: '感言'
      },
      events: {
        calender: '日历',
        name: '名称',
        description: '描述',
        start: '开始:',
        end: '结束:',
        upcoming: '即将到来',
        past: '过去',
        clone: '克隆',
        delete_message: '你确定要删除这个项目吗？',
        error: '错误',
        enter_name: "名称不能为空",
        enter_start_time: "开始时间不能为空",
        enter_end_time: "结束时间不能为空",
        start_time: '开始时间',
        end_time: '时间结束'
      },
      sort: {
        sort_by: '排序方式',
        title_caps: '标题',
        title: '标题',
        a_z: 'A-Z',
        z_a: 'Z-A',
        pricing_caps: '价钱',
        pricing: '价钱:',
        h_to_l: '从高到低',
        l_to_h: '从低到高',
        rating_caps: '评分',
        rating: '评分:',
        relevance: '关联',
        most_recent: '最近',
        most_popular: '最受欢迎'
      },
      search: {
        search_str: '搜索',
        search_users: '搜索用户'
      },
      filter: {
        text: '过滤',
        reset: '重启',
        price: '价钱',
        delivery_type: '交货类型',
        quiz: '测验',
        video: '视频',
        audio: '音频',
        webbased: '基于Web的',
        presentation: '介绍',
        document_text: '文件',
        survey: '调查',
        scrom: 'Scorm',
        package_text: '包',
        tincan: '锡罐',
        rating: '评分',
        language: '语',
        duration: '期间'
      },
      announcement: {
        text: '公告',
        text_plural: '通告',
        dismiss: '解雇',
        write_an_announcement: '写一个公告:',
        characters_remaining: '剩余字符',
        display_announcement: '显示公告：',
        announcement_cant_blank: "公告不能为空",
        start_date_error: "开始日期不能为空",
        never_end: '留空以永远不会结束',
        priority: '优先',
        normal: '正常',
        urgent: '紧急',
        who_should_see_this_announcement: '哪些参与者应该看到这个公告？',
        search_courses: '搜索课程',
        selected_items: '所选项目:',
        select_none: '选择无',
        every_one: '大家',
        available_items: '可用项目:',
        select_all: '全选',
        end_date: '结束日期',
        start_date: '开始日期',
        no_announcements: '没有公告'
      },
      enrolled_item_filter: {
        in_progress: '进行中',
        recently_completed: '最近完成',
        all_completed: '全部完成',
        cancelled: '取消',
        due_soon: '即将到期'
      },
      enrolled_item_more_link: {
        progress: '进展:',
        questions: '问题',
        cancel_text: '你确定？ \n（所有的进度都会丢失，如果这是一个付费项目，则必须再次购买。）'
      },
      available_item_filter: {
        all: '所有',
        featured_items: '精选项目'
      },
      available_item_more_link: {
        preview: '预习',
        comment: '评论',
        share: '分享',
      },
      content_progress: {
        completed: '已完成'
      },
      add_to_cart: {
        full: '充分',
        subscribers_only: '只有订户'
      },
      item: {
        content: '内容',
        about_this_course: '关于本课程',
        review_and_ratings: '评论和评分',
        write_a_review: '写评论',
        rate_this_item: '评价这个项目',
        review: '{count} 评论 | {count} 评论',
        additional_information: '附加信息',
        type_your_review: '输入您的评论',
        prerequisites: '先决条件',
        see_more: '查看更多',
        see_less: '看少了',
        created_by: '由...制作',
        last_updated: '最近更新时间',
        comments: '{count} 评论 | {count} 评论',
        hide_reply: '隱藏 {count} 條回复 | 隱藏 {count} 個回复',
        most_helpul: '最有帮助的',
        newest_first: '最近的',
        enter_new_comment: '在这里写下你的新评论',
        add_your_comment: '添加你的评论！',
        add_comment: '添加评论',
        write_your_comment: '在这里写下你的新评论',
        your_rating: '你的评分',
        be_the_first_to_comment: '成为第一个发表评论的人',
        no_comments_yet: '暂时没有评论',
        add_to_wishlist: '添加到愿望清单',
        launch: '發射',
        course_details: '课程详情',
        instructor: '讲师',
        what_is_inside: '里面是什么'
      },
      cart: {
        cart: '大车',
        Total: '总：',
        checkout: '查看',
        shipping: '运输',
        handling: '处理：',
        total_before_tax: '税前总额：',
        estimated_tax: '预估税：',
        less_discounts: '减少折扣：',
        thanks_for_your_order: '感谢您的订单',
        these_were_added_to: '这些被添加到“我正在做什么”。',
        checkout_order_summary: '结帐 - 订单摘要',
        apply_discount_code: '应用优惠码',
        note: '注意：',
        finish_checkout_message: '在申请密码后10分钟内完成结账。',
        continue_shopping: '继续购物',
        next: '下一个',
        bill_me_later: '稍后再付账，给我发一张发票',
        your_order_is_safe: '您的订单是安全的和安全的。保证。',
        items: '项目',
        Discount: '折扣',
        invalid_coupon_code: '优惠券代码无效',
        cart_empty: '您的购物车中没有任何物品。',
        item_enrolled: '项目已经添加到我的学习。从购物车中删除它继续。',
        item_deleted: '物品不可再购买。从购物车中删除它继续。',
        total_amount_less_than_one: '您的总购物车金额，调整折扣，不能低于0.99美元。请调整您的购物车。',
        return_to_cart: '返回到购物车',
        checkout_pay_with_credit_card: '结帐 - 输入信用卡信息'
      },
      share_popup: {
        copy:'复制',
        message_default: '我以为你可能对这个项目感兴趣',
        link_share:'复制这个网址分享',
        embed_share:'复制这段代码并粘贴到网页中',
        embed_show_thumbnail:'显示缩略图',
        embed_show_add_learning:'显示添加到学习按钮',
        social_share:'点击你想要分享的社交网络',
        can_edit_users: '可编辑此课程的用户:',
        send_invite: '您的邀请已发送',
        share_edit: '成功共享'
      },
      share_popup_filter: {
        invite: '邀请',
        link: '链接',
        embed: '嵌入',
        social: '社交',
        share_for_edit: '共享编辑'
      },
      new_comment: {
        post:'帖子',
        write_a_comment: '在这里写下你的评论',
        enter_comment: '评论不能为空',
        comment_added: '推荐成功增加'
      },
      enrolled_item_question_lightbox: {
        create_question: '创建问题',
        no_questions: '还没有创建任何问题。成为第一个...',
        search_questions: '搜索问题',
        write_question: '写一个问题',
        about: '关于',
        participants: '参与者',
        best_question: '0最好的问题| 1个最佳的问题| {count}最好的问题',
        open_question: '0打开问题| 1打开问题| {count}打开问题',
        more: '更多',
        less: '减'
      },
      enrolled_item_new_answer: {
        enter_your_answer: '输入你的答案',
        add_answer: '添加答案',
        add_your_answer: '添加你的答案'
      },
      enrolled_item_question_detail: {
        back_to_questions: '< 回到问题',
        answers: '0回答| 1答案| {count}答案',
        asked_by: '问道'
      },
      inperson_event_edit: {
        edit_inperson_event: '编辑个人事件',
        schedule: '时间表',
        days: '天',
        time: '时间',
        instructors: '导师：',
        choose_instructor: '选择教师',
        categories: '分类：',
        choose: '选择',
        tags: '标签：',
        survey: '调查：',
        non_member_price: '非会员价格：',
        member_price: '会员价：',
        max_participants: '最大参与者：',
        free: '自由',
        unlimited: '无限',
        who_can_see_in_catalog: '谁可以在目录中看到这个面对面的活动：',
        every_one: '大家',
        only_certain_groups: '只有某些组',
        choose_groups: '选择组',
        auto_enroll: '自动注册',
        no_groups_created: '你还没有创建任何组',
        credits: '积分：',
        product_code: '产品代码：',
        choose_a_group: '選擇至少一個組',
        code_already_taken: '代碼已被採納',
        select_weekday: '平日未選中',
        create_content: '創建內容'
      },
      display_in_catalog_date: {
        begin: '開始',
        end: '結束'
      },
      choose_item_popup: {
        selected_users: '選定的用戶',
        create_new: '(創建新的)',
        selected_categories: '選擇的類別',
        selected_tags: '選定的標籤',
        selected_groups: '選定的組',
        available_categories: '可用的類別',
        available_tags: '可用的標籤',
        available_groups: '可用組'
      },
      price_schedule_popup: {
        price_schedule: '價格表',
        create_price: '創造價格'
      },
      price_schedule_list: {
        effective_now: '現在生效',
        starting: '開始'  
      },
      choose_survey: {
        select_survey_content: '選擇或創建調查或問題以添加到您的面對面活動中',
        mine: '礦',
        shared: '共享',
        folders: '文件夾',
        types: '類型',
        select_first: '請先選擇內容'
      },
      instruction: {
        instructions: '说明:',
        question: '0 题 | 1 题 | {count} 问题',
        start: '开始'
      },
      create_course: {
        title: '学习视频标题',
        description: '学习视频说明',
        category: '类别',
        price: '价钱',
        tags: '标签',
        prerequisites: '先决条件',
        who_can_see_this_course: '谁可以在目录中看到此课程',
        catalog_start_date: '开始',
        catalog_end_date: '结尾',
        access_expires: '访问过期',
        assign_course_to: '将此课程分配给',
        view_content: '查看内容',
        credits: '学分',
        product_code: '产品代码',
        schedule: '日程',
        scheduled_start_date: '从',
        scheduled_end_date: '到',
        heading: '创建课程',
        save: '节省',
        cancel: '取消',
        add_content: '添加内容',
        add_content_desc: '（选择内容添加到您的学习视频中）',
        languages: ['英语','法语','德语','意大利语','日语','韩语','葡萄牙语','俄语','简体中文','西班牙语']
      }      
    }
  }
});