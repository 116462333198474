<template>
	<div class="content_listing lxa-content_list">
		<div :id="'content-head-'+cnt.content_id" :class="[checkIfActive(cnt)? 'active lxa_bg_01 lxa_text_color_08 pb-0 m-0 px-3':'content_detail px-3 mt-3 mb-0']" class="lxa_font_size_02 lxa_light_font lxa_text_color_02" v-for="cnt in all_contents">
			<div class="row p-0 m-0 py-2" :class="checkIfActive(cnt)? '':'lxa_common_border_b1'">
				<div class="col-7 p-0" :id="'content_details_'+cnt.content_id" @click="playContent(cnt)">
					<div class="title lxa_font_size_03 lxa_medium_font">{{ cnt.content_title }}</div> 
					<div :class="[{'lxa_text_color_02': !checkIfActive(cnt) }, 'mt-2']" class="d-flex align-items-center justify-content-start"> 
						<span :class="[{'lxa_text_color_07': !checkIfActive(cnt) },'lxa_font_size_04']" v-if="cnt.duration != null">
						<i class="fad fa-clock"></i> 
					</span><span class="ml-1 mr-3" v-if="cnt.duration != null">{{ cnt.duration }}</span>
						<span :class="[{'lxa_text_color_07': !checkIfActive(cnt) },'lxa_font_size_04']">
						<i class="fad fa-calendar"></i>
						</span><span class="ml-1 mr-3"> {{ cnt.created_at }}</span>
					</div>
				</div>
				<div class="col-5 p-0">
					<!-- <div :class="[{'lxa_text_color_05': !checkIfActive(cnt) },'play_button']" :id="'course_content_play_'+cnt.content_id" @click="togglePlay(cnt, cnt.content_id)"> <span><i class="fad fa-play-circle float_right lxa_font_size_06"></i> </span></div> 
					<div :class="[{'lxa_text_color_05': !checkIfActive(cnt) },'pause_button display_none']" :id="'course_content_pause_'+cnt.content_id" @click="togglePlay(cnt, cnt.content_id)"> <span><i class="fad fa-pause-circle float_right lxa_font_size_06"></i> </span> </span></div> --> 
					<div :class="[{'lxa_text_color_02': !checkIfActive(cnt) },'percentage_status mt-3 pt-2']"> {{ cnt.progress_percent }} % Completed </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'LxpContent',
		props: ['all_contents', "active_content_id", "percentage"],
		data() {
			return{

			}
		},
		mounted() {
			for (var i = 0; i < this.all_contents.length; i++) {
        if (this.all_contents[i].content_id == this.active_content_id) {
          this.$parent.$parent.$parent.active_content = this.all_contents[i]
          return
        }
      }
		},
		methods: {
			togglePlay(cnt, content_id) {
			  if (this.checkIfActive(cnt)) {
          if (['video', 'audio', 'weblink'].includes(cnt.content_type)) {
						if ( $('.vjs-control-bar .vjs-paused').length != 0) {
							$('.vjs-control-bar .vjs-paused').click()
							$('#course_content_pause_'+content_id).show()
							$('#course_content_play_'+content_id).hide()
						} else {
							$('.vjs-control-bar .vjs-playing').click()
							$('#course_content_play_'+content_id).show()
							$('#course_content_pause_'+content_id).hide()
						}
				  }
			  } else {
			     this.playContent(cnt)
			  }
			},
			checkIfActive(cnt) {
				return (this.active_content_id == cnt.content_id) ? true : false
			},
			renderIcon(content) {
				var type = content.content_type
				var subTypes = {
					"video": "fad fa-video",
					"document": "fad fa-file-pdf",
					"slideshow": "fad fa-file-powerpoint",
					"weblink": "fad fa-video",
					"webtext": "fad fa-file-alt",
					"quiz": "fad fa-question",
					"survey": "fad fa-question",
					"scorm_package": "",
					"tincan_package": "",
				}
				var icon = subTypes[type]
				if(typeof icon == "undefined") {
					icon = "fad fa-book-reader"
				}
				return icon
			},
			fetchColor(content) {
				var type = content.content_type
				var colors = {
					"video": "red_color",
					"document": "orange_color",
					"slideshow": "dark_blue_color",
					"weblink": "green_color",
					"webtext": "dark_blue_color",
					"quiz": "green_color",
					"survey": "#blue_color",
					"scorm_package": "blue_color",
					"tincan_package": "green_color",
				}
				var color = colors[type]
				if(typeof color == "undefined") {
					color = "red_color"
				}
				if(this.active_content_id == content.content_id) {
					color = 'white_color'
				}
				return color
			},
			playContent(content) {
				var current_content_index = this.$parent.all_contents.indexOf(this.$parent.active_content)
				var refresh_content_url = this.$parent.active_content.refresh_content_progress_url;
        if (refresh_content_url != '') {
          this.$http.get(refresh_content_url).then(function (response) {
            console.log(this.$parent)
            console.log(this.$parent.all_contents)
            this.$parent.all_contents[current_content_index].progress_percent = response.body.progress_percent
          });
        }
				this.$root.$emit('changeContent', content)
				this.$parent.$parent.$parent.active_content = content
			}
		}
	}
</script>