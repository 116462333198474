import Vue from 'vue/dist/vue.esm'
Vue.component('inbox-popup',{
  template:`<div id="inbox_messages_display" class="inbox_home padding_bottom_3">
              <div class="container-fluid" id="container">  
                <div class="row" id="row">
                  <div class="col-sm-3 padding_top_2 margin_bottom_2" >
                    <label class="change_font padding_top_2">{{$t('message.mail')}}</label> 
                  </div>
                </div>
                <hr v-if="!show_new_form" class="inbox_hr">
              </div>
              <div v-infinite-scroll="loadMoreMessages" infinite-scroll-disabled="busy" infinite-scroll-distance="10" infinite-scroll-immediate-check="false" v-if="show_inbox_msg" id="inbox_messages_display_container" class="padding_bottom_6">
                <div v-if="inbox_response.messages.length>0">
                  <message v-for="(content,index) in inbox_response.messages" :index="index" :page_no="page" :message_item="content" :user_messages_url="user_messages_url" :delete_selected_message_url="inbox_response.delete_selected_message_url" :id="content.message_id" :mark_as_read_user_messages_url="inbox_response.mark_as_read_user_messages_url" v-bind:key="content.message_id"></message>
                </div>
                <div v-else class="padding_top_10 padding_left_16 font_open_sans_regular_12_dark_gray">{{$t('common.none')}}</div>
                <div class="loading hidden">
                  <hr class="loading_hr">
                  <item-loader></item-loader>
                </div>                
              </div>
              <div v-infinite-scroll="loadMoreMessages" infinite-scroll-disabled="busy" infinite-scroll-distance="10" infinite-scroll-immediate-check="false" v-if="show_sent_msg" id="sent_messages_display_container" class="padding_bottom_6">
                <div v-if="inbox_response.messages.length>0">
                  <sent_message_component v-for="(content,index) in inbox_response.messages" :index="index" :page_no="page" :message_item="content" :user_messages_url="user_messages_url" :delete_selected_message_url="inbox_response.delete_selected_message_url" :id="content.message_id" v-bind:key="content.message_id"></sent_message_component>
                </div>
                <div v-else class="padding_top_10 padding_left_16 font_open_sans_regular_12_dark_gray">{{$t('common.none')}}</div>
                <div class="loading hidden">
                  <hr class="loading_hr">
                  <item-loader></item-loader>
                </div>                    
              </div>
            </div>`,
  props: {
    inbox_response: Object,
    user_messages_url: String,
    sent_message: Boolean,
    inbox_message: Boolean,
    total_message_pages: Number,
    inbox_url: String
  },
  data() {
    return {
      active: false,
      show_inbox_msg: this.inbox_message,
      show_sent_msg: this.sent_message,
      show_new_form: false,
      messages_obj: {},
      current_view: 'inbox',
      inbox_res: this.inbox_response,
      page: 1,
      busy: false
    }
  },
  mounted: function() {
    jQuery("#inbox_messages_display_container").niceScroll({cursorcolor:"#4184F3",cursorwidth:"3px",autohidemode: "scroll"});
    jQuery("#sent_messages_display_container").niceScroll({cursorcolor:"#4184F3",cursorwidth:"3px",autohidemode: "scroll"});
  },
  methods:{
    new_message: function(event) {
      this.show_new_form = true;
      jQuery("#inbox_messages_display_container").hide();
      jQuery("#sent_messages_display_container").hide();
      jQuery(".new_message").hide();
    },
    loadMoreMessages: function() {
      // var self = this;
      // self.busy = true;
      // category = self.show_sent_msg? "&mailbox=sent" : "&mailbox=inbox";
      // url = self.user_messages_url+".json?" + category;
      // current_view = self.current_view+"_messages_display_container";
      // if(self.page < self.total_message_pages){
      //   self.page = self.page + 1;
      //   ajaxloadcontent(self.page, url, current_view, 'inbox_loader', function(response) {
      //     if(response.messages.length != 0){
      //       jQuery("#"+current_view).find('.loading').addClass('hidden');
      //       jQuery("#"+current_view).find('.circular_loader').addClass('hidden');
      //       self.$root.$children[0].inbox_response.messages = self.$root.$children[0].inbox_response.messages.concat(response.messages);
      //       self.busy = false;
      //     }
      //   });
      // } 
    },    
  }
});

Vue.component('new-message-popup', {
  template:`<div id="new-message-display">
              <form id="new_message" class="form-horizontal padding_left_3" v-on:submit.prevent="onSubmit" role="form">
                <div class="padding_left_10">
                  <div>
                    <input id="email" type="text" class="form-control input-sm tags message_input hide" v-validate="'required'" data-vv-validate-on="none" name="emails" :placeholder="$t('message.to')" autocomplete="off">
                    <newTagInput v-bind:recipient_err="errors.has('emails')" />
                    <div class="error_container">  
                      <span class ="font_open_sans_regular_11_red inbox_err inline_block padding_bottom_5" v-show="errors.has('emails')">{{ $t('message.recipient_error') }}</span>
                    </div>                 
                  </div>
                  <div>
                    <input id="message_subject" name="message[subject]" v-bind:class="[{border_btm_light_grey: errors.has('message[subject]')},'message_input form-control input-sm']" type="text" v-validate="'required'" data-vv-validate-on="none" :placeholder="$t('message.subject')" v-model="subject" style="padding-left: 5px !important">
                    <div class="error_container">  
                      <span class ="font_open_sans_regular_11_red inbox_err inline_block padding_bottom_5" v-show="errors.has('message[subject]')">{{ $t('message.subject_error') }}</span>
                    </div>                 
                  </div>
                  <div style="margin-top: -1px;">
                    <textarea id="message_body" name="message[body]" v-bind:class="[{border_btm_light_grey: errors.has('message[body]')},'message_input form-control input-sm']" v-validate="'required'" data-vv-validate-on="none" :placeholder="$t('message.message_content')" style="height:95px !important;padding-left: 5px !important" v-model="message"></textarea>           
                    <div>  
                      <span class ="font_open_sans_regular_11_red inbox_err margin_top_min_5 inline_block padding_bottom_5" v-show="errors.has('message[body]')">{{ $t('message.body_error') }}</span>
                    </div>                 
                  </div> 
                  <div class="new_message_div" id="new_message_submit">
                    <div class="container-fluid margin_top_5">
                      <div class="row">  
                        <div class="col-sm-12 padding_top_0 text_align_right margin_left_15 padding_right_10">
                          <div class="cancel_alignment">
                            <a class="cancel inbox_cancel padding_right_6" v-on:click="message_cancel">{{$t('common.cancel')}}</a>
                          </div>
                          <div class="right_float">
                            <button type="submit" class="font_open_sans_semibold_10_blue btn btn-default btn-round-sm">{{$t('common.send').toUpperCase()}}</button>
                          </div>
                        </div>
                      </div> 
                    </div>
                    <div class="new_message_div new_msg_success hide" style="width: 260px" v-bind:id="'timed_span' + new Date().getTime()">{{$t('message.send_message')}}</div>    
                  </div> 
                </div>
              </form>         
            </div>`,
  props: {
    user_messages_url: String,
    auto_complete_url: String
  },
  data: function() {
    return{
      emails: '', 
      subject: '',
      message: '',
      active: false
   }
  },
  mounted: function() {
    jQuery('#newTagsInput').typeahead({
      displayField: "email",
      ajax: {
        url: this.auto_complete_url+'.json',
        triggerLength: 1
      },
      valueField: "name",
      onSelect: function(e) {updateElementForMessages(e);}
    });
    jQuery("input#newTagsInput").focus();
  },
  methods:{
    message_cancel: function(event){
      this.$parent.show_new_form = false;
      jQuery(".new_message").show();
      jQuery("#inbox_messages_display_container").show();
      jQuery("#sent_messages_display_container").show();
      jQuery(".new_message").show();
    },
    onSubmit() {
      var pr = this.$parent;
      this.$validator.validateAll().then(result => {
        if (result) {
          var self = this;
          jQuery.ajax({
            type: 'POST',
            url: this.user_messages_url+'.json',
            data : jQuery('#new_message').serialize(),
            success: function(response) {
              jQuery(".new_msg_success").removeClass('hide');
              jQuery("#new_message_submit .container-fluid").addClass('hide');
              setTimeout(function() {
                pr.show_new_form = false;
                jQuery("#inbox_messages_display_container").show();
                jQuery("#sent_messages_display_container").show();
                jQuery(".new_message").show();
              }, 4000);
            }
          });
        }
      });  
    }
  }
});

Vue.component('newTagInput', {
  template:`<div v-bind:class="[{border_btm_light_grey: recipient_err}, 'newTagsEditor lxa_border_radius']">
              <ul class="newTagsList tagLines p-0 _primary_bgcolor lxa_border_radius border-0">
                <li class="newTagInputWrapper newTagListItem" id="newTagInputWrapper">
                  <input id="taginput_fixed_width" name="taginput_fixed_width" class="primary_bgcolor" type="hidden" value="false">
                  <input type="text" name="newTags" id="newTagsInput" :placeholder="$t('message.to')" class="tab newTagInput yui-ac-input class_1504400137 w-100 ml-0 primary_bgcolor lxa_border_radius" autocomplete="off">
                  <div id="tmpTagsInput"></div>
                </li>
                <li class="clr" id="newTagClr" />
              </ul>
            </div>`,
  props: {
    recipient_err: Boolean
  },
  mounted: function() {
    AttachTagEditorEvents();
  }
});

Vue.component('inbox-message-filter', {
  template:`<div id="inbox_message_type">
              <ul id="col-sm-12 inbox_type_select">
                <li id="inbox_list" v-on:click="onClick"><div id="inbox" class="change_font_regular padding_left_0 word_wrap"> {{$t('message.inbox')}}<span class="tick_mark span_padding right_float"><img class="inbox_tick" src="/images/single_page/tick.png"></span></div></li>
                <li v-on:click="onClick"><div id="sent" class="change_font_regular padding_left_0 word_wrap"> {{$t('message.sent')}}</div></li>
              </ul>      
            </div>`,
  props: {
    user_messages_url: String
  }, 
  data: function(){
    return {
      count: 1
    }
  },      
  methods: {
    onClick: function(event) {
      if (this.count == 1) {
        var list_width = jQuery('#inbox_list').width();
        jQuery("#inbox").css('width',list_width);
        jQuery("#sent").css('width',list_width);
      }
      this.count = this.count + 1;
      jQuery('.tick_mark').remove();
      jQuery('#'+event.target.id).append('<span class="tick_mark text_align_right right_float"><img class="inbox_tick" src="/images/single_page/tick.png"></span>');
      this.$parent.busy = false;
      if (event.target.id=="sent") {    
        var self = this;
        jQuery.ajax({
          type: 'GET',
          url: this.user_messages_url+'.json',
          data : jQuery('#form').serialize() + "&mailbox=sent",
          success: function(response) {
            self.$parent.show_inbox_msg = false;
            self.$parent.show_sent_msg = true;
            self.$parent.current_view = 'sent';
            self.$parent.$parent.inbox_response = response;
            self.$parent.page = 1;
            self.$parent.$parent.total_message_pages = response.total_pages;
            jQuery("#sent_messages_display_container").niceScroll({cursorcolor:"#4184F3",cursorwidth:"3px",cursorfixedheight: "50",autohidemode: "scroll"});
          }
        });
  
      }
      else{
        jQuery('#reply_link'+this.id).removeClass("hide");
        var self = this;
        jQuery.ajax({
          type: 'GET',
          url: this.user_messages_url+'.json',
          data : jQuery('#form').serialize() + "&mailbox=inbox",
          success: function(response) {
            self.$parent.show_sent_msg = false;
            self.$parent.show_inbox_msg = true;
            self.$parent.current_view = 'inbox';
            self.$parent.$parent.inbox_response = response;
            self.$parent.page = 1;
            self.$parent.$parent.total_message_pages = response.total_pages;
          }
        });
      }
      setTimeout(function(){ 
        jQuery("#inbox_messages_display").removeClass("hide");
        jQuery("#new-message-display").addClass("hide");
      },1000);
    },
  }
});

Vue.component('reply-option',{
  template:`<div v-bind:id="'delete_' + id" class="popover reply_popover bottom in" style="display: block">
              <div class="reply_arrow" id="arrow-position"><img src="/images/single_page/arrow.png"></div>
                <div class="reply" style="padding-left:12px;padding-top:6px">
                  <div v-if="this.email" v-bind:id="'reply_link' + id">
                    <a class="col-sm-11 padding_left_0 change_font_regular reply" v-on:click="show_reply" style="text-decoration:none;line-height:23px;cursor: pointer">{{$t('message.reply')}}</a>
                  </div>
                  <div>
                    <a class="col-sm-11 padding_left_0 change_font_regular reply" v-on:click="delete_message" style="text-decoration:none;cursor: pointer">{{$t('common.delete_string')}}</a>
                  </div>
                </div>    
            </div>`,
  props:{
    id: Number,
    email: String,
    delete_selected_message_url: String
  },
  methods:{
    show_reply: function(event){
      jQuery('#reply_message'+this.id).css('display', '');
      jQuery('#reply_message_submit'+this.id).css('display', '');
      jQuery("#timed_span"+this.id).addClass("hide"); 
    },
    delete_message: function(event){
      var self = this;
      jQuery.ajax({
        type: 'POST',
        url: this.delete_selected_message_url+'.json',
        data : jQuery('#form').serialize() + "&list="+this.id,
        success: function(response) { 
          jQuery('#message_' + self.id).addClass("hide");
        }
      });
    }
  }
});

Vue.component('message',{
 template:`<div v-bind:class="[{padding_top_6: index==0},{cursor_pointer: truncated}, 'container-fluid message_container padding_right_0 padding_left_5']" :id="'message_' + id" v-on:click="show_full_message($event)">
              <div class="rowinbox" id="inbox_row">
                <hr v-if="index!=0"/>
                <div class="col-sm-1 padding_top_3">
                  <div class="inbox_circle">
                    <div class="inboxCircle" v-bind:class="{ bold: !read_at}">{{message_item.display_name[0]}}</div>
                  </div>
                </div>
                <div class="col-sm-10 padding_top_1 padding_left_9" style="padding-right: 0px;width: 78.333333%;">
                  <div id="message_body_id">
                    <label class="message_body_class message_sender_name word_wrap" v-bind:class="[{ bold: !read_at},{cursor_pointer: truncated}]" v-bind:id="'message_firstname' + id">{{message_item.display_name}}</label>
                    <br/>
                    <label class="message_body_class message_subject_display word_wrap" v-bind:class="[{ bold: !read_at},{cursor_pointer: truncated}]" v-bind:id="'message_subject' + id">{{$t('message.subject')}}: {{message_item.message_subject}}</label>
                    <br/>
                    <label v-bind:id="'message_body' + id" v-if="truncated" class="message_body_class message_body_display word_wrap margin_top_1 cursor_pointer" v-bind:class="{ bold: !read_at}">{{message_body_truncated}}</label>
                    <label v-if="!truncated" class="message_body_class message_body_display word_wrap margin_top_1">{{message_item.message_body}}.</label>
                    <br/>
                    <label class="message_body_class padding_top_5" id="time" style="color:#808285;font-size:10px !important;font-family: OpensansRegular !important">{{message_item.message_time}}</label>
                    <br/>
                  </div>                
                </div>
              </div>
            </div>`,
  props: {
    index: Number,
    page_no: Number,
    message_item: Object,
    user_messages_url: String,
    delete_selected_message_url: String,
    mark_as_read_user_messages_url: String,
    id: Number
  },
  data: function(){
    return{
      reply_message_data: '\n' + this.$t("message.on") + ' ' + this.message_item.message_time + ', ' + this.message_item.display_email + ' ' + this.$t("message.wrote")  + this.message_item.message_body,
      message_data: '\n' + this.$t("message.on") + ' ' + this.message_item.message_time + ', ' + this.message_item.display_email + ' ' + this.$t("message.wrote")  + this.message_item.message_body,
      active: false,
      message_body_truncated: '',
      truncated: true,
      read_at: true,
      invalid_username: this.$t('message.invalid_username')
    }
  },            
  mounted: function () {
    if (!this.message_item.message_read_at) {
      this.read_at = false;
    }  
    if (this.message_item.message_body.length >= 20) {
      for (var i = 0; i < 20; i++) {
        this.message_body_truncated = this.message_body_truncated + this.message_item.message_body[i];
      } 
    }
    else{
      for (var i = 0; i < this.message_item.message_body.length; i++) {
        this.message_body_truncated = this.message_body_truncated + this.message_item.message_body[i];
      }
    }   
  },
  methods: {
    cancel_reply: function(){
      this.reply_message_data = this.message_data;
      jQuery('#reply_message'+this.message_item.message_id).css('display', 'none');
    },
    show_full_message: function(event){
      if(!hasClass(event.target,'reply')){
        jQuery('#message_subject' + this.id).removeClass("bold");
        jQuery('#message_firstname' + this.id).removeClass("bold");
        this.truncated = !this.truncated;
        jQuery('#message_body' + this.id).removeClass("bold");
        jQuery("#inbox_messages_display_container").getNiceScroll().resize(); 
        if (!this.read_at) {
          var self = this;
          jQuery.ajax({
            type: 'POST',
            url: this.mark_as_read_user_messages_url + '.json',
            data : jQuery('#form').serialize() + "&list="+this.message_item["message_id"] +"&from_button_click=false",
            success: function(response) {
              self.$parent.$parent.updated_inbox_count = response;
              self.read_at = true;
              jQuery('#message_body' + self.id).removeClass("bold");
            }
          });  
        }
      }
    },
    save_reply: function() {
      this.$validator.validateAll().then(result => {
        if (result) {
          var self = this;
          jQuery.ajax({
            type: 'POST',
            url: this.user_messages_url+'.json',
            data : jQuery('#form').serialize() + "&message[to]="+this.message_item["display_email"] +"&message[subject]=Re: "+this.message_item["message_subject"] +"&message[body]="+this.reply_message_data +"&in_reply_to_message="+this.message_item["message_id"],
            success: function(response) {
              if (response == true) {
                jQuery("#message_error").html(self.invalid_username);
              }
              else{
                setTimeout(function(){ 
                  jQuery('#reply_message'+self.message_item["message_id"]).css('display', 'none');
                  self.reply_message_data = self.message_data;
                },4000);
                jQuery('#reply_message_submit'+self.message_item["message_id"]).css('display', 'none');
                jQuery("#timed_span"+self.message_item["message_id"]).removeClass("hide");            
              }
            }
          });
        }
      });    
    }
  }          
});

Vue.component('sent_message_component', {
  template:`<div v-bind:class="[{padding_top_6: index==0}, 'container-fluid message_container padding_right_0 padding_left_5']" v-bind:id="'sent_message_' + id" v-on:click="show_full_message($event)">
              <div class="rowinbox" id="inbox_row">
                <hr v-if="index!=0"/>
                <div class="col-sm-1 padding_top_3">
                  <div class="inbox_circle">
                    <div class="inboxCircle">{{message_item.display_name[0]}}</div>
                  </div>
                </div>
                <div class="col-sm-10 padding_top_1 padding_left_9" style="width: 78.333333%;">
                  <div id="message_body">
                    <label class="message_body_class message_sender_name word_wrap">{{message_item.display_name}}</label>
                    <br/>
                    <label class="message_body_class message_subject_display word_wrap">Subject: {{message_item.message_subject}}</label>
                    <br/>
                    <label v-if="truncated" class="message_body_class message_body_display word_wrap margin_top_1">{{message_body_truncated}}</label>
                    <label v-if="!truncated" class="message_body_class message_body_display word_wrap margin_top_1">{{message_item.message_body}}</label>
                    <br/>
                    <label class="message_body_class message_time_display padding_top_5">{{message_item.message_time}}</label>
                    <br/> 
                   </div>                
                </div>
                <div class="col-sm-1 mini padding_top_2">
                  <div class="more_img padding_left_16" v-on:mouseover="active = true" v-on:mouseleave="active = false">
                    <img src="/images/single_page/more_icon.png" alt="more" class="more">
                    <delete-option id="reply_option" v-show="active" v-bind:id="message_item.message_id" :delete_selected_message_url="delete_selected_message_url"></delete-option>
                  </div>
                </div>
              </div> 
            </div>`,
  props: {
    index: Number,
    page_no: Number,
    message_item: Object,
    delete_selected_message_url: String,
    id: Number
  },
  data: function(){
    return{
      reply_message_data: '',
      message_body_truncated: '',
      active: false,
      truncated: true
    }
  },
  mounted: function () {  
    if (this.message_item.message_body.length >= 20) {
      for (var i = 0; i < 20; i++) {
        this.message_body_truncated = this.message_body_truncated + this.message_item.message_body[i];
      } 
    }
    else{
      for (var i = 0; i < this.message_item.message_body.length; i++) {
        this.message_body_truncated = this.message_body_truncated + this.message_item.message_body[i];
      }
    }   
  },
  methods: {
    cancel_reply: function(){
      this.reply_message_data = '';
      jQuery('#reply_message'+this.message_item["message_id"]).css('display', 'none');
    },
    show_full_message: function(){
      if(!hasClass(event.target,'reply')){
        this.truncated = !this.truncated;
      }
    }
  }          
});

Vue.component('delete-option',{
  template:`<div v-bind:id="'delete_' + id" class="popover reply_popover bottom in" style="display: block">
              <div class="reply_arrow" id="arrow-position"><img src="/images/single_page/arrow.png"></div>
                <div class="reply" style="padding-left:12px;padding-top:6px;">
                  <div>
                    <a class="col-sm-11 padding_left_0 change_font_regular reply" v-on:click="delete_message" style="text-decoration:none;cursor: pointer">{{$t('common.delete_string')}}</a>
                  </div>
                </div>    
            </div>`,
  props:{
    id: Number,
    delete_selected_message_url: String
  },
  methods:{
    delete_message: function(event){
      var self = this;
      jQuery.ajax({
        type: 'POST',
        url: this.delete_selected_message_url+'.json',
        data : jQuery('#form').serialize() + "&list="+this.id,
        success: function(response) { 
          jQuery('#sent_message_' + self.id).addClass("hide");
        }
      });
    }
  }
});