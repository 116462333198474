import Vue from 'vue/dist/vue.esm';
const EventBus = new Vue();

Vue.component('user-testimonial', {
  template: `<div class="modal" id="user_testimonial_modal" role="dialog">
                <div class="modal-xl">
                  <div class="modal-content user_account_setting">
                    <div class="mb-4 d-flex align-items-center justify-content-start">
                      <div class="account_setting_heading lxa_text_color_05 lxa_font_size_08 lxa_light_font"> 
                        <span v-if="site_admin"> {{$t('testimonial.admin_heading')}} </span>
                        <span v-else> {{$t('testimonial.user_heading')}} </span>
                      </div>
                      <span data-toggle="modal" data-target="#collect_user_testimonial_modal" data-backdrop="static" data-keyboard="false" class="btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase ml-auto cursor_pointer" v-if="!site_admin" @click="collectUserFeedback()"> Provide feedback </span>
                    </div>
                    <p class="light_box_close" data-dismiss="modal"> <i data-toggle="tooltip" data-placement="top" title="Close" class="fad fa-times lxa_text_color_08 lxa_font_size_06 cursor_pointer"></i></p>

                    <div class="lxa_menu_container lxa_font_size_04 lxa_menu_container d-flex align-items-center justify-content-start pb-4" v-if="site_admin">
                      <span :class="[{'active menu_active': active_tab == 'published'}, 'px-0 cursor_pointer menu']" @click="RemoveScroll();active_tab='published'"> Published </span>
                      <span :class="[{'active menu_active': active_tab == 'unpublished'}, 'px-0 cursor_pointer menu']" @click="RemoveScroll();active_tab='unpublished'"> Unpublished</span>
                    </div>

                    <div class="row mx-0 lxa_font_size_04" v-if="site_admin">
                      <publish-unpublish-testimonial :testimonials="published_testimonials" :active_tab="active_tab" v-if="active_tab == 'published'" :site_admin="site_admin"> </publish-unpublish-testimonial>
                      <publish-unpublish-testimonial :testimonials="unpublished_testimonials" :active_tab="active_tab" v-if="active_tab == 'unpublished'" :site_admin="site_admin"> </publish-unpublish-testimonial>
                    </div>
                    <div class="h-100" v-else>
                      <publish-unpublish-testimonial :testimonials="my_testimonials" :site_admin="site_admin"> </publish-unpublish-testimonial>
                    </div>

                  </div>
                </div>
              </div>`,
  data: function() {
    return {
      active_tab: 'published',
      published_testimonials: [],
      unpublished_testimonials: [],
      my_testimonials: []
    }
  },
  props: {
    site_admin: Boolean,
    logged_in: Boolean
  },
  mounted: function() {
    if (this.logged_in) {
      if (this.site_admin) {
        this.fetch_testimonials();
      } else {
        this.fetch_user_testimonials()
      }
      EventBus.$on("updateTestimonials", () => {
        this.fetch_testimonials()
      });
      EventBus.$on("updateUserTestimonials", () => {
        this.fetch_user_testimonials()
      });
    }
  },
  methods: {
    fetch_testimonials() {
      this.$http.get('/fetch_testimonial_data.json').then(function(response) {
        this.published_testimonials = response.body.published_testimonials
        this.unpublished_testimonials = response.body.unpublished_testimonials
      });
    },
    fetch_user_testimonials() {
      this.$http.get('/fetch_user_testimonial_data.json').then(function(response) {
        this.my_testimonials = response.body.my_testimonials
      });
    },
    collectUserFeedback() {
      this.$root.$emit('collectUserFeedback')
    },
    RemoveScroll: function() {
      jQuery("#testimonial_container").slimScroll({destroy: true});
      jQuery("#user_testimonial_modal .lxascroll").remove();
    }
  }
});

Vue.component('publish-unpublish-testimonial', {
  template: `<div id="testimonial_container" class="col-12 px-0">
                <div v-if="testimonials.length">
              <div class="row mx-0 lxa_common_border_b1" v-for="(testimonial,tmlindex) in testimonials" :class="(tmlindex==0) ? 'pb-3':'py-3'">
                <div class="col-12 px-0">
                  <div class="d-flex align-items-start justify-content-start">
                  <span class="pull-left"> <img class="thumbnail_very_large lxa_border_radius border-0" :src="testimonial.user_photo_url"></span>
                  <div class="pl-2 lxa_font_size_04 d-flex align-items-center justify-content-start lxa-testimonial-list">
                   <div class="lxa_testimonial_title_date">
                    <div :class="colors[random]"> <b>{{ testimonial.title }}</b>, {{ testimonial.user_profession}} </div>
                    <div class="mt-1"> {{ testimonial.created_at}} </div>
                   </div>
                    <span class="ml-auto publish_unpublish btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase cursor_pointer" v-if="active_tab == 'unpublished' && site_admin" @click.prevent="publish_testimonial(testimonial, true)"> Publish </span> 
                    <span class="ml-auto publish_unpublish btn lxa_bg_01 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase cursor_pointer" v-if="active_tab == 'published' && site_admin" @click.prevent="publish_testimonial(testimonial, false)"> Unpublish </span> 
                  </div>
                  </div>
                </div>
                <div class="col-12 px-0 pt-2">
                  <span class="lxa_font_size_04 lxa_text_color_03"> {{ testimonial.user_comments }} </span>
                </div>
              </div>
              </div>
              <div v-else>No Testimonial to Display</div>
            </div>`,
  props: {
    testimonials: Array,
    active_tab: String,
    site_admin: Boolean
  },
  data() {
    return {
      colors: ['lxa_text_color_01', 'lxa_text_color_04', 'lxa_text_color_06'],
      random: Math.floor(Math.random() * 3) 
    }
  },
  mounted: function() {
    var self = this;
    setTimeout(function(){ 
      self.LoadScroll();
      // jQuery("#announcement_display").niceScroll({fixed:true,cursorcolor:"#4184F3",cursorwidth:"3px",autohidemode: "scroll", nativeparentscrolling: false});
    }, 20);
  },
  updated: function() {
    this.LoadScroll();
  },
  methods: {
    publish_testimonial(testimonial, published) {
      testimonial.published = published
      testimonial.active = true
      var data = {...testimonial}
      this.$http.post('/publish_testimonial', data).then(function(response) {
        this.$parent.fetch_testimonials();
        var message = published ? 'Successfully Published' : 'Unpublished'
        this.$root.popUpMessage(message);
        EventBus.$emit("updateTestimonialsAdmin");
      });
    },
    LoadScroll: function() {
      jQuery("#testimonial_container").slimScroll({
        barClass:'lxascroll',
        size : '3px',
        width: '100%',
        height : '100%',
        position : 'right',
        start : 'top',
        alwaysVisible : false,
        distance: '0',
        borderRadius: '0',
        // railVisible: true,
        railBorderRadius:0,
        opacity:1,
        allowPageScroll: true,
        touchScrollStep:200,
        enableKeyNavigation: true
      });
    }
  }
});

Vue.component('approved-testimonial', {
  template: `<div v-if="published_testimonials.length > 0 && !show_cart">
              <div class="col-12 px-0 lxa_mt_60" >
                <span class="text_align_left lxa_main_title_light"> {{ $t('testimonial.what_they_are_saying')}} </span>
              </div>

              <div class="carousel slide pt-3" data-ride="carousel" id="approved_testimonial_carousel">
                <div class="carousel-inner">
                  <div class="carousel-item active" style="background: transparent;">
                    <div class="row m-0" id="approved_testimonial">
                      <div class="col-12 approved p-3 cursor_pointer" v-for="approved in published_testimonials" @mouseover="mouseOver(approved)" @mouseout="mouseOut(approved)">
                        <div class="text_align_center">
                          <img class="thumbnail_very_large" :src="approved.user_photo_url">
                          <span v-if="site_admin" :id="'unpublish_'+approved.id" class="unpublish cursor_pointer lxa_font_size_04 lxa_text_color_01" style="display: none;" @click="unpublish(approved)"> <i class="fad fa-trash-alt"></i> Unpublish </span>
                        </div>
                        <!-- <div class="text_align_center mt-2 lxa_font_size_04" :style="{color: colors[random]}"> {{ approved.title}}</div> -->
                        <div class="lxa_text_color_03 text_align_center mt-2 lxa_font_size_04"> {{ approved.user_comments}}</div>
                        <div class="text_align_center mt-2 lxa_font_size_04" :style="{color: colors[random]}"> 
                          <b>{{ approved.user_name }}</b>
                          <span v-if="approved.user_profession.length != 0">, {{approved.user_profession}}  </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <a class="carousel_arrow carousel-control-prev" v-on:click="prev_page()" role="button" data-slide="prev">
                  <div class="prev_icon_box">
                    <span class="carousel-control-prev-icon" aria-hidden="true">
                     <i class="fad fa-chevron-left"></i>
                    </span>
                    <span class="sr-only">Previous</span>
                  </div>
                </a>
                <a class="carousel_arrow carousel-control-next" v-on:click="next_page()" role="button" data-slide="next">
                  <div class="next_icon_box">
                    <span class="carousel-control-next-icon" aria-hidden="true">
                     <i class="fad fa-chevron-right"></i>
                    </span>
                    <span class="sr-only">Next</span>
                  </div>
                </a>
              </div>
            </div>`,
  data: function() {
    return {
      published_testimonials: [],
      current_page: 1,
      colors: ['#e64235', 'orange', 'blue'],
      random: Math.floor(Math.random() * 3)
    }
  },
  props: {
    show_cart: Boolean,
    site_admin: Boolean
  },
  mounted() {
    var params_per_page = 'page='+this.current_page+'&per_page=3';
    this.collect_testimonials(params_per_page)
    EventBus.$on("updateTestimonialsAdmin", () => {
      this.collect_testimonials(params_per_page)
    });
  },
  methods: {
    mouseOver(approved) {
      if(this.site_admin) {
        $('#unpublish_'+approved.id).css('display', 'initial');
      }
    },
    mouseOut(approved) {
      $('#unpublish_'+approved.id).css('display', 'none');
    },
    collect_testimonials(params_per_page) {
      this.$http.get('/fetch_approved_testimonial.json?'+params_per_page).then(function(response) {
        if (response.body.published_testimonials.length != 0 ){
          this.published_testimonials = response.body.published_testimonials
        } else {
          this.current_page = this.current_page - 1;
          if (this.current_page == 0) {
            this.published_testimonials = []
          }
        }
      });
    },
    prev_page() {
      this.current_page = this.current_page - 1;
      this.current_page = this.current_page == 0 ? 1 : this.current_page 
      var params_per_page = 'page='+this.current_page+'&per_page=3';
      this.collect_testimonials(params_per_page)
    },
    next_page() {
      this.current_page = this.current_page + 1;
      var params_per_page = 'page='+this.current_page+'&per_page=3';
      this.collect_testimonials(params_per_page)
    },
    unpublish(approved) {
      approved.published = false
      var data = {...approved}
      if ((this.published_testimonials.length % 2) != 0) {
        this.current_page = this.current_page - 1
      }
      this.$http.post('/publish_testimonial', data).then(function(response) {
        this.$root.popUpMessage('Unpublished');
        var params_per_page = 'page='+this.current_page+'&per_page=3';
        this.collect_testimonials(params_per_page)
        EventBus.$emit("updateTestimonials");
      });
    }
  }
});

Vue.component('collect-testimonial', {
  template: `<div class="modal" id="collect_testimonial_modal" role="dialog" v-if="collect_feedback">
                <div class="modal-xl">
                  <div class="modal-content user_account_setting collect_testimonial_first">
                    <div class="mb-4" v-if="!provide_feedback">
                    <span class="lxa_text_color_05 lxa_font_size_08 lxa_light_font"> {{ $t('testimonial.collect_feedback') }} </span> 
                    </div>
                    <div class="mb-4" v-else>
                      <span class="lxa_text_color_05 lxa_font_size_08 lxa_light_font"> {{$t('testimonial.learner_heading')}} </span>
                    </div>
                    <p class="light_box_close" data-dismiss="modal"> <i data-toggle="tooltip" data-placement="top" title="Close" class="fad fa-times lxa_text_color_08 lxa_font_size_06 cursor_pointer"></i></p>

                    <div class="row mx-0 mt-5" v-if="!provide_feedback">
                      <div class="col-12 p-0 text-center">
                        <span class="btn lxa_bg_01 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" @click="collect_feedback_later('dont_ask_again')"> {{ $t('testimonial.dont_ask_again').toUpperCase() }} </span>
                        <span class="btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase ml-3" @click="toggle_provide_feedback"> {{ $t('testimonial.provide_feedback').toUpperCase() }} </span>
                        <span class="btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase ml-3" @click="collect_feedback_later('maybe_later')"> {{ $t('testimonial.maybe_later').toUpperCase() }} </span>
                      </div>
                    </div>

                    <div v-else>
                      <div class="col-12 px-0">
                        <div class="lxa_font_size_04">
                          <label class="lxa_font_size_04 lxa_text_color_02 lxa_light_font"> Name </label>
                          <input type="text" class="primary_bgcolor lxa_border_radius lxa_font_size_04 p-2 col-12 border-0" v-model="new_testimonial.title" required/>
                        </div>

                        <div class="lxa_font_size_04 mt-3">
                          <label class="lxa_font_size_04 lxa_text_color_02 lxa_light_font"> Description </label>
                          <span class="lxa_text_color_09 lxa_font_size_04 float_right">{{charactersLeft}}</span>
                          <textarea class="primary_bgcolor lxa_border_radius lxa_font_size_04 p-2 txtarea-resize col-12 border-0" v-model="new_testimonial.user_comments" :maxlength="maxlength" required> </textarea>
                        </div>

                        <div class="lxa_font_size_04 mt-4 float_right">
                          <span class="btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" @click="cancel_testimonial">Cancel</span>
                          <span class="btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase ml-3" @click="submit_testimonial">Submit</span>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>`,
  props: {
    logged_in: Boolean
  },
  data() {
    return {
      new_testimonial: Object,
      collect_feedback: Boolean,
      provide_feedback: false,
      maxlength: 500
    }
  },
  mounted() {
    this.collect_testimonials();
  },
  methods: {
    collect_testimonials() {
      this.$http.get('/fetch_testimonial_data.json').then(function(response) {
        this.new_testimonial = response.body.new_testimonial
        this.collect_feedback = response.body.collect_feedback
      });
    },
    cancel_testimonial() {
      this.$modal.show('dialog', {
        text: 'The Testimonial that you provided will be deleted.',
        buttons: [{
            title: 'Cancel',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Yes',
            handler: () => {
              this.close()
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    },
    submit_testimonial() {
      if (this.new_testimonial.title === '' || this.new_testimonial.title === null) {
        this.$root.popUpMessage("Please Provide Name");
      } else if (this.new_testimonial.user_comments === '' || this.new_testimonial.user_comments === null) {
        this.$root.popUpMessage("Please Provide Comments");
      } else {
        var data = {...this.new_testimonial}
        this.$http.post('/submit_testimonial', data).then(function(response) {
          this.$root.popUpMessage("Successfully Submitted");
          this.close();
        });
      }
    },
    collect_feedback_later(data) {
      var data = {...{status: data}}
      this.$http.post('/collect_feedback_later', data).then(function(response) {
        this.close();
      });
    },
    toggle_provide_feedback() {
      this.provide_feedback = !this.provide_feedback
    },
    close() {
      this.toggle_provide_feedback();
      this.clear_testimonial()
      this.collect_testimonials();
      $('#collect_testimonial_modal .light_box_close').click();
    },
    clear_testimonial() {
      this.new_testimonial.title = ''
      this.new_testimonial.user_comments = ''
    }
  },
  computed: {
    charactersLeft() {
      var char = 0
      var limit = this.maxlength;
      if (this.new_testimonial.user_comments != undefined) {
        char = this.new_testimonial.user_comments.length
      }
      return (limit - char) + " of " + limit;
    }
  }
});

Vue.component('collect-user-testimonial', {
  template: `<div class="modal" id="collect_user_testimonial_modal" role="dialog" v-if="user_feedback">
                <div class="modal-xl">
                  <div class="modal-content user_account_setting collect_testimonial_first lxa_border_full">
                    <div class="mb-4">
                      <span class="lxa_text_color_05 lxa_font_size_08 lxa_light_font"> {{$t('testimonial.learner_heading')}} </span>
                    </div>
                    <p class="light_box_close" data-dismiss="modal"> <i class="fad fa-times lxa_text_color_08 lxa_font_size_06 cursor_pointer"></i></p>

                    <div class="col-12 px-0">
                      <div class="lxa_font_size_04">
                        <label class="lxa_font_size_04 lxa_text_color_02 lxa_light_font"> Name </label>
                        <input type="text" class="col-12 primary_bgcolor lxa_border_radius lxa_font_size_04 p-2 border-0" v-model="new_testimonial.title" required/>
                      </div>

                      <div class="lxa_font_size_04 mt-3">
                        <label class="lxa_font_size_04 lxa_text_color_02 lxa_light_font"> Description </label>
                        <span class="lxa_text_color_09 lxa_font_size_04 float_right">{{charactersLeft}}</span>
                        <textarea class="col-12 primary_bgcolor lxa_border_radius lxa_font_size_04 p-2 txtarea-resize border-0" v-model="new_testimonial.user_comments" :maxlength="maxlength" required> </textarea>
                      </div>

                      <div class="lxa_font_size_04 mt-4 float_right">
                        <span class="btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" @click="cancel_testimonial">Cancel</span>
                        <span class="btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase ml-3" @click="submit_testimonial">Submit</span>
                      </div>
                    </div>
                  </div>
                </div>
            </div>`,
  props: {
    user_feedback: Boolean
  },
  data() {
    return {
      new_testimonial: Object,
      maxlength: 500
    }
  },
  mounted() {
    this.$http.get('/fetch_testimonial_data.json').then(function(response) {
      this.new_testimonial = response.body.new_testimonial
    });
  },
  methods: {
    cancel_testimonial() {
      this.$modal.show('dialog', {
        text: 'The Testimonial that you provided will be deleted.',
        buttons: [{
            title: 'Cancel',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Yes',
            handler: () => {
              this.close()
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    },
    submit_testimonial() {
      if (this.new_testimonial.title === '' || this.new_testimonial.title === null) {
        this.$root.popUpMessage("Please Provide Name");
      } else if (this.new_testimonial.user_comments === '' || this.new_testimonial.user_comments === null) {
        this.$root.popUpMessage("Please Provide Comments");
      } else {
        var data = {...this.new_testimonial}
        this.$http.post('/submit_testimonial', data).then(function(response) {
          this.$root.popUpMessage("Successfully Submitted");
          EventBus.$emit('updateUserTestimonials')
          this.close();
        });
      }
    },
    close() {
      $('#collect_user_testimonial_modal .light_box_close').click();
      this.clear_testimonial()
    },
    clear_testimonial() {
      this.new_testimonial.title = ''
      this.new_testimonial.user_comments = ''
    }
  },
  computed: {
    charactersLeft() {
      var char = 0
      var limit = this.maxlength;
      if (this.new_testimonial.user_comments != undefined) {
        char = this.new_testimonial.user_comments.length
      }
      return (limit - char) + " of " + limit;
    }
  }
})

Vue.component('edit-collected-testimonial-modal', {
  template: `<div>
              <edit-collected-testimonial v-for="item in announcements" :testimonial_id="item.testimonial_id" v-if="item.testimonial_id != null"> </edit-collected-testimonial>
            </div>`,
  data() {
    return {
      announcements: []
    }
  },
  mounted() {
    this.$http.get('/my_learnings/announcements.json').then(response => {
      this.announcements = response.body.announcements
    });
  }
})

Vue.component('edit-collected-testimonial', {
  template: `<div class="modal" :id="'edit_collected_testimonial_modal_'+testimonial_id" role="dialog" v-if="user_testimonial">
              <div class="modal-xl">
                <div class="modal-content user_account_setting collect_testimonial_first">
                  <div class="mb-4">
                    <span class="lxa_text_color_05 lxa_font_size_08 lxa_light_font"> {{$t('testimonial.admin_review_heading')}} </span>
                  </div>
                  <p class="light_box_close" data-dismiss="modal"> <i class="fad fa-times lxa_text_color_08 lxa_font_size_06 cursor_pointer"></i></p>

                  <div class="col-12 px-0">
                    <div class="lxa_font_size_04">
                      <label class="lxa_font_size_04 lxa_text_color_02 lxa_light_font"> Name </label>
                      <input type="text" class="col-12 primary_bgcolor lxa_border_radius lxa_font_size_04 p-2 border-0" v-model="user_testimonial.title" required/>
                    </div>

                    <div class="lxa_font_size_04 mt-3">
                      <label class="lxa_font_size_04 lxa_text_color_02 lxa_light_font"> Description </label>
                      <span class="lxa_text_color_09 lxa_font_size_04 float_right">{{charactersLeft}}</span>
                      <textarea class="admin_user_comments col-12 primary_bgcolor lxa_border_radius lxa_font_size_04 p-2 txtarea-resize" v-model="user_testimonial.user_comments" :maxlength="maxlength" required> </textarea>
                    </div>

                    <div class="lxa_font_size_04 mt-4 float_right">
                      <span class="btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" @click.prevent="publish_testimonial(user_testimonial, false)">UNPUBLISH</span>
                      <span class="btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase ml-3" @click.prevent="publish_testimonial(user_testimonial, true)">PUBLISH</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  `,
  props: {
    testimonial_id: Number
  },
  data() {
    return {
      user_testimonial: Object,
      maxlength: 500
    }
  },
  mounted() {
    this.$http.get('/fetch_testimonial/'+this.testimonial_id).then(response => {
      this.user_testimonial = response.body.user_testimonial
    });
    tinymce.init({
      selector: 'textarea.admin_user_comments',
      height: 250,
      menubar: false,
      insertdatetime_formats: ["%H:%M:%S", "%Y-%m-%d", "%I:%M:%S %p", "%D"],
      charmap_append: [
        [0x2600, 'sun'],
        [0x2601, 'cloud']
      ],
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount'
      ],
      toolbar1: 'undo redo | bold italic underline backcolor charmap' +
      ' alignleft aligncenter alignright alignjustify | bullist numlist outdent indent ' ,
      content_css: '//www.tiny.cloud/css/codepen.min.css'
    });  
  },
  methods: {
    publish_testimonial(testimonial, published) {
      testimonial.user_comments = tinymce.activeEditor.getContent({format: 'text'});
      testimonial.published = published
      testimonial.active = true
      var data = {...testimonial}
      this.$http.post('/publish_testimonial', data).then(function(response) {
        var message = published ? 'Successfully Published' : 'Unpublished'
        this.$root.popUpMessage(message);
        EventBus.$emit("updateTestimonialsAdmin");
        EventBus.$emit("updateTestimonials");
        this.close()
      });
    },
    close() {
      $('.light_box_close').click();
    }
  },
  computed: {
    charactersLeft() {
      var char = 0
      var limit = this.maxlength;
      if (this.user_testimonial.user_comments != undefined) {
        char = this.user_testimonial.user_comments.length
      }
      return (limit - char) + " of " + limit;
    }
  }
})