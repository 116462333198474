<template>
	<div class="answer_container hotspot_answer">
    <div class="hotspot_container" v-bind:id="'attachment_preview_question_'+question_id" style="color:#666;-moz-user-select: none;-webkit-user-select: none;user-select: none; display: inline-block;">
      <img alt="Action_link_and_button_wrapping" v-bind:src="hotspot_img">
    </div>
    <input v-if="!have_response" v-bind:id="'responses_hotspot_'+question_id+'_'" v-bind:name="'responses[hotspot]['+question_id+'][]'" type="hidden" v-bind:value=response_answer_text>
  </div>
</template>

<script>
	export default {
		name: 'HotspotType',
		props: {
			item: Array,
			question_id: Number,
			hotspot_img: Array,
			response: Array,
			have_response: Boolean,
			navigate_response: Boolean
		},
		data: function() {
			return{
				response_answer_text: this.navigate_response || this.have_response ? this.response[0].answer_text : ''
			}
		},
		mounted: function() {
			if(!this.have_response) {
				var json = null;
				try {
					json = JSON.parse(this.response_answer_text);
				} catch(err) {
					json = {'left': 0, 'top' : 0};
				}
				jQuery("#attachment_preview_question_"+this.question_id).hotspot_answer({'selectionPoint' : json});
			}
		}
	}
</script>