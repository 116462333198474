import Vue from 'vue/dist/vue.esm'
import Multiselect from 'vue-multiselect'
import VuePoll from 'vue-poll'
import moment from 'moment'
const EventBus = new Vue();

Vue.component('blog-post-list',{
  template:`
    <div class="container-fluid" v-if="show_blog_items">
      <div v-show="loading" class="text-center">
        <i class="fad fa-spinner fa-spin lxa_font_size_11 text-danger"></i>
      </div>
      <div class="row" v-if="!loading"> 
        <div class="col-sm-12 mt-5" v-if="showPost">
          <div class="lxa_font_size_08 d-flex align-items-start justify-content-start">
            <span class="blog_header cursor_pointer lxa_text_color_06 lxa_light_font" @click="toggleShowPost()"> Blog </span> 
            <span class="mx-3"><i class="fad fa-angle-right lxa_text_color_06"></i> </span>
            <span class="blog_header lxa_text_color_01 lxa_blog_crs_tittle"> {{ active_post.title }} </span>
          </div>
          <blog-post-show :post="active_post" :published_posts_rating="published_posts_rating" :published_rated_by_user="published_rated_by_user" :site_admin="site_admin"> </blog-post-show>
        </div>
        <div id="posts" class="col-sm-12" v-else>
          <div class="row mx-0">
            <div class="col-12 px-0">
              <div class="d-flex align-items-center justify-content-start">
                <span class="blog_header lxa_text_color_06 lxa_font_size_08 lxa_light_font"> Blog </span>
                <span class="add_blog btn lxa_bg_04 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase cursor_pointer ml-auto" data-toggle="modal" data-target="#new_blog_post_modal" data-backdrop="static" data-keyboard="false" v-if="site_admin">
                  <i class="fad fa-plus"></i><span class="ml-2">CREATE BLOG</span> 
                </span>
              </div>
            </div>
          </div>
          <div class="row mx-0 blog_search_filter_sort">
            <div class="col-sm-12 col-md-6 col-lg-6 p-0 blog_menu lxa_menu_container d-flex align-items-center justify-content-start">
              <span v-for="menu in menu_items" :class="[{'active menu_active': menu == active_menu},'cursor_pointer menu']" @click="changeTab(menu)">
                {{menu}}
              </span>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 px-0 lxa-set-height">
              <div class="d-flex align-items-start justify-content-end position-relative">
                <div class="mr-3 d-flex align-items-start justify-content-start position-relative">
                  <input class="blog_search_area lxa_main_search lxa_border_radius_tlbl lxa_padding_l lxa_search_txt_color lxa_font_size_03 search-bar search-bar-open lxa_search_txt_color" type="text" placeholder="Search" v-model="search_blog"> </input>
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary search_box_submit px-2 lxa_border_radius_trbr lxa_padding_lr" @click="searchBlog()">
                     <i class="fad fa-search lxa_search_icon blog_search_icon"></i>
                    </button>
                  </div>
                </div>
                <div class="blog_filters filters d-flex align-items-start justify-content-start position-relative">
                  <div :class='[{"active_tab": show_filter},"filter_area lxa_filter_sort_ui lxa_bg_05 mr-3"]' @click="showFilters"> <i class="fad fa-sliders-h lxa_filter_icon lxa_font_size_04"></i> <span class="ml-2 text-uppercase lxa_font_size_03">Filters</span> </div>
                  <div :class='[{"active_tab": show_sort},"sort_area lxa_filter_sort_ui lxa_bg_05"]' @click="showSortingOptions"> <span class="mr-1 lxa_font_size_03 text-uppercase"> Sort </span> <i class="fad fa-chevron-down lxa_sortby_icon lxa_font_size_04"></i> </div>
                </div>
                <div v-if="show_filter" class="w-100 filters_option row m-0">
                  <div id="category-filter-container" class="col-4 text_align_left">
                    <div class="filter_heading"> Category </div>
                    <div class="search_filter">
                      <input type="text" v-model="search_text" placeholder="Search">
                      <span> <i class="fa fa-search fa-1x" aria-hidden="true"></i> </span>
                    </div>
                    <div class="category_filter_body">
                      <div v-for='item in filteredCategories'>
                        <div class='learnexa-custom-checkbox'>
                          <input class='delivery' type='checkbox' :id="filter_unique_id + '_' + item.id" :value='item.id' v-model='selected_filter_category'>
                          <label :for="filter_unique_id + '_' + Object.values(item)[0]">{{ item.name }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="duration-filter-container" class="col-4 text_align_left">
                    <div class="filter_heading"> {{$t('filter.duration')}} </div>
                    <div class="duartion-filter-body">
                      <div v-for='item,index in duration_types'>
                        <div class='learnexa-custom-checkbox'>
                          <input class='duration' type='checkbox' :id="filter_unique_id + '_' + item" :value='index' v-model='selectedDurationType'>
                          <label :for="filter_unique_id + '_' + item">{{item}}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="delivery-type-container" class="col-4 text_align_left">
                    <div class="filter_heading"> Author </div>
                    <div class="search_filter">
                      <input type="text" v-model="search_user" placeholder="Search">
                      <span> <i class="fa fa-search fa-1x" aria-hidden="true"></i> </span>
                    </div>
                    <div class="category_filter_body">
                      <div v-for='item in filteredUser'>
                        <div class='learnexa-custom-checkbox'>
                          <input class='delivery' type='checkbox' :id="filter_unique_id + '_user_' + item.id" :value='item.id' v-model='selected_filter_author'>
                          <label :for="filter_unique_id + '_user_' + Object.values(item)[0]">{{ item.firstname }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 px-0">
                    <div class="filter_footer float_right">
                      <div @click="clearFilter" class="lxa_font_size_04 btn text-uppercase lxa_btn_txt_02">Clear</div>
                      <div class="_apply btn lxa_bg_01 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" @click="requestData">Apply</div>
                    </div>
                  </div>
                </div>
                <div class='sort_option' id="blogsortId" v-if="show_sort">
                  <ul class="sorting_list mb-0">
                    <li v-for="item in sorting_option" :id="Object.keys(item)[0]" @click="selected_sort" :class="[{'active': sort_blog == Object.keys(item)[0] }]">
                      {{ Object.values(item)[0] }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row mx-0">
            <div v-if="active_menu == 'Category'" class="col-12 px-0 blog-tab-content w-100 mt-3">
              <div class="mb-3" v-for="(category, name, index) in category_names">
                <span class="lxa_fontfamily lxa_font_size_05 lxa_text_color_01 lxa_bold_font">{{ name}} </span>
                <div class="margin_top_15 lxa_fontfamily lxa_font_size_03 lxa_medium_font">
                  <p class="mb-2 cursor_pointer" v-for="cg_name in category" @click="chooseCategory(cg_name[1])"> {{ cg_name[0] }} </p>
                </div>
              </div>
            </div>
            <div v-else class="col-12 px-0 _margin_top_30">
              <span class="mt-2" v-if="selected_category.length > 0">
                <span class="applied-filter align-self-center lxp-text-primary lxp-fontfamily-primary px-2 py-1 mr-2 mb-2" v-for="sc in selected_category" @click="clearCategory(sc)" > {{ getCategoryName(sc) }} <i data-toggle="tooltip" data-placement="top" title="Clear Category" class="fad fa-times"></i> </span>
              </span>
              <span class="mt-2" v-if="selected_author.length > 0">
                <span class="applied-filter align-self-center lxp-text-primary lxp-fontfamily-primary px-2 py-1 mr-2 mb-2" v-for="sc in selected_author" @click="clearAuthor(sc)"> {{ getAuthorName(sc) }} <i data-toggle="tooltip" data-placement="top" title="Clear Author" class="fad fa-times"></i> </span>
              </span>
              <span class="mt-2" v-if="selected_duration.length > 0">
                <span class="applied-filter align-self-center lxp-text-primary lxp-fontfamily-primary px-2 py-1 mr-2 mb-2" v-for="sc in selected_duration" @click="clearDuration(sc)"> {{ getDurationName(sc) }} <i data-toggle="tooltip" data-placement="top" title="Clear Duration" class="fad fa-times"></i> </span>
              </span>
              <span class="mt-2" v-if="searched_key != ''">
                <span class="cursor_pointer p-2 lxa_fontfamily lxa_font_size_04 lxa_light_font lxa_bg_05" @click="clearSearch()"> {{ searched_key }} <i class="fad fa-times" data-toggle="tooltip" data-placement="top" title="Clear Search"></i> </span>
              </span>
              <div class="blog-tab-content" v-if="active_posts.length != 0">
                <div class="lxa_fontfamily lxa_font_size_04 lxa_light_font position-relative" v-for="post in active_posts" :key="post.id">
                  <blog-post :post="post" :color="colors[Math.floor(Math.random() * colors.length)]" :site_admin="site_admin"> </blog-post>
                </div>
              </div>
              <div v-else>
                <div class="font_barlow_semi_condensed_light_14"> None </div>
              </div>
              </div>
              
            </div>
          </div>
          <div class="col-12 margin_top_30" v-if="showLoadMore">
            <div class="col-12 text-center">
              <a class="btn lxa_bg_01 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04" role="button" @click="seeMore"> LOAD MORE </a>
            </div>
          </div>
          <new-blog-post :all_categories="available_categories" :all_tags="available_tags" v-if="available_categories.length != 0"> </new-blog-post>
        </div>
      </div
    </div>`,
  components: {
     Multiselect,
     VuePoll
  },
  data () {
    var sorting_option = [
      {'created_at desc' : 'Newly Listed'},
      {'title asc' : 'Title A-z'},
      {'title desc' : 'Title Z-a'},
    ]
    return {
      show_blog_items: false,
      show_new_blog: false,
      newBlogCreation: false,
      post_title: '',
      posts: [],
      shared: [],
      unpublished: [],
      published_posts_rating: [],
      shared_posts_rating: [],
      unpublished_posts_rating: [],
      published_rated_by_user: [],
      shared_rated_by_user: [],
      unpublished_rated_by_user: [],
      new_tag: [],
      new_category: '',
      available_tags: [],
      available_categories: [],
      current_id: 0,
      menu_items: ['Top Rated','Most Popular', 'Category', 'All'],
      active_menu: 'All',
      active_posts: [],
      top_rated_posts: [],
      most_popular_posts: [],
      all_posts: [],
      colors: ['#e64235', '#f77e29', '#3c88c2'],
      category_names: [],
      selected_category: [],
      selected_filter_category: [],
      selected_filter_author: [],
      show_filter: false,
      show_sort: false,
      search_blog: '',
      searched_key: '',
      sort_blog: 'created_at desc',
      sorting_option: sorting_option,
      search_text: '',
      filter_unique_id: 'blog',
      duration_types: this.$t('filter.duration_types'),
      selectedDurationType: [],
      available_users: [],
      search_user: '',
      selected_author: [],
      showPost: false,
      active_post: {},
      current_page: 1,
      tab_post_count: {},
      showLoadMore: false,
      selected_duration: [],
      loading: true
    }
  },
  props: {
    site_admin: Boolean,
    logged_in: Boolean
  },
  mounted: function(){
    EventBus.$on('canSeeBlog', this.$root.show_blog_items);
    this.show_blog_items = this.$root.show_blog_items
    this.load_posts();
  },
  computed: {
    filteredCategories(){
      return this.available_categories.filter((ctg) => { 
        return Object.values(ctg)[1].toLowerCase().includes(this.search_text.toLowerCase())
      });
    },
    filteredUser(){
      return this.available_users.filter((ctg) => { 
        return ctg.firstname.toLowerCase().includes(this.search_user.toLowerCase())
      });
    }
  },
  methods: {
    seeMore() {
      this.current_page = this.current_page + 1
      var params = '?&selectedCategory='+this.selected_category+'&search='+this.search_blog+'&sort='+this.sort_blog+'&selectedAuthor='+this.selected_author+'&page=' +this.current_page+'&duration='+this.selected_duration
      this.$http.get('/posts.json'+params).then(response => {
        this.top_rated_posts = this.top_rated_posts.concat(response.body.top_rated_posts)
        this.most_popular_posts = this.most_popular_posts.concat(response.body.most_popular_posts)
        this.all_posts = this.all_posts.concat(response.body.all_posts)
        this.changeTab(this.active_menu)
      });
    },
    toggleShowPost() {
      this.showPost = false
      this.showLoadMore = true
    },
    clearFilter() {
      this.selected_filter_category = []
      this.selected_filter_author = []
      this.selectedDurationType = []
    },
    requestData() {
      this.selected_category = this.selected_filter_category
      this.selected_author = this.selected_filter_author
      this.selected_duration = this.selectedDurationType
      this.show_filter = false
      this.show_sort = false
      this.load_posts()
    },
    getCategoryName(sc) {
      var category = this.available_categories.find(c => c.id == sc);
      return category.name
    },
    getAuthorName(sc) {
      var user = this.available_users.find(c => c.id == sc);
      return user.firstname
    },
    getDurationName(sc) {
      return this.duration_types[sc]
    },
    addCategory: function(val) {
      this.selected_category.push(val);
    },
    addAuthor: function(val) {
      this.selected_author.push(val);
    },
    load_posts(menu = this.active_menu) {
      var params = '?&selectedCategory='+this.selected_category+'&search='+this.search_blog+'&sort='+this.sort_blog+'&selectedAuthor='+this.selected_author+'&page=' +this.current_page+'&duration='+this.selected_duration
      this.$http.get('/posts.json'+params).then(response => {
        this.newBlogCreation = response.body.loggedAsAdmin;
        this.posts = response.body.published_posts;
        this.shared = response.body.shared_posts;
        this.unpublished = response.body.unpublished_posts;
        this.published_posts_rating = response.body.published_posts_rating;
        this.shared_posts_rating = response.body.shared_posts_rating;
        this.unpublished_posts_rating = response.body.unpublished_posts_rating;
        this.published_rated_by_user = response.body.published_rated_by_user;
        this.shared_rated_by_user = response.body.shared_rated_by_user;
        this.unpublished_rated_by_user = response.body.unpublished_rated_by_user;
        this.available_categories = response.body.available_categories;
        this.available_users = response.body.available_users;
        this.available_tags = response.body.available_tags;
        this.current_company = response.body.current_account_name;
        this.current_id = response.body.current_user_id;
        this.top_rated_posts = response.body.top_rated_posts
        this.most_popular_posts = response.body.most_popular_posts
        this.category_names = response.body.category_names
        this.all_posts = response.body.all_posts
        this.tab_post_count = response.body.tab_post_count
        this.loading = false;
        this.changeTab(menu)
      });
    },
    blogListUpdate: function() {
      this.$http.get('/posts.json?').then(response => {
        this.posts = response.body.published_posts;
        this.shared = response.body.shared_posts;
        this.unpublished = response.body.unpublished_posts;
      });
    },
    changeTab(menu) {
      this.active_menu = menu
      if (this.active_menu == this.menu_items[0]) {
        this.active_posts = this.top_rated_posts  
        if (this.tab_post_count['top_rated_posts_count']) {
          this.showLoadMore = true
        }
      } else if (this.active_menu == this.menu_items[1]) {
        this.active_posts = this.most_popular_posts
        if (this.tab_post_count['most_popular_posts_count']) {
          this.showLoadMore = true
        }
      } else if (this.active_menu == this.menu_items[2]) {
        this.active_posts = []
      } else {
        this.active_posts = this.all_posts
        if (this.tab_post_count['all_posts_count']) {
          this.showLoadMore = true
        }
      }
    },
    chooseCategory(name) {
      this.selected_category.push(name)
      this.load_posts(this.menu_items[3])
    },
    clearCategory(sc) {
      var sc_index = this.selected_category.indexOf(sc);
      if (sc_index > -1) {
        this.selected_category.splice(sc_index, 1);
      }
      this.load_posts()
    },
    clearAuthor(sc) {
      var sc_index = this.selected_author.indexOf(sc);
      if (sc_index > -1) {
        this.selected_author.splice(sc_index, 1);
      }
      this.load_posts()
    },
    clearDuration(sc) {
      var sc_index = this.selected_duration.indexOf(sc);
      if (sc_index > -1) {
        this.selected_duration.splice(sc_index, 1);
      }
      this.load_posts()
    },
    showFilters() {
      this.show_filter = !this.show_filter
      if (this.show_sort) {
        this.show_sort = !this.show_sort
      }
    },
    showSortingOptions() {
      this.show_sort = !this.show_sort
      if (this.show_filter) {
        this.show_filter = !this.show_filter  
      }
    },
    searchBlog() {
      this.searched_key = this.search_blog
      this.load_posts()
    },
    clearSearch() {
      this.search_blog = ''
      this.searchBlog()
    },
    selected_sort(e) {
      this.show_sort = false
      this.sort_blog = e.target.id
      this.load_posts()
    }
  }
});


Vue.component('new-blog-post', {
  template: `<div class="modal" id="new_blog_post_modal" role="dialog">
              <div class="modal-xl lxa_create_edit_blog_content">
                <div class="modal-content user_filebox primary_bgcolor">
                  <div class="lxa_create_blog_modal_header">
                    <span class="blog_header lxa_text_color_06 lxa_font_size_08 lxa_light_font"> Create Blog </span>
                  </div>
                  <p class="light_box_close" data-dismiss="modal"> <i class="fad fa-times lxa_text_color_08 lxa_font_size_06 cursor_pointer"></i></p>
                  <div class="margin_top_15px lxa_font_size_03">
                    <div class="row m-0">
                      <span class="create_blog_inner_text lxa_text_color_02 lxa_fontfamily lxa_font_size_04 lxa_light_font mb-2">Title</span>
                      <input id="blogTitle" class="w-100 height_40px blog_input_text lxa_bg_05 p-2 lxa_border_radius border-0 lxa_font_size_04 lxa_light_font" type="text" v-model="formposts.title"></input>
                    </div>
                    <div class="row m-0">
                      <span class="create_blog_inner_text lxa_text_color_02 lxa_fontfamily lxa_font_size_04 lxa_light_font mb-2 mt-3">Description</span>
                      <textarea class="post_textarea lxa_bg_05 lxa_border_radius border-0 lxa_font_size_04 lxa_light_font txtarea-resize" v-model="formposts.content"> </textarea>
                    </div>
                  </div>
                  <div class="margin_top_15px">
                    <div class="row mx-0 mb-2">
                      <span class="col-3 pl-0 create_blog_inner_text lxa_text_color_02 lxa_fontfamily lxa_font_size_04 lxa_light_font">Publish</span>
                      <span class="col-3 pl-0 create_blog_inner_text lxa_text_color_02 lxa_fontfamily lxa_font_size_04 lxa_light_font">Poll</span>
                      <span class="col-3 pl-0 create_blog_inner_text lxa_text_color_02 lxa_fontfamily lxa_font_size_04 lxa_light_font">Begin</span>
                      <span class="col-3 px-0 create_blog_inner_text lxa_text_color_02 lxa_fontfamily lxa_font_size_04 lxa_light_font">End <span class="end_datetime_text lxa_font_size_02 lxa_text_color_03 mb-2">(Leave empty to never end)</span> </span>
                    </div>
                    <div class="row mx-0 mb-2">
                      <span class="col-3 pl-0">
                        <select @change="PublishDropdown" class="publish_and_poll_dropdown blog_input_text w-100 height_40px lxa_bg_05 p-2 lxa_border_radius border-0 lxa_font_size_04 lxa_light_font">
                          <option value="true">Yes</option>
                        </select>
                      </span>
                      <span class="col-3 pl-0">
                        <select @change="PollDropdown" class="publish_and_poll_dropdown blog_input_text w-100 height_40px lxa_bg_05 p-2 lxa_border_radius border-0 lxa_font_size_04 lxa_light_font">
                          <option name="post_show_poll" value="false">No</option>
                        </select>
                      </span>
                      <span class="col-3 pl-0 blog_date_section d-flex align-items-center justify-content-start">
                        <input id="BlogStartDate" class="w-75 height_40px cursor_pointer blog_input_text  lxa_bg_05 p-2 lxa_border_radius border-0 lxa_font_size_04 lxa_light_font" v-model="formposts.display_in_catalog_start_date">
                        <input id="BlogStartTime" class="w-25 height_40px cursor_pointer ml-2 blog_input_text  lxa_bg_05 p-2 lxa_border_radius border-0 lxa_font_size_04 lxa_light_font" v-model="formposts.display_in_catalog_start_time">
                      </span>
                      <span class="col-3 px-0 blog_date_section d-flex align-items-center justify-content-start">
                        <input id="BlogEndDate" class="w-75 height_40px cursor_pointer blog_input_text  lxa_bg_05 p-2 lxa_border_radius border-0 lxa_font_size_04 lxa_light_font" v-model="formposts.display_in_catalog_end_date">
                        <input id="BlogEndTime" class="w-25 height_40px cursor_pointer ml-2 blog_input_text  lxa_bg_05 p-2 lxa_border_radius border-0 lxa_font_size_04 lxa_light_font" v-model="formposts.display_in_catalog_end_time">
                      </span>
                    </div>
                    <div id="pollShowHide" v-if="formposts.show_poll" class="margin_bottom_10">
                      <div class="row m-0">
                        <span class="create_blog_inner_text lxa_text_color_02 lxa_fontfamily lxa_font_size_04 lxa_light_font mb-2">Poll Question</span>
                        <input id="poll_question" type ="text" v-model ="formposts.question" class="w-100 height_40px blog_input_text p-2 border-0 lxa_border_radius">
                      </div>
                      <div class="row m-0 mt-3 poll_question_answers">
                        <div class="col-12 p-0 mt-2">
                          <span class="create_blog_inner_text lxa_text_color_02 lxa_fontfamily lxa_font_size_04 lxa_light_font mb-2">Choice 1</span>
                          <input id="Choice_1" type ="text" v-model ="formposts.choices[0]" class="w-100 height_40px blog_input_text mt-2 lxa_bg_05 p-2 lxa_border_radius border-0 lxa_font_size_04 lxa_light_font">
                        </div>
                        <div class="col-12 p-0 mt-2">
                          <span class="create_blog_inner_text lxa_text_color_02 lxa_fontfamily lxa_font_size_04 lxa_light_font mb-2">Choice 2</span>
                          <input id="Choice_2" type ="text" v-model ="formposts.choices[1]" class="w-100 height_40px blog_input_text mt-2 lxa_bg_05 p-2 lxa_border_radius border-0 lxa_font_size_04 lxa_light_font">
                        </div>
                        <div class="col-12 p-0 mt-2">
                          <span class="create_blog_inner_text lxa_text_color_02 lxa_fontfamily lxa_font_size_04 lxa_light_font mb-2">Choice 3</span>
                          <input id="Choice_3" type ="text" v-model ="formposts.choices[2]" class="w-100 height_40px blog_input_text mt-2 lxa_bg_05 p-2 lxa_border_radius border-0 lxa_font_size_04 lxa_light_font">
                        </div>
                        <div class="col-12 p-0 mt-2">
                          <span class="create_blog_inner_text lxa_text_color_02 lxa_fontfamily lxa_font_size_04 lxa_light_font mb-2">Choice 4</span>
                          <input id="Choice_4" type ="text" v-model ="formposts.choices[3]" class="w-100 height_40px blog_input_text mt-2 lxa_bg_05 p-2 lxa_border_radius border-0 lxa_font_size_04 lxa_light_font">
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 my-3">
                      <div class="col-3 pl-0">
                        <span class="create_blog_inner_text lxa_text_color_02 lxa_fontfamily lxa_font_size_04 lxa_light_font">Duration</span>
                        <input id="BlogDuration" class="height_40px cursor_pointer blog_input_text w-100 lxa_bg_05 p-2 lxa_border_radius border-0 lxa_font_size_04 lxa_light_font mt-2" v-model="formposts.duration">
                      </div>
                      <div class="col-6 pl-0">
                        <span class="create_blog_inner_text lxa_text_color_02 lxa_fontfamily lxa_font_size_04 lxa_light_font">Categories</span>
                        <multiselect v-model="formposts.category_list" tag-placeholder="Add this as new category" placeholder="Select Category" label="name" track-by="id" :options="available_categories" :multiple="true" :taggable="true" @tag="addCategory" class="mt-2"></multiselect>
                      </div>
                      <div class="columns col-3 px-0" id="group_logo_field">
                        <span class="create_blog_inner_text lxa_text_color_02 lxa_fontfamily lxa_font_size_04 lxa_light_font">Upload Blog Image</span>
                        <div v-if="file_url" class="d-flex"><img v-bind:src="file_url" class="upload_link lxa_text_color_01 lxa_font_size_03 lxa_regular_font text-uppercase d-flex align-items-center justify-content-start"></img><span v-on:click="changeBlogImage()" class="mt-5 pt-2 pl-3 cursor_pointer"><i class="fad fa-trash-alt lxa_text_color_01 lxa_font_size_04"></i></span></div>
                        <div v-else class="d-flex">
                          <div class="upload_link lxa_bg_05 lxa_text_color_01 lxa_font_size_03 lxa_regular_font text-uppercase d-flex align-items-center justify-content-center" v-on:click="uploadBlogImageFile()">
                            Upload
                          </div>
                           <span class="mt-5 pt-2 pl-3 cursor_pointer"><i class="fad fa-trash-alt color_light_gray lxa_font_size_04"></i></span>
                        </div>
                        <input type='file' ref="files" accept="image/*" id="blog-upload" @change="attachBlogImageFile()" style="display: none"/>
                      </div>
                  </div>
                    </div>
                    <div class="row mx-0 mb-3">
                    <div class="col-12 px-0">
                      <span class="create_blog_inner_text lxa_text_color_02 lxa_fontfamily lxa_font_size_04 lxa_light_font">Tags</span>
                      <multiselect v-model="formposts.tag_list" class="mt-2" tag-placeholder="Add this as new tag" placeholder="Select Tag" label="name" track-by="id" :options="available_tags" :multiple="true" :taggable="true" @tag="addTag"></multiselect>
                    </div>
                    </div>
                    <div class="row m-0">
                      <div id="new_blog_post" class="create_blog_inner_text pb-0">
                      <div id="dis_viewcount_wrapper" class="dis_viewcount_wrapper custom-controls custom-checkbox">
                          <input v-model="formposts.display_sharing_tools" id="new_blog" name="new_blog" class="delivery custom-control-input lxa-bs-checkboxbtn" type="checkbox" value="create blog">
                          <label class="string_trunk8 custom-control-label" for="new_blog">
                            <i class="fad fa-stop lxa_icon_size lxa_text_color_05"></i>
                            <i class="fad fa-check-square lxa_icon_size lxa_text_color_05"></i>
                            <span class="dis_viewcount_label lxa_font_size_03 lxa_light_font ml-2">Display view counts,ratings,comments</span>
                          </label>
                        </div>
                        
                      </div>
                    </div>
                    <div class="row mx-0 margin_top_30 float-right">
                      <div class=""><button class="button blog_cancel_button btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase cursor_pointer mr-3" @click.prevent ="closeEditor()">Cancel</button></div>
                      <div><button class="button blog_create_button btn lxa_bg_04 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase cursor_pointer" @click.prevent ="createNewPost()">Create</button></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>`,
  data() {
    return {
      show_new_blog: false,
      newBlogCreation: false,
      post_title: '',
      posts: [],
      shared: [],
      unpublished: [],
      published_posts_rating: [],
      shared_posts_rating: [],
      unpublished_posts_rating: [],
      published_rated_by_user: [],
      shared_rated_by_user: [],
      unpublished_rated_by_user: [],
      formposts: { title: '', content: '' , is_publish: false, show_poll: false, question: '',choices: [], display_in_catalog_start_date: '', display_in_catalog_start_time: '', display_in_catalog_end_date: '', display_in_catalog_end_time: '', user_id: 0, display_sharing_tools: true, category_list: [],tag_list: [], duration: ''},
      new_tag: [],
      new_category: '',
      available_tags: [],
      available_categories: [],
      current_id: 0,
      file: '',
      file_url: ''
    }
  },
  components: {
     Multiselect,
  },
  props: {
    all_categories: Array,
    all_tags: Array,
  },
  mounted() {
    this.openNewEditor()
    var _this = this;
    this.available_categories = this.all_categories
    this.available_tags = this.all_tags
    EventBus.$on('clone-blog-post', function(clone_post) {
      _this.file_url= clone_post.image_url
      _this.formposts.title = clone_post.title
      _this.formposts.content = clone_post.post
      _this.formposts.user_id = clone_post.user_id
      _this.formposts.display_in_catalog_start_date = moment(new Date(clone_post.display_in_catalog_start_date)).utc().format('MMM DD, yyyy')
      _this.formposts.display_in_catalog_start_time = moment(new Date(clone_post.display_in_catalog_start_date)).utc().format('HH:mm')
      if (clone_post.display_in_catalog_end_date == null) {
        _this.formposts.display_in_catalog_end_date = ''
        _this.formposts.display_in_catalog_end_time = ''
      }
      _this.formposts.display_in_catalog_end_date = moment(new Date(clone_post.display_in_catalog_end_date)).utc().format('MMM DD, yyyy')
      _this.formposts.display_in_catalog_end_time = moment(new Date(clone_post.display_in_catalog_end_date)).utc().format('HH:mm')
      _this.formposts.category_list = clone_post.category_list;
      _this.formposts.tag_list = clone_post.tag_list;
      _this.formposts.show_poll = clone_post.show_poll;
      _this.formposts.question = clone_post.question;
      _this.formposts.choices = clone_post.poll_choices;
      _this.available_categories = clone_post.available_categories
      _this.available_tags = clone_post.available_tags
      _this.current_id = clone_post.current_user_id
    });
  },
  methods: {
    attachBlogImageFile() {
      this.file = this.$refs.files.files[0];
      this.file_url = URL.createObjectURL(this.file);
    },
    uploadBlogImageFile: function() {    
      document.getElementById('blog-upload').click();
    },
    changeBlogImage() {
      this.file = '';
      this.file_url = '';
    },
    PublishDropdown(publishValue) {
      this.formposts.is_publish = publishValue.target.value;
    },
    PollDropdown(pollValue) {
      this.formposts.show_poll = pollValue.target.value;
      if (this.formposts.show_poll == "true") {
        jQuery("#pollShowHide").toggle();
      }
      else {
        jQuery("#pollShowHide").hide();
      }
    },
    newPostOutside: function(e) {
      if(jQuery(e.target).closest('#newPost').length < 1) {
        this.show_new_blog = false;
      }
    },
    openNewEditor(){
      this.show_new_blog = true;
      DateTimePicker('BlogStartDate','M d, Y',true,false);
      DateTimePicker('BlogEndDate','M d, Y',true,false);
      DateTimePicker('BlogStartTime','H:i',false,true);
      DateTimePicker('BlogEndTime','H:i',false,true);
      DateTimePicker('BlogDuration','H:i',false,true);
      this.formposts = { title: '', content: '' , is_publish: true, show_poll: false, question: '',choices: [], display_in_catalog_start_date: Date.now().toString("MMM dd, yyyy"), display_in_catalog_start_time: Date.now().toString("HH:mm"), display_in_catalog_end_date: '', display_in_catalog_end_time: '', user_id: 0, display_sharing_tools: true, category_list: [],tag_list: [], duration: ''}
      jQuery("#new_post").toggle();
      tinymce.init({
      selector: '.post_textarea',
      width: '100%',
      height: '400px',
      menubar: false,
      insertdatetime_formats: ["%H:%M:%S", "%Y-%m-%d", "%I:%M:%S %p", "%D"],
      charmap_append: [
      [0x2600, 'sun'],
      [0x2601, 'cloud']
      ],
      plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help wordcount'
      ],
      toolbar1: 'undo redo | bold italic underline backcolor charmap' +
      ' alignleft aligncenter alignright alignjustify | bullist numlist outdent indent ' ,
      content_css: '//www.tiny.cloud/css/codepen.min.css'
      });
    },
    addTag(newTag) {
        const tag = {
        id: '',
        name: newTag,
        company_id: ''
      }
      this.$http.post('/tags.json?'+'&tag_name='+newTag).then(function(response) {
        this.formposts.tag_list.push(response.body.tag_data)
      });
    },
    addCategory(newCategory) {
      const category = {
        id: '',
        name: newCategory,
        company_id: ''
      }
      this.$http.post('/tags.json?'+'&category_name='+newCategory).then(function(response) {
        this.formposts.category_list.push(response.body.category_data)
      });
    },
    closeEditor(postId){
      jQuery("#new_blog_post_modal .light_box_close").click();
    },
    createNewPost(){
      this.formposts.display_in_catalog_start_date = moment(new Date(jQuery('#BlogStartDate').val())).format('YYYY-MM-DD');
      this.formposts.display_in_catalog_start_time = jQuery('#BlogStartTime').val();
      var message = tinymce.activeEditor.getContent();
      this.formposts.content = message;
      this.formposts.user_id = this.current_id;
      if (jQuery("#blogTitle").val().length > 0 && jQuery("#BlogStartDate").val().length > 0 && jQuery("#BlogStartTime").val().length > 0) {
        if ($('input[type=radio][name=post_show_poll]:checked').val()) {
          var pollQuestion = jQuery("#poll_question").val().length > 0
          var pollChoice1 = jQuery("#Choice_1").val().length > 0
          var pollChoice2 = jQuery("#Choice_2").val().length > 0
          var pollChoice3 = jQuery("#Choice_3").val().length > 0
          var pollChoice4 = jQuery("#Choice_4").val().length > 0
          var postPollingData = pollQuestion && pollChoice1 && pollChoice2 && pollChoice3 && pollChoice4
          if (postPollingData) {
            this.postBlog()
          }
        }
        else {
          this.postBlog()
        }
        this.closeEditor()
      }
    },
    postBlog() {
      let formData = new FormData();
      formData.append('post[image]', this.file);
      formData.append('post[title]', this.formposts.title)
      formData.append('post[post]', this.formposts.content)
      formData.append('post[raw_post]', this.formposts.content)
      formData.append('post[user_id]', this.$parent.current_id)
      formData.append('post[is_publish]', this.formposts.is_publish)
      formData.append('post[hidden]', this.formposts.hidden)
      formData.append('post[show_poll]', this.formposts.show_poll)
      formData.append('post[display_in_catalog_start_date]', this.formposts.display_in_catalog_start_date + ' ' + this.formposts.display_in_catalog_start_time)
      formData.append('post[display_in_catalog_end_date]', this.formposts.display_in_catalog_end_date + ' ' + this.formposts.display_in_catalog_end_time)
      formData.append('post[display_sharing_tools]', this.formposts.display_sharing_tools)
      formData.append('post[duration]', this.formposts.duration)
      formData.append('tag_list', JSON.stringify(this.formposts.tag_list))
      formData.append('category_list', JSON.stringify(this.formposts.category_list))
      if (this.formposts.show_poll) {
        formData.append('post[question]', this.formposts.question)
        formData.append('post[choices]', this.formposts.choices)
      }
      if (this.formposts.title != '' && this.formposts.content != '') {
        this.$http.post('/posts', formData).then(function(response) {
          if(response.data.status == 'failed'){
            this.$root.popUpMessage(response.data.message);
          }
          else{
            this.$root.popUpMessage("Created Posts");
            this.$parent.all_posts.unshift(response.data.post)
            this.formposts = { title: '', content: '' , is_publish: false, show_poll: false, question: '',choices: [], display_in_catalog_start_date: '', display_in_catalog_start_time: '', display_in_catalog_end_date: '', display_in_catalog_end_time: '', user_id: 0, display_sharing_tools: true, category_list: [],tag_list: [], duration: '', file: '', file_url: ''}
            this.changeBlogImage();
            tinymce.activeEditor.setContent('');          
          }
        });
      } else {
        this.$root.popUpMessage("Please provide valid content ");
      }
    }
  }
})

Vue.component('blog-post', {
  template:`
    <div>
      <div v-bind:id="'blog_post_'+post.id" class="mt-3 all_blog_posts well  lxa_border_radius overflow-hidden position-relative" style="height: 380px;">
        <div class="blog_post h-100 cursor_pointer" v-on:mouseover="show_full_details = true" v-on:mouseleave="show_full_details = false">
          <div v-if="!show_full_details" class="w-100 h-100">
            <img :src="post.image_url" class="object_fit_cover w-100 h-100">
          </div>
          <div class="blog_details_wrapper position-absolute" :class="!show_full_details? 'lxa_bg_05 h-50':'h-100'">
          <div class="row mx-0 blog_details p-3 w-100" @click="showPost(post.id)">
            <span class="col-12 px-0 lxa_fontfamily lxa_font_size_03 lxa_medium_font lxa_post_title"> {{post.title}} </span>
            <span class="col-12 px-0 lxa_fontfamily lxa_font_size_02 lxa_medium_font"> {{post.category}} </span>
            <span class="col-12 px-0 lxa_fontfamily lxa_font_size_02 lxa_medium_font"> {{post.created_at}}</span>
            <span class="col-12 px-0 lxa_fontfamily lxa_font_size_02 lxa_medium_font" v-if="show_full_details">{{post.created_by}}</span>
            <div v-show="show_full_details" v-bind:id="'blog_desc_hover_'+post.id" class="col-12 px-0 margin_bottom_30 post_description lxa_fontfamily lxa_font_size_03 lxa_light_font">
              <span class="col-12 px-0"> {{post.post}} </span>
            </div>
            <div v-show="!show_full_details" v-bind:id="'blog_desc_'+post.id" class="col-12 pt-0 pl-3 mb-3 post_description lxa_fontfamily lxa_font_size_03 lxa_light_font">
            <span class="col-12 px-0"> {{post.post}} </span>
          </div>
          <span class="col-12 p-3 p-0 blog_info lxa_fontfamily lxa_font_size_03 lxa_light_font position-absolute mb-3 w-100 lxa_blog_grid_footer" :style="{color: color}" v-if="!show_full_details">
            <span v-if="post.view_count != 0"> {{ post.view_count}} Views </span>
            <span v-if="post.shared_count != 0" class="pl-2"> {{ post.shared_count}} Shares </span>
          </span>
          </div>
          
          <div class="row mx-0 lxa_fontfamily lxa_font_size_04 lxa_light_font blog_details position-absolute mb-3 w-100 lxa_blog_grid_footer">
            <div class="col-12 d-flex justify-content-start align-items-center" v-if="show_full_details" style="z-index: 10">
              <!-- <span class="pr-3 cursor_pointer"><a data-toggle="modal" :data-target="'#rating_blog_post_modal_'+post.id" data-backdrop="static" data-keyboard="false"><i class="fad fa-thumbs-up lxa_text_color_04"></i> Recommend </a> </span> -->
              <span class="blog_info cursor_pointer" @click="share_clicked = true"><a data-toggle="modal" :data-target="'#share_blog_post_modal_'+post.id" data-backdrop="static" data-keyboard="false"><i class="fad fa-share-alt"></i></a> </span>
              <span class="blog_info ml-auto" v-if="site_admin">
                <span class="pl-2 cursor_pointer"> <a @click="clonePost(post.id)" data-toggle="tooltip" data-placement="top" title="Clone" ><i class="fad fa-clone"></i> </a></span>
                <span class="pl-2 cursor_pointer" @click="edit_clicked = true"><a data-toggle="modal" :data-target="'#edit_blog_post_modal_'+post.id" data-backdrop="static" data-keyboard="false" ><i data-toggle="tooltip" data-placement="top" title="Edit" class="fad fa-pencil-alt"></i></a> </span>
                <span class="pl-2 cursor_pointer"><a @click="deletePost(post.id)"><i data-toggle="tooltip" data-placement="top" title="Delete" class="fad fa-trash-alt"></i></a></span>
              </span>
            </div>
          </div>
         </div>
        </div>
        <blog-share-invite :post="post" :share_clicked="share_clicked"></blog-share-invite>
        <blog-edit :post="post" :edit_clicked="edit_clicked"></blog-edit>
      </div>
    </div>`,
  components: {
     Multiselect
  },
  props: {
    post: Object,
    published_posts_rating: Number,
    published_rated_by_user: Boolean,
    color: String,
    site_admin: Boolean
  },
  data: function() {
    return {
      post_user_rating: this.published_posts_rating,
      post_user_rated: this.published_rated_by_user,
      poll_voted: '',
      show_hide_display_sharing_tools: '',
      show_rating_popup: false,
      show_comment_popup: false,
      ratings_id: "ratings_" + new Date().getTime(),
      user_rating: 0,
      show_display: '',
      show_share_post: false,
      selected_blog_editor: false,
      share_post: false,
      show_polling: false,
      selectedBlogPost: false,
      formedit: { title: '', content: '' , is_publish: false, show_poll: false, question: '',choices: [], user_id: 0, display_sharing_tools: false, category_list: [],tag_list: []},
      poll_data:{
        question: '',
        answers: [
          { value: 1, text: '', votes: 0 },
          { value: 2, text: '', votes: 0 },
          { value: 3, text: '', votes: 0 },
          { value: 4, text: '', votes: 0 }
        ]
      },
      show_blog_edit: false,
      current_post_author: '',
      post_created_at: moment(this.post.created_at).format('LL LT'),
      formshare:{by_user_id: 0, recommendable_id: 0, recommendable_type: '', message: '', to_user_id: 0, to_user_email: ''},
      emailed_count: 0,
      views_count: 0,
      show_full_details: false,
      edit_clicked: false,
      share_clicked: false
    }
  },
  watch: {
    edit_clicked() {
      this.getPostDetails()
      tinymce.init({
        selector: '.post_textarea',
        width: '100%',
        height: '400px',
        menubar: false,
        insertdatetime_formats: ["%H:%M:%S", "%Y-%m-%d", "%I:%M:%S %p", "%D"],
        charmap_append: [
        [0x2600, 'sun'],
        [0x2601, 'cloud']
        ],
        plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount'
        ],
        toolbar1: 'undo redo | bold italic underline backcolor charmap' +
        ' alignleft aligncenter alignright alignjustify | bullist numlist outdent indent ' ,
        content_css: '//www.tiny.cloud/css/codepen.min.css'
      });
      },
    show_full_details() {
      if (this.show_full_details) {
        this.$nextTick(() => {            
          ellipsis('#blog_desc_hover_'+this.post.id, 14);
        });
      } else {
        this.$nextTick(() => {            
          ellipsis('#blog_desc_'+this.post.id, 3);
        });
      }
    }
  },
  mounted: function(){
    EventBus.$on('item-blog-post', (post_items, post_sharing_tools) => {
      this.$parent.posts = post_items;
      this.show_hide_display_sharing_tools = post_sharing_tools;
    });
    ellipsis('.lxa_post_title', 2);
    ellipsis('#blog_desc_'+this.post.id, 3);
    ellipsis('#blog_desc_hover_'+this.post.id, 5);
    EventBus.$on('blog-rating-response', (data) => { this.ratingBlogPost(); });
    EventBus.$on('comment-rating-response', (data) => { this.ratingBlogPost(); });
    jQuery('#'+this.ratings_id).rating({
      theme: 'krajee-uni',
      filledStar: '&#x2605;',
      emptyStar: '&#x2606;',
      showClear: false,
      showCaption: false,
      step: 1
    });
    $(".rating-container .empty-stars, .rating-container .filled-stars").css("color", "#4184f3");
  },
  methods: {
    getPostDetails() {
      this.$http.get('/posts/'+this.post.id+'.json').then(response => {
        this.views_count = response.body.views_count;
        this.emailed_count = response.body.emailed_count;
        this.curr_user_id = response.body.curr_user_id;
        this.current_post_author = response.body.current_post_author;
        this.show_polling = response.body.show_polling;
        if (this.show_polling) {
          this.poll_voted = response.body.poll_voted;
          this.post_poll = response.body.post_poll;
          if (this.post_poll) {
            this.poll_data.question = this.post_poll.question;
          }
          this.poll_choices = response.body.poll_choices;
          this.percent_of_votes = response.body.percent_of_votes;
          if (this.poll_choices != null) {
            this.poll_choices.forEach((value, index) => {
              this.poll_data.answers[index].votes = value.votes_count;
              this.poll_data.answers[index].text = value.description;
            });
          }
        }
      });
    },
    clonePost(post_id) {
      // this.$parent.clonePost(post_id)
      jQuery('.add_blog').click()
      var clone_post = this.post
      clone_post['show_poll'] = this.show_polling
      clone_post['question'] = this.poll_data.question
      clone_post['poll_choices'] = this.poll_data.answers.map(ans => ans.text)
      EventBus.$emit('clone-blog-post', clone_post)
    },
    ratingBlogPost: function() {
      // this.$http.get('/posts/'+this.post.id+'.json').then(function(response) {
      //   this.post_user_rating = response.body.average_rating;
      //   $('#'+this.ratings_id).rating('update', this.post_user_rating);
      // });
    },
    showRatingPopup(postId){
      this.show_rating_popup = true;
    },
    showCommentPopup(postId){
      this.show_comment_popup = true;
    },
    ratingPopupOutsideClick: function(e) {
      if(jQuery(e.target).closest("#ratingPopup").length < 1) {
        this.show_rating_popup = false;
      }
    },
    commentPopupOutsideClick: function(e) {
      if(jQuery(e.target).closest("#commentPopup").length < 1) {
        this.show_comment_popup = false;
      }
    },
    showhideFromDisplay: function(postId) {
      this.$http.get('/posts/'+postId+'/toggle_visibility.json').then(function(response) {
        this.show_display = response.body.show_display;
      });
    },
    pollPercentWidth(polling_width) {
      return polling_width
    },
    savePollData(){
      var currentUserId = this.$parent.curr_user_id;
      var postPollId = document.getElementById($('input[type=radio][name=poll_data_choice]:checked').attr('id')).value;
      var ChoiceId = document.getElementById($('input[type=radio][name=poll_data_choice]:checked').attr('id')).id;
      this.$http.post('/votes.json', { vote: { user_id: currentUserId, poll_id: postPollId, choice_id: ChoiceId } }).then(function(response) {
        this.poll_voted = true;
        this.percent_of_votes = response.body.poll_vote_percent;
        this.poll_choices = response.body.poll_choices;
        this.poll_choices.forEach((value, index) => {
          this.poll_data.answers[index].votes = value.votes_count;
          this.poll_data.answers[index].text = value.description;
        });
      });
    },
    showPost(postId){
      this.$parent.showPost = true
      this.$parent.showLoadMore = false
      this.$parent.active_post = this.post
      jQuery(".all_blog_posts.well").css({'display':'none'});
      this.selectedBlogPost = true;
      this.$parent.newBlogCreation = false;
      this.show_polling = false;
    },
    blogPostList: function() {
      jQuery(".all_blog_posts.well").css({'display':'block'});
      this.selectedBlogPost = false;
      this.$parent.newBlogCreation = true;
      this.$http.get('/posts.json?').then(response => {
        this.$parent.posts = response.body.published_posts;
      });
    },
    showShareDetails(postId){
      this.formshare = {by_user_id: 0, recommendable_id: 0, recommendable_type: '', message: 'I thought you might be interested in this item.', to_user_id: 0, to_user_email: '', share_post: ''}
      this.show_share_post = true;
    },
    sharePopupOutsideClick: function(e) {
      if(jQuery(e.target).closest("#new_recommendation").length < 1) {
        this.show_share_post = false;
      }
    },
    blogOutsideClick: function(e) {
      if(jQuery(e.target).closest("#blogEditorId").length < 1) {
        this.show_blog_edit = false;
      }
    },
    openEditor(postId) {
      this.show_blog_edit = true;
    },
    selectedBlogOutsideClick: function(e) {
      if(jQuery(e.target).closest("#blogEditorId").length < 1) {
        this.selected_blog_editor = false;
      }
    },
    openselectedBlogEditor(postId) {
      this.selected_blog_editor = true;
    },
    blogPostItems: function() {
      jQuery(".well").css({'display':'block'});
      this.$http.get('/posts.json?').then(response => {
        this.$parent.posts = response.body.published_posts;
        this.$parent.newBlogCreation = true;
      });
    },
    unPublishPost(postId){
      this.$http.put('/posts/'+postId +'.json', { post: { is_publish: false, title: this.post.title, post: this.post.post, raw_post: this.post.raw_post, user_id: this.post.user_id, show_poll: this.post.show_poll, question: this.post.question, display_sharing_tools: this.post.display_sharing_tools, category_list: this.post.category_list, tag_list: this.post.tag_list } }).then(response =>{
        this.$parent.posts = response.body.published_posts;
        if(response.body.errors){
          this.display_error_message(response.body.errors);
        }
      });
    },
    deletePost(postId){
      this.$modal.show('dialog', {
        text: 'Are you sure you want to delete this item?',
        buttons: [{
            title: 'Cancel',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Yes',
            handler: () => {
              var _this = this
              this.$http.delete('/posts/'+postId +'.json').then(response =>{
                _this.$parent.load_posts()
                this.$root.popUpMessage("Deleted Successfully");
              });
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    }
  }
});

Vue.component('blog-post-show', {
  template: `<div>
              <div class="blog_detail_and_polling lxa_mt_30 lxa_border_radius lxa_bg_05 p-3">
                <span class="add_blog " data-toggle="modal" data-target="#new_blog_post_modal" data-backdrop="static" data-keyboard="false" style="display: none;"> <i class="fad fa-plus"></i> CREATE BLOG </span>
                <div class="row mx-0">
                  <div class="col-2 px-0">
                    <img :src="post.image_url" width="100%" height="100%" class="lxa_border_radius img-fluid">
                  </div>
                  <div class="col-10 pr-0 d-flex flex-column align-items-start justify-content-start">
                    <div class="row mx-0 mb-auto">
                      <div class="col-12 px-0"><span class="lxa_fontfamily lxa_font_size_05 lxa_medium_font">{{post.title}}</span> </div>
                      <div class="col-12 px-0"><span class="lxa_fontfamily lxa_font_size_05 lxa_light_font">{{post.created_by}}, {{post.created_at}}</span> </div>
                    </div>
                    <div class="mt-5 w-100 lxa_font_size_04 lxa_light_font d-flex align-items-center justify-content-start">
                      <span class="d-flex align-items-center justify-content-start lxa_text_color_07">
                        <span class="mr-3"> {{ post.view_count }} Views </span>
                        <span> {{ post.shared_count }} Shares </span>
                      </span>
                      <span class="ml-auto">
                        <span class="pr-3 cursor_pointer" @click="share_clicked = true"><a data-toggle="modal" :data-target="'#share_blog_post_modal_'+post.id" data-backdrop="static" data-keyboard="false"><i class="fad fa-share-alt lxa_text_color_05"></i> Share </a> </span>
                        <span v-if="site_admin">
                          <span class="pr-3 cursor_pointer"><a @click="clonePost()"><i class="fad fa-clone lxa_text_color_06"></i> Clone </a></span>
                          <span class="pr-3 cursor_pointer" @click="edit_clicked = true"><a data-toggle="modal" :data-target="'#edit_blog_post_modal_'+post.id" data-backdrop="static" data-keyboard="false" > <i class="fad fa-pencil-alt lxa_text_color_07"></i> Edit </a></span>
                          <span class="cursor_pointer" @click="deletePost(post.id)"><i class="fad fa-trash-alt lxa_text_color_01"></i> Delete </span>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="lxa_common_border_b1 my-3 w-100"></div>
                <div class="row m-0 line_break_auto lxa_fontfamily lxa_font_size_04  lxa_light_font mt-3 ">
                  <div class="col-12 p-0 post_description" v-html="post.post_raw"></div>
                </div>
                <div v-if="show_polling" class="row m-0 margin_top_15 poll_border_box">
                  <div class="col-sm-12 text_font_color margin_top_15px">
                    <p class="lxa_fontfamily lxa_font_size_05 lxa_medium_font">Poll</p>
                    <p class="lxa_fontfamily lxa_font_size_04 lxa_light_font">{{poll_data.question}}</p>
                  </div>
                  <div v-if="poll_voted" class="margin_top_10 lxa_fontfamily lxa_font_size_04  lxa_light_font col-12 p-0">
                    <div v-for="(poll_data_answer, index) in poll_data.answers" class="col-12 polling_details">
                      <div class="row m-0 col-12 p-0">
                        <div class="col-5">
                          {{poll_data_answer.text}}
                        </div>
                        <div class="col-5">
                          <div class="polling_choice_container">
                            <div class="bar" :style="{'width': pollPercentWidth(percent_of_votes[index]) + '%', 'background-color': '#0066b3', 'height': '50%', 'border-radius': '10px'}">
                              <p class="visibility_hidden">{{poll_data_answer.text}}</p>
                            </div>
                          </div>
                        </div>
                        <div class="col-2 text_align_center">
                          <p>{{percent_of_votes[index]}}%</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div v-for="(poll_data_answer, index) in poll_data.answers" class="col-12 margin_bottom_10 font_barlow_semi_condensed_light_16">
                      <input type="radio" v-bind:id="poll_choices[index].id" v-bind:value="poll_choices[index].poll_id" name="poll_data_choice"> {{poll_data_answer.text}}
                    </div>
                    <button class="orange button polling_submit" v-on:click="savePollData">Submit</button>
                  </div>
                </div>
              </div>
              <div class="blog_detail_and_polling lxa_mt_30 lxa_border_radius lxa_bg_05 p-3 lxa_fontfamily lxa_font_size_03 lxa_light_font">
                <blog-write-comment :post="post" :rated_by_user="post_user_rated" :user_rating="post_user_rating"></blog-write-comment>
              </div>
              <blog-edit :post="post" :edit_clicked="edit_clicked"></blog-edit>
              <blog-share-invite :post="post" :share_clicked="share_clicked"></blog-share-invite>
              <blog-rating-popup :post="post" :rated_by_user="published_rated_by_user" :user_rating="published_posts_rating"></blog-rating-popup>
              <new-blog-post :all_categories="post.available_categories" :all_tags="post.available_tags"> </new-blog-post>
            </div>`,
  data() {
    return {
      post_user_rating: this.published_posts_rating,
      post_user_rated: this.published_rated_by_user,
      show_polling: false,
      views_count: 0,
      emailed_count: 0,
      poll_voted: '',
      post_poll: '',
      poll_data:{
        question: '',
        answers: [
          { value: 1, text: '', votes: 0 },
          { value: 2, text: '', votes: 0 },
          { value: 3, text: '', votes: 0 },
          { value: 4, text: '', votes: 0 }
        ]
      },
      current_post_author: '',
      post_created_at: moment(this.post.created_at).format('LL LT'),
      poll_choices: '',
      show_share_post: false,
      show_rating_popup: false,
      curr_user_id: 0,
      edit_clicked: false,
      share_clicked: false
    }
  },
  props: {
    post: Object,
    published_posts_rating: Array, 
    published_rated_by_user: Array,
    site_admin: Boolean
  },
  mounted() {
    this.$http.get('/posts/'+this.post.id+'.json').then(response => {
      this.views_count = response.body.views_count;
      this.emailed_count = response.body.emailed_count;
      this.curr_user_id = response.body.curr_user_id;
      this.current_post_author = response.body.current_post_author;
      this.show_polling = response.body.show_polling;
      if (this.show_polling) {
        this.poll_voted = response.body.poll_voted;
        this.post_poll = response.body.post_poll;
        if (this.post_poll) {
          this.poll_data.question = this.post_poll.question;
        }
        this.poll_choices = response.body.poll_choices;
        this.percent_of_votes = response.body.percent_of_votes;
        if (this.poll_choices != null) {
          this.poll_choices.forEach((value, index) => {
            this.poll_data.answers[index].votes = value.votes_count;
            this.poll_data.answers[index].text = value.description;
          });
        }
      }
    });
  },
  methods: {
    clonePost() {
      // this.$parent.clonePost(this.post.id)
      jQuery('.add_blog').click()
      var clone_post = this.post
      clone_post['show_poll'] = this.show_polling
      clone_post['question'] = this.poll_data.question
      clone_post['poll_choices'] = this.poll_data.answers.map(ans => ans.text)
      EventBus.$emit('clone-blog-post', clone_post)
    },
    toggleShowPost() {
      this.showPost = false
      this.showLoadMore = true
    },
    showShareDetails(){
      this.show_share_post = true;
    },
    showRatingDetails(){
      this.show_rating_popup = true;
    },
    blogPostList() {
      this.$parent.showPost = false
    },
    openselectedBlogEditor() {

    },
    selectedBlogOutsideClick() {

    },
    closeEditor() {

    },
    updatePost() {

    },
    deletePost(postId){
      this.$modal.show('dialog', {
        text: 'Are you sure you want to delete this item?',
        buttons: [{
            title: 'Cancel',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Yes',
            handler: () => {
              var _this = this
              this.$http.delete('/posts/'+postId +'.json').then(response =>{
                _this.$parent.toggleShowPost();
              });
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    },
    pollPercentWidth(polling_width) {
      return polling_width
    },
    savePollData(){
      var currentUserId = this.curr_user_id;
      var postPollId = document.getElementById($('input[type=radio][name=poll_data_choice]:checked').attr('id')).value;
      var ChoiceId = document.getElementById($('input[type=radio][name=poll_data_choice]:checked').attr('id')).id;
      this.$http.post('/votes.json', { vote: { user_id: currentUserId, poll_id: postPollId, choice_id: ChoiceId } }).then(function(response) {
        this.poll_voted = true;
        this.percent_of_votes = response.body.poll_vote_percent;
        this.poll_choices = response.body.poll_choices;
        this.poll_choices.forEach((value, index) => {
          this.poll_data.answers[index].votes = value.votes_count;
          this.poll_data.answers[index].text = value.description;
        });
      });
    }
  }
});

Vue.component('shared-post', {
  template:`
    <div>
      <div v-bind:id="'blog_post_'+post.id" class = "all_blog_posts well">
        <div class="row">
          <div class="col-sm-3 margin_top_10">
            <i class="fad fa-user-alt margin_left_35" style="width: 84px; height: 84px; color:blue; cursor: pointer" viewBox="100 0 512 512"></i>
          </div>
          <div class ="col-sm-6">
            <div v-if="show_share_post">
              <blog-share-invite :post="post"></blog-share-invite>
            </div>
            <div v-if="show_rating_popup">
              <blog-rating-popup :post="post" :rated_by_user="shared_rated_by_user" :user_rating="shared_posts_rating"></blog-rating-popup>
            </div>
            <div v-if="show_comment_popup">
              <blog-comment-popup :post="post" :rated_by_user="shared_rated_by_user" :user_rating="shared_posts_rating"></blog-comment-popup>
            </div>
            <p><a @click="showPost(post.id)" class="blogTitle">{{post.title}}</a></p>
            <input disabled="disabled" v-bind:id="ratings_id" type="text" class="kv-uni-star rating-loading" :value="shared_user_rating" data-size="xs" title="">
            <a v-click-outside="sharePopupOutsideClick" @click="showShareDetails(post.id)"><i class="fas fa-share-alt" style="color: #1AC867"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
            <a v-click-outside="blogOutsideClick" @click="openEditor(post.id)"><i class="fas fa-pencil-alt" style="color: #31CADF"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
            <a><blog-bookmark :post="post"></blog-bookmark>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
            <a @click="deletePost(post.id)"><i class="far fa-trash-alt" style="color: #E51434"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
            <span v-if="show_hide_display_sharing_tools">
              <a class="blog_item_text" v-click-outside="ratingPopupOutsideClick" @click="showRatingPopup(post.id)">Rating</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            <span v-if="show_hide_display_sharing_tools">
              <a class="blog_item_text" v-click-outside="commentPopupOutsideClick" @click="showCommentPopup(post.id)">Comment</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            <span v-if="post_is_publish"><a class="blog_item_text" @click="unPublishPost(post.id)">Unpublish</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span v-else><span class="blog_item_unpublished_text">Unpublished</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span v-if="show_display"><a class="blog_item_text" @click="showhideFromDisplay(post.id)">Hide from display</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span v-else><a class="blog_item_text" @click="showhideFromDisplay(post.id)">Display</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-offset-3 col-sm-4">
            <span>By&nbsp;{{current_post_author}}&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            <span>{{post_created_at}}&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            <span v-if="show_hide_display_sharing_tools">{{views_count}}&nbsp;&nbsp;View&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            <span>{{emailed_count}}&nbsp;&nbsp;Shares</span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 line_break_auto">
            <div v-html="post.post"></div>
          </div>
        </div>
      </div>
      <div v-if="show_blog_edit">
        <div v-bind:class="'tinyEditor_of_' + post.id">
          <div class="create_blog_lightbox">
            <blog-edit :post="post"></blog-edit>
          </div>
        </div>
      </div>
      <div v-if="selectedBlogPost">
        <div class="blog_detail_and_polling margin_top_30px">
          <p v-on:click="blogPostList" class="margin_left_15">Back</p>
          <div class="row">
            <div class="col-sm-3 margin_top_10">
              <i class="fad fa-user-alt margin_left_35" style="width: 84px; height: 84px; color:blue; cursor: pointer" viewBox="100 0 512 512"></i>
            </div>
            <div class ="col-sm-6">
              <div>
                <p><a class ="blogTitle">{{post.title}}</a></p>
                <a v-click-outside="selectedBlogOutsideClick" @click="openselectedBlogEditor(post.id)"><i class="fas fa-pencil-alt" style="color: #31CADF"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a @click = "deletePost(post.id)"><i class="far fa-trash-alt" style="color: #E51434"></i></a>
                <div v-bind:class="'tinyEditor_of_' + post.id" style = "display:none">
                  <div class="balloon_popup">
                    <form>
                      <label>Title</label>
                      <input type="text" v-model="formedit.title"><p>{{post.title}}</p>
                      <textarea v-model="formedit.content">{{ post.content }}</textarea>
                      <a class="cancel_link_container "@click.prevent ="closeEditor(post.id)">Cancel</a>
                      <button class= "orange button" @click.prevent ="updatePost(post.id)">Done</button>
                    </form>
                  </div>
                </div>
              </div>
              <div>
                <span>By&nbsp;{{current_post_author}}&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                <span>{{post_created_at}}&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                <span v-if="show_hide_display_sharing_tools">{{views_count}}&nbsp;&nbsp;View&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                <span>{{emailed_count}}&nbsp;&nbsp;Shares</span>
              </div>
            </div>
          </div>
          <div class="row line_break_auto">
            <div v-html="post.post"></div>
          </div>
          <div class="row poll_border_box">
            <div class="col-sm-12 text_font_color margin_top_15px">
              <p class="font_weight_bold">Poll</p>
              <p>{{poll_data.question}}</p>
            </div>
            <div v-if="poll_voted" class="margin_top_10">
              <div v-for="(poll_data_answer, index) in poll_data.answers" class="col-sm-12 polling_details">
                <div class="row margin_left_0">
                  <div class="col-sm-4">
                    {{poll_data_answer.text}}
                  </div>
                  <div class="col-sm-4">
                    <div class="polling_choice_container">
                      <div class="bar" :style="{'width': pollPercentWidth(percent_of_votes[index]) + '%', 'background-color': '#0066b3', 'height': '50%', 'border-radius': '10px'}">
                        <p class="visibility_hidden">{{poll_data_answer.text}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <p>{{percent_of_votes[index]}}&nbsp;%</p>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div v-for="(poll_data_answer, index) in poll_data.answers" class="col-sm-12 margin_bottom_10">
                <input type="radio" v-bind:id="poll_choices[index].id" v-bind:value="poll_choices[index].poll_id" name="poll_data_choice"> {{poll_data_answer.text}}
              </div>
              <button class="orange button polling_submit" v-on:click="savePollData">Submit</button>
            </div>
          </div>
        </div>
        </br>
        <blog-write-comment :post="post" :rated_by_user="shared_rated_by_user" :user_rating="shared_posts_rating"></blog-write-comment>
        <div v-if="selected_blog_editor">
          <div v-bind:class="'tinyEditor_of_' + post.id">
            <div class="create_blog_lightbox">
              <blog-edit :post="post"></blog-edit>
            </div>
          </div>
        </div>
      </div>
    </div>`,
  components: {
     Multiselect
  },
  props: {
    post: Object,
    shared_posts_rating: Number,
    shared_rated_by_user: Boolean
  },
  data: function() {
    return {
      post_is_publish: this.post.is_publish,
      shared_user_rating: this.shared_posts_rating,
      poll_voted: '',
      show_hide_display_sharing_tools: '',
      show_rating_popup: false,
      show_comment_popup: false,
      ratings_id: "ratings_" + new Date().getTime(),
      user_rating: 0,
      show_display: '',
      show_share_post: false,
      selected_blog_editor: false,
      share_post: false,
      show_polling: false,
      selectedBlogPost: false,
      formedit: { title: '', content: '' , is_publish: false, show_poll: false, question: '',choices: [], user_id: 0, display_sharing_tools: false, category_list: [],tag_list: []},
      poll_data:{
        question: '',
        answers: [
          { value: 1, text: '', votes: 0 },
          { value: 2, text: '', votes: 0 },
          { value: 3, text: '', votes: 0 },
          { value: 4, text: '', votes: 0 }
        ]
      },
      show_blog_edit: false,
      current_post_author: '',
      post_created_at: moment(this.post.created_at).format('LL LT'),
      formshare:{by_user_id: 0, recommendable_id: 0, recommendable_type: '', message: '', to_user_id: 0, to_user_email: ''},
      emailed_count: 0,
      views_count: 0
    }
  },
  mounted: function(){
    EventBus.$on('shared-post-is-publish', (data) => { this.post_is_publish = data; });
    jQuery('#'+this.ratings_id).rating({
      theme: 'krajee-uni',
      filledStar: '&#x2605;',
      emptyStar: '&#x2606;',
      showClear: false,
      showCaption: false,
      step: 1
    });
    $(".rating-container .empty-stars, .rating-container .filled-stars").css("color", "#4184f3");
    EventBus.$on('item-shared-post', (shared_items, shared_sharing_tools) => {
      this.$parent.shared = shared_items;
      this.show_hide_display_sharing_tools = shared_sharing_tools;
    });
    EventBus.$on('blog-rating-response', (data) => { this.ratingBlogShared(); });
    EventBus.$on('comment-rating-response', (data) => { this.ratingBlogShared(); });
    this.$http.get('/posts/'+this.post.id+'.json?').then(response => {
      this.show_display = response.body.show_display;
      this.show_hide_display_sharing_tools = response.body.display_sharing_tools;
      this.views_count = response.body.views_count;
      this.emailed_count = response.body.emailed_count;
      this.current_post_author = response.body.current_post_author;
    });
  },
  methods: {
    ratingBlogShared: function() {
      this.$http.get('/posts/'+this.post.id+'.json').then(function(response) {
        this.shared_user_rating = response.body.average_rating;
        $('#'+this.ratings_id).rating('update', this.shared_user_rating);
      });
    },
    showRatingPopup(postId){
      this.show_rating_popup = true;
    },
    showCommentPopup(postId){
      this.show_comment_popup = true;
    },
    ratingPopupOutsideClick: function(e) {
      if(jQuery(e.target).closest("#ratingPopup").length < 1) {
        this.show_rating_popup = false;
      }
    },
    commentPopupOutsideClick: function(e) {
      if(jQuery(e.target).closest("#commentPopup").length < 1) {
        this.show_comment_popup = false;
      }
    },
    showhideFromDisplay: function(postId) {
      this.$http.get('/posts/'+postId+'/toggle_visibility.json').then(function(response) {
        this.show_display = response.body.show_display;
      });
    },
    pollPercentWidth(polling_width) {
      return polling_width
    },
    savePollData(){
      var currentUserId = this.$parent.curr_user_id;
      var postPollId = document.getElementById($('input[type=radio][name=poll_data_choice]:checked').attr('id')).value;
      var ChoiceId = document.getElementById($('input[type=radio][name=poll_data_choice]:checked').attr('id')).id;
      this.$http.post('/votes.json', { vote: { user_id: currentUserId, poll_id: postPollId, choice_id: ChoiceId } }).then(function(response) {
        this.poll_voted = true;
        this.percent_of_votes = response.body.poll_vote_percent;
        this.poll_choices = response.body.poll_choices;
        this.poll_choices.forEach((value, index) => {
          this.poll_data.answers[index].votes = value.votes_count;
          this.poll_data.answers[index].text = value.description;
        });
      });
    },
    showPost(postId){
      jQuery(".all_blog_posts.well").css({'display':'none'});
      this.selectedBlogPost = true;
      this.$parent.newBlogCreation = false;
      this.show_polling = false;
      this.$http.get('/posts/'+postId+'.json?'+'&selectedBlogPost='+this.selectedBlogPost).then(response => {
        this.views_count = response.body.views_count;
        this.emailed_count = response.body.emailed_count;
        this.$parent.curr_user_id = response.body.curr_user_id;
        this.show_polling = response.body.show_polling;
        if (this.show_polling) {
          this.poll_voted = response.body.poll_voted;
          this.post_poll = response.body.post_poll;
          this.poll_data.question = this.post_poll.question;
          this.poll_choices = response.body.poll_choices;
          this.percent_of_votes = response.body.percent_of_votes;
          this.poll_choices.forEach((value, index) => {
            this.poll_data.answers[index].votes = value.votes_count;
            this.poll_data.answers[index].text = value.description;
          });
        }
      });
    },
    blogPostList: function() {
      jQuery(".all_blog_posts.well").css({'display':'block'});
      this.selectedBlogPost = false;
      this.$parent.newBlogCreation = true;
      this.$http.get('/posts.json?').then(response => {
        this.$parent.shared = response.body.shared_posts;
      });
    },
    showShareDetails(postId){
      this.formshare = {by_user_id: 0, recommendable_id: 0, recommendable_type: '', message: 'I thought you might be interested in this item.', to_user_id: 0, to_user_email: '', share_post: ''}
      this.show_share_post = true;
    },
    sharePopupOutsideClick: function(e) {
      if(jQuery(e.target).closest("#new_recommendation").length < 1) {
        this.show_share_post = false;
      }
    },
    blogOutsideClick: function(e) {
      if(jQuery(e.target).closest("#blogEditorId").length < 1) {
        this.show_blog_edit = false;
      }
    },
    openEditor(postId) {
      this.show_blog_edit = true;
    },
    selectedBlogOutsideClick: function(e) {
      if(jQuery(e.target).closest("#blogEditorId").length < 1) {
        this.selected_blog_editor = false;
      }
    },
    openselectedBlogEditor(postId) {
      this.selected_blog_editor = true;
    },
    unPublishPost(postId){
      this.$http.put('/posts/'+postId +'.json', { post: { is_publish: false, title: this.post.title, post: this.post.post, raw_post: this.post.raw_post, user_id: this.post.user_id, show_poll: this.post.show_poll, question: this.post.question, display_sharing_tools: this.post.display_sharing_tools, category_list: this.post.category_list, tag_list: this.post.tag_list } }).then(response =>{
        this.post_is_publish = false;
        this.$parent.shared = response.body.shared_posts;
        if(response.body.errors){
          this.display_error_message(response.body.errors);
        }
      });
    },
    blogPostItems: function() {
      jQuery(".well").css({'display':'block'});
      this.$http.get('/posts.json?').then(response => {
        this.$parent.shared = response.body.shared_posts;
        this.$parent.newBlogCreation = true;
      });
    },
    deletePost(postId){
      if(confirm("Are you sure you want to delete this item?"))
        this.$http.delete('/posts/'+postId +'.json').then(response =>{
         this.blogPostItems();
       });
    }
  }
});

Vue.component('unpublished-post', {
  template:`
    <div>
      <div v-bind:id="'blog_post_'+post.id" class = "all_blog_posts well">
        <div class="row">
          <div class="col-sm-3 margin_top_10">
            <i class="fad fa-user-alt margin_left_35" style="width: 84px; height: 84px; color:blue; cursor: pointer" viewBox="100 0 512 512"></i>
          </div>
          <div class ="col-sm-6">
            <div v-if="show_rating_popup">
              <blog-rating-popup :post="post" :rated_by_user="unpublished_rated_by_user" :user_rating="unpublished_posts_rating"></blog-rating-popup>
            </div>
            <div v-if="show_comment_popup">
              <blog-comment-popup :post="post" :rated_by_user="unpublished_rated_by_user" :user_rating="unpublished_posts_rating"></blog-comment-popup>
            </div>
            <p><a @click="showPost(post.id)" class="blogTitle">{{post.title}}</a></p>
            <input disabled="disabled" v-bind:id="ratings_id" type="text" class="kv-uni-star rating-loading" :value="unpublished_user_rating" data-size="xs" title="">
            <a><blog-bookmark :post="post"></blog-bookmark>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
            <a @click="deletePost(post.id)"><i class="far fa-trash-alt" style="color: #E51434"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
            <span v-if="show_hide_display_sharing_tools">
              <a class="blog_item_text" v-click-outside="ratingPopupOutsideClick" @click="showRatingPopup(post.id)">Rating</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            <span v-if="show_hide_display_sharing_tools">
              <a class="blog_item_text" v-click-outside="commentPopupOutsideClick" @click="showCommentPopup(post.id)">Comment</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            <span><a class="blog_item_text" @click="publishPost(post.id)">Publish</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span v-if="show_display"><a class="blog_item_text" @click="showhideFromDisplay(post.id)">Hide from display</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span v-else><a class="blog_item_text" @click="showhideFromDisplay(post.id)">Display</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-offset-3 col-sm-4">
            <span>By&nbsp;{{current_post_author}}&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            <span>{{post_created_at}}&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            <span v-if="show_hide_display_sharing_tools">{{views_count}}&nbsp;&nbsp;View&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            <span>{{emailed_count}}&nbsp;&nbsp;Shares</span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 line_break_auto">
            <div v-html="post.post"></div>
          </div>
        </div>
      </div>
      <div v-if="show_blog_edit">
        <div v-bind:class="'tinyEditor_of_' + post.id">
          <div class="create_blog_lightbox">
            <blog-edit :post="post"></blog-edit>
          </div>
        </div>
      </div>
      <div v-if="selectedBlogPost">
        <div class="blog_detail_and_polling margin_top_30px">
          <p v-on:click="blogPostList" class="margin_left_15">Back</p>
          <div class="row">
            <div class="col-sm-3 margin_top_10">
              <i class="fad fa-user-alt margin_left_35" style="width: 84px; height: 84px; color:blue; cursor: pointer" viewBox="100 0 512 512"></i>
            </div>
            <div class ="col-sm-6">
              <div>
                <p><a class ="blogTitle">{{post.title}}</a></p>
                <a v-click-outside="selectedBlogOutsideClick" @click="openselectedBlogEditor(post.id)"><i class="fas fa-pencil-alt" style="color: #31CADF"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a @click = "deletePost(post.id)"><i class="far fa-trash-alt" style="color: #E51434"></i></a>
                <div v-bind:class="'tinyEditor_of_' + post.id" style = "display:none">
                  <div class="balloon_popup">
                    <form>
                      <label>Title</label>
                      <input type="text" v-model="formedit.title"><p>{{post.title}}</p>
                      <textarea v-model="formedit.content">{{ post.content }}</textarea>
                      <a class="cancel_link_container "@click.prevent ="closeEditor(post.id)">Cancel</a>
                      <button class= "orange button" @click.prevent ="updatePost(post.id)">Done</button>
                    </form>
                  </div>
                </div>
              </div>
              <div>
                <span>By&nbsp;{{current_post_author}}&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                <span>{{post_created_at}}&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                <span v-if="show_hide_display_sharing_tools">{{views_count}}&nbsp;&nbsp;View&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                <span>{{emailed_count}}&nbsp;&nbsp;Shares</span>
              </div>
            </div>
          </div>
          <div class="row line_break_auto">
            <div v-html="post.post"></div>
          </div>
          <div v-if="show_polling" class="row poll_border_box">
            <div class="col-sm-12 text_font_color margin_top_15px">
              <p class="font_weight_bold">Poll</p>
              <p>{{poll_data.question}}</p>
            </div>
            <div v-if="poll_voted" class="margin_top_10">
              <div v-for="(poll_data_answer, index) in poll_data.answers" class="col-sm-12 polling_details">
                <div class="row margin_left_0">
                  <div class="col-sm-4">
                    {{poll_data_answer.text}}
                  </div>
                  <div class="col-sm-4">
                    <div class="polling_choice_container">
                      <div class="bar" :style="{'width': pollPercentWidth(percent_of_votes[index]) + '%', 'background-color': '#0066b3', 'height': '50%', 'border-radius': '10px'}">
                        <p class="visibility_hidden">{{poll_data_answer.text}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <p>{{percent_of_votes[index]}}&nbsp;%</p>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div v-for="(poll_data_answer, index) in poll_data.answers" class="col-sm-12 margin_bottom_10">
                <input type="radio" v-bind:id="poll_choices[index].id" v-bind:value="poll_choices[index].poll_id" name="poll_data_choice"> {{poll_data_answer.text}}
              </div>
              <button class="orange button polling_submit" v-on:click="savePollData">Submit</button>
            </div>
          </div>
        </div>
        </br>
        <blog-write-comment :post="post" :rated_by_user="unpublished_rated_by_user" :user_rating="unpublished_posts_rating"></blog-write-comment>
        <div v-if="selected_blog_editor">
          <div v-bind:class="'tinyEditor_of_' + post.id">
            <div class="create_blog_lightbox">
              <blog-edit :post="post"></blog-edit>
            </div>
          </div>
        </div>
      </div>
    </div>`,
  components: {
     Multiselect
  },
  props: {
    post: Object,
    unpublished_posts_rating: Number,
    unpublished_rated_by_user: Boolean
  },
  data: function() {
    return {
      unpublished_user_rating: this.unpublished_posts_rating,
      poll_voted: '',
      show_hide_display_sharing_tools: '',
      show_rating_popup: false,
      show_comment_popup: false,
      ratings_id: "ratings_" + new Date().getTime(),
      show_display: '',
      selected_blog_editor: false,
      show_polling: false,
      selectedBlogPost: false,
      formedit: { title: '', content: '' , is_publish: false, show_poll: false, question: '',choices: [], user_id: 0, display_sharing_tools: false, category_list: [],tag_list: []},
      poll_data:{
        question: '',
        answers: [
          { value: 1, text: '', votes: 0 },
          { value: 2, text: '', votes: 0 },
          { value: 3, text: '', votes: 0 },
          { value: 4, text: '', votes: 0 }
        ]
      },
      show_blog_edit: false,
      current_post_author: '',
      post_created_at: moment(this.post.created_at).format('LL LT'),
      emailed_count: 0,
      views_count: 0
    }
  },
  mounted: function(){
    jQuery('#'+this.ratings_id).rating({
      theme: 'krajee-uni',
      filledStar: '&#x2605;',
      emptyStar: '&#x2606;',
      showClear: false,
      showCaption: false,
      step: 1
    });
    $(".rating-container .empty-stars, .rating-container .filled-stars").css("color", "#4184f3");
    EventBus.$on('item-unpublished-post', (unpublished_items, unpublished_sharing_tools) => {
      this.$parent.unpublished = unpublished_items;
      this.show_hide_display_sharing_tools = unpublished_sharing_tools;
    });
    EventBus.$on('blog-rating-response', (data) => { this.ratingBlogUnpublished(); });
    EventBus.$on('comment-rating-response', (data) => { this.ratingBlogUnpublished(); });
    this.$http.get('/posts/'+this.post.id+'.json?').then(response => {
      this.show_display = response.body.show_display;
      this.show_hide_display_sharing_tools = response.body.display_sharing_tools;
      this.views_count = response.body.views_count;
      this.emailed_count = response.body.emailed_count;
      this.current_post_author = response.body.current_post_author;
    });
  },
  methods: {
    ratingBlogUnpublished: function() {
      this.$http.get('/posts/'+this.post.id+'.json').then(function(response) {
        this.unpublished_user_rating = response.body.average_rating;
        $('#'+this.ratings_id).rating('update', this.unpublished_user_rating);
      });
    },
    showRatingPopup(postId){
      this.show_rating_popup = true;
    },
    showCommentPopup(postId){
      this.show_comment_popup = true;
    },
    ratingPopupOutsideClick: function(e) {
      if(jQuery(e.target).closest("#ratingPopup").length < 1) {
        this.show_rating_popup = false;
      }
    },
    commentPopupOutsideClick: function(e) {
      if(jQuery(e.target).closest("#commentPopup").length < 1) {
        this.show_comment_popup = false;
      }
    },
    showhideFromDisplay: function(postId) {
      this.$http.get('/posts/'+postId+'/toggle_visibility.json').then(function(response) {
        this.show_display = response.body.show_display;
      });
    },
    pollPercentWidth(polling_width) {
      return polling_width
    },
    savePollData(){
      var currentUserId = this.$parent.curr_user_id;
      var postPollId = document.getElementById($('input[type=radio][name=poll_data_choice]:checked').attr('id')).value;
      var ChoiceId = document.getElementById($('input[type=radio][name=poll_data_choice]:checked').attr('id')).id;
      this.$http.post('/votes.json', { vote: { user_id: currentUserId, poll_id: postPollId, choice_id: ChoiceId } }).then(function(response) {
        this.poll_voted = true;
        this.percent_of_votes = response.body.poll_vote_percent;
        this.poll_choices = response.body.poll_choices;
        this.poll_choices.forEach((value, index) => {
          this.poll_data.answers[index].votes = value.votes_count;
          this.poll_data.answers[index].text = value.description;
        });
      });
    },
    showPost(postId){
      jQuery(".all_blog_posts.well").css({'display':'none'});
      this.selectedBlogPost = true;
      this.$parent.newBlogCreation = false;
      this.show_polling = false;
      this.$http.get('/posts/'+postId+'.json?'+'&selectedBlogPost='+this.selectedBlogPost).then(response => {
        this.views_count = response.body.views_count;
        this.emailed_count = response.body.emailed_count;
        this.$parent.curr_user_id = response.body.curr_user_id;
        this.show_polling = response.body.show_polling;
        if (this.show_polling) {
          this.poll_voted = response.body.poll_voted;
          this.post_poll = response.body.post_poll;
          this.poll_data.question = this.post_poll.question;
          this.poll_choices = response.body.poll_choices;
          this.percent_of_votes = response.body.percent_of_votes;
          this.poll_choices.forEach((value, index) => {
            this.poll_data.answers[index].votes = value.votes_count;
            this.poll_data.answers[index].text = value.description;
          });
        }
      });
    },
    blogPostList: function() {
      jQuery(".all_blog_posts.well").css({'display':'block'});
      this.selectedBlogPost = false;
      this.$parent.newBlogCreation = true;
      this.$http.get('/posts.json?').then(response => {
        this.$parent.unpublished = response.body.unpublished_posts;
      });
    },
    selectedBlogOutsideClick: function(e) {
      if(jQuery(e.target).closest("#blogEditorId").length < 1) {
        this.selected_blog_editor = false;
      }
    },
    openselectedBlogEditor(postId) {
      this.selected_blog_editor = true;
    },
    publishPost(postId){
      this.$http.put('/posts/'+postId +'.json', { post: { is_publish: true, title: this.post.title, post: this.post.post, raw_post: this.post.raw_post, user_id: this.post.user_id, show_poll: this.post.show_poll, question: this.post.question, display_sharing_tools: this.post.display_sharing_tools, category_list: this.post.category_list, tag_list: this.post.tag_list } }).then(response =>{
        jQuery("#unpublished_post_"+postId).css({'display':'none'})
        EventBus.$emit('shared-post-is-publish', true);
        this.$parent.unpublished = response.body.unpublished_posts;
        if(response.body.errors){
          this.display_error_message(response.body.errors);
        }
      });
    },
    blogPostItems: function() {
      jQuery(".well").css({'display':'block'});
      this.$http.get('/posts.json?').then(response => {
        this.$parent.unpublished = response.body.unpublished_posts;
        this.$parent.newBlogCreation = true;
      });
    },
    deletePost(postId){
      if(confirm("Are you sure you want to delete this item?"))
        this.$http.delete('/posts/'+postId +'.json').then(response =>{
         this.blogPostItems();
       });
    }
  }
});

Vue.component('blog-rating-popup', {
  template:`<div class="modal" :id="'rating_blog_post_modal_'+post.id" role="dialog">
              <div class="modal-xl">
                <div class="modal-content user_filebox">
                  <p class="light_box_close" data-dismiss="modal"> <i data-toggle="tooltip" data-placement="top" title="Close" class="fad fa-times lxa_text_color_08 lxa_font_size_06 cursor_pointer"></i></p>
                  <div class="">
                    <form  id="ratingPopup">
                      <div class="pb-3 lxa_fontfamily lxa_font_size_05 lxa_medium_font">Average rating of {{post.title}}</div>
                      <div v-if="star_rated_by_user" class="row col-12 px-0 mx-0 paddingNil">
                        <input disabled="disabled" v-bind:id="ratings_id" type="text" class="kv-uni-star rating-loading" :value="user_star_rating" data-size="xs" title="">
                        <span class="lxa_fontfamily lxa_font_size_04 lxa_light_font">&nbsp;&nbsp;&nbsp;&nbsp;{{total_ratings_count}}&nbsp;&nbsp;Rating</span>
                      </div>
                      <div v-else class="row col-12 paddingNil">
                        <input v-bind:id="ratings_id" type="text" class="kv-uni-star rating-loading" data-size="xs" title="">
                        <span class="lxa_fontfamily lxa_font_size_04 lxa_light_font">&nbsp;&nbsp;&nbsp;&nbsp;{{total_ratings_count}}&nbsp;&nbsp;Rating</span>
                      </div>
                    </form>
                    <div class="py-3 lxa_fontfamily lxa_font_size_04 lxa_light_font">Rating poll progress</div>
                    <div class="col-sm-12 margin_bottom_2_percent px-0" v-for="(star_rating_count, index) in star_rating_counts">
                      <div class="col-sm-3 px-0 lxa_font_size_04 lxa_light_font">{{star_rating_count}} Star</div>
                      <div class="rating_score_breakup col-sm-6 px-0">
                        <div class="progress_container">
                          <div class="progress" :style="{'width': star_rating_percent[index] + '%'}">
                            <p class="visibility_hidden">5 Star</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-3 px-0 lxa_fontfamily lxa_font_size_04 lxa_light_font">{{star_rating_value_count[index]}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>`,
  props: {
    post: { type: Object, default: 'post' },
    rated_by_user: Array,
    user_rating: Array
  },
  data: function() {
    return {
      user_star_rating: this.user_rating,
      star_rated_by_user: this.rated_by_user,
      ratings_id: "ratings_" + new Date().getTime(),
      ratings_url: '',
      rates_count: '',
      average_rating: '',
      count: this.rates_count,
      rating: this.average_rating,
      star_rating_counts: [ 5, 4, 3, 2, 1],
      star_rating_value_count: '',
      star_rating_percent: [ 0, 0, 0, 0, 0 ],
      total_ratings_count: ''
    }
  },
  mounted: function(){
    this.blogRatingPopupDetail();
    EventBus.$on('blog-rating-response', (data) => { this.blogRatingPopupDetail(); });
    var curr = this;
    jQuery('#'+this.ratings_id).rating({
      theme: 'krajee-uni',
      filledStar: '&#x2605;',
      emptyStar: '&#x2606;',
      showClear: false,
      showCaption: false,
      step: 1
    });
    $(".rating-container .empty-stars, .rating-container .filled-stars").css("color", "#4184f3");
    jQuery('#'+this.ratings_id).on('rating.change', function(event, target) {
      jQuery(this).rating("refresh", {disabled:true, showClear:false});
      $(".rating-container .empty-stars, .rating-container .filled-stars").css("color", "#4184f3");
      curr.$http.post(curr.ratings_url+'&rating='+this.value).then(response => {
        curr.rating = response.body;
        curr.count += 1;
        curr.star_rated_by_user = true;
        jQuery(this).rating("refresh", {disabled:true, showClear:false});
        $(".rating-container .empty-stars, .rating-container .filled-stars").css("color", "#4184f3");
        EventBus.$emit('blog-rating-response', true);
      });
    });
  },
  methods: {
    blogRatingPopupDetail: function() {
      this.$http.get('/posts/'+this.post.id+'.json').then(function(response) {
        this.star_rating_value_count = response.body.score_breakup[0];
        this.star_rating_percent = response.body.score_breakup[1];
        this.total_ratings_count = response.body.total_ratings_count;
        this.ratings_url = response.body.ratings_url;
        this.average_rating = response.body.average_rating;
        this.rates_count = response.body.rates_count;
      });
    }
  }
});

Vue.component('blog-comment-popup', {
  template:`<div class="balloon_popup blog_popup_share">
              <form id="commentPopup">
                <blog-write-comment :post="post" :rated_by_user="comment_rated_by_user" :user_rating="comment_user_rating"></blog-write-comment>
              </form>
            </div>`,
  props: {
    post: { type: Object, default: 'post' },
    rated_by_user: Boolean,
    user_rating: Number
  },
  data: function() {
    return {
      comment_user_rating: this.user_rating,
      comment_rated_by_user: this.rated_by_user
    }
  },
  mounted: function(){
    
  },
  methods: {
    
  }
});

Vue.component('blog-edit', {
  template:` <div class="modal" :id="'edit_blog_post_modal_'+post.id" role="dialog">
              <div class="modal-xl lxa_create_edit_blog_content">
                <div class="modal-content user_filebox primary_bgcolor">
                  <div class="">
                    <span class="blog_header lxa_text_color_06 lxa_font_size_08 lxa_light_font"> Edit Blog </span>
                  </div>
                  <p class="light_box_close" data-dismiss="modal"> <i data-toggle="tooltip" data-placement="top" title="Close" class="fad fa-times lxa_text_color_08 lxa_font_size_06 cursor_pointer"></i></p>

                  <div class="margin_top_15px lxa_font_size_03">
                    <div class="row mx-0">
                      <span class="create_blog_inner_text lxa_text_color_02 lxa_fontfamily lxa_font_size_04 lxa_light_font mb-2">Title</span>
                      <input :id="'blogEditTitle_'+post.id" class="w-100 height_40px blog_input_text lxa_bg_05 p-2 lxa_border_radius border-0 lxa_font_size_04 lxa_light_font" type="text" v-model="formedit.title"></input>
                    </div>
                    <div class="row m-0">
                      <span class="lxa_text_color_02 lxa_fontfamily lxa_font_size_04 lxa_light_font mb-2 mt-3">Description</span>
                      <textarea class="post_textarea lxa_bg_05 lxa_border_radius border-0 lxa_font_size_04 lxa_light_font txtarea-resize" v-model="formedit.content"> </textarea>
                    </div>
                  </div>
                  <div class="margin_top_15px">
                    <div class="row mx-0 mb-2">
                      <span class="col-3 pl-0 lxa_text_color_02 lxa_fontfamily lxa_font_size_04 lxa_light_font">Publish</span>
                      <span class="col-3 pl-0 lxa_text_color_02 lxa_fontfamily lxa_font_size_04 lxa_light_font">Poll</span>
                      <span class="col-3 pl-0 lxa_text_color_02 lxa_fontfamily lxa_font_size_04 lxa_light_font">Begin</span>
                      <span class="col-3 px-0 lxa_text_color_02 lxa_fontfamily lxa_font_size_04 lxa_light_font">End <span class="end_datetime_text lxa_font_size_02 lxa_text_color_03 mb-2">(Leave empty to never end)</span> </span>
                    </div>
                    <div class="row mx-0 mb-2">
                      <span class="col-3 pl-0">
                        <select @change="PublishEditDropdown" class="publish_and_poll_dropdown blog_input_text w-100 height_40px lxa_bg_05 p-2 lxa_border_radius border-0 lxa_font_size_04 lxa_light_font" v-model="formedit.is_publish">
                          <option value="true">Yes</option>
                        </select>
                      </span>
                      <span class="col-3 pl-0">
                        <select @change="PollEditDropdown" class="publish_and_poll_dropdown blog_input_text w-100 height_40px lxa_bg_05 p-2 lxa_border_radius border-0 lxa_font_size_04 lxa_light_font" >
                          <option name="post_show_poll" value="false">No</option>
                        </select>
                      </span>
                      <span class="col-3 pl-0 blog_date_section d-flex align-items-center justify-content-start">
                        <input :id="'post_start_date_'+post.id" class="w-75 height_40px cursor_pointer blog_input_text lxa_bg_05 p-2 lxa_border_radius border-0 lxa_font_size_04 lxa_light_font" v-model="formedit.display_in_catalog_start_date">
                        <input :id="'post_start_time_'+post.id" class="w-25 height_40px cursor_pointer ml-2 blog_input_text lxa_bg_05 p-2 lxa_border_radius border-0 lxa_font_size_04 lxa_light_font" v-model="formedit.display_in_catalog_start_time">
                      </span>
                      <span class="col-3 px-0 blog_date_section d-flex align-items-center justify-content-start">
                        <input :id="'post_end_date_'+post.id" class="w-75 height_40px cursor_pointer blog_input_text lxa_bg_05 p-2 lxa_border_radius border-0 lxa_font_size_04 lxa_light_font" v-model="formedit.display_in_catalog_end_date">
                        <input :id="'post_end_time_'+post.id" class="w-25 height_40px cursor_pointer ml-2 blog_input_text lxa_bg_05 p-2 lxa_border_radius border-0 lxa_font_size_04 lxa_light_font" v-model="formedit.display_in_catalog_end_time">
                      </span>
                    </div>

                    <div id="pollBlogShowHide" v-if="formedit.show_poll" class="margin_bottom_10">
                      <div class="row m-0">
                        <span class="create_blog_inner_text lxa_text_color_02 lxa_fontfamily lxa_font_size_04 lxa_light_font mb-2">Poll Question</span>
                        <input :id="'poll_question_'+post.id" type ="text" v-model ="formedit.question" class="w-100 height_32px blog_input_text border-0 p-2 lxa_border_radius">
                      </div>
                      <div class="row m-0 mt-3 poll_question_answers">
                        <div class="col-12 p-0 mt-2">
                          <span class="create_blog_inner_text lxa_text_color_02 lxa_fontfamily lxa_font_size_04 lxa_light_font mb-2">Choice 1</span>
                          <input :id="'Choice_1_'+post.id" type ="text" v-model ="formedit.choices[0]" class="w-100 height_40px pl-2 blog_input_text mt-2 lxa_bg_05 p-2 lxa_border_radius border-0 lxa_font_size_04 lxa_light_font">
                        </div>
                        <div class="col-12 p-0 mt-2">
                          <span class="create_blog_inner_text lxa_text_color_02 lxa_fontfamily lxa_font_size_04 lxa_light_font mb-2">Choice 2</span>
                          <input :id="'Choice_2_'+post.id" type ="text" v-model ="formedit.choices[1]" class="w-100 height_40px pl-2 blog_input_text mt-2 lxa_bg_05 p-2 lxa_border_radius border-0 lxa_font_size_04 lxa_light_font">
                        </div>
                        <div class="col-12 p-0 mt-2">
                          <span class="create_blog_inner_text lxa_text_color_02 lxa_fontfamily lxa_font_size_04 lxa_light_font mb-2">Choice 3</span>
                          <input :id="'Choice_3_'+post.id" type ="text" v-model ="formedit.choices[2]" class="w-100 height_40px pl-2 blog_input_text mt-2 lxa_bg_05 p-2 lxa_border_radius border-0 lxa_font_size_04 lxa_light_font">
                        </div>
                        <div class="col-12 p-0 mt-2">
                          <span class="create_blog_inner_text lxa_text_color_02 lxa_fontfamily lxa_font_size_04 lxa_light_font mb-2">Choice 4</span>
                          <input :id="'Choice_4_'+post.id" type ="text" v-model ="formedit.choices[3]" class="w-100 height_40px pl-2 blog_input_text mt-2 lxa_bg_05 p-2 lxa_border_radius border-0 lxa_font_size_04 lxa_light_font">
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 my-3">
                      <div class="col-3 pl-0">
                        <span class="create_blog_inner_text lxa_text_color_02 lxa_fontfamily lxa_font_size_04 lxa_light_font">Duration</span>
                        <input :id="'post_duration_'+post.id" class="height_40px cursor_pointer blog_input_text w-100 lxa_bg_05 p-2 lxa_border_radius border-0 lxa_font_size_04 lxa_light_font mt-2" style="width: 100%;"v-model="formedit.duration">
                      </div>
                      <div class="col-6 pl-0">
                        <span class="create_blog_inner_text lxa_text_color_02 lxa_fontfamily lxa_font_size_04 lxa_light_font">Categories</span>
                        <multiselect v-model="formedit.category_list" tag-placeholder="Add this as new tag" placeholder="Select Category" label="name" track-by="id" :options="available_categories" :multiple="true" :taggable="true" @tag="addCategory" class="mt-2"></multiselect>
                      </div>
                      <div class="columns col-3 px-0" id="group_logo_field">
                        <span class="create_blog_inner_text lxa_text_color_02 lxa_fontfamily lxa_font_size_04 lxa_light_font">Upload Blog Image</span>
                        <div v-if="post.image_url" class="d-flex"><img v-bind:src="post.image_url" class="upload_link lxa_text_color_01 lxa_font_size_03 lxa_regular_font text-uppercase d-flex align-items-center justify-content-start"></img><span v-on:click="editBlogImage(post)" class="mt-5 pt-2 pl-3 cursor_pointer"><i class="fad fa-trash-alt lxa_text_color_01 lxa_font_size_04"></i></span></div>
                        <div v-else class="d-flex">
                         <div class="upload_link lxa_bg_05 lxa_text_color_01 lxa_font_size_03 lxa_regular_font text-uppercase d-flex align-items-center justify-content-center" v-on:click="uploadEditBlogImageFile()">
                         Upload
                        </div>
                          <span class="mt-5 pt-2 pl-3 cursor_pointer"><i class="fad fa-trash-alt color_light_gray lxa_font_size_04"></i></span>
                        </div>
                        <input type='file' ref="files" accept="image/*" id="edit-blog-upload" @change="attachEditBlogImageFile(post)" style="display: none"/>
                      </div>
                    </div>
                    <div class="row mx-0 mb-3">
                    <div class="col-12 px-0">
                      <span class="create_blog_inner_text lxa_text_color_02 lxa_fontfamily lxa_font_size_04 lxa_light_font">Tags</span>
                      <multiselect v-model="formedit.tag_list" class="mt-2" tag-placeholder="Add this as new tag" placeholder="Select Tag" label="name" track-by="id" :options="available_tags" :multiple="true" :taggable="true" @tag="addTag"></multiselect>
                    </div>
                    </div>
                    <div class="row mx-0">
                      <div id="edit_blog_post" class="col-12 px-0 create_blog_inner_text pb-0">
                      <div id="dis_editcount_wrapper" class="dis_editcount_wrapper custom-controls custom-checkbox">
                          <input v-model="formedit.display_sharing_tools" :id="'edit_blog-'+post.id" name="edit_blog" class="delivery edit_blog custom-control-input lxa-bs-checkboxbtn" type="checkbox" value="edit blog">
                          <label class="string_trunk8 custom-control-label" :for="'edit_blog-'+post.id">
                            <i class="fad fa-stop lxa_icon_size lxa_text_color_05"></i>
                            <i class="fad fa-check-square lxa_icon_size lxa_text_color_05"></i>
                            <span class="dis_editcount_label lxa_font_size_03 lxa_light_font ml-2">Display view counts,ratings,comments</span>
                          </label>
                        </div>                
                      </div>
                    </div>
                    <div class="row mx-0 margin_top_30 float-right">
                      <div class=""><button class="button blog_cancel_button btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase cursor_pointer mr-3" @click.prevent ="closeBlogEditor(post.id)">Cancel</button></div>
                      <div><button class="button blog_create_button btn lxa_bg_04 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase cursor_pointer" @click.prevent ="updatePost(post.id)">Done</button></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>`,
  components: {
     Multiselect
  },
  props: {
    post: { type: Object, default: 'post' },
    edit_clicked: Boolean
  },
  watch: {
    edit_clicked() {
      this.loadPostData()
    }
  },
  data: function() {
    return {
      post_poll: '',
      poll_choices: '',
      new_tag: [],
      new_category: '',
      available_tags: [],
      available_categories: [],
      formedit: { title: this.post.title, content: this.post.post, is_publish: this.post.is_publish, user_id: this.post.user_id, show_poll: this.post.show_poll, question: this.post.question, choices: [], display_in_catalog_start_date: '', display_in_catalog_start_time: '', display_in_catalog_end_date: '', display_in_catalog_end_time: '', display_sharing_tools: this.post.display_sharing_tools, category_list: this.post.category_list, tag_list: this.post.tag_list, duration: this.post.duration },
    }
  },
  mounted: function(){
    DateTimePicker('post_start_date_'+this.post.id,'M d, Y',true,false);
    DateTimePicker('post_end_date_'+this.post.id,'M d, Y',true,false);
    DateTimePicker('post_start_time_'+this.post.id,'H:i',false,true);
    DateTimePicker('post_end_time_'+this.post.id,'H:i',false,true);
    DateTimePicker('post_duration_'+this.post.id,'H:i',false,true);
    if (this.post.display_in_catalog_end_date == null) {
      this.formedit.display_in_catalog_end_date = jQuery('#post_end_date_'+this.post.id).val('');
      this.formedit.display_in_catalog_end_time = jQuery('#post_end_time_'+this.post.id).val('');
      this.formedit = { title: this.post.title, content: this.post.post, is_publish: this.post.is_publish, user_id: this.post.user_id, show_poll: this.post.show_poll, question: this.post.question, choices: [], display_in_catalog_start_date: moment(new Date(this.post.display_in_catalog_start_date)).utc().format('MMM DD, yyyy'), display_in_catalog_start_time: moment(new Date(this.post.display_in_catalog_start_date)).utc().format('HH:mm'), display_in_catalog_end_date: '', display_in_catalog_end_time: '', display_sharing_tools: this.post.display_sharing_tools, category_list: this.post.category_list, tag_list: this.post.tag_list, duration: moment(new Date(this.post.duration)).utc().format('HH:mm'), image: '' }
    }
    else {
      this.formedit = { title: this.post.title, content: this.post.post, is_publish: this.post.is_publish, user_id: this.post.user_id, show_poll: this.post.show_poll, question: this.post.question, choices: [], display_in_catalog_start_date: moment(new Date(this.post.display_in_catalog_start_date)).utc().format('MMM DD, yyyy'), display_in_catalog_start_time: moment(new Date(this.post.display_in_catalog_start_date)).utc().format('HH:mm'), display_in_catalog_end_date: moment(new Date(this.post.display_in_catalog_end_date)).utc().format('MMM DD, yyyy'), display_in_catalog_end_time: moment(new Date(this.post.display_in_catalog_end_date)).utc().format('HH:mm'), display_sharing_tools: this.post.display_sharing_tools, category_list: this.post.category_list, tag_list: this.post.tag_list, duration: moment(new Date(this.post.duration)).utc().format('HH:mm'), image: '' }
    }
  },
  methods: {
    loadPostData() {
      this.$http.get('/posts/'+this.post.id+'.json?').then(response => {
        this.formedit.category_list = response.body.selected_categories;
        this.available_categories = response.body.available_categories;
        this.formedit.tag_list = response.body.selected_tags;
        this.available_tags = response.body.available_tags;
        this.post_poll = response.body.post_poll;
        this.formedit.show_poll = this.post.show_poll
        if (this.formedit.show_poll == true) {
          this.formedit.question = this.post_poll.question
          this.poll_choices = response.body.poll_choices;
          this.formedit.choices = []
          this.poll_choices.forEach((value, index) => {
            this.formedit.choices[index] = value.description
          });
          jQuery("#pollBlogShowHide").show();
        }
        else {
          jQuery("#pollBlogShowHide").hide();
        }
      });
    },
    closeBlog() {
      jQuery("#edit_blog_post_modal_"+this.post.id +" .light_box_close").click();
    },
    attachImageFile() {
      this.formedit.image = this.$refs.files.files[0];
    },
    addTag(newTag) {
        const tag = {
        id: '',
        name: newTag,
        company_id: ''
      }
      this.$http.post('/tags.json?'+'&tag_list_name='+newTag).then(function(response) {
        this.formedit.tag_list.push(response.body.tag_data)
      });
    },
    addCategory(newCategory) {
      const category = {
        id: '',
        name: newCategory,
        company_id: ''
      }
      this.$http.post('/tags.json?'+'&category_list_name='+newCategory).then(function(response) {
        this.formedit.category_list.push(response.body.category_data)
      });
    },
    PublishEditDropdown(publishValue) {
      this.formedit.is_publish = publishValue.target.value;
    },
    PollEditDropdown(pollValue) {
      this.formedit.show_poll = pollValue.target.value;
      if (this.formedit.show_poll == "true") {
        jQuery("#pollBlogShowHide").show();
      }
      else {
        jQuery("#pollBlogShowHide").hide();
      }
    },
    updatePost(postId){
      var message = tinymce.activeEditor.getContent();
      this.formedit.content = message;
      this.formedit.duration = jQuery('#post_duration_'+this.post.id).val();
      if (jQuery("#blogEditTitle_"+this.post.id).val().length > 0 && jQuery("#post_start_date_"+this.post.id).val().length > 0 && jQuery("#post_start_time_"+this.post.id).val().length > 0) {
        if ($('input[type=radio][name=post_show_poll]:checked').val()) {
          var pollQuestion = jQuery("#poll_question").val().length > 0
          var pollChoice1 = jQuery("#Choice_1").val().length > 0
          var pollChoice2 = jQuery("#Choice_2").val().length > 0
          var pollChoice3 = jQuery("#Choice_3").val().length > 0
          var pollChoice4 = jQuery("#Choice_4").val().length > 0
          var postPollingData = pollQuestion && pollChoice1 && pollChoice2 && pollChoice3 && pollChoice4
          if (postPollingData) {
            this.$http.put('/posts/'+postId +'.json', this.buildUpdateParams()).then(function(response) {
              // this.renderUpdatedPost(response.body.published_posts, response.body.shared_posts, response.body.unpublished_posts, response.body.display_sharing_tools);
              this.$root.popUpMessage("Updated Post");
              this.$parent.$parent.load_posts()
              this.closeBlog()
            });
            this.$parent.show_blog_edit = false;
            this.$parent.selected_blog_editor = false;
          }
        }
        else {
           this.formedit.display_in_catalog_start_date = moment(new Date(jQuery('#post_start_date_'+this.post.id).val())).utc().format('YYYY-MM-DD') + ' ' + jQuery('#post_start_time_'+this.post.id).val()
           this.formedit.display_in_catalog_end_date = moment(new Date(jQuery('#post_end_date_'+this.post.id).val())).utc().format('YYYY-MM-DD') + ' ' + jQuery('#post_end_time_'+this.post.id).val()
          this.$http.put('/posts/'+postId +'.json', this.buildUpdateParams()).then(function(response) {
            // this.renderUpdatedPost(response.body.published_posts, response.body.shared_posts, response.body.unpublished_posts, response.body.display_sharing_tools);
            this.$root.popUpMessage("Updated Post");
            this.$parent.$parent.load_posts()
            this.closeBlog()
          });
          this.$parent.show_blog_edit = false;
          this.$parent.selected_blog_editor = false;
        }
      }
    },
    renderUpdatedPost: function(published_posts, shared_posts, unpublished_posts, blog_display_sharing_tools) {
      this.posts = published_posts;
      this.shared = shared_posts;
      this.unpublished = unpublished_posts;
      this.show_hide_display_sharing_tools = blog_display_sharing_tools;
      EventBus.$emit('item-blog-post', this.posts, this.show_hide_display_sharing_tools);
      EventBus.$emit('item-shared-post', this.shared, this.show_hide_display_sharing_tools);
      EventBus.$emit('item-unpublished-post', this.unpublished, this.show_hide_display_sharing_tools);
    },
    closeBlogEditor(postId){
      // this.$parent.show_blog_edit = false;
      // this.$parent.selected_blog_editor = false;
      this.closeBlog()
    },
    attachEditBlogImageFile(post) {
      this.file = this.$refs.files.files[0];
      post.image_url = URL.createObjectURL(this.file);
      this.formedit.image = this.$refs.files.files[0];
    },
    uploadEditBlogImageFile: function() {    
      document.getElementById('edit-blog-upload').click();
    },
    editBlogImage(post) {
      this.file = '';
      post.image_url = '';
    },
    getPostImage(post){
      return post.image_url
    },
    buildUpdateParams() {
      let formData = new FormData();
      formData.append('post[image]', this.formedit.image);
      formData.append('post[title]', this.formedit.title)
      formData.append('post[post]', this.formedit.content)
      formData.append('post[raw_post]', this.formedit.content)
      formData.append('post[user_id]', this.formedit.user_id)
      formData.append('post[is_publish]', this.formedit.is_publish)
      formData.append('post[hidden]', this.formedit.hidden)
      formData.append('post[show_poll]', this.formedit.show_poll)
      formData.append('post[display_in_catalog_start_date]', this.formedit.display_in_catalog_start_date)
      formData.append('post[display_in_catalog_end_date]', this.formedit.display_in_catalog_end_date)
      formData.append('post[display_sharing_tools]', this.formedit.display_sharing_tools)
      formData.append('post[duration]', this.formedit.duration)
      if (this.formedit.show_poll) {
        formData.append('post[question]', this.formedit.question)
        formData.append('post[choices]', this.formedit.choices)
      }
      return formData
    }
  }
});

Vue.component('blog-share-invite', {
  template:`<div class="modal" :id="'share_blog_post_modal_'+post.id" role="dialog">
              <div class="modal-xl">
                <div class="modal-content user_filebox blog_share_invite primary_bgcolor">
                  <p class="light_box_close" data-dismiss="modal"> <i data-toggle="tooltip" data-placement="top" title="Close" class="fad fa-times lxa_text_color_08 lxa_font_size_06 cursor_pointer"></i></p>
                  <div class="lxa_font_size_04 lxa_light_font">
                    <form :id="'new_recommendation_'+post.id" class="form-horizontal " v-on:submit.prevent="onSubmit" role="form">
                      <div class="">Send invitations to:</div>
                      <div class="lxa_font_size_02 lxa_text_color_03 mb-2">To: (Existing users only)</div>
                      <input :id="'sendBlogInvite_'+post.id" type="text" class="lxa_bg_05 p-2 lxa_border_radius border-0 tags message_input hide" data-vv-validate-on="none" name="user_ids" placeholder="To" autocomplete="off">
                      <newTagInput v-bind:recipient_err="errors.has('user_ids')" />
                      <div class="error_container">
                        <span id="error_on_user_list" class ="font_open_sans_regular_11_red inbox_err inline_block hide"></span>
                      </div>
                      <div class="mb-2">Message:</div>
                      <textarea class="blog_formshare_message p-2 lxa_bg_05 lxa_border_radius border-0 txtarea-resize" name="recommendation[message]" v-model="message"></textarea>
                      <div class="d-flex align-items-center justify-content-end blog_share_buttons my-3">
                        <button class="cancel_link_container btn lxa_bg_03 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase cursor_pointer" @click.prevent ="cancelShare()">Cancel</button>
                        <button type="submit" class="ml-3 button_share btn lxa_bg_04 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase cursor_pointer">{{$t('common.send').toUpperCase()}}</button>
                      </div>
                      <div class="lxa_common_border_b1 mb-3 w-100"></div>
                      <div class="w-100 lxa_bold_font text-center text-uppercase">Or...</div>
                      <div class="mt-3 mb-2">Share this link to the item.</div>
                      <input :id="'copy_invite_link_'+post.id" type="text" class="blog_formshare_link lxa_bg_05 p-2 lxa_border_radius border-0" placeholder="Link" v-model="link_item">
                      <div class="float-right blog_share_buttons mt-3">
                        <button @click.prevent="copyText" class="button_share btn lxa_bg_04 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase">{{$t('share_popup.copy').toUpperCase()}}</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>`,
  props: {
    post: { type: Object, default: 'post' },
    share_clicked: Boolean
  },
  watch: {
    share_clicked() {
      this.get_link()
    }
  },
  data: function() {
    return {
      link_item: '',
      message: this.$t('share_popup.message_default')
    }
  },
  mounted: function(){
    self = this;
    $('#copy_invite_link_'+this.post.id).attr('disabled','disabled');
    jQuery('#newTagsInput').typeahead({
      displayField: "email",
      ajax: {
        url: '/home/auto_complete_for_username.json',
        method: 'get',
        triggerLength: 1
      },
      valueField: "name",
      onSelect: function(e) {updateElementForMessages(e);}
    });
    jQuery("input#newTagsInput").focus();

    jQuery('.newTagsList').on('DOMNodeInserted', '.dropdown-menu', function (){
     self.resize_dropdownlist();
    });

    window.addEventListener('resize', this.resize_dropdownlist);

    jQuery(".newTagsList").niceScroll({cursorcolor:"#4184F3",cursorwidth:"3px",cursorfixedheight: "10",autohidemode: "scroll"});
    jQuery("#invite_message").find('textarea').niceScroll({cursorcolor:"#4184F3",cursorwidth:"3px",autohidemode: "scroll"});
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.resize_dropdownlist);
  },
  methods: {
    get_link() {
      this.$http.get('/item_link_shared_code.json?'+'&item='+this.post.id+'&current_user_id='+this.post.user_id).then(response => {
        this.link_item = response.body.product_permissions_url;
      });
    },
    copyText: function() {
      const el = document.createElement('textarea');
      el.value = this.link_item;
      el.setAttribute('readonly', '');
      document.body.appendChild(el);
      const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;
      el.select();
      document.execCommand('copy');
      el.remove();
    },
    postMessage: function() {},
    setInterval: function() {},
    cancelShare(){
      this.$parent.show_share_post = false;
    },
    onSubmit: function() {
      var self=this;
      jQuery("#error_on_user_list").removeClass('show').addClass('hide');
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.post('/recommendations'+'?&recommendable_id='+this.post.id+'&recommendable_type=Post&message='+this.message+'&'+jQuery('#new_recommendation_'+this.post.id).serialize()).then(response => {
            var result = response;
            this.$parent.emailed_count = response.body.emailed_count;
            this.$root.popUpMessage("Shared successfully");
            $('#share_blog_post_modal_'+self.post.id + ' .light_box_close').click();
          });
        }
        this.$parent.show_share_post = false;
      });
    },
    resize_dropdownlist: function() {
      var flag = false;
      setTimeout(function() {
        jQuery(".dropdown-menu").removeClass('max_content_dropdown');
        jQuery(".dropdown-menu li").each(function(index) {
          if (this.offsetWidth < this.scrollWidth) {
            flag =  true;
          }
        });
        if(flag){
          jQuery(".dropdown-menu").css({'margin-left': '0px', 'width': '100%'});
        }
      }, 50);
    }
  }
});

Vue.component('blog-write-comment', {
  template:`<div>
              <div class="col-12 p-0 row m-0">
                <span class="lxa_fontfamily lxa_font_size_05 lxa_medium_font">{{comments_count}} Comments</span>
              </div>
              <div class="col-12 row p-0 m-0">
                <div v-bind:class="'blog_post_'+post.id +' col-12 p-0'">
                  <div v-for="existing_comment in existing_comments">
                    <div class="" v-if="existing_comment.deleted_at == null">
                      <div class="row m-0 mt-3">
                      <div class="col-12 px-0 lxa_blog_comment_wrapper">
                        <div class="d-flex align-items-start justify-content-start">
                        <div class="p-0">
                          <img class="lxa_blog_comment_left text_align_center lxa_blog_usr_pic lxa_border_radius lxa_bg_05 cursor_pointer"" :src="user_photo_url">
                        </div>
                        <div class="lxa_blog_comment_right ml-3">
                        <div class="row mx-0 font_barlow_semi_condensed_light_14">
                          <div class="col-12 p-0">
                            <div class="font_barlow_semi_condensed_medium_16">{{existing_comment.author_name}}</div>
                            <comment-star-rating v-if="false" :post="post" :user_rating="comment_user_star_rating"></comment-star-rating>
                            <div class="mt-2">{{existing_comment.body}}</div>
                            <div class="row float_right m-0">
                              <span class="comment_like_reply_delete pr-3" @click.prevent="showReplyComment(existing_comment.id)"><i class="fad fa-reply fa-lg lxa_text_color_06 lxa_icon_size cursor_pointer"></i></span>
                              <span class="comment_like_reply_delete pr-1" @click.prevent="showLikeComment(existing_comment.id)"><i class="fad fa-thumbs-up fa-lg lxa_text_color_05 lxa_icon_size cursor_pointer"></i></span>
                              <span v-for="comment_like in comment_likes" class="pr-1">
                                <span v-if="existing_comment.id == comment_like.id && comment_like.liked">
                                  <span v-if="comment_like.likes_count > 0" class="like_count_circle lxa_medium_font lxa_font_size_01 lxa_bg_01 lxa_text_color_08">{{comment_like.likes_count}}</span>
                                </span>
                                <span v-else>
                                  <span v-if="existing_comment.id == comment_like.likeable_id" class="like_count_circle lxa_medium_font lxa_font_size_01 lxa_bg_01 lxa_text_color_08">{{comment_like.likes_count}}</span>
                                </span>
                              </span>
                              <span v-if="existing_comment.can_be_deleted" class="comment_like_reply_delete" @click="deleteComment(existing_comment.id)"><i class="fad fa-trash-alt fa-lg lxa_text_color_01 lxa_icon_size cursor_pointer"></i></span>
                            </div>
                          </div>
                          <div class="col-12 p-0 mt-2">
                            <div class="row mx-0" v-for="reply_for_comment in reply_for_comments" v-if="existing_comment.id == reply_for_comment.commentable_id">
                              <div class="col-12 px-0 lxa_blog_comment_wrapper">
                              <div class="d-flex align-items-start justify-content-start"> 
                              <div><img class="lxa_blog_comment_left text_align_center lxa_blog_usr_pic lxa_border_radius lxa_bg_05 cursor_pointer" :src="user_photo_url">
                              </div>
                              <div class="lxa_blog_comment_right ml-3" v-if="existing_comment.id == reply_for_comment.commentable_id">
                                <div class="font_barlow_semi_condensed_medium_16"> {{reply_for_comment.author_name}}</div>
                                <div class="mt-2">{{reply_for_comment.body}}</div>
                                <div class="row float_right m-0">
                                  <span class="comment_like_reply_delete pr-1" @click.prevent="showLikeComment(reply_for_comment.id)"><i class="fad fa-thumbs-up fa-lg lxa_text_color_05 lxa_icon_size cursor_pointer"></i></span>
                                  <span v-for="reply_like in reply_likes">
                                    <span v-if="reply_for_comment.id == reply_like.id && reply_like.liked">
                                      <span v-if="reply_like.likes_count > 0" class="like_count_circle lxa_medium_font lxa_font_size_01 lxa_bg_01 lxa_text_color_08">{{reply_like.likes_count}}</span>
                                    </span>
                                    <span v-else>
                                      <span v-if="reply_for_comment.id == reply_like.likeable_id" class="like_count_circle lxa_medium_font lxa_font_size_01 lxa_bg_01 lxa_text_color_08">{{reply_like.likes_count}}</span>
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                            </div>
                          </div>
                          <div v-bind:id="'showReplyId'+existing_comment.id" class="row m-0 mt-2 reply_comment col-12 p-0 display_none">
                            <div class="col-6 px-0">
                              <input v-bind:id="'addPublishReplyId'+existing_comment.id" type="text" class="primary_bgcolor p-3 lxa_font_size_03 lxa_light_font border-0 lxa_border_radius"/>
                            </div>
                            <div class="col-6 pr-0">
                              <button class="_button btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase cursor_pointer" @click.prevent ="addBlogReply(existing_comment.id)">Reply</button>
                            </div>
                          </div>
                        </div>
                      </div>
                         </div>
                      </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mx-0 margin_top_15">
                    <div class="col-6 px-0">
                      <textarea rows="3" id="addPublishCommentId" class="primary_bgcolor p-3 lxa_font_size_03 lxa_light_font txtarea-resize border-0 lxa_border_radius" placeholder="Write your comments here"> </textarea>
                    </div>
                    <div class="col-6">
                    <div class="d-flex align-items-start justify-content-start h-100">
                      <button id="addComment" class="_button btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase cursor_pointer" @click.prevent ="addBlogComment(post.id)">Add Your Comment</button>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>`,
  props: {
    post: Object,
    rated_by_user: Array,
    user_rating: Array
  },
  data: function() {
    return {
      comment_likes: '',
      reply_likes: '',
      comment_rate_by_user: this.rated_by_user,
      comment_user_star_rating: this.user_rating,
      ratings_id: 'ratings_' + new Date().getTime(),
      comments_count: 0,
      existing_comments: '',
      reply_to_comment: '',
      formcomment: {comment:'', commentable_id: 0, commentable_type:"Post", author_name: '', author_email:''},
      formreply: {comment:'', commentable_id: 0, commentable_type:"Comment", author_name: '', author_email:''}
    }
  },
  mounted: function() {
    var self = this;
    this.commentsData();
    jQuery('#'+this.ratings_id).rating({
      theme: 'krajee-uni',
      filledStar: '&#x2605;',
      emptyStar: '&#x2606;',
      showClear: false,
      showCaption: false,
      step: 1
    });
    $(".rating-container .empty-stars, .rating-container .filled-stars").css("color", "#4184f3");
    jQuery('#'+this.ratings_id).on('rating.change', function(event, target) {
      self.rating = this.value;
    });
  },
  methods: {
    commentsData: function() {
      this.$http.get('/posts/'+this.post.id+'.json?').then(response => {
        this.comments_count = response.body.comments_count;
        this.comment_user_star_rating = response.body.user_rating;
        this.existing_comments = response.body.existing_comments;
        this.reply_for_comments = response.body.reply_for_comments;
        this.comment_likes = response.body.comment_likes;
        this.reply_likes = response.body.reply_likes;
        this.user_photo_url = response.body.user_photo_url;
      });
    },
    showLikeComment: function(likeId) {
      this.$http.get('/posts/'+this.post.id+'.json?'+'&likeable_id='+likeId+'&post_like=true').then(response => {
        this.liked = response.body.liked;
        var liked_id = response.body.liked_id;
        var currentUserId = response.body.curr_user_id;
        if (this.liked) {
          this.$http.delete('/likes/'+liked_id+'.json?'+'&likeable_id='+likeId+'&likeable_type=Comment'+'&user_id='+currentUserId).then(response =>{
            this.commentsData();
          });
        }
        else {
          this.$http.post('/likes?'+'&likeable_id='+likeId+'&likeable_type=Comment'+'&user_id='+currentUserId).then(function(response) {
            this.commentsData();
          });
        }
      });
    },
    showReplyComment: function(commentId) {
      jQuery("#showReplyId"+commentId).removeClass('display_none');
      /*jQuery("#showReplyId"+commentId).addClass('footer_columns grid_2');*/
    },
    deleteComment(commentId){
      this.$modal.show('dialog', {
        text: 'Are you sure you want to delete this item?',
        buttons: [{
            title: 'Cancel',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Yes',
            handler: () => {
              this.$http.delete('/comments/'+commentId+'.json?&commentable_id='+commentId+'&commentable_type=Post').then(response =>{
                this.renderCommentReply(this.post.id);
              });
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    },
    addBlogComment(postId){
      if($('#addPublishCommentId').val() != "") {
        var rating_value = jQuery("#"+this.ratings_id).val();
        this.formcomment.commentable_id = postId;
        this.formcomment.comment = jQuery("#addPublishCommentId").val();
        this.$http.post('/comments?'+'&resource_id='+postId+'&rating='+rating_value+'&resource_type=Post', this.formcomment).then(function(response) {
          this.renderCommentReply(postId);
          EventBus.$emit('comment-rating-response', true);
        });
      } else {
        this.$root.popUpMessage("Comment text Can't empty.");
      }
    },
    addBlogReply(postId){
      this.formreply.commentable_id = postId;
      this.formreply.comment = jQuery('#addPublishReplyId'+ postId).val();
      this.$http.post('/comments', this.formreply).then(function(response) {
        jQuery('#addPublishReplyId'+ postId).val("");
        this.renderCommentReply(this.post.id);
      });
      jQuery("#showReplyId"+postId).removeClass('footer_columns grid_2');
      jQuery("#showReplyId"+postId).addClass('display_none');
    },
    renderCommentReply: function(postId) {
      jQuery("#addPublishCommentId").val("");
      this.$http.get('/posts/'+postId+'.json?').then(response => {
        this.comment_rate_by_user = response.body.rated_by_user;
        this.comment_user_star_rating = response.body.user_rating;
        this.comments_count = response.body.comments_count;
        this.existing_comments = response.body.existing_comments;
        this.reply_for_comments = response.body.reply_for_comments;
      });
    }
  }
});

Vue.component('comment-star-rating', {
  template:`<div>
              <input disabled="disabled" v-bind:id="ratings_id" type="text" class="kv-uni-star rating-loading" :value="star_rating" data-size="xs" title="">
            </div>`,
  props: {
    post: Object,
    user_rating: Number
  },
  data: function() {
    return {
      ratings_id: 'ratings_' + new Date().getTime(),
      star_rating: this.user_rating
    }
  },
  mounted: function() {
    var self = this;
    jQuery('#'+this.ratings_id).rating({
      theme: 'krajee-uni',
      filledStar: '&#x2605;',
      emptyStar: '&#x2606;',
      showClear: false,
      showCaption: false,
      step: 1
    });
    $(".rating-container .empty-stars, .rating-container .filled-stars").css("color", "#4184f3");
    jQuery('#'+this.ratings_id).on('rating.change', function(event, target) {
      self.rating = this.value;
    });
    EventBus.$on('comment-rating-response', (data) => {
      this.$http.get('/posts/'+this.post.id+'.json').then(function(response) {
        this.star_rating = response.body.average_rating;
        $('#'+this.ratings_id).rating('update', this.star_rating);
      });
    });
  },
  methods: {
    
  }
});

Vue.component('blog-bookmark', {
  template: `<div v-if="bookmarked" class="bookmark inline_block">
              <img src="/images/single_page/bookmarked.png" alt="bookmarked" title="Bookmarked">
            </div>
            <div v-else class="bookmark inline_block">
              <img v-on:click="onBookmarkClick" v-on:mouseover="onMouseOver" v-on:mouseleave="onMouseLeave" src="/images/single_page/bookmark.png" class="cursor_pointer" alt="bookmark" title="Bookmark">
            </div>`,
  props: {
    post: Object
  },
  data: function() {
    return {
      bookmarked: '',
    }
  },
  mounted: function(){
    this.show_bookmarked_item();
  },
  methods: {
    show_bookmarked_item: function() {
      this.$http.get('bookmarks.json?'+'&bookmarkable_id='+this.post.id+'&bookmarkable_type=Post&user_id='+this.post.user_id).then(response => {
        this.bookmarked = response.body.bookmarked;
      });
    },
    onBookmarkClick: function(event) {
      this.$http.post('/bookmarks.json?'+'&bookmarkable_id='+this.post.id+'&bookmarkable_type=Post&user_id='+this.post.user_id).then(response => {
        jQuery(event.target).attr('src', '/images/single_page/bookmarked.png');
        jQuery(event.target).attr('title', 'Bookmarked');
        jQuery(event.target).removeClass('cursor_pointer');
        jQuery(event.target).addClass('disabled');
        this.show_bookmarked_item();
      });
    },
    onMouseOver: function(event) {
      if(!jQuery(event.target).hasClass('disabled')) {
        jQuery(event.target).attr('src', '/images/single_page/bookmarked.png');
      }
    },
    onMouseLeave: function(event) {
      if(!jQuery(event.target).hasClass('disabled')) {
        jQuery(event.target).attr('src', '/images/single_page/bookmark.png');
      }
    }
  }
});