var from_single_page = true;
require('packs/trunk8.js')

jQuery(document).ready(function($) {
  mouse_over_actions('enrolled_items_menu', 'enrolled_items_menu_popup');
  mouse_over_actions('available_items_menu', 'available_items_menu_popup');

  $(window).resize(function() {
    $(".item_title").trunk8();
    $(".item_description").trunk8();
    resize_login_modal();
    $(".announcement_popup").css({top: $('#header_announcement').position().top+65, left: $('#header_announcement').position().left-185});
  });

  jQuery(document).on('click', '#last_content_notice_container .last_content_notice', function() {
    showHideLastContentNotice(false);
    selectNextContent();
  });
  
  jQuery(document).on('click', '#scorm_last_content_notice_container .last_content_notice', function() {
    var player_preview = jQuery(this).parent().parent();
    player_preview.getNiceScroll(0).doZoomOut();
    showHideLastContentNotice(false);
    selectNextContent(false, true);
  });
  
   jQuery(document).on('click', '#tincan_last_content_notice_container .last_content_notice', function() {
    var player_preview = jQuery(this).parent().parent();
    player_preview.getNiceScroll(0).doZoomOut();
    showHideLastContentNotice(false);
    selectNextContent(false, true);
  });
  
  jQuery(document).on('click', ".last_content_notice .dismiss_notice", function(){
    showHideLastContentNotice(false);
    event.stopPropagation();
  });
  // $("[data-toggle='tooltip']").tooltip();
  window.onscroll = function() {addStickyHeader()};

});

addStickyHeader = function () {
  var navbar = document.getElementById("navbar");
  var sticky = navbar.offsetTop;
  if (window.pageYOffset > sticky) {
    navbar.classList.add("sticky_header");
    jQuery("#announcement_display").getNiceScroll().resize();
  } else {
    navbar.classList.remove("sticky_header");
    jQuery("#announcement_display").getNiceScroll().resize();
  }
}

mouse_over_actions = function (el_id, popup_id) {
  jQuery(document).on('mouseover', "#"+el_id, function(){
    jQuery("#"+popup_id).removeClass("hide");
    jQuery("#"+popup_id).addClass("show");
  });
  jQuery(document).on('mouseleave', "#"+el_id, function(){
    jQuery("#"+popup_id).addClass("hide");
    jQuery("#"+popup_id).removeClass("show");
  });
}

customCheckbox = function (checkbox) {
    jQuery(checkbox).each(function(){
      jQuery(this).wrap( "<span class='custom_checkbox'></span>" );
      if(jQuery(this).is(':checked')){
        jQuery(this).closest('.checkbox').find("#tick_id").addClass("tick_span");
      }
      jQuery(this).on('click', function(){
        jQuery(this).closest('.checkbox').find("#tick_id").toggleClass("tick_span");
      });
    });
}

hasClass = function (element, classname) {
  return (element.getAttribute('class') && element.getAttribute('class').indexOf(classname) > -1);
}

items_per_page = function (from_search) {
  var el = jQuery(document);
  var doc_width = from_search ? (el.width()*(90/100)) : el.width();
  var per_page;
  if(doc_width >= 1200) {
    per_page = from_search ? 16 : 5;
  } else if(doc_width >= 992 && doc_width <= 1199) {
    per_page = from_search ? 9 : 4;
  } else if(doc_width >= 768 && doc_width <= 991) {
    per_page = from_search ? 6 : 3;
  } else if(doc_width <= 767) {
    per_page = from_search ? 3 : 2;
  }
  return per_page;
}

my_learning_per_page = function(list_view = false) {
  // var el = jQuery('#my_learning_layout');
  // var doc_width = el.width()*90/100;
  // var per_page;
  // if(doc_width >= 1175) {
  //   per_page = 5;
  // } else if(doc_width >= 992 && doc_width <= 1174) {
  //   per_page = 4;
  // } else if(doc_width >= 745 && doc_width <= 991) {
  //   per_page = 3;
  // } else if(doc_width >= 505 && doc_width <= 744) {
  //   per_page = 2;
  // } else if(doc_width <= 504) {
  //   per_page = 1;  
  // }
  var component_width = jQuery('#my_learning_layout').width();
  var items = Math.floor(component_width/250);
  if (items > 4) {
    items = 4
  } 
  if (!list_view) {
    items = items*2
  }
  return items;
  // return per_page;  
}

similar_course_per_page = function() {
  var screen_width = window.innerWidth;
  if (screen_width>1010) {
    items = 4
  } else if (screen_width>787) {
    items = 3
  } else {
    items = 1
  }
  return items; 
}

resetSortFilterOptions = function (obj, type, event) {
  //if ((event && event.target.id != obj[type+'_active_filter']) || event == undefined){
    jQuery("#" + type + "_filter .reset_filter").click();
    jQuery("#" + type + "_sort_dropdown li").each(function(index){
      jQuery(this).removeClass('activated');
    });
    jQuery("#" + type + "_sort_dropdown").find("#recent_items").addClass('activated');
  //}
}

showHideLoader = function (selector, show) {
  if(show) {
    jQuery(selector+' .circular_loader').removeClass('hidden');
  } else {
    jQuery(selector+' .circular_loader').addClass('hidden');
  }
}

addSlider = function () {
  var jcarousel = jQuery('.jcarousel');

  jcarousel.on('jcarousel:reload jcarousel:create', function () {
    var carousel = jQuery(this);
    width = carousel.innerWidth()/items_per_page();
    carousel.jcarousel('items').css('width', Math.ceil(width) + 'px');
  }).jcarousel({
    wrap: 'custom'
  });

  jQuery('.jcarousel-control-prev').jcarouselControl({
    target: '-='
  });

  jQuery('.jcarousel-control-next').jcarouselControl({
    target: '+='
  });
}

search_and_highlight = function (id, search_item, style='block', flag = true){
  var equal = false;
  var matched = false;
  var keyword = search_item;
  jQuery("#"+id+" li").each( function(ele){
    label = jQuery(this).find('.lxa_selected_chbox');
    label_id = label.attr('id');
    jQuery("#"+label_id).find('b').removeClass('stop_close').removeAttr('class');
    label_text = label.html().replace(/<(\/)?b>/gi,''); 
    if (label == undefined || label == null) {
      if (flag){
        this.style.display = 'none';
      }
    }else {
      matches = label_text.match(new RegExp(keyword, "gi"));
      if (matches == null) {
        if (flag){
          this.style.display = 'none';
        }
      }else { 
        matched = true;
        if (label_text.toLowerCase() == keyword.toLowerCase()) {
          equal = true;
        };
        this.style.display = style;
        jQuery.each(matches, function(index, str) {                  
          bold_text = label_text.replace(str, str.bold());
          jQuery("#"+label_id).html(bold_text);
        });
        jQuery("#"+label_id).find('b').addClass('stop_close');   
      }
    }
  });
  return equal;
}


search_items = function (id, search_item, style='block', flag = true){
  var equal = false;
  var matched = false;
  var keyword = search_item;
  jQuery("#"+id+" .items").each( function(ele){
    label = jQuery(this).find('label');
    label_id = label.attr('id');
    jQuery("#"+label_id).find('b').removeClass('stop_close').removeAttr('class');
    label_text = label.html().replace(/<(\/)?b>/gi,''); 
    if (label == undefined || label == null) {
      if (flag){
        this.style.display = 'none';
      }
    }else {
      matches = label_text.match(new RegExp(keyword, "gi"));
      if (matches == null) {
        if (flag){
          this.style.display = 'none';
        }
      }else { 
        matched = true;
        if (label_text.toLowerCase() == keyword.toLowerCase()) {
          equal = true;
        };
        this.style.display = style;
        jQuery.each(matches, function(index, str) {                  
          bold_text = label_text.replace(str, str.bold());
          jQuery("#"+label_id).html(bold_text);
        });
        jQuery("#"+label_id).find('b').addClass('stop_close');   
      }
    }
  });
  return equal;
}

flash = function (message, type, duration) {
  jQuery("#flash_container").addClass('z_index_1000');
  if (jQuery('#flash_message_content').css('display') != 'none') {
    setTimeout(function() {
      flash(message, type, duration);
    }, 20);
  }else{
    if (duration == null) {
    duration = 5;
    }
    if (type == null) {
      type = 'flash_notice';
    }

    if(type == 'flash_notice'){
      jQuery("#flash_message_content").removeClass('alert-danger').addClass('alert-success');
    } else if(type == 'flash_warning'){
      jQuery("#flash_message_content").removeClass('alert-success').addClass('alert-danger');
    }
    jQuery("#flash_message_content").find('p').empty().append(unescape(message));
    jQuery("#flash_message_content").slideDown().addClass('bounce_flash');

    setTimeout(function() {
      close_flash();
    }, duration * 1000);
  }
}

close_flash = function () {
  if(jQuery("#flash_message_content").hasClass('bounce_flash')){
    jQuery("#flash_message_content").removeClass('bounce_flash').addClass('marquee');
  }
  setTimeout(function() {
    jQuery("#flash_message_content").removeClass('marquee').toggle();
    jQuery("#flash_container").removeClass('z_index_1000');
  }, 3000);
}

circle_animation = function (id,color,trailcolor,strokeWidth){
  return new ProgressBar.Circle(id, {
    easing: 'easeInOut',
    duration: 0,
    color: color,
    trailColor: trailcolor,
    strokeWidth: strokeWidth,
    trailWidth: 7,
    svgStyle: null
  });
}

ajaxloadcontent = function (page_no, controller_url, current_view, loader_class ,content_handler) {
  var self = this;
  jQuery("#"+current_view).find('.loading').removeClass('hidden');
  jQuery("#"+current_view).find('.circular_loader').removeClass('hidden').addClass(loader_class);
  jQuery.ajax({
                      type: 'GET',
                      url: controller_url,
                      data : "page="+page_no,
                      success: function(response) { 
                        return content_handler(response);
                      }                  
  });      
}

array_sort_by_name = function (array, name) {
  array.sort(( a, b) => {
    if (a[name].toLowerCase() < b[name].toLowerCase())
      return -1;
    if (a[name].toLowerCase() > b[name].toLowerCase())
      return 1;
    return 0;
  });
  return array;
}

before_hotspot_answer_submit = function () {
  var hotspotAnswers = jQuery('.hotspot_answer');
  hotspotAnswers.each(function(i, el) {
      var $el             = jQuery(el),
          $container      = $el.find('.hotspot_container'),
          selectionPoint  = $container.data('selectionPoint'),
          $answerField    = $el.find('input[type=hidden]');

      $answerField.val(JSON.stringify(selectionPoint));
  });
}  


init_tiny_mce = function (callback, height, width) {
  tinyMCE.init({
    browsers : "msie,gecko,safari",
    cleanup_on_startup : true, 
    convert_fonts_to_spans : true, 
    editor_deselector : 'mceNoEditor',
    extended_valid_elements : 'object,param[name|value],embed,img[class|src|flashvars|border=0|alt|title|hspace|vspace|width|height|align|onmouseover|onmouseout|name|obj|param|embed|scale|wmode|salign|style|data|type],embed[src|quality|scale|salign|wmode|bgcolor|width|height|name|align|type|pluginspage|flashvars|allowfullscreen],object[type|align<bottom?left?middle?right?top|archive|border|class|classid|codebase|codetype|data|declare|dir<ltr?rtl|height|hspace|id|lang|name|style|tabindex|title|type|usemap|vspace|width|allowNetworking|allowfullscreen],iframe[src|title|width|height],video[autoplay|class|controls|dir<ltr?rtl|id|lang|loop|onclick|ondblclick|onkeydown|onkeypress|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|preload|poster|src|style|title|width|*],source[src|type|*],mcevideoembed[*],div[id|style|*],canvas[*],audio[*],article[*],aside[*],details[*],figure[*],footer[*],header[*],main[*],mark[*],nav[*],section[*],summary[*],time[*],figcaption[*]', 
    height : height+'px', 
    init_instance_callback : callback, 
    mode : 'none', 
    plugins : "inlinepopups,safari,curblyadvimage", 
    readonly : true, 
    theme : 'advanced', 
    theme_advanced_buttons1 : "bold,italic,underline,separator,bullist,numlist,separator,link,unlink,image", 
    theme_advanced_buttons2 : "", 
    theme_advanced_buttons3 : "", 
    theme_advanced_resize_horizontal : false, 
    theme_advanced_resizing : true, 
    theme_advanced_statusbar_location : 'bottom', 
    theme_advanced_toolbar_location : 'top', 
    width : width+'px'
  });
}

set_item_width = function () {
  // if(jQuery("#modal-header-global-search").is(':visible')) {
  //   var modal_width = jQuery("#modal-header-global-search .modal-dialog").width();
  //   var width;
  //   if(modal_width >= 1200) {
  //     width = '25%';
  //   } else if(modal_width >= 992 && modal_width <= 1199) {
  //     width = '33.33333333%';
  //   } else if(modal_width >= 768 && modal_width <= 991) {
  //     width = '50%';
  //   } else if(modal_width <= 767) {
  //     width = '100%';
  //   }
  //   jQuery('#modal-header-global-search .item').css('width',width);
  // }
}

truncateItemText = function (block_name) {
  var title_selector = block_name ? '#'+block_name+' .item_title' : '.item_title';
  var desc_selector = block_name ? '#'+block_name+' .item_description' : '.item_description';
  initTrunk8Faded(title_selector, true, 1);
  jQuery(title_selector).removeClass('visibility_hidden');
  initTrunk8Faded(desc_selector, false, 3);
  jQuery(desc_selector).removeClass('visibility_hidden');
}

show_login_popup = function (obj) {
  if(jQuery("#item_details_lightbox").is(":visible"))
    jQuery("#item_details_lightbox").modal('hide');
  obj.show_login_popup();
}

show_no_items_txt = function (items, id) {
  if(items.length == 0) {
    jQuery("#"+id+" #no_items").removeClass('hidden');
  } else {
    jQuery("#"+id+" #no_items").addClass('hidden');
  }
}

filter_callback = function (res, id) {
  show_no_items_txt(res, id);
  setTimeout(function(){
    truncateItemText(id);
  }, 500);
}

resize_login_modal = function() {
  var login_modal_width = $('.modal-body').width()/1.84;
  $("#login_modal_body").css('min-height',login_modal_width+'px')
}

groups_per_page = function () {
  return 12;
}

loadIframeAPI =  function () {
  loadYoutubeAPI()  
  loadVimeoAPI()
}

loadYoutubeAPI = function () {
  var tag = document.createElement('script');
  tag.src = "//www.youtube.com/player_api";
  var firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

loadVimeoAPI = function () {
  var tag = document.createElement('script');
  tag.src = "//player.vimeo.com/api/player.js";
  var firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

onYouTubePlayerAPIReady = function (iFrameID, bannerID) {
  const player = new YT.Player(iFrameID, {});
  var unmute_button = document.getElementById("unmute_video_"+bannerID);
  unmute_button.addEventListener("click", function() {
    player.unMute();
  });
  var mute_button = document.getElementById("mute_video_"+bannerID);
  mute_button.addEventListener("click", function() {
    player.mute();
  });
  // player.getDuration()
}

onVimeoPlayerAPIReady = function (iFrameID, bannerID) {
  const iframe = document.getElementById(iFrameID);
  const player = new Vimeo.Player(iframe);

  var unmute_button = document.getElementById("unmute_video_"+bannerID);
  unmute_button.addEventListener("click", function() {
    player.setMuted(false);
  });

  var mute_button = document.getElementById("mute_video_"+bannerID);
  mute_button.addEventListener("click", function() {
    player.setMuted(true);
  });

  player.getDuration().then(function(duration) {
    $('#banner_id_'+bannerID).attr('data-interval', duration*1000)
  });

}

ShowMore = function(id) {
  jQuery('#'+id).click()
}
// $("#carouselImageVideo").carousel();

