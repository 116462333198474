/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import Vue from 'vue/dist/vue.esm'
import VueRouter from "vue-router"
import VueResource from 'vue-resource'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import('stylesheets/application.sass');
import '../components/item_components.js'
import '../components/events_components.js'
import '../components/announcements_components.js'
import '../components/post_components.js'
import '../components/login_components.js'
import '../components/groups_components.js'
import 'packs/jquery.nicescroll.js'
import 'packs/jquery.slimscroll.min.js'
import '../components/layout_components.js'
import '../components/catalog_components.js'
import 'packs/app.js'
import 'packs/common_helper.js'
import Layout1 from "../layouts/Layout1.vue";
import Layout2 from "../layouts/Layout2.vue";
import FeedbackLayout from "../layouts/FeedbackLayout.vue";
import StatusLayout from "../layouts/StatusLayout.vue";
import template1 from "../layouts/LayoutTemplate1.vue";
import template2 from "../layouts/LayoutTemplate2.vue";
import template3 from "../layouts/LayoutTemplate3.vue";
import AdminLayout from "../layouts/AdminLayout.vue";
import { i18n } from '../i18n/lang.js'
import "@fortawesome/fontawesome-pro/js/all";
import 'packs/app_main.js'
import '../components/create_course_components.js'
import infiniteScroll from 'packs/vue-infinite-scroll.js'
import bFormSlider from 'vue-bootstrap-slider'
import 'bootstrap-slider/dist/css/bootstrap-slider.css'
import "../stylesheets/star-rating.min.css"
import '../components/cart_components.js'
import '../components/testimonial_components.js'
import '../components/playlist_components.js'
import VueSimpleAccordion from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';
import 'packs/bootstrap-typeahead.js'
import VeeValidate from 'vee-validate';
import { store } from "../vuex_store.js"
import '../components/inbox_components.js'
import '../components/create_bundle_components.js'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueApexCharts from 'vue-apexcharts'
import ToggleButton from 'vue-js-toggle-button'
import Print from 'vue-print-nb'
import VModal from 'vue-js-modal'
import PopUpMessage from '../components/pop_up/Message.vue'

Vue.use(VeeValidate);
Vue.use(VueResource)
Vue.use(infiniteScroll)
Vue.use(bFormSlider)
Vue.use(VueSimpleAccordion);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.use(ToggleButton)
Vue.use(Print);
Vue.use(VModal, {dialog: true})

const routes = [];
export const bus = new Vue();

const router = new VueRouter({
  mode: 'history',
  routes
});

const EventBus = new Vue();

document.addEventListener('DOMContentLoaded', () => {
  var dataMixin = {
    data: function () {
      return {
        logged_in: false,
        site_admin: false,
        show_cart: false,
        my_learning: false,
        display_playlist: false,
        active_enrolled_content: '',
        total_announcement_count: 0,
        show_lightbox: true,
        show_course_details: false,
        enable_google_sso: false,
        encryted_company_id: '',
        show_blog_items: false,
        show_content_player: false,
        timedout: null,
        total_cart_count: 0,
        clickToClose: true,
        show_group: false,
        get_footer_details_url: '',
        save_footer_details_url: '',
        get_header_details_url: '',
        save_header_details_url: '',
        users_url: '',
        others_url: '',
        report_url: '',
        home_url: '',
        super_site: false,
        language: 'en',
        header_details: [],
        payment_status: false
      }
    },
    mounted: function(){
      this.$http.get('/home/new.json').then(function(response) {
        this.logged_in = response.body.logged_in;
        this.site_admin = response.body.site_admin;
        this.current_id = response.body.current_id;
        this.layout = response.body.current_layout;
        this.current_components = response.body.current_components;
        this.components = this.baseComponents();
        this.report_url = response.data.report_url;
        this.users_url = response.data.users_url;
        this.others_url = response.data.others_url;
        this.home_url = response.body.home_url;
        this.achievement_url = response.data.achievement_url;
        this.announcement_url = response.data.announcement_url;
        this.total_announcement_count = response.body.total_announcement_count;
        this.enable_google_sso = response.body.enable_google_sso;
        this.encryted_company_id = response.body.encryted_company_id;
        this.timedout = response.body.timedout
        this.total_cart_count = response.data.total_cart_count
        this.get_footer_details_url = response.body.get_footer_details_url
        this.save_footer_details_url = response.body.save_footer_details_url
        this.get_header_details_url = response.body.get_header_details_url
        this.save_header_details_url = response.body.save_header_details_url
        this.super_site = response.body.super_site
        this.language = response.body.language
        this.$root.$i18n.locale = this.language
        this.header_details = response.body.header_details;
        if (!this.payment_status) {
          this.payment_status = response.body.payment_status
          EventBus.$emit('update_content', [this.my_learning,this.show_course_details,this.show_blog_items,this.show_cart,this.show_group,this.payment_status])
        }
      });
    },
    methods: {
      baseComponents:function() {
        var temp_components = []
        for (var i = 0; i < this.current_components.length; i++) {
          temp_components[i] = {name: this.current_components[i], properties:{logged_in: this.logged_in, show_cart:this.show_cart,show_group: this.show_group, display_playlist: this.display_playlist, active_enrolled_content: this.active_enrolled_content, site_admin: this.site_admin}};
        }
        return temp_components;
      },
      popUpMessage: function (text) {
        this.$modal.show( PopUpMessage,
          { text: text },
          { clickToClose: this.$root.clickToClose},
        )
      },
      HeaderDetails: function(data) {
        EventBus.$emit('ChangeHeader', data);
      }
    },
    watch: {
      show_cart: function (val) {
        this.components = this.baseComponents();
      },
      display_playlist: function() {
        this.components = this.baseComponents();
      },
      active_enrolled_content: function() {
        this.components = this.baseComponents();
      },
      timedout: function () {
        if (this.timedout && !($('#login_modal').hasClass('show'))) {
          jQuery('#user_sign_in').click();
        }
      }
    }
  }

  new Vue({
    router,
    i18n,
    mixins: [dataMixin],
    el: '#site_header',
    data: function () {
      return {
        components: [{'properties': {"show_cart": this.show_cart, "achievement_url": this.achievement_url}}]
      }
    },
    mounted: function () {
      EventBus.$on('CourseDetails', (data) => {
        this.show_course_details = data
      });
      EventBus.$on('ToggleGroup', (data) => {
        this.show_group = data
      });
      EventBus.$on('ToggleBlog', (data) => {
        this.show_blog_items = data
      });
      EventBus.$on('update_header',(data) => {
        this.my_learning = data[0];
        this.show_course_details = data[1];
        this.show_blog_items = data[2];
        this.show_cart = data[3];
        this.show_group = data[4];
        this.payment_status = data[5];
      });
    },
    methods: {
      content_update: function(id='') {
        EventBus.$emit('update_content', [this.my_learning,this.show_course_details,this.show_blog_items,this.show_cart,this.show_group,this.payment_status])
        EventBus.$emit('update_header', [this.my_learning,this.show_course_details,this.show_blog_items,this.show_cart,this.show_group,this.payment_status])
        if (id != '') {
          this.$nextTick(() => {
            if(!$("#navbar").hasClass("sticky_header")){
              var navbar = document.getElementById("navbar");
              navbar.classList.add("sticky_header");
            } 
            EventBus.$emit('ScrollToView', id);
          });
        }
      }
    }
  }).$mount()

  new Vue({
    router,
    i18n,
    el: '#siteFooter',
    data: function () {
      return {
      }
    }
  }).$mount()

  const app = new Vue({
    router,
    i18n,
    mixins: [dataMixin],
    components: {
      Layout1,
      Layout2,
      AdminLayout,
      FeedbackLayout,
      StatusLayout
    },
    el: '#site_content',
    store,
    data: function () {
      return {
        layout:"Layout1",
        current_components: [],
        feedback_layout: {'name': "FeedbackLayout", 'components': [{'name': 'testimonial'}], 'properties': {'site_admin': this.site_admin, 'logged_in': this.logged_in}},
        admin_layout: {'name': "AdminLayout","properties": {"logged_in": this.logged_in, "show_cart":this.show_cart, "site_admin": this.site_admin}},
        status_layout: {'name': "StatusLayout", 'components': [{'name': 'payment-status'}], 'properties': {'site_admin': this.site_admin, 'logged_in': this.logged_in}},
        preview: false,
        components: [],
        admin_components: this.admin_component_data()
      }
    },
    mounted: function() {
      EventBus.$on('MyLearningView', (data) => {
        this.my_learning = data;
        if(this.my_learning == false){
          this.show_course_details = false;
          this.show_blog_items = false;
        }
      }); 
      EventBus.$on('canSeeBlog', (data1, data2) => {
        this.show_course_details = data2
        this.my_learning = data1;
        this.show_blog_items = data1;
      });
      EventBus.$on('toogleCourseDetails', (data) => {
        this.show_course_details = data
      });
      EventBus.$on('ToggleCart', (data) => {
        this.show_cart = data
      });
      EventBus.$on('ToggleGroup', (data) => {
        this.show_group = data
      });
      EventBus.$on('CourseDetails', (data) => {
        this.show_course_details = data
      })
      EventBus.$on('update_content',(data) => {
        this.my_learning = data[0];
        this.show_course_details = data[1];
        this.show_blog_items = data[2];
        this.show_cart = data[3];
        this.show_group = data[4];
        this.payment_status = data[5];
      });
      EventBus.$on('ChangeHeader', (data) => {
        this.header_details = data
      });
      this.$store.commit('update_defaults', {all_items: this, invite_disabled: false, social_disabled: false,
      username_autocomplete_url: ''});         
    },
    watch: {
      logged_in: function (val) {
        this.admin_layout = {'name': "AdminLayout","properties": {"logged_in": this.logged_in, "show_cart":this.show_cart, "site_admin": this.site_admin}}
        this.feedback_layout = {'name': "FeedbackLayout", 'components': [{'name': 'testimonial'}], 'properties': {'site_admin': this.site_admin, 'logged_in': this.logged_in}}
        this.status_layout = {'name': "StatusLayout", 'components': [{'name': 'payment-status'}], 'properties': {'site_admin': this.site_admin, 'logged_in': this.logged_in}},
        this.admin_components = this.admin_component_data()
      },
      show_cart: function (val) {
        this.admin_layout = {'name': "AdminLayout","properties": {"logged_in": this.logged_in, "show_cart":this.show_cart, "site_admin": this.site_admin}}
        this.admin_components = this.admin_component_data()
      },
      site_admin() {
        this.admin_layout = {'name': "AdminLayout","properties": {"logged_in": this.logged_in, "show_cart":this.show_cart, "site_admin": this.site_admin}}
        this.feedback_layout = {'name': "FeedbackLayout", 'components': [{'name': 'testimonial'}], 'properties': {'site_admin': this.site_admin, 'logged_in': this.logged_in}}
        this.status_layout = {'name': "StatusLayout", 'components': [{'name': 'payment-status'}], 'properties': {'site_admin': this.site_admin, 'logged_in': this.logged_in}}
        this.admin_components = this.admin_component_data() 
      },
      // show_course_details: function() {
      //   EventBus.$emit('CourseDetails', this.show_course_details)
      // },
      // show_group: function() {
      //   EventBus.$emit('ToggleGroup', this.show_group)
      // },
      // show_blog_items: function() {
      //   EventBus.$emit('ToggleBlog', this.show_blog_items)
      // }
    },
    methods: {
      admin_component_data: function() {
        return [
          {'name': 'store-design', 'properties': { "logged_in": this.logged_in, "show_cart":this.show_cart, "layout":this.layout,"components":this.components}},
          {'name': 'admin-quick-access', 'properties': { "logged_in": this.logged_in, "show_cart": this.show_cart, "site_admin": this.site_admin}},
          {'name': 'new-cart-list', 'properties': { "logged_in": this.logged_in, "show_cart":this.show_cart}},
          {'name': 'inbox-layout', 'properties': {"logged_in": this.logged_in}},
          {'name': 'approved-testimonial', 'properties': {"logged_in": this.logged_in, "show_cart":this.show_cart}},
          {'name': 'user-profile-view', 'properties': {"logged_in": this.logged_in, "show_cart":this.show_cart}},
          {'name': 'banner-uploads', 'properties': {"logged_in": this.logged_in}}
        ]
      },
      component_data: function() {
        return [
          {'name': 'banner-layout', 'properties': { "logged_in": this.logged_in, "show_cart":this.show_cart, 'site_admin': this.site_admin }}, 
          {'name': "MyLearning-layout", "properties": { "logged_in": this.logged_in, "show_cart":this.show_cart }}, 
          {'name': 'Announcement-layout', 'properties': { "logged_in": this.logged_in, "show_cart":this.show_cart }}, 
          {'name': 'Quick-access', 'properties': { "logged_in": this.logged_in, "show_cart":this.show_cart, "site_admin": this.site_admin }},
          // {'name': 'Event-layout', 'properties': { "logged_in": this.logged_in, "show_cart":this.show_cart }}, 
          {'name': 'blog-post-list', 'properties': { "logged_in": this.logged_in, 'site_admin': this.site_admin }}, 
          {'name': 'catalog-layout', 'properties': { "logged_in": this.logged_in, "show_cart":this.show_cart } }, 
          'footer-layout', 
          {'name': 'new-cart-list', 'properties': { "logged_in": this.logged_in, "show_cart":this.show_cart}},
          {'name': 'inbox-layout', 'properties': {"logged_in": this.logged_in}},
          {'name': 'content-player', 'properties': {"logged_in": this.logged_in}},
          {'name': 'banner-uploads', 'properties': {"logged_in": this.logged_in}},
          {'name': 'approved-testimonial', 'properties': {"logged_in": this.logged_in, "show_cart":this.show_cart,"site_admin": this.site_admin}},
          {'name': 'user-profile-view', 'properties': {"logged_in": this.logged_in}},
          {'name': 'groups-layout', 'properties': {"logged_in": this.logged_in}}
        ]
      },
      header_update: function() {
        EventBus.$emit('update_header', [this.my_learning,this.show_course_details,this.show_blog_items,this.show_cart,this.show_group])
        EventBus.$emit('update_content', [this.my_learning,this.show_course_details,this.show_blog_items,this.show_cart,this.show_group])
      }
    }
  }).$mount()

Vue.component('template-store',{
  template: `<div>
              <div class="alert alert-success d-none" id ="msg"><p>Layout template saved successfully</p></div>
              <form id="layoutForm">
                <div class="row">
                	<div class="col-sm-4">
                    <div>
                  		<input type="radio" id="temp1" name="layoutTemps" value="template1" v-model ="layout" @click="highlightTemplate($event)">
                   		<label for="temp1">Select</label><br>
                    </div>
                    <div class="layout_template1">
                      <div class ="holder1" v-bind:class="[selectedTemplate=='template1' ? 'redHolder':'deselectHolder']"></div>
                  		<div class ="holder2 inlineFloat" v-bind:class="[selectedTemplate=='template1' ? 'blueHolder':'deselectHolder']"></div>
                  		<div class ="holder2 inlineFloat" v-bind:class="[selectedTemplate=='template1' ? 'yellowHolder':'deselectHolder']"></div>
                  		<div class ="holder1 inlineFloat" v-bind:class="[selectedTemplate=='template1' ? 'orangeHolder':'deselectHolder']"></div>
                      <div class ="holder1 inlineFloat" v-bind:class="[selectedTemplate=='template1' ? 'magentaHolder':'deselectHolder']"></div>
                  		<div class ="holder1 inlineFloat" v-bind:class="[selectedTemplate=='template1' ? 'greenHolder':'deselectHolder']"></div>
                    </div>
                    <a v-on:click="showPreview">Click to see how it looks like</a>
                    </div>
                	<div class="col-sm-4">
                    <div>
                  		<input type="radio" id="temp2" name="layoutTemps" value="template2"  v-model ="layout" @click="highlightTemplate($event)">
                  		<label for="temp2">Select</label><br>
                    </div>
                    <div class="layout_template2">
                      <div class ="holder1" v-bind:class="[selectedTemplate=='template2' ? 'orangeHolder':'deselectHolder']"></div>
                      <div class ="holder1" v-bind:class="[selectedTemplate=='template2' ? 'magentaHolder':'deselectHolder']"></div>
                      <div class ="holder1 inlineFloat" v-bind:class="[selectedTemplate=='template2' ? 'yellowHolder':'deselectHolder']"></div>
                      <div class ="holder1 inlineFloat" v-bind:class="[selectedTemplate=='template2' ? 'greenHolder':'deselectHolder']"></div>
                      <div class ="holder1 inlineFloat" v-bind:class="[selectedTemplate=='template2' ? 'redHolder':'deselectHolder']"></div>
                    </div>
                		<a v-on:click="showPreview">Click to see how it looks like</a>
                		</div>
                	<div class="col-sm-4">
                    <div>
                      <input type="radio" id="temp3" name="layoutTemps" value="template3"  v-model ="layout" @click="highlightTemplate($event)">
                  		<label for="temp3">Select</label><br>
                    </div>
                    <div class="layout_template3">
                      <div class ="holder1 inlineFloat" v-bind:class="[selectedTemplate=='template3' ? 'greenHolder' : 'deselectHolder']"></div>
                      <div class ="holder1 inlineFloat" v-bind:class="[selectedTemplate=='template3' ? 'magentaHolder':'deselectHolder']"></div>
                      <div class ="holder3 inlineFloat" v-bind:class="[selectedTemplate=='template3' ? 'redHolder':'deselectHolder']"></div>
                      <div class ="holder4 inlineFloat" v-bind:class="[selectedTemplate=='template3' ? 'yellowHolder':'deselectHolder']"></div>
                      <div class ="holder3 inlineFloat" v-bind:class="[selectedTemplate=='template3' ? 'blueHolder':'deselectHolder']"></div>
                      <div class ="holder4 inlineFloat" v-bind:class="[selectedTemplate=='template3' ? 'aquaHolder':'deselectHolder']"></div>
                      <div class ="holder1 inlineFloat" v-bind:class="[selectedTemplate=='template3' ? 'orangeHolder':'deselectHolder']"></div>
                    </div>
                		<a v-on:click="showPreview">Click to see how it looks like</a>
                		</div>
                	</div>
                	<div class="float-right">
                	  <a @click="closeTemplates">CANCEL</a>
                    <button type="submit" @click.prevent ="saveTemplate" class="btn btn-primary bg-danger">SAVE</button>
                	</div>
                </form>
                <div class ="preview balloon_popup" style="display:none">
                  <div class="row">
                    <div class="col-sm-11"><p> Preview </p></div>
                    <div class="col-sm-1"><a v-on:click="closePreview"> Close X</a></div>
                  </div>
                  <div class="readOnly">
                    <div id="site_header">
                      <header-layout></header-layout>
                    </div>
                    <base-layout v-bind:layout="layout" :components="components" :show_cart="show_cart"></base-layout>
                    <div id="siteFooter">
                      <footer-layout></footer-layout>
                    </div>
                  </div>
                </div>
              </div>
              `,
  components:{
    template1,
    template2,
    template3
    },
  props:{
    layout: String,
    components: Array,
    show_cart: Boolean,
    showTemplates: Boolean
  },
  data:function() {
    return {
      base_components: ["banner-layout", "MyLearning-layout", "Announcement-layout", "Quick-access", "catalog-layout",  "blog-post-list", "new-cart-list", "inbox-layout", "content-player", "banner-uploads"],
      selectedTemplate: ""
    }
  },
  mounted: function(){
    this.selectedTemplate = this.layout;
  },
  methods: {
    highlightTemplate: function(event){
      this.selectedTemplate = event.target.value;
    },
    showPreview: function(){
      this.layout = jQuery('input[name=layoutTemps]:checked', '#layoutForm').val();
      jQuery(".preview").show();
    },
    closePreview: function(){
      jQuery(".preview").hide();
    },
    closeTemplates: function(){
      this.$parent.showTemplates = false;
    },
    saveTemplate: function(){
      this.layout = jQuery('input[name=layoutTemps]:checked', '#layoutForm').val();
      if (this.layout == "template1"){
        this.base_components = ["banner-layout", "MyLearning-layout", "Announcement-layout", "Quick-access", "catalog-layout", "blog-post-list", "new-cart-list", "inbox-layout", "content-player", "banner-uploads", "approved-testimonial","user-profile-view","groups-layout"]
      }
      jQuery.ajax({
        url: "companies/" +this.current_id+ "/store_layout",
        data: { layout: this.layout, components: this.base_components},
        dataType: "json",
        method: "put",
        success: function(data) {
          jQuery('#msg').html(data).fadeIn('slow');
          jQuery('#msg').removeClass('d-none');
          jQuery('#msg').delay(5000).fadeOut('slow');
        }
      });
    }
  }
});

Vue.component('base-layout',{
  template: `<div v-if="!show_course_details">
              <div v-if="!show_blog_items&&!show_group">
                <div :components="components" :layout="layout" :show_cart="show_cart">
                  <component :is="layout">
                    <template v-slot:component1 v-if ="!show_cart">
                    <component :is="components[0].name" v-bind="components[0].properties">
                       <router-view :layout.sync = "components[0]"></router-view>
                      </component>
                    </template>
                    <template v-slot:component2 v-if ="!show_cart">
                      <component :is="components[1].name" v-bind="components[1].properties">
                       <router-view :layout.sync = "components[1]"></router-view>
                      </component>
                    </template>
                    <template v-slot:component3 v-if ="!show_cart">
                      <component :is="components[2].name" v-bind="components[2].properties">
                       <router-view :layout.sync = "components[2]"></router-view>
                      </component>
                    </template>
                    <template v-slot:component4 v-if ="!show_cart">
                      <component :is="components[3].name" v-bind="components[3].properties">
                       <router-view :layout.sync = "components[3]"></router-view>
                      </component>
                    </template>
                    <template v-slot:component5 v-if ="!show_cart">
                      <component :is="components[4].name" v-bind="components[4].properties">
                       <router-view :layout.sync = "components[4]"></router-view>
                      </component>
                    </template>
                    <template v-slot:component6 v-if ="!show_cart">
                      <component :is="components[5].name" v-bind="components[5].properties">
                       <router-view :layout.sync = "components[5]"></router-view>
                      </component>
                    </template>
                    <template v-slot:component7 v-if ="!show_cart">
                      <component :is="components[6].name" v-bind="components[6].properties">
                       <router-view :layout.sync = "components[6]"></router-view>
                      </component>
                    </template>
                    <template v-slot:cart_component v-if ="show_cart">
                      <component :is="components[6].name" v-bind="components[6].properties">
                       <router-view :layout.sync = "components[6]"></router-view>
                      </component>
                    </template>
                    <template v-slot:inbox_component>
                      <component :is="components[8].name" v-bind="components[8].properties">
                       <router-view :layout.sync = "components[8]"></router-view>
                      </component>
                    </template>
                    
                    <template v-slot:banner_upload_component>
                      <component :is="components[9].name" v-bind="components[9].properties">
                       <router-view :layout.sync = "components[9]"></router-view>
                      </component>
                    </template>
                    <template v-slot:testimonial_component v-if ="!show_cart">
                      <component :is="components[10].name" v-bind="components[10].properties">
                       <router-view :layout.sync = "components[10]"></router-view>
                      </component>
                    </template>  
                    <template v-slot:user_profile_view_component v-if ="!show_cart">
                      <component :is="components[11].name" v-bind="components[11].properties">
                       <router-view :layout.sync = "components[11]"></router-view>
                      </component>
                    </template>
                  </component>
                </div>
                <div v-if="show_all_announcements">
                  <all-announcement-component></all-announcement-component>
                </div>
                <div v-if="show_new_form">
                  <div class="modal show" id="new_announcement" role="dialog" aria-labelledby="new_announcement_label" aria-hidden="true" tabindex="-1" data-backdrop="static" data-keyboard="false">
                    <div class="modal-dialog modal-xl" role="document">
                      <div class="modal-content">
                        <p class="light_box_close" v-on:click="closeNewAnnouncementModel()"> <i class="fad fa-times"></i></p>
                        <div class="modal-body">              
                          <new-announcement-form :update_announcement_url="update_announcement_url" :create="create" :edit_product="edit_product" :available_items="available_items" :selected_items="selected_items" :show_new_form="show_new_form" :create_announcement_url="create_announcement_url" :audience_id="audience_id" :audience_type="audience_type"> </new-announcement-form>
                        </div>
                      </div>
                    </div>
                  </div> 
                </div>
                <span id="collect_testimonial" data-toggle="modal" data-target="#collect_testimonial_modal" data-backdrop="static" data-keyboard="false"></span>
              </div>
              <div v-else-if="show_blog_items">
                <blog-post-list :logged_in="logged_in" :site_admin="site_admin"></blog-post-list>
              </div>
              <div v-else>
                <div :components="components" :layout="layout" :show_cart="show_cart">
                  <component :is="layout">
                    <template v-slot:group_component v-if ="!show_cart">
                      <component :is="components[12].name" v-bind="components[12].properties">
                       <router-view :layout.sync = "components[12]"></router-view>
                      </component>
                    </template>
                  </component>
                </div>    
              </div>
            </div>
            <div v-else class="row">
              <div class="row col-12 m-0">
                <item-tile-details-header v-if="!show_cart"></item-tile-details-header>
                <item-tile-details-lightbox :show_course_details="show_course_details" :logged_in="logged_in" :link_name="link_name" :show_cart="show_cart" :launch_item="launch_item" v-if="!show_cart"></item-tile-details-lightbox>
                <new-cart-list :logged_in="logged_in" :show_cart="show_cart" v-if="show_cart"> </new-cart-list>
              </div>
            </div>`,
  components:
  {
    template1,
    template2,
    template3,
    Layout1
  },
  props: {
    layout: String,
    show_cart: Boolean,
    logged_in: Boolean,
    show_course_details: Boolean,
    components: Array,
    display_playlist: Boolean,
    active_enrolled_content: String,
    show_blog_items: Boolean,
    show_content_player: Boolean,
    site_admin: Boolean,
    link_name: Number,
    show_group: Boolean
  },
  data: function() {
    return {
      launch_item: false,
      show_all_announcements: false,
      show_new_form: false,
      update_announcement_url: '',
      create: true,
      edit_product: false,
      show_announcement: false,
      available_items: [],
      selected_items: [],
      create_announcement_url: '',
      audience_id: '',
      audience_type: '',
    }
  },
  watch: {
    show_course_details: function() {
      if (!this.show_course_details) {
        this.launch_item = false
      }
    }
  },
  mounted() {
    $('#collect_testimonial').click();
    EventBus.$on('ScrollToView', (data) => {
      this.$nextTick(() => {
        const element = document.getElementById(data);
        element.scrollIntoView();
      });
    });
  },
  methods: {
    ShowAll: function() {
      this.show_all_announcements = true
    },
    closeNewAnnouncementModel: function() {
      $("#new_announcement").modal('hide');
      this.show_new_form = false;
      jQuery('.modal-backdrop').remove();
      jQuery('body').removeClass('modal-open');      
    },
  }
});

  console.log(app)
})
