import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
  	header_container: {},
    all_items_container: {},
   	invite_disabled: false,
    social_disabled: false,
    username_autocomplete_url: null
  },
  mutations: {
    update_defaults: function(state, obj) {
      state.all_items_container = obj.all_items;
      state.invite_disabled = obj.invite_disabled;
      state.social_disabled = obj.social_disabled;
      state.username_autocomplete_url = obj.username_autocomplete_url;
    },
    update_header_container: function(state, obj) {
      state.header_container = obj;
    }
  }
});