import Vue from 'vue/dist/vue.esm'
import moment from 'moment';

Vue.component('all-playlist', {
	template: `<div class="modal fade" id="contentPlaylist">
						  <div class="modal-dialog modal-dialog-centered _modal-dialog-scrollable" :class="existing_playlist? 'modal-md':''" role="document">
						    <div class="modal-content lxa_border_radius primary_bgcolor" :class="existing_playlist? 'padding_30':'my-4'">
									<p class="cursor_pointer light_box_close" data-dismiss="modal"> <i data-toggle="tooltip" data-placement="top" title="Close" class="fad fa-times lxa_text_color_08 lxa_font_size_06 cursor_pointer"></i></p>
						      <div class="modal-body p-0">
						        <div class="choosing_playlist my-4 d-flex align-items-center justify-content-center flex-nowrap" v-if="!create_new_playlist && !existing_playlist">
						        	<button class="btn lxa_bg_07 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase mr-3" @click="toggleNewPlaylist()"> Create New Playlist </button>
						        	<button class="btn lxa_bg_08 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" @click="toggleExistingPlaylist()" v-if="user_playlists.length > 0"> Add to Existing Playlist </button>
						        </div>
										<div class="col-12 p-0 pt-2 pb-2 lxa_font_size_04" v-if="display_message != ''">
											<span :class="messageColor"> {{ display_message }} </span>
										</div>
						        <div class="create_new_playlist" v-if="create_new_playlist">
											<div class="user_new_playlist p-4">
												<h4 class="lxa_font_size_08 lxa_text_color_07 mb-3 lxa_light_font"> Create New Playlist</h4>
												<div class="form_group mb-3 lxa_font_size_03">
													<label> Name of the Playlist  </label>
													<input class="playlist_name lxa_border_radius lxa_font_size_03 p-3" type="text" ref="NewPlaylistName"/>
												</div>
												<div class="form_group d-flex align-items-center justify-content-end flex-nowrap my-3">
													<button class="btn lxa_bg_01 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase mr-3" @click="toggleNewPlaylist()"> Cancel </button>
													<button class="btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" @click.prevent="createPlaylist()"> Add </button>
												</div>
											</div>
						        </div>
						        <div class="existing_playlist" v-if="existing_playlist">
						        	<div v-if="user_playlists.length !=0">
												<div class="row m-0">
													<div class="col-md-8 p-0"><h5 class="mb-3 lxa_font_size_08 lxa_text_color_04 lxa_light_font"> Add to Existing Playlist </h5> </div>
													<div class="col-md-4 p-0 float_right" id="playlist_sort">
														<div v-click-outside="sortClickOutside" @click.prevent="show_sort = !show_sort" class="hover float_right lxa_font_size_03 lxa_plylist_sortby lxa_border_radius lxa_bg_05 p-2">
														<span class="mr-2" >Sort By</span>
				                    <span :key="'lxa-sort-up'" class="pe-none" v-if="show_sort">
				                    <i class="fad fa-chevron-up lxa_sortby_icon lxa_font_size_04"></i>
				                    </span>
				                    <span :key="'lxa-sort-down'" class="pe-none" v-else>
				                    <i  class="fad fa-chevron-down lxa_sortby_icon lxa_font_size_04"></i>
				                    </span>
														</div>
														<ul class="lxa_bg_05 lxa_border_radius_tlblbr overflow-hidden" :class='[{"hide": !show_sort},"sorting_list position-absolute lxa_plylist_sortby_list", "hover", "float_right"]'>
															<li v-for="item in sorting_option" :id="Object.keys(item)[0]" @click.prevent="selected_sort" class="px-3 py-2 lxa_font_size_04 text-uppercase">
															{{ Object.values(item)[0] }}
														</li>
														</ul>
													</div>
												</div>
												<div class="row mx-0">
												<div class="col-12 px-0">
												<div class="lxa_existing_playlist_scroll">
						        		<ol class="mb-2">
						        			<li class="py-3 lxa_font_size_04 lxa_common_border_b1" v-for="playlist in user_playlists">
						        				<span class="title lxa_light_font lxa_text_color_02">{{ playlist.title }} </span>
						        				<span class="float_right"> 
                        <div :id="playlist.id +'wrapper'" class="plylist_radio_wrapper custom-controls custom-radio d-flex align-items-center justify-content-start">
                          <input :id="playlist.id" name="existing_playlist" class="plylist_radio custom-control-input lxa-bs-radiobtn" type="radio" @click="selected_playlist_id = playlist.id">
                          <label class="custom-control-label" :for="playlist.id">
                            <i class="fad fa-circle  mr-1 lxa_icon_size lxa_text_color_01"></i>
                            <i class="fad fa-dot-circle  mr-1 lxa_icon_size lxa_text_color_05"></i>
                            <span></span>
                          </label>
                        </div>
						        				</span>
						        			</li>
						        		</ol>
						        		</div>
						        		</div>
						        		</div>
						        		<div class="row mx-0 mt-4">
						        		<div class="col-12 px-0">
						        		<div class="d-flex align-items-center justify-content-end">
						        			<button class="btn lxa_bg_01 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase mr-3" @click="toggleExistingPlaylist()"> Cancel </button>
													<button class="btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase" @click.prevent="addToPlaylist(selected_playlist_id)"> Add </button>
						        		</div>
						        		</div>
						        		</div>
	                    </div>
	                    <div v-else>
	                    	<h4 class="mb-2"> You have not created any Playlist. </h4>
	                    </div>
						        </div>
						      </div>
						    </div>
						  </div>
						</div>`,
	data: function() {
		return {
			user_playlists: Array,
			new_playlist_name: String,
			active_playlist: Object,
			sorting_option: [
        {'title_asc' : 'A to Z'},
        {'title_desc' : 'Z to A'},
        {'created_at_asc' : 'Date : Ascending'},
        {'created_at_desc' : 'Date : Decending'}
      ],
      show_sort: false,
      changes_made: false,
      display_message: '',
      create_new_playlist: false,
      existing_playlist: false,
      selected_playlist_id: null,
      message_status: 'success',
      active: 'created_at_desc'
		}
	},
	props: {
		active_enrolled_content: String,
		active_content: Object
	},
	mounted: function() {
		$('#playlist_sort li#created_at_desc').addClass('active')
  	//$('.existing_playlist ul li#created_at_desc').addClass('active')
  	var self = this;
  	jQuery('#contentPlaylist').on('hidden.bs.modal',function(){
      jQuery.extend(self.$data, self.$options.data.apply(self));
  	})
  	jQuery('#contentPlaylist').on('show.bs.modal',function(){
      self.loadPlaylist()
  	})
    setTimeout(function(){ 
      self.LoadScroll();
      // jQuery("#announcement_display").niceScroll({fixed:true,cursorcolor:"#4184F3",cursorwidth:"3px",autohidemode: "scroll", nativeparentscrolling: false});
    }, 20);
	},
	updated: function() {
    this.LoadScroll();
  },
  methods: {
  	LoadScroll: function() {
      jQuery(".lxa_existing_playlist_scroll").slimScroll({
        barClass:'lxascroll',
        size : '3px',
        width: '100%',
        height : '100%',
        position : 'right',
        start : 'top',
        alwaysVisible : false,
        distance: '0',
        borderRadius: '0',
        // railVisible: true,
        railBorderRadius:0,
        opacity:1,
        allowPageScroll: true,
        touchScrollStep:200,
        enableKeyNavigation: true
      });
    },
  	sortClickOutside: function(e) {
      if(jQuery(e.target).closest('#sortId').length < 1) {
        this.show_sort = false;
      }
    },
  	toggleExistingPlaylist() {
  		this.existing_playlist = !this.existing_playlist
  		this.selected_playlist_id = null
  		this.display_message = ''
  	},
  	toggleNewPlaylist() {
  		this.create_new_playlist = !this.create_new_playlist
  	},
  	loadPlaylist() {
  		this.$http.get('/get_user_playlist').then(function(response) {
	      this.user_playlists = response.body.playlists
	    });
  	},
  	createPlaylist() {
  		if (this.$refs.NewPlaylistName.value.trim() != "" ) {
	  		var data = {playlist_name: this.$refs.NewPlaylistName.value.trim()}
	  		this.$http.post('/create_playlist', data).then(function(response) {
		      this.user_playlists = response.body.playlists
		      // if (response.body.new_playlist == true) {
		      // 	this.addToPlaylist(response.body.playlist_id)
		      // }
	      	this.$root.popUpMessage(response.body.message)
	      	// this.display_message = response.body.message
	      	// this.message_status = response.body.message_status
		    });
		    this.$refs.NewPlaylistName.value = '';
  		} else {
  			// this.message_status = 'error'
  			this.$root.popUpMessage('Enter Valid Playlist Name')
  			// this.display_message = 'Enter Valid Playlist Name'
  		}
  		setTimeout(() => this.closePlaylist(), 2000);
  		this.closePlaylist();
  	},
  	closePlaylist() {
  		this.create_new_playlist = false
  		this.existing_playlist = false
  		this.selected_playlist_id = null
  		if (this.display_message != '') {
  			this.display_message = ''
  		} else {
  			$("#contentPlaylist .close").click();
  		}
  	},
  	addToPlaylist(playlist_id) {
  		if (playlist_id) {
	  		var data = {playlist_id: playlist_id, enrollment_id: this.active_enrolled_content, content_id: this.active_content.content_id}
	  		this.$http.post('/add_to_playlist', data).then(function(response) {
		    	// this.user_playlists = response.body.playlists
	  			// this.display_message = response.body.message
	  			// this.message_status = response.body.success
	  			this.$root.popUpMessage(response.body.message)
	  			setTimeout(() => this.closePlaylist() , 2000);
		    });
	  	} else {
	  		this.message_status = 'error'
	  		this.display_message = 'Select Playlist'
	  		this.$root.popUpMessage(response.body.message)
	  	}
  	},
  	delete_playlist(playlist_id) {
  		this.$modal.show('dialog', {
        text: 'Are you sure you want to remove this Playlist?',
        buttons: [{
            title: 'Cancel',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Yes',
            handler: () => {
              var data = {playlist_id: playlist_id}
				  		this.$http.post('/delete_playlist', data).then(function(response) {
					    	this.user_playlists = response.body.playlists
					    });
              this.$modal.hide('dialog')
            }
          }
        ]
      })
  	},
  	edit_playlist(playlist_id) {
  		this.active_playlist = this.user_playlists.find((pl) => pl.id == playlist_id)
  		this.loadPlaylist();
  	},
  	cancel_edit_changes() {
  		if (this.changes_made) {
	  		if(confirm("Are you sure you want to cancel the changes made to the Playlist?")) {
	  			this.active_playlist = {}
	  		}
  		} else {
	  			this.active_playlist = {}
  		}
  		this.loadPlaylist();
  	},
  	save_edit_changes() {
  		var data = {edited_playlist: this.active_playlist}
  		this.$http.post('/save_playlist_changes', data).then(function(response) {
  			this.user_playlists = response.body.playlists
  		});
  		this.active_playlist = {}
  		this.changes_made = false;
  	},
  	remove_playlist_content(content_id) {
  		this.changes_made = true
  		this.active_playlist.contents.find((ct) => ct.id == content_id).delete = true
  	},
  	selected_sort(e) {
  		var data = {sort_by: e.target.id}
  		//$('.existing_playlist ul li').removeClass('active')
      //$('.existing_playlist ul li#'+e.target.id).addClass('active')
  		this.$http.post('/sort_playlist', data).then(function(response) {
  			this.user_playlists = response.body.playlists
  		});
  	},
  	onHeadingClick(data) {
  		if (this.changes_made == true) {
  			if (confirm('Changes have been made to the playlist. Do you want to save the changes?')) {
  				this.save_edit_changes();
  			} else {
  				this.loadPlaylist();
  			}
  		}
    }
  },
  computed: {
  	messageColor() {
  		return this.message_status == 'success' ? 'lxa_text_color_05' : 'lxa_text_color_01'
  	}
  }
})

Vue.filter('formatDate', function(value) {
	if (value) {
		return moment(String(value)).format('MMM DD, YYYY')
	}
});



Vue.component('user-playlist', {
	template: `<div class="modal" id="user_playlist_modal" role="dialog">
                <div class="modal-xl">
                  <div class="modal-content user_account_setting user_playlist">
                    <div class="d-flex align-items-center justify-content-between">
                      <span class="account_setting_heading lxa_text_color_06 lxa_font_size_08 lxa_light_font"> Playlist </span>
												<sort-drop-down  :from_section="'playlist_items'" :show_sort="show_sort" :sorting_option="sorting_option" @selectCallBack="selected_sort" />
                    </div>
                    <p class="light_box_close" data-dismiss="modal"> <i data-toggle="tooltip" data-placement="top" title="Close" class="fad fa-times lxa_text_color_08 lxa_font_size_06 cursor_pointer"></i></p>
                    <div class="playlist_list mx-0 lxa_font_size_04 text_align_center">
                    	<div class="col-12 p-0" v-if="user_playlists.length !=0" >
												 <vsa-list>
												   <vsa-item v-for="(playlist,index) in user_playlists" :key="'playlist_'+index" :forceActive="active_playlist.id == playlist.id ? true : false" :on-heading-click="onHeadingClick">
												    	<vsa-heading> 
												     		<div class="row mx-0">
													     	<div class="col-12 px-0 text-left">
														       <span class="lxa_font_size_04 lxa_regular_font lxa_text_color_02"> {{ index + 1}}. {{ playlist.title }}</span>
														     </div>
													       <div class="col-12 px-0 pt-3 lxa_light_font lxa_font_size_03 lxa_text_color_03">
													       <div class="d-flex align-items-center justify-content-start">
												           <span class="pl-3 lxa_text_color_04"> Number of Playlist: {{ playlist.total_contents_count }} </span>
												           <span class="pl-4 lxa_text_color_07"> Created On: {{ playlist.created_at | formatDate}} </span> 
												           <span class="pl-4 lxa_text_color_05"> Modified On: {{ playlist.updated_at | formatDate}} </span>
												         </div>  
												         </div>
												        </div>
												    	</vsa-heading>
															<vsa-icon>
																<div class="row mx-0">
																<div class="col-12 px-0">
																<div class="d-flex align-items-center justify-content-start">
																	<!-- <span data-toggle="tooltip" data-placement="bottom" title="Edit" class="cursor_pointer" @click.prevent="edit_playlist(playlist.id)"> 
																		<i class="fad fa-pencil-alt lxa_text_color_05" title="Edit"></i> 
																	</span> -->
																	<span data-toggle="tooltip" data-placement="bottom" title="Delete" class="ml-3 cursor_pointer" @click.prevent="delete_playlist(playlist.id)"> <i class="fad fa-trash-alt lxa_text_color_01 lxa_font_size_05" title="Delete Playlist"></i> </span>
																	<span class="ml-3 close cursor_pointer lxa-accordion-arrow"> <i class="fad fa-angle-down lxa_text_color_06 lxa_font_size_08"></i> </span>
																	<span class="ml-3 open cursor_pointer lxa-accordion-arrow"> <i class="fad fa-angle-up lxa_text_color_06 lxa_font_size_08"></i>  </span>
																</div>
																</div>
																</div>
															</vsa-icon>
												    	<vsa-content>
												       	<div class="row mx-0">
												         	<div class="col-md-12 px-0">
												           	<div class="d-flex col-11 p-0 m-0" v-for="content in playlist.contents"> 
												           		<div class="flex-grow-1 p-0 mt-2">
												           		<div class="d-flex align-items-center justify-content-start">
										                   	<span> 
										                   		<i class="fad fa-video lxa_text_color_06" v-if="content.content_type == 'video'"></i>
										                   		<i class="fad fa-file-pdf lxa_text_color_01" v-else-if="content.content_type == 'document'"></i>
										                     	<i class="fad fa-file lxa_text_color_05" v-else></i> 
										                   	</span>
									                   		<span class="pl-2 lxa_text_color_02"> {{ content.content_title}} </span>
									                   		</div>
									                   	</div>
									                   	<div class="">
									                   		<div class="row mx-0">
									                   		<div class="col-12 px-0 mb-3">
									                   		<div class="d-flex align-items-center justify-content-start">
											                  	<span class="lxa_text_color_03 p-0"> Added On: {{ content.added_on | formatDate}} </span>
																					<progress-circle :playlist="playlist" :progress="content.percentage" :content="content"> </progress-circle>
											                  	<span class="p-0" @click="launchPlayer(playlist.id, content.id)" data-toggle="modal" data-target="#playlist_content_player_modal" data-backdrop="static" data-keyboard="false" style="max-height: 40px;"> 
											                   		<div class="btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase cursor_pointer"> 
											                   		<i class="fad fa-play-circle"></i> <span> Launch </span> 
											                   		</div>
											                  	</span>
											                  	<span :class="getPlaylistContent(playlist.id)" @click.prevent="remove_playlist_content(playlist.id, content.id)" style="display: none;">  
											                  		<i class="fad fa-trash-alt lxa_text_color_01" title="Delete Content"></i> 
											                  	</span>
											                  	</div>
											                  	</div>
											                  </div>
										                  </div>
									                 </div>
												         </div>
												       </div>
												     </vsa-content>
												   </vsa-item>
												 </vsa-list>
												</div>
												<div v-else>
        									<p>No Playlist To Display</p>
												</div>
                    </div>
                  </div>
                </div>
              </div>`,
  data() {
  	return {
  		user_playlists: [],
  		active_playlist: Object,
  		changes_made: false,
       sorting_option: [
        {id:1,title: 'A to Z', value: 'title_asc'},
        {id:2,title: 'Z to A', value: 'title_desc'},
        {id:3,title: 'Date : Ascending', value: 'created_at_asc'},
        {id:4,title:'Date : Decending', value: 'created_at_desc'}
      ],
      show_sort: false,
      sort_value: 'created_at_desc'
  	}
  },
  mounted() {
  	var self = this;
  	this.get_user_playlist()
    setTimeout(function(){ 
    	jQuery(".playlist_list").slimScroll({
        barClass:'lxascroll',
        size : '3px',
        height: '100%',
        width: '100%',
        position : 'right',
        start : 'top',
        alwaysVisible : false,
        distance: '0',
        borderRadius: '0',
        // railVisible: true,
        railBorderRadius:0,
        opacity:1,
        allowPageScroll: true,
        touchScrollStep:200,
        enableKeyNavigation: true
      });
    }, 20);
    jQuery('#user_playlist_modal').on('hidden.bs.modal',function() {
      self.$parent.showUserPlaylist = false;
    })
  },
  methods: {
  	getPlaylistContent(pl_id) {
  		return "p-0 ml-3 cursor_pointer pl_content pl_content_" + pl_id
  	},
  	get_user_playlist() {
  		this.user_playlists = []
  		this.$http.get('/get_user_playlist').then(function(response) {
	      this.user_playlists = response.body.playlists
	      this.user_playlists.forEach((value, index) => {
	      	if (value.contents.length == 0) {
	      		this.remove_empty_playlist(value.id)
	      	}
        });
	    });
  	},
  	edit_playlist(playlist_id) {
  		this.active_playlist = this.user_playlists.find((pl) => pl.id == playlist_id)
  		jQuery('.pl_content').css('display', 'none');
  		jQuery('.pl_content_'+playlist_id).css('display', 'block');
  	},
  	cancel_edit_changes() {
  		if (this.changes_made) {
	  		if(confirm("Are you sure you want to cancel the changes made to the Playlist?")) {
	  			this.active_playlist = {}
	  		}
  		} else {
	  			this.active_playlist = {}
  		}
  		this.loadPlaylist();
  	},
  	save_edit_changes() {
  		var data = {edited_playlist: this.active_playlist}
  		this.$http.post('/save_playlist_changes', data).then(function(response) {
	  		this.changes_made = false;
	  		this.get_user_playlist()
  		});
  	},
  	remove_playlist_content(pl_id, content_id) {
  		this.$modal.show('dialog', {
        text: 'Are you sure you want to remove this content from Playlist?',
        buttons: [{
            title: 'Cancel',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Yes',
            handler: () => {
              this.edit_playlist(pl_id)
				  		this.changes_made = true
				  		this.active_playlist.contents.find((ct) => ct.id == content_id).delete = true
				  		this.save_edit_changes()
              this.$modal.hide('dialog')
            }
          }
        ]
      })
  	},
  	selected_sort(value) {
  		this.sort_value = value
  		var data = {sort_by: this.sort_value}
  		this.user_playlists = []
  		this.$http.post('/sort_playlist', data).then(function(response) {
  			this.user_playlists = response.body.playlists
  			this.active_playlist = {}
  			this.edit_playlist(this.user_playlists[0].id)
  		});
  	},
  	onHeadingClick(data) {
  		if (this.changes_made == true) {
  			if (confirm('Changes have been made to the playlist. Do you want to save the changes?')) {
  				this.save_edit_changes();
  			} else {
  				this.get_user_playlist();
  			}
  		}
    },
    delete_playlist(playlist_id) {
    	this.$modal.show('dialog', {
        text: 'Are you sure you want to remove this Playlist?',
        buttons: [{
            title: 'Cancel',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Yes',
            handler: () => {
            	this.remove_empty_playlist(playlist_id)
              this.$modal.hide('dialog')
            }
          }
        ]
      })
  	},
  	remove_empty_playlist(pl_id) {
    	var data = {playlist_id: pl_id}
  		this.$http.post('/delete_playlist', data).then(function(response) {
	    	this.user_playlists = response.body.playlists
	    });
  	},
  	launchPlayer(playlist_id, content_id) {
  		var data = {playlist_id: playlist_id, content_id: content_id}
  		this.$root.$emit('launchContentPlayer', data);
  		jQuery('#user_playlist_modal').modal('hide');
  	}
  }
});

Vue.component('progress-circle', {
	template: `<span class="p-0" :id="'playlist_progress_'+playlist.id+'_'+content.id+'_'+id">
							<span class="play_progress mx-3" :id="'progress_percent_'+playlist.id+'_'+content.id+'_'+id">
								<span> </span>
							</span>
						</span>`,
	props: {
		progress: Number,
		playlist: Object,
		content: Object
	},
	data() {
		return {
			circular_progress: '',
			id: ''
		}
	},
	mounted() {
		this.id = this._uid
		this.$nextTick(function () {
			jQuery('#playlist_progress_'+this.playlist.id +'_'+this.content.id+'_'+this.id+' .play_progress').find('span').html(this.progress+'%');
			if(this.circular_progress == '')
        this.circular_progress = circle_animation("#progress_percent_"+this.playlist.id+'_'+this.content.id+'_'+this.id,'#52e471','#c5c5c5',7)
      this.circular_progress.animate(this.progress/100);
		});
	}
})