<template>
	<div class="instructions complete" id="'quiz_result_'+modal_id">
		<div v-if="item_type!='survey'" class="quiz_complete">
			<div class="heading font_open_sans_bold_18_black">Results</div>
			<div v-if="result_item.response_set_present" class="course_score font_open_sans_bold_18_black">
				Your score:&nbsp<span>{{ result_item.score }}</span>%.
			</div>
			<div v-if="!result_item.response_set_blank && result_item.course_or_certification_passed" class="margin_top_10 font_open_sans_bold_18_black" >
				You passed this quiz
			</div>
			<div v-if="!result_item.response_set_blank && !result_item.course_or_certification_passed" class="margin_top_10 font_open_sans_bold_18_black">
				You did not pass this quiz.
			</div>
			<div class="margin_top_10 font_open_sans_bold_18_black" v-if="result_item.response_set_blank"> 
				This quiz contains at least one essay question
				<br>
				which needs to be graded before your score can be determined.
				<br>
				<br>
				Once graded, your score will appear in your
				<br>
				My Courses or My Quizzes report.
			</div>
			<div class="margin_top_10" v-if="result_item.is_not_unlimited_quiz && !result_item.course_or_certification_passed">
				Your have&nbsp<span>{{result_item.attempts_left}}</span>&nbspattempts remaining.
			</div>
			<button v-if="!result_item.course_or_certification_passed && !result_item.response_set_blank && !result_item.attempts_reached" type="submit" id="retry_quiz" v-on:click="retryQuiz()" class="btn lxa_bg_02 lxa_btn_txt_01 lxa_border_radius lxa_font_size_04 text-uppercase cursor_pointer mt-3">Retry Quiz</button>
		</div>
		<div v-else class="quiz_complete">
			<div class="margin_top_10 font_open_sans_bold_18_black">Your survey is complete</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'QuizComplete',
		props: {
			result: Object,
			modal_id: String,
			item_type: String
		}, 
		data: function() {
			return {
				result_item: this.result.result
			}
		},
		methods: {
			retryQuiz: function() {
            	jQuery("#content_details_"+this.$parent.$parent.active_content.content_id).click();
			}
		}
	}
</script>