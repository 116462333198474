<template>
	<div id="content_wrapper" class="modal_content_height">
		<div class="content_player_viewer quiz_player_viewer height_100_percent" v-bind:id="'preview_content_'+question_item.question_id">
			<div v-if='quiz_complete && preview' class="instructions complete" id="'quiz_questionnaire_'+modal_id">
				<div class="quiz_complete">
					<div class="heading font_open_sans_bold_18_black">Results</div>
					<div class="score font_open_sans_bold_18_black">Score not available in Preview mode.</div>
				</div>
			</div>
			<quiz-complete-component v-if='quiz_complete && show_result' v-bind:result='result' :modal_id='modal_id' :item_type = 'active_item.content_type' />
			<div v-if='!quiz_complete' class="quiz_instruction">
				<div v-show='show_instruction' class="instruction_container">
					<div class="instruction_title font_open_sans_bold_18_black padding_top_10">{{$t('instruction.instructions')}}</div>
					<div class="quiz_instruction padding_top_10" v-html="course_instruction"></div>
				</div>
				<div v-show='start_quiz' class="h-100">
					<form v-bind:id="'question_submit_'+question_item.question_id" class="form-horizontal" v-on:submit.prevent="onSubmit" role="form">
						<div class="scrollbar_container">
							<div class="quiz_scrollbar_content">
								<div class='question font_open_sans_bold_18_black'>{{question_item.question_text}}</div>
								<fill-blanks-type v-if="question_item.question_type == 'fill_blanks'" v-bind:item="question_item.answers" :question_id="question_item.question_id" :response="response" :have_response="have_response":navigate_response="navigate_response"></fill-blanks-type>
								<hotspot-type v-if="question_item.question_type == 'hotspot'" v-bind:item="question_item.answers" :question_id="question_item.question_id" :hotspot_img="question_item.hotspot_img" :response="response" :have_response="have_response" :navigate_response="navigate_response"></hotspot-type>
								<multiline-type v-if="question_item.question_type == 'textarea'" v-bind:item="question_item.answers" :question_id="question_item.question_id" :response="response" :have_response="have_response" :navigate_response="navigate_response"></multiline-type>
								<multiple-choice-type v-if="question_item.question_type == 'checkbox'" v-for="(item,index) in question_item.answers" v-bind:item="item" :key="'question_'+item.id" :index="index" :numbering="question_item.numbering" :question_id="question_item.question_id" :response="response" :have_response="have_response" :navigate_response="navigate_response"></multiple-choice-type>
								<single-choice-type v-if="question_item.question_type == 'radiobutton' || question_item.question_type == 'yesno'" v-for="(item,index) in question_item.answers" v-bind:item="item" :key="'question_'+item.id" :index="index" :numbering="question_item.numbering" :question_id="question_item.question_id" :response="response" :have_response="have_response" :navigate_response="navigate_response"></single-choice-type>
							</div>
						</div>
						<hr>
						<div v-if="total_pages>1" class="font_open_sans_bold_11_black inline_block" style="margin-left: 40%;">
							<img  v-if="current_page != 1" src="/images/pagination_left_arrow.png" class="cursor_pointer" v-on:click="pageNavigate('prev')">
							<img  v-else src="/images/pagination_left_arrow_disabled.png"> 
							<input id="page_number" class="margin_left_10 input-sm form-control display_inline_block" v-model="current_page" readonly="readonly" style="width: 29px;height: 20px">  
							<span class="margin_left_10">of&nbsp{{total_pages}}</span>
							<img v-if="current_page != total_pages" src="/images/pagination_right_arrow.png" class="margin_left_10 cursor_pointer"  v-on:click="pageNavigate('next')">
							<img v-else class="margin_left_10" src="/images/pagination_right_arrow_disabled.png">
						</div> 
						<div v-if="!have_response" class="pl-0 pr-0 inline_block right_float"> 
							<div class="pull-right">
								<button type="submit" id="submit_course_quiz" v-bind:class="[current_page == total_pages ? 'lxa_title_light_01 btn btn-default btn-round-md pointer_event_all' : 'lxa_title_light_01 btn btn-default btn-round-md pointer_event_none']">{{$t('common.submit')}}</button>
							</div>
							<div class="pull-right padding_right_10 margin_top_4">
								<a id="cancel_comment" v-on:click='cancelCourse' class="cancel no_underline">{{$t('common.cancel')}}</a>
							</div>  
						</div>
					</form>
				</div>
			</div>
			<div v-show='show_instruction'>
				<hr class="col-12 border-0">
				<div class="row p-0 m-0">
					<div class="col-7 lxa_title_medium_bold text_align_right mt-2">{{$tc('instruction.question',no_of_questions, {count: no_of_questions})}}</div>
					<div class="text_align_right col-5">
						<button type="submit" v-on:click='show_instruction=false,start_quiz=true' class="lxa_title_light_01 btn btn-default btn-round-md save margin_bottom_3">{{$t('instruction.start')}}</button>
					</div>
				</div>
			</div>	
		</div>
	</div>
</template>

<script>
	import QuizComplete from './quiz/QuizComplete'
	import SingleChoiceType from './quiz/SingleChoiceType'
	import FillBlanksType from './quiz/FillBlanksType'
	import HotspotType from './quiz/HotspotType'
	import MultilineType from './quiz/MultilineType'
	import MultipleChoiceType from './quiz/MultipleChoiceType'

	export default {
		name: 'QuizContent',
		components: {
			'quiz-complete-component': QuizComplete,
			'single-choice-type': SingleChoiceType,
			'fill-blanks-type': FillBlanksType,
			'hotspot-type': HotspotType,
			'multiline-type': MultilineType,
			'multiple-choice-type': MultipleChoiceType,
		},
		props: {
			active_item: Object,
			quiz_obj: Object,
			modal_id: String,
			from_page: String,
			result_response: [],
			result_have_response: Boolean,
			preview: Boolean
		},
		data: function() { 
		    if ('responses' in this.quiz_obj) {
              var response = this.quiz_obj.responses;
			  var have_response = response.length > 0;
			} else {
			  var response = this.result_response;
			  var have_response = this.result_have_response;
			}
			return {
				current_page: this.quiz_obj.current_page,
				total_pages: this.quiz_obj.total_pages,
				question_item: this.quiz_obj.question[0],
				course_instruction: this.quiz_obj.associated_instruction,
				no_of_questions: this.quiz_obj.associated_questions,
				show_instruction: this.quiz_obj.associated_instruction ? true : false,
				start_quiz: this.quiz_obj.associated_instruction ? false : true,
				update_response_url: this.quiz_obj.update_response_url,
				quiz_complete: false,
				show_result: false,
				result: {},
				response: response,
				have_response: have_response,
				navigate_response: false,
				quiz_last_content: this.quiz_obj.is_last_content,
				// preview: this.from_page == 'preview'
			}
		},
		watch: {
		  quiz_obj: function() {
		    this.current_page = this.quiz_obj.current_page;
			this.total_pages = this.quiz_obj.total_pages;
			this.question_item = this.quiz_obj.question[0];
			this.course_instruction = this.quiz_obj.associated_instruction;
			this.no_of_questions = this.quiz_obj.associated_questions;
			this.show_instruction = this.quiz_obj.associated_instruction ? true : false;
			this.start_quiz = this.quiz_obj.associated_instruction ? false : true;
			this.update_response_url = this.quiz_obj.update_response_url;
			this.navigate_response = false;
			this.quiz_complete = false;
			this.quiz_last_content = this.quiz_obj.is_last_content;
			this.response = this.result_response;
			this.have_response = this.result_have_response;
			if ('responses' in this.quiz_obj) {
              this.response = this.quiz_obj.responses;
			  this.have_response = this.response.length > 0;
			}
		  }
		},
		methods: {
			cancelCourse: function() {
				//this.$parent.saveProgress(true);
				this.$parent.close()
			},
			onSubmit: function() {
				var self = this;
				this.quiz_complete = true
				if(this.question_item.question_type == 'hotspot') {
					before_hotspot_answer_submit();
				}
				if(!this.preview){
					this.$http.put(this.update_response_url+ "&" +jQuery('#question_submit_'+this.question_item.question_id).serialize() + "&page="+this.current_page+"&display_page="+this.current_page+"&pagination_action=&from_page=&finish=true").then(response => {
						this.result = response.body;
						this.show_result = true;
					});
				}
				if(!this.quiz_last_content) {
					showHideLastContentNotice(true);
				}else{
					showHideLastContentNotice(false);
				}
			},
			pageNavigate: function(navigate) {
				if(this.preview) {
					this.$http.put(this.update_response_url+"&page="+this.current_page+"&display_page="+this.current_page+"&pagination_action="+navigate+"&from_page=preview").then(response => {
						this.question_item = response.body.question[0];
						this.changeCurrentPage(navigate);
					});
				}else {
					if(this.question_item.question_type == 'hotspot') {
						before_hotspot_answer_submit();
					}
					this.$http.put(this.update_response_url+ "&" +jQuery('#question_submit_'+this.question_item.question_id).serialize() + "&page="+this.current_page+"&display_page="+this.current_page+"&pagination_action="+navigate+"&from_page=").then(response => {
						this.question_item = response.body.question[0];
						this.response = response.body.responses.length ? response.body.responses[0].response : {};
						this.navigate_response = response.body.responses.length ? true : false;
						this.changeCurrentPage(navigate);
					});
				}
			},
			changeCurrentPage: function(navigate) {
				if(navigate == 'next') {
					this.current_page +=1;
				}else {
					this.current_page -=1;
				}
				if(this.current_page == this.total_pages) {
					jQuery("#submit_course_quiz").css('pointer-events', 'all');
				}
			},
		}
	}
</script>