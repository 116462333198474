import Vue from 'vue/dist/vue.esm'
import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)

Vue.component('CreateBundleContent', {
	template: `<div>
							<div class="create_course_header">
								<span class="create_course_header_title"> Create Bundle </span>
								<span class="create_course_header_button active" @click="save_bundle"><a> {{$t('create_course.save')}} </a></span>
								<span class="create_course_header_button" @click="clear_bundle"><a> {{$t('create_course.cancel')}} </a></span>
							</div>
							<div class="create_course_body">
								<AddBundleContent :logged_in="logged_in"/>
							</div>
						</div>`,
	props: {
		logged_in: Boolean
	},
	data: function() {
		return {}
	},
	methods: {
		save_bundle() {
			var bundle = this.$children[0].bundle
			var products = {bundle_items: this.$children[0].selected_products}
			var data = {...bundle, ...products}

			if (bundle.id) {
				this.$http.patch('/bundles/'+bundle.id, data).then(function(response) {
					alert('Bundle Updated Successfully');
					this.$children[0].bundle = response.body.bundle
					this.$children[0].product_types = response.body.product_types
					this.$children[0].selected_product_types = response.body.selected_product_types
					this.$children[0].bundles = response.body.bundles
				});
			} else {
				this.$http.post('/bundles', data).then(function(response) {
					alert('Bundle Created Successfully');

					this.$children[0].bundle = response.body.bundle
					this.$children[0].product_types = response.body.product_types
					this.$children[0].selected_product_types = response.body.selected_product_types
					this.$children[0].bundles = response.body.bundles
				});
			}
		},
		clear_bundle() {
			this.$children[0].bundle.title = '';
			this.$children[0].bundle.description = '';
			this.$children[0].selected_products = [];
		}
	}
});

Vue.component('AddBundleContent', {
	template: `<div class="row">
							<div class="col-md-9 padding_0">
								<div class="row">
									<div class="sample_course_content col-md-12 col-sm-12">
										<div class="sample_list">
											<div class="search_box">
												<input type="text" placeholder="Search" v-model="bundle_search_text"/>
												<span @click="filterProductType"> <i class="fa fa-search fa-2x" aria-hidden="true"></i> </span>
											</div>
											<div style="float: right;margin-right: 10px;font-size: 14px;">
												<a @click="show_product_types = !show_product_types">
													Types <i class="fad fa-chevron-down"></i>
												</a>
											</div>
											<div style="border: 1px solid grey;padding: 10px;border-radius: 10px;float: right;margin-top: 15px;z-index: 100;position: absolute;background: grey;color: #FFFFFF;left: 30%; width: 150px;" v-if="show_product_types" class="bundle_product_types">
												<p v-for="product_type in product_types" style="margin-bottom: 5px;">
													<input type="checkbox" :id="product_type" name="conversation" :value="product_type" :checked=selected_product_types.includes(product_type)> {{ product_type }}
												</p>
												<p style="display: grid; grid-template-columns: 1fr 1fr 1fr;">
													<a @click="selectProductType('all')"> All </a>
													<a @click="selectProductType('none')"> None </a>
													<a @click="filterProductType"> Done </a>
												</p>
											</div>
											<div class="listing_records" style="height: 400px; overflow: scroll; margin-top: 40px;">
												<div class="record" v-for="product in products" @click="get_product(product.id)">
													<div class="icon"><i class="fa fa-sticky-note fa-2x" aria-hidden="true"></i> </div>
													<div>
														<span class="icon" style="float: right; margin-right: 30px;" v-if="selected_products.includes(product.id)"><i class="fa fa-check-circle" aria-hidden="true"></i> </span>
														<p class="title"> {{ product.title }} </p>
														<p class="description"> Created By {{ product.created_at }} </p>
													</div>
												</div>
											</div>
											<paginate 
									      :pageCount="total_pages"
									      :containerClass="'pagination'"
									      :clickHandler="clickCallback" v-if="total_pages">
									    </paginate>
										</div>
										<div class="sample_list_preview" style="height: 550px; overflow: scroll;">  
											<div v-for="bundle in bundles" style="margin: 10px;font-size: 14px;border-bottom: 1px solid #000; padding-bottom: 10px;">
												<span>{{ bundle.title }}</span> <br>
												<span>{{ bundle.description }}</span> <br>
												<a @click="editBundle(bundle)"> Edit </a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-3 padding_0">
								<NewBundleFormFields :logged_in="logged_in" :bundle="bundle"/>
							</div>
						</div>`,
	props: {
		logged_in: Boolean
	},
	data: function() {
		return {
			bundle: {},
			product_types: [],
			products: [],
			selected_products: [],
			show_product_types: false,
			bundle_search_text: '',
			total_pages: null,
			current_page: 1,
			selected_product_types: [],
			bundles: []
		}
	},
	methods: {
		editBundle(bundle) {
			this.bundle.id = bundle.id
			this.bundle.title = bundle.title
			this.bundle.description = bundle.description
			this.selected_products = bundle.items
		},
		get_product(product_id) {
			const index = this.selected_products.indexOf(product_id);
			if (index > -1) {
				this.selected_products.splice(index, 1);
			}else{
				this.selected_products.push(product_id)
			}
		},
		selectProductType(type) {
      var checkboxes = document.querySelectorAll('.bundle_product_types input[type="checkbox"]');
      var selected = (type == 'all' ? true : false)
      for (var checkbox of checkboxes) {
        checkbox.checked = selected;
      }
		},
		filterProductType(skip = false) {
			if (!skip) {
				this.selected_product_types = []
			}
			var product_type = []
      var markedCheckbox = document.querySelectorAll('.bundle_product_types input[type="checkbox"]:checked');
      for (var checkbox of markedCheckbox) {
        product_type.push(checkbox.value)
        const index = this.selected_product_types.indexOf(checkbox.value);
        if (index < -1) {
        	this.selected_product_types.push(checkbox.value)
				}
      }
      var not_selected = []
      var unmarkedCheckbox = document.querySelectorAll('.bundle_product_types input[type="checkbox"]:not(:checked)');
      for (var checkbox of unmarkedCheckbox) {
        not_selected.push(checkbox.value)
        const index = this.selected_product_types.indexOf(checkbox.value);
        if (index > -1) {
        	this.selected_product_types.splice(index, 1)
				}
      }
			this.$http.get('/product_picker?product_type='+product_type+'&search='+this.bundle_search_text+'&exclude_product_type='+not_selected+'&page='+this.current_page).then(function(response) {
				this.products = response.body.products
				this.total_pages = response.body.total_pages
			});
			this.show_product_types = false
		},
		clickCallback: function(page) {
      this.current_page = page
      this.filterProductType()
    }
	},
	mounted: function() {
		this.$http.get('/bundles/new').then(function(response) {
			this.bundle = response.body.bundle
			this.product_types = response.body.product_types
			this.selected_product_types = response.body.selected_product_types
			this.bundles = response.body.bundles
			this.filterProductType(true)
		});
	}
});


Vue.component('NewBundleFormFields', {
	template: `<div class="add_course_form_fields">
							<div class="form_fields_col_1 col-md-12 col-sm-12">
								<div class="form_group">
									<label class="required"> {{$t('create_course.title')}} </label> 
									<input type="text" class="grey_background" v-model="bundle.title"/>
								</div>
								<div class="form_group">
									<label class="required"> {{$t('create_course.description')}} </label>
									<textarea class="grey_background textarea" v-model="bundle.description" wrap="hard"/>
								</div>
							</div>
						</div>`,
	props: {
		bundle: Object
	},
	data: function() {
		return {}
	}
})